import React from 'react'

import SmartStatus from '../SmartStatus'

export const STATUSES = {
  new: {
    label: 'New Client',
    color: 'yellow',
  },
  lead: {
    label: 'Applicant',
    color: 'green',
  },
  intake: {
    label: 'In Intake',
    color: 'green',
  },
  current: {
    label: 'Current Client',
    color: 'blue',
  },
  past: {
    label: 'Past Client',
    color: 'gray',
  },
  alumni: {
    label: 'Alumni',
    color: 'yellow',
  },
  declined: {
    label: 'Declined',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={STATUSES} {...rest} />
}

export default Status
