import React from 'react'

import Alert from '../../components/Alert'
import Overlay from '../../components/Overlay'
import Markup from '../../components/Markup'

import { OrganizationLeadForm } from './OrganizationLeadForm'

type Props = {
  companyName: string
  firstName: string
  logo: string
  onClose: Function
  schema: any
  thankYouMessage: string
  welcomeMessage: string
}

export const OrganizationLeadFormOverlayExample = (props: Props) => {
  const { companyName, firstName, logo, onClose, schema, thankYouMessage, welcomeMessage, roiDisclaimerMessage } = props

  const form = React.useRef()

  return (
    <Overlay position="right" maxWidth={60} closeOnBackdrop showBackdrop={true} onClose={onClose}>
      <Overlay.Header icon="organization_lead_forms" title="Application Form Example" />
      <Overlay.Content>
        <div css={{ padding: '2.5rem', maxWidth: 600, margin: '0 auto' }}>
          {logo && (
            <div css={styles.header}>
              <img src={logo} alt={`${companyName} logo`} css={styles.logo} />
            </div>
          )}

          {/* {firstName && <p className="text-[1.2rem] font-[700] mt-0 mx-0 mb-2">Hello {firstName},</p>} */}

          {welcomeMessage && <Markup className="text-[1.1rem] mt-0 mx-0 mb-4" value={welcomeMessage} />}

          {/* <Alert contrast glyph="info" className="!mb-[1.5rem]">
            As a security measure, this page will expire in <strong>24 hours</strong>. If you need more time, don't worry, you can save and
            continue as long as you entered your email or cell phone number so we can send you a message with a link to resume.
          </Alert> */}

          <OrganizationLeadForm
            form={form}
            schema={schema}
            companyName={companyName}
            canUpload={false}
            roiDisclaimerMessage={roiDisclaimerMessage}
          />

          {thankYouMessage && (
            <Alert contrast glyph="tick_circle" type="positive" className="mt-6">
              <Markup value={thankYouMessage} />
            </Alert>
          )}
        </div>
      </Overlay.Content>
    </Overlay>
  )
}

const styles = {
  header: {
    margin: '0 0 2rem',
  },

  logo: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxWidth: 120,
    maxHeight: 80,
    margin: '0 auto',
  },
}
