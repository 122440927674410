import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'
import URLInput from '../../components/Forms/URLInput'

import { ORGANIZATIONS_TYPE_VENDORS, ORGANIZATIONS_TYPE_RESOURCES, ORGANIZATIONS_TYPE_PROVIDERS } from '../../utils/constants'
import { useCreate } from '../../hooks/useNewAPI'
import { OrganizationRelationshipTypeSelect } from '../Organizations/OrganizationRelationshipTypeSelect'

const CategorySelect = () => {
  return (
    <>
      <ContextShow when="category" is="vendor">
        <Select
          label="Category"
          model="subcategory"
          defaultValue="billing_service"
          validations={{
            presence: {
              message: 'Please select an organization category',
            },
          }}
        >
          {Object.keys(ORGANIZATIONS_TYPE_VENDORS).map((vendor) => (
            <Option label={ORGANIZATIONS_TYPE_VENDORS[vendor]} value={vendor} />
          ))}
        </Select>
      </ContextShow>

      <ContextShow when="category" within="resource">
        <Select
          label="Category"
          model="subcategory"
          defaultValue="general_resource"
          validations={{
            presence: {
              message: 'Please select an organization category',
            },
          }}
        >
          {Object.keys(ORGANIZATIONS_TYPE_RESOURCES).map((resource) => (
            <Option label={ORGANIZATIONS_TYPE_RESOURCES[resource]} value={resource} />
          ))}
        </Select>
      </ContextShow>

      <ContextShow when="category" within="provider">
        <Select
          label="Category"
          model="subcategory"
          defaultValue="sober_living_home"
          validations={{
            presence: {
              message: 'Please select an organization category',
            },
          }}
        >
          {Object.keys(ORGANIZATIONS_TYPE_PROVIDERS).map((provider) => (
            <Option label={ORGANIZATIONS_TYPE_PROVIDERS[provider]} value={provider} />
          ))}
        </Select>
      </ContextShow>
    </>
  )
}

const RootOrganizationLeadNewOrganizationOverlay = (props: any) => {
  const { close, form, isInvalid, isOverlayLoading, onValidationUpdate, initialModel } = useOverlay({
    options: props,
    disableParentRequest: true,
  })

  const { mutateAsync, isLoading: isSaving } = useCreate({
    name: 'organizations',
    url: `/organization_lead_form_submissions/${props.formID}/create_organization`,
    invalidate: 'organizations',
    invalidateKeys: ['organizations', 'organization_lead_form_submissions'],
  })

  const [relationship, setRelationship] = React.useState(initialModel?.relationship || 'internal')

  const save = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    close()
  }

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const { contacts, locations, programs, organization } = initialModel

  const contactsCount = size(contacts)
  const hasContacts = contactsCount > 0

  const locationsCount = size(locations)
  const hasLocations = locationsCount > 0

  const programsCount = size(programs)
  const hasPrograms = programsCount > 0

  return (
    <Overlay onClose={close} showBackdrop>
      <Overlay.Header icon="organizations" title="Create Organization" help={<HelpTagIframe id="add_organization" />} />

      <Overlay.Content>
        <Form getForm={form} timezone={timezone} initialModel={organization} onValidationUpdate={onValidationUpdate}>
          <Grid gap="0.5rem">
            <Section>
              <FormSection layout="vertical">
                <Input
                  autoFocus
                  label="Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter an organization name',
                    },
                  }}
                />

                {(hasContacts || hasLocations) && (
                  <CheckboxGroup label="Lead Form Imports" layout="vertical-dense">
                    {hasContacts && (
                      <Checkbox
                        defaultChecked
                        icon="contacts"
                        model="import_contacts"
                        label={`Auto-import ${countWord('Contacts', contactsCount)} from the Lead Form`}
                      />
                    )}

                    {hasLocations && (
                      <Checkbox
                        defaultChecked
                        icon="locations"
                        model="import_locations"
                        label={`Auto-import ${countWord('Locations', locationsCount)} from the Lead Form`}
                      />
                    )}

                    {hasPrograms && (
                      <Checkbox
                        defaultChecked
                        icon="programs"
                        model="import_programs"
                        label={`Auto-import ${countWord('Programs', programsCount)} from the Lead Form`}
                      />
                    )}
                  </CheckboxGroup>
                )}

                <OrganizationRelationshipTypeSelect
                  model="relationship"
                  tooltip="This option will help categorize the submissions you receive and allow you to create organization records based on the relationship type."
                  value={relationship}
                  onUpdate={({ value }) => setRelationship(value)}
                />

                {relationship === 'internal' && (
                  <>
                    <Select label="Type" model="category" defaultValue="provider">
                      <Option label="Vendor" value="vendor" />
                      <Option label="Community Resource" value="resource" />
                      <Option label="Provider" value="provider" />
                    </Select>

                    <CategorySelect />
                  </>
                )}

                {relationship === 'provider' && (
                  <>
                    <Select label="Type" model="category" defaultValue="provider" isEditable={false}>
                      <Option label="Vendor" value="vendor" />
                      <Option label="Community Resource" value="resource" />
                      <Option label="Provider" value="provider" />
                    </Select>

                    <CategorySelect />
                  </>
                )}

                {relationship === 'vendor' && (
                  <>
                    <Select label="Type" model="category" defaultValue="vendor" isEditable={false}>
                      <Option label="Vendor" value="vendor" />
                      <Option label="Community Resource" value="resource" />
                      <Option label="Provider" value="provider" />
                    </Select>

                    <CategorySelect />
                  </>
                )}

                <EmailInput label="Email Address" model="email" />

                <Flex horizontal gap="1rem">
                  <PhoneInput css={{ flex: '1 1 auto' }} model="phone_no" label="Phone Number" type="tel" />
                  <Input css={{ width: 145 }} glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />
                </Flex>

                <Input glyph="fax" label="Fax" model="fax" />

                <Textarea useQuickText model="additional_info" label="Notes" />
              </FormSection>
            </Section>

            <Section title="Address">
              <FormSection>
                <AddressSelectorInput actionLabel="Find Address" model="address" />
              </FormSection>
            </Section>

            <Section title="Links & Meetings">
              <FormSection layout="vertical">
                <URLInput label="Website" model="website_url" glyph="website" />

                <Grid vertical gap="1rem" columns="1fr 1fr 1fr">
                  <URLInput glyph="facebook" label="Facebook" model="facebook" />
                  <URLInput glyph="instagram" label="Instagram" model="instagram" />
                  <URLInput glyph="googleplus" label="Google Business" model="google_business" />
                </Grid>

                <Grid vertical gap="1rem" columns="1fr 1fr">
                  <URLInput glyph="whatsapp" label="WhatApp" model="whatsapp" />
                  <URLInput glyph="zoom" label="Zoom" model="zoom" />
                </Grid>

                <Grid vertical gap="1rem" columns="1fr 1fr">
                  <URLInput glyph="googleplus" label="Google Meet" model="google_meet" />
                  <URLInput glyph="skype" label="Skype" model="skype" />
                </Grid>
              </FormSection>
            </Section>
          </Grid>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Create Organization" type="primary" color="green" isLoading={isSaving} onClick={save} isDisabled={isInvalid} />
      </Overlay.Footer>
    </Overlay>
  )
}

export const OrganizationLeadNewOrganizationOverlay = withOverlayError(RootOrganizationLeadNewOrganizationOverlay)
