import React from 'react'
import Tabs from '@behavehealth/components/Tabs'

export const useStaffDataFormTabs = () => {
  const [activeTab, setActiveTab] = React.useState('supervisor')

  const queryParams = React.useMemo(() => {
    const result: any = {}

    if (activeTab === 'supervisor') result['supervisor'] = true
    if (activeTab === 'author') result['author'] = true

    return result
  }, [activeTab])

  return { activeTab, setActiveTab, queryParams }
}

export const StaffDataFormTabs = ({ activeTab, setActiveTab }: any) => {
  return (
    <Tabs activeTab={activeTab} onChange={setActiveTab} className="-mt-3">
      <Tabs.List>
        <Tabs.Item label="Supervisor" name="supervisor" />
        <Tabs.Item label="Author" name="author" />
      </Tabs.List>
    </Tabs>
  )
}
