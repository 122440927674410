import React from 'react'

import Button from '../../components/Button'
import ButtonGroup from '../../components/ButtonGroup'
import Permission from '../../components/Permission'
import Tooltip from '../../components/Tooltip'

import { NavGroupLabel } from '../../components/NavGroup'

export const ClientTrackTabs = ({ clientTrack, activeId, setActiveId, manageLinkTarget, className = 'px-4 pt-3 pb-2' }) => {
  if (!clientTrack) return null

  return (
    <Permission featureFlagV2="client_tracks">
      <div className={className}>
        <div className="flex flex-nowrap justify-between items-center mb-2">
          <NavGroupLabel label="Templates" />
          <Tooltip
            content={
              <div className="grid gap-2">
                <div>
                  <b>Client Tracks</b>
                </div>

                <ul>
                  <li>
                    Client Tracks help manage which templates are available for clients based on the track they are currently assigned to.
                  </li>
                  <li>Clients can be on only one track at a time, and templates can be linked to one or more tracks.</li>
                  <li>When adding a new record, the available templates will be filtered based on the client's current track.</li>
                </ul>

                <div>
                  <Button
                    label="Manage Client Tracks"
                    display="inline-flex"
                    glyph="settings"
                    size={100}
                    link="/settings/client-tracks"
                    target={manageLinkTarget}
                    useGlyphForTarget={!!manageLinkTarget}
                  />
                </div>
              </div>
            }
            className="ml-2"
          />
        </div>

        <ButtonGroup stretchSelf>
          <Button
            label={clientTrack?.name}
            icon="client_tracks"
            color="text"
            type={activeId ? 'primary' : 'default'}
            onClick={(e) => {
              e.stopPropagation()
              setActiveId(clientTrack?.id)
            }}
            size={200}
          />

          <Button
            label="All Client Tracks"
            icon="client_tracks"
            color="text"
            type={!activeId ? 'primary' : 'default'}
            onClick={(e) => {
              e.stopPropagation()
              setActiveId(undefined)
            }}
            size={200}
          />
        </ButtonGroup>
      </div>
    </Permission>
  )
}
