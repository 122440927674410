import useSettings from '@behavehealth/hooks/useSettings'
import { useLocation } from 'react-router-dom-v5-compat'

export const useIsMyProfile = () => {
  const location = useLocation()
  const { user } = useSettings()

  if (!location?.pathname || !user?.id) return false

  return location.pathname.startsWith(`/me/${user.id}`) || location.pathname.startsWith(`/employees/${user.id}`)
}
