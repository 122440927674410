import React from 'react'
import { useParams } from 'react-router-dom'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { FormSubmissionPageActions } from '@behavehealth/constructs/FormSubmissions/FormSubmissionPageActions'
import { FormSubmissionsDataTable } from '@behavehealth/constructs/FormSubmissions/FormSubmissionsDataTable'

const FormSubmissions = ({ reference, allowedReference }: any) => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['client', resource_id, 'form_submissions'],
    endpoint: `/residents/${resource_id}/form_submissions`,
    updateDeleteEndpoint: `/form_submissions`,
    localStorageKey: 'form_submissions_v2',
  })

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  return (
    <Page
      title="Form Inbox"
      icon="web_form"
      actions={
        <FormSubmissionPageActions
          reference={reference}
          allowedReference={allowedReference}
          data={{
            reference: client,
            reference_id: client?.id,
            reference_type: 'resident',
          }}
        />
      }
    >
      <Grid>
        <FormSubmissionsDataTable
          asCard
          showClientPortalColumn
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'form_submissions.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export default FormSubmissions
