import React from 'react'

import ContactProfileHeader from '../../components/ContactProfileHeader'
import Flex from '../../components/Flex'
import OverlaySubHeader from '../../components/Overlay/OverlaySubHeader'

export const ContactWorkflowSubheader = ({ contact }) => {
  if (!contact) return null

  return (
    <OverlaySubHeader className="grid gap-2 !py-2 shadow-hard-2 relative z-[3]">
      <Flex gap="1rem" alignItems="center">
        <div>
          <ContactProfileHeader contact={contact} />
        </div>
      </Flex>
    </OverlaySubHeader>
  )
}
