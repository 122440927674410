import React from 'react'

import DateTimeInput from '../../components/Forms/DateTimeInput'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Section from '../../components/Section'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

export const AuthorityFormGeneralSection = ({ defaultName, isNew }) => {
  return (
    <Section scrollview={{ id: 'general', name: 'General' }} headingType="h2" title="General">
      <FormSection>
        <Input
          label="Name"
          model="name"
          defaultValue={defaultName}
          validations={{
            presence: {
              message: 'Please enter a form name',
            },
          }}
        />

        {!isNew && (
          <>
            <ObjectSelector
              label="Author"
              blankLabel="Select Staff…"
              icon="employees"
              type="employees"
              model="author"
              selectTitle={(data) => data?.name}
              selectDescription={() => null}
              isEditable={false}
            />
          </>
        )}

        <ObjectSelector
          isPolymorphic
          label="Supervisor"
          blankLabel="Select Supervisor…"
          icon="employees"
          type="clinical_supervisors"
          model="supervisor"
          selectTitle={(data) => data?.name}
          selectDescription={() => null}
          tooltip="A signature box will be available at the bottom of this form for the selected supervisor"
        />

        <MultiObjectSelector
          isPolymorphic
          label="Staff Members"
          blankLabel="Select Staff Members…"
          icon="employees"
          type="employees"
          model="staff"
          selectTitle={(data) => data?.name}
          selectDescription={() => null}
          tooltip="A signature box will be available at the bottom of this form for each of the selected staff members"
        />

        <DateTimeInput
          defaultToNow
          model="dated_at"
          label="Start Date and Time"
          validations={{
            presence: {
              message: 'Please enter a date and time',
            },
          }}
        />

        <FormFutureDateWarning dateLabel="Start Date and Time" model="dated_at" />

        <DateTimeInput model="ended_at" label="End Date and Time" />
      </FormSection>
    </Section>
  )
}
