import React from 'react'
import { useLocation } from 'react-router-dom'

import { mapToArray } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Divider from '../../components/Divider'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'
import State from '../../components/State'

import { OrganizationTrackTabs } from '../../constructs/Organizations/OrganizationTrackTabs'

export const OrganizationTodoPageActions: React.FC<any> = ({
  data,
  organizationTrack,
  newFeatureFlag,
  onClick,
  showManageLink = true,
  buttonSize = 300,
}) => {
  const { pathname } = useLocation()

  const [organizationTrackId, setOrganizationTrackId] = React.useState(organizationTrack?.id)

  const { data: templates, isLoading }: any = useGet({
    name: ['todo-templates', { organizationTrackId }],
    url: `/event_templates`,
    params: { event_type: 'task', organization_track_id: organizationTrackId },
  })

  React.useEffect(() => {
    if (!organizationTrack?.id) return

    setOrganizationTrackId(organizationTrack?.id)
  }, [organizationTrack])

  return (
    <>
      {showManageLink && (
        <Button
          label="Manage Todo Templates"
          glyph="settings"
          type="default"
          link="/settings/todo-templates"
          permission="todo_templates.view"
        />
      )}

      <Dropdown label="Add Todo…" glyph="add" buttonType="primary" buttonSize={buttonSize} permission="todos.create">
        <DropdownItem
          label="New To-Do"
          glyph="add"
          color="paleBlue"
          link={
            onClick
              ? undefined
              : {
                  pathname: `${pathname}/todo-builder`,
                  data: data,
                }
          }
          onClick={onClick ? () => onClick({}) : undefined}
          permission="todos.create"
          newFeatureFlag={newFeatureFlag}
        />

        <Divider />

        <OrganizationTrackTabs organizationTrack={organizationTrack} activeId={organizationTrackId} setActiveId={setOrganizationTrackId} />

        {isLoading ? (
          <State isLoading minHeight={100} />
        ) : (
          mapToArray(templates).map((template) => {
            const { id, ...templateKeys } = template

            return (
              <DropdownItem
                key={template.id}
                label={template.title}
                icon="tasks"
                color="paleBlue"
                link={
                  onClick
                    ? undefined
                    : {
                        pathname: `${pathname}/todo-builder`,
                        data: {
                          ...templateKeys,
                          ...data,
                          template_id: template.id,
                        },
                      }
                }
                onClick={onClick ? () => onClick(template) : undefined}
                permission="todos.create"
                newFeatureFlag={newFeatureFlag}
              />
            )
          })
        )}
      </Dropdown>
    </>
  )
}
