import React from 'react'
import { Link, Route, useLocation } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Page, PageLayout, TipAlert, HelpTagIframe } from '@behavehealth/components'
import { ExportCSVButton } from '@behavehealth/components/Buttons/ExportCSVButton'
import { PermissionTemplateOverlay } from '@behavehealth/constructs/PermissionTemplates/PermissionTemplateOverlay'
import { PermissionTemplatesDataTable } from '@behavehealth/constructs/PermissionTemplates/PermissionTemplatesDataTable'

import PermissionTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/PermissionTemplatesImportOverlay'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'permission_templates',
  title: 'Permission Templates',
  help: <HelpTagIframe id="settings_permission_templates" />,
}

const PermissionTemplates = () => {
  return (
    <>
      <PermissionTemplatesIndex />

      <AnimatedRoutes>
        <Route path="import" element={<PermissionTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<PermissionTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const PermissionTemplatesIndex = () => {
  const { pathname } = useLocation()

  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['permission_templates'],
    endpoint: `/permission_templates`,
    localStorageKey: 'permission_templates_v1',
  })

  return (
    <Page
      breakpoint={3}
      actions={
        <>
          {isBehave && <Button as={Link} label="Import Behave Templates" type="default" glyph="add" link={`${pathname}/import`} />}
          <Button
            as={Link}
            label="Add Permissions Template"
            type="primary"
            glyph="add"
            link={`${pathname}/new`}
            permission="settings.permissions.create"
          />
        </>
      }
      {...pageConfig}
    >
      <PageLayout>
        <TipAlert contrast type="warning" localStorageKey="alumni_table_columns_moved">
          <b>Please note:</b> to change your staff permissions, navigate to <Link to="/employees/active">Active Staff</Link>. Select the
          staff member and navigate to their permissions sub-page. Click the apply Staff Permissions button to use your Staff Permission
          Templates or go fully custom by changing it for just the selected staff member.
        </TipAlert>

        <PermissionTemplatesDataTable
          to={(row: any) => row.id}
          headerAfter={<ExportCSVButton url={`/permission_templates/csv`} />}
          {...tableProps}
        />
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(PermissionTemplates, pageConfig))
