import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { useGet } from '@behavehealth/hooks/useNewAPI'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, DnDUploader, Card, CardContent, Page, Uploader, HelpTagIframe, Permission } from '@behavehealth/components'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { FilesDataTable } from '@behavehealth/constructs/Files/FilesDataTable'

const pageConfig = {
  feature: 'files',
  help: <HelpTagIframe id="files" />,
  marketingID: 'files',
}

const Files: React.FC = () => {
  const match = useRouteMatch()
  const params: any = useParams()

  const { data: organization }: any = useGet({
    name: ['organization', params?.resource_id],
    url: `/organizations/${params?.resource_id}`,
  })

  const tableProps: any = useDataTable({
    name: ['organization', params?.resource_id, 'files'],
    endpoint: `/organizations/${params?.resource_id}/documents`,
    updateDeleteEndpoint: '/documents',
    enabled: !!params?.resource_id,
    localStorageKey: 'organization_files_v1',
  })

  const actions = (
    <Uploader category="general" resource={organization}>
      <Button type="primary" glyph="upload" label="Upload Files" permission="organizations.files.create" />
    </Uploader>
  )

  return (
    <Page actions={actions} {...pageConfig}>
      <div className="grid grid-cols-[100%]">
        <Card>
          <FilesDataTable
            {...tableProps}
            title="Organization Files"
            to={(row: any) => `${match.url}/${row.id}`}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'organizations.files.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />

          <CardContent>
            <Permission permission="organizations.files.create">
              <DnDUploader category="general" queryKey={tableProps.queryKey} resource={organization} isEditable={true} isDisabled={false} />
            </Permission>
          </CardContent>
        </Card>
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(Files, pageConfig))
