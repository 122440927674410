import React from 'react'
import { NavLink, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { AuthorityDirectoryOverlay } from '@behavehealth/constructs/AuthorityDirectories/AuthorityDirectoryOverlay'
import { AuthorityDirectoryStatus } from '@behavehealth/constructs/AuthorityDirectories/AuthorityDirectoryStatus'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'

const RootAuthorityDirectories = () => {
  return (
    <>
      <AuthorityDirectoriesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<AuthorityDirectoryOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const AuthorityDirectoriesIndex = () => {
  const to = React.useCallback((data) => data.id, [])

  const tableProps = useDataTable({
    name: ['directories'],
    endpoint: `/directories`,
    localStorageKey: 'directories_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        id: 'name',
        model: 'name',
        title: 'Name',
        formatValue: ({ value, data }: any) => <MainCell as={NavLink} to={data.id} value={value} />,
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }) => <AuthorityDirectoryStatus status={value} />,
      },
      {
        width: 200,
        model: 'slug',
        title: 'Public Link',
        formatValue: ({ data, value }) => {
          if (!value || data.status !== 'active') return null

          return <LinkCell isExternal glyph="link" href={`https://directory.behavehealth.com/${value}`} value="Open Public Link" />
        },
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <Page
      title="Directories"
      icon="directories"
      actions={<Button as={NavLink} label="Add Directory" glyph="add" type="primary" link="new" />}
    >
      <Grid>
        <DataTable asCard to={to} columns={columns} icon="directories" title="Directories" {...tableProps} />
      </Grid>
    </Page>
  )
}

export const AuthorityDirectories = withPageError(RootAuthorityDirectories)
