import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { useCreate, useGet } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Section from '../../components/Section'

import { OrganizationLeadForm } from './OrganizationLeadForm'

const RootNewOrganizationLeadFormSubmissionOverlay = (props: any) => {
  const { close, form, isEditable, params, saveWithData, isSaving } = useOverlay({
    name: 'organization_lead_form_submissions',
    endpoint: 'organization_lead_form_submissions',
    invalidate: 'organization_lead_form_submissions',
    disableParentRequest: true,
    options: {
      ...props,
      dataID: 'new',
    },
  })

  const form_id = params?.form_id || props.formId

  const [organization, setOrganization]: any = React.useState(props.organization || null)

  const leadFormQuery = useGet({
    name: ['organization_lead_forms', form_id],
    url: `/organization_lead_forms/${form_id}`,
  })

  const { timezone } = useSettings()

  const save = async () => {
    const formData = form.current.getFormValue()

    await saveWithData({
      data: formData,
      organization_lead_form_id: form_id,
      organization_id: organization?.id || null,
    })

    close()
  }

  if (leadFormQuery.isLoading || !leadFormQuery.data) {
    return <OverlayLoader position="right" maxWidth={60} />
  }

  const { schema } = leadFormQuery.data

  return (
    <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={60}>
      <Overlay.Header icon="organization_lead_forms" title="Organization Lead Contact Form" />

      <Overlay.Content>
        <Section>
          <div className="pb-2 mb-4">
            <FormSection maxWidth="100%">
              <OverlaySelector
                isCompact
                isEditable={isEditable}
                label="Linked Organization"
                blankLabel="Select organization…"
                icon="organizations"
                type="organizations_with_relationships"
                value={organization}
                onUpdate={({ object }) => {
                  setOrganization(object)
                }}
                selectTitle={(data) => data.name}
                selectDescription={() => null}
              />

              <Divider className="!m-0" />
            </FormSection>
          </div>

          <OrganizationLeadForm form={form} schema={schema} timezone={timezone} isEditable={isEditable} />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          glyph="check"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          flex="100 1 auto"
          permission="organization_lead_forms.create"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const NewOrganizationLeadFormSubmissionOverlay = withOverlayError(RootNewOrganizationLeadFormSubmissionOverlay)
