import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { getVersionSlug } from '@behavehealth/utils/functions'
import { MEDICAL_ASSESSMENTS_SLUGS } from '@behavehealth/utils/constants'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, Chotomate, HelpTagIframe, Page, Grid } from '@behavehealth/components'

import { MedicalAssessmentsDataTable } from '@behavehealth/constructs/MedicalAssessments/MedicalAssessmentsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { StaffDataFormsAlert } from '../components/StaffDataFormsAlert'

import { getBatchActions, getCanSelectDataForm } from '../utils/dataForms'
import { useIsMyProfile } from '../utils/useIsMyProfile'
import { useStaffDataFormTabs, StaffDataFormTabs } from '../components/StaffDataFormTabs'

const pageConfig = {
  feature: 'medical_assessments',
  help: <HelpTagIframe id="medical_assessments" />,
  marketingID: 'medical_assessments',
}

type Props = {
  canCreate: boolean
}

const MedicalAssessments: React.FC<Props> = () => {
  const match = useRouteMatch()
  const resourceID = match.params?.resource_id

  const isMyProfile = useIsMyProfile()
  const { activeTab, setActiveTab, queryParams } = useStaffDataFormTabs()

  const tableProps = useDataTable({
    name: ['employee', resourceID, 'data_forms'],
    endpoint: `/employees/${resourceID}/data_forms`,
    params: { category: 'medical_assessment', ...queryParams },
    updateDeleteEndpoint: '/data_forms',
    localStorageKey: 'employee_medical_assessments_v1',
  })

  const to = React.useMemo(
    () => (record: any) => {
      const { version, subcategory, id } = record

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

      return {
        pathname: `${match.url}/${MEDICAL_ASSESSMENTS_SLUGS[subcategory]}/${id}${formattedVersion}`,
        parent: match,
      }
    },
    [match.url],
  )

  return (
    <Page {...pageConfig}>
      <Chotomate name="medical_assessments" ready={!tableProps.isLoading} />

      <Grid gap="1rem" columns="100%">
        <StaffDataFormsAlert name="Medical Assessments" />

        <StaffDataFormTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        <Card>
          <MedicalAssessmentsDataTable
            {...tableProps}
            to={to}
            key={`tab-${activeTab}`}
            headerLinksConfig={[
              {
                type: 'report',
                to: '/reports/medical-assessments',
              },
            ]}
            getCanSelect={getCanSelectDataForm}
            batchActionsConfig={getBatchActions({ isMyProfile, activeTab, tableProps, permissionBase: 'medical_assessments' })}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(MedicalAssessments, pageConfig))
