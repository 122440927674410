import React from 'react'
import { DateTime } from 'luxon'

import { SectionCard } from '../../../components/SectionCard'
import { usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import PageSection from '../../../components/PageSection/PageSection'
import RichTextEditor from '../../../components/Forms/RichTextEditor'
import Text from '../../../components/Typography/Text'

export const QuoteComments = (props: any) => {
  const { data, isEditable, formData, onValidationUpdate, onUpdate } = props

  const form = React.useRef(null)

  const { timezone: settingsTimezone } = useSettings()
  const timezone = props.timezone || settingsTimezone

  return (
    <Form
      isCompact
      getForm={form}
      isEditable={isEditable}
      initialModel={{
        comments: {
          behave_comments: data?.comments?.behave_comments || '',
        },
      }}
      onValidationUpdate={onValidationUpdate}
      timezone={formData.timezone || timezone}
      onUpdate={({ comments }) => {
        const res = { ...(data?.comments || {}), ...comments }

        if (comments?.behave_comments !== data?.comments?.behave_comments) {
          res['behave_comments_updated'] = DateTime.local().setZone(timezone).toISO()
        }

        onUpdate({ comments: res })
      }}
    >
      <FormSection maxWidth="100%">
        <PageSection className="p-5">
          <PageSection.Header>
            <PageSection.Title title="Comments" className="!text-[1.65rem]" />
          </PageSection.Header>

          <div className="grid grid-cols-1 gap-5">
            <SectionCard className="!p-3 grid gap-2">
              <h4 className="mb-2">Customer Comments</h4>
              <RichTextEditor isEditable={false} value={data?.comments?.customer_comments || ''} />

              {data?.comments?.customer_comments_updated && (
                <div>
                  <Text
                    glyph="date"
                    label="Last Updated: "
                    description={usDateTime(data?.comments?.customer_comments_updated, timezone)}
                    className="mt-1 text-[0.9rem]"
                  />
                </div>
              )}
            </SectionCard>

            <SectionCard className="!p-3 grid gap-2">
              <h4 className="mb-2">Behave Health Comments</h4>
              <RichTextEditor model="comments.behave_comments" />

              {data?.comments?.behave_comments_updated && (
                <div>
                  <Text
                    glyph="date"
                    label="Last Updated: "
                    description={usDateTime(data?.comments?.behave_comments_updated, timezone)}
                    className="mt-1 text-[0.9rem]"
                  />
                </div>
              )}
            </SectionCard>
          </div>
        </PageSection>
      </FormSection>
    </Form>
  )
}
