import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import CustomNoteSections from '../../components/Elements/CustomNoteSections'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Dialog from '../../components/Dialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'

const RootStaffCustomNoteTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'custom_note_templates',
    endpoint: '/custom_note_templates',
    invalidate: 'custom_note_templates',
    options: props,
  })

  const { allowEditing = true } = props

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={82} />
  }

  return (
    <Overlay
      onClose={close}
      showBackdrop={isEditable}
      maxWidth={82}
      closeWrapper={(element) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost. This action cannot be undone."
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={close}
          skip={!isEditable}
        >
          {element}
        </Dialog>
      )}
    >
      <Overlay.Header title={data?.name || 'New Staff Custom Note Template'} icon="custom_notes" />

      <Overlay.Content>
        <Form
          useFullModel
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          linked={{ variant: 'staff' }}
        >
          <Section title="Template Details" headingType="h2">
            <FormSection maxWidth="100%">
              <Flex gap="1rem" alignItems="flex-end">
                <Input
                  autoFocus
                  className="!grow-[2]"
                  label="Template Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a template name',
                    },
                  }}
                />

                <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox label="Require Supervisor Signature" model="settings.require_supervisor_signature" />
                </CheckboxGroup>
              </Flex>

              <Textarea useQuickText label="Description" model="notes" className="!grow !basis-[300px] !self-start" />
            </FormSection>
          </Section>

          <Divider />

          <CustomNoteSections
            model="sections"
            validations={{
              presence: {
                message: 'Please add at least one section',
              },
            }}
          />

          <Section
            headingType="h2"
            title="Signatures"
            aside={
              <CheckboxGroup layout="horizontal-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                <Checkbox label="Client Signature" model="settings.require_reference_signature" />
                <Checkbox label="Staff Signature" model="settings.require_staff_signature" />
              </CheckboxGroup>
            }
          />

          <Divider />

          {!isNew && data && (
            <>
              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {allowEditing && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save Template"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 240px"
                permission="settings.staff_custom_note_templates.edit"
              />

              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                label="Edit"
                glyph="edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="settings.staff_custom_note_templates.edit"
              />

              <DeleteDialog
                title="Delete Custom Note Template?"
                message="Are you sure you want to delete this Custom Note Template? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="settings.staff_custom_note_templates.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const StaffCustomNoteTemplateOverlay = withOverlayError(RootStaffCustomNoteTemplateOverlay)
