import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { apiGet } from '@behavehealth/modules/api'
import { useAPI } from '@behavehealth/hooks'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Notifications from '@behavehealth/modules/notifications'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, CardContent, CardHeader, CardTitle, HelpTagIframe, Icon, Page, PageLayout } from '@behavehealth/components'

import Accordion from '@behavehealth/components/Accordion'
import Checkbox from '@behavehealth/components/Forms/Checkbox'
import CheckboxGroup from '@behavehealth/components/Forms/CheckboxGroup'
import Flex from '@behavehealth/components/Flex'
import Form from '@behavehealth/components/Forms/Form'
import FormSection from '@behavehealth/components/Forms/FormSection'
import Tooltip from '@behavehealth/components/Tooltip'
import OverlaySelector from '@behavehealth/components/Forms/Selectors/OverlaySelector/OverlaySelector'

import { EVOBTable, VOBSummariesTable } from '@behavehealth/components/Tables'
import { EVOBDataTable } from '@behavehealth/constructs/EVOB/EVOBDataTable'
import { FullVOBDataTable } from '@behavehealth/constructs/FullVOB/FullVOBDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import useSettings from '@behavehealth/hooks/useSettings'

import { InsurancePoliciesDataTable } from '@behavehealth/constructs/Insurance/InsurancePoliciesDataTable'

const pageConfig = {
  feature: 'insurance_policies',
  title: 'Insurance & VOB',
  help: <HelpTagIframe id="insurance_and_vob" />,
  marketingID: 'insurance_and_vob',
}

const Insurance: React.FC = () => {
  const match = useRouteMatch()
  const [isProcessingEVOB, setIsProcessingEVOB] = React.useState(false)
  const timeout = React.useRef(null)
  const timeoutCount = React.useRef(1)

  const clientId = match.params?.resource_id
  const { isBilledByBehave, isBehave } = useSettings()

  // const insuranceEVOBs = useSelector((state) => state.data.insurance_evobs?.data)
  // const insuranceEVOBsEmpty = size(insuranceEVOBs) <= 0
  // const loadingInsuranceEVOBs = useSelector((state) => state.data.insurance_evobs?.loading)

  // const insuranceVOBSummaries = useSelector((state) => state.data.insurance_vob_summaries?.data)
  // const loadingInsuranceVOBSummaries = useSelector((state) => state.data.insurance_vob_summaries?.loading)

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
  })

  const { mutateAsync: updateClient }: any = useUpdate({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
    invalidate: ['clients'],
  })

  const insuranceTableProps = useDataTable({
    name: ['client', clientId, 'insurance_policies'],
    endpoint: `/residents/${clientId}/insurance_policies`,
    updateDeleteEndpoint: '/insurance_policies',
    enabled: !!clientId,
    localStorageKey: 'client_insurance_policies_v1',
  })

  const emptyInsurancePolicies = size(insuranceTableProps.data) === 0

  const insuranceLinkTo = React.useMemo(
    () => (rowData: any) => ({
      pathname: `${match.url}/${rowData.id}`,
      parent: match,
    }),
    [match.url],
  )

  // useAPI('insurance_evobs', `/residents/${match.params?.resource_id}/insurance_evobs`)
  // useAPI('insurance_vob_summaries', `/residents/${match.params?.resource_id}/insurance_vobs/summaries`)

  const insuranceEVOBsTableProps = useDataTable({
    name: ['client', clientId, 'insurance_evobs'],
    endpoint: `/residents/${clientId}/insurance_evobs`,
    enabled: !!clientId,
    localStorageKey: 'client_insurance_evobs_v1',
  })

  const insuranceVOBSummariesTableProps = useDataTable({
    name: ['client', clientId, 'insurance_vob_summaries'],
    endpoint: `/residents/${clientId}/insurance_vobs/summaries`,
    enabled: !!clientId,
    localStorageKey: 'client_insurance_vob_summaries_v1',
  })

  const insuranceEVOBs = insuranceEVOBsTableProps?.data

  const actions = (
    <>
      <Button
        label="Add Insurance Policy"
        glyph="add"
        type="primary"
        link={{
          pathname: `${match.url}/new`,
          parent: match,
        }}
        permission="insurance_policies.create"
      />
    </>
  )

  const calculateDynamicIntervalCount = (count) => {
    if (count >= 3) return 20000
    else return count * 5000
  }

  const refreshData = () => {
    // clear timeout
    if (timeout.current) clearTimeout(timeout.current)

    // if not processing just return
    if (!isProcessingEVOB) return

    // recreate timeout with new interval
    const interval = calculateDynamicIntervalCount(timeoutCount.current)
    timeout.current = setTimeout(() => {
      Notifications.send('Refreshing Insurance eVOBs', 'positive')
      apiGet({
        name: 'insurance_evobs',
        url: `/residents/${match.params?.resource_id}/insurance_evobs`,
        opts: {
          cache: 0,
        },
      })
      timeoutCount.current = timeoutCount.current + 1
      refreshData()
    }, interval)
  }

  React.useEffect(() => {
    if (!insuranceEVOBs) return

    let isProcessing = false

    for (const key in insuranceEVOBs) {
      if (insuranceEVOBs[key].status === 'processing') {
        isProcessing = true
        break
      }
    }

    setIsProcessingEVOB(isProcessing)
  }, [insuranceEVOBs])

  React.useEffect(() => {
    if (isProcessingEVOB) {
      refreshData()
    } else if (timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = null
      timeoutCount.current = 1
    }

    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [isProcessingEVOB])

  return (
    <Page emptyDescription="There are no insurance policies added yet" actions={actions} {...pageConfig}>
      <PageLayout>
        {/* Billing */}
        <Accordion
          isOpen
          activateEditMode
          icon="insurance"
          title="Insurance Billing"
          permission="clients.general_info.billing.view"
          editPermission="clients.general_info.billing.edit"
          initialModel={client}
          onSubmit={updateClient}
          newFeatureFlag="insurance_claims"
        >
          <Form>
            <FormSection layout="vertical">
              <Flex gap="1rem">
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Billable Client" model="is_insurance_billable" />
                </CheckboxGroup>

                <Tooltip content="Please use this in order to generate Insurance Roster Items" />
              </Flex>

              {isBehave && (
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Billed by Behave" model="is_billed_by_behave" />
                </CheckboxGroup>
              )}

              <Flex gap="1rem">
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Automatic Eligibility Run" model="automatic_eligibility_run" />
                </CheckboxGroup>
                <Tooltip content="Automatically run eligibility on the 15th of each month" />
              </Flex>

              <OverlaySelector label="Default Fee Schedule" type="insurance.fee_schedules" model="default_fee_schedule" />
            </FormSection>
          </Form>
        </Accordion>

        <Card>
          <CardHeader graphic={<Icon icon="insurance" size={20} />}>
            <CardTitle title="Insurance Policies" />
          </CardHeader>

          <CardContent padding="0" showDivider={!emptyInsurancePolicies}>
            <InsurancePoliciesDataTable
              {...insuranceTableProps}
              to={insuranceLinkTo}
              batchActionsConfig={[
                {
                  type: 'delete',
                  permission: 'insurance_policies.delete',
                  action: async ({ ids }: any) => {
                    await insuranceTableProps.deleteRecords(ids.join(','))
                  },
                },
              ]}
            />
          </CardContent>
        </Card>

        <Card featureFlagV2="evobs" permission="insurance_evobs.view">
          <CardHeader graphic={<Icon icon="financials" size={20} />}>
            <CardTitle title="eVOBs" />
          </CardHeader>

          <CardContent padding="0" showDivider>
            {/* <EVOBTable
              title="eVOBs"
              icon="financials"
              data={insuranceEVOBs}
              isLoading={insuranceEVOBsEmpty && loadingInsuranceEVOBs}
              to={(id) => ({
                pathname: `${match.url}/evob-summaries/${id}`,
                parent: match,
              })}
            /> */}
            <EVOBDataTable
              {...insuranceEVOBsTableProps}
              asCard={false}
              to={(row) => ({
                pathname: `${match.url}/evob-summaries/${row?.id}`,
                parent: match,
              })}
            />
          </CardContent>
        </Card>

        {isBilledByBehave && (
          <Card featureFlagV2="full_vobs" permission="insurance_evobs.view">
            <CardHeader graphic={<Icon icon="financials" size={20} />}>
              <CardTitle title="Full VOBs" />
            </CardHeader>

            <CardContent padding="0" showDivider>
              {/* <VOBSummariesTable
                title="Full VOB Summaries"
                icon="financials"
                data={insuranceVOBSummaries}
                isLoading={loadingInsuranceVOBSummaries}
                to={(id) => ({
                  pathname: `${match.url}/vob-summaries/${id}`,
                  parent: match,
                })}
              /> */}

              <FullVOBDataTable
                {...insuranceVOBSummariesTableProps}
                asCard={false}
                to={(row) => ({
                  pathname: `${match.url}/vob-summaries/${row?.id}`,
                  parent: match,
                })}
              />
            </CardContent>
          </Card>
        )}
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(Insurance, pageConfig))
