import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { OrganizationTrackOverlay } from '@behavehealth/constructs/AuthorityOrganizations/OrganizationTrackOverlay'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Alert from '@behavehealth/components/Alert'
import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import Status from '@behavehealth/components/Status'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const OrganizationTracks = () => {
  const tableProps = useDataTable({
    name: ['organization_tracks'],
    endpoint: `/organization_tracks`,
    localStorageKey: 'organization_tracks_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        disableSort: true,
        formatValue: ({ data, value }: any) => {
          return (
            <MainCell
              as={NavLink}
              id={data.id}
              to={data.id}
              value={
                <span className="flex items-center">
                  {value}
                  {data?.is_default && <Status small label="Default" color="blue" className="ml-1" />}
                </span>
              }
            />
          )
        },
      },
      {
        title: 'Description',
        model: 'description',
        width: 300,
        disableSort: true,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <>
      <Page
        breakpoint={3}
        title="Organization Tracks"
        feature="organization_tracks"
        actions={
          <Button as={Link} label="Add Organization Track" type="primary" glyph="add" link="new" permission="organization_tracks.create" />
        }
      >
        <div className="grid gap-3">
          <Alert small contrast glyph="info">
            <div>
              <b>Organization Tracks</b>
            </div>

            <ul className="pl-5">
              <li>Each organization track has a corresponding workflow</li>
              <li>Configure the steps and instructions for each track</li>
              <li>Create multiple tracks and assign them to organizations as needed</li>
            </ul>
          </Alert>

          <DataTable asCard title="Organization Tracks" icon="organization_tracks" columns={columns} {...tableProps} />
        </div>
      </Page>

      <AnimatedRoutes>
        <Route path=":id" element={<OrganizationTrackOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

export default withPageError(OrganizationTracks)
