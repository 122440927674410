import React from 'react'
import { NavLink, useParams } from 'react-router-dom-v5-compat'

import { Button, Grid, HelpTagIframe, Page } from '@behavehealth/components'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { OrganizationContactsDataTable } from '@behavehealth/constructs/Contacts/OrganizationContactsDataTable'

const Contacts: React.FC = () => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['organization', resource_id, 'contacts'],
    endpoint: `/organizations/${resource_id}/contacts`,
    enabled: !!resource_id,
    localStorageKey: 'organization_contacts_v1',
  })

  return (
    <Page
      feature="contacts"
      help={<HelpTagIframe id="organization_contacts_and_communications" />}
      actions={
        <>
          <Button as={NavLink} label="Add Contact" glyph="add" type="primary" link="new" permission="contacts.create" />
        </>
      }
    >
      <Grid gap="1rem" columns="100%">
        <OrganizationContactsDataTable mainLinkAs={NavLink} to={({ id }) => id} {...tableProps} />
      </Grid>
    </Page>
  )
}

export default Contacts
