import React from 'react'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'

import { useSettings } from '../../hooks/useSettings'
import { useTreatmentPlanPermissions } from '../../constructs/TreatmentPlans/useTreatmentPlanPermissions'

type Props = {
  employeeID: string
  id?: string
}

const EmployeeNavItems = ({ employeeID, id }: Props) => {
  const basePath = `/employees/${employeeID || id}`

  const { isBehave, isManagement } = useSettings()
  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  return (
    <>
      <NavGroup label="General">
        <NavItem feature="general_info" label="General Info" to={`${basePath}/general-info`} />
        <NavItem
          showForCommunity
          showMarketing
          feature="todos"
          label="To-Do"
          to={`${basePath}/todo`}
          permission="todos.view"
          featureFlagV2="todos"
        />
        <NavItem
          showForCommunity
          showMarketing
          label="Calendar"
          to={`${basePath}/calendar`}
          feature="calendar"
          permission="events.view"
          featureFlagV2="calendar"
        />
        <NavItem
          showForCommunity
          showMarketing
          feature="files"
          label="Files"
          to={`${basePath}/files`}
          permission="employees.files.view"
          featureFlagV2="files"
        />
        <NavItem
          showMarketing
          feature="files"
          label="Admin HR Files"
          to={`${basePath}/admin-hr-files`}
          permission={isBehave || isManagement}
          featureFlagV2="files"
        />
        <NavItem
          feature="activity_timeline"
          label="Activity Timeline"
          to={`${basePath}/activity-timeline`}
          permission="activity_timeline.view"
        />
        <NavItem
          showForCommunity
          feature="form_submissions"
          label="Form Inbox"
          to={`${basePath}/form-submissions`}
          featureFlagV2="form_submissions"
          permission="form_submissions.view"
        />
        <NavItem glyph="portal" label="Login Info" to={`${basePath}/account`} />
        <NavItem feature="settings" label="Settings" to={`${basePath}/settings`} featureFlagV2={isBehave} />
      </NavGroup>

      <NavGroup label="Compliance">
        <NavItem
          showMarketing
          feature="staff_agreements"
          label="Agreements"
          to={`${basePath}/agreements`}
          permission="employee_agreements.view"
          featureFlagV2="staff_agreements"
        />
        <NavItem
          showMarketing
          feature="staff_credentials"
          label="Staff Credentials"
          to={`${basePath}/credentials`}
          permission="credentials.view"
          featureFlagV2="staff_credentials"
        />
        <NavItem feature="staff_journey" label="Staff Journey" to={`${basePath}/staff-journey`} featureFlagV2="staff_journey" />
        {/* <NavItem
          showMarketing
          feature="staff_credentials"
          label="NPIs"
          to={`${basePath}/insurance-credentials`}
          permission="credentials.view"
          featureFlagV2="staff_credentials"
        /> */}
      </NavGroup>

      <NavGroup label="Security">
        <NavItem
          showMarketing
          feature="access_lists"
          label="Staff Access"
          to={`${basePath}/staff-access`}
          permission="access_controls.view"
          featureFlagV2="staff_clients_access"
        />
        <NavItem
          showForCommunity
          showMarketing
          feature="permissions"
          label="Staff Permissions"
          to={`${basePath}/staff-permissions`}
          permission="employees.permissions.view"
          featureFlagV2="staff_permissions"
        />
        <NavItem
          showMarketing
          feature="ip_restrictions"
          label="IP Restrictions"
          to={`${basePath}/ip-restrictions`}
          featureFlagV2="staff_ip_restrictions"
        />
      </NavGroup>

      <NavGroup label="Notes">
        <NavItem
          showMarketing
          feature="safety_checks"
          label="Safety Checks"
          to={`${basePath}/safety-checks`}
          permission="safety_checks.view"
          featureFlagV2="safety_checks"
        />
        <NavItem
          showMarketing
          feature="shift_notes"
          label="Shift Notes"
          to={`${basePath}/shift-notes`}
          permission="shift_notes.view"
          featureFlagV2="shift_notes"
        />
        <NavItem
          showMarketing
          feature="recovery_coaching"
          label="Recovery Notes"
          to={`${basePath}/recovery-coaching`}
          permission="recovery_coaching.view"
          featureFlagV2="recovery_coaching"
        />
        <NavItem
          showMarketing
          feature="custom_notes"
          label="Custom Notes"
          to={`${basePath}/custom-notes`}
          permission="staff_custom_notes.view"
          featureFlagV2="staff_custom_notes"
        />
        <NavItem
          showMarketing
          feature="admin_hr_notes"
          label="Admin HR Notes"
          to={`${basePath}/admin-hr-notes`}
          permission={isBehave || isManagement}
          // featureFlag="admin_hr_custom_notes"
        />
        <NavItem
          showMarketing
          feature="peer_notes"
          label="Peer Notes"
          to={`${basePath}/peer-notes`}
          permission="peer_notes.view"
          featureFlagV2="peer_notes"
        />
        <NavItem
          showMarketing
          feature="daily_notes"
          label="Daily Notes"
          to={`${basePath}/daily-notes`}
          permission="daily_notes.view"
          featureFlagV2="daily_notes"
        />
      </NavGroup>

      <NavGroup label="Clinical">
        <NavItem
          showMarketing
          feature="clinical_assessments"
          label="Clinical Assessments"
          to={`${basePath}/clinical-assessments`}
          permission="clinical_assessments.view"
          featureFlagV2="clinical_assessments"
        />
        <NavItem
          isBeta
          showMarketing
          feature="treatment_plans"
          label="Treatment Plans"
          to={`${basePath}/treatment-plans`}
          featureFlagV2="treatment_plans"
          permission={viewSomeTreatmentPlans}
        />
        <NavItem
          showMarketing
          feature="progress_reviews"
          label="Progress Reviews"
          to={`${basePath}/progress-reviews`}
          permission="progress_reviews.view"
          featureFlagV2="progress_reviews"
        />
        <NavItem
          showMarketing
          feature="clinical_notes"
          label="Clinical Notes"
          to={`${basePath}/clinical-notes`}
          permission="clinical_notes.view"
          featureFlagV2="clinical_notes"
        />
        <NavItem
          showMarketing
          feature="clinical_measurements"
          label="Clinical Measures"
          to={`${basePath}/clinical-measurements`}
          permission="clinical_measurements.view"
          featureFlagV2="clinical_measures"
        />
        <NavItem
          showMarketing
          feature="outcome_measures"
          label="Outcome Measures"
          to={`${basePath}/outcome-measures`}
          permission="outcome_measures.view"
          featureFlagV2="outcome_measures"
        />
      </NavGroup>

      <NavGroup label="Medical">
        <NavItem
          showForCommunity
          showMarketing
          feature="test_results"
          label="Test Results"
          to={`${basePath}/test-results`}
          permission="test_results"
          featureFlagV2="test_results"
        />
        <NavItem
          showMarketing
          feature="medical_assessments"
          label="Medical Assessments"
          to={`${basePath}/medical-assessments`}
          featureFlagV2="medical_assessments"
          permission="medical_assessments.view"
        />
        <NavItem
          showMarketing
          feature="physician_orders"
          label="Physician Orders"
          to={`${basePath}/physician-orders`}
          permission="physician_orders.view"
          featureFlagV2="physician_orders"
        />
        <NavItem
          showMarketing
          feature="nursing"
          label="Nursing"
          to={`${basePath}/nursing`}
          featureFlagV2="nursing"
          permission="nursing.view"
        />
        <NavItem
          feature="progress_notes"
          label="Progress Notes"
          to={`${basePath}/progress-notes`}
          featureFlagV2="progress_notes"
          permission="progress_notes.view"
        />
      </NavGroup>
    </>
  )
}

export default EmployeeNavItems
