export const FORM_STATUSES = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  pending: {
    label: 'Pending Review',
    color: 'yellow',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'orange',
  },
  in_progress: {
    label: 'In Progress',
    color: 'blue',
  },
  approved: {
    label: 'Approved',
    color: 'paleGreen',
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
  },
  closed: {
    label: 'Closed',
    color: 'green',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
}

export const COMMENT_STATUSES = {
  issue: {
    label: 'Issue',
    color: 'orange',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
  draft: {
    label: 'Draft',
    color: 'blue',
  },
}

export const PRIMARY_SERVICES = {
  helpline: 'Helpline',
  peer_support: 'Peer Support',
  child_and_family_resources: 'Child and Family Resources',
  outpatient_counseling: 'Outpatient Counseling',
  communited_based_services: 'Communited-Based Services',
  cris_stabilization_less_than_23_hours: 'Cris Stabilization Less Than 23 Hours',
  inpatient_psychiatric: 'Inpatient Psychiatric',
  inpatient_withdrawal_management: 'Inpatient Withdrawal Management',
  residential: 'Residential',
  treatment_program_sud: 'Treatment Program SUD',
  residential_treatment_program_mh: 'Residential Treatment Program MH',
  medication_assisted_treatment_otp: 'Medication Assisted Treatment OTP',
  medication_assisted_treatment_non_otp: 'Medication Assisted Treatment Non-OTP',
  intensive_outpatient_treatment: 'Intensive Outpatient Treatment',
  supportive_housing: 'Supportive Housing',
  sober_living_housing: 'Sober Living Housing',
  ambulatory_withdrawal_management: 'Ambulatory Withdrawal Management',
  suicide_prevention: 'Suicide Prevention',
  information_and_education: 'Information and Education',
  support_groups: 'Support Groups ',
}

export const ACCREDITING_BODY = {
  carf: 'CARF',
  joint_commission: 'Joint Commission',
  narr: 'NARR',
  narr_state_affiliate: 'NARR State Affiliate',
  bhso: 'BHSO',
  oxford_houses: 'Oxford Houses',
  state_substance_use_treatment_agency: 'State substance use treatment agency',
  state_mental_health_department: 'State mental health department',
  state_department_of_health: 'State department of health',
  coa: 'COA',
  hfap: 'HFAP',
  hospital_licensing_authority: 'Hospital licensing authority',
  ncqa: 'NCQA',
  federally_qualified_health_center: 'Federally Qualified Health Center',
  samhsa_otp_certification: 'SAMHSA OTP Certification',
  dea: 'DEA',
}

export const SERVICE_TYPE = {
  emergency_crisis_and_diversionary_services: 'Emergency, Crisis, and Diversionary Services',
  hospital_inpatient_24_hour_residential: 'Hospital inpatient/24-hour residential ',
  hospital_inpatient_detoxification: 'Hospital inpatient detoxification',
  hospital_inpatient_treatment: 'Hospital inpatient treatment',
  intensive_outpatient_treatment: 'Intensive outpatient treatment',
  long_term_residential: 'Long-term residential',
  mental_health_inpatient: 'Mental Health Inpatient',
  mental_health_outpatient: 'Mental Health Outpatient',
  outpatient_detoxification: 'Outpatient detoxification',
  outpatient_day_treatment_or_partial_hospitalization: 'Outpatient day treatment or partial hospitalization',
  outpatient_methadone_buprenorphine_or_naltrexone_treatment: 'Outpatient methadone/buprenorphine or naltrexone treatment',
  recovery_housing: 'Recovery Housing',
  regular_outpatient_treatment: 'Regular Outpatient Treatment',
  residential_detoxification: 'Residential Detoxification',
  residential_24_hour_residential: 'Residential/24-hour residential',
  self_help_peer_support: 'Self-Help & Peer Support',
  short_term_residential: 'Short-term Residential',
  social_services_social_determinants_of_health: 'Social Services/Social Determinants of Health',
  substance_use_outpatient: 'Substance Use Outpatient',
  substance_use_residential_inpatient: 'Substance Use Residential/Inpatient',
  suicide_prevention_support: 'Suicide Prevention & Support',
  telehealth: 'Telehealth',
}

export const ADDITIONAL_SERVICES = {
  treatment_for_gambling_disorder: 'Treatment for gambling disorder',
  treatment_for_other_addiction_disorder: 'Treatment for other addiction disorder',
  acupuncture: 'Acupuncture',
  residential_beds_for_clients_children: "Residential beds for clients' children",
  case_management_service: 'Case management service',
  child_care_for_clients_children: "Child care for clients' children",
  domestic_violence_services_including_family_or_partner: 'Domestic violence services, including family or partner',
  early_intervention_for_hiv: 'Early intervention for HIV',
  social_skills_development: 'Social skills development',
  transportation_assistance: 'Transportation assistance',
  integrated_primary_care_services: 'Integrated primary care services',
  suicide_prevention_services: 'Suicide prevention services',
  case_management: 'Case management (create plans for treatment or recovery)',
  peer_services: 'Peer services (clients in recovery assist others in recovery)',
  housing_services: 'Housing services',
  tobacco_screening: 'Tobacco screening',
  counseling_for_smoking_tobacco: 'Counseling for smoking tobacco',
  substance_abuse_screening: 'Substance abuse screening',
  assessment_for_mental_health_disorders: 'Assessment for mental health disorders',
  comprehensive_assessment_for_substance_abuse: 'Comprehensive assessment for substance abuse',
  comprehensive_assessment_for_mental_health: 'Comprehensive assessment for mental health',
  community_outreach: 'Community outreach',
  interim_services_for_clients: 'Interim services for clients',
  breath_analyzer_or_blood_alcohol_testing: 'Breath analyzer or blood alcohol testing',
  drug_or_alcohol_urine_screening: 'Drug or alcohol urine screening',
  planning_for_discharge: 'Planning for discharge',
  aftercare_continuing_care: 'Aftercare continuing care',
  development_of_social_skills:
    'Development of social skills (communication, emotional, decision-making, negotiation, problem solving, conflict resolution, relationships)',
  help_with_social_services: 'Help with social services (education, food subsidies, health care, job training, subsidized housing)',
  employment_counseling_or_training: 'Employment counseling or training',
  domestic_violence_services_for_family_or_partner: 'Domestic violence services for family or partner',
  hiv_aids_human_immunodeficiency_virus_education_counseling_or_support:
    'HIV - AIDS - human immunodeficiency virus education counseling or support',
  hepatitis_a_b_and_c_education_counseling_or_support: 'Hepatitis A, B, and C education counseling or support',
  substance_abuse_education: 'Substance abuse education',
  mental_health_services:
    'Mental health services (clinical depression, anxiety disorder, bipolar disorder, schizophrenia, obsessive compulsive disorder,post traumatic stress disorder)',
  self_help_support_groups: 'Self-help support groups',
  treatment_for_non_substance_abuse_addiction_disorder: 'Treatment for non-substance abuse addiction disorder',
  gambling_addiction_treatment: 'Gambling addiction treatment',
  internet_addiction_treatment: 'Internet addiction treatment',
  one_on_one_counseling_sessions: 'One on one counseling sessions',
  group_counseling_sessions: 'Group counseling sessions',
  family_counseling_sessions: 'Family counseling sessions',
  counseling_for_married_couples: 'Counseling for married couples',
}

export const SUBSTANCES_TREATED = {
  alcohol: 'Alcohol',
  anti_convulsants: 'Anti-Convulsants',
  benzodiazepines: 'Benzodiazepines',
  cannabis: 'Cannabis',
  cocaine: 'Cocaine',
  dextromethorphan: 'Dextromethorphan',
  hallucinogens: 'Hallucinogens',
  heroin: 'Heroin',
  inhalants: 'Inhalants (e.g. bath salts, salvia etc.)',
  methamphetamine: 'Methamphetamine',
  non_substance: 'Non-Substance (e.g. Food, Gambling, Shopping, Sex etc.)',
  other_opioids: 'Other Opioids (e.g. Fentanyl, Oxycontin etc.)',
  other_sedatives: 'Other Sedatives (Barbiturates)',
  pcp: 'PCP',
  stimulants: 'Stimulants',
  tobacco: 'Tobacco',
}

export const MEDICAL_AND_PSYCHIATRIC_CONDITIONS = {
  suicidal_with_attempt: 'Suicidal with attempt',
  overdose: 'Overdose',
  active_psychosis: 'Active Psychosis',
}

export const SPECIAL_POPULATIONS = {
  adolescent_17_and_under: 'Adolescent (17 and under)',
  adults_18_and_older: 'Adults (18 and older)',
  alaska_native_american_indian: 'Alaska Native/American Indian',
  any_court_involvement: 'Any court involvement',
  autism: 'Autism',
  blind_visually_impaired: 'Blind & Visually Impaired',
  child_welfare_involved: 'Child Welfare Involved',
  children_12_and_under: 'Children (12 and under)',
  clients_who_have_experienced_trauma: 'Clients who have experienced trauma',
  children_who_lost_their_parents: 'Children who lost their parents',
  co_occurring_mental_and_substance_use_disorders: 'Co-occurring Mental and Substance Use Disorders',
  community_corrections: 'Community Corrections',
  criminal_justice: 'Criminal Justice',
  deaf_or_hard_of_hearing: 'Deaf or Hard of Hearing',
  department_of_corrections: 'Department of Corrections',
  eating_disorders: 'Eating Disorders',
  felons_currently_on_probation_or_parole: 'Felons currently on probation or parole',
  gender_specific_men_or_women: 'Gender-Specific: Men or Women',
  geriatric: 'Geriatric',
  hiv_aids: 'HIV/AIDS',
  homeless_housing_insecure: 'Homeless & housing insecure',
  immigrant_communities: 'Immigrant Communities',
  indigenous_peoples: 'Indigenous Peoples',
  intravenous_drug_use: 'Intravenous Drug Use',
  justice_involved: 'Justice Involved',
  lgbtqia_friendly: 'LGBTQIA+ Friendly',
  men_with_children: 'Men with Children',
  members_of_military_families: 'Members of military families',
  military_and_veterans: 'Military and Veterans',
  native_americans: 'Native Americans',
  other: 'Other',
  pregnant: 'Pregnant',
  re_entry_population: 'Re-entry population',
  registered_violent_offenders: 'Registered violent offenders',
  registered_sex_offenders: 'Registered sex offenders',
  seniors_or_older_adults: 'Seniors or older adults',
  traumatic_brain_injury: 'Traumatic Brain Injury',
  under_18: 'Under 18',
  veterans: 'Veterans',
  women_with_children: 'Women with children',
  youth_17_and_under: 'Youth (17 and under)',
  young_adults: 'Young Adults',
}

export const GENDER_AND_AGE_FOCUS = {
  adult_female: 'Adult - Female',
  adult_male: 'Adult - Male',
  children_12_and_under: 'Children 12 and Under',
  children_3_and_under: 'Children 3 and Under',
  geriatric_65_female: 'Geriatric (65+) Female',
  geriatric_65_male: 'Geriatric (65+) Male',
  young_adult_18_26_female: 'Young Adult (18 - 26) - Female',
  young_adult_18_26_male: 'Young Adult (18 - 26) - Male',
  youth_17_and_under_female: 'Youth (17 and under) - Female',
  youth_17_and_under_male: 'Youth (17 and under) - Male',
  women_only: 'Women Only',
  men_only: 'Men Only',
  gender_neutral: 'Gender Neutral',
  transgender_men: 'Transgender Men',
  transgender_women: 'Transgender Women',
  men_w_children: 'Men w/ Children',
  women_w_children: 'Women w/ Children',
  couples_w_o_children: 'Couples w/o Children',
  couples_w_children: 'Couples w/ Children',
  lgbtq: 'LGBTQ+',
  intersex: 'Intersex',
  non_binary: 'Non-Binary',
}

export const LANGUAGES_SERVED = {
  arabic: 'Arabic',
  cantonese: 'Cantonese',
  chinese: 'Chinese',
  english: 'English',
  french: 'French',
  german: 'German',
  korean: 'Korean',
  other: 'Other',
  russian: 'Russian',
  hebrew: 'Hebrew',
  spanish: 'Spanish',
  tagalog: 'Tagalog',
  vietnamese: 'Vietnamese',
  mandarin: 'Mandarin',
  yiddish: 'Yiddish',
  hindi: 'Hindi',
  italian: 'Italian',
  portuguese: 'Portuguese',
  polish: 'Polish',
  croatian: 'Croatian',
  greek: 'Greek',
  serbian: 'Serbian',
  turkish: 'Turkish',
  farsi: 'Farsi',
  lithuanian: 'Lithuanian',
  bengali: 'Bengali',
  urdu: 'Urdu',
  asl: 'ASL (American Sign Language)',
}
