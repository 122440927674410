import React from 'react'
import { keyframes } from '@emotion/react'
import * as Popover from '@radix-ui/react-popover'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../../../theme'
import { useFuse } from '../../../hooks/useFuse'
import { useGet, useUpdate, getPrefix } from '../../../hooks/useNewAPI'
import { usePortal } from '../../../hooks/usePortal'

import { PopoverMenuSearch } from '../../PopoverMenu'
import Button from '../../Button'
import Card from '../../Card'
import Glyph from '../../Glyph'
import Icon from '../../Icon'
import Grid from '../../Grid'
import Loader from '../../Loader'
import State from '../../State'
import Tooltip from '../../Tooltip'

import { DataTableCell } from '../DataTableCell'
import { EmptyCell } from './EmptyCell'
import { useStore } from '../useStore'
import { transparentize } from 'polished'

export const RecordOrganizationTracksCell = (props: any) => {
  const { canEdit, invalidate, invalidateKeys, queryKey, record, value } = props

  const updateId = record?.id

  const updateKey: any = useStore((state: any) => state.updateKey)
  const updateDeleteEndpoint: any = useStore((state: any) => state.updateDeleteEndpoint)

  const portalContainer = usePortal('default')

  const [isOpen, setIsOpen] = React.useState(false)
  const [didChange, setDidChange] = React.useState(false)
  const [menuTracks, setMenuTracks]: any = React.useState(value || [])
  const [searchTerm, setSearchTerm]: any = React.useState('')

  const menuTrackIds = menuTracks?.map?.((o) => o.id)

  const { data: organizationTracks, isLoading: isLoadingOrganizationTracks }: any = useGet({
    name: ['organization_tracks'],
    url: `/organization_tracks`,
  })

  const searchData = useFuse(organizationTracks, searchTerm, { keys: ['name', 'description'] })

  const searchIds = React.useMemo(() => {
    const result: any = []

    for (const track of searchData) {
      if (!result.includes(track?.id)) result.push(track?.id)
    }

    return result
  }, [searchData])

  const isSearchActive = size(searchTerm) > 1
  const isSearchEmpty = isSearchActive && size(searchData) === 0

  const { mutateAsync, isLoading: isUpdating }: any = useUpdate({
    name: [updateKey, updateId].flat(),
    url: `${updateDeleteEndpoint}/${updateId}`,
    invalidate: invalidate,
    invalidateKeys: [queryKey, invalidateKeys],
    onSuccess: (_data: any, variables: any, queryClient: any) => {
      if (!queryKey || !updateId || !variables.organization_tracks) return

      const prefix = getPrefix()

      // update tags in queryKey cache
      queryClient.setQueryData([prefix, queryKey].flat(), (oldData: any) => {
        if (!oldData?.data) return

        const index = oldData.data.findIndex((o) => o.id === updateId)
        if (index === -1) return

        const newData = produce(oldData, (draft: any) => {
          draft.data[index].organization_tracks = variables.organization_tracks
        })

        return newData
      })
    },
  })

  const isOrganizationTracksEmpty = size(organizationTracks) === 0
  const isCellTracksEmpty = size(value) === 0

  const rootClasses = clsx(canEdit && isOpen && 'is-open')

  const clearSearch = () => {
    setSearchTerm('')
  }

  const toggleOpen = (event) => {
    event.stopPropagation()

    setIsOpen((c) => !c)
  }

  React.useEffect(() => {
    if (!value) return
    setMenuTracks(value)
  }, [value])

  const addOrganizationTrack = (tag: any) => {
    setDidChange(true)

    const newTags = produce(menuTracks, (draft: any) => {
      draft.push(tag)
    })
    setMenuTracks(newTags)
  }

  const removeOrganizationTrack = (id: number) => {
    setDidChange(true)

    const newTags: any = produce(menuTracks, (draft: any) => {
      const index = draft.findIndex((o) => o.id === id)
      draft.splice(index, 1)
    })
    setMenuTracks(newTags)
  }

  const handleSave = () => {
    if (didChange && mutateAsync) {
      mutateAsync({
        organization_track_ids: menuTracks.map((o: any) => o.id),
        organization_tracks: menuTracks, // used in the optimistic update
      })
    }
  }

  return (
    <DataTableCell {...props} className={rootClasses} css={STYLES.cellRoot} onClick={() => setIsOpen(true)}>
      {isUpdating && (
        <div style={{ marginRight: '0.5rem' }}>
          <Loader size={18} color="blue" />
        </div>
      )}

      {!isCellTracksEmpty ? (
        <div className="tags-wrapper">
          <div className="tags-inner">
            {value?.map((track) => (
              <div key={track.id} css={STYLES.tag}>
                <Icon icon="organization_tracks" size={14} />
                {track.name}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <EmptyCell />
      )}

      {canEdit && (
        <Popover.Root asChild open={isOpen} onOpenChange={setIsOpen}>
          <div css={STYLES.root}>
            <Popover.Trigger asChild>
              <button type="button" onClick={toggleOpen} className="edit-button">
                <Glyph glyph="more_vertical" size={12} />
              </button>
            </Popover.Trigger>

            <Popover.Portal container={portalContainer}>
              <Popover.Content asChild onInteractOutside={handleSave} align="start" side="right">
                <div css={STYLES.menu}>
                  <Card css={STYLES.card}>
                    <PopoverMenuSearch value={searchTerm} onChange={setSearchTerm} onClear={clearSearch} />

                    {isOrganizationTracksEmpty || isLoadingOrganizationTracks ? (
                      <State
                        title="Organization Tracks"
                        minHeight={150}
                        emptyDescription="No Organization Tracks have been created yet"
                        emptyActions={
                          <Button
                            label="Manage Organization Tracks →"
                            size={200}
                            type="link"
                            glyph="settings"
                            link="/settings/organization-tracks"
                          />
                        }
                        icon="organization_tracks"
                        isEmpty={isOrganizationTracksEmpty}
                        isLoading={isLoadingOrganizationTracks}
                      />
                    ) : isSearchEmpty ? (
                      <State isEmpty minHeight={150} emptyDescription="No Organization Tracks found matching your search" glyph="search" />
                    ) : (
                      <>
                        <div css={STYLES.content}>
                          <Grid>
                            {organizationTracks.map((track: any) => {
                              const isAdded = menuTrackIds.includes(track.id)
                              const isSearched = searchIds?.includes(track.id)

                              if (isSearchActive && !isSearched) return null

                              return (
                                <Tooltip key={track.id} content={track.description} position="left">
                                  <div
                                    css={STYLES.row}
                                    className={clsx(isAdded && 'is-added')}
                                    style={{ '--color': track.color }}
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      event.nativeEvent.stopImmediatePropagation()

                                      if (isAdded) removeOrganizationTrack(track.id)
                                      else addOrganizationTrack(track)
                                    }}
                                  >
                                    <Icon icon="organization_tracks" size={14} css={STYLES.glyph} />

                                    <div>{track.name}</div>

                                    {isAdded && (
                                      <div className="close-button">
                                        <Glyph glyph="cross" size={10} color={COLORS.textMuted} />
                                      </div>
                                    )}
                                  </div>
                                </Tooltip>
                              )
                            })}
                          </Grid>
                        </div>
                      </>
                    )}
                  </Card>
                </div>
              </Popover.Content>
            </Popover.Portal>
          </div>
        </Popover.Root>
      )}
    </DataTableCell>
  )
}

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateY(0);
  }
`

const STYLES = {
  cellRoot: {
    paddingRight: 0,

    '& > .DataTableCell-value': {
      display: 'flex',
      alignItems: 'center',
    },

    '&.can-edit': {
      '&:hover, &.is-open': {
        cursor: 'pointer',
        boxShadow: `inset 0 0 0 1px ${COLORS.divider}`,
        background: COLORS.white,
        overflow: 'visible',
        zIndex: 10,

        '.tags-wrapper': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 'fit-content',
          padding: '0.25rem var(--padding-x)',
          background: COLORS.white,
          boxShadow: `0 0 0 1px ${COLORS.divider}, ${SHADOW(3)}`,
          borderRadius: 4,
        },

        '.tags-inner': {
          flexWrap: 'wrap',
        },

        '.edit-button': {
          display: 'flex',
        },
      },
    },

    '.tags-inner': {
      display: 'flex',
      flexWrap: 'nowrap',
      paddingRight: 1,
      margin: '-0.2rem',

      '& > *': { margin: '0.2rem' },
    },

    '.edit-button': {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      width: '1.5rem',
      position: 'absolute',
      border: 'none',
      background: 'white',
      right: 0,
      top: 1,
      bottom: 1,
      zIndex: 1,
    },
  },

  root: {
    whiteSpace: 'nowrap',
  },

  tag: {
    display: 'inline-flex',
    borderRadius: 100,
    border: `1px dashed ${transparentize(0.5, COLORS.textMuted)}`,
    padding: '0.1rem 0.5rem',
    height: '1.6rem',
    alignItems: 'center',
    background: COLORS.white,
    boxShadow: SHADOW(1),

    fontWeight: 600,
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    lineHeight: 'normal',

    svg: {
      marginRight: '0.25rem',
    },
  },

  menu: {
    padding: '0 0.25rem',
    animation: `${animation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
    outline: 'none',
  },

  card: {
    minWidth: '15rem',
    maxWidth: '20rem',
    maxHeight: 400,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'auto !important',
  },

  content: {
    overflowY: 'auto',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0.75rem',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '0.95rem',
    width: '100%',

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-added': {
      fontWeight: 500,

      '.row-swatch': {
        boxShadow: `
          0 0 0 2px ${COLORS.white},
          0 0 0 4px var(--color)
        `,
      },

      '.close-button': {
        marginLeft: 'auto',
      },

      '&:hover .close-button svg': {
        transform: 'scale3d(1.25, 1.25, 1.25)',
        fill: COLORS.red,
      },
    },
  },

  glyph: {
    marginRight: '0.25rem',
  },
}
