import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import { OrganizationRelationshipTypeSelect } from '../Organizations/OrganizationRelationshipTypeSelect'

const RELATIONSHIPS_BY_SLUG: any = {
  internal: 'internal',
  providers: 'provider',
  vendors: 'vendor',
  authorities: 'authority',
}

const RootOrganizationWorkflowBuilderOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    params,
  } = useOverlay({
    name: 'organization_workflows',
    endpoint: '/organization_workflows',
    invalidate: 'organization_workflows',
    options: props,
  })

  const { tenant, timezone } = useSettings()

  const { slug }: any = params

  const defaultRelationship = RELATIONSHIPS_BY_SLUG?.[slug]

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable}>
      <Overlay.Header icon="organization_workflows" title="Organization Workflow" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a workflow name',
                  },
                }}
              />

              <OrganizationRelationshipTypeSelect
                label="Category"
                model="category"
                defaultValue={defaultRelationship}
                validations={{
                  presence: {
                    message: 'Please select a category',
                  },
                }}
              />

              <ObjectSelector
                label="Organization Track"
                model="organization_track"
                type="organization_tracks"
                icon="organization_tracks"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => data.description}
                selectGraphic={() => <Icon icon="organization_tracks" size={20} />}
                showAvatars={false}
                validations={{
                  presence: {
                    message: 'Please select an organization track',
                  },
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Workflow"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.organization_workflows.edit"
            />

            <DeleteDialog
              title="Delete Workflow?"
              message="Are you sure you want to delete this Workflow? This action cannot be undone."
              onYes={deleteRecord}
              permission="settings.organization_workflows.edit"
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="settings.organization_workflows.edit"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const OrganizationWorkflowBuilderOverlay = withOverlayError(RootOrganizationWorkflowBuilderOverlay)
