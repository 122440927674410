import React from 'react'
import { Element } from 'react-scroll'

import { COLORS } from '../../theme'

import Button from '../Button'
import Divider from '../Divider'
import Section from '../Section'
import Tooltip from '../Tooltip'

import Checkbox from '../Forms/Checkbox'
import CheckboxGroup from '../Forms/CheckboxGroup'
import DeleteDialog from '../Dialogs/DeleteDialog'
import FormSection from '../Forms/FormSection'
import Input from '../Forms/Input'
import SmartRichTextEditor from '../Forms/SmartRichTextEditor'

const AgreementSection = (props: any) => {
  const { isEditable, data, id, idx, isFirst, isLast, remove, moveUp, moveDown, onUpdate, testKey } = props

  if (!data) return null

  if (!isEditable) {
    return (
      <>
        <Section testKey={testKey}>
          <h1
            css={{
              display: 'inline-block',
              marginBottom: '1rem',
              padding: '0.24em 0.6em',

              fontSize: '0.9rem',
              letterSpacing: 1,
              textTransform: 'uppercase',

              background: COLORS.text,
              color: COLORS.white,
              borderRadius: 5,
            }}
          >
            {data.name || `Section #${idx + 1}`}
          </h1>

          <SmartRichTextEditor maxRows={20} value={data.content} isEditable={false} />
        </Section>

        <Divider />
      </>
    )
  }

  return (
    <Element key={data.id} name={data.id}>
      <Section
        id={id}
        testKey={testKey}
        title={data.name || <span className="opacity-60">Section #{idx + 1}</span>}
        headingType="h2"
        aside={
          <>
            <DeleteDialog
              glyph="delete"
              title="Delete Section?"
              message="This action cannot be undone."
              noLabel="No, Cancel"
              yesLabel="Delete Section"
              onYes={() => remove(idx)}
            >
              <Button type="minimal" testKey="remove_section_button" label="Remove" glyph="delete" color="red" />
            </DeleteDialog>

            <Tooltip content="Move section up">
              <Button
                testKey="move_section_up_button"
                glyph="triangle_up"
                className="!p-0"
                onClick={() => moveUp(idx)}
                isDisabled={isFirst}
                hideLabel
              />
            </Tooltip>

            <Tooltip content="Move section down">
              <Button
                hideLabel
                testKey="move_section_down_button"
                glyph="triangle_down"
                className="!p-0"
                onClick={() => moveDown(idx)}
                size={300}
                isDisabled={isLast}
              />
            </Tooltip>
          </>
        }
      >
        <FormSection maxWidth="100%" className="!mt-4">
          <FormSection horizontal maxWidth="100%">
            <Input
              className="!grow-[2]"
              testKey="section_name_input"
              label="Section Name"
              validations={{
                presence: {
                  message: 'Please enter a section name',
                },
              }}
              value={data.name}
              onUpdate={(state) => onUpdate(idx, 'name', state.value)}
            />

            <CheckboxGroup label="Confirmation" trueIcon="check" falseIcon="cross" falseStyle="none">
              <Checkbox
                testKey="section_confirmation_checkbox"
                label="Require Section Confirmation"
                defaultChecked={data.should_confirm}
                value={data.should_confirm}
                onUpdate={(state) => onUpdate(idx, 'should_confirm', state.value)}
              />
            </CheckboxGroup>
          </FormSection>

          <SmartRichTextEditor
            testKey="text_editor"
            maxRows={20}
            validations={{
              presence: {
                message: 'Please add the section content',
              },
            }}
            value={data.content}
            onUpdate={(state) => onUpdate(idx, 'content', state.value)}
          />
        </FormSection>
      </Section>
    </Element>
  )
}

export default AgreementSection
