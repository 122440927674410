import React from 'react'
import { useParams } from 'react-router-dom'
import { useParams as useParamsV6 } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { usDate } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import EventOverlay from '../../../components/Overlays/pages/Calendar/EventOverlay'
import FormSection from '../../../components/Forms/FormSection'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'
import Workflow from '../../../components/Workflow/Workflow'

import { CalendarPageActions } from '../../Calendar/CalendarPageActions'

const columns = (timezone: string) => [
  {
    accessor: 'title',
    Header: 'Title',
    width: 150,
    Cell: ({ value, row }: any) => (
      <SummonOverlay overlay={<EventOverlay showBackdrop dataID={row.original.id} initialData={row.original} />}>
        <TableCell.MainLink label={value} />
      </SummonOverlay>
    ),
  },
  {
    width: 160,
    accessor: 'started_at',
    Header: 'Date',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'author',
    Header: 'Added by',
    width: 140,
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

const Events = ({ client, setStatus, timezone, useV6Router }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = useV6Router ? useParamsV6() : useParams()

  const clientID = client?.id || resource_id
  const filters = { event_type: 'event' }

  const { data, isLoading }: any = useGet({
    name: ['client', clientID, 'events', filters],
    url: `/residents/${clientID}/events`,
    params: filters,
  })

  const isEmpty = size(data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <FormSection maxWidth="100%">
      <Card>
        <Table
          icon="calendar"
          title="Events"
          showSettings={false}
          data={data}
          isLoading={isLoading}
          columns={columns(timezone)}
          emptyDescription="No events have been added yet."
        />
        <CardContent>
          <div className="flex">
            <CalendarPageActions
              useSummonOverlay
              showManageLink={false}
              showAuditLink={false}
              buttonSize={200}
              buttonDisplay="inline-flex"
              data={{ resident: client }}
              clientTrack={client?.client_track}
            />
          </div>
        </CardContent>
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

const styles = {
  dropdown: {
    display: 'inline-flex',
  },
}

export default Events
