import React from 'react'

import { ClientTagSelector } from '../Tags/ClientTagSelector'
import { ClientTrackSelector } from '../ClientTracks/ClientTrackSelector'

import ClientProfileHeader from '../../components/ClientProfileHeader'
import Flex from '../../components/Flex'
import OverlaySubHeader from '../../components/Overlay/OverlaySubHeader'

export const ClientWorkflowSubheader = ({ client }) => {
  if (!client) return null

  return (
    <OverlaySubHeader className="grid gap-2 !py-2 shadow-hard-2 relative z-[3]">
      <Flex gap="1rem" alignItems="center">
        <div>
          <ClientProfileHeader client={client} />
        </div>

        <div>
          <ClientTrackSelector clientId={client.id} activeTrack={client.client_track} />
        </div>
      </Flex>
      <ClientTagSelector client={client} />
    </OverlaySubHeader>
  )
}
