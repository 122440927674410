import {
  BASIC_INFORMATION,
  CONTACT,
  CERTIFICATIONS,
  DEMOGRAPHICS,
  FACILITY_DETAILS,
  FINANCIALS,
  POLICIES,
  TREATMENT,
  MARKETING,
  PROGRAM_OPERATIONS,
  TREATMENT_DETAILS,
  DOCUMENTATION,
  MEDICATION_MANAGEMENT,
  STAFF_DEVELOPMENT,
} from './constants'

export const DIRECTORY_SCHEMA = {
  basic_information: {
    name: 'Basic Information',
    schema: BASIC_INFORMATION,
    icon: 'general_info',
  },
  contact: {
    name: 'Contact',
    schema: CONTACT,
    icon: 'phone',
  },
  financials: {
    name: 'Financials',
    schema: FINANCIALS,
    icon: 'financials',
  },
  certifications: {
    name: 'Certifications',
    schema: CERTIFICATIONS,
    icon: 'reports',
  },
  demographics: {
    name: 'Demographics',
    schema: DEMOGRAPHICS,
    icon: 'residents',
  },
  facility_details: {
    name: 'Facility Details',
    schema: FACILITY_DETAILS,
    icon: 'enterprise',
  },
  policies: {
    name: 'Policies',
    schema: POLICIES,
    icon: 'sell',
  },
  treatment: {
    name: 'Treatment',
    schema: TREATMENT,
    icon: 'treatment_data',
  },
  marketing: {
    name: 'Marketing',
    schema: MARKETING,
    icon: 'recovery_coaching',
  },
  program_operations: {
    name: 'Program Operations',
    schema: PROGRAM_OPERATIONS,
    icon: 'checklist',
  },
  treatment_details: {
    name: 'Treatment Details',
    schema: TREATMENT_DETAILS,
    icon: 'treatment_plans',
  },
  documentation: {
    name: 'Documentation',
    schema: DOCUMENTATION,
    icon: 'files',
  },
  medication_management: {
    name: 'Medication Management',
    schema: MEDICATION_MANAGEMENT,
    icon: 'medication',
  },
  staff_development: {
    name: 'Staff Development',
    schema: STAFF_DEVELOPMENT,
    icon: 'employees',
  },
} as const
