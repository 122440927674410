import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../DataTable/DataTable'
import { MainCell } from '../../DataTable/cells/MainCell'
import { DEFAULT_FILTERS } from '../../../constructs/Filters/constants'

const CATEGORIES: any = {
  general: 'General',
  client_admission: 'Client Admission',
  recovery_coaching: 'Recovery Coaching',
  housing_activity: 'Housing Activity',
  discharge: 'Discharge',
  psych_appointment: 'Psych Appointment',
  medical_appointment: 'Medical Appointment',
  clinical_appointment: 'Clinical Appointment',
  progress_appointment: 'Progress Appointment',
  individual_therapy: 'Individual Therapy',
  group_therapy: 'Group Therapy',
  individual_peer_note: 'Individual Peer Note',
  group_peer_notes: 'Group Peer Note',
  individual_daily_note: 'Individual Daily Note',
  group_daily_notes: 'Group Daily Note',
  meds_pass: 'Meds Pass',
  urinalysis: 'Urinalysis',
  breathalyzer: 'Breathalyzer',
  vitals: 'Vitals',
  communicable_tests: 'Communicable Tests',
  bed_check: 'Bed Check',
  housing_intake: 'Housing Intake',
  show_as_busy: 'Show as Busy',
}

export const EventTemplatesDataTable = (props: any) => {
  const { to, onClick, setTableColumns, timezone, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Title',
        model: 'title',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            as={NavLink}
            to={to ? to(data) : undefined}
            onClick={onClick ? () => onClick(data) : undefined}
            value={value}
            avatar={data.avatar}
          />
        ),
      },
      {
        title: 'Client Tracks',
        model: 'client_tracks',
        type: 'record_client_tracks',
        featureFlag: 'client_tracks',
        editPermission: 'client_tracks.edit',
        disableSort: true,
      },
      {
        title: 'Organization Tracks',
        model: 'organization_tracks',
        type: 'record_organization_tracks',
        featureFlag: 'organization_tracks',
        editPermission: 'organization_tracks.edit',
        disableSort: true,
      },
      {
        title: 'Category',
        model: 'category',
        formatValue: ({ value }: any) => {
          return CATEGORIES[value] || null
        },
      },
      {
        title: 'Public Description',
        model: 'public_description',
        type: 'rich_text',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to, onClick],
  )

  const finalColumns = setTableColumns ? setTableColumns(to, timezone) : columns

  return (
    <DataTable
      asCard
      title="Event Templates"
      testKey="event_templates_data_table"
      icon="calendar"
      columns={finalColumns}
      filtersConfig={FILTERS_CONFIG}
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  title: {
    label: 'Title',
    type: 'string',
  },
  client_tracks: DEFAULT_FILTERS.client_tracks,
  organization_tracks: DEFAULT_FILTERS.organization_tracks,
  category: {
    label: 'Category',
    type: 'multi_select',
    options: Object.entries(CATEGORIES).map(([value, label]) => ({ value, label })),
  },
  public_description: {
    label: 'Public Description',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
