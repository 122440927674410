import React from 'react'
import startCase from 'lodash/startCase'

import { fullname, age } from '../utils/functions'
import { Client } from '../declarations/Client'

import ClientStatus from './Statuses/ClientStatus'
import { Text } from './Typography'
import Flex from './Flex'

type Props = {
  client: Client
}

const ClientProfileHeader: React.FC<Props> = ({ client }) => {
  if (!client) return null

  return (
    <Flex gap={6}>
      <Text avatar={client.avatar} label={fullname(client)} />
      <ClientStatus status={client.status} />
      <Text>{`${startCase(client.sex)} • #${client.behave_id}`}</Text>
    </Flex>
  )
}

export default ClientProfileHeader
