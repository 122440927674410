import React from 'react'
import startCase from 'lodash/startCase'
import size from 'lodash/size'

import { address, monthsBetweenRelative } from '../../utils/functions'
import { ICONS } from '../../theme'
import { RECURRING_DAYS } from '../../utils/recurrence'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordions from '../../components/Accordions'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ColorSelector from '../../components/Forms/ColorSelector'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Links from '../../components/Forms/Links'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Subtasks from '../../components/Forms/Subtasks'
import TimeInputSelector from '../../components/Forms/TimeInputSelector/TimeInputSelector'
import URLInput from '../../components/Forms/URLInput'

const RootAuthorityOrganizationTodoBuilderOverlay = (props: any) => {
  const { close, data, form, initialData, isInvalid, isSaving, onValidationUpdate, save } = useOverlay({
    name: 'todo',
    endpoint: '/events/builder',
    invalidate: 'todos',
    options: props,
    disableParentRequest: true,
    headers: {
      'X-Request-Level': 'global',
    },
  })

  const { timezone } = useSettings()

  const [recurringFrequency, setRecurringFrequency]: any = React.useState('never')
  const [startedAtDate, setStartedAtDate]: any = React.useState()
  const [repeatFromDate, setRepeatFromDate]: any = React.useState()
  const [repeatUntilDate, setRepeatUntilDate]: any = React.useState(null)
  const [formData, setFormData] = React.useState(initialData)

  const monthsDiff = monthsBetweenRelative(repeatFromDate, repeatUntilDate, timezone)
  const isMonthsDiffValid = recurringFrequency === 'never' ? true : !!monthsDiff && monthsDiff <= 12

  const hasOrganizations = size(formData?.organization_ids) > 0

  return (
    <Overlay maxWidth={37} onClose={close}>
      <Overlay.Header icon="tasks" title="Add To-Do" help={<HelpTagIframe id="add_todo" />} />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...initialData,
            ...data,
            recurringFrequency: 'never',
          }}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
          linked={{
            event_type: 'task',
          }}
          onUpdate={setFormData}
        >
          <Section>
            <FormSection layout="vertical" maxWidth="100%">
              <Input
                autoFocus
                label="Title"
                model="title"
                validations={{
                  presence: {
                    message: 'Please enter a task title',
                  },
                }}
              />

              <Flex gap="1rem">
                <div className="!flex-auto">
                  <Select model="priority" label="Priority" fullWidth defaultValue="normal">
                    <Option label="Low" value="low" />
                    <Option label="Normal" value="normal" />
                    <Option label="High" value="high" />
                  </Select>
                </div>

                <ColorSelector label="Color" model="color" />
              </Flex>

              <MiniRichTextEditor useDictation useQuickText label="Public Description" model="public_description" />
            </FormSection>
          </Section>

          <Section>
            <Accordions>
              <Accordions.Item isOpen title="Date & Time">
                <FormSection>
                  <Select
                    label="Repeat"
                    model="recurring_frequency"
                    defaultValue="never"
                    onUpdate={(model: any) => {
                      setRecurringFrequency(model.value)
                    }}
                  >
                    <Option label="Never" value="never" />
                    <Option label="Daily" value="daily" />
                    <Option label="Weekly" value="weekly" />
                    <Option label="Bi-Weekly" value="biweekly" />
                    <Option label="Monthly" value="monthly" />
                    <Option label="Quarterly" value="quarterly" />
                    <Option label="Yearly" value="yearly" />
                  </Select>

                  <ContextShow when="recurring_frequency" is="never">
                    <DateInput
                      defaultToNow
                      label="Due Day"
                      model="started_at"
                      onUpdate={(value: any) => {
                        setStartedAtDate(value)
                      }}
                      validations={{
                        presence: {
                          message: 'Please select an todo date',
                        },
                      }}
                    />
                  </ContextShow>

                  {recurringFrequency !== 'never' && (
                    <>
                      <Flex stretchChildrenX gap="1rem">
                        <DateInput
                          defaultToTomorrow
                          label="Repeat From"
                          model="repeat_from"
                          onUpdate={({ date }: any) => {
                            setRepeatFromDate(date)
                          }}
                          validations={{
                            presence: {
                              message: 'Please select when the repeating todo should start',
                            },
                          }}
                        />

                        <DateInput
                          label="Repeat Until"
                          model="repeat_until"
                          onUpdate={({ date }: any) => {
                            setRepeatUntilDate(date)
                          }}
                          validations={{
                            presence: {
                              message: 'Please select when the repeating todo should end',
                            },
                            datetime: {
                              earliest: repeatFromDate?.date,
                              message: 'Please enter a future date',
                            },
                          }}
                        />
                      </Flex>

                      {monthsDiff > 12 && (
                        <Alert small glyph="warning" type="warning">
                          <div>The to-do will repeat for more than a year ({Math.round(monthsDiff)} months).</div>

                          <div>Please select a shorter duration.</div>
                        </Alert>
                      )}
                    </>
                  )}

                  {parseInt(repeatFromDate?.day) >= 29 && (
                    <ContextShow when="recurring_frequency" within={['monthly', 'quarterly']}>
                      <Alert glyph="info">This todo will be repeat only on months with a {repeatFromDate.day} day.</Alert>
                    </ContextShow>
                  )}

                  <ContextShow when="recurring_frequency" within={['weekly', 'biweekly']}>
                    <div>
                      <Label label="Repeat On" />
                      <Flex nowrap>
                        {RECURRING_DAYS.weekly.map((day: string) => (
                          <Checkbox
                            key={day}
                            label={day.charAt(0)}
                            model={`weekly_repeat_days.${day?.toLowerCase()}`}
                            css={styles.dayCheckbox}
                          />
                        ))}
                      </Flex>
                    </div>
                  </ContextShow>

                  <ContextShow when="is_all_day" is={false}>
                    <TimeInputSelector
                      defaultToNow
                      label="Due By"
                      model="start_time"
                      validations={{
                        presence: {
                          message: 'Please select when the todo starts',
                        },
                      }}
                    />
                  </ContextShow>

                  <Checkbox label="All Day Task" model="is_all_day" defaultValue={false} />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item isOpen title="Tasks">
                <Subtasks endpoint="events" model="subtasks" taskId={data?.id} />
              </Accordions.Item>

              <Accordions.Item isOpen title="Staff">
                <FormSection>
                  <MultiOverlaySelector
                    model="employees"
                    type="employees.active"
                    icon={ICONS.employees}
                    // selectTitle={(data: any) => data?.name}
                    // selectDescription={(data: any) => startCase(data?.position)}
                  />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Organizations">
                <FormSection>
                  <MultiOverlaySelector
                    label="Organizations"
                    model="organizations"
                    type="organizations.all"
                    icon={ICONS.organizations}
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => startCase(data.status)}
                  />

                  <MultiOverlaySelector
                    label="Organization Contacts"
                    description="Select Contacts from the Organizations selected above"
                    model="organization_contacts"
                    type="all_organization_contacts"
                    dependentValue={formData?.organization_ids}
                    icon={ICONS.organizations}
                    isDisabled={!hasOrganizations}
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => startCase(data.status)}
                  />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Notifications">
                <FormSection>
                  <Alert glyph="info" type="positive">
                    Notifications are being sent to the above <b>Staff</b>
                  </Alert>

                  <Label label="Send a Notification now with details about this To-Do:" className="!pb-0" />

                  <CheckboxGroup layout="vertical-dense">
                    <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                    <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
                  </CheckboxGroup>

                  <Label label="Time-based Reminders:" className="!pb-0" />

                  <Checkbox label="Send SMS and Email Reminders" model="should_send_reminders" />

                  <ContextShow when="should_send_reminders" is={true}>
                    <div className="!pl-6">
                      <CheckboxGroup
                        label="Send Reminders Options"
                        layout="vertical-dense"
                        validations={{
                          presence: {
                            message: 'Please select at least one option',
                          },
                        }}
                      >
                        <Checkbox label="Ninety days before the event" model="send_reminders.ninety_days" />
                        <Checkbox label="Sixty days before the event" model="send_reminders.sixty_days" />
                        <Checkbox label="Thirty days before the event" model="send_reminders.thirty_days" />
                        <Checkbox label="Three days before the event" model="send_reminders.three_days" />
                        <Checkbox label="Two days before the event" model="send_reminders.two_days" />
                        <Checkbox label="One day before the event" model="send_reminders.one_day" />
                        <Checkbox label="Three hours before the event" model="send_reminders.three_hours" />
                        <Checkbox label="Two hours before the event" model="send_reminders.two_hours" />
                        <Checkbox label="One hour before the event" model="send_reminders.one_hour" />
                        <Checkbox label="15 min before event" model="send_reminders.15_minutes" />
                      </CheckboxGroup>
                    </div>
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Place">
                <FormSection>
                  <Select label="Meeting Place" model="meeting_place" defaultValue="none" className="!flex-1">
                    <Option label="None" value="none" />
                    <Option label="Online" value="online" />
                    <Option label="Housing" value="property" />
                    <Option label="Office" value="office" />
                    <Option label="Organization" value="organization" />
                    <Option label="Other" value="other" />
                  </Select>

                  {formData?.meeting_place === 'online' && (
                    <>
                      <Checkbox
                        defaultChecked
                        falseIcon="cross"
                        falseStyle="none"
                        label=" "
                        description="I acknowledge it is my responsibility to secure this external meeting room service that is outside the control of Behave Health Corp. This includes any requirements related to HIPAA. If you have any questions email us at contact@behavehealth.com"
                        model="responsibility_acknowledgement"
                      />

                      <URLInput
                        label="Meeting Link"
                        model="meeting_url"
                        isDisabled={formData?.responsibility_acknowledgement !== true}
                        validations={{
                          presence: {
                            message: 'Please enter a meeting link',
                          },
                        }}
                      />
                    </>
                  )}

                  <ContextShow when="meeting_place" is="property">
                    <ObjectSelector
                      icon={ICONS.properties}
                      label="Housing"
                      type="properties"
                      model="house"
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select a location',
                        },
                      }}
                    />

                    <ObjectSelector
                      icon={ICONS.rooms}
                      label="Room (optional)"
                      type="property.rooms"
                      model="room"
                      dependent="house_id"
                      disableUnless="house_id"
                      selectTitle={(data: any) => data.name}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" is="office">
                    <ObjectSelector
                      icon={ICONS.properties}
                      label="Office"
                      type="offices"
                      model="house"
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select a location',
                        },
                      }}
                    />

                    <ObjectSelector
                      icon={ICONS.rooms}
                      label="Room (optional)"
                      type="property.rooms"
                      model="room"
                      dependent="house_id"
                      disableUnless="house_id"
                      selectTitle={(data: any) => data.name}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" is="organization">
                    <ObjectSelector
                      label="Organization"
                      model="organization"
                      type="organizations"
                      icon={ICONS.organizations}
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" not="none">
                    <MiniRichTextEditor useQuickText label="More Details" model="place_details" />
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Links">
                <Links model="urls" />
              </Accordions.Item>

              <Accordions.Item title="Notes">
                <FormSection>
                  <MiniRichTextEditor useQuickText label="Internal Notes" model="internal_notes" />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Attachments" description="Upload the files related to this todo">
                <FormSection layout="vertical">
                  <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
                </FormSection>
              </Accordions.Item>
            </Accordions>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          isDisabled={isInvalid || !isMonthsDiffValid}
          flex="100 1 auto"
          permission="todos.create"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  dayCheckbox: {
    width: 'auto !important',
    flex: '1 1 auto !important',
    marginLeft: 4,
    marginRight: 4,

    '@media (max-width: 550px)': {
      marginLeft: 2,
      marginRight: 2,
    },

    '&:first-of-type': { marginLeft: 0 },
    '&:last-of-type': { marginRight: 0 },

    label: {
      fontSize: '0.9rem',

      '@media (max-width: 550px)': {
        padding: '0.5em 0.4em',
        flexDirection: 'column',
      },
    },

    '.check-element': {
      width: 18,
      height: 18,

      '@media (max-width: 550px)': {
        margin: '0 0 0.4rem 0',
      },
    },
  },

  meetingIcon: {
    marginLeft: 'auto',
  },
}

export const AuthorityOrganizationTodoBuilderOverlay = withOverlayError(RootAuthorityOrganizationTodoBuilderOverlay)
