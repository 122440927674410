import React from 'react'
import merge from 'lodash/merge'
import produce from 'immer'
import size from 'lodash/size'

import { daysBetween, usDateTime } from '../../utils/functions'
import { useGet, useCreate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'
import withSettings from '../../hocs/withSettings'

import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import DataList from '../../components/DataList'
import Dialog from '../../components/Dialog'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Workflow from '../../components/Workflow/Workflow'

import Accept from './common/Accept'
import AccessLists from './common/AccessLists'
import Agreements from './common/Agreements'
import Applications from './common/Applications'
import ArrivalDetails from './common/ArrivalDetails'
import BedAssignment from './common/BedAssignment'
import CareTeam from './common/CareTeam'
import ClinicalMeasures from './common/ClinicalMeasures'
import ContactDetails from './common/ContactDetails'
import Contacts from './common/Contacts'
import Events from './common/Events'
import Financials from './common/Financials'
import InsuranceEvobs from './common/InsuranceEvobs'
import InsuranceFullVobs from './common/InsuranceFullVobs'
import InsurancePolicies from './common/InsurancePolicies'
import NotifyPayers from './common/NotifyPayers'
import NotifySignees from './common/NotifySignees'
import OutcomeMeasures from './common/OutcomeMeasures'
import PreScreen from './common/PreScreen'
import Programs from './common/Programs'
import RecoveryNotes from './common/RecoveryNotes'
import ReferralDetails from './common/ReferralDetails'
import ServiceEpisodes from './common/ServiceEpisodes'
import Todos from './common/Todos'
import PersonalDetails from './common/PersonalDetails'
import HomeAddress from './common/HomeAddress'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import { SecureMessage } from './common/SecureMessage'
import { ClientWorkflowSubheader } from './ClientWorkflowSubheader'

export const ACCEPT_STEPS: any = {
  arrival_details: {
    label: 'Arrival Details',
    isRequired: true,
  },
  applications: {
    label: 'Applications',
    isRequiredOptional: true,
    featureFlagV2: 'admissions',
  },
  bed_assignment: {
    label: 'Bed Assignment',
    isRequiredOptional: true,
    featureFlagV2: 'bed_management',
  },
  service_episodes: {
    label: 'Service Episodes',
    isRequiredOptional: true,
    featureFlagV2: 'service_episodes',
  },
  pre_screen: {
    label: 'Pre-Screen',
    isRequiredOptional: true,
    featureFlagV2: 'clinical_assessments',
  },
  programs_phases: {
    label: 'Programs Phases',
    isRequiredOptional: true,
    featureFlagV2: 'programs',
  },
  personal_details: {
    label: 'Personal Details',
    isRequiredOptional: true,
  },
  contact_details: {
    label: 'Contact Details',
  },
  home_address: {
    label: 'Home Address',
    isRequiredOptional: true,
  },
  contacts: {
    label: 'Contacts',
    isRequiredOptional: true,
    featureFlagV2: 'contacts',
  },
  referral_details: {
    label: 'Referral Details',
  },
  care_team: {
    label: 'Care Team',
    isRequiredOptional: true,
    featureFlagV2: 'care_team',
  },
  agreements: {
    label: 'Agreements',
    isRequiredOptional: true,
    featureFlagV2: 'client_agreements',
  },
  notify_signees: {
    label: 'Notify Signees',
    isRequiredOptional: true,
    featureFlagV2: 'client_agreements',
  },
  financials: {
    label: 'Financials',
    isRequiredOptional: true,
    featureFlagV2: 'financials',
  },
  notify_payers: {
    label: 'Notify Payers',
  },
  access_lists: {
    label: 'Access Lists',
    featureFlagV2: 'staff_clients_access',
  },
  todos: {
    label: 'Todos',
    isRequiredOptional: true,
    featureFlagV2: 'todos',
  },
  events: {
    label: 'Events',
    isRequiredOptional: true,
    featureFlagV2: 'calendar',
  },
  insurance_policies: {
    label: 'Insurance Policies',
    isRequiredOptional: true,
    featureFlagV2: 'insurance_policies',
  },
  insurance_evobs: {
    label: 'Insurance eVOBs',
    featureFlagV2: 'evobs',
  },
  insurance_full_vobs: {
    label: 'Insurance Full VOBs',
    featureFlagV2: 'full_vobs',
  },
  recovery_notes: {
    label: 'Recovery Notes',
    isRequiredOptional: true,
    featureFlagV2: 'recovery_coaching',
  },
  clinical_measures: {
    label: 'Clinical Measures',
    isRequiredOptional: true,
    featureFlagV2: 'clinical_measures',
  },
  outcome_measures: {
    label: 'Outcome Measures',
    isRequiredOptional: true,
    featureFlagV2: 'outcome_measures',
  },
  accept: {
    label: 'Accept',
    isMandatory: true,
  },
}

const stepsReducer = (state: any, payload: any) => {
  const { step, status } = payload
  if (!status || !state.hasOwnProperty(step)) return state

  return produce(state, (draft: any) => {
    draft[step] = status
  })
}

const RootAcceptForAdmission = (props: any) => {
  const { match, location, history, online, timezone } = props
  const { resource_id } = match.params

  const { tenant, isBehave, isSelfServiceBilling } = useSettings()

  const { data: client, isLoading }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const initialSteps = React.useMemo(() => {
    const result: any = {}

    for (const step of Object.keys(ACCEPT_STEPS)) {
      result[step] = 'todo'
    }

    return result
  }, [ACCEPT_STEPS])

  const [steps, setSteps] = React.useReducer(stepsReducer, initialSteps)
  const [data, setData] = React.useState({ occupancy: client?.current_bed_occupancy || client?.next_bed_occupancy })

  const clientName = client?.first_name
  const canAccept = data?.estimated_arrival

  const arrivalDate = data?.estimated_arrival
  const dischargeDate = data?.estimated_discharge
  const isIndefinite = data?.occupancy?.period_type === 'indefinite'

  const { mutateAsync, isLoading: isAccepting }: any = useCreate({
    name: ['client', resource_id],
    url: `/residents/${resource_id}/accept`,
    invalidateKeys: ['admissions', 'clients', ['client', resource_id]],
  })

  const updateData = (newValue: any) => {
    const newData = produce(data, (draft: any) => {
      draft = merge(draft, newValue)
    })

    setData(newData)
  }

  React.useEffect(() => {
    if (!tenant) return

    // if the tenant has the right feature flags, load up the client as billable
    if (tenant.feature_flags?.['insurance_claims'] === true) {
      updateData({ is_insurance_billable: true })
    }
  }, [tenant])

  if (!client || isLoading) return <OverlayLoader />

  const accept = async () => {
    const payload = produce(data, (draft: any) => {
      // remove place object
      if (draft?.occupancy?.place) delete draft.occupancy.place
      if (draft?.referral_in) delete draft.referral_in
      if (draft?.referral_out) delete draft.referral_out
      if (draft?.referral_in_contact) delete draft.referral_in_contact
      if (draft?.referral_out_contact) delete draft.referral_out_contact
    })

    await mutateAsync(payload)
    close()
  }

  const close = () => {
    const path = location.pathname
    history.push(path.substr(0, path.lastIndexOf('/')))
  }

  const options = client?.client_workflow?.options || tenant?.client_workflow_options

  return (
    <Overlay
      fullheight
      showBackdrop
      maxWidth={52}
      position="center"
      onClose={close}
      closeWrapper={(closeElement: any) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost if not saved on the Review & Save step"
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={close}
        >
          {closeElement}
        </Dialog>
      )}
    >
      <Overlay.Header glyph="tick_circle" title={`Accept ${clientName} For Admission`} />

      <ClientWorkflowSubheader client={client} />

      <Overlay.Content>
        <Workflow
          name="Accept Applicant"
          config={ACCEPT_STEPS}
          stepStatuses={steps}
          visibilityOptions={options?.accept}
          requiredOptions={options?.accept_required_steps}
          stepInstructions={options?.accept_instructions}
        >
          {/* Arrival Details */}
          <Workflow.Panel step="arrival_details">
            <Workflow.Header title="Arrival Details" after={<Workflow.Status status={steps.arrival_details} />} />
            <Workflow.Content>
              <ArrivalDetails
                data={data}
                client={client}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'arrival_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Applications */}
          <Workflow.Panel step="applications" permission="applications.view" featureFlagV2="admissions">
            <Workflow.Header title="Applications" after={<Workflow.Status status={steps.applications} />} />
            <Workflow.Content>
              <Applications
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'applications', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Bed Assignment */}
          <Workflow.Panel showForCommunity step="bed_assignment" featureFlagV2="bed_management">
            <Workflow.Header title="Bed Assignment" after={<Workflow.Status status={steps.bed_assignment} />} />
            <Workflow.Content>
              <BedAssignment
                data={data}
                isRequired={false}
                hasCurrentOccupancy={!!client.current_bed_occupancy || !!client.next_bed_occupancy}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'bed_assignment', status })
                }}
                defaultStatus="reserved"
                defaultFromDate={data?.estimated_arrival}
                defaultUntilDate={data?.estimated_discharge}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Service Episodes */}
          <Workflow.Panel
            step="service_episodes"
            permission={isBehave ? true : isSelfServiceBilling ? 'service_episodes.view' : false}
            featureFlagV2="service_episodes"
          >
            <Workflow.Header title="Service Episodes" after={<Workflow.Status status={steps.service_episodes} />} />
            <Workflow.Content>
              <ServiceEpisodes
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'service_episodes', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Pre-Screen */}
          <Workflow.Panel step="pre_screen" permission="clinical_assessments.view" featureFlagV2="clinical_assessments">
            <Workflow.Header title="Pre-Screen" after={<Workflow.Status status={steps.pre_screen} />} />
            <Workflow.Content>
              <PreScreen
                data={data}
                client={client}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'pre_screen', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Programs & Phases */}
          <Workflow.Panel step="programs_phases" featureFlagV2="programs">
            <Workflow.Header title="Programs" after={<Workflow.Status status={steps.programs_phases} />} />
            <Workflow.Content>
              <Programs
                match={match}
                setStatus={(status: string) => {
                  setSteps({ step: 'programs_phases', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Personal Details */}
          <Workflow.Panel step="personal_details">
            <Workflow.Header title="Personal Details" after={<Workflow.Status status={steps.personal_details} />} />
            <Workflow.Content>
              <PersonalDetails
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'personal_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Contact Details */}
          <Workflow.Panel step="contact_details">
            <Workflow.Header title="Contact Details" after={<Workflow.Status status={steps.contact_details} />} />
            <Workflow.Content>
              <ContactDetails
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'contact_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Home Address */}
          <Workflow.Panel step="home_address">
            <Workflow.Header title="Home Address" after={<Workflow.Status status={steps.home_address} />} />
            <Workflow.Content>
              <HomeAddress
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'home_address', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Contacts */}
          <Workflow.Panel step="contacts" featureFlagV2="contacts">
            <Workflow.Header title="Contacts" after={<Workflow.Status status={steps.contacts} />} />
            <Workflow.Content>
              <Contacts
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'contacts', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Referral Details */}
          <Workflow.Panel step="referral_details">
            <Workflow.Header title="Referrals Details" after={<Workflow.Status status={steps.referral_details} />} />
            <Workflow.Content>
              <ReferralDetails
                client={client}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'referral_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* <Workflow.Panel  step="referral_details">
            <Workflow.Header title="Referrals Details" after={<Workflow.Status status={steps.referral_details} />} />
            <Workflow.Content>
              <ReferralDetails
                client={client}
                timezone={timezone}
                data={data}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'referral_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel> */}

          {/* Care Team */}
          <Workflow.Panel showForCommunity step="care_team" featureFlagV2="care_team">
            <Workflow.Header title="Care Team" after={<Workflow.Status status={steps.care_team} />} />
            <Workflow.Content>
              <CareTeam
                setStatus={(status: string) => {
                  setSteps({ step: 'care_team', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Agreements */}
          <Workflow.Panel step="agreements" featureFlagV2="client_agreements">
            <Workflow.Header title="Agreements" after={<Workflow.Status status={steps.agreements} />} />
            <Workflow.Content>
              <Agreements
                match={match}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'agreements', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Notify Signees */}
          <Workflow.Panel step="notify_signees" featureFlagV2="client_agreements">
            <Workflow.Header title="Notify Signees" after={<Workflow.Status status={steps.notify_signees} />} />
            <Workflow.Content>
              <NotifySignees
                match={match}
                setStatus={(status: string) => {
                  setSteps({ step: 'notify_signees', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Financials */}
          <Workflow.Panel step="financials" featureFlagV2="financials">
            <Workflow.Header title="Financials" after={<Workflow.Status status={steps.financials} />} />
            <Workflow.Content>
              <Financials
                match={match}
                location={location}
                setStatus={(status: string) => {
                  setSteps({ step: 'financials', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Staff Access Lists */}
          <Workflow.Panel step="access_lists" featureFlagV2="staff_clients_access">
            <Workflow.Header title="Staff Access Lists" after={<Workflow.Status status={steps.access_lists} />} />
            <Workflow.Content>
              <AccessLists
                match={match}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'access_lists', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Todos */}
          <Workflow.Panel showForCommunity step="todos" featureFlagV2="todos">
            <Workflow.Header title="To-Do's" after={<Workflow.Status status={steps.todos} />} />
            <Workflow.Content>
              <Todos
                client={client}
                match={match}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'todos', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Events */}
          <Workflow.Panel showForCommunity step="events" featureFlagV2="calendar">
            <Workflow.Header title="Events" after={<Workflow.Status status={steps.events} />} />
            <Workflow.Content>
              <Events
                client={client}
                match={match}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'events', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Insurance Policies */}
          <Workflow.Panel step="insurance_policies" permission="insurance_policies.view" featureFlagV2="insurance_policies">
            <Workflow.Header title="Insurance Policies" after={<Workflow.Status status={steps.insurance_policies} />} />
            <Workflow.Content>
              <InsurancePolicies
                match={match}
                timezone={timezone}
                client={client}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'insurance_policies', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* eVOBs */}
          <Workflow.Panel step="insurance_evobs" permission="insurance_evobs.view" featureFlagV2="evobs">
            <Workflow.Header title="eVOBs" after={<Workflow.Status status={steps.insurance_evobs} />} />
            <Workflow.Content>
              <InsuranceEvobs
                match={match}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'insurance_evobs', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Full VOBs */}
          <Workflow.Panel step="insurance_full_vobs" permission="insurance_evobs.view" featureFlagV2="full_vobs">
            <Workflow.Header title="Full VOBs" after={<Workflow.Status status={steps.insurance_full_vobs} />} />
            <Workflow.Content>
              <InsuranceFullVobs
                match={match}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'insurance_full_vobs', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Recovery Notes */}
          <Workflow.Panel step="recovery_notes" permission="recovery_coaching.view" featureFlagV2="recovery_coaching">
            <Workflow.Header title="Recovery Notes" after={<Workflow.Status status={steps.recovery_notes} />} />
            <Workflow.Content>
              <RecoveryNotes
                match={match}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'recovery_notes', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Outcome Measures */}
          <Workflow.Panel step="clinical_measures" permission="clinical_measurements.view" featureFlagV2="clinical_measures">
            <Workflow.Header title="Clinical Measures" after={<Workflow.Status status={steps.clinical_measures} />} />
            <Workflow.Content>
              <ClinicalMeasures
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'clinical_measures', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Outcome Measures */}
          <Workflow.Panel step="outcome_measures" permission="outcome_measures.view" featureFlagV2="outcome_measures">
            <Workflow.Header title="Outcome Measures" after={<Workflow.Status status={steps.outcome_measures} />} />
            <Workflow.Content>
              <OutcomeMeasures
                match={match}
                timezone={timezone}
                client={client}
                subcategories={['forse_admit_survey', 'forse_demographic_information']}
                setStatus={(status: string) => {
                  setSteps({ step: 'outcome_measures', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Accept */}
          <Workflow.Panel step="accept">
            <Workflow.Header title={`Accept ${clientName}`} />
            <Workflow.Content>
              <AcceptStep
                accept={accept}
                arrivalDate={arrivalDate}
                canAccept={canAccept}
                clientName={clientName}
                data={data}
                dischargeDate={dischargeDate}
                isAccepting={isAccepting}
                isIndefinite={isIndefinite}
                timezone={timezone}
                updateData={updateData}
                steps={steps}
              />
            </Workflow.Content>
          </Workflow.Panel>
        </Workflow>
      </Overlay.Content>
    </Overlay>
  )
}

const AcceptStep = (props: any) => {
  const { accept, arrivalDate, canAccept, clientName, data, dischargeDate, isAccepting, isIndefinite, timezone, updateData } = props

  const { isAllCompleted }: any = React.useContext(Workflow.Context)

  return (
    <FormSection maxWidth="100%">
      {!canAccept && (
        <Alert contrast type="warning" glyph="warning">
          Please set an estimated arrival date to accept {clientName}.
        </Alert>
      )}

      {!isAllCompleted && (
        <Alert contrast glyph="warning" type="warning">
          Please complete all required steps.
        </Alert>
      )}

      {canAccept && isAllCompleted && (
        <>
          <DataList>
            <DataList.Item label="Est. Arrival Date" value={usDateTime(arrivalDate, timezone)} />
            <DataList.Item label="Est. Discharge Date" value={usDateTime(dischargeDate, timezone)} />
            <DataList.Item label="Est. Length of Stay" value={`${daysBetween(dischargeDate, arrivalDate, timezone)} days`} />

            {data?.occupancy?.place ? (
              <>
                <DataList.Item
                  label="Location"
                  value={
                    <Flex centerY gap="0.4rem">
                      <Avatar src={data.occupancy?.place?.house?.avatar} initials={data.occupancy?.place?.house?.name} size={24} />
                      <div>{data.occupancy?.place?.house?.name}</div>
                    </Flex>
                  }
                />

                <DataList.Item
                  label="Bed"
                  value={
                    <Flex centerY gap="0.6rem">
                      <div>
                        <Flex nowrap centerY gap="0.4rem">
                          <Icon icon="floors" size={20} />
                          <div>{data?.occupancy?.place?.floor?.name}</div>
                        </Flex>
                      </div>

                      <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                      <div>
                        <Flex nowrap centerY gap="0.4rem">
                          <Icon icon="rooms" size={20} />
                          <div>{data?.occupancy?.place?.room?.name}</div>
                        </Flex>
                      </div>

                      <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                      <div>
                        <Flex nowrap centerY gap="0.4rem">
                          <Icon icon="beds" size={20} />
                          <div>{data?.occupancy?.place?.name}</div>
                        </Flex>
                      </div>
                    </Flex>
                  }
                />

                <DataList.Item
                  label={data.occupancy?.status === 'occupied' ? 'Occupied From' : 'Reserved From'}
                  value={usDateTime(data.occupancy?.started_at, timezone)}
                />
                <DataList.Item
                  label={data.occupancy?.status === 'occupied' ? 'Occupied Until' : 'Reserved Until'}
                  value={isIndefinite ? 'Discharged' : usDateTime(data.occupancy?.ended_at, timezone)}
                />
              </>
            ) : (
              <DataList.Item label="Bed Assignment" value="N/A" />
            )}
          </DataList>

          <Accept data={data} setData={updateData} />

          <SecureMessage data={data} setData={updateData} category="accept" />

          <Button
            label={`Accept ${clientName}`}
            glyph="check_in"
            type="primary"
            color="green"
            onClick={accept}
            isLoading={isAccepting}
            isDisabled={!canAccept}
          />
        </>
      )}
    </FormSection>
  )
}

export const AcceptForAdmission = withOverlayError(withSettings(RootAcceptForAdmission))
