import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { DEFAULT_FILTERS } from '../../constructs/Filters/constants'
import { QuickTextCategoryStatus, STATUSES } from './QuickTextCategoryStatus'

export const QuickTextDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} to={to ? to(data) : undefined} value={value} />,
      },
      {
        title: 'Visibility',
        model: 'category',
        width: 120,
        formatValue: ({ value }: any) => <QuickTextCategoryStatus status={value} />,
      },
      {
        title: 'Category',
        model: 'subcategory',
        width: 200,
        type: 'title',
      },
      {
        title: 'Notes',
        model: 'notes',
        width: 260,
        type: 'rich_text',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to],
  )

  return (
    <DataTable
      asCard
      title="Quick Text"
      testKey="quick_texts_data_table"
      icon="quick_text"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  category: {
    label: 'Visibility',
    type: 'multi_select',
    options: Object.keys(STATUSES).map((key) => ({
      label: STATUSES[key].label,
      value: key,
    })),
  },
  subcategory: {
    label: 'Category',
    type: 'string',
  },
  notes: {
    label: 'Notes',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
