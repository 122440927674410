import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Status from '../../components/Status'
import Textarea from '../../components/Forms/Textarea'

const RootOrganizationTrackOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'organization_tracks',
    endpoint: '/organization_tracks',
    invalidate: 'organization_tracks',
    invalidateKeys: ['organization_workflows'],
    options: props,
  })

  const { mutateAsync: setAsDefault, isLoading: isMakingDefault } = useUpdate({
    name: ['update_organization_track'],
    url: `/organization_tracks/${id}/default`,
    invalidate: 'organization_tracks',
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={46} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={46}>
      <Overlay.Header
        icon="organization_tracks"
        title="Organization Track"
        titleAside={data?.is_default && <Status small label="Default" color="blue" className="ml-1.5" />}
      />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />
              <Textarea label="Description" model="description" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={isNew ? 'organization_tracks.create' : 'organization_tracks.edit'}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Organization Track"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="organization_tracks.edit"
            />

            {!data?.is_default && (
              <>
                <ConfirmDialog
                  title="Set as Default Organization Track?"
                  message={`Are you sure you want to set this Organization Track as the default?`}
                  yesLabel="Set as Default"
                  onYes={async () => {
                    await setAsDefault({})
                  }}
                  permission="organization_tracks.edit"
                >
                  <Button
                    fullWidth
                    glyph="tick_circle"
                    label="Set as Default"
                    type="default"
                    color="green"
                    isLoading={isMakingDefault}
                    permission="organization_tracks.edit"
                  />
                </ConfirmDialog>

                <DeleteDialog
                  title="Delete Organization Track?"
                  message="Are you sure you want to delete this Organization Track? This action cannot be undone."
                  onYes={deleteRecord}
                  permission="organization_tracks.delete"
                >
                  <Button
                    fullWidth
                    glyph="delete"
                    label="Delete"
                    type="default"
                    color="red"
                    isLoading={isDeleting}
                    permission="organization_tracks.delete"
                  />
                </DeleteDialog>
              </>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const OrganizationTrackOverlay = withOverlayError(RootOrganizationTrackOverlay)
