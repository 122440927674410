import pickBy from 'lodash/pickBy'
import { v4 as uuid } from 'uuid'
import pluralize from 'pluralize'

import { store } from '../setup/store'
import { readFileAsDataURL } from '../utils/functions'
import { apiCreate } from './api'

import { updateQueue, updateStats, processingStarted, setCurrentUpload, processingFinished } from '../actions/common'
import { invalidateQueries } from '../hooks/useNewAPI'

class Upload {
  constructor() {
    this.requestQueue = {}
    this.pendingQueue = {}
    this.failedQueue = {}
    this.current = null
    this.percentage = 0
    this.queryKey = null
  }

  uploadFile = async (request: any) => {
    request.status = 'loading'
    store.dispatch(setCurrentUpload(this.requestQueue, request))

    let dataUrl = await readFileAsDataURL(request.file)
    const params = {
      title: request.file.name.replace(/\.[^/.]+$/, ''),
      category: request.options.category,
      subcategory: request.options.subcategory,
      [`${request.model}_name`]: request.file.name,
      [`${request.model}_data`]: dataUrl,
      [`${request.model}_content_type`]: request.file.type,
      [`${request.resource_name}_id`]: request?.resource?.id,
      [`${request.resource_name}_type`]: request?.resource?.type,
      ...request.data,
    }

    const parent = {
      id: request.resource?.id,
      type: request.resource?.type ? pluralize.plural(request.resource?.type) : null,
    }

    try {
      await apiCreate({
        name: request.options?.name ? request.options.name : 'documents',
        url: request.options?.url ? request.options?.url : `${parent.type}/${parent.id}/documents`,
        params: params,
        headers: request.options.headers
      })

      if (request.options?.queryKey) invalidateQueries(request.options?.queryKey)

      invalidateQueries(null, 'files')

      request.status = 'completed'

      store.dispatch(updateQueue(this.requestQueue))
    } catch (errors) {
      request.status = 'error'

      store.dispatch(updateQueue(this.requestQueue))
    }
  }

  clearQueue = () => {
    this.requestQueue = {}
    this.pendingRequests = {}
    this.failedQueue = {}
    this.current = null
    this.percentage = 0
  }

  removeFromQueue = (id) => {
    if (!id) return
    if (this.requestQueue.hasOwnProperty(id)) {
      delete this.requestQueue[id]

      this.failedRequests = pickBy(this.requestQueue, (o) => o.status === 'error')
      this.pendingRequests = pickBy(this.requestQueue, (o) => o.status === 'pending')

      const pendingLength = Object.keys(this.pendingRequests).length
      const totalLength = Object.keys(this.requestQueue).length
      const failedLength = Object.keys(this.failedRequests).length
      const completedLength = totalLength - pendingLength - 1 // (1 is loading)

      this.percentage = 100 - ((failedLength + pendingLength + completedLength) / totalLength) * 100

      store.dispatch(updateStats(this.percentage, totalLength - pendingLength))
      store.dispatch(updateQueue(this.requestQueue))
    }
  }

  queue = ({ files, resource, options, model = 'file', data, resourceName = 'resource' }: any) => {
    let requests = {}

    for (let i = 0; i < files.length; i++) {
      const request = {
        id: uuid(),
        status: 'pending',
        file: files[i],
        name: files[i].name,
        model: model,
        resource: resource,
        resource_name: resourceName,
        options: options,
        data,
      }

      this.requestQueue[request.id] = request
      requests[request.id] = request
    }

    return requests
  }

  process = async () => {
    this.failedRequests = pickBy(this.requestQueue, (o) => o.status === 'error')
    this.pendingRequests = pickBy(this.requestQueue, (o) => o.status === 'pending')

    const pendingKeys = Object.keys(this.pendingRequests)
    const totalLength = Object.keys(this.requestQueue).length
    const failedLength = Object.keys(this.failedRequests).length
    const pendingLength = pendingKeys.length

    this.percentage = 100 - ((failedLength + pendingLength) / totalLength) * 100
    store.dispatch(updateStats(this.percentage, totalLength - pendingLength + 1))

    if (pendingKeys.length > 0) {
      this.current = this.pendingRequests[pendingKeys[0]]

      await this.uploadFile(this.current)
      await this.process()
    }
  }

  start = async () => {
    store.dispatch(processingStarted())
    await this.process()
    store.dispatch(processingFinished())
  }
}

export default new Upload()
