import React from 'react'
import { Link } from 'react-router-dom-v5-compat'
import { produce } from 'immer'
import { transparentize } from 'polished'
import { v4 as uuid } from 'uuid'

import { COLORS, ICONS, MEDIA_QUERY } from '../../theme'
import { titleCase } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Notifications from '../../modules/notifications'

import Button from '../../components/Button'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Nav from '../../components/Nav'
import NavItem from '../../components/NavItem'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageGrid from '../../components/PageGrid'
import SummonOverlay from '../../components/SummonOverlay'
import Tooltip from '../../components/Tooltip'

import { APP_URLS } from '../MyAccount/utils'
import { EHRAccountBuilderOverlay } from '../EHR/EHRAccountBuilderOverlay'

import { getQuoteDetailsFromFacility } from '../QuoteElements/functions'
import { QuoteConfidentialityNDAForm } from '../QuoteElements/QuoteConfidentialityNDAForm'
import { QuoteDetails } from './components/QuoteDetails'
import { QuotePaymentForm } from '../QuoteElements/QuotePaymentForm'
import { QuotePricingForm } from '../QuoteElements/QuotePricingForm'
import { QuoteProvider, useQuoteContext } from '../QuoteElements/context'
import { QuoteSettingsForm } from '../QuoteElements/QuoteSettingsForm'
import { QuoteSigningForm } from '../QuoteElements/QuoteSigningForm'
import { QuoteStatus } from './QuoteStatus'
import { QuoteSummaryForm } from '../QuoteElements/QuoteSummaryForm'

import { parseQuoteVariables } from './functions'
import { QuoteComments } from './components/QuoteComments'

const ACCOUNT_TYPES: any = {
  healthcare_facility: 'Healthcare Facility',
  healthcare_practice: 'Healthcare Practice',
  support_facility: 'Support Facility',
  support_practice: 'Support Practice',
  provider_affiliate: 'Provider Partner',
}

const INITIAL_DATA = {
  status: 'draft',
  iframes_settings: {},
  use_confidentiality_section: true,
  use_nda_section: true,
  use_price_matching_section: true,
  use_summary_section: true,
  billed_by_company_name: 'Behave Health Corp',
  billed_by_email: 'contact@behavehealth.com',
  billed_by_phone_no: '+1 650-338-4113',
  billed_by_address: {
    address_line_1: '135 Main Street',
    address_line_2: 'Suite #1140',
    city: 'San Francisco',
    state: 'CA',
    zip_code: '94105',
  },
}

const INITIAL_OFFER = {
  name: 'Offer 1',
  description: '',
  quote_lines: [],
}

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const RootQuoteOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    edit,
    isEditable,
    isNew,
    isOverlayLoading,
    isSaving,
    saveWithData,
    refetch,
    initialData,
    deleteRecord,
    isDeleting,
  } = useOverlay({
    name: 'quotes',
    endpoint: '/quotes',
    invalidate: 'quotes',
    options: props,
  })

  const { timezone, isBehave, user } = useSettings()

  const [activePageId, setActivePageId] = React.useState('quote_details')

  if (!isBehave) return null

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={130} />
  }

  return (
    <QuoteProvider data={data} timezone={data?.facility?.timezone || data?.timezone || timezone}>
      <InnerQuoteOverlay
        activePageId={activePageId}
        setActivePageId={setActivePageId}
        cancel={cancel}
        edit={edit}
        isEditable={isEditable}
        isNew={isNew}
        isSaving={isSaving}
        onSave={saveWithData}
        data={data}
        initialData={
          isNew
            ? {
                billed_by_name: user?.name || 'Ben Weiss',
                ...INITIAL_DATA,
                ...getQuoteDetailsFromFacility(initialData?.facility),
                ...initialData,
              }
            : data
        }
        onClose={close}
        dataID={data?.id}
        refetch={refetch}
        deleteRecord={deleteRecord}
        isDeleting={isDeleting}
        key={`updated-${data?.updated_at}`}
      />
    </QuoteProvider>
  )
}

const InnerQuoteOverlay = (props: any) => {
  const {
    cancel,
    edit,
    isEditable,
    isNew,
    isSaving,
    onSave,
    initialData,
    data,
    onClose,
    dataID,
    refetch,
    deleteRecord,
    isDeleting,
    activePageId,
    setActivePageId,
  } = props

  const { isHQApp, isBehave } = useSettings()
  const { variableValues, setData: setContextData }: any = useQuoteContext()

  const [formData, setFormData] = React.useReducer(dataReducer, initialData)
  const [facility, setFacility] = React.useState<any>(initialData?.facility || null)

  const [quoteOffers, setQuoteOffers] = React.useState(isNew ? [{ _id: uuid(), ...INITIAL_OFFER }] : initialData.quote_offers || [])

  const [deleteQuoteOffers, setDeleteQuoteOffers] = React.useState([])

  const isFinalStatus = initialData?.status === 'paid'

  const { mutateAsync: setupEHR, isLoading: isSettingEHR } = useCreate({
    name: ['setup_ehr'],
    url: `/quotes/${dataID}/setup_and_connect_ehr`,
    invalidate: 'quotes',
  })

  const { mutateAsync: disconnectEHR } = useCreate({
    name: ['setup_ehr'],
    url: `/quotes/${dataID}/disconnect_ehr`,
    invalidate: 'quotes',
  })

  const { mutateAsync: processQuote } = useCreate({
    name: ['process-quote'],
    url: `/quotes/${dataID}/process_tenant`,
    invalidate: 'quotes',
  })

  const handleConnectNewEHR = async (ehr) => {
    if (!ehr) return

    try {
      const result = await setupEHR({
        ...ehr,
        owners: [
          {
            email: ehr.email,
            phone_no: ehr.phone_no,
            first_name: ehr.first_name,
            last_name: ehr.last_name,
          },
        ],
      })

      if (result) setFacility(result)
    } catch (error) {
      console.error(error)
    }
  }

  const handleConnectEHR = async (ehr) => {
    if (!ehr?.id) return

    try {
      await setupEHR({ facility_id: ehr.id })

      setFacility(ehr)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDisconnectEHR = async () => {
    try {
      await disconnectEHR({ facility_id: null })

      setFacility(null)
    } catch (error) {
      console.error(error)
    }
  }

  const handleProcessQuote = async () => {
    try {
      await processQuote({})

      Notifications.send('Quote Successfully Processed', 'positive')
    } catch (error) {
      console.error(error)
    }
  }

  // Update context data when form data changes to support variables parsing
  React.useEffect(() => {
    setContextData(formData)
  }, [formData])

  const handleSave = async () => {
    const quote_offers_attributes: any = []

    for (let i = 0; i < quoteOffers.length; i++) {
      const offer = quoteOffers[i]
      const lines = offer?.quote_lines || []
      const deleteLines = offer?.delete_quote_lines || []

      const quote_lines_attributes: any = []

      for (let j = 0; j < lines.length; j++) {
        const line = lines[j]

        quote_lines_attributes.push({
          id: line.id,
          product_id: line.product?.id || null,
          discount_id: line.discount?.id || null,
          order: j,
        })
      }

      for (const line of deleteLines) {
        quote_lines_attributes.push(line)
      }

      quote_offers_attributes.push({
        id: offer.id,
        order: i,
        quote_id: dataID,
        name: offer.name,
        description: offer.description,
        sales_tax: offer.sales_tax,
        sales_tax_type: offer.sales_tax_type,
        feature_flag_config_id: offer.feature_flag_config_id || offer.feature_flag_config?.id || null,
        quote_lines_attributes,
      })
    }

    for (const deletedOffer of deleteQuoteOffers) {
      quote_offers_attributes.push(deletedOffer)
    }

    const saveData: any = produce(formData, (draft) => {
      draft.facility_id = facility?.id || draft?.facility_id
      delete draft.facility
      delete draft.quote_offers
    })

    const parsedData = await parseQuoteVariables(saveData, variableValues)

    const result = await onSave({
      ...saveData,
      ...parsedData,
      quote_offers_attributes,
      owner_signup_id: initialData?.owner_signup_id,
    })

    setDeleteQuoteOffers([])

    if (result.quote_offers) {
      setQuoteOffers(result.quote_offers)
    }
  }

  const [isValid, setIsValid] = React.useState(false)

  const ActivePageTag = React.useMemo(() => {
    return PAGES[activePageId].component || React.Fragment
  }, [activePageId, PAGES])

  const finalIsValid = React.useMemo(() => {
    if (!formData || !isValid) return false

    const { name, timezone, billed_to_company_name, billed_to_email } = formData

    if (!name || !timezone || !billed_to_company_name || !billed_to_email) return false

    for (const offer of quoteOffers) {
      if (!offer.quote_lines) continue

      for (const line of offer.quote_lines) {
        if (!line.product) return false
      }
    }

    return true
  }, [isValid, formData, quoteOffers])

  return (
    <Overlay onClose={onClose} showBackdrop={isNew || isEditable} maxWidth={130} closeOnEscape={false}>
      <Overlay.Header
        icon="quotes"
        title="Quote"
        titleAside={
          <>
            <QuoteStatus status={initialData?.status} />

            {data?.external_id && (
              <Button
                useGlyphForTarget
                label="Open Public Quote"
                glyph="link"
                target="_blank"
                className="!ml-3"
                size={100}
                href={`${APP_URLS.mini_apps}/quotes/${data.external_id}`}
              />
            )}

            {data?.external_id && (
              <ConfirmDialog
                isDisabled={data?.is_processed === true}
                title="Process Quote for Tenant?"
                message={
                  <span>
                    This will <b>copy over the Payment Method</b> from the Quote, <b>create Subscriptions</b> and{' '}
                    <b>apply the Feature Flag Config</b>. Do you want to continue?
                  </span>
                }
                yesColor="green"
                yesLabel="Yes, Process Account Setup"
                onYes={handleProcessQuote}
              >
                <Button
                  isDisabled={data?.is_processed === true}
                  color="green"
                  label="Process Account Setup…"
                  glyph="behave"
                  className="!ml-3"
                  size={100}
                />
              </ConfirmDialog>
            )}

            {data?.stripe_payment_intent && isHQApp && isBehave && (
              <Button
                useGlyphForTarget
                label="Open in Stripe"
                glyph="stripe"
                target="_blank"
                className="!ml-2"
                size={100}
                href={`https://dashboard.stripe.com/payments/${data.stripe_payment_intent}`}
              />
            )}

            {/* {data?.id && (
              <SummonOverlay overlay={<QuoteReviewOverlay dataID={data?.id} />}>
                <Button label="View Active Quote" glyph="view" size={100} className="!ml-3" />
              </SummonOverlay>
            )} */}
          </>
        }
      />

      <Overlay.SubHeader className="!py-1 border-t border-0 border-solid border-divider">
        <Flex centerY gap={8} justifyContent="space-between">
          <div className="flex items-center flex-nowrap !cursor-auto">
            <Icon icon="enterprise" size={20} className="mr-2" />

            {!!facility?.id ? (
              <div>
                <div className="font-[600] text-[1.05rem] tracking-normal">
                  <Link
                    className="inline-flex items-center"
                    to={`${APP_URLS.hq}/companies/${facility.id}/${facility.subdomain}`}
                    target={isHQApp ? '' : '_blank'}
                  >
                    {facility?.name} {isHQApp ? '→' : <Glyph glyph="external_link" size={12} color={COLORS.blue} className="ml-1.5" />}
                  </Link>
                </div>

                <div className="font-[400] text-text-muted tracking-normal -mt-1 text-[0.9rem]">
                  <span>
                    <span className="font-[600]">Account Type: </span>
                    {ACCOUNT_TYPES?.[facility?.category] || titleCase(facility.category) || '–'}
                  </span>

                  <span className="mx-1.5">•</span>

                  <span>
                    <span className="font-[600]">Account Status: </span>
                    {facility?.plan_status ? titleCase(facility.plan_status) : '–'}
                  </span>

                  <span className="mx-1.5">•</span>

                  <span>
                    <span className="font-[600]">Subdomain: </span>
                    {facility.subdomain || '–'}
                  </span>
                </div>
              </div>
            ) : (
              <span className="italic opacity-60">No Connected Company (EHR)</span>
            )}
          </div>

          {facility?.id ? (
            <Flex gap="0.75rem">
              <Tooltip show={isEditable} content="Save the quote first to set up or update the connected EHR">
                <SummonOverlay
                  isDisabled={isEditable}
                  overlay={
                    <EHRAccountBuilderOverlay
                      initialModel={{
                        timezone: formData?.timezone,
                      }}
                      onLink={handleConnectEHR}
                      onSave={handleConnectNewEHR}
                      createButtonLabel="Create EHR & Link with Quote"
                    />
                  }
                >
                  <Button
                    label={`Edit Connected EHR…`}
                    type="default"
                    glyph="edit"
                    size={100}
                    isLoading={isSettingEHR}
                    isDisabled={isEditable}
                  />
                </SummonOverlay>
              </Tooltip>

              <Tooltip show={isEditable} content="Save the quote first to remove the connected EHR">
                <DeleteDialog
                  title="Disconnect EHR?"
                  message="Are you sure you want to disconnect the connected EHR?"
                  yesLabel="Disconnect EHR"
                  glyph="cross"
                  onYes={handleDisconnectEHR}
                  isDisabled={isEditable}
                >
                  <Button
                    label={`Disconnect EHR…`}
                    type="default"
                    glyph="cross"
                    color="text"
                    glyphColor="red"
                    size={100}
                    isLoading={isSettingEHR}
                    isDisabled={isEditable}
                  />
                </DeleteDialog>
              </Tooltip>
            </Flex>
          ) : (
            <Tooltip show={isEditable} content="Save the quote first to set up the connected EHR">
              <SummonOverlay
                isDisabled={isEditable}
                overlay={
                  <EHRAccountBuilderOverlay
                    initialModel={{
                      timezone: formData?.timezone,
                    }}
                    onLink={handleConnectEHR}
                    onSave={handleConnectNewEHR}
                    createButtonLabel="Create EHR & Link with Quote"
                  />
                }
              >
                <Button
                  label={`Set Up EHR…`}
                  type="primary"
                  icon="enterprise"
                  size={200}
                  isLoading={isSettingEHR}
                  isDisabled={isEditable}
                />
              </SummonOverlay>
            </Tooltip>
          )}
        </Flex>
      </Overlay.SubHeader>

      <PageGrid scroll breakpoint={3} className="overlay-page-grid" css={STYLES.pageGrid}>
        <Nav top="0" breakpoint={3} background={transparentize(0.4, 'white')} css={STYLES.nav}>
          {Object.keys(PAGES).map((pageId: any) => {
            const page = PAGES[pageId]

            if (page.isNewOnly && !isNew) return null

            return (
              <NavItem
                key={pageId}
                icon={page.icon}
                color={page.icon?.color}
                label={page.title}
                onClick={() => setActivePageId(pageId)}
                isActive={activePageId === pageId}
              />
            )
          })}
        </Nav>

        <Overlay.Content>
          <ActivePageTag
            data={data}
            isEditable={isEditable}
            formData={formData}
            onValidationUpdate={setIsValid}
            onUpdate={setFormData}
            facility={facility}
            setFacility={setFacility}
            quoteOffers={quoteOffers}
            setQuoteOffers={setQuoteOffers}
            setDeleteQuoteOffers={setDeleteQuoteOffers}
            timezone={facility?.timezone}
            updateQuote={onSave}
            refetchQuote={refetch}
            isNew={isNew}
          />
        </Overlay.Content>
      </PageGrid>

      {!isFinalStatus && (
        <Overlay.Footer withGradient={false}>
          {isEditable && (
            <>
              <Button
                label="Save Quote"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={handleSave}
                isDisabled={!finalIsValid}
                flex="100 1 auto"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button glyph="edit" label="Edit Quote" type="default" onClick={edit} flex="100 1 auto" />

              <QuoteReset quoteId={data?.id} />

              <DeleteDialog
                title="Delete Quote?"
                message="Are you sure you want to delete this Quote? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const QuoteReset = ({ quoteId }: any) => {
  const { mutateAsync: resetQuote } = useCreate({
    name: ['reset-quote'],
    url: `/quotes/${quoteId}/reset`,
    invalidate: 'quotes',
  })

  const handleReset = async () => {
    try {
      await resetQuote({})
      Notifications.send('Quote Successfully Reset', 'positive')
    } catch (error) {
      console.error(error)
    }
  }

  if (!quoteId) return null

  return (
    <DeleteDialog
      title="Reset Quote?"
      glyph="reset"
      message="Are you sure you want to reset this Quote?"
      yesLabel="Reset Quote"
      onYes={handleReset}
    >
      <Button label="Reset Quote…" glyph="reset" type="default" color="text" glyphColor="red" />
    </DeleteDialog>
  )
}

const PAGES = {
  quote_details: {
    title: 'Quote Details',
    component: QuoteDetails,
    icon: ICONS['quotes'],
  },
  comments: {
    title: 'Comments',
    component: QuoteComments,
    icon: ICONS['chat'],
  },
  summary: {
    title: 'Summary',
    component: QuoteSummaryForm,
    icon: ICONS['general_info'],
  },
  confidentiality_nda: {
    title: 'Confidentiality Notice & NDA',
    component: QuoteConfidentialityNDAForm,
    icon: ICONS['legal_agreement_alt'],
  },
  pricing: {
    title: 'Pricing',
    component: QuotePricingForm,
    icon: ICONS['financials'],
  },
  signing: {
    title: 'Signing',
    component: QuoteSigningForm,
    icon: ICONS['signature'],
  },
  payment: {
    title: 'Payment',
    component: QuotePaymentForm,
    icon: ICONS['online_card_payment'],
  },
  settings: {
    title: 'Settings',
    component: QuoteSettingsForm,
    icon: ICONS['settings'],
  },
}

const STYLES = {
  pageGrid: {
    flex: '1 1 auto',
  },

  nav: {
    [MEDIA_QUERY[3]]: {
      maxWidth: 300,
    },
  },
}

export const QuoteOverlay = withOverlayError(RootQuoteOverlay)
