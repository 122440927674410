import React from 'react'
import { Route, useParams, useRouteMatch } from 'react-router-dom'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useStore from '@behavehealth/modules/store'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Portal, Page, Alert, Dropdown, DropdownItem, Icon, Link, PageLayout, PageSection, AnimatedSwitch } from '@behavehealth/components'

import OrganizationFinancialPlanCard from '@behavehealth/constructs/Financials/OrganizationFinancialPlanCard'
import OrganizationOnlineFinancialCards from '@behavehealth/constructs/Financials/OrganizationOnlineFinancialCards'
import OrganizationPaymentMethodsSection from '@behavehealth/constructs/Stripe/OrganizationPaymentMethodsSection'

import { AuthorityTransactions } from '@behavehealth/constructs/AuthorityFinancials/AuthorityTransactions'

import { AuthorityChargeBuilderOverlay } from '@behavehealth/constructs/AuthorityFinancials/AuthorityChargeBuilderOverlay'
import { AuthorityChargeOverlay } from '@behavehealth/constructs/AuthorityFinancials/AuthorityChargeOverlay'
import { AuthorityCreditOverlay } from '@behavehealth/constructs/AuthorityFinancials/AuthorityCreditOverlay'
import { AuthorityPaymentOverlay } from '@behavehealth/constructs/AuthorityFinancials/AuthorityPaymentOverlay'
import { AuthorityRefundOverlay } from '@behavehealth/constructs/AuthorityFinancials/AuthorityRefundOverlay'
import { AuthorityWriteOffOverlay } from '@behavehealth/constructs/AuthorityFinancials/AuthorityWriteOffOverlay'

const pageConfig = {
  feature: 'financials',
  title: 'Financials',
}

type Props = {
  canCreate: boolean
}

const Financials: React.FC<Props> = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { url } = match

  const tenant = useStore((state) => state.tenant)

  const { data: organization }: any = useGet({
    name: ['organization', resource_id],
    url: `/organizations/${resource_id}`,
  })

  return (
    <>
      <Page breakpoint="0" {...pageConfig}>
        <PageLayout>
          {/* Stripe Connection */}
          {!tenant?.is_stripe_connected && (
            <Alert contrast small type="warning" glyph="info">
              Set up <Link to="/settings/online-payments">Online Payments</Link> and share the Funding & Donations links with{' '}
              {organization?.name} to automatically collect Funding & Donations
            </Alert>
          )}

          {tenant?.is_stripe_connected && <OrganizationOnlineFinancialCards organization={organization} />}

          <OrganizationFinancialPlanCard organization={organization} />

          {tenant?.is_stripe_connected && organization && (
            <OrganizationPaymentMethodsSection title="Payment Methods" organization={organization} tenant={tenant} />
          )}

          <PageSection>
            <PageSection.Header
              graphic={<Icon icon="cashbook_green" size={22} />}
              after={
                <Dropdown label="Add New…" glyph="add" buttonType="primary" buttonSize={200} permission="ledger.create">
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Charges"
                    link={{
                      pathname: `${url}/charge-builder`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Payment"
                    link={{
                      pathname: `${url}/payments/new`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Credit"
                    link={{
                      pathname: `${url}/credits/new`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Refund"
                    link={{
                      pathname: `${url}/refunds/new`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Write-Off"
                    link={{
                      pathname: `${url}/write-offs/new`,
                      parent: match,
                    }}
                  />
                </Dropdown>
              }
            >
              <PageSection.Title title="Ledger Transactions" className="!mr-2" />
            </PageSection.Header>

            <PageSection.Content>
              <AuthorityTransactions
                name={['organization', resource_id, 'financial-transactions']}
                url={`/organizations/${resource_id}/financial_transactions`}
              />
            </PageSection.Content>
          </PageSection>
        </PageLayout>
      </Page>

      <Portal type="z90">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <Route exact path={`${match.path}/charge-builder`} component={AuthorityChargeBuilderOverlay} />
          <Route exact path={`${match.path}/charges/:id`} component={AuthorityChargeOverlay} />
          <Route exact path={`${match.path}/credits/:id`} component={AuthorityCreditOverlay} />
          <Route exact path={`${match.path}/payments/:id`} component={AuthorityPaymentOverlay} />
          <Route exact path={`${match.path}/refunds/:id`} component={AuthorityRefundOverlay} />
          <Route exact path={`${match.path}/write-offs/:id`} component={AuthorityWriteOffOverlay} />
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

export default withPageError(withMarketing(Financials, pageConfig))
