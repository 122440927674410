import React from 'react'

import { useSettings } from '../../hooks/useSettings'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'

type Props = {
  organization: object
  isPartner: boolean
}

export const CommunityOrganizationInternalNavItems = (props: Props) => {
  const { organization } = props

  const { isBehave } = useSettings()
  const basePath = `/community/organizations/${organization?.id}`

  return (
    <>
      <NavGroup label="General">
        <NavItem feature="general_info" label="General Info" to={`${basePath}/internal/general-info`} />
        <NavItem
          showMarketing
          feature="todos"
          label="To-Do"
          to={`${basePath}/internal/todo`}
          permission="todos.view"
          featureFlagV2="todos"
        />
        <NavItem
          showMarketing
          label="Calendar"
          to={`${basePath}/internal/calendar`}
          feature="calendar"
          permission="events.view"
          featureFlagV2="calendar"
        />
        <NavItem
          showMarketing
          feature="files"
          label="Files"
          to={`${basePath}/internal/files`}
          permission="organizations.files.view"
          featureFlagV2="files"
        />
      </NavGroup>

      <NavGroup label="Forms">
        <NavItem
          label="Lead Contact Forms"
          feature="organization_lead_forms"
          to={`${basePath}/internal/lead-contact-forms`}
          permission="organization_lead_forms.view"
          featureFlagV2="organization_lead_forms"
        />
        <NavItem
          feature="form_submissions"
          label="Form Inbox"
          to={`${basePath}/internal/form-submissions`}
          featureFlagV2="form_submissions"
          permission="form_submissions.view"
        />
        <NavItem
          isBeta
          feature="form_submissions"
          label="Send Referral"
          to={`${basePath}/internal/send-referral`}
          featureFlagV2="orgs_forms_send_referral"
        />
        <NavItem
          isBeta
          feature="form_submissions"
          label="Send Message"
          to={`${basePath}/internal/send-message`}
          featureFlagV2="orgs_forms_send_message"
        />
      </NavGroup>

      <NavGroup label="Org Coordination">
        <NavItem
          showMarketing
          feature="organization_agreements"
          label="Agreements"
          to={`${basePath}/internal/agreements`}
          permission="organization_agreements.view"
          featureFlagV2="organization_agreements"
        />
        <NavItem
          showMarketing
          feature="contacts"
          label="Contacts"
          to={`${basePath}/internal/contacts`}
          permission="contacts.view"
          featureFlagV2="contacts"
        />
        {isBehave && (
          <NavItem
            feature="form_submissions"
            label="Contacts Form Inbox"
            to={`${basePath}/internal/contacts-form-submissions`}
            // permission="TODO"
            // featureFlagV2="TODO"
          />
        )}
        <NavItem
          showMarketing
          feature="communications"
          label="Communications"
          to={`${basePath}/internal/communications`}
          permission="communications.view"
          featureFlagV2="communication_logs"
        />
      </NavGroup>

      <NavGroup label="Notes">
        <NavItem
          showMarketing
          feature="shift_notes"
          label="Shift Notes"
          to={`${basePath}/internal/shift-notes`}
          permission="shift_notes.view"
          featureFlagV2="shift_notes"
        />
        <NavItem
          showMarketing
          feature="custom_notes"
          label="Custom Notes"
          to={`${basePath}/internal/custom-notes`}
          permission="organization_custom_notes.view"
          featureFlagV2="organization_custom_notes"
        />
      </NavGroup>

      <NavGroup label="Financials">
        <NavItem
          feature="financials"
          label="Financials"
          to={`${basePath}/internal/financials`}
          permission="ledger.view"
          featureFlagV2="organization_financials"
        />
      </NavGroup>
    </>
  )
}
