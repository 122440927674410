import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { AGREEMENTS } from '../../utils/constants'
import { DEFAULT_FILTERS } from '../Filters/constants'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Status from '../../components/Status'

export const ClientAgreementTemplatesDataTable = (props: any) => {
  const { to, onClick, duplicateFormLinkProps, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 240,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            as={NavLink}
            to={to ? to(data) : undefined}
            onClick={onClick ? () => onClick(data) : undefined}
            value={value}
          />
        ),
      },
      {
        title: 'Client Tracks',
        model: 'client_tracks',
        type: 'record_client_tracks',
        featureFlag: 'client_tracks',
        editPermission: 'client_tracks.edit',
        disableSort: true,
      },
      {
        title: 'Category',
        model: 'category',
        width: 160,
        formatValue: ({ value }: any) => AGREEMENTS?.[value] || '–',
      },
      {
        title: 'Signee Signature',
        model: 'should_sign',
        width: 160,
        formatValue: ({ value }: any) =>
          value === true ? <Status label="Required" color="green" /> : <Status label="Not Required" color="gray" />,
      },
      {
        title: 'Order',
        model: 'order',
        width: 100,
        type: 'number',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
      ...(duplicateFormLinkProps
        ? [
            {
              title: 'Actions',
              model: 'id',
              width: 130,
              disableSort: true,
              formatValue: ({ data }: any) => {
                const duplicated = {
                  ...data,
                  name: data.name + ' Copy',
                }
                delete duplicated.id
                delete duplicated.author
                delete duplicated.created_at
                delete duplicated.updated_at

                return (
                  <Flex gap="0.5rem">
                    <Button label="Duplicate" glyph="add" size={100} {...duplicateFormLinkProps(duplicated)} />
                  </Flex>
                )
              },
            },
          ]
        : []),
    ],
    [to, onClick, duplicateFormLinkProps],
  )

  return (
    <DataTable
      asCard
      title="Agreement Templates"
      icon="legal_agreement_alt"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      emptyDescription="No agreement templates have been added yet"
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  client_tracks: DEFAULT_FILTERS.client_tracks,
  category: {
    label: 'Category',
    type: 'multi_select',
    options: Object.entries(AGREEMENTS).map(([value, label]) => ({ label, value })),
  },
  should_sign: {
    label: 'Signee Signature',
    type: 'multi_select',
    options: [
      { label: 'Required', value: true },
      { label: 'Not Required', value: false },
    ],
  },
  order: {
    label: 'Order',
    type: 'number',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
