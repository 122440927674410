import React from 'react'

import { PaymentMethod } from '../BillingElements/PaymentMethodElements'
import { niceAmount } from '../../utils/functions'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

export const InvoicePaymentsDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        disableSort: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={to ? to(data) : undefined} value={value} />
        },
      },
      {
        title: 'Collected',
        model: 'full_amount',
        width: 140,
        hoverExpand: false,
        disableSort: true,
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          return (
            <span className="flex justify-end font-[600]">
              {niceAmount(value)} {data.pass_fee_to_payer ? `(${niceAmount(data.stripe_fee)} fee)` : ''}
            </span>
          )
        },
      },
      {
        title: 'Status',
        model: 'status',
        type: 'title',
        disableSort: true,
      },
      {
        title: 'Paid With',
        model: 'payed_with',
        type: 'title',
        disableSort: true,
      },
      {
        title: 'Payment Method',
        model: 'global_payment_method',
        disableSort: true,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <PaymentMethod paymentMethod={value} />
        },
      },
      {
        title: 'Pass Fee To Payer',
        model: 'pass_fee_to_payer',
        type: 'boolean',
        width: 150,
        disableSort: true,
      },
      {
        title: 'Date Paid',
        model: 'payed_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to],
  )

  return (
    <DataTable
      asCard
      title="Invoice Payments"
      testKey="invoice_payment_data_table"
      icon="online_card_payment"
      columns={columns}
      {...rest}
    />
  )
}
