import React from 'react'
import { useRouteMatch, Link } from 'react-router-dom'

import { apiUpdate } from '@behavehealth/modules/api'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'

import {
  Accordion,
  AddressSelectorInput,
  Alert,
  Chotomate,
  Divider,
  EmailInput,
  Form,
  FormSection,
  Grid,
  Input,
  Option,
  Page,
  Permission,
  PhoneInput,
  Select,
  Textarea,
  URLInput,
} from '@behavehealth/components'

import Checkbox from '@behavehealth/components/Forms/Checkbox'
import CheckboxGroup from '@behavehealth/components/Forms/CheckboxGroup'
import NumberInput from '@behavehealth/components/Forms/NumberInput'

import { ACCREDITING_BODY } from '@behavehealth/constructs/Authority/constants'
import { DIRECTORIES_DATA } from '@behavehealth/constructs/AuthorityDirectories/data'

const RootDirectoryInfo: React.FC = () => {
  const match: any = useRouteMatch()
  const id = match.params?.resource_id

  const { data: organization, isLoading }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const { mutateAsync: updateOrganization }: any = useUpdate({
    name: ['organization', id],
    url: `/organizations/${id}`,
    invalidate: ['organizations'],
  })

  if (!organization || isLoading) return null

  return (
    <Page feature="general_info" title="Directory Info">
      <Chotomate name="general_info" ready={organization} />

      <Grid gap="1.5rem">
        <div>
          {/* Organization Details */}
          <Accordion
            isOpen
            activateEditMode
            title="Organization Details"
            initialModel={organization}
            glyph="organization_details"
            permission="organizations.general_info.organization_details.view"
            editPermission="organizations.general_info.organization_details.edit"
            onSubmit={updateOrganization}
          >
            <Form>
              <FormSection labelWidth="150px">
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox
                    label="Share this Organization in Directories"
                    description="Allow the organization to be shared in Directories"
                    model="is_shared_with_directories"
                  />
                </CheckboxGroup>

                <Alert small icon="directories">
                  <b>Note:</b> go to <Link to="/settings/directories">Settings → Directories</Link> to manage your directories and the data
                  points that are shared.
                </Alert>

                <Input label="Licensing Body" model="licensing_body" />

                <Select allowEmpty label="Accrediting Body" model="accrediting_body">
                  {Object.keys(ACCREDITING_BODY).map((model) => (
                    <Option key={model} label={ACCREDITING_BODY[model]} value={model} />
                  ))}
                </Select>

                <Textarea label="About" model="about" minRows={4} description="A general overview of the facility and its services" />

                <Textarea
                  label="Mission Statement"
                  model="mission_statement"
                  minRows={4}
                  description="The facility's official mission statement"
                />

                <NumberInput label="Year Founded" model="year_founded" size={6} />

                <CheckboxGroup label="Facility Type" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                  <Checkbox model="is_substance_use" label="Substance Use" />
                  <Checkbox model="is_mental_health" label="Mental health" />
                  <Checkbox model="is_health_care_center" label="Health Care Centers" />
                  <Checkbox model="is_buprenorphine_practitioner" label="Buprenorphine Practitioners" />
                  <Checkbox model="is_opioid_treatment_program" label="Opioid Treatment Programs" />
                  <Checkbox model="is_treatment_center" label="Treatment Center" />
                </CheckboxGroup>

                <CheckboxGroup
                  label="Service Availability"
                  tooltip={DIRECTORIES_DATA.service_availability.tooltip}
                  layout="vertical-dense"
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="faded"
                >
                  {Object.entries(DIRECTORIES_DATA.service_availability.options).map(([model, label]) => (
                    <Checkbox key={model} model={`service_availability.${model}`} label={label} />
                  ))}
                </CheckboxGroup>

                <Input
                  label="Operating Hours"
                  model="operating_hours"
                  minRows={4}
                  description="The facility's standard hours of operation"
                />
              </FormSection>
            </Form>
          </Accordion>

          {/* Contact Details */}
          <Accordion
            glyph="contact_details"
            initialModel={organization}
            title="Contact Details"
            permission="organizations.general_info.contact_details.view"
            editPermission="organizations.general_info.contact_details.edit"
            activateEditMode
            onSubmit={updateOrganization}
          >
            <Form>
              <FormSection>
                <URLInput glyph="website" label="Website" model="website_url" />

                <Divider className="m-0" />

                <h3>Marketing</h3>
                <Input label="Marketing Contact Name" model="marketing_contact_name" />
                <EmailInput label="Marketing Email Address" model="marketing_email" />
                <PhoneInput label="Marketing Phone Number" model="marketing_phone_no" />

                <h3>Emergency</h3>
                <Input label="Emergency Contact Name" model="emergency_contact_name" />
                <EmailInput label="Emergency Email Address" model="emergency_email" />
                <PhoneInput label="Emergency Phone Number" model="emergency_phone_no" />
                <Input
                  label="Emergency Contact Hours"
                  model="emergency_contact_hours"
                  minRows={4}
                  tooltip="Hours when emergency contacts are available"
                />
              </FormSection>
            </Form>
          </Accordion>

          {/* Address */}
          <Accordion title="Address" initialModel={organization} glyph="address" permission="organizations.general_info.address.view">
            <FormSection labelWidth="150px">
              <AddressSelectorInput
                label="Main Address"
                actionLabel="Update Address"
                value={organization?.address}
                permission="organizations.general_info.address.edit"
                coords={{
                  lat: organization?.lat,
                  lon: organization?.lon,
                }}
                afterAddressSelect={(address) =>
                  apiUpdate({
                    name: 'organizations',
                    url: `/organizations/${organization.id}`,
                    params: { address: address, lat: address?.coords?.lat, lon: address?.coords?.lon },
                  })
                }
              />

              <AddressSelectorInput
                label="Mailing Address"
                actionLabel="Update Address"
                value={organization?.mailing_address}
                permission="organizations.general_info.address.edit"
                afterAddressSelect={(address) =>
                  apiUpdate({
                    name: 'organizations',
                    url: `/organizations/${organization.id}`,
                    params: { mailing_address: address },
                  })
                }
              />
            </FormSection>
          </Accordion>
        </div>
      </Grid>
    </Page>
  )
}

export const DirectoryInfo = withPageError(RootDirectoryInfo)
