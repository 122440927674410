import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../Filters/constants'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Status from '../../components/Status'

export const ClientTracksDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        disableSort: true,
        formatValue: ({ data, value }: any) => {
          return (
            <MainCell
              as={NavLink}
              id={data.id}
              to={to ? to(data) : undefined}
              value={
                <span className="flex items-center">
                  {value}
                  {data?.is_default && <Status small label="Default" color="blue" className="ml-1" />}
                </span>
              }
            />
          )
        },
      },
      {
        title: 'Description',
        model: 'description',
        width: 300,
        disableSort: true,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: false,
      },
    ],
    [to],
  )

  return <DataTable asCard title="Client Tracks" icon="client_tracks" columns={columns} filtersConfig={FILTERS_CONFIG} {...rest} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
