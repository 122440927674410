import React from 'react'
import isUndefined from 'lodash/isUndefined'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'
import { withFormContext } from '../../components/Forms/context'

import { PopoverMenu } from '../../components/PopoverMenu'
import Avatar from '../../components/Avatar'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Loader from '../../components/Loader'
import NavGroup from '../../components/NavGroup'
import NavItem from '../../components/NavItem'
import Overlay from '../../components/Overlay'
import SummonOverlay from '../../components/SummonOverlay'

import { EmployeesTable } from '../../components/Forms/Selectors/tables/EmployeesTable'

const RootAuthorityFormSubmissionAssignmentSelector = (props: any) => {
  const { isFinalStatus, label = 'Assignee:', value, onUpdate, filedUnderClient } = props

  const [data, setData]: any = React.useState(value)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isUpdated, setIsUpdated] = React.useState(false)

  const { isBehave, isManagement } = useSettings()

  const isEditable = !isFinalStatus

  const onSelect = async (record: any) => {
    // update local state
    setData(record)
    setIsOpen(false)

    // call updating function if exists
    if (onUpdate) {
      setIsLoading(true)

      try {
        await onUpdate(record)

        setIsLoading(false)
        setIsUpdated(true)
      } catch (error) {
        setIsLoading(false)
        setIsUpdated(false)
      }
    }
  }

  return (
    <div>
      <SummonOverlay isDisabled={!isEditable} overlay={<SelectorOverlay onSelect={onSelect} filedUnderClient={filedUnderClient} />}>
        <div css={STYLES.trigger}>
          <div css={STYLES.triggerLabel}>{label}</div>
          {data ? (
            <Value label={data?.name} avatar={data?.avatar} />
          ) : (
            <>{isEditable ? <div css={STYLES.triggerEmpty}>Select…</div> : <div className="text-text-strongly-muted">–</div>}</>
          )}

          {isEditable && <Glyph glyph="triangle_down" size={10} css={STYLES.triggerTriangle} />}

          {isLoading && <Loader size={12} color="green" css={{ marginLeft: 5 }} />}
          {isUpdated && <Glyph size={12} glyph="check" />}
        </div>
      </SummonOverlay>

      <PopoverMenu align="start" sideOffset={10} isOpen={isOpen} onOpenUpdated={setIsOpen}></PopoverMenu>
    </div>
  )
}

const Value = ({ label, icon, avatar }: any) => {
  const hasAvatar = !isUndefined(avatar)

  return (
    <div css={STYLES.value}>
      {(icon || hasAvatar) && (
        <div css={STYLES.valueGraphic}>
          {icon && <Icon icon={icon} size={16} />}
          {hasAvatar && <Avatar src={avatar} initials={label} size={20} />}
        </div>
      )}

      <div className="text-blue-500 font-[500]">{label}</div>
    </div>
  )
}

const NAV = [
  {
    label: 'Company',
    navItems: [
      {
        label: 'Staff',
        feature: 'employees',
        component: EmployeesTable,
        type: 'staff',
      },
    ],
  },
] as const

const FLAT_NAV = NAV.reduce((acc: any, navGroup) => {
  return [...acc, ...navGroup.navItems]
}, [])

const SelectorOverlay = (props: any) => {
  const { onClose, onSelect, filedUnderClient } = props

  const handleSelect = (record: any) => {
    onSelect?.(record)
    onClose?.()
  }

  const [activeResource, setActiveResource]: any = React.useState(FLAT_NAV[0])

  const SelectorComponent = activeResource?.component

  return (
    <Overlay
      showBackdrop
      closeOnBackdrop
      position={activeResource ? 'right' : 'center'}
      maxWidth={activeResource ? 80 : 40}
      onClose={onClose}
    >
      <Overlay.Header title="Assign Form Submission To…" icon="web_form" />

      <Overlay.Content className="!bg-white flex flex-nowrap">
        <div className="w-full max-w-[220px] shrink-0 min-w-[0] px-4 py-3 border-r border-0 border-solid border-divider shadow-x-hard-3 relative z-10">
          {filedUnderClient && (
            <NavGroup label="Filed-Under Client">
              <NavItem
                showAvatarInitials
                avatarInitials={filedUnderClient.name}
                label={filedUnderClient.name}
                avatar={filedUnderClient.avatar}
                onClick={() => {
                  onSelect?.(filedUnderClient)
                  onClose?.()
                }}
              />
            </NavGroup>
          )}

          {NAV.map((navGroup) => {
            if (navGroup.hiddenIfClientReference && !!filedUnderClient) return null

            return (
              <NavGroup key={navGroup.label} label={navGroup.label}>
                {navGroup.navItems.map((navItem) => (
                  <NavItem
                    key={navItem.label}
                    label={navItem.label}
                    feature={navItem.feature}
                    onClick={() => {
                      setActiveResource(navItem)
                    }}
                    isActive={activeResource?.type === navItem.type}
                  />
                ))}
              </NavGroup>
            )
          })}
        </div>

        {SelectorComponent && <SelectorComponent minimal onSelect={handleSelect} />}
      </Overlay.Content>
    </Overlay>
  )
}

const STYLES = {
  trigger: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.95rem',
    cursor: 'pointer',
  },

  triggerLabel: {
    fontWeight: 600,
    marginRight: '0.5rem',
    display: 'inline-flex',
  },

  triggerEmpty: {
    color: COLORS.blue,
  },

  triggerTriangle: {
    marginLeft: '0.25rem',
  },

  value: {
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    lineHeight: 1,
  },

  valueGraphic: {
    marginRight: '0.35rem',
  },
}

export const AuthorityFormSubmissionAssignmentSelector = withFormContext(RootAuthorityFormSubmissionAssignmentSelector)
