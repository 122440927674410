import React from 'react'
import { NavLink, Link, Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { titleCase } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { NewOrganizationLeadFormSubmissionOverlay } from '@behavehealth/constructs/OrganizationLeads/NewOrganizationLeadFormSubmissionOverlay'
import { OrganizationLeadFormSubmissionOverlay } from '@behavehealth/constructs/OrganizationLeads/OrganizationLeadFormSubmissionOverlay'
import { OrganizationLeadNewOrganizationOverlay } from '@behavehealth/constructs/OrganizationLeads/OrganizationLeadNewOrganizationOverlay'

import Button from '@behavehealth/components/Button'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import PageHeader from '@behavehealth/components/PageHeader'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'
import State from '@behavehealth/components/State'

export const CommunityLeads = () => {
  const { url } = useRouteURL()

  const leadFormsQuery = useGet({
    name: ['organization_lead_forms'],
    url: `/organization_lead_forms`,
  })

  return (
    <div css={STYLES.root}>
      <div className="bg-white sticky top-0 z-10 flex-0">
        <PageHeader
          title="Organization Leads"
          icon="organization_lead_forms"
          aside={
            <>
              <Button
                label="Manage Lead Contact Forms"
                glyph="settings"
                type="minimal"
                link="/settings/organization-lead-contact-forms"
                permission="settings.organization_lead_forms.create"
              />

              <Dropdown label="Add New Organization Lead" glyph="add" buttonType="primary" permission="organization_lead_forms.create">
                {leadFormsQuery.isLoading || size(leadFormsQuery.data) === 0 ? (
                  <State
                    isLoading={leadFormsQuery.isLoading}
                    isEmpty={size(leadFormsQuery.data)}
                    minHeight={100}
                    title="Organization Lead Contact Forms"
                    icon="organization_lead_forms"
                    emptyDescription="No Organization Lead Contact Forms created yet"
                    emptyActions={
                      <Button
                        label="Manage Lead Contact Forms"
                        glyph="settings"
                        type="minimal"
                        link="/settings/organization-lead-contact-forms"
                        permission="settings.organization_lead_forms.create"
                        size={100}
                      />
                    }
                  />
                ) : (
                  <>
                    {leadFormsQuery.data?.map?.((form: any) => (
                      <DropdownItem
                        key={form.id}
                        as={Link}
                        link={`/community/leads/new/new-lead/${form.id}`}
                        icon="organization_lead_forms"
                        permission="organization_lead_forms.create"
                        label={form.name}
                      />
                    ))}
                  </>
                )}
              </Dropdown>
            </>
          }
        />

        <Tabs>
          <Tabs.List className="px-4">
            <Tabs.Item as={NavLink} label="New" to={`new`} />
            <Tabs.Item as={NavLink} label="Archived" to={`archived`} />
          </Tabs.List>
        </Tabs>
      </div>

      <div css={STYLES.content}>
        <Routes>
          <Route index element={<Navigate to="new" replace />} />
          <Route path=":status/*" element={<CommunityLeadsDataTable />} />
        </Routes>
      </div>

      <AnimatedRoutes>
        <Route path=":status/:id" element={<OrganizationLeadFormSubmissionOverlay useV6Router />} />
        <Route path=":status/new-lead/:form_id" element={<NewOrganizationLeadFormSubmissionOverlay useV6Router back={url} />} />
      </AnimatedRoutes>
    </div>
  )
}

export const CommunityLeadsDataTable = () => {
  const { status }: any = useParams()

  const tableProps = useDataTable({
    name: ['organization_lead_form_submissions'],
    endpoint: '/organization_lead_form_submissions',
    params: {
      status: status === 'new' ? 'lead' : status,
    },
    localStorageKey: 'organization_lead_form_submissions_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Organization Name',
        model: 'data.organization.name',
        width: 300,
        disableHide: true,
        // disableSort: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => (
          <MainCell testKey="main_cell" as={NavLink} id={data.id} value={value} avatar={''} to={`${data.id}`} />
        ),
      },
      {
        title: 'Lead Contact Form',
        model: 'name',
        width: 200,
      },
      {
        title: 'Actions',
        id: 'actions',
        width: 180,
        disableSort: true,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          if (!!data?.organization?.id) return null

          return (
            <SummonOverlay
              overlay={
                <OrganizationLeadNewOrganizationOverlay
                  dataID="new"
                  formID={data.id}
                  initialData={{
                    organization: data?.data?.organization,
                    locations: data?.data?.locations,
                    programs: data?.data?.programs,
                    contacts: data?.data?.contacts,
                    relationship: data?.organization_lead_form?.organization_relationship,
                  }}
                />
              }
            >
              <Button label="Create Organization" size={100} glyph="add" display="flex" />
            </SummonOverlay>
          )
        },
      },
      {
        title: 'Organization',
        model: 'organization',
        width: 240,
        disableSort: true,
        type: 'profile',
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'organizations',
      },
      {
        title: 'Source',
        model: 'source',
        type: 'title',
      },
      {
        title: 'Phone',
        model: 'data.organization.phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'data.organization.email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'data.organization.website',
        type: 'external_url',
        disableSelect: true,
      },
      {
        title: 'Notes',
        model: 'data.organization.additional_info',
      },
      {
        title: 'Address',
        model: 'data.organization.address',
        type: 'address',
        disableSort: true,
        width: 200,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <>
      <DataTable
        asCard
        {...tableProps}
        title={`${titleCase(status)} Organization Leads`}
        icon="organization_lead_forms"
        columns={columns}
      />
    </>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    overflowY: 'auto',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '100%',
  },
}
