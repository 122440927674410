import React from 'react'

import AddressSelectorInput from '../../../components/Forms/AddressSelectorInput'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import DateInput from '../../../components/Forms/DateInput'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import EmailInput from '../../../components/Forms/EmailInput'
import Input from '../../../components/Forms/Input'
import NumberInput from '../../../components/Forms/NumberInput'
import Option from '../../../components/Forms/Option'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Select from '../../../components/Forms/Select'
import SignatureInput from '../../../components/Forms/SignatureInput'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import URLInput from '../../../components/Forms/URLInput'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import Attachments from '../../../components/Forms/Attachments'

const CustomAttachments = ({ description, fieldLabel, fieldModel }) => {
  const [show, setShow] = React.useState(false)

  return (
    <div className="grid content-start gap-2">
      <Checkbox
        label={fieldLabel}
        model={`show_attachments.${fieldModel}`}
        checked={show}
        onUpdate={({ value }) => setShow(value)}
        description={description}
        withHover={false}
      />

      {show && <Attachments label={fieldLabel} model={`attachments.${fieldModel}`} />}
    </div>
  )
}

export const DOCUMENT_REVIEW_CHECKLIST = [
  {
    title: 'Mission and Legal Documents',
    model: 'mission_and_legal_documents',
    fields: [
      {
        label: 'Mission and Vision Statements',
        model: 'mission_and_vision_statements',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 1.A, 1.B" />,
      },
      {
        label: 'Legal Documents',
        model: 'legal_documents',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 2.A" />,
      },
      {
        label: 'Proof of Appropriate Liability Insurance',
        model: 'liability_insurance_proof',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 2.B" />,
      },
      {
        label: 'Property Owner Statement',
        model: 'property_owner_statement',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 2.C" />,
      },
      {
        label: 'Nondiscrimination Policy',
        model: 'nondiscrimination_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 2.D" />,
      },
      {
        label: 'Marketing Material - Brochure, Website, Or Social Media Page',
        model: 'marketing_material',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 2.E" />,
      },
    ],
  },
  {
    title: 'Staff and Resident Policies',
    model: 'staff_and_resident_policies',
    fields: [
      {
        label: 'Staff Background Checks',
        model: 'staff_background_checks',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 2.F" />,
      },
      {
        label: 'Resident Paid Work Policy',
        model: 'resident_paid_work_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 2.G" />,
      },
      {
        label: 'Staff And Resident Financial Policy',
        model: 'staff_and_resident_financial_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 2.H" />,
      },
      {
        label: 'Staff Code Of Ethics Policy',
        model: 'staff_code_of_ethics_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 2.I" />,
      },
      {
        label: 'Resident Financial Policy',
        model: 'resident_financial_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 3.A, 3.B, 3.C, 3.D" />,
      },
      {
        label: 'Personal Data Policy',
        model: 'personal_data_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 4.A" />,
      },
    ],
  },
  {
    title: 'Resident Agreement and Rights',
    model: 'resident_agreement_and_rights',
    fields: [
      {
        label: 'Resident Agreement / Handbook',
        model: 'resident_agreement_handbook',
        component: (rest) => (
          <CustomAttachments {...rest} description="NARR Standard 3.0 – 5.A, 7.D, 27.A, 27.B, 27.C, 27.D, 27.E, 27.F, 29.D, 29.E" />
        ),
      },
      {
        label: 'Statement Of Residents Rights',
        model: 'statement_of_residents_rights',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 5.A" />,
      },
      {
        label: 'Confidentiality Policy',
        model: 'confidentiality_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 6.A, 6.B, 6.C" />,
      },
      {
        label: 'House Rules',
        model: 'house_rules',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 7.A, 7.E, 18.A" />,
      },
      {
        label: 'Grievance Policy',
        model: 'grievance_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 7.B" />,
      },
    ],
  },
  {
    title: 'House Management and Staffing',
    model: 'house_management_and_staffing',
    fields: [
      {
        label: 'House Manager Guidelines',
        model: 'house_manager_guidelines',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 8.B, 21.C" />,
      },
      {
        label: 'Staffing Policy',
        model: 'staffing_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 10.A, 10.B. 10.C, 11.A, 13.A" />,
      },
      {
        label: 'Staff Job Descriptions',
        model: 'staff_job_descriptions',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 12.A, 12.B, 12.C" />,
      },
    ],
  },
  {
    title: 'Substance Use and Safety Policies',
    model: 'substance_use_and_safety_policies',
    fields: [
      {
        label: 'Alcohol And Drug Policy',
        model: 'alcohol_and_drug_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 16.A, 16.B, 16.E" />,
      },
      {
        label: 'Drug Testing Policy',
        model: 'drug_testing_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 16.C" />,
      },
      {
        label: 'Medication Policy',
        model: 'medication_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 16.D" />,
      },
      {
        label: 'Emergency Policy',
        model: 'emergency_policy',
        component: (rest) => <CustomAttachments {...rest} description="NARR Standard 3.0 – 16.E, 17.C, 19, 19.B" />,
      },
    ],
  },
  {
    title: 'Affiliate Documents',
    model: 'affiliate_documents',
    fields: [
      {
        label: 'Affiliate - Assurances',
        model: 'affiliate_assurances',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Affiliate - Accreditation Agreement',
        model: 'affiliate_accreditation_agreement',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Affiliate - Grievance Policy',
        model: 'affiliate_grievance_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Affiliate - Incident Policy',
        model: 'affiliate_incident_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
    ],
  },
  {
    title: 'Resident Management Policies',
    model: 'resident_management_policies',
    fields: [
      {
        label: 'Resident Screening Policy & Procedure',
        model: 'resident_screening_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Resident Orientation Policy & Procedure',
        model: 'resident_orientation_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Hardship Scholarship Assessment Policy',
        model: 'hardship_scholarship_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Good Neighbor Policy',
        model: 'good_neighbor_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Hazardous Items Search Policy & Procedure',
        model: 'hazardous_items_search_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
    ],
  },
  {
    title: 'Facility Management',
    model: 'facility_management',
    fields: [
      {
        label: 'Life Skills Development Program Overview & Supporting Documentation (LEVEL III & IV Only)',
        model: 'life_skills_development_program',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Maintenance Repair',
        model: 'maintenance_repair',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Fire Safety Compliance',
        model: 'fire_safety_compliance',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Codes Compliance',
        model: 'codes_compliance',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Letter from HOA if applicable',
        model: 'hoa_letter',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Letter from Landlord (if applicable)',
        model: 'landlord_letter',
        component: (rest) => <CustomAttachments {...rest} />,
      },
    ],
  },
  {
    title: 'Financial and Administrative',
    model: 'financial_and_administrative',
    fields: [
      {
        label: 'Sample statement of resident payments, balances, charges',
        model: 'sample_resident_statement',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Accounting system (name or describe in detail)',
        model: 'accounting_system',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Residents records secure storage policy',
        model: 'resident_records_storage_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Community Privacy Policy',
        model: 'community_privacy_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Prohibited Items Policy',
        model: 'prohibited_items_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
    ],
  },
  {
    title: 'Operational Documents',
    model: 'operational_documents',
    fields: [
      {
        label: 'Daily Accountability Sheet',
        model: 'daily_accountability_sheet',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Courtesy Policies',
        model: 'courtesy_policies',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'NARR Code of Ethics',
        model: 'narr_code_of_ethics',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Affiliate Code of Ethics',
        model: 'affiliate_code_of_ethics',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Priority Population',
        model: 'priority_population',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Cultural Competency Training',
        model: 'cultural_competency_training',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Organizational Chart/Structure',
        model: 'organizational_chart',
        component: (rest) => <CustomAttachments {...rest} />,
      },
    ],
  },
  {
    title: 'Legal and Compliance',
    model: 'legal_and_compliance',
    fields: [
      {
        label: 'Rental License',
        model: 'rental_license',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Reasonable Accommodations',
        model: 'reasonable_accommodations',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Certificate of Occupancy',
        model: 'certificate_of_occupancy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Municipal Lien Certificate',
        model: 'municipal_lien_certificate',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Letter of Recommendation',
        model: 'letter_of_recommendation',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Floor plan of each residence',
        model: 'floor_plan',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Local zoning documentation',
        model: 'local_zoning_documentation',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Fire marshal inspection',
        model: 'fire_marshal_inspection',
        component: (rest) => <CustomAttachments {...rest} />,
      },
    ],
  },
  {
    title: 'Additional Policies',
    model: 'additional_policies',
    fields: [
      {
        label: 'Camera Policy',
        model: 'camera_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Disclaimer',
        model: 'disclaimer',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Exit planning checklist',
        model: 'exit_planning_checklist',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Exit planning policy',
        model: 'exit_planning_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'House Meeting',
        model: 'house_meeting',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Privacy Policy',
        model: 'privacy_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Program Orientation Checklist',
        model: 'program_orientation_checklist',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Recovery Plan Info',
        model: 'recovery_plan_info',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Recovery Planning Protocol',
        model: 'recovery_planning_protocol',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Secretary of State Letter of Good Standing',
        model: 'secretary_of_state_letter',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Pest Infestation Policy',
        model: 'pest_infestation_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Evacuation Map',
        model: 'evacuation_map',
        component: (rest) => <CustomAttachments {...rest} />,
      },
    ],
  },
  {
    title: 'Staff Documents',
    model: 'staff_documents',
    fields: [
      {
        label: 'Staff Evaluation Policy & Procedure',
        model: 'staff_evaluation_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Staff Certification and Verification Policy & Procedure',
        model: 'staff_certification_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Sample Staff Schedule',
        model: 'sample_staff_schedule',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Staff Wellness & Selfcare Policy & Procedure',
        model: 'staff_wellness_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Staff Development Policy & Procedure',
        model: 'staff_development_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Supervision of Staff Policy & Procedure',
        model: 'staff_supervision_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Staff Drug Testing Policy & Procedure',
        model: 'staff_drug_testing_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Job Descriptions - All (staff, peer leader, peer support, etc)',
        model: 'job_descriptions',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Employee files',
        model: 'employee_files',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'List of staff',
        model: 'staff_list',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Social media policy',
        model: 'social_media_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Staff code of conduct',
        model: 'staff_code_of_conduct',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Staff Directory',
        model: 'staff_directory',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Community resources',
        model: 'community_resources',
        component: (rest) => <CustomAttachments {...rest} />,
      },
    ],
  },
  {
    title: 'Resident Agreement/Handbook',
    model: 'resident_agreement_handbook',
    fields: [
      {
        label: 'Resident Application',
        model: 'resident_application',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Services provided',
        model: 'services_provided',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Recovery goals',
        model: 'recovery_goals',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Relapse policies',
        model: 'relapse_policies',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Policies that promote resident-driven length of stay',
        model: 'resident_driven_length_of_stay_policies',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Residents are involved in food preparation',
        model: 'resident_food_preparation',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Residents have a voice in determining with whom they live',
        model: 'resident_living_arrangements',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Residents help maintain and clean the home / chores',
        model: 'resident_chores',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Share household expenses',
        model: 'shared_household_expenses',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Community or residence meetings are held at least once a week',
        model: 'community_meetings',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Residents have access to common areas of the home',
        model: 'common_area_access',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Information on how the operator may end the resident agreement / exit',
        model: 'operator_exit_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Information on how the resident may end the resident agreement / exit',
        model: 'resident_exit_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Information about what will happen to any resident property that is left in the home after the resident has vacated',
        model: 'resident_property_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label:
          'Documentation that residents are formally linked with the community such as job search, family services, health and/or housing programs',
        model: 'community_linkage_documentation',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label:
          'Documentation that residents and staff engage in community relations and interactions to promote kinship with other recovery communities and goodwill for recovery services',
        model: 'community_engagement_documentation',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'The resident agreement does not contain statements that request a resident waive housing, landlord tenant or other rights',
        model: 'no_rights_waiver',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label:
          'The resident agreement does not contain statements that residents must leave the recovery home after a specified amount of time or that length of residency is determined arbitrarily or by a third-party payer',
        model: 'no_arbitrary_exit',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label:
          'The resident agreement does not contain statements that require residents to receive services from a specific organization in order to maintain housing',
        model: 'no_required_external_services',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Confidentiality',
        model: 'confidentiality',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Staff/Management Contact Sheet',
        model: 'staff_contact_sheet',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Program phases/steps',
        model: 'program_phases',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Sample resident weekly schedule',
        model: 'resident_weekly_schedule',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'House rules and consequences',
        model: 'house_rules_and_consequences',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Good neighbor policy, procedure, & consent',
        model: 'good_neighbor_policy_consent',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Hazardous Items search policy',
        model: 'hazardous_items_search_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Medication storage/use/management',
        model: 'medication_management',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Drug testing policies',
        model: 'drug_testing_policies',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Discharge policy',
        model: 'discharge_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Emergency policy',
        model: 'emergency_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Emergency/non-emergency contact sheet',
        model: 'emergency_contact_sheet',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Grievance policy and form',
        model: 'grievance_policy_form',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Maintenance/repair',
        model: 'maintenance_repair',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Resident lead/financial obligations',
        model: 'resident_financial_obligations',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Community resource guide',
        model: 'community_resource_guide',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Criteria to be a resident',
        model: 'resident_criteria',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Infectious Disease',
        model: 'infectious_disease',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Resident Data Sheet',
        model: 'resident_data_sheet',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Emergency contact collected from residents',
        model: 'resident_emergency_contact',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Consent to release information',
        model: 'information_release_consent',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Resident paid work agreement',
        model: 'resident_paid_work_agreement',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Resident property searches',
        model: 'resident_property_searches',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Smoking policy',
        model: 'smoking_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Narcan/naloxone training',
        model: 'narcan_training',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Employer relationship agreement',
        model: 'employer_relationship_agreement',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Curfew',
        model: 'curfew',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Vehicle/parking',
        model: 'vehicle_parking',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Social media policy',
        model: 'social_media_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Nondiscrimination',
        model: 'nondiscrimination',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Data collection policy',
        model: 'data_collection_policy',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Personal property',
        model: 'personal_property',
        component: (rest) => <CustomAttachments {...rest} />,
      },
      {
        label: 'Waiver of liability',
        model: 'liability_waiver',
        component: (rest) => <CustomAttachments {...rest} />,
      },
    ],
  },
]
