import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'

import { OrganizationLeadForm } from './OrganizationLeadForm'
import { OrganizationLeadNewOrganizationOverlay } from './OrganizationLeadNewOrganizationOverlay'

const RootOrganizationLeadFormSubmissionOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'organization_lead_form_submissions',
    endpoint: '/organization_lead_form_submissions',
    invalidate: 'organization_lead_form_submissions',
    disableParentRequest: true,
    options: props,
  })

  const { timezone } = useSettings()

  const [organization, setOrganization] = React.useState(props.organization || data?.organization)

  const save = async () => {
    const formData = form.current.getFormValue()

    await saveWithData({
      data: formData,
      organization_id: organization?.id || null,
    })
  }

  const { mutateAsync: archiveAsync, isLoading: isArchiving } = useUpdate({
    name: ['organization_lead_form_submissions', id],
    url: `/organization_lead_form_submissions/${id}/archive`,
    invalidate: 'organization_lead_form_submissions',
  })

  const { mutateAsync: unarchiveAsync, isLoading: isUnarchiving } = useUpdate({
    name: ['organization_lead_form_submissions', id],
    url: `/organization_lead_form_submissions/${id}/unarchive`,
    invalidate: 'organization_lead_form_submissions',
  })

  const toggleArchive = async () => {
    if (data?.archived_at) {
      await unarchiveAsync({})
    } else {
      await archiveAsync({})
    }
  }

  React.useEffect(() => {
    if (!data) return

    setOrganization(data.organization)
  }, [data])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={55} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={55}>
      <Overlay.Header icon="organization_lead_forms" title="Organization Lead Contact Form" />

      <Overlay.Content>
        <Section>
          <div className="pb-2 mb-4">
            <FormSection maxWidth="100%">
              <OverlaySelector
                isEditable={isEditable}
                label="Linked Organization"
                blankLabel="Select organization…"
                icon="organizations"
                type="organizations_with_relationships"
                value={organization}
                onUpdate={({ object }) => {
                  setOrganization(object)
                }}
                selectTitle={(data) => data.name}
                selectDescription={() => null}
                labelAfter={
                  isEditable && (
                    <SummonOverlay
                      overlay={
                        <OrganizationLeadNewOrganizationOverlay
                          dataID="new"
                          formID={data.id}
                          onSaveSuccessful={(o) => {
                            console.log('onSaveSuccessful', o)
                          }}
                          initialData={{
                            organization: data?.data?.organization,
                            locations: data?.data?.locations,
                            contacts: data?.data?.contacts,
                            relationship: data?.organization_lead_form?.organization_relationship,
                          }}
                        />
                      }
                    >
                      <Button label={`Add New`} size={100} glyph="add" type="minimal" className="-mr-1.5" />
                    </SummonOverlay>
                  )
                }
              />

              <Divider className="!m-0" />
            </FormSection>
          </div>

          <OrganizationLeadForm
            form={form}
            isEditable={isEditable}
            timezone={timezone}
            schema={data?.schema}
            initialModel={initialModel?.data}
            onValidationUpdate={onValidationUpdate}
            key={`updated-${data?.updated_at}`}
          />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              flex="100 1 auto"
              permission={isNew ? 'organization_lead_forms.create' : 'organization_lead_forms.edit'}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Lead Contact Form"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="organization_lead_forms.edit"
            />

            {data?.archived_at ? (
              <Button
                label="Un-archive"
                glyph="delete"
                type="default"
                color="gray"
                onClick={toggleArchive}
                isLoading={isUnarchiving}
                permission="organization_lead_forms.edit"
              />
            ) : (
              <Button
                label="Archive"
                glyph="delete"
                type="default"
                color="gray"
                onClick={toggleArchive}
                isLoading={isArchiving}
                permission="organization_lead_forms.edit"
              />
            )}

            <DeleteDialog
              title="Delete Lead Contact Form?"
              message="Are you sure you want to delete this Lead Contact Form? This action cannot be undone."
              onYes={deleteRecord}
              permission="organization_lead_forms.delete"
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                permission="organization_lead_forms.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const OrganizationLeadFormSubmissionOverlay = withOverlayError(RootOrganizationLeadFormSubmissionOverlay)
