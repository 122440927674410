import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { getVersionSlug } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Chotomate, Card, Dropdown, DropdownItem, Page, Grid, HelpTagIframe } from '@behavehealth/components'

import { CLINICAL_ASSESSMENTS_SLUGS } from '@behavehealth/utils/constants'
import { ClinicalAssessmentsDataTable } from '@behavehealth/constructs/ClinicalAssessments/ClinicalAssessmentsDataTable'
import { DataFormAddDropdown } from '@behavehealth/constructs/DataFormsBuilder/DataFormAddDropdown'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { StaffDataFormsAlert } from '../components/StaffDataFormsAlert'

import { getBatchActions, getCanSelectDataForm } from '../utils/dataForms'
import { useIsMyProfile } from '../utils/useIsMyProfile'
import { useStaffDataFormTabs, StaffDataFormTabs } from '../components/StaffDataFormTabs'

const pageConfig = {
  feature: 'clinical_assessments',
  help: <HelpTagIframe id="clinical_assessments" />,
  marketingID: 'clinical_assessments',
}

type Props = {
  canCreate: boolean
}

const ClinicalAssessments: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const resourceID = match.params?.resource_id

  const isMyProfile = useIsMyProfile()
  const { activeTab, setActiveTab, queryParams } = useStaffDataFormTabs()

  const tableProps = useDataTable({
    name: ['employee', resourceID, 'data_forms'],
    endpoint: `/employees/${resourceID}/data_forms`,
    params: { category: 'clinical_assessment', ...queryParams },
    updateDeleteEndpoint: '/data_forms',
    localStorageKey: 'employee_clinical_assessments_v1',
  })

  const to = React.useMemo(
    () => (record: any) => {
      const { version, subcategory, id } = record

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

      return {
        pathname: `${match.url}/${CLINICAL_ASSESSMENTS_SLUGS[subcategory]}/${id}${formattedVersion}`,
        parent: match,
      }
    },
    [match.url],
  )

  return (
    <Page {...pageConfig}>
      <Grid gap="1rem">
        <Chotomate name="clinical_assessments" ready={!tableProps.isLoading} />

        <StaffDataFormsAlert name="Clinical Assessments" />

        <StaffDataFormTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        <Card>
          <ClinicalAssessmentsDataTable
            testKey="clinical_assessments_table"
            {...tableProps}
            to={to}
            key={`tab-${activeTab}`}
            getCanSelect={getCanSelectDataForm}
            headerLinksConfig={[
              {
                type: 'report',
                to: '/reports/clinical-assessments',
              },
            ]}
            batchActionsConfig={getBatchActions({ isMyProfile, activeTab, tableProps, permissionBase: 'clinical_assessments' })}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ClinicalAssessments, pageConfig))
