import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { Button, Page, HelpTagIframe } from '@behavehealth/components'
import { StaffCredentialsDataTable } from '@behavehealth/constructs/StaffCredentials/StaffCredentialsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'staff_credentials',
  help: <HelpTagIframe id="staff_credentials" />,
  title: 'Credentials',
}

const Credentials: React.FC = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['employee', resource_id, 'credentials'],
    endpoint: `/employees/${resource_id}/credentials`,
    updateDeleteEndpoint: `/credentials`,
    localStorageKey: 'employee_credentials_v1',
  })

  const actions = (
    <Button
      label="Add Credential"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
      }}
      permission="credentials.create"
    />
  )

  return (
    <Page actions={actions} {...pageConfig}>
      <div className="grid grid-cols-[100%]">
        <StaffCredentialsDataTable
          {...tableProps}
          to={(row: any) => `${match.url}/${row.id}`}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'credentials.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(Credentials, pageConfig))
