import React from 'react'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'

import Accordion from '../../components/Accordion'
import Alert from '../../components/Alert'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Markup from '../../components/Markup'
import Textarea from '../../components/Forms/Textarea'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardTitle from '../../components/CardTitle'
import DataArray from '../../components/Forms/DataArray'

import { data } from './application_form_data'
import Divider from '../../components/Divider'

type Props = {
  companyName: string
  roiDisclaimerMessage?: string
  schema: object
  form: React.RefObject<any>
  initialModel?: any
  isEditable?: boolean
  onUpdate?: Function
  onValidationUpdate?: Function
  timezone?: string
  forceOpenAccordion?: boolean
  linked?: object
}

export const checkShowGroup = (groupSchema: any) => {
  if (!groupSchema || !groupSchema.show) return false

  let res = true

  if (groupSchema.hasOwnProperty('fields')) {
    let isAnyFieldVisible = false

    for (const key in groupSchema.fields) {
      if (groupSchema.fields[key].show) {
        isAnyFieldVisible = true
        break
      }
    }

    res = isAnyFieldVisible
  }

  return res
}

export const OrganizationLeadForm: React.FC<Props> = (props: any) => {
  const { form, initialModel, isEditable, linked, onUpdate, onValidationUpdate, schema, timezone, before } = props

  if (!schema) return null

  return (
    <Form
      autoFocus={false}
      getForm={form}
      timezone={timezone}
      onUpdate={onUpdate}
      isEditable={isEditable}
      initialModel={initialModel}
      onValidationUpdate={onValidationUpdate}
      linked={linked}
    >
      {before}

      <Grid gap="0.75rem">
        {data.map((group: any, groupIdx) => {
          const groupSchema = schema?.[`${group.model}`]
          const shouldShow = checkShowGroup(groupSchema)

          if (group.model === 'custom' && size(groupSchema?.questions) === 0) return null

          if (!shouldShow) return null

          if (group.isDataArray) {
            return (
              <React.Fragment key={group.model}>
                {groupIdx > 0 && <Divider />}

                <h2 id={group.model} className="pt-3">
                  {group.title}
                </h2>

                {groupSchema?.custom_text_before && <Markup value={groupSchema?.custom_text_before} />}

                <DataArray model={group.model}>
                  {({ orderedIds, add, remove, isEditable }) => (
                    <>
                      {orderedIds?.map((id: string, idx: number) => (
                        <Card key={id}>
                          <CardHeader
                            after={
                              isEditable && (
                                <Button type="minimal" color="red" glyph="delete" label="Remove" onClick={() => remove(id)} size={100} />
                              )
                            }
                          >
                            <CardTitle title={`${group.title} #${idx + 1}`} />
                          </CardHeader>

                          <div className="px-3 pb-4">
                            <SectionRenderer group={group} groupIdx={groupIdx} schema={schema} dataArrayId={id} />
                          </div>
                        </Card>
                      ))}

                      {isEditable && (
                        <div>
                          <Button
                            label={`Add New ${group.title}`}
                            glyph="add"
                            type="default"
                            display="inline-flex"
                            size={200}
                            onClick={add}
                          />
                        </div>
                      )}
                    </>
                  )}
                </DataArray>
              </React.Fragment>
            )
          }

          return (
            <React.Fragment key={group.model}>
              {groupIdx > 0 && <Divider />}

              <h2 id={group.model} className="pt-3">
                {group.title}
              </h2>

              {groupSchema?.custom_text_before && <Markup value={groupSchema?.custom_text_before} />}

              <SectionRenderer group={group} groupIdx={groupIdx} schema={schema} />
            </React.Fragment>
          )
        })}
      </Grid>
    </Form>
  )
}

const SectionRenderer = (props: any) => {
  const { schema, group, groupIdx, dataArrayId } = props

  return (
    <FormSection maxWidth="100%">
      {group.description && schema[`${group.model}`].description && (
        <Alert type="default" glyph="info" className="!mt-2">
          <Markup value={schema[`${group.model}`].description} />
        </Alert>
      )}

      {group.fields?.map((field, fieldIdx) => {
        const fieldData = data[groupIdx].fields[fieldIdx]
        const fieldSettings = schema[`${group.model}`]?.fields[`${field.model}`]

        return <React.Fragment key={field.model}>{fieldSettings?.show && fieldData.component({ dataArrayId })}</React.Fragment>
      })}

      {size(schema[`${group.model}`]?.fields?.custom_questions?.questions) > 0 && (
        <>
          {schema[`${group.model}`].fields.custom_questions.questions.map((o: any) => (
            <Textarea
              key={o.id}
              label={o.question}
              model={`${dataArrayId || group.model}.custom_questions.${snakeCase(o.question)}`}
              validations={
                o.required && {
                  presence: {
                    message: 'Please enter a response',
                  },
                }
              }
            />
          ))}
        </>
      )}
    </FormSection>
  )
}
