import React from 'react'

import { COMMUNICABLE_DISEASE_TESTS } from '../../utils/constants'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SignaturePad from '../../components/Forms/SignaturePad'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import Switch from '../../components/Forms/Switch'
import Timeline from '../../components/Timeline/Timeline'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

const RootCommunicableDiseaseTestOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'communicable-disease-tests',
    endpoint: '/communicable_disease_tests',
    invalidate: 'communicable-disease-tests',
    options: props,
    openAfterCreate: true,
  })

  const { isPortal, tenant, timezone } = useSettings()

  const isTrialing = tenant?.plan_status === 'trialing'

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay showBackdrop={isEditable} onClose={close}>
      <Overlay.Header
        icon="test_results"
        title="Communicable Disease Test"
        help={<HelpTagIframe id="test_results_communicable_disease_tests" />}
      />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/communicable_disease_tests/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection>
              <OverlaySelector
                initialModelRequired={isNew}
                model="client"
                label="Client"
                type="clients.current"
                icon="clients"
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.behave_id}
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />
              <Select
                allowEmpty
                label="Test Type"
                model="category"
                validations={{
                  presence: {
                    message: 'Please select a test type',
                  },
                }}
              >
                {Object.keys(COMMUNICABLE_DISEASE_TESTS).map((test) => (
                  <Option label={COMMUNICABLE_DISEASE_TESTS[test]} value={test} />
                ))}
              </Select>

              <RadioGroup
                model="status"
                label="Status"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select a status',
                  },
                }}
              >
                <Radio label="Collected" value="collected" />
                <Radio label="Not Collected" value="refused" />
              </RadioGroup>

              <DateTimeInput defaultToNow label="Date & Time" model="recorded_at" />

              <FormFutureDateWarning dateLabel="Date & Time" model="recorded_at" />

              <ContextShow when="status" is="collected">
                <RadioGroup
                  model="result"
                  label="Result"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select a result',
                    },
                  }}
                >
                  <Radio label="Positive" value="positive" />
                  <Radio label="Negative" value="negative" />
                  <Radio label="Not Applicable" value="not_available" />
                </RadioGroup>
              </ContextShow>

              <ContextShow when="result" is="positive">
                <Input
                  label="Result Quantitative Measure"
                  description="e.g. more details, number of antibodies"
                  model="quantitative_measure"
                />
              </ContextShow>

              <SmartTextarea useDictation useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          {tenant.is_billed && (
            <>
              <Section
                headingType="h2"
                title="Billing"
                description="Use this section to record the billable service"
                aside={<Switch defaultValue={false} model="is_billable" />}
              >
                <ContextShow when="is_billable" is={true}>
                  <FormSection maxWidth="100%">
                    <ObjectSelector
                      dependent="client_id"
                      model="insurance_service"
                      label="Rendered Insurance Service"
                      type="client.insurance_services"
                      icon="billing"
                      searchKeys={['code', 'short_description', 'long_description']}
                      selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
                      selectDescription={(o: any) => o?.long_description}
                      groupBy={{
                        accessor: 'service_type',
                        sections: {
                          in_network: 'In Network',
                          out_of_network: 'Out of Network',
                          global_oon: 'Global Out of Network',
                        },
                      }}
                      validations={{
                        presence: {
                          message: 'Please select a service',
                        },
                      }}
                    />

                    <SmartTextarea useDictation useQuickText model="billing_notes" label="Notes" />
                  </FormSection>
                </ContextShow>
              </Section>

              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload any files related to this Communicable Disease Test">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section
            headingType="h2"
            title="Signatures"
            description="Use this section to record signatures"
            aside={<Switch defaultValue={false} model="is_signable" />}
          >
            <ContextShow when="is_signable" is={true}>
              <FormSection layout="vertical" className="!pt-4">
                <SignaturePad
                  showSignedBy={false}
                  label="Client Signature"
                  model="client_signature"
                  signedAtModel="client_signed_at"
                  allowPin={false}
                  showAccept={false}
                />

                <SignaturePad
                  allowPin
                  person={data?.signed_by}
                  label="Staff Signature"
                  model="employee_signature"
                  signedAtModel="author_signed_at"
                  showAccept={false}
                />
              </FormSection>
            </ContextShow>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {!isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save Communicable Disease Test"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission="test_results.communicable_disease_tests.create"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit Communicable Disease Test"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="test_results.communicable_disease_tests.edit"
              />

              <DeleteDialog
                title="Delete Communicable Disease Test?"
                message="Are you sure you want to delete this Communicable Disease Test? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="test_results.communicable_disease_tests.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const CommunicableDiseaseTestOverlay = withOverlayError(RootCommunicableDiseaseTestOverlay)
