import React from 'react'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'

import { fullname } from '../utils/functions'

import { Text } from './Typography'
import Status from './Status'
import Flex from './Flex'

const ContactProfileHeader: React.FC<any> = ({ contact }) => {
  if (!contact) return null

  return (
    <Flex gap={6}>
      <Text avatar={contact.avatar} label={fullname(contact)} />
      <Status label="Contact" color="orange" />
      {(contact.sex || contact.relationship) && (
        <Text>{compact([contact.sex && startCase(contact.sex), contact.relationship].join(' • '))}</Text>
      )}
    </Flex>
  )
}

export default ContactProfileHeader
