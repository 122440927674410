import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Employee } from '@behavehealth/declarations'
import { FilesDataTable } from '@behavehealth/constructs/Files/FilesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, CardContent, DnDUploader, HelpTagIframe, Page, PageLayout, Uploader, Permission } from '@behavehealth/components'

const pageConfig = {
  feature: 'files',
  help: <HelpTagIframe id="files" />,
  marketingID: 'files',
}

const Files: React.FC = () => {
  const match = useRouteMatch()
  const employee: Employee = useSelector((state) => state.data?.employees?.data?.[match?.params?.resource_id])

  const tableProps: any = useDataTable({
    name: ['employees', match.params?.resource_id, 'files'],
    endpoint: `/employees/${match.params?.resource_id}/documents`,
    updateDeleteEndpoint: '/documents',
    params: { category: 'general' },
    enabled: !!match.params?.resource_id,
    localStorageKey: 'employee_files_v1',
  })

  const actions = (
    <Uploader category="general" resource={employee}>
      <Button type="primary" glyph="upload" label="Upload Files" permission="employees.files.create" />
    </Uploader>
  )

  return (
    <Page actions={actions} {...pageConfig}>
      <PageLayout>
        <Card>
          <FilesDataTable
            {...tableProps}
            title="Staff Files"
            to={(row: any) => `${match.url}/${row.id}`}
            headerLinksConfig={[
              {
                type: 'report',
                to: '/reports/employees-files',
              },
            ]}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'employees.files.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />

          <CardContent>
            <Permission permission="employees.files.create">
              <DnDUploader category="general" queryKey={tableProps.queryKey} resource={employee} isEditable={true} isDisabled={false} />
            </Permission>
          </CardContent>
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(Files, pageConfig))
