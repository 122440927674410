import React from 'react'
import { Link, NavLink, Route, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'

import Button from '@behavehealth/components/Button'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import State from '@behavehealth/components/State'
import Status from '@behavehealth/components/Status'

import { NewOrganizationLeadFormSubmissionOverlay } from '@behavehealth/constructs/OrganizationLeads/NewOrganizationLeadFormSubmissionOverlay'
import { OrganizationLeadFormSubmissionOverlay } from '@behavehealth/constructs/OrganizationLeads/OrganizationLeadFormSubmissionOverlay'

export const LeadApplicationForms = () => {
  const { url } = useRouteURL()
  const { resource_id }: any = useParams()

  const { data: organization }: any = useGet({
    name: ['organization', resource_id],
    url: `/organizations/${resource_id}`,
  })

  return (
    <>
      <LeadApplicationFormsIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<OrganizationLeadFormSubmissionOverlay useV6Router />} />
        <Route
          path="new-lead/:form_id"
          element={<NewOrganizationLeadFormSubmissionOverlay useV6Router back={url} organization={organization} />}
        />
      </AnimatedRoutes>
    </>
  )
}

const LeadApplicationFormsIndex = () => {
  const { resource_id }: any = useParams()

  const leadFormsQuery = useGet({
    name: ['organization_lead_forms'],
    url: `/organization_lead_forms`,
  })

  const tableProps = useDataTable({
    name: ['organizations', resource_id, 'organization_lead_form_submissions'],
    endpoint: `/organizations/${resource_id}/organization_lead_form_submissions`,
    updateDeleteEndpoint: `/organization_lead_form_submissions`,
    localStorageKey: 'organization_profile_lead_form_submissions_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Lead Contact Form',
        model: 'organization_lead_form.name',
        width: 300,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" as={NavLink} id={data.id} value={value} to={`${data.id}`} />,
      },
      {
        title: 'Status',
        model: 'archived_at',
        formatValue: ({ value }) => {
          if (value) return <Status small color="gray" label="Archived" />

          return <Status small color="green" label="Active" />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'organizations',
      },
      {
        title: 'Source',
        model: 'source',
        type: 'title',
      },
      {
        title: 'Phone',
        model: 'data.organization.phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'data.organization.email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'data.organization.website',
        type: 'external_url',
        disableSelect: true,
      },
      {
        title: 'Notes',
        model: 'data.organization.additional_info',
      },
      {
        title: 'Address',
        model: 'data.organization.address',
        type: 'address',
        disableSort: true,
        width: 200,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <Page
      title="Lead Contact Forms"
      icon="organization_lead_forms"
      actions={
        <>
          <Button
            label="Manage Lead Contact Forms"
            glyph="settings"
            type="minimal"
            link="/settings/organization-lead-contact-forms"
            permission="settings.organization_lead_forms.create"
          />

          <Dropdown label="Add New Organization Lead" glyph="add" buttonType="primary" permission="organization_lead_forms.create">
            {leadFormsQuery.isLoading || size(leadFormsQuery.data) === 0 ? (
              <State
                isLoading={leadFormsQuery.isLoading}
                isEmpty={size(leadFormsQuery.data)}
                minHeight={100}
                title="Organization Lead Contact Forms"
                icon="organization_lead_forms"
                emptyDescription="No Organization Lead Contact Forms created yet"
                emptyActions={
                  <Button
                    label="Manage Lead Contact Forms"
                    glyph="settings"
                    type="minimal"
                    link="/settings/organization-lead-contact-forms"
                    permission="settings.organization_lead_forms.create"
                    size={100}
                  />
                }
              />
            ) : (
              <>
                {leadFormsQuery.data?.map?.((form: any) => (
                  <DropdownItem
                    key={form.id}
                    as={Link}
                    link={`new-lead/${form.id}`}
                    icon="organization_lead_forms"
                    permission="organization_lead_forms.create"
                    label={form.name}
                  />
                ))}
              </>
            )}
          </Dropdown>
        </>
      }
    >
      <Grid>
        <DataTable asCard {...tableProps} icon="organization_lead_forms" title="Lead Contact Forms" columns={columns} />
      </Grid>
    </Page>
  )
}
