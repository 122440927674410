import React from 'react'

import Alert from '../Alert'
import Button from '../Button'
import ConfirmDialog from '../Dialogs/ConfirmDialog'
import DeleteDialog from '../Dialogs/DeleteDialog'
import SignatureDialog from '../Dialogs/SignatureDialog'
import SummonOverlay from '../SummonOverlay'
import TextareaDialog from '../Dialogs/TextareaDialog'

export const DataTableActionButton = (props: any) => {
  const { type, label, glyph, color, action, count, permission, config, selectedRows, testKey, buttonType = 'minimal', isDisabled } = props

  const [isActionProcessing, setIsActionProcessing] = React.useState(false)

  switch (type) {
    case 'delete':
      return (
        <DeleteDialog
          isDisabled={isDisabled}
          title="Delete selected records?"
          message={`Are you sure you want to delete the ${count} selected records? This action cannot be undone.`}
          yesLabel={`Yes, Delete (${count})`}
          onYes={async () => {
            // start loader
            setIsActionProcessing(true)

            await action() // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            testKey={testKey}
            label={label}
            glyph={glyph}
            color={color}
            type={buttonType}
            size={100}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
            isDisabled={isDisabled}
          />
        </DeleteDialog>
      )

    case 'batch_edit':
      const OverlayTag = config?.overlay

      if (!OverlayTag || count < 2) return null

      return (
        <SummonOverlay isDisabled={isDisabled} overlay={<OverlayTag initialData={selectedRows} />}>
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type={buttonType}
            size={100}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
            isDisabled={isDisabled}
          />
        </SummonOverlay>
      )

    case 'sign_off':
      return (
        <SignatureDialog
          isDisabled={isDisabled}
          model="supervisor"
          title="Sign Off as Supervisor"
          yesLabel="Apply Signature & Sign Off"
          before={
            <Alert contrast small type="warning" glyph="warning" className="mb-4">
              Please ensure all selected records are accurate and complete before signing off.
            </Alert>
          }
          onYes={async (signature) => {
            // start loader
            setIsActionProcessing(true)

            await action(signature) // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type={buttonType}
            size={100}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
            isDisabled={isDisabled}
          />
        </SignatureDialog>
      )

    case 'data_form_send_for_review':
      return (
        <SignatureDialog
          isDisabled={isDisabled}
          model="author"
          title="Sign & Send for Review"
          yesLabel="Apply Signature & Send for Review"
          onYes={async (signature) => {
            // start loader
            setIsActionProcessing(true)

            await action(signature) // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type={buttonType}
            size={100}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
            isDisabled={isDisabled}
          />
        </SignatureDialog>
      )

    case 'employee_sign_off':
      return (
        <SignatureDialog
          isDisabled={isDisabled}
          model="employee"
          title="Staff Signature"
          yesLabel="Apply Signature"
          onYes={async (signature: any) => {
            // start loader
            setIsActionProcessing(true)

            await action(signature) // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type={buttonType}
            size={100}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
            isDisabled={isDisabled}
          />
        </SignatureDialog>
      )

    case 'close':
      return (
        <ConfirmDialog
          isDisabled={isDisabled}
          title="Close?"
          yesLabel="Yes, Close"
          onYes={async () => {
            // start loader
            setIsActionProcessing(true)

            await action() // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type={buttonType}
            size={100}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
            isDisabled={isDisabled}
          />
        </ConfirmDialog>
      )

    case 'send_for_review':
      return (
        <ConfirmDialog
          isDisabled={isDisabled}
          title="Send for Review"
          yesLabel="Send for Review"
          onYes={async () => {
            // start loader
            setIsActionProcessing(true)

            await action() // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type={buttonType}
            size={100}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
            isDisabled={isDisabled}
          />
        </ConfirmDialog>
      )

    case 'confirmation':
      return (
        <ConfirmDialog
          isDisabled={isDisabled}
          title={config.confirmationTitle || 'Confirm'}
          message={config.confirmationMessage || 'Are you sure you want to do this?'}
          yesLabel={config.confirmationYesLabel || 'Yes'}
          onYes={async () => {
            // start loader
            setIsActionProcessing(true)

            await action() // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type={buttonType}
            size={100}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
            isDisabled={isDisabled}
          />
        </ConfirmDialog>
      )

    case 'request_updates':
      return (
        <TextareaDialog
          isDisabled={isDisabled}
          model="rejected_reason"
          title="What updates should be made?"
          onYes={async (updates) => {
            // start loader
            setIsActionProcessing(true)

            await action(updates) // process action

            // stop loader
            setIsActionProcessing(false)
          }}
          yesLabel="Ask For Updates"
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type={buttonType}
            size={100}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
            isDisabled={isDisabled}
          />
        </TextareaDialog>
      )

    default:
      return (
        <Button
          label={label}
          glyph={glyph}
          color={color}
          type={buttonType}
          size={100}
          display="inline-flex"
          isLoading={isActionProcessing}
          permission={permission}
          isDisabled={isDisabled}
          onClick={async () => {
            // start loader
            setIsActionProcessing(true)

            await action() // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        />
      )
  }
}
