import React from 'react'
import produce from 'immer'
import MapGL, { Source, Layer, Image, Popup } from '@urbica/react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import turfLength from '@turf/length'
import turfAlong from '@turf/along'

import { address } from '../utils/functions'
import { FONT_FAMILY } from '../theme'
import { STATE_COORDINATES, STATE_CODES_BY_NAME } from '../utils/constants'

import markerCheckin from '../assets/img/map/checkin@2x.png'
import markerClient from '../assets/img/map/client@2x.png'
import markerDefault from '../assets/img/map/default@2x.png'
import markerDevice from '../assets/img/map/device@2x.png'
import markerLocation from '../assets/img/map/location@2x.png'
import markerOrganization from '../assets/img/map/organization@2x.png'
import markerProperty from '../assets/img/map/property@2x.png'

type Layer = 'properties' | 'organizations' | 'clients'

type Props = {
  className: string
  css?: any
  enableLocalStorage: boolean
  lat: number
  markers: any
  traces: any
  lon: number
  popup: (location: any) => React.ReactNode
  theme: 'streets' | 'light'
  zoom: number
}

const LOCAL_STORAGE_KEY = 'bh.maps'

const US_CENTER = {
  lat: 37.77193829408682,
  lon: -96.02823830832438,
}

const THEMES = {
  default: 'mapbox://styles/mapbox/streets-v11',
  streets: 'mapbox://styles/mapbox/streets-v11',
  light: 'mapbox://styles/mapbox/light-v10',
}

const MARKERS: any = {
  default: markerDefault,
  organizations: markerOrganization,
  properties: markerProperty,
  clients: markerClient,
  checkins: markerCheckin,
  devices: markerDevice,
  locations: markerLocation,
}

export const dataToFeatures = (data: any) => {
  if (!data) return null

  const features = []

  for (const record of data) {
    if (!record?.lat || !record?.lon) continue

    features.push({
      type: 'Feature',
      properties: {
        ...record,
        address: address(record?.address),
        avatar: record.avatar ? record.avatar : '',
      },
      geometry: {
        type: 'Point',
        coordinates: [record.lon, record.lat],
      },
    })
  }

  return { type: 'FeatureCollection', features }
}

export const traceDataToFeature = (data: any) => {
  if (!data) return null

  const collection = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: data.map((record: any) => [record.lon, record.lat]),
    },
  }

  return collection
}

export const arcDataToFeature = (data: any) => {
  if (!data) return null

  const collection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [data.origin.lon, data.origin.lat],
            [data.destination.lon, data.destination.lat],
          ],
        },
      },
    ],
  }

  const lineDistance = turfLength(collection.features[0])
  const items = []
  const steps = 1000

  for (let i = 0; i < lineDistance; i += lineDistance / steps) {
    const segment = turfAlong(collection.features[0], i)
    items.push(segment.geometry.coordinates)
  }

  collection.features[0].geometry.coordinates = items

  return collection
}

const getStateSettings = (stateName: any) => {
  const stateCode = STATE_CODES_BY_NAME?.[stateName]

  return STATE_COORDINATES?.[stateCode] || null
}

const getLocalStorageSettings = (key: string) => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!key || !value) return null

  const current = JSON.parse(value)

  return current[key] || null
}

const setLocalStorageSettings = (key: string, settings: any) => {
  if (!key || !settings) return null

  if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({}))
  }

  const current = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))

  const updated = produce(current, (draft: any) => {
    draft[key] = {
      ...draft[key],
      ...settings,
    }
  })

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updated))
}

const Map = ({
  arcs,
  className,
  enableLocalStorage = true,
  lat = US_CENTER.lat,
  lon = US_CENTER.lon,
  markers,
  traces,
  popup,
  theme = 'streets',
  zoom = 2.306415811856867,
  getRef,
  localStorageKey,
  stateName,
  onUpdate,
}: any) => {
  const ref = React.useRef()

  const stateSettings = getStateSettings(stateName)
  const userSettings = getLocalStorageSettings(localStorageKey)

  const [open, setOpen] = React.useState(false)
  const [selectedFeature, setSelectedFeature] = React.useState(null)
  const [selectedLocation, setSelectedLocation] = React.useState(null)

  const [viewport, setViewport] = React.useState({
    latitude: userSettings?.latitude || stateSettings?.lat || lat || US_CENTER.lat,
    longitude: userSettings?.longitude || stateSettings?.lon || lon || US_CENTER.lon,
    zoom: userSettings?.zoom || stateSettings?.zoom || zoom,
  })

  const onLayerClick = (event: any) => {
    event.originalEvent.preventDefault()
    setSelectedFeature(event.features[0])
  }

  const onLayerEnter = () => {
    ref.current.getMap().getCanvas().style.cursor = 'pointer'
  }

  const onLayerLeave = () => {
    ref.current.getMap().getCanvas().style.cursor = ''
  }

  React.useEffect(() => {
    if (selectedFeature) {
      setSelectedLocation(selectedFeature.properties)
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [selectedFeature])

  React.useEffect(() => {
    if (!selectedLocation?.lat || !selectedLocation?.lon) return

    const map = ref.current.getMap()

    map?.easeTo({
      center: [selectedLocation.lon, selectedLocation.lat],
      zoom: 14,
    })
  }, [selectedLocation])

  React.useEffect(() => {
    setLocalStorageSettings(localStorageKey, viewport)
    if (onUpdate) onUpdate(viewport)
  }, [viewport])

  React.useEffect(() => {
    if (getRef) getRef(ref)
  }, [ref])

  return (
    <MapGL
      antialias
      ref={ref}
      {...viewport}
      mapStyle={THEMES[theme] || THEMES.default}
      accessToken={process.env.BH_MAPBOX}
      onViewportChange={setViewport}
      attributionControl={false}
      css={styles}
      className={className}
    >
      {arcs &&
        arcs.map((arc, index) => {
          return (
            <>
              <Source id={`arc-source-${index}`} type="geojson" data={arcDataToFeature(arc)} />

              <Layer
                minzoom={0}
                type="line"
                id={`arc-layer-line-${index}`}
                source={`arc-source-${index}`}
                paint={{
                  'line-color': '#336BFD',
                  'line-opacity': 0.75,
                  'line-width': 5,
                }}
              />
            </>
          )
        })}

      {traces &&
        Object.keys(traces)?.map((trace: string) => (
          <React.Fragment key={trace}>
            {/* load the geojson points into mapbox */}
            <Source id={`trace-source-${trace}`} type="geojson" data={traceDataToFeature(traces[trace].data)} />

            <Layer
              minzoom={0}
              type="line"
              id={`trace-layer-line-${trace}`}
              source={`trace-source-${trace}`}
              layout={{
                'line-join': 'round',
                'line-cap': 'round',
                visibility: traces[trace].visible ? 'visible' : 'none',
              }}
              paint={{
                'line-color': '#336BFD',
                'line-opacity': 0.75,
                'line-width': 5,
              }}
            />

            <Layer
              minzoom={0}
              type="circle"
              id={`trace-layer-${trace}`}
              source={`trace-source-${trace}`}
              paint={{
                'circle-radius': 6,
                'circle-color': '#336BFD',
              }}
              layout={{
                visibility: traces[trace].visible ? 'visible' : 'none',
              }}
            />
          </React.Fragment>
        ))}

      {markers &&
        Object.keys(markers)?.map((marker: string) => (
          <React.Fragment key={marker}>
            {/* load the image into mapbox */}
            <Image id={`marker-image-${marker}`} image={MARKERS[marker] || MARKERS.default} pixelRatio={2} />

            {/* load the geojson points into mapbox */}
            <Source id={`marker-source-${marker}`} type="geojson" data={dataToFeatures(markers[marker].data)} />

            {/* display the layer on mapbox */}
            <Layer
              minzoom={0}
              type="symbol"
              id={`marker-layer-${marker}`}
              onClick={onLayerClick}
              onEnter={onLayerEnter}
              onLeave={onLayerLeave}
              source={`marker-source-${marker}`}
              layout={{
                'icon-image': `marker-image-${marker}`,
                'icon-allow-overlap': true,
                visibility: markers[marker].visible ? 'visible' : 'none',
              }}
            />
          </React.Fragment>
        ))}

      {popup && open && (
        <Popup
          open={open}
          onClose={() => setOpen(false)}
          latitude={selectedLocation?.lat}
          longitude={selectedLocation?.lon}
          closeButton={true}
          closeOnClick={false}
          // offset={[0, 200]}
        >
          {popup(selectedLocation)}
        </Popup>
      )}
    </MapGL>
  )
}

const styles = {
  '.mapboxgl-popup': {
    maxWidth: 'unset!important',
    fontFamily: FONT_FAMILY,
    fontSize: 15,
    minWidth: 300,
    marginTop: -12, // compensate for marker image white space
  },

  '.mapboxgl-popup-tip': {
    display: 'none',
  },

  '.mapboxgl-popup-content': {
    background: 'transparent',
    padding: 0,
    border: 0,
    borderRadius: 0,
  },

  '.mapboxgl-popup-close-button': {
    zIndex: 1,
    outline: 'none!important',
    fontSize: '2rem',
    borderRadius: 0,
    padding: 0,
    width: 44,
    height: 44,
    lineHeight: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  '.mapboxgl-canvas': {
    outline: 'none!important',
  },
}

export default Map
