import React from 'react'
import { NavLink, useParams } from 'react-router-dom-v5-compat'

import { Grid, HelpTagIframe, Page } from '@behavehealth/components'
import { LocationCheckInsDataTable } from '@behavehealth/constructs/LocationCheckIns/LocationCheckInsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'location_gps_check_in',
  help: <HelpTagIframe id="location_check_in" />,
  marketingID: 'location_check_in',
}

const LocationCheckin: React.FC = () => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['location', resource_id, 'location-checks'],
    endpoint: `/houses/${resource_id}/location_checks`,
    localStorageKey: 'property_location_checkin_v1',
  })

  const to = React.useMemo(
    () => (rowData: any) => ({
      pathname: `${rowData.id}`,
      source: 'ehr',
    }),
    [],
  )

  return (
    <Page {...pageConfig}>
      <Grid>
        <LocationCheckInsDataTable
          {...tableProps}
          to={to}
          mainLinkAs={NavLink}
          mainLinkState={{ data: { source: 'ehr' } }}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/location-checkins',
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(LocationCheckin, pageConfig))
