import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MyIPAddress from '../../components/MyIPAddress'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

const RootStaffAllowedIPsOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    params,
    save,
  } = useOverlay({
    name: 'allowed_ips',
    endpoint: '/allowed_ips',
    invalidate: 'allowed_ips',
    options: props,
  })

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable}>
      <Overlay.Header icon="permissions" title={isNew ? 'Add IP' : 'Update IP'} />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          decorate={() => ({
            reference_id: params?.resource_id,
            reference_type: 'Employee',
          })}
        >
          <Section>
            <FormSection layout="vertical">
              <Select label="Status" model="status" defaultValue="active">
                <Option label="Active" value="active" />
                <Option label="Inactive" value="inactive" />
              </Select>

              <MyIPAddress />

              <Input
                label="IP Address"
                model="ip"
                validations={{
                  presence: {
                    message: 'Please enter an IP address',
                  },
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit IP" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete IP?"
              message="Are you sure you want to delete this IP address? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button fullWidth glyph="delete" label="Delete" type="default" color="red" isLoading={isLoading} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const StaffAllowedIPsOverlay = withOverlayError(RootStaffAllowedIPsOverlay)
