import React from 'react'

import size from 'lodash/size'
import startCase from 'lodash/startCase'
import pluralize from 'pluralize'
import { DateTime } from 'luxon'

import { ICONS } from '../../theme'
import { titleCase, usDate, DT, daysToWords } from '../../utils/functions'
import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '../../utils/recurrence'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Glyph from '../../components/Glyph'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Link from '../../components/Link'

import AmountInput from '../../components/Forms/AmountInput'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'

import { niceOccurrenceText } from '../../components/Overlays/pages/Financials/utils'

import ChargePayersSection from '../../components/Overlays/pages/Financials/components/ChargePayersSection'
import { PAYMENT_METHODS_LIST } from '../../utils/constants'

const FREQUENCIES = {
  weekly: 'recurring_weekly_due_day',
  biweekly: 'recurring_biweekly_due_day',
  monthly: 'recurring_monthly_due_day',
}

const ORGANIZATION_TYPES = {
  vendor: 'Vendor',
  resource: 'Community Resource',
  provider: 'Provider',
}

const RootAutoRepeatingChargeOverlay = (props: any) => {
  const {
    cancel,
    client,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'financial-transactions',
    endpoint: '/financial_transactions',
    invalidate: 'financial-transactions',
    options: props,
  })

  const [formData, setFormData] = React.useState(initialModel)
  const [useCustomFrequency, setUseCustomFrequency] = React.useState(initialModel?.use_custom_frequency)

  const { timezone } = useSettings()

  const service = initialModel?.service

  const employees = initialModel?.service?.employees
  const hasEmployees = size(employees) > 0

  const organizations = initialModel?.service?.organizations
  const hasOrganizations = size(organizations) > 0

  const hasPayers = size(initialModel.subcharges) > 0
  const dayOfNextRecurrence = formData?.transactioned_at && formData?.end_in_months && DT(formData?.transactioned_at)

  const isCustomAmount = initialModel?.is_custom_amount || formData?.is_custom_amount

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="financials" title="Auto-Repeating Charge" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          onUpdate={setFormData}
        >
          <Section>
            <FormSection>
              <ObjectSelector
                isEditable={false}
                model="service"
                label="Linked Service"
                type="client_services"
                sortBy={['order', 'created_at']}
                icon={ICONS.financials}
                selectTitle={(data) => data.name}
              />

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a charge name',
                  },
                }}
              />

              {!isNew && data && (
                <Flex gap="1rem">
                  <DateTimeInput isEditable={false} label="Date Created" model="created_at" />
                  <DateTimeInput isEditable={false} label="Date Updated" model="updated_at" />
                </Flex>
              )}

              {isCustomAmount ? (
                <AmountInput
                  label="Custom Amount"
                  model="amount"
                  suffix={niceOccurrenceText(
                    service?.is_recurring,
                    useCustomFrequency ? formData?.recurring_frequency : service?.recurring_frequency,
                    useCustomFrequency
                      ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                      : service?.[FREQUENCIES[service?.recurring_frequency]],
                  )}
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                    numericality: {
                      greaterThan: 0,
                      message: 'Please enter an amount',
                    },
                  }}
                />
              ) : (
                <AmountInput
                  isEditable={false}
                  label="Service Amount"
                  suffix={niceOccurrenceText(
                    service?.is_recurring,
                    useCustomFrequency ? formData?.recurring_frequency : service?.recurring_frequency,
                    useCustomFrequency
                      ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                      : service?.[FREQUENCIES[service?.recurring_frequency]],
                  )}
                  value={service?.amount}
                />
              )}

              {isEditable && (
                <Flex gap="1rem" stretchChildrenX>
                  <Checkbox
                    label="Set Custom Frequency"
                    model="use_custom_frequency"
                    onUpdate={(model: any) => {
                      setUseCustomFrequency(model.value)
                    }}
                  />
                </Flex>
              )}

              {useCustomFrequency && (
                <>
                  <RadioGroup
                    label="Custom Repeating Frequency"
                    model="recurring_frequency"
                    layout="vertical-dense"
                    defaultValue={service?.recurring_frequency || 'monthly'}
                    validations={{
                      presence: {
                        message: 'Please select a billing frequency',
                      },
                    }}
                  >
                    {Object.keys(RECURRING_FREQUENCIES).map((key) => (
                      <Radio testKey={`frequency_selector_${key}`} key={key} label={RECURRING_FREQUENCIES[key].label} value={key} />
                    ))}
                  </RadioGroup>

                  <ContextShow when="recurring_frequency" is="weekly">
                    <Select
                      asNumber
                      label="Weekly Due Day"
                      model="recurring_weekly_due_day"
                      defaultValue={service?.recurring_weekly_due_day || 0}
                      validations={{
                        presence: {
                          message: 'Please select a due day',
                        },
                      }}
                    >
                      {RECURRING_DAYS.weekly.map((key, idx) => (
                        <Option key={idx} label={`Every ${key}`} value={idx} />
                      ))}
                    </Select>
                  </ContextShow>

                  <ContextShow when="recurring_frequency" is="biweekly">
                    <Select
                      asNumber
                      label="Biweekly Due Day"
                      model="recurring_biweekly_due_day"
                      defaultValue={service?.recurring_biweekly_due_day || 0}
                      validations={{
                        presence: {
                          message: 'Please select a due day',
                        },
                      }}
                    >
                      {RECURRING_DAYS.biweekly.map((key, idx) => (
                        <Option key={idx} label={`Every other ${key}`} value={idx} />
                      ))}
                    </Select>
                  </ContextShow>

                  <ContextShow when="recurring_frequency" is="monthly">
                    <Select
                      asNumber
                      label="Monthly Due Day"
                      model="recurring_monthly_due_day"
                      defaultValue={service?.recurring_monthly_due_day || 1}
                      validations={{
                        presence: {
                          message: 'Please select a due day',
                        },
                      }}
                    >
                      {RECURRING_DAYS.monthly.map((key, idx) => (
                        <Option key={idx} label={key} value={idx + 1} />
                      ))}
                    </Select>
                  </ContextShow>
                </>
              )}

              <FormSection>
                <Flex gap={16}>
                  <DateInput
                    label="Next Renewal Date"
                    model="transactioned_at"
                    validations={{
                      presence: {
                        message: 'Please enter a renewal date',
                      },
                    }}
                  />

                  <Select
                    defaultValue={1}
                    label="Covered Time Period"
                    model="end_in_months"
                    layout="vertical-dense"
                    validations={{
                      presence: {
                        message: 'Please select an end time',
                      },
                    }}
                  >
                    <Option label="1 Month" value={1} />
                    <Option label="2 Months" value={2} />
                    <Option label="3 Months" value={3} />
                    <Option label="6 Months" value={6} />
                    <Option label="9 Months" value={9} />
                    <Option label="12 Months" value={12} />
                  </Select>
                </Flex>
              </FormSection>

              {hasEmployees && (
                <OverlaySelector
                  model="employees"
                  label="Staff Member"
                  type="employees.active"
                  apiData={employees}
                  icon={ICONS.employees}
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => startCase(data.position)}
                />
              )}

              {hasOrganizations && (
                <ObjectSelector
                  model="organizations"
                  label="Organization"
                  type="organizations"
                  apiData={organizations}
                  icon={ICONS.organizations}
                  selectTitle={(data: any) => data.name}
                  selectDescription={() => null}
                />
              )}

              <ObjectSelector
                isRelation={false}
                className="!flex-auto"
                model="payed_with"
                label="Pay With"
                apiData={PAYMENT_METHODS_LIST}
                showAvatars={false}
                selectTitle={(data: any) => (
                  <>
                    <Glyph glyph={data.glyph} /> {data.name}
                  </>
                )}
                selectDescription={(data: any) => data.description}
                validations={{
                  presence: {
                    message: 'Please select an option',
                  },
                }}
              />

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          {formData?.payed_with?.model === 'online' && (
            <>
              <Section title="Automatic Collection">
                <FormSection maxWidth="100%">
                  <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded">
                    <Checkbox
                      label="Use Automatic Payment Collection"
                      model="should_auto_charge"
                      description="Attempt to collect from the Payment Method selected below"
                    />
                  </CheckboxGroup>

                  {formData?.should_auto_charge && (
                    <ObjectSelector
                      icon="billing"
                      type="client.payment_methods"
                      label="Payment Method to Charge"
                      model="payment_method"
                      dependentValue={client?.id}
                      selectTitle={(data: any) => data?.name}
                      selectDescription={(data: any) => data?.reference?.name}
                      validations={{
                        presence: {
                          message: 'Please select a Payment Method to use',
                        },
                      }}
                    />
                  )}
                </FormSection>
              </Section>

              <Divider />
            </>
          )}

          {formData?.payed_with?.model !== 'online' && (
            <>
              <Section title="Payer">
                <FormSection maxWidth="100%">
                  <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense" defaultValue="client">
                    <Radio label="Client" value="client" />
                    <Radio label="Contact" value="contact" />
                    <Radio label="Organization" value="organization" />
                  </RadioGroup>

                  <ContextShow when="reference_category" is="client">
                    <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" value={client} />
                  </ContextShow>

                  <ContextShow when="reference_category" is="contact">
                    <ObjectSelector
                      isPolymorphic
                      icon="contacts"
                      label="Payer"
                      model="reference"
                      type="client.contacts"
                      description={
                        <>
                          Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                        </>
                      }
                      dependentValue={client?.id}
                      selectTitle={(data: any) => data?.name}
                      selectDescription={(data: any) => titleCase(data?.relationship)}
                    />
                  </ContextShow>

                  <ContextShow when="reference_category" is="organization">
                    <ObjectSelector
                      isPolymorphic
                      icon="organizations"
                      label="Payer"
                      model="reference"
                      type="organizations"
                      dependentValue={client?.id}
                      selectTitle={(data: any) => data?.name}
                      selectDescription={(data: any) => ORGANIZATION_TYPES[data?.category]}
                      description={
                        <>
                          Can't find the Organization you're looking for? Go to <Link to="/community/organizations">Organizations</Link> to
                          add it.
                        </>
                      }
                    />
                  </ContextShow>
                </FormSection>
              </Section>

              <Divider />
            </>
          )}

          {hasPayers && (
            <>
              <ChargePayersSection subcharges={initialModel.subcharges} />
              <Divider />
            </>
          )}

          <Section title="Renewal & Cancellation">
            <Grid gap="0.75rem">
              <Alert glyph="date" type="positive">
                This service will renew on <b>{usDate(dayOfNextRecurrence)}</b> <i>({daysToWords(dayOfNextRecurrence)})</i> and generate the
                next batch of charges (see below) for {formData?.end_in_months} {pluralize('month', parseInt(formData?.end_in_months))}.
              </Alert>

              <Alert glyph="circle_error" type="negative">
                Cancel the recurring service before <b>{DT(dayOfNextRecurrence)?.toFormat('ccc, MMM dd')}</b> to stop generating the next
                batch of charges.
              </Alert>
            </Grid>
          </Section>

          {/* TODO: Fix & PUT THIS BACK */}
          {/* {formData && service?.id && (
            <PreviewRecurringChargesSection
              usingCustomFrequency={useCustomFrequency}
              from={formData?.transactioned_at}
              endIn={formData?.end_in_months}
              serviceID={service?.id}
              timezone={timezone}
              amount={formData?.is_custom_amount ? formData?.amount : service?.amount}
            />
          )} */}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save Recurring Service"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Recurring Service"
              type="default"
              isDisabled={isSaving}
              onClick={edit}
              flex="100 1 auto"
              permission="ledger.edit"
            />

            <DeleteDialog
              title="Cancel Recurring Service?"
              message="Are you sure you want to cancel this recurring service? This action cannot be undone."
              yesLabel="Yes, Cancel Service"
              onYes={deleteRecord}
            >
              <Button fullWidth glyph="circle_error" label="Cancel Service" color="red" isLoading={isDeleting} permission="ledger.delete" />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AutoRepeatingChargeOverlay = withOverlayError(RootAutoRepeatingChargeOverlay)
