import React from 'react'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'

import { PlainSwitch } from '../../components/Forms/Switch'
import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordion from '../../components/Accordion'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import State from '../../components/State'
import Status from '../../components/Status'
import Switch from '../../components/Forms/Switch'
import TableArrayForm from '../../components/Forms/TableArrayForm'
import Tooltip from '../../components/Tooltip'
import TreeItem from '../../components/TreeItem'

import { CATEGORIES, DATA_FORM_SETTINGS } from './constants'
import { DataFormRenderer } from './DataFormRenderer'
import { DataFormSettingsStatus } from './DataFormSettingsStatus'

import { ClientTracksMultiObjectSelector } from '../../components/Forms/elements/ClientTracksMultiObjectSelector'

const RootDataFormBuilderOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    edit,
    form,
    id,
    initialModel,
    isEditable,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    saveWithData,
  } = useOverlay({
    name: 'data_form_settings',
    endpoint: '/data_form_settings',
    invalidate: 'data_form_settings',
    options: props,
  })

  const { permissionName } = props
  const isDesktop = useMedia({ minWidth: 1024 })

  const [formData, setFormData] = React.useState(data?.schema || {})
  const [isPreviewVisible, setIsPreviewVisible] = React.useState(false)

  const handlePreviewChange = () => {
    setIsPreviewVisible((c) => !c)
  }

  const debouncedSetFormData = React.useMemo(() => {
    return debounce((value) => {
      setFormData?.(value)
    }, 300)
  }, [])

  const categoryConfig = React.useMemo(() => {
    if (!data?.category) return null

    return Object.values(CATEGORIES).find((o) => o.id === data.category) || null
  }, [data?.category])

  const settings = React.useMemo(() => {
    if (!data) return null

    const recordKey = compact([data.subcategory, data.variant]).join('_')

    return DATA_FORM_SETTINGS[data.category]?.[recordKey] || null
  }, [data])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={50} />
  }

  if (!categoryConfig || !settings) {
    return null
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={isDesktop && isPreviewVisible ? 100 : 50}>
      <Overlay.Header icon={categoryConfig?.icon} title={data.name} titleAside={<DataFormSettingsStatus status={data?.status} />} />

      {isDesktop && (
        <Overlay.SubHeader className="flex items-center justify-end flex-nowrap px-2 !py-1.5">
          <Label isCompact label="Show Preview" onClick={handlePreviewChange} className="!flex-[0_0_auto] cursor-pointer" />
          <PlainSwitch size={200} isChecked={isDesktop && isPreviewVisible} onCheckedChange={handlePreviewChange} />
        </Overlay.SubHeader>
      )}

      <div
        className={clsx(
          'grid grid-rows-[100%] overflow-hidden flex-[1_1_auto]',
          isDesktop && isPreviewVisible ? 'grid-cols-[50rem_1fr]' : 'grid-cols-1',
        )}
      >
        <Overlay.Content className={isDesktop && isPreviewVisible ? 'border-r border-0 border-solid border-divider' : ''}>
          <Form
            isCompact
            getForm={form}
            initialModel={initialModel}
            onValidationUpdate={onValidationUpdate}
            isEditable={isEditable}
            onUpdate={debouncedSetFormData}
          >
            <Section>
              <Accordion isOpen icon="client_tracks" title="Client Tracks">
                <FormSection maxWidth="100%">
                  <ClientTracksMultiObjectSelector />
                </FormSection>
              </Accordion>

              <Accordion isOpen glyph="merge" title="Sign-Off Workflow">
                <FormSection maxWidth="100%">
                  <CheckboxGroup trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded" layout="vertical-dense">
                    <Checkbox
                      label="Enable Supervisor Review Workflow"
                      model={'settings.enable_supervisor_review'}
                      tooltip="Submissions for this form can be sent to a supervisor for review. The supervisor can then mark the assessment as 'Approved', 'Rejected', or 'Updates Required'"
                    />

                    <Checkbox
                      label="Enable Sign-Off Workflow"
                      model={'settings.enable_signoff_workflow'}
                      tooltip="When checked, submissions for this form can be marked as signed-off"
                    />
                  </CheckboxGroup>

                  <ContextShow when={'settings.enable_signoff_workflow'} is={true}>
                    <CheckboxGroup
                      label="Sign-Off Requirements:"
                      trueIcon="check"
                      falseIcon="empty_checkbox"
                      falseStyle="faded"
                      layout="vertical-dense"
                    >
                      <Checkbox
                        label="Client must sign"
                        model={'settings.signoff_settings.client_must_sign'}
                        tooltip="Client added to the assessments will be required to sign before the form can be signed-off."
                      />
                      <Checkbox
                        label="Supervisor must sign"
                        model={'settings.signoff_settings.supervisors_must_sign'}
                        tooltip="The assigned supervisor will be required to sign before the form can be signed-off."
                      />
                      <Checkbox
                        label="All Staff Members must sign"
                        model={'settings.signoff_settings.staff_must_sign'}
                        tooltip="All staff members added to the assessments will be required to sign before the form can be signed-off."
                      />
                      <ContextShow when={'settings.enable_supervisor_review'} is={true}>
                        <Checkbox
                          label="Supervisor must approve the form"
                          model={'settings.signoff_settings.supervisor_must_approve'}
                          tooltip="The assigned supervisor will be required to approve the form before it can be signed-off."
                        />
                      </ContextShow>
                    </CheckboxGroup>
                  </ContextShow>

                  {formData?.settings?.enable_signoff_workflow &&
                    (formData?.settings?.signoff_settings?.supervisors_must_sign ||
                      formData?.settings?.signoff_settings?.staff_must_sign) && (
                      <TreeItem isOpen withHover={false} title="Signatures Legal Copy">
                        <div className="grid gap-3 pt-3 pr-3 pb-4">
                          {isEditable && (
                            <Alert small contrast glyph="info">
                              Customize the legal copy for each signature type below
                            </Alert>
                          )}

                          {formData?.settings?.signoff_settings?.client_must_sign && (
                            <MiniRichTextEditor
                              useQuickText
                              label="Client Signature Legal Copy"
                              model="settings.signatures_legal_text.client"
                            />
                          )}

                          {formData?.settings?.signoff_settings?.staff_must_sign && (
                            <MiniRichTextEditor
                              useQuickText
                              label="Staff Member Signatures Legal Copy"
                              model="settings.signatures_legal_text.staff"
                            />
                          )}

                          {formData?.settings?.signoff_settings?.supervisors_must_sign && (
                            <MiniRichTextEditor
                              useQuickText
                              label="Supervisor Signatures Legal Copy"
                              model="settings.signatures_legal_text.supervisors"
                            />
                          )}
                        </div>
                      </TreeItem>
                    )}
                </FormSection>
              </Accordion>

              <Accordion isOpen glyph="form_builder_smart_sections" title="Form Sections">
                <div className="grid gap-3">
                  {categoryConfig.showLinkedGoalsObjectivesInterventions && (
                    <DataFormSection
                      isOpen
                      title="General"
                      isEditable={isEditable}
                      model={`schema.general`}
                      sectionId={`section_general`}
                      isPreviewVisible={isDesktop && isPreviewVisible}
                    >
                      <div className="grid gap-1.5 mt-3">
                        <Checkbox
                          label="Linked Goals, Objectives, Interventions"
                          model={`schema.general.fields.linked_goals_objectives_interventions`}
                          trueIcon="check"
                          falseIcon="empty_checkbox"
                          falseStyle="faded"
                        />
                      </div>
                    </DataFormSection>
                  )}

                  {settings.sections.map((section: any) => (
                    <DataFormSection
                      isOpen
                      key={section.model}
                      title={section.title}
                      subtitle={section.subtitle}
                      isRequired={section.isRequired}
                      isEditable={isEditable}
                      model={`schema.${section.model}`}
                      sectionId={`section_${section.model}`}
                      isPreviewVisible={isDesktop && isPreviewVisible}
                    >
                      <div className="grid gap-1.5 mt-3">
                        <Checkbox
                          label="Custom Questions"
                          model={`schema.${section.model}.fields.custom_questions_before`}
                          trueIcon="check"
                          falseIcon="empty_checkbox"
                          falseStyle="faded"
                        />

                        <ContextShow when={`schema.${section.model}.fields.custom_questions_before`} is={true}>
                          <div className="pl-5 mt-1.5 mb-2">
                            <TableArrayForm
                              isCompact
                              name="Custom Questions"
                              model={`schema.${section.model}.custom_questions_before`}
                              inputs={INPUTS}
                            />
                          </div>
                        </ContextShow>

                        {section.fields?.map?.((field: any) => {
                          const BuilderComponent = field?.builderComponent

                          return (
                            <div key={`${section.model}-${field.model}`} className="grid grid-cols-1 gap-3">
                              <Checkbox
                                key={field.model}
                                label={field.label}
                                description={field.description}
                                model={`schema.${section.model}.fields.${field.model}`}
                                isDisabled={field.isDisabled}
                                value={field.isChecked}
                                trueIcon="check"
                                falseIcon="empty_checkbox"
                                falseStyle="faded"
                              />

                              {BuilderComponent && (
                                <ContextShow when={`schema.${section.model}.fields.${field.model}`} is={true}>
                                  <BuilderComponent schema={formData} />
                                </ContextShow>
                              )}
                            </div>
                          )
                        })}

                        <Checkbox
                          label="Custom Questions"
                          model={`schema.${section.model}.fields.custom_questions_after`}
                          trueIcon="check"
                          falseIcon="empty_checkbox"
                          falseStyle="faded"
                        />

                        <ContextShow when={`schema.${section.model}.fields.custom_questions_after`} is={true}>
                          <div className="pl-5 mt-1.5">
                            <TableArrayForm
                              isCompact
                              name="Custom Questions"
                              model={`schema.${section.model}.custom_questions_after`}
                              inputs={INPUTS}
                            />
                          </div>
                        </ContextShow>
                      </div>
                    </DataFormSection>
                  ))}
                </div>
              </Accordion>
            </Section>
          </Form>

          {/* <Json data={formData} /> */}
        </Overlay.Content>

        {isDesktop && isPreviewVisible && settings?.sections && (
          <Overlay.Content>
            <DataFormPreview sections={settings.sections} schema={formData?.schema} />
          </Overlay.Content>
        )}
      </div>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button label="Save" glyph="check" type="primary" color="green" isLoading={isSaving} onClick={save} flex="100 1 auto" />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Assessment"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission={permissionName ? `${permissionName}.edit` : undefined}
            />

            {!isNew && data && (
              <>
                {data?.status === 'active' && (
                  <Button
                    label={
                      <span className="flex items-center">
                        <span className="mr-1.5">Deactivate Form</span>
                        <Tooltip content="Hide this form from the list of new client assessments" />
                      </span>
                    }
                    glyph="cross"
                    type="default"
                    color="text"
                    isLoading={isSaving}
                    isDisabled={isLoading}
                    onClick={async () => {
                      await saveWithData({ status: 'inactive' })
                    }}
                    permission={permissionName ? `${permissionName}.edit` : undefined}
                  />
                )}

                {data?.status === 'inactive' && (
                  <Button
                    label={
                      <span className="flex items-center">
                        <span className="mr-1.5">Activate Form</span>
                        <Tooltip content="Show this form in the list of new client assessments" />
                      </span>
                    }
                    glyph="cross"
                    type="default"
                    color="green"
                    isLoading={isSaving}
                    isDisabled={isLoading}
                    onClick={async () => {
                      await saveWithData({ status: 'active' })
                    }}
                    permission={permissionName ? `${permissionName}.edit` : undefined}
                  />
                )}
              </>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const DataFormPreview = ({ sections, schema }) => {
  const previewForm = React.useRef(null)

  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  if (isLoading) return <State isLoading />

  return (
    <Form getForm={previewForm}>
      <DataFormRenderer sections={sections} schema={schema} />
    </Form>
  )
}

const DataFormSection = (props: any) => {
  const { title, children, isRequired, isEditable, model, isOpen, sectionId, isPreviewVisible } = props
  const [open, setOpen] = React.useState(isOpen)

  const scrollToPreviewSection = () => {
    const element = document?.getElementById(sectionId)

    if (!element) return

    element.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <section className="[&+&]:mt-4">
      <div className="flex items-center">
        <div className="flex items-center">
          <h3>{title}</h3>

          {isPreviewVisible && (
            <Button label="Preview" glyph="view" size={100} type="minimal" className="mx-1.5" onClick={scrollToPreviewSection} />
          )}
        </div>

        {isEditable && isRequired && <Status label="Required" color="red" css={{ marginLeft: 'auto' }} />}

        <div css={{ marginLeft: 'auto', display: isRequired || !isEditable ? 'none' : 'block' }}>
          <Switch
            withHover={false}
            size={100}
            label={false}
            value={open}
            model={`${model}.show`}
            onUpdate={(state) => setOpen(state.value)}
          />
        </div>
      </div>

      <div style={{ overflow: open ? 'visible' : 'hidden', height: open ? 'auto' : 0, opacity: open ? 1 : 0 }}>{children}</div>
    </section>
  )
}

const INPUTS = [
  {
    name: 'Label',
    width: '2fr',
    element: (id: string) => {
      return (
        <>
          <Input withHover={false} model={`${id}.label`} placeholder="Enter your question here…" />
          <Input value={id} model={`${id}._id`} className="!hidden" />
        </>
      )
    },
  },
  {
    name: 'Type',
    width: '1fr',
    element: (id: string) => (
      <Select isCompact withHover={false} defaultValue="textarea" model={`${id}.category`}>
        <Option label="Multi-Line Text" value="textarea" />
        <Option label="Single-Line Text" value="input" />
        <Option label="Yes/No" value="true_false" />
      </Select>
    ),
  },
  {
    name: 'Required',
    width: '1fr',
    element: (id: string) => (
      <Checkbox isCompact label="Required" model={`${id}.is_required`} trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded" />
    ),
  },
]

export const DataFormBuilderOverlay = withOverlayError(RootDataFormBuilderOverlay)
