import { AcceptedClientsTable } from './clients/AcceptedClientsTable'
import { AdminContactsTable } from './AdminContactsTable'
import { AdmissionsAndCurrentClientsTable } from './AdmissionsAndCurrentClientsTable'
import { AdmissionsClientsTable } from './clients/AdmissionsClientsTable'
import { AllClientsTable } from './AllClientsTable'
import { AlumniClientsTable } from './clients/AlumniClientsTable'
import { ApplicantsTable } from './clients/ApplicantsTable'
import { BillableClientsTable } from './clients/BillableClientsTable'
import { ClinicalSupervisorsTable } from './ClinicalSupervisorsTable'
import { CommunityAccountsTable } from './CommunityAccountsTable'
import { CommunityEmployeesTable } from './CommunityEmployeesTable'
import { CurrentClientsTable } from './clients/CurrentClientsTable'
import { DeclinedClientsTable } from './clients/DeclinedClientsTable'
import { EmployeesTable } from './EmployeesTable'
import { EventsTable } from './EventsTable'
import { FacilitiesTable } from './FacilitiesTable'
import { FormsTable } from './FormsTable'
import { HubspotCommunityProfilesTable } from './HubspotCommunityProfilesTable'
import { HubspotCompaniesTable } from './HubspotCompaniesTable'
import { HubspotContactsTable } from './HubspotContactsTable'
import { InsuranceEOBsTable } from './InsuranceEOBsTable'
import { InsuranceERAsTable } from './InsuranceERAsTable'
import { InsuranceFeeSchedulesTable } from './InsuranceFeeSchedulesTable'
import { OrganizationsTable } from './OrganizationsTable'
import { PartnersTable } from './PartnersTable'
import { ProgramsTable } from './ProgramsTable'
import { PropertiesTable } from './PropertiesTable'
import { ProvidersTable } from './ProvidersTable'
import { PublicFormsTable } from './PublicFormsTable'
import { ShareablePartnersTable } from './ShareablePartnersTable'
import { AllClientContactsTable } from './AllClientContactsTable'
import { AllOrganizationContactsTable } from './AllOrganizationContactsTable'
import { LevelOfCareServiceEpisodesTable } from './LevelOfCareServiceEpisodesTable'
import { ActiveServiceEpisodesTable } from './ActiveServiceEpisodesTable'
import { FeatureFlagConfigsTable } from './FeatureFlagConfigsTable'

import { AdminDiscountTemplatesTable } from './AdminDiscountTemplatesTable'
import { AdminPlanAddonServiceTemplatesTable } from './AdminPlanAddonServiceTemplatesTable'
import { DiscountsTable } from './DiscountsTable'
import { PlansAddonsServicesTable } from './PlansAddonsServicesTable'
import { InsuranceNewFeeScheduleServicesTable } from './InsuranceNewFeeScheduleServicesTable'
import { AdminNotificationConfigsTable } from './AdminNotificationConfigsTable'
import { AuthorityOrganizationsTable } from './AuthorityOrganizationsTable'
import { OrganizationsWithRelationshipsTable } from './OrganizationsWithRelationshipsTable'

export const TABLES_CONFIG = {
  clients: {
    all: {
      title: 'Client',
      icon: 'clients',
      component: AllClientsTable,
    },
    current: {
      title: 'Current Client',
      icon: 'clients',
      component: CurrentClientsTable,
    },
    alumni: {
      title: 'Alumni',
      icon: 'alumni_relations',
      component: AlumniClientsTable,
    },
    admissions: {
      title: 'Applicant',
      icon: 'admissions',
      component: AdmissionsClientsTable,
    },
    admissions_and_current: {
      title: 'Client',
      icon: 'clients',
      component: AdmissionsAndCurrentClientsTable,
    },
  },
  clients_by_status: {
    applicants: {
      title: 'Applicants',
      icon: 'admissions',
      component: ApplicantsTable,
    },
    accepted: {
      title: 'Accepted Clients',
      icon: 'admissions',
      component: AcceptedClientsTable,
    },
    declined: {
      title: 'Declined Clients',
      icon: 'admissions',
      component: DeclinedClientsTable,
    },
    current: {
      title: 'Current Clients',
      icon: 'clients',
      component: CurrentClientsTable,
    },
    alumni: {
      title: 'Alumni',
      icon: 'alumni_relations',
      component: AlumniClientsTable,
    },
  },
  admin: {
    facilities: {
      title: 'EHR',
      icon: 'enterprise',
      component: FacilitiesTable,
    },
    communities: {
      title: 'Community Account',
      icon: 'community',
      component: CommunityAccountsTable,
    },
    contacts: {
      title: 'Contacts',
      icon: 'admin_contacts',
      component: AdminContactsTable,
    },
    feature_flag_configs: {
      title: 'Feature Flag Configs',
      icon: 'feature_flags',
      component: FeatureFlagConfigsTable,
    },
    plan_addon_service_templates: {
      title: 'Product Template',
      icon: 'certificate',
      component: AdminPlanAddonServiceTemplatesTable,
    },
    discount_templates: {
      title: 'Discount Template',
      icon: 'certificate',
      component: AdminDiscountTemplatesTable,
    },
    notification_configs: {
      title: 'Notification Configs',
      icon: 'notification_configs',
      component: AdminNotificationConfigsTable,
    },
  },
  products: {
    title: 'Products',
    icon: 'certificate',
    component: PlansAddonsServicesTable,
  },
  products_no_links: {
    title: 'Products',
    icon: 'certificate',
    endpoint: '/products/no_links',
    component: PlansAddonsServicesTable,
  },
  discounts: {
    title: 'Discounts',
    icon: 'certificate',
    component: DiscountsTable,
  },
  discounts_no_links: {
    title: 'Discounts',
    icon: 'certificate',
    endpoint: '/discounts/no_links',
    component: DiscountsTable,
  },
  community: {
    employees: {
      title: 'Community Employee',
      icon: 'employees',
      component: CommunityEmployeesTable,
    },
  },
  employees: {
    active: {
      title: 'Staff Member',
      icon: 'employees',
      component: EmployeesTable,
    },
    clinical_supervisors: {
      title: 'Clinical Supervisors',
      icon: 'employees',
      component: ClinicalSupervisorsTable,
    },
  },
  organizations_with_relationships: {
    title: 'Organization',
    icon: 'organizations',
    component: OrganizationsWithRelationshipsTable,
  },
  organizations: {
    all: {
      title: 'Organization',
      icon: 'organizations',
      component: OrganizationsTable,
    },
    authority: {
      title: 'Authority Organizations',
      icon: 'organizations',
      component: AuthorityOrganizationsTable,
    },
  },
  local_organizations: {
    all: {
      title: 'Organization',
      icon: 'organizations',
      component: OrganizationsTable,
    },
  },
  partnerships: {
    all: {
      title: 'Partners',
      icon: 'community_partners',
      component: PartnersTable,
    },
    shareable: {
      title: 'Shareable Partners',
      icon: 'community_partners',
      component: ShareablePartnersTable,
    },
  },
  properties: {
    all: {
      title: 'Locations',
      icon: 'gps_check_in_out',
      component: PropertiesTable,
    },
  },
  programs: {
    all: {
      title: 'Programs',
      icon: 'checklist',
      component: ProgramsTable,
    },
  },
  forms: {
    all: {
      title: 'Forms',
      icon: 'web_form',
      component: FormsTable,
    },
    public: {
      title: 'Public Forms',
      icon: 'web_form',
      component: PublicFormsTable,
    },
  },
  insurance: {
    billable_clients: {
      title: 'Billable Client',
      icon: 'clients',
      component: BillableClientsTable,
    },
    insurance_eobs: {
      title: 'Insurance EOBs',
      icon: 'insurance',
      component: InsuranceEOBsTable,
    },
    insurance_eras: {
      title: 'Insurance ERAs',
      icon: 'insurance',
      component: InsuranceERAsTable,
    },
    fee_schedules: {
      title: 'Fee Schedules',
      icon: 'insurance',
      component: InsuranceFeeSchedulesTable,
    },
    fee_schedules_insurance_codes: {
      title: 'Fee Schedule Insurance Codes',
      icon: 'insurance',
      component: InsuranceNewFeeScheduleServicesTable,
    },
  },
  events: {
    title: 'Events',
    icon: 'calendar',
    component: EventsTable,
  },
  providers: {
    title: 'Provider',
    icon: 'staff_credentials',
    component: ProvidersTable,
  },
  hubspot: {
    companies: {
      title: 'Hubspot Companies',
      icon: 'hubspot',
      component: HubspotCompaniesTable,
    },
    community_profiles: {
      title: 'Hubspot Community Profiles',
      icon: 'hubspot',
      component: HubspotCommunityProfilesTable,
    },
    contacts: {
      title: 'Hubspot Contacts',
      icon: 'enterprise',
      component: HubspotContactsTable,
    },
  },
  all_client_contacts: {
    title: 'Client Contacts',
    icon: 'contacts',
    component: AllClientContactsTable,
  },
  all_organization_contacts: {
    title: 'Organization Contacts',
    icon: 'contacts',
    component: AllOrganizationContactsTable,
  },
  service_episodes: {
    active: {
      title: 'Active Service Episodes',
      icon: 'service_episodes',
      component: ActiveServiceEpisodesTable,
    },
    levels_of_care: {
      title: 'Levels of Care',
      icon: 'service_episodes',
      component: LevelOfCareServiceEpisodesTable,
    },
  },
}
