import React from 'react'
import { useParams } from 'react-router-dom'
import includes from 'lodash/includes'

import { COLORS } from '../../theme'
import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { useGet, useCreate, useUpdate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import ApplicationForm from '../../components/ApplicationForm/ApplicationForm'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Dialog from '../../components/Dialog'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Link from '../../components/Link'
import Notifications from '../../modules/notifications'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'
import Tooltip from '../../components/Tooltip'
import { Text } from '../../components/Typography'

import DataList from '../../components/DataList'

const RootIntakeApplicationOverlay = (props: any) => {
  const {
    cancel,
    close,
    createAsync,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isLoading,
    isNew,
    isOverlayLoading,
    onValidationUpdate,
    updateAsync,
    isSaving,
    navigate,
    location,
  } = useOverlay({
    name: 'intake_applications',
    endpoint: '/intake_applications',
    invalidate: 'intake_applications',
    invalidateKeys: ['client', 'clients'],
    options: props,
    openAfterCreate: true,
  })

  const params: any = useParams()

  const declineForm = React.useRef(null)
  const statusForm = React.useRef(null)

  const [sentReminder, setSentReminder] = React.useState(false)
  // const [showDuplicateOverlay, setShowDuplicateOverlay] = React.useState(false)

  const { tenant, timezone } = useSettings()

  const isTrialing = tenant?.plan_status === 'trialing'
  const hasContactDetails = data?.data?.contact?.primary_contact?.email || data?.data?.contact?.primary_contact?.phone_no

  const { data: client }: any = useGet({
    name: ['client', params.resource_id],
    url: `/residents/${params.resource_id}`,
  })

  const { mutateAsync: accessCodeMutateAsync }: any = useUpdate({
    name: ['intake_applications', 'send_new_access_code'],
    url: `/intake_applications/${id}/send_new_access_code`,
    invalidate: ['intake_applications'],
    invalidateKeys: ['intake_applications'],
  })

  const { mutateAsync: reprocessApplicationAsync }: any = useCreate({
    name: ['intake_applications', 'reprocess'],
    url: `/intake_applications/${id}/reprocess`,
    invalidate: ['intake_applications'],
    invalidateKeys: ['intake_applications'],
  })

  const handleSave = async () => {
    const model = form.current.getFormValue()
    let application = {}
    let declineModel = null
    const reviewStatusModel = statusForm?.current?.getFormValue?.()

    if (!isNew && data?.status === 'declined') {
      declineModel = declineForm?.current?.getFormValue?.()
    }

    if (isNew) {
      application = await createAsync({
        source: 'app',
        reference_id: client?.id,
        reference_type: client?.type,
        application_form_id: model.application_form_id || initialData?.application_form?.id || initialData?.application_form_id,
        review_status: reviewStatusModel.review_status,
        client_signature_data: model.client_signature_data,
        client_signature_signed_at: model.client_signature_signed_at,
        data: {
          first_name: model.personal_details.first_name,
          last_name: model.personal_details.last_name,
          dob: model.personal_details.dob,
          sex: model.demographics?.sex,

          email: model.contact?.primary_contact?.email,
          phone_no: model.contact?.primary_contact?.phone_no,
          landline_no: model.contact?.primary_contact?.landline_no,

          ...model,
        },
      })
    } else {
      await updateAsync({
        data: {
          first_name: model.personal_details.first_name,
          last_name: model.personal_details.last_name,
          dob: model.personal_details.dob,
          sex: model.demographics?.sex,

          email: model.contact?.primary_contact?.email,
          phone_no: model.contact?.primary_contact?.phone_no,
          landline_no: model.contact?.primary_contact?.landline_no,

          ...model,
        },
        review_status: reviewStatusModel.review_status,
        decline_reason: declineModel?.decline_reason,
        client_signature_data: model.client_signature_data,
        client_signature_signed_at: model.client_signature_signed_at,
      })
    }

    close()

    if (isNew && application?.data?.id && location.pathname?.endsWith('/new')) {
      navigate(location.pathname.replace('/new', `/${application.data.id}`))
    }
  }

  const createAccessCode = async () => {
    await accessCodeMutateAsync({})

    Notifications.send(`Successfully sent a Reminder to ${data?.name}`, 'positive')

    setSentReminder(true)
  }

  const reprocessApplication = async () => {
    try {
      await reprocessApplicationAsync({})

      Notifications.send('Successfully Processed Application', 'positive')
    } catch (error) {
      Notifications.send('Failed to Process Application. Please try again, and if the issue persists reach out to us.', 'negative')
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const applicationForm = data?.application_form || initialData?.application_form || tenant?.default_application_form
  const schema = applicationForm?.schema

  const shouldProcessData =
    applicationForm?.should_process_contacts ||
    applicationForm?.should_process_insurance_policies ||
    applicationForm?.should_process_medications

  return (
    <Overlay
      onClose={close}
      showBackdrop={isNew || isEditable}
      closeWrapper={
        isEditable
          ? (closeElement: any) => (
              <Dialog
                glyph="delete"
                title="Close without saving?"
                message="All changes will be lost if not saved"
                yesColor="red"
                yesLabel="Yes, Close Without Saving"
                onYes={close}
              >
                {closeElement}
              </Dialog>
            )
          : undefined
      }
    >
      <Overlay.Header icon="applications" title={isNew ? 'Add Application' : 'Application'} />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/intake_applications/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <div css={{ padding: 20 }}>
          <Alert small contrast glyph="info" className="mb-4">
            <b>Note:</b> this application {isNew ? 'is' : 'was'} created using the{' '}
            <Link to={`/settings/application-forms/${applicationForm?.id}`} className="font-[600] inline-flex items-center" target="_blank">
              "{applicationForm?.name}" <Glyph size={12} glyph="external_link" className="ml-0.5 -mt-0.5" color={COLORS.blue} />
            </Link>{' '}
            template. You can manage all Application Form templates from the{' '}
            <Link to={`/settings/application-forms`} className="font-[600] inline-flex items-center" target="_blank">
              Settings page <Glyph size={12} glyph="external_link" className="ml-0.5 -mt-0.5" color={COLORS.blue} />
            </Link>
            .
          </Alert>

          <Flex vertical gap={20}>
            <Card className="!p-4">
              <Form getForm={statusForm} initialModel={data || initialModel} isEditable={isEditable} timezone={timezone}>
                <FormSection layout="vertical">
                  <Select label="Application Review Status" model="review_status" defaultValue="not_reviewed">
                    <Option label="Reviewed" value="reviewed" />
                    <Option label="Not Reviewed" value="not_reviewed" />
                    <Option label="More Info Required" value="more_info_required" />
                  </Select>
                </FormSection>
              </Form>
            </Card>

            <Card className="!p-4">
              <Form initialModel={data || initialModel} isEditable={isEditable} timezone={timezone}>
                <FormSection layout="vertical">
                  <Flex gap="1.5rem">
                    <DateTimeInput model="reminded_at" label="Reminded To Complete At" isEditable={false} />
                    <DateTimeInput model="processed_at" label="Processed At" isEditable={false} />
                  </Flex>
                </FormSection>
              </Form>
            </Card>

            {data?.status === 'declined' && (
              <Alert
                type="negative"
                glyph="decline"
                css={{
                  paddingBottom: '1em',
                  marginBottom: 20,
                }}
              >
                <Form getForm={declineForm} initialModel={data} isEditable={isEditable} timezone={timezone}>
                  <Textarea label="Decline Reason" model="decline_reason" />
                </Form>
              </Alert>
            )}

            <ApplicationForm
              form={form}
              companyName={tenant?.name}
              roiDisclaimerMessage={applicationForm?.roi_disclaimer_message}
              initialModel={{
                ...(isNew && initialData?.data),
                ...data?.data,
                client_signature: data?.client_signature,
              }}
              isEditable={isEditable}
              onValidationUpdate={onValidationUpdate}
              schema={schema}
              timezone={timezone}
              linked={{
                application_form_id: applicationForm?.id || tenant.default_application_form?.id,
              }}
            />
          </Flex>
        </div>

        {!isNew && data && (
          <>
            <Divider />

            <Section headingType="h2" title="Timeline">
              <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
            </Section>
          </>
        )}
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={handleSave}
              flex="100 1 auto"
              permission="applications.create"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} flex="1 1 auto" />}
          </>
        )}

        {!isEditable && (
          <Grid gap={8} stretchChildrenX>
            {sentReminder && (
              <Alert glyph="check" type="positive">
                We sent a reminder to {data?.name}
              </Alert>
            )}

            {includes(['lead', 'sent', 'opened', 'partial_completion'], data?.status) && (
              <ConfirmDialog
                glyph="behave_health"
                title="Ask Client to complete Application"
                message="This will send an SMS and Email to the Client. Are you sure you want to continue?"
                onYes={createAccessCode}
                isDisabled={!hasContactDetails}
              >
                <Grid gap={8}>
                  <Alert contrast glyph="info">
                    Add a <strong>Cell Phone Number</strong> and/or <strong>Email Address</strong> in the <strong>Contact</strong> section
                    above to text or email the Application Form to the Client.
                  </Alert>

                  <Tooltip
                    css={{ display: 'flex' }}
                    position="top"
                    content={`A valid Cell Phone Number or Email Address is required to send the Application Form`}
                    show={!hasContactDetails}
                  >
                    <Button
                      fullWidth
                      type="primary"
                      color="green"
                      label={`Ask ${data?.data?.personal_details?.first_name} to complete the Application`}
                      isLoading={isLoading}
                      isDisabled={!hasContactDetails}
                    />
                  </Tooltip>
                </Grid>
              </ConfirmDialog>
            )}

            {shouldProcessData && (
              <ConfirmDialog
                glyph="behave_health"
                title="Re-import Application Data?"
                yesLabel="Yes, Re-Import Data"
                message={
                  <Grid gap="1rem">
                    <Text>
                      Please find this <strong>Application Forms' Processing Options</strong> below. You can update them from the{' '}
                      <Link
                        to={`/settings/application-forms/${applicationForm?.id}`}
                        className="font-[600] inline-flex items-center"
                        target="_blank"
                      >
                        Settings page <Glyph size={12} glyph="external_link" className="ml-0.5 -mt-0.5" color={COLORS.blue} />
                      </Link>
                    </Text>

                    <DataList labelWidth={390}>
                      <DataList.Item
                        label="Save any Contacts entered under the Applicant's Contacts page"
                        value={
                          applicationForm?.should_process_contacts ? <Glyph size={20} glyph="check" /> : <Glyph size={20} glyph="cross" />
                        }
                      />
                      <DataList.Item
                        label="Save any Medications entered under the Applicant's Medications page"
                        value={
                          applicationForm?.should_process_insurance_policies ? (
                            <Glyph size={20} glyph="check" />
                          ) : (
                            <Glyph size={20} glyph="cross" />
                          )
                        }
                      />
                      <DataList.Item
                        label="Save any Insurance Policies under the Applicant's Insurance page"
                        value={
                          applicationForm?.should_process_medications ? (
                            <Glyph size={20} glyph="check" />
                          ) : (
                            <Glyph size={20} glyph="cross" />
                          )
                        }
                      />
                    </DataList>

                    <Alert contrast glyph="info">
                      We will automatically de-duplicate records based on the application data
                    </Alert>
                  </Grid>
                }
                onYes={reprocessApplication}
              >
                <Button fullWidth type="primary" color="green" label="Re-Import Application Data…" isLoading={isLoading} />
              </ConfirmDialog>
            )}

            <div>
              <Flex gap={8}>
                <Button
                  glyph="edit"
                  label="Edit Application"
                  type="default"
                  isDisabled={isLoading}
                  onClick={edit}
                  flex="100 1 auto"
                  permission="applications.edit"
                />

                <DeleteDialog
                  title="Delete Application?"
                  yesLabel="Yes, Delete"
                  message="Are you sure you want to delete this application? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button
                    glyph="delete"
                    label="Delete"
                    type="default"
                    color="red"
                    isLoading={isDeleting}
                    fullWidth
                    permission="applications.delete"
                  />
                </DeleteDialog>
              </Flex>
            </div>
          </Grid>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const IntakeApplicationOverlay = withOverlayError(RootIntakeApplicationOverlay)
