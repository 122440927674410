import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { apiUpdate } from '@behavehealth/modules/api'
import { Button, Form, Grid, HelpTagIframe, Page, Radio, RadioGroup } from '@behavehealth/components'
import { StaffAllowedIPsDataTable } from '@behavehealth/constructs/StaffIPRestrictions/StaffAllowedIPsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'staff_ip_restrictions',
  title: 'IP Restrictions',
  help: <HelpTagIframe id="staff_ip_restrictions" />,
}

const IPRestrictions: React.FC = () => {
  const { url }: any = useRouteMatch()
  const { resource_id }: any = useParams()

  const form = React.useRef()

  const { data: employee, isLoading: isLoadingEmployee }: any = useGet({
    name: ['employee', resource_id],
    url: `/employees/${resource_id}`,
  })

  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employee', resource_id],
    url: `/employees/${resource_id}`,
    invalidate: ['employees'],
  })

  const tableProps = useDataTable({
    name: ['employee', resource_id, 'allowed_ips'],
    endpoint: `/employees/${resource_id}/allowed_ips`,
    localStorageKey: 'employee_allowed_ips_v1',
  })

  const usingIPWhitelist = employee.prefs?.use_ip_whitelist

  return (
    <Page
      actions={usingIPWhitelist && <Button label="Add IP Restriction" type="primary" glyph="add" link={`${url}/new`} />}
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Form getForm={form} initialModel={{ prefs: employee.prefs }}>
          <RadioGroup
            label="Whitelist by IP"
            layout="vertical-dense"
            model="prefs.use_ip_whitelist"
            isDisabled={isLoadingEmployee}
            onChange={async ({ value }: any) =>
              await updateEmployee({
                prefs: {
                  ...employee.prefs,
                  use_ip_whitelist: value,
                },
              })
            }
          >
            <Radio label="No IP restrictions" value={false} />
            <Radio label="Yes, restrict by IP" value={true} />
          </RadioGroup>
        </Form>

        {usingIPWhitelist && <StaffAllowedIPsDataTable {...tableProps} to={(row) => `${url}/${row.id}`} />}
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(IPRestrictions, pageConfig))
