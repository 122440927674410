import React from 'react'
import { Link, Route, useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { ProgressNotesDataTable } from '@behavehealth/constructs/ProgressNotes/ProgressNotesDataTable'
import { ProgressNoteOverlay } from '@behavehealth/constructs/ProgressNotes/ProgressNoteOverlay'

const pageConfig = {
  feature: 'progress_notes',
  help: <HelpTagIframe id="progress_notes" />,
  marketingID: 'progress_notes',
}

type Props = {
  canCreate: boolean
}

const ProgressNotes = () => {
  return (
    <>
      <ProgressNotesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<ProgressNoteOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const ProgressNotesIndex: React.FC<Props> = ({ canCreate = true }) => {
  const { resource_id = '' }: any = useParams()

  const tableProps = useDataTable({
    name: ['client', resource_id, 'data_forms'],
    endpoint: `/residents/${resource_id}/data_forms`,
    updateDeleteEndpoint: '/data_forms',
    params: { category: 'progress_note' },
    enabled: !!resource_id,
    localStorageKey: 'client_progress_notes_v1',
  })

  const actions = <Button as={Link} label="Add Progress Note" type="primary" glyph="add" link="new" permission="progress_notes.create" />

  return (
    <Page actions={canCreate && actions} {...pageConfig}>
      <Grid gap="1rem">
        <ProgressNotesDataTable
          asCard
          hiddenColumnIds={['client']}
          to={(data: any) => data.id}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'progress_notes.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ProgressNotes, pageConfig))
