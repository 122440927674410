import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'

import { useSettings } from '../../hooks/useSettings'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Markup from '../../components/Markup'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { CustomQuestion } from './components/CustomQuestion'
import { COMMENT_STATUSES } from './constants'
import { NARRFormStatus } from '../AuthorityOrganizations/NARRFormStatus'
import { daysToWords } from '../../utils/functions'

import { AttachmentsInput } from './components/AttachmentsInput'

const getIsAnyTrue = (obj: any) => {
  if (!obj) return false

  for (let key in obj) {
    if (obj[key]?.show === true) return true
  }

  return false
}

export const AuthorityFormRenderer = (props: any) => {
  const {
    authorityName,
    sections,
    client,
    schema,
    useScrollView,
    isEditable,
    parentSection,
    enableComments,
    canUpload,
    isCommentsVisible,
    setIsCommentsVisible,
  }: any = props

  const { timezone } = useSettings()

  const formattedSections = React.useMemo(() => {
    if (!sections || !schema) return null

    const result: any = []
    let questionIndex = 0

    for (const section of sections) {
      if (!schema[section.model]?.show) continue

      const anyFieldsTrue = getIsAnyTrue(schema[section.model]?.fields)

      if (!anyFieldsTrue) continue

      const fields: any = []

      for (const field of section.fields) {
        if (!schema[section.model]?.fields?.[field.model]?.show) continue

        fields.push({
          ...field,
          label: `${questionIndex + 1}. ${field.label}`,
        })

        questionIndex++
      }

      result.push({ ...section, fields })
    }

    return result
  }, [sections, schema])

  if (!formattedSections) return null

  return (
    <>
      {formattedSections.map((section: any, index: number) => {
        const isLast = index === size(sections) - 1

        const show = schema[section.model]?.show

        if (!show) return null

        const anyFieldsTrue = getIsAnyTrue(schema[section.model]?.fields)

        if (!anyFieldsTrue) return null

        const hasParent = !!parentSection

        const parent = hasParent
          ? {
              id: parentSection.model,
              name: parentSection.title,
            }
          : section.navParent

        return (
          <React.Fragment key={section.model}>
            <Section
              id={`section_${section.model}`}
              key={section.model}
              title={section.title}
              // className={hasParent ? '!m-0 !mt-7 !p-0' : '!my-0 !py-6'}
              className="!my-7 !px-5"
              scrollview={
                useScrollView && {
                  id: section.model,
                  name: section.title,
                  parent: parent,
                }
              }
            >
              <FormSection
                maxWidth="100%"
                className="!gap-0"
                // className={clsx(enableComments ? '!gap-0' : '!gap-5')}
              >
                {schema?.[section.model]?.custom_text_before && <Markup value={schema?.[section.model]?.custom_text_before} />}

                {schema?.[section.model]?.custom_questions_before?.map?.((question: any) => {
                  return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                })}

                {section.fields?.map?.((field: any) => {
                  const FieldComponent = field.component || field.CustomComponent
                  const fieldSettings = schema[section.model]?.fields?.[field.model]

                  if (!fieldSettings?.show) return null

                  return (
                    <CommentsWrapper isCommentsVisible={isCommentsVisible} setIsCommentsVisible={setIsCommentsVisible}>
                      <FormSection maxWidth="100%">
                        <FieldComponent
                          key={field.model}
                          client={client}
                          isEditable={isEditable}
                          timezone={props.timezone || timezone}
                          schema={schema}
                          isRequired={fieldSettings.required}
                          authorityName={authorityName}
                          fieldModel={field.model}
                          fieldLabel={field.label}
                          withHover={false}
                        />

                        {fieldSettings.attachments && (
                          <AttachmentsInput canUpload={canUpload} model={`${section.model}.${field.model}_attachments`} />
                        )}
                      </FormSection>
                    </CommentsWrapper>
                  )
                })}

                {schema?.[section.model]?.custom_questions_after?.map?.((question: any) => {
                  return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                })}
              </FormSection>
            </Section>
          </React.Fragment>
        )
      })}
    </>
  )
}

const CommentsWrapper = (props: any) => {
  const { children, isCommentsVisible, setIsCommentsVisible } = props

  const { tenant, user } = useSettings()

  const [comments, setComments]: any = React.useState([])
  const commentsCount = size(comments)
  const hasComments = commentsCount > 0

  const [newComment, setNewComment] = React.useState('')
  const [showNewComment, setShowNewComment] = React.useState(false)

  const addComment = () => {
    setIsCommentsVisible(true)

    setComments([
      ...comments,
      {
        id: uuid(),
        content: newComment,
        organization: tenant?.name || 'Anonymous',
        name: user?.name || 'Anonymous',
        status: 'draft',
        created_at: DateTime.local().toISO(),
        updated_at: DateTime.local().toISO(),
      },
    ])
    setNewComment('')
    setShowNewComment(false)
  }

  const commentsVisible = hasComments || showNewComment

  return (
    <div
      className={clsx(
        '-mx-5 pl-5',
        'grid mq900:gap-6 relative hover:rounded-[5px] px-1.5 -mx-1.5 hover:bg-hover [&:hover_.HOVER]:!opacity-100 first:border-t border-b border-px border-0 border-solid border-divider',
        isCommentsVisible ? 'mq900:grid-cols-[1fr_340px]' : 'mq900:grid-cols-[1fr_55px]',
      )}
    >
      <div className="pt-2 mq900:pb-3">{children}</div>

      <div className="relative content-start grid gap-3 text-[0.96rem] mq900:border-l mq900:border-px mq900:border-0 mq900:border-solid mq900:border-divider mq900:pl-3 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between">
            {commentsVisible && (
              <div className="text-text-muted opacity-80 text-[0.85rem] uppercase font-[700] tracking-[0.5px]">Comments</div>
            )}
          </div>

          {!showNewComment && (
            <div className="mq900:absolute mq900:right-3 mq900:top-1.5">
              <Button
                hideLabel
                size={200}
                type="link"
                color="gray"
                glyph="comment"
                display="inline-flex"
                onClick={() => {
                  setIsCommentsVisible(true)
                  setShowNewComment(true)
                }}
                className="opacity-60 hover:opacity-100"
              />
            </div>
          )}
        </div>

        {hasComments && (
          <>
            {comments.map((comment) => (
              <Comment key={comment.id} comment={comment} />
            ))}

            {/* {!showNewComment && (
              <div className="flex justify-start">
                <Button
                  label="Add Comment"
                  size={100}
                  type="primary"
                  glyph="add"
                  display="inline-flex"
                  onClick={() => setShowNewComment(true)}
                />
              </div>
            )} */}
          </>
        )}

        {showNewComment && (
          <>
            <SmartTextarea
              isEditable
              autoFocus
              useQuickText={false}
              useDictation
              placeholder="Your comment"
              value={newComment}
              onUpdate={({ value }) => setNewComment(value)}
              withHover={false}
              key={`comment_${commentsCount}`}
            />

            <div className="mt-2">
              <Flex gap="0.5rem">
                <Button
                  label="Add Comment"
                  type="primary"
                  size={100}
                  color="green"
                  glyph="tick_circle"
                  display="inline-flex"
                  onClick={addComment}
                  isDisabled={!newComment}
                />

                <Button
                  label="Cancel"
                  size={100}
                  type="default"
                  onClick={() => {
                    setShowNewComment(false)
                    setNewComment('')
                  }}
                />
              </Flex>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const Comment = (props: any) => {
  const { comment } = props

  const [content, setContent] = React.useState(comment.content)
  const [isEditable, setIsEditable] = React.useState(false)

  if (!comment) return null

  const { organization, name, created_at } = comment

  return (
    <Card className="pt-2 pb-3 px-2">
      <div className="flex flex-nowrap">
        <div className="w-10 h-10 flex justify-start items-start justify-start">
          <Avatar src="" initials={organization} size={26} />
        </div>

        <div className="-mt-0.5">
          <div className="font-[600]">{organization}</div>
          <div className="text-[0.92em] text-text-muted">
            {name} ({daysToWords(created_at)})
          </div>
        </div>

        <div className="ml-auto pl-2 -mt-1">
          <NARRFormStatus status="draft" statuses={COMMENT_STATUSES} />
        </div>
      </div>

      <div className="pl-10 mt-1.5">
        {isEditable ? (
          <React.Fragment key="edit-mode">
            <SmartTextarea
              isEditable
              autoFocus
              useQuickText={false}
              useDictation
              placeholder="Your comment"
              value={content}
              onUpdate={({ value }) => setContent(value)}
              withHover={false}
            />

            <div className="mt-2">
              <Flex gap="0.75rem">
                <Button
                  label="Save"
                  type="primary"
                  size={100}
                  color="green"
                  glyph="tick_circle"
                  display="inline-flex"
                  onClick={() => setIsEditable(false)}
                  isDisabled={!content}
                />

                <Button
                  label="Cancel"
                  size={100}
                  type="default"
                  onClick={() => {
                    setContent(comment.content)
                    setIsEditable(false)
                  }}
                />
              </Flex>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment key="readonly-mode">
            <div className="mb-3">{content}</div>

            <Flex gap="0.5rem">
              <Button
                label="Edit"
                glyph="edit"
                type="default"
                size={100}
                display="inline-flex"
                className="!min-w-[80px]"
                onClick={() => setIsEditable(true)}
              />

              <DeleteDialog title="Delete Comment?" message="Are you sure you want to delete this comment? This action cannot be undone.">
                <Button label="Delete…" glyph="delete" type="default" color="red" size={100} display="inline-flex" />
              </DeleteDialog>
            </Flex>
          </React.Fragment>
        )}
      </div>
    </Card>
  )
}
