import React from 'react'
import { Link, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { OrganizationLeadFormOverlay } from '@behavehealth/constructs/OrganizationLeads/OrganizationLeadFormOverlay'
import { OrganizationLeadFormsDataTable } from '@behavehealth/constructs/OrganizationLeads/OrganizationLeadFormsDataTable'

const RootOrganizationLeadForms = () => {
  const tableProps = useDataTable({
    name: ['organization_lead_forms'],
    endpoint: `/organization_lead_forms`,
    localStorageKey: 'organization_lead_forms_v1',
  })

  return (
    <>
      <Page
        title="Organization Lead Contact Forms"
        feature="organization_lead_forms"
        actions={
          <Button
            as={Link}
            label="Add Lead Contact Form"
            glyph="add"
            type="primary"
            link="new"
            permission="settings.organization_lead_forms.create"
          />
        }
      >
        <Grid gap="1rem">
          <OrganizationLeadFormsDataTable {...tableProps} />
        </Grid>
      </Page>

      <AnimatedRoutes>
        <Route path=":id" element={<OrganizationLeadFormOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

export const OrganizationLeadForms = withPageError(RootOrganizationLeadForms)
