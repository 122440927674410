import React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import snakeCase from 'lodash/snakeCase'

import { mapToArray } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import Divider from '../../components/Divider'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'
import State from '../../components/State'
import SummonOverlay from '../../components/SummonOverlay'

import { ClientTrackTabs } from '../ClientTracks/ClientTrackTabs'
import ClientAgreementOverlay from '../../components/Overlays/pages/Agreements/ClientAgreementOverlay'
import ImportAgreementsOverlay from '../../components/Overlays/pages/Agreements/ImportAgreementsOverlay'

const filterAgreementType = (obj, agreement_type = 'general') => {
  const result = {}
  for (const key in obj) {
    if (obj[key].agreement_type === agreement_type) {
      result[key] = obj[key]
    }
  }
  return result
}

export const ClientAgreementsPageActions = ({ client, clientTrack, useSummonOverlay, buttonSize = 300 }: any) => {
  const match = useRouteMatch()
  const { pathname } = useLocation()

  const { isBehave, isManagement } = useSettings()
  const [clientTrackId, setClientTrackId] = React.useState(clientTrack?.id)

  const params = {
    variant: 'client',
    client_track_id: clientTrackId,
    ...(isBehave || isManagement ? { include_admin: true } : {}),
  }

  const { data, isLoading }: any = useGet({
    name: ['agreement_templates', params],
    url: `/agreement_templates`,
    params,
  })

  const templates = filterAgreementType(data, 'general')
  const adminTemplates = filterAgreementType(data, 'admin')

  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false)
  const [initialData, setInitialData] = React.useState({})

  const [isImportOverlayOpen, setIsImportOverlayOpen] = React.useState(false)

  React.useEffect(() => {
    if (!clientTrack?.id) return

    setClientTrackId(clientTrack.id)
  }, [clientTrack?.id])

  return (
    <>
      {useSummonOverlay && (
        <>
          <SummonOverlay
            isOpen={isOverlayOpen}
            onClose={() => {
              setIsOverlayOpen(false)
              setInitialData({})
            }}
            overlay={<ClientAgreementOverlay showBackdrop dataID="new" initialData={initialData} client={client} />}
          />

          <SummonOverlay
            isOpen={isImportOverlayOpen}
            onClose={() => {
              setIsImportOverlayOpen(false)
            }}
            overlay={<ImportAgreementsOverlay match={match} />}
          />
        </>
      )}

      <Dropdown
        label="Add Agreement…"
        glyph="add"
        buttonType="primary"
        buttonSize={300}
        permission="agreements.create"
        buttonSize={buttonSize}
      >
        <DropdownItem
          label="Empty Agreement"
          glyph="add"
          color="paleBlue"
          link={
            useSummonOverlay
              ? undefined
              : {
                  pathname: `${pathname}/new`,
                  parent: match,
                  data: { name: 'New Agreement' },
                }
          }
          onClick={
            useSummonOverlay
              ? () => {
                  setIsOverlayOpen(true)
                  setInitialData({ name: 'New Agreement' })
                }
              : undefined
          }
        />

        <Divider />

        <DropdownItem
          label="Import Multiple Agreements…"
          glyph="documents"
          color="blue"
          link={useSummonOverlay ? undefined : { pathname: `${pathname}/import`, parent: match }}
          onClick={useSummonOverlay ? () => setIsImportOverlayOpen(true) : undefined}
        />

        <Divider />

        <ClientTrackTabs clientTrack={clientTrack} activeId={clientTrackId} setActiveId={setClientTrackId} />

        {isLoading ? (
          <State isLoading minHeight={100} />
        ) : (
          <>
            {sortBy(mapToArray(templates), 'order', 'created_at').map((template) => (
              <DropdownItem
                testKey={`${snakeCase(template.name)}_dropdown_item`}
                key={template.id}
                label={template.name}
                icon="legal_agreement_alt"
                color="paleBlue"
                link={
                  useSummonOverlay
                    ? undefined
                    : {
                        pathname: `${pathname}/new`,
                        parent: match,
                        data: template,
                      }
                }
                onClick={
                  useSummonOverlay
                    ? () => {
                        setIsOverlayOpen(true)
                        setInitialData(template)
                      }
                    : undefined
                }
              />
            ))}

            {(isBehave || isManagement) && (
              <>
                <Divider />

                {sortBy(mapToArray(adminTemplates), 'order', 'created_at').map((template) => (
                  <DropdownItem
                    testKey={`${snakeCase(template.name)}_dropdown_item`}
                    key={template.id}
                    label={template.name}
                    icon="legal_agreement_alt"
                    color="paleBlue"
                    link={
                      useSummonOverlay
                        ? undefined
                        : {
                            pathname: `${pathname}/new`,
                            parent: match,
                            data: { ...template, agreement_type: 'admin' },
                          }
                    }
                    onClick={
                      useSummonOverlay
                        ? () => {
                            setIsOverlayOpen(true)
                            setInitialData({ ...template, agreement_type: 'admin' })
                          }
                        : undefined
                    }
                  />
                ))}
              </>
            )}
          </>
        )}
      </Dropdown>
    </>
  )
}
