import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { countWord, getClientLink, usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { NotApplicableCell } from '../../components/DataTable/cells/NotApplicableCell'

import Status from '../../components/Status'

import { FILTERS } from '../Filters/config'

export const VitalSignsDataTable = (props: any) => {
  const { to } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Date & Time',
        model: 'recorded_at',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell as={NavLink} id={data.id} to={to ? to(data) : undefined} value={usDateTime(value, timezone)} />
        },
      },
      {
        title: 'Client',
        model: 'client',
        formatValue: ({ value }) => {
          if (!value) return null

          return <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/test-results/vital-signs`} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => {
          if (value === 'refused') return <Status color="red" label="Refused Collection" />
          if (value === 'collected') return <Status color="blue" label="Collected" />
          return <NotApplicableCell />
        },
      },
      {
        title: 'Assessment Type',
        model: 'assessment_type',
        formatValue: ({ value }) => {
          if (value == 'normal') return 'Normal'
          else if (value == 'orthostatic') return 'Orthostatic'

          return null
        },
      },
      {
        title: 'Blood Pressure',
        model: 'blood_pressure_diastolic',
        id: '_blood_pressure',
        formatValue: ({ data }) => {
          const {
            assessment_type,
            blood_pressure_diastolic,
            blood_pressure_systolic,
            blood_pressure_systolic_lying_down,
            blood_pressure_diastolic_lying_down,
            blood_pressure_systolic_standing_1_minute,
            blood_pressure_diastolic_standing_1_minute,
            blood_pressure_systolic_standing_3_minute,
            blood_pressure_diastolic_standing_3_minute,
          } = data

          if (assessment_type === 'normal') {
            if (!blood_pressure_diastolic && !blood_pressure_systolic) return null
            return `${blood_pressure_systolic} / ${blood_pressure_diastolic} mm Hg`
          } else if (assessment_type === 'orthostatic') {
            return `
              Down: ${blood_pressure_systolic_lying_down || '–'} / ${blood_pressure_diastolic_lying_down || '–'} mm Hg, 
              St (1m): ${blood_pressure_systolic_standing_1_minute || '–'} / ${blood_pressure_diastolic_standing_1_minute || '–'}, 
              St (3m): ${blood_pressure_systolic_standing_3_minute || '–'} / ${blood_pressure_diastolic_standing_3_minute || '–'}
            `
          }

          return null
        },
      },
      {
        width: 100,
        title: 'Temp.',
        model: 'temperature',
        formatValue: ({ value }) => {
          if (!value) return null
          return `${value} °F`
        },
      },
      {
        width: 100,
        title: 'O2 Sat',
        model: 'oxygen_saturation',
        formatValue: ({ value }) => {
          if (!value) return null
          return `${value}%`
        },
      },
      {
        width: 180,
        title: 'Pulse',
        model: 'pulse',
        formatValue: ({ data }) => {
          const { assessment_type, pulse, pulse_lying_down, pulse_standing_1_minute, pulse_standing_3_minute } = data

          if (assessment_type === 'normal') {
            if (!pulse) return null
            return `${pulse} beats / min`
          } else if (assessment_type === 'orthostatic') {
            return `
              Down: ${pulse_lying_down || '–'} beats / min, 
              St (1m): ${pulse_standing_1_minute || '–'} beats / min, 
              St (3m): ${pulse_standing_3_minute || '–'} beats / min
            `
          }

          return null
        },
      },
      {
        width: 180,
        title: 'Respiration',
        model: 'respiration',
        formatValue: ({ value }) => {
          if (!value) return null
          return `${value} breaths / min`
        },
      },
      {
        width: 100,
        title: 'Pain Scale',
        model: 'pain_scale',
        formatValue: ({ value }) => {
          if (!value) return null
          return `${value} / 10`
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to, timezone],
  )

  return (
    <DataTable
      asCard
      testKey="vital_signs_data_table"
      title="Vital Signs"
      icon="test_results"
      columns={columns}
      filtersConfig={FILTERS.vital_signs}
      {...props}
    />
  )
}
