import React from 'react'

import AddressSelectorInput from '../../../components/Forms/AddressSelectorInput'
import Attachments from '../../../components/Forms/Attachments'
import DateInput from '../../../components/Forms/DateInput'
import EmailInput from '../../../components/Forms/EmailInput'
import Input from '../../../components/Forms/Input'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import SignatureInput from '../../../components/Forms/SignatureInput'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'

export const INCIDENT_REPORTING_FORM = [
  {
    title: 'Organization Information',
    model: 'organization_info',
    fields: [
      {
        label: 'Name of Organization',
        model: 'name',
        component: ({ isRequired }) => (
          <Input
            label="Name of Organization"
            model="organization_info.name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Address',
        model: 'address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Organization Address"
            model="organization_info.address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Contact Information',
    model: 'contact_info',
    fields: [
      {
        label: 'Full Name',
        model: 'full_name',
        component: ({ isRequired }) => (
          <Input
            label="Full Name"
            model="contact_info.full_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Title',
        model: 'title',
        component: ({ isRequired }) => (
          <Input
            label="Title"
            model="contact_info.title"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Email',
        model: 'email',
        component: ({ isRequired }) => (
          <EmailInput
            label="Email"
            model="contact_info.email"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Phone',
        model: 'phone',
        component: ({ isRequired }) => (
          <PhoneInput
            label="Phone"
            model="contact_info.phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Incident Details',
    model: 'incident_details',
    fields: [
      {
        label: 'Date of Report to Affiliate',
        model: 'report_date',
        component: ({ isRequired }) => (
          <Input
            label="Date of Report to Affiliate"
            model="incident_details.report_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Date of Incident',
        model: 'incident_date',
        component: ({ isRequired }) => (
          <Input
            label="Date of Incident"
            model="incident_details.incident_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Time of Incident',
        model: 'incident_time',
        component: ({ isRequired }) => (
          <Input
            label="Time of Incident"
            model="incident_details.incident_time"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Address of Incident',
        model: 'incident_address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Address of Incident"
            model="incident_details.incident_address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Incident Type',
        model: 'incident_type',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Incident Type"
            layout="vertical-dense"
            model="incident_details.incident_type"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Critical/Immediate" value="critical_immediate" />
            <Radio label="Serious" value="serious" />
            <Radio label="Not Serious" value="not_serious" />
          </RadioGroup>
        ),
      },
      {
        label: 'Details of Incident',
        model: 'incident_details',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Details of Incident"
            model="incident_details.incident_details"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Name of Person(s) Involved and Role',
        model: 'persons_involved',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Name of Person(s) Involved and Role"
            model="incident_details.persons_involved"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Contact Info of Person Involved',
        model: 'person_contact_info',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Contact Info of Person Involved"
            model="incident_details.person_contact_info"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Police Report',
    model: 'police_report',
    fields: [
      {
        label: 'Police Report Filed (Yes/No)',
        model: 'filed',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Police Report Filed (Yes/No)"
            model="police_report.filed"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Report #',
        model: 'report_number',
        component: ({ isRequired }) => (
          <Input
            label="Report #"
            model="police_report.report_number"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Reporting Officer Name',
        model: 'officer_name',
        component: ({ isRequired }) => (
          <Input
            label="Reporting Officer Name"
            model="police_report.officer_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Reporting Officer Phone',
        model: 'officer_phone',
        component: ({ isRequired }) => (
          <Input
            label="Reporting Officer Phone"
            model="police_report.officer_phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Follow-up Actions',
    model: 'follow_up_actions',
    fields: [
      {
        label: 'Actions taken to address this incident',
        model: 'actions_taken',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Actions taken to address this incident"
            model="follow_up_actions.actions_taken"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Measures taken to prevent future similar situations',
        model: 'preventive_measures',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Measures taken to prevent future similar situations"
            model="follow_up_actions.preventive_measures"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Additional help or resources needed to prevent future similar incidents',
        model: 'additional_resources',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Additional help or resources needed to prevent future similar incidents"
            model="follow_up_actions.additional_resources"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Signature and Date',
    model: 'signature_date',
    fields: [
      {
        label: 'Signature',
        model: 'signature',
        component: ({ isRequired }) => (
          <SignatureInput
            label="Signature"
            model="signature_date.signature"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Date',
        model: 'date',
        component: ({ isRequired }) => (
          <DateInput
            label="Date"
            model="signature_date.date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Additional Incident Information',
    model: 'additional_incident_info',
    fields: [
      {
        label: 'Emergency Services Called',
        model: 'emergency_services_called',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Emergency Services Called"
            textareaLabel="Please provide details"
            model="additional_incident_info.emergency_services_called"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Level of Severity',
        model: 'severity_level',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Level of Severity"
            layout="vertical-dense"
            model="additional_incident_info.severity_level"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Not serious" value="not_serious" />
            <Radio label="Serious" value="serious" />
            <Radio label="Critical" value="critical" />
          </RadioGroup>
        ),
      },
      {
        label: 'Certification Status',
        model: 'certification_status',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Certification Status"
            layout="vertical-dense"
            model="additional_incident_info.certification_status"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Level I" value="1" />
            <Radio label="Level II" value="2" />
            <Radio label="Level III" value="3" />
            <Radio label="Level IV" value="4" />
            <Radio label="Oxford" value="oxford" />
            <Radio label="Other" value="other" />
          </RadioGroup>
        ),
      },
      {
        label: "What were this person's drugs of choice, if applicable",
        model: 'drugs_of_choice',
        component: ({ isRequired }) => (
          <Input
            label="What were this person's drugs of choice, if applicable"
            model="additional_incident_info.drugs_of_choice"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Were there any pertinent behaviors prior to this incident or overdose that you can recall? What were those behaviors?',
        model: 'prior_behaviors',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Were there any pertinent behaviors prior to this incident or overdose that you can recall? What were those behaviors?"
            model="additional_incident_info.prior_behaviors"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'If called, how soon were emergency responders called and how long did it take them to arrive',
        model: 'emergency_response_time',
        component: ({ isRequired }) => (
          <Input
            label="If called, how soon were emergency responders called and how long did it take them to arrive"
            model="additional_incident_info.emergency_response_time"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Was anyone notified other than emergency responders',
        model: 'other_notifications',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Was anyone notified other than emergency responders"
            model="additional_incident_info.other_notifications"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'What has been or will be done to assess and address the community in the aftermath?',
        model: 'community_assessment',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="What has been or will be done to assess and address the community in the aftermath?"
            model="additional_incident_info.community_assessment"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Client Information',
    model: 'client_info',
    fields: [
      {
        label: 'Gender of Client if Applicable',
        model: 'gender',
        component: ({ isRequired }) => (
          <Input
            label="Gender of Client if Applicable"
            model="client_info.gender"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Race of Client',
        model: 'race',
        component: ({ isRequired }) => (
          <Input
            label="Race of Client"
            model="client_info.race"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Age of Client',
        model: 'age',
        component: ({ isRequired }) => (
          <Input
            label="Age of Client"
            model="client_info.age"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Are they a state resident',
        model: 'state_resident',
        component: ({ isRequired }) => (
          <Input
            label="Are they a state resident"
            model="client_info.state_resident"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Injured Party Information',
    model: 'injured_party_info',
    fields: [
      {
        label: 'Injured Party Name',
        model: 'name',
        component: ({ isRequired }) => (
          <Input
            label="Injured Party Name"
            model="injured_party_info.name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Injured Party DOB',
        model: 'dob',
        component: ({ isRequired }) => (
          <Input
            label="Injured Party DOB"
            model="injured_party_info.dob"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Injured Party Age',
        model: 'age',
        component: ({ isRequired }) => (
          <Input
            label="Injured Party Age"
            model="injured_party_info.age"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Affiliate Completes Section',
    model: 'affiliate_section',
    fields: [
      {
        label: 'Results of Incident',
        model: 'incident_results',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Results of Incident"
            layout="vertical-dense"
            model="affiliate_section.incident_results"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="No further action" value="no_further_action" />
            <Radio label="Further Investigation" value="further_investigation" />
            <Radio label="Immediate suspension followed by further investigation" value="immediate_suspension" />
            <Radio label="Immediate termination" value="immediate_termination" />
          </RadioGroup>
        ),
      },
      {
        label: 'Comments',
        model: 'comments',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Comments"
            model="affiliate_section.comments"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Required Actions',
        model: 'required_actions',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Required Actions"
            model="affiliate_section.required_actions"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Due Date',
        model: 'due_date',
        component: ({ isRequired }) => (
          <Input
            label="Due Date"
            model="affiliate_section.due_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Name of Affiliate Reviewer(s)',
        model: 'reviewer_names',
        component: ({ isRequired }) => (
          <Input
            label="Name of Affiliate Reviewer(s)"
            model="affiliate_section.reviewer_names"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Incident Closed Date',
        model: 'closed_date',
        component: ({ isRequired }) => (
          <Input
            label="Incident Closed Date"
            model="affiliate_section.closed_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Affiliate Signature',
        model: 'affiliate_signature',
        component: ({ isRequired }) => (
          <Input
            label="Affiliate Signature"
            model="affiliate_section.affiliate_signature"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Affiliate Signature Date',
        model: 'affiliate_signature_date',
        component: ({ isRequired }) => (
          <Input
            label="Affiliate Signature Date"
            model="affiliate_section.affiliate_signature_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Affiliate Email',
        model: 'affiliate_email',
        component: ({ isRequired }) => (
          <EmailInput
            label="Affiliate Email"
            model="affiliate_section.affiliate_email"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Operators Signature',
        model: 'operators_signature',
        component: ({ isRequired }) => (
          <Input
            label="Operators Signature"
            model="affiliate_section.operators_signature"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Operators Signature Date',
        model: 'operators_signature_date',
        component: ({ isRequired }) => (
          <Input
            label="Operators Signature Date"
            model="affiliate_section.operators_signature_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Results of Review',
        model: 'review_results',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Results of Review"
            layout="vertical-dense"
            model="affiliate_section.review_results"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Suspension of Accreditation" value="suspension_of_accreditation" />
            <Radio label="Termination of Accreditation" value="termination_of_accreditation" />
            <Radio label="Further Investigation Warranted" value="further_investigation_warranted" />
            <Radio label="Training/Support Required" value="training_support_required" />
            <Radio label="No Further Action Needed" value="no_further_action_needed" />
          </RadioGroup>
        ),
      },
      {
        label: 'Additional Files Upload',
        model: 'additional_files',
        component: ({ isRequired }) => (
          <Attachments
            label="Additional Files Upload"
            model="affiliate_section.additional_files"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
]
