import React from 'react'

import { OrganizationTagSelector } from '../Tags/OrganizationTagSelector'
import OverlaySubHeader from '../../components/Overlay/OverlaySubHeader'
import OrganizationProfileHeader from '../../components/OrganizationProfileHeader'

export const OrganizationWorkflowSubheader = ({ organization }) => {
  if (!organization) return null

  return (
    <OverlaySubHeader className="grid gap-2 !py-2 shadow-hard-2 relative z-[3]">
      <OrganizationProfileHeader organization={organization} />
      <OrganizationTagSelector organization={organization} />
    </OverlaySubHeader>
  )
}
