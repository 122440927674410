import React from 'react'
import { DateTime } from 'luxon'
import { tint } from 'polished'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { usDateTime } from '../../../utils/functions'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useSettings } from '../../../hooks/useSettings'
import { withFormContext } from '../../Forms/context'

import { PopoverMenu } from '../../../components/PopoverMenu'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Glyph from '../../../components/Glyph'

import { useStore } from '../utils/useStore'

const RootDateTimeCell = (props: any) => {
  const { canEdit, column, form, isEditing, model, onBlur, setValue, validations, value } = props

  const { timezone: settingsTimezone } = useSettings()

  const updateField: any = useStore((state: any) => state.updateField)
  const showInvalid: any = useStore((state: any) => state.showInvalid)
  const timezone: any = useStore((state: any) => state.timezone)

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    validations: validations,
    isValid: size(validations) === 0,
  })

  const { isInvalid } = formState

  const [isOpen, setIsOpen] = React.useState(isEditing)

  // Sync props value with state value
  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  React.useEffect(() => {
    if (column?.config?.defaultTo === 'now') {
      setValue(DateTime.local().setZone(settingsTimezone).toISO())
    }
  }, [column?.config?.defaultTo])

  // Update form state
  React.useEffect(() => {
    if (isUndefined(value)) return

    formActions.setValue(value)
  }, [value])

  const triggerClasses = clsx(isEditing && 'is-editing', isInvalid && showInvalid && 'is-invalid')

  const trigger = (
    <div className={triggerClasses} css={STYLES.trigger}>
      {value && usDateTime(value, timezone || settingsTimezone)}
      {canEdit && <Glyph glyph="date" size={12} color={COLORS.blue} className="select-trigger-date-glyph" />}
    </div>
  )

  if (!canEdit) return trigger

  return (
    <PopoverMenu
      isOpen={isOpen}
      onOpenUpdated={(currentIsOpen: boolean) => {
        setIsOpen(currentIsOpen)

        if (!currentIsOpen) onBlur()
      }}
      closeOnItemClick={false}
      maxWidth="640px"
      trigger={trigger}
    >
      <div css={STYLES.menu}>
        <DateTimeInput
          showPickerInline
          withHover={false}
          value={value}
          onUpdate={(state: any) => {
            setValue(state.value)
            updateField({ model, value: state.value })
          }}
          timezone={timezone}
        />
      </div>
    </PopoverMenu>
  )
}

const STYLES = {
  trigger: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    padding: '0.2rem',
    paddingLeft: '0.4rem',
    paddingRight: '1.2rem',
    overflow: 'hidden',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    '.select-trigger-label': {
      fontWeight: 500,
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.select-trigger-glyph': {
      marginRight: '0.5rem',
      pointerEvents: 'none',
    },

    '.select-trigger-date-glyph': {
      position: 'absolute',
      right: '0.25rem',
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },

    '&.is-empty': {
      fontStyle: 'italic',
      color: COLORS.textStronglyMuted,
    },

    '&.is-editing': {
      borderRadius: 3,
      background: COLORS.white,
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },
  },

  menu: {
    display: 'grid',
    gridTemplateColumns: '100%',

    '.DateTimeInput': {
      padding: '0.5rem 1rem',
      borderBottom: `1px solid ${COLORS.divider}`,
      margin: '0 !important',
    },
  },
}

export const DateTimeCell: any = withFormContext(RootDateTimeCell)
