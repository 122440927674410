import React from 'react'
import { NavLink, Navigate, Route, Routes, useNavigate, useParams, useMatch } from 'react-router-dom-v5-compat'
import kebabCase from 'lodash/kebabCase'
import snakeCase from 'lodash/snakeCase'
import size from 'lodash/size'

import { titleCase } from '@behavehealth/utils/functions'
import { useGet, useCreate } from '@behavehealth/hooks/useNewAPI'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'
import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Alert from '@behavehealth/components/Alert'
import Card from '@behavehealth/components/Card'
import Divider from '@behavehealth/components/Divider'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import PageHeader from '@behavehealth/components/PageHeader'
import State from '@behavehealth/components/State'
import Tabs from '@behavehealth/components/Tabs'

import { TreatmentPlansCategoryPage } from './pages/treatment_plans_category'
import { ClientTrackTabs } from '@behavehealth/constructs/ClientTracks/ClientTrackTabs'

const TreatmentPlansV2: React.FC = () => {
  const { url } = useRouteURL()
  const navigate = useNavigate()

  const locationMatch: any = useMatch({ path: `/:resourceType/:resourceId/treatment-plans/:category/*` })
  const category = snakeCase(locationMatch?.params?.category)
  const permissionBase = `${category}_treatment_plans`
  const templatePermissionBase = `${category}_treatment_plan_templates`

  const { resource_id: clientId }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
  })

  const [clientTrackId, setClientTrackId] = React.useState(client?.client_track?.id)

  const {
    viewCaseManagementTreatmentPlans,
    viewClinicalTreatmentPlans,
    viewMedicalTreatmentPlans,
    viewPeerTreatmentPlans,
    viewSomeTreatmentPlans,
  } = useTreatmentPlanPermissions()

  const allowedTabs = React.useMemo(() => {
    const result = []

    if (viewClinicalTreatmentPlans) result.push('clinical')
    if (viewMedicalTreatmentPlans) result.push('medical')
    if (viewCaseManagementTreatmentPlans) result.push('case_management')
    if (viewPeerTreatmentPlans) result.push('peer')

    return result
  }, [viewClinicalTreatmentPlans, viewMedicalTreatmentPlans, viewCaseManagementTreatmentPlans, viewPeerTreatmentPlans])

  const firstAllowedTab = allowedTabs[0]

  React.useEffect(() => {
    if (!client?.client_track?.id) return

    setClientTrackId(client?.client_track?.id)
  }, [client?.client_track?.id])

  React.useEffect(() => {
    if (!category || allowedTabs.includes(category)) return

    if (firstAllowedTab) navigate(`${kebabCase(firstAllowedTab)}`, { replace: true })
  }, [category, allowedTabs, firstAllowedTab])

  const { data: templates, isLoading: isLoadingTemplates } = useGet({
    name: ['treatment-plan-templates', { category, client_track_id: clientTrackId }],
    url: `/treatment_plan_templates`,
    params: { category, client_track_id: clientTrackId },
    options: { enabled: !!category },
  })

  const { mutateAsync: createTreatmentPlan, isLoading: isCreating } = useCreate({
    name: ['create-treatment-plan'],
    url: `/treatment_plans`,
    invalidateKeys: ['treatment_plans'],
  })

  const isTemplatesEmpty = size(templates) === 0

  const handleCreateTreatmentPlan = async (templateData?: any) => {
    try {
      const result = await createTreatmentPlan({
        name: templateData?.name || `${titleCase(category)} Treatment Plan`,
        ...templateData?.data,
        client_id: clientId,
        category,
      })

      if (result?.data?.id) navigate(`${url}/${kebabCase(category)}/${result.data.id}`)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div className="bg-white sticky top-0 z-10">
        <PageHeader
          title="Treatment Plans"
          icon="treatment_plans"
          aside={
            <Dropdown
              label={`Add ${titleCase(category)} Treatment Plan`}
              buttonType="primary"
              glyph="add"
              permission={`${permissionBase}.create`}
              isLoading={isCreating}
              position="bottom-end"
            >
              <ClientTrackTabs clientTrack={client?.client_track} activeId={clientTrackId} setActiveId={setClientTrackId} />

              {isTemplatesEmpty || isLoadingTemplates ? (
                <State
                  isEmpty={isTemplatesEmpty}
                  minHeight={60}
                  isLoading={isLoadingTemplates}
                  emptyDescription={
                    isTemplatesEmpty ? (
                      <Alert small glyph="info" className="!text-left !text-text">
                        You need to create a <b>{titleCase(category)} Treatment Plan Template</b> before you can create a Treatment Plan for
                        this client.
                      </Alert>
                    ) : (
                      `There is no active ${titleCase(category)} Treatment Plan created yet`
                    )
                  }
                />
              ) : (
                templates?.map((template: any) => (
                  <DropdownItem
                    key={template.id}
                    label={template.name}
                    icon="treatment_plans"
                    onClick={() => handleCreateTreatmentPlan(template)}
                  />
                ))
              )}

              <Divider />

              <DropdownItem
                glyph="settings"
                label={`Manage ${titleCase(category)} Templates`}
                link={`/settings/treatment-plan-templates/${kebabCase(category)}`}
                permission={`${templatePermissionBase}.view`}
              />
            </Dropdown>
          }
        />

        {viewSomeTreatmentPlans && (
          <Tabs>
            <Tabs.List className="px-4">
              <Tabs.Item as={NavLink} label="Clinical" to="clinical" permission={viewClinicalTreatmentPlans} />
              <Tabs.Item as={NavLink} label="Medical" to="medical" permission={viewMedicalTreatmentPlans} />
              <Tabs.Item as={NavLink} label="Case Management" to="case-management" permission={viewCaseManagementTreatmentPlans} />
              <Tabs.Item as={NavLink} label="Peer" to="peer" permission={viewPeerTreatmentPlans} />
            </Tabs.List>
          </Tabs>
        )}
      </div>

      {!viewSomeTreatmentPlans && (
        <div className="p-4">
          <Card>
            <State
              isEmpty
              icon="treatment_plans"
              title="Treatment Plans"
              emptyDescription="You do not have permissions to access Clinical, Medical nor Peer Treatment Plans. If you're looking for the old Treatment Plans, please click on the menu item under Clinical Archived in the Client's menu."
            />
          </Card>
        </div>
      )}

      {viewSomeTreatmentPlans && firstAllowedTab && (
        <Routes>
          <Route index element={<Navigate to={firstAllowedTab} replace />} />
          <Route path=":plan_category/*" element={<TreatmentPlansCategoryPage key={category} />} />
        </Routes>
      )}
    </div>
  )
}

export default withPageError(TreatmentPlansV2)
