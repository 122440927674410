import React from 'react'

import Icon from '../../Icon'
import Permission from '../../Permission'
import MultiObjectSelector from '../Selectors/MultiObject/MultiObjectSelector'

const selectTitle = (data: any) => data.name
const selectDescription = (data: any) => data.description
const selectGraphic = () => <Icon icon="organization_tracks" size={20} />

export const OrganizationTracksMultiObjectSelector = (props) => {
  return (
    <Permission featureFlagV2="organization_tracks">
      <MultiObjectSelector
        label="Applicable Organization Tracks"
        model="organization_tracks"
        type="organization_tracks"
        icon="organization_tracks"
        selectTitle={selectTitle}
        selectDescription={selectDescription}
        selectGraphic={selectGraphic}
        showAvatars={false}
        tooltip={
          <>
            <div>Select one or more Organization Tracks to restrict this template to the chosen tracks.</div>
            <div>Leave blank to make the template available for all Organization Tracks.</div>
          </>
        }
        {...props}
      />
    </Permission>
  )
}
