import React from 'react'
import { transparentize } from 'polished'
import get from 'lodash/get'
import isEqual from 'react-fast-compare'
import size from 'lodash/size'

import { MEDIA_QUERY } from '../../../../theme'
import { setIdleSaving } from '../../../../actions/common'
import { TIMEZONE } from '../../../../utils/constants'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ContextShow from '../../../Forms/ContextShow'
import DateTimeInput from '../../../Forms/DateTimeInput'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Dialog from '../../../Dialog'
import Divider from '../../../Divider'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Input from '../../../Forms/Input'
import Nav from '../../../Nav'
import NoInternet from '../../../Alerts/NoInternet'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../../Overlay'
import PageGrid from '../../../PageGrid'
import Permission from '../../../Permission'
import ScrollMenu from '../../../ScrollMenu'
import ScrollView from '../../../ScrollView'
import Section from '../../../Section'
import SignatureDialog from '../../../Dialogs/SignatureDialog'
import SignaturePad from '../../../Forms/SignaturePad'
import Switch from '../../../Forms/Switch'
import Textarea from '../../../Forms/Textarea'
import TextareaDialog from '../../../Dialogs/TextareaDialog'
import Timeline from '../../../Timeline/Timeline'
import TooltipButton from '../../../TooltipButton'

import { apiCreate, apiUpdate, apiDelete } from '../../../../modules/api'
import { clientSelector } from '../../../../reducers/selectors'

import { ExportPDFButton } from '../../../Buttons/ExportPDFButton'

export class DataFormsBase extends React.Component {
  constructor(props) {
    super(props)

    const dataID = props.match.params.id
    const isNew = dataID === 'new'
    let data = {}

    const defaultData = {
      status: 'employee_draft',
      category: props.category,
      subcategory: props.subcategory,
      version: props.version,
      data: {},
      author_signature: null,
      client_signature: null,
      supervisor_signature: null,
      author: props.author,
      client: props.client || {},
      client_id: props.client?.id,
      supervisor: null,
    }

    if (isNew) data = Object.assign({}, defaultData, props.location.data)
    else data = get(props, `data.${dataID}`, {})

    this.state = {
      id: dataID,
      form: { isInvalid: true },
      data: data,
      origin: { ...data },
      isValid: false,
      isInvalid: true,
      $new: isNew,
      $editable: isNew,
      dated_at: null,
    }

    this.form = React.createRef()
    this.isPortal = props.location?.source === 'portal' || process.env.BH_APP === 'portal'
  }

  /*
    LIFECYCLE
  */
  shouldComponentUpdate = (nextProps, nextState) => {
    if (!isEqual(nextProps.data_form, this.props.data_form)) return true
    if (nextProps.loading !== this.props.loading) return true
    if (nextProps.idle !== this.props.idle) return true
    if (nextState.$editable !== this.state.$editable) return true
    if (nextState.isValid !== this.state.isValid) return true
    if (nextState.dated_at !== this.state.dated_at) return true

    return false
  }

  componentDidUpdate = (prevProps) => {
    // if (!prevProps.idle && this.props.idle) this.onIdle()
  }

  /*
    CUSTOM FUNCTIONS
  */
  renderHeader = () => null
  renderContent = () => null
  renderSidebar = () => <ScrollMenu />

  onIdle = async () => {
    const { match, data_form, category, subcategory, version } = this.props
    const { $new, isValid } = this.state

    if (isValid) {
      this.props.idleSaving()

      const client_id = $new ? this.props.client.id : data_form.client.id
      const data = {
        id: data_form.id,
        name: this.props.title,
        ...this.form.current.getFormValue(),
        client_id: client_id,
        category: category,
        subcategory: subcategory,
        version: version,
      }

      if ($new) await apiCreate({ name: 'data_forms', url: `/data_forms`, params: data })
      else await apiUpdate({ name: 'data_forms', url: `/data_forms/${match.params.id}`, params: data })
    }
  }

  onDisabledClick = () => {
    this.form.current.validate()
  }

  onDateUpdate = (date) => {
    this.setState({ dated_at: date.value })
  }

  close = () => {
    if (this.props.location.parent) {
      this.props.history.push(this.props.location.parent.url)
    } else {
      const path = this.props.location.pathname
      this.props.history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  edit = () => this.setState({ $editable: true })

  cancel = () => {
    this.form.current.resetForm()
    this.setState({ $editable: false })
  }

  save = async () => {
    const { match, data_form, category, subcategory, version } = this.props
    const { $new } = this.state

    const client_id = $new ? this.props.client.id : data_form.client.id

    const formData = this.form.current.getFormValue()

    const data = {
      id: data_form.id,
      name: this.props.title,
      ...formData,
      client_id: client_id,
      category: category,
      subcategory: subcategory,
      version: version,
      status: formData?.client_should_complete ? 'client_draft' : 'employee_draft',
    }

    if ($new) await apiCreate({ name: 'data_forms', url: `/data_forms`, params: data })
    else await apiUpdate({ name: 'data_forms', url: `/data_forms/${match.params.id}`, params: data })

    this.close()

    // this.setState({ $editable: false, origin: data })
  }

  delete = async () => {
    await apiDelete({ name: 'data_forms', url: `/data_forms/${this.props.match.params.id}` })
    this.close()
  }

  /*
    BUSINESS LOGIC
  */
  updateStatusTo = async (status) => {
    await apiUpdate({
      name: 'data_forms',
      url: `/data_forms/${this.props.data_form.id}`,
      params: {
        status: status,
      },
    })

    this.setState({ $editable: false })
  }

  sendForSupervisorReview = async (signature) => {
    await apiUpdate({
      name: 'data_forms',
      url: `/data_forms/${this.props.data_form.id}`,
      params: {
        [signature.model]: signature.value,
      },
    })

    this.setState({ $editable: false })
  }

  requestEmployeeUpdates = async (updates) => {
    await apiUpdate({
      name: 'data_forms',
      url: `/data_forms/${this.props.data_form.id}`,
      params: {
        status: 'employee_updates_required',
        requested_updates: updates.value,
      },
    })

    this.setState({ $editable: false })
  }

  requestClientUpdates = async (updates) => {
    await apiUpdate({
      name: 'data_forms',
      url: `/data_forms/${this.props.data_form.id}`,
      params: {
        status: 'client_updates_required',
        client_updates_requested: updates.value,
      },
    })

    this.setState({ $editable: false })
  }

  signOff = async (signature) => {
    await apiUpdate({
      name: 'data_forms',
      url: `/data_forms/${this.props.data_form.id}`,
      params: {
        [signature.model]: signature.value,
      },
    })

    this.setState({ $editable: false })
  }

  signOffWithoutSupervisor = async (signature) => {
    await apiUpdate({
      name: 'data_forms',
      url: `/data_forms/${this.props.data_form.id}`,
      params: {
        [signature.model]: signature.value,
        status: 'signed_off',
      },
    })

    this.setState({ $editable: false })
  }

  buildPermission = (permission_name, type) => {
    return permission_name ? `${permission_name}.${type}` : true
  }

  /*
    RENDER
  */
  render() {
    const { $new, $editable, isInvalid, closing, data } = this.state
    const {
      user,
      title,
      loading,
      location,
      signoffWithoutSupervisor,
      requireSupervisor,
      requestClientSignature,
      enableClientWorkflow,
      requestAuthorSignature,
      requestSupervisorSignature,
      data_form,
      online,
      timezone,
      tenant,
      defaultDebounce,
      maxWidth = 70,
    } = this.props

    const record = $new ? data : data_form

    if (!$new && size(record) === 0) {
      return (
        <Overlay showBackdrop position="center">
          <div css={styles.loaderWrapper}>
            <div>Loading…</div>
          </div>
        </Overlay>
      )
    }

    const isSupervisor = user?.id === record?.supervisor?.id
    const permission_name = location && location.permission_name
    const clientShouldComplete = enableClientWorkflow && record.client_should_complete
    const isTrialing = tenant?.plan_status === 'trialing'

    return (
      <Overlay
        position="center"
        onClose={this.close}
        showBackdrop
        maxWidth={maxWidth}
        isDirty={$editable}
        closeWrapper={(element, onClose) => (
          <Dialog
            glyph="check"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            skip={!$editable || !onClose}
            onYes={onClose}
          >
            {element}
          </Dialog>
        )}
      >
        {this.renderHeader()}

        {!this.state.$editable && !isTrialing && (
          <Permission permission="clients.actions.export">
            <Overlay.SubHeader>
              <ExportPDFButton url={`/data_forms/${this.state.id}/pdf`} />
            </Overlay.SubHeader>
          </Permission>
        )}

        <ScrollView>
          <PageGrid scroll breakpoint={3} css={STYLES.pageGrid}>
            <Nav
              top="0"
              breakpoint={3}
              title={' '}
              headingSize={300}
              desktopProps={{ title: null }}
              className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
            >
              <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
              {this.renderSidebar()}
            </Nav>

            <Overlay.Content preventScroll={closing} style={{ padding: 1 }}>
              <Form
                getForm={this.form}
                initialModel={record}
                timezone={timezone}
                defaultDebounce={defaultDebounce}
                isEditable={$editable}
                onValidationUpdate={(valid: any) => this.setState({ isValid: valid, isInvalid: !valid })}
              >
                <Section
                  scrollview={{
                    id: 'general',
                    name: 'General',
                  }}
                  headingType="h2"
                  title="General"
                >
                  <FormSection>
                    {!$new && (
                      <>
                        <ObjectSelector
                          label="Author"
                          blankLabel="Select Staff…"
                          icon="employees"
                          type="employees"
                          model="author"
                          selectTitle={(data) => data.name}
                          selectDescription={() => null}
                          isEditable={false}
                          disableLink={this.isPortal}
                        />
                      </>
                    )}

                    <Input
                      label="Name"
                      model="name"
                      defaultValue={title}
                      isEditable={$editable && !this.isPortal}
                      validations={{
                        presence: {
                          message: 'Please select a Supervisor',
                        },
                      }}
                    />

                    {requireSupervisor && (
                      <ObjectSelector
                        isPolymorphic
                        label="Supervisor"
                        blankLabel="Select Supervisor…"
                        icon="employees"
                        type="clinical_supervisors"
                        model="supervisor"
                        selectTitle={(data) => data.name}
                        selectDescription={() => null}
                        disableLink={this.isPortal}
                        isEditable={$editable && !this.isPortal}
                        validations={{
                          presence: {
                            message: 'Please select a Supervisor',
                          },
                        }}
                      />
                    )}

                    <DateTimeInput
                      defaultToNow
                      model="dated_at"
                      label="Date and Time"
                      isEditable={$editable && !this.isPortal}
                      onUpdate={(state) => this.onDateUpdate(state)}
                      validations={{
                        presence: {
                          message: 'Please enter a date and time',
                        },
                      }}
                    />

                    <DateTimeInput
                      model="ended_at"
                      label="Session End Time"
                      isEditable={$editable && !this.isPortal}
                      onUpdate={(state) => this.onDateUpdate(state)}
                      validations={{
                        presence: {
                          message: 'Please enter a date and time',
                        },
                      }}
                    />
                  </FormSection>
                </Section>

                <Divider />

                {!this.isPortal && enableClientWorkflow && (
                  <>
                    <Section headingType="h2" title="Client">
                      <FormSection>
                        <CheckboxGroup trueIcon="check" falseStyle="faded" falseIcon="cross">
                          <Checkbox label="Request client to complete this form" model="client_should_complete" />
                        </CheckboxGroup>

                        <ContextShow when="client_should_complete" is={true}>
                          <ObjectSelector
                            isPolymorphic
                            label="Reviewing Staff"
                            blankLabel="Select Reviewing Staff…"
                            icon="employees"
                            type="employees"
                            model="reviewing_employee"
                            selectTitle={(data) => data.name}
                            selectDescription={() => null}
                            disableLink={this.isPortal}
                            validations={{
                              presence: {
                                message: 'Please select a Reviewing Staff',
                              },
                            }}
                          />
                        </ContextShow>
                      </FormSection>
                    </Section>

                    <Divider />
                  </>
                )}

                {!this.isPortal && record.status === 'employee_updates_required' && (
                  <>
                    <Section>
                      <Alert type="negative" glyph="note">
                        <Textarea fullWidth label="Updates Requested before Sign-Off" model="requested_updates" />
                      </Alert>
                    </Section>

                    <Divider />
                  </>
                )}

                {record.status === 'client_updates_required' && (
                  <>
                    <Section>
                      <Alert type="negative" glyph="note">
                        <Textarea
                          fullWidth
                          label="Client Updates Requested before Sign-Off"
                          model="client_updates_requested"
                          isEditable={$editable && !this.isPortal}
                        />
                      </Alert>
                    </Section>

                    <Divider />
                  </>
                )}

                {tenant.is_billed && !this.isPortal && (
                  <>
                    <Section
                      headingType="h2"
                      title="Billing"
                      description="Use this section to record the billable service provided to the Client"
                      aside={<Switch defaultValue={false} model="is_billable" />}
                    >
                      <ContextShow when="is_billable" is={true}>
                        <FormSection maxWidth="100%">
                          <ObjectSelector
                            dependentValue={record.client?.id || this.props.client?.id}
                            model="insurance_service"
                            label="Rendered Insurance Service"
                            type="client.insurance_services"
                            icon="billing"
                            searchKeys={['code', 'short_description', 'long_description']}
                            selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
                            selectDescription={(o: any) => o?.long_description}
                            groupBy={{
                              accessor: 'service_type',
                              sections: {
                                in_network: 'In Network',
                                out_of_network: 'Out of Network',
                                global_oon: 'Global Out of Network',
                              },
                            }}
                            validations={{
                              presence: {
                                message: 'Please select a service',
                              },
                            }}
                          />

                          <Textarea model="billing_notes" label="Notes" />
                        </FormSection>
                      </ContextShow>
                    </Section>

                    <Divider />
                  </>
                )}

                {this.renderContent()}

                {(requestClientSignature || requestAuthorSignature || requestSupervisorSignature) && (
                  <>
                    <Divider />

                    <Section
                      scrollview={{
                        id: 'signatures',
                        name: 'Signatures',
                      }}
                      headingType="h2"
                      title="Signatures"
                    >
                      <FormSection layout="vertical" maxWidth={600}>
                        {requestClientSignature && (
                          <SignaturePad
                            label="Client Signature"
                            person={record.client || this.props.client}
                            model="client_signature"
                            signedAtModel="client_signed_at"
                            allowPin={false}
                          />
                        )}

                        {record.author_signature && (
                          <SignaturePad
                            label="Author Signature"
                            person={record.author}
                            allowPin={false}
                            model="author_signature"
                            signedAtModel="author_signed_at"
                          />
                        )}

                        {record.supervisor_signature && (
                          <SignaturePad
                            label="Supervisor Signature"
                            person={record.supervisor}
                            allowPin={false}
                            model="supervisor_signature"
                            signedAtModel="supervisor_signed_at"
                          />
                        )}

                        {!requestClientSignature && !record.author_signature && !record.supervisor_signature && (
                          <>
                            <p>No signatures added yet</p>
                          </>
                        )}
                      </FormSection>
                    </Section>
                  </>
                )}

                {!$new && (
                  <>
                    <Divider />

                    <Section
                      headingType="h2"
                      title="Timeline"
                      scrollview={{
                        id: 'timeline',
                        name: 'Timeline',
                      }}
                    >
                      <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} disableLinks={this.isPortal} />
                    </Section>
                  </>
                )}
              </Form>
            </Overlay.Content>
          </PageGrid>
        </ScrollView>

        {record.status !== 'signed_off' && !this.isPortal && (
          <Overlay.Footer>
            {$editable && online && (
              <>
                <Button
                  label={$new ? 'Save as Draft' : 'Save Changes'}
                  glyph="check"
                  type="primary"
                  color="green"
                  onClick={this.save}
                  isLoading={loading}
                  isDisabled={loading}
                  flex="100 1 auto"
                  permission={this.buildPermission(permission_name, 'create')}
                />
                {isInvalid && (
                  <Button
                    label="Highlight Required Fields"
                    glyph="view"
                    type="default"
                    color="orange"
                    onClick={this.onDisabledClick}
                    isDisabled={loading}
                  />
                )}
                {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
              </>
            )}

            {!$editable && online && (
              <>
                {(record.status === 'employee_draft' || record.status === 'client_draft') && (
                  <>
                    <Button
                      glyph="edit"
                      label={`Edit ${title}`}
                      type="default"
                      isDisabled={loading}
                      onClick={this.edit}
                      flex="100 1 auto"
                      permission={this.buildPermission(permission_name, 'edit')}
                    />
                    <DeleteDialog
                      title={`Delete ${title}?`}
                      message={`Are you sure you want to delete this ${title}? This action cannot be undone.`}
                      onYes={this.delete}
                    >
                      <Button
                        glyph="delete"
                        label={`Delete ${title}…`}
                        type="default"
                        color="red"
                        isDisabled={loading}
                        fullWidth
                        permission={this.buildPermission(permission_name, 'delete')}
                      />
                    </DeleteDialog>
                    {!isSupervisor && !signoffWithoutSupervisor && (
                      <SignatureDialog
                        model="author"
                        title="Sign & Send for Review"
                        yesLabel="Apply Signature & Send for Review"
                        onYes={this.sendForSupervisorReview}
                        isDisabled={loading || isInvalid}
                        onDisabledClick={this.onDisabledClick}
                      >
                        <TooltipButton
                          fullWidth
                          label="Sign & Send for Supervisor Review…"
                          glyph="signature"
                          type="default"
                          isDisabled={loading || isInvalid}
                          permission={this.buildPermission(permission_name, 'edit')}
                          show={isInvalid}
                          message="Click to highlight required fields"
                        />
                      </SignatureDialog>
                    )}
                    {isSupervisor && (
                      <SignatureDialog
                        model="supervisor"
                        title="Sign Off as Supervisor"
                        yesLabel="Apply Signature & Sign Off"
                        onYes={this.signOff}
                        isDisabled={loading || isInvalid}
                        onDisabledClick={this.onDisabledClick}
                      >
                        <TooltipButton
                          fullWidth
                          label="Sign Off as Supervisor…"
                          glyph="signature"
                          type="primary"
                          color="green"
                          isDisabled={loading || isInvalid}
                          permission={this.buildPermission(permission_name, 'edit')}
                          show={isInvalid}
                          message="Click to highlight required fields"
                        />
                      </SignatureDialog>
                    )}
                    {signoffWithoutSupervisor && (
                      <SignatureDialog
                        model="author"
                        title="Sign Off"
                        yesLabel="Apply Signature & Sign Off"
                        onYes={this.signOffWithoutSupervisor}
                        isDisabled={loading || isInvalid}
                        onDisabledClick={this.onDisabledClick}
                      >
                        <TooltipButton
                          fullWidth
                          label="Sign Off"
                          glyph="signature"
                          type="primary"
                          color="green"
                          isDisabled={loading || isInvalid}
                          permission={this.buildPermission(permission_name, 'edit')}
                          show={isInvalid}
                          message="Click to highlight required fields"
                        />
                      </SignatureDialog>
                    )}
                  </>
                )}

                {(record.status === 'pending_review' ||
                  record.status === 'employee_pending_review' ||
                  record.status === 'supervisor_pending_review') && (
                  <>
                    <Button
                      glyph="edit"
                      label={`Edit ${title}`}
                      type="default"
                      isDisabled={loading}
                      onClick={this.edit}
                      flex="100 1 auto"
                      permission={this.buildPermission(permission_name, 'edit')}
                    />
                    <DeleteDialog
                      title={`Delete ${title}?`}
                      message={`Are you sure you want to delete this ${title}? This action cannot be undone.`}
                      onYes={this.delete}
                    >
                      <Button
                        glyph="delete"
                        label={`Delete ${title}…`}
                        type="default"
                        color="red"
                        isDisabled={loading}
                        fullWidth
                        permission={this.buildPermission(permission_name, 'delete')}
                      />
                    </DeleteDialog>

                    {clientShouldComplete && (
                      <TextareaDialog
                        model="requested_updates"
                        title="What updates should be made?"
                        onYes={this.requestClientUpdates}
                        yesLabel="Ask For Client Updates"
                      >
                        <Button
                          label="Request Client Updates…"
                          type="default"
                          color="orange"
                          glyph="asterisk"
                          isDisabled={loading}
                          permission={this.buildPermission(permission_name, 'edit')}
                        />
                      </TextareaDialog>
                    )}

                    {isSupervisor && (
                      <TextareaDialog
                        model="requested_updates"
                        title="What updates should be made?"
                        onYes={this.requestEmployeeUpdates}
                        yesLabel="Ask For Staff Updates"
                      >
                        <Button
                          label="Request Staff Updates…"
                          type="default"
                          color="orange"
                          glyph="asterisk"
                          isDisabled={loading}
                          permission={this.buildPermission(permission_name, 'edit')}
                        />
                      </TextareaDialog>
                    )}

                    {!isSupervisor && (
                      <TooltipButton
                        fullWidth
                        label="Send for Supervisor Review"
                        glyph="signature"
                        type="default"
                        isDisabled={loading || isInvalid}
                        onClick={() => this.updateStatusTo('supervisor_pending_review')}
                        onDisabledClick={this.onDisabledClick}
                        permission={this.buildPermission(permission_name, 'edit')}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    )}

                    {isSupervisor && (
                      <SignatureDialog
                        model="supervisor"
                        title="Sign Off as Supervisor"
                        yesLabel="Apply Signature & Sign Off"
                        onYes={this.signOff}
                        isDisabled={loading || isInvalid}
                        onDisabledClick={this.onDisabledClick}
                      >
                        <TooltipButton
                          fullWidth
                          label="Sign Off as Supervisor…"
                          glyph="signature"
                          type="primary"
                          color="green"
                          isDisabled={loading || isInvalid}
                          permission={this.buildPermission(permission_name, 'edit')}
                          show={isInvalid}
                          message="Click to highlight required fields"
                        />
                      </SignatureDialog>
                    )}
                  </>
                )}

                {(record.status === 'employee_updates_required' || record.status === 'client_updates_required') && (
                  <>
                    <Button
                      glyph="edit"
                      label={`Edit ${title}`}
                      type="default"
                      isDisabled={loading}
                      onClick={this.edit}
                      flex="100 1 auto"
                      permission={this.buildPermission(permission_name, 'edit')}
                    />
                    <DeleteDialog
                      title={`Delete ${title}?`}
                      message={`Are you sure you want to delete this ${title}? This action cannot be undone.`}
                      onYes={this.delete}
                    >
                      <Button
                        fullWidth
                        glyph="delete"
                        label={`Delete ${title}…`}
                        type="default"
                        color="red"
                        isDisabled={loading}
                        permission={this.buildPermission(permission_name, 'delete')}
                      />
                    </DeleteDialog>

                    {!isSupervisor && (
                      <TooltipButton
                        fullWidth
                        label="Send for Supervisor Review"
                        glyph="signature"
                        type="default"
                        isDisabled={loading || isInvalid}
                        onClick={() => this.updateStatusTo('supervisor_pending_review')}
                        onDisabledClick={this.onDisabledClick}
                        permission={this.buildPermission(permission_name, 'edit')}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    )}

                    {isSupervisor && (
                      <SignatureDialog
                        model="supervisor"
                        title="Sign Off as Supervisor"
                        yesLabel="Apply Signature & Sign Off"
                        onYes={this.signOff}
                        isDisabled={loading || isInvalid}
                        onDisabledClick={this.onDisabledClick}
                      >
                        <TooltipButton
                          fullWidth
                          label="Sign Off as Supervisor…"
                          glyph="signature"
                          type="primary"
                          color="green"
                          isDisabled={loading || isInvalid}
                          permission={this.buildPermission(permission_name, 'edit')}
                          show={isInvalid}
                          message="Click to highlight required fields"
                        />
                      </SignatureDialog>
                    )}
                  </>
                )}
              </>
            )}

            {!online && <NoInternet />}
          </Overlay.Footer>
        )}

        {record.status !== 'signed_off' && this.isPortal && clientShouldComplete && (
          <Overlay.Footer>
            {$editable && (
              <>
                <Button
                  label="Save Changes"
                  type="primary"
                  color="green"
                  glyph="check"
                  onClick={this.save}
                  isLoading={loading}
                  isDisabled={loading}
                  flex="100 1 auto"
                />
                <Button label="Cancel" glyph="cross" onClick={this.cancel} isDisabled={loading} />
              </>
            )}

            {!$editable && (
              <>
                <Button label="Edit" glyph="edit" onClick={this.edit} />

                {(record.status === 'client_draft' || record.status === 'client_updates_required') && (
                  <Button
                    label="Send For Review"
                    type="primary"
                    color="green"
                    glyph="check"
                    onClick={() => this.updateStatusTo('employee_pending_review')}
                    isLoading={loading}
                    isDisabled={loading}
                  />
                )}
              </>
            )}
          </Overlay.Footer>
        )}
      </Overlay>
    )
  }
}

const styles = {
  loaderWrapper: {
    padding: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

DataFormsBase.defaultProps = {
  title: 'Form',
  defaultDebounce: 300,
  signoffWithoutSupervisor: false,
  requireSupervisor: true,
  requestClientSignature: true,
  requestAuthorSignature: true,
  requestSupervisorSignature: true,
  enableClientWorkflow: false,
}

const STYLES = {
  pageGrid: {
    flex: '1 1 auto',
  },

  nav: {
    [MEDIA_QUERY[3]]: {
      maxWidth: 300,
    },
  },
}

export const mapDispatchToProps = (dispatch) => ({
  idleSaving: () => dispatch(setIdleSaving(true)),
})

export const mapStateToProps = (state, props) => ({
  client: clientSelector(state, props.match),
  user: state.me.user,
  loading: get(state, `data.data_forms.loading`),
  data: get(state, `data.data_forms.data`),
  data_form: get(state, `data.data_forms.data.${props.match.params.id}`, {}),
  online: state.common.global.online,
  idle: state.common.global.idle,
  tenant: state.me.tenant,
  timezone: state.me.tenant.timezone || TIMEZONE,
})
