import React from 'react'
import size from 'lodash/size'
import { v4 as uuid } from 'uuid'
import TextareaAutosize from 'react-autosize-textarea'

import { COLORS, INPUT_STYLES } from '../../theme'
import { isDefined } from '../../utils/functions'
import { validate } from './validators'
import { withFormContext } from './context'
import Flex from '../Flex'
import Value from '../Value'
import Permission from '../Permission'

import FieldBase from './FieldBase'

import { QuickTextSelector } from '../../constructs/QuickText/QuickTextSelector'
import { snakeCase } from 'lodash'

const DEFAULT_EMPTY_VALUE = ''

class Textarea extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = props.validations
    let value = props.value

    if (!value && props.model) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

      value = modelVal || initialModelVal || DEFAULT_EMPTY_VALUE
    }

    if (!value) {
      if (isDefined(props.defaultValue)) value = props.defaultValue
      else value = DEFAULT_EMPTY_VALUE
    }

    if (vs) errors = validate(value, vs)

    this.state = {
      type: 'TEXTAREA',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isBlur: false,
      isRequired:
        (props.validations && props.validations.hasOwnProperty('presence')) ||
        (props.validations?.length && props.validations.length.hasOwnProperty('minimum')),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }
    this.updateType = 'DATA'
  }

  editRender = () => {
    const { validations, useQuickText, testKeyQuickTextSelector }: any = this.props
    const { value }: any = this.state

    const minLength = validations?.length?.minimum
    const maxLength = validations?.length?.maximum

    return (
      <>
        <TextareaAutosize
          id={this.state.id}
          testKey={this.testKey}
          name={this.props.model}
          value={value || ''}
          placeholder={this.props.placeholder}
          rows={this.props.minRows || this.props.rows}
          maxRows={this.props.maxRows || this.props.rows}
          onChange={this.onChange}
          onKeyPress={this.props.onKeyPress}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          css={INPUT_STYLES}
          autoFocus={this.props.autoFocus}
        />

        {minLength && (
          <div css={styles.lengthDescription}>
            {size(value)} / {minLength} characters minimum
          </div>
        )}

        {maxLength && (
          <div css={styles.lengthDescription}>
            {size(value)} / {maxLength} characters maximum
          </div>
        )}

        {useQuickText && (
          <Permission featureFlagV2="quick_text">
            <Flex justifyContent="flex-end">
              <QuickTextSelector
                onSelect={(content: string) => {
                  this.changeValue(value ? `${value}\n\n${content?.plain}` : content?.plain)
                }}
              />
            </Flex>
          </Permission>
        )}
      </>
    )
  }

  readOnlyRender = () => <Value testKey={this.props.testKey || `${snakeCase(this.props.label)}_value`} value={this.state.value} />
}

const styles = {
  lengthDescription: {
    color: COLORS.textMuted,
  },
}

Textarea.defaultProps = {
  isEditable: true,
  minRows: 3,
  maxWidth: false,
  validateOn: 'blur-change',
}

export default withFormContext(Textarea)
