import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

export const OrganizationContactsDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            isActive={props.activeId === data.id}
            as={props.mainLinkAs}
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            to={to?.(data)}
            onClick={props.onClick ? () => props.onClick(data) : undefined}
          />
        ),
      },
      {
        width: 100,
        model: 'sex',
        title: 'Sex',
        formatValue: ({ data, value }: any) => startCase(value),
      },
      {
        width: 160,
        model: 'phone_no',
        title: 'Phone',
        type: 'phone',
      },
      {
        width: 240,
        model: 'email',
        title: 'Email',
        type: 'email',
      },
      {
        width: 160,
        model: 'notes',
        title: 'Notes',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Organization Contacts"
      icon="contacts"
      columns={columns}
      filtersConfig={FILTERS.organization_contacts}
      {...props}
    />
  )
}
