import React from 'react'
import dompurify from 'dompurify'

import { useGet, useUpdate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import DateInput from '../../components/Forms/DateInput'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SingleSearchSelector from '../../components/Forms/Selectors/SingleSearchObject/SingleSearchSelector'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import ICDCodeSelector from '../../components/Forms/elements/ICDCodeSelector'

const sanitizer = dompurify.sanitize

const RootTreatmentPlanDiagnosisOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    params,
  } = useOverlay({
    name: 'diagnosis',
    endpoint: '/diagnoses',
    invalidate: 'diagnosis',
    invalidateKeys: ['diagnoses', 'treatment-diagnoses'],
    options: props,
  })

  const { canEdit, planId, clientId, permissionBase } = props

  const [selectedCode, setSelectedCode] = React.useState<any>(null)
  const [selectedCodeDescription, setSelectedCodeDescription] = React.useState<any>(data?.description)

  const { timezone } = useSettings()

  const { data: diagnoses, isLoading: isLoadingDiagnoses } = useGet({
    name: ['treatment_plans', planId, 'treatment-diagnoses'],
    url: `/treatment_plans/${planId}/treatment_diagnoses`,
    options: { enabled: !!planId },
  })

  const { mutateAsync: updatePlan, isLoading: isUnlinking }: any = useUpdate({
    name: ['treatment_plans', planId],
    url: `/treatment_plans/${planId}`,
    invalidate: ['treatment_plans'],
    invalidateKeys: ['treatment-plan-updates', 'treatment-diagnoses', 'treatment-problems', 'treatment-problem'],
  })

  const handleUnlink = async () => {
    const ids = diagnoses?.map(({ diagnosis }: any) => diagnosis.id)
    const newIds = ids?.filter((diagnosisId: any) => diagnosisId !== data.id)

    await updatePlan({ diagnosis_ids: newIds })

    close()
  }

  if (isOverlayLoading || isLoadingDiagnoses) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay showBackdrop={isEditable} onClose={close}>
      <Overlay.Header icon="diagnosis" title={isNew ? 'Add Diagnosis' : 'Diagnosis'} />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              client_id: clientId,
              treatment_plan_ids: [planId],
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <>
                {isNew && (
                  <Alert contrast glyph="info">
                    Try searching for the code or for a keyword in the code description. For example "Opioid Dependence".
                  </Alert>
                )}

                <ICDCodeSelector
                  label="ICD-10 Code"
                  testKey="icd_code_selector"
                  model="code"
                  onUpdate={(model: any) => {
                    setSelectedCode(model?.value)
                    setSelectedCodeDescription(model?.object?.description)
                  }}
                  validations={{
                    presence: {
                      message: 'Please add a code',
                    },
                  }}
                />

                <SmartTextarea useQuickText useDictation label="Description" model="description" value={selectedCodeDescription} />

                <Divider />
              </>

              <Select model="status" label="Status" defaultValue="active">
                <Option label="Active" value="active" />
                <Option label="To Review" value="to_review" />
                <Option label="Inactive" value="inactive" />
                <Option label="Resolved" value="resolved" />
              </Select>

              <Select model="category" label="Type" defaultValue="primary">
                <Option label="Admitting" value="admitting" />
                <Option label="Primary" value="primary" />
                <Option label="Principal" value="principal" />
                <Option label="Secondary" value="secondary" />
              </Select>

              <Flex stretchChildrenX gap="1rem">
                <DateInput
                  defaultToNow
                  label="From Date"
                  model="from"
                  validations={{
                    presence: {
                      message: 'Please enter a date',
                    },
                  }}
                />
                <DateInput label="Until Date" model="until" />
              </Flex>

              <SmartTextarea useQuickText useDictation label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this diagnosis">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {/* {!isNew && data && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} disableLinks={isPortal} />
              </Section>
            </>
          )} */}
        </Form>
      </Overlay.Content>

      {canEdit && (
        <Overlay.Footer>
          {isNew ? (
            <Button
              key="add"
              label="Add & Import Diagnosis"
              color="green"
              glyph="tick_circle"
              type="primary"
              onClick={save}
              isDisabled={isInvalid}
              isLoading={isSaving}
              permission={`${permissionBase}.edit`}
            />
          ) : (
            <Button
              key="unlink"
              fullWidth
              glyph="cross"
              label="Unlink Diagnosis"
              type="default"
              color="red"
              isLoading={isUnlinking}
              onClick={handleUnlink}
              permission={`${permissionBase}.edit`}
            />
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const TreatmentPlanDiagnosisOverlay = withOverlayError(RootTreatmentPlanDiagnosisOverlay)
