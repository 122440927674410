import React from 'react'
import { Link, Route, useLocation } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useStore from '@behavehealth/modules/store'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Page, HelpTagIframe } from '@behavehealth/components'
import { ExportCSVButton } from '@behavehealth/components/Buttons/ExportCSVButton'
import { TodoTemplatesDataTable } from '@behavehealth/components/Templates/tables/TodoTemplatesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import TodoTemplateOverlay from '@behavehealth/components/Templates/overlays/TodoTemplateOverlay'
import TodoTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/TodoTemplatesImportOverlay'

const pageConfig = {
  feature: 'todo_templates',
  title: 'To-Do Templates',
  help: <HelpTagIframe id="settings_todo_templates" />,
}

const TodoTemplates = () => {
  return (
    <div>
      <TodoTemplatesIndex />

      <AnimatedRoutes>
        <Route path="import" element={<TodoTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<TodoTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const TodoTemplatesIndex = () => {
  const { pathname } = useLocation()

  const isBehave = useStore((state) => state?.isBehave)

  const tableProps = useDataTable({
    name: ['todo-templates'],
    endpoint: `/event_templates`,
    params: { event_type: 'task' },
    localStorageKey: 'todo_templates_v1',
  })

  return (
    <Page
      breakpoint={3}
      actions={
        <>
          {isBehave && (
            <Button
              as={Link}
              label="Import Behave Templates"
              type="default"
              glyph="add"
              link={`${pathname}/import`}
              permission="todo_templates.create"
            />
          )}

          <Button
            as={Link}
            label="Add To-Do Template"
            type="primary"
            glyph="add"
            link={`${pathname}/new`}
            permission="todo_templates.create"
          />
        </>
      }
      {...pageConfig}
    >
      <TodoTemplatesDataTable
        headerAfter={<ExportCSVButton url={`/event_templates/csv`} params={{ event_type: 'task' }} />}
        to={(row: any) => row.id}
        {...tableProps}
      />
    </Page>
  )
}

export default withPageError(withMarketing(TodoTemplates, pageConfig))
