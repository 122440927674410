import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AccessListDataTable } from '@behavehealth/constructs/AccessList/AccessListDataTable'
import { Alert, Button, Form, Grid, HelpTagIframe, Page, Radio, RadioGroup } from '@behavehealth/components'

const pageConfig = {
  feature: 'staff_clients_access',
  title: 'Staff Access',
  help: <HelpTagIframe id="staff_access_lists" />,
}

const AccessList: React.FC = () => {
  const { resource_id }: any = useParams()
  const { url }: any = useRouteMatch()

  const form = React.useRef()

  const { data: employee, isLoading: isLoadingEmployee }: any = useGet({
    name: ['employee', resource_id],
    url: `/employees/${resource_id}`,
  })

  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employee', resource_id],
    url: `/employees/${resource_id}`,
    invalidate: ['employees'],
  })

  const tableProps = useDataTable({
    name: ['employee', resource_id, 'access_controls'],
    endpoint: `/employees/${resource_id}/access_controls`,
    localStorageKey: 'employee_access_controls_v2',
  })

  if (!employee) return <Page isLoading {...pageConfig} />

  const hasFullAccess = employee.access_level === 'full'
  const isOwner = employee.position === 'owner'

  return (
    <Page
      actions={
        !hasFullAccess && (
          <Button label="Add Clients" type="primary" glyph="add" link={`${url}/import`} permission="access_controls.actions.import" />
        )
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        {isOwner && <Alert glyph="info">Owners have full access to all clients by default. This setting cannot be changed.</Alert>}

        {!isOwner && (
          <>
            <Form getForm={form} initialModel={{ access_level: employee.access_level }}>
              <RadioGroup
                label="Client Access Level"
                layout="vertical-dense"
                model="access_level"
                isDisabled={isLoadingEmployee}
                onChange={async ({ value }: any) => await updateEmployee({ access_level: value })}
              >
                <Radio label="Full access to All Clients" value="full" />
                <Radio label="Restricted access to selected Clients only" value="restricted" />
              </RadioGroup>
            </Form>

            {!hasFullAccess && (
              <div className="grid gap-4">
                <Alert contrast glyph="warning" type="warning">
                  <b>Please note:</b> when restricting access to clients, staff members who have permissions for features such as Live
                  Reports, Generated Reports, Programs, Bed Management and more will still be able to view all clients. To restrict access
                  to these features, please update the staff member's permissions.
                </Alert>

                <AccessListDataTable {...tableProps} to={(row: any) => `/clients/${row?.resource?.id}`} />
              </div>
            )}
          </>
        )}
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(AccessList, pageConfig))
