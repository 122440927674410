import React from 'react'
import size from 'lodash/size'

import { useDataTable } from '../../../components/DataTable/useDataTable'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import FormSection from '../../../components/Forms/FormSection'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { AuthorityOrganizationCommunicationsDataTable } from '../AuthorityOrganizationCommunicationsDataTable'
import { AuthorityOrganizationCommunicationOverlay } from '../AuthorityOrganizationCommunicationOverlay'

export const CommunicationsStep = ({ organization, setStatus }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const [dataId, setDataId] = React.useState(null)
  const [newDataId, setNewDataId] = React.useState(null)

  const tableProps = useDataTable({
    name: ['organizations', organization.id, 'communications'],
    endpoint: `/organizations/${organization.id}/communications`,
    enabled: !!organization.id,
    localStorageKey: 'authority_organization_communications_v1',
    headers: {
      'X-Request-Level': 'global',
    },
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <AuthorityOrganizationCommunicationsDataTable
            {...tableProps}
            asCard
            activeId={dataId}
            onClick={(item: any) => {
              setDataId(item.id)
            }}
          />

          <CardContent className="flex">
            <Button
              label="Add Communication Log"
              size={200}
              type="primary"
              glyph="add"
              onClick={() => {
                setNewDataId('new')
              }}
            />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        isOpen={!!newDataId}
        onClose={() => {
          setNewDataId(null)
        }}
        overlay={<AuthorityOrganizationCommunicationOverlay dataID="new" referenceID={organization.id} referenceType={organization.type} />}
      />

      <SummonOverlay
        key={`id-${dataId}`}
        isOpen={!!dataId}
        onClose={() => {
          setDataId(null)
        }}
        overlay={<AuthorityOrganizationCommunicationOverlay dataID={dataId} />}
      />
    </>
  )
}
