import React from 'react'
import { parse } from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isUUID } from '@behavehealth/utils/functions'
import { NotionBrowser } from '@behavehealth/constructs/Notion'
import type { NotionPage } from '@behavehealth/constructs/Notion/NotionBrowser'

import Card from '@behavehealth/components/Card'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import State from '@behavehealth/components/State'

const HOME_TAG = 'help_center_home'

export const HelpCenter = () => {
  const location = useLocation()
  const history = useHistory()

  let params: any = parse(location.search)
  const helpTag: any = isUUID(params.guide) ? '' : params.guide

  const helpPages = useSelector((state: any) => state.me?.settings?.help_center)
  const helpTags = useSelector((state: any) => state.me?.settings?.help_center_tags)

  const page = helpPages?.[helpTag]
  const homePage = helpPages?.[HOME_TAG]

  const handlePageChange = (page: NotionPage) => {
    // if (!page?.id) return
    // const tag = helpTags[page.id]
    // const searchParams = new URLSearchParams({ guide: tag || page.id })
    // history.replace({ search: searchParams.toString() })
  }

  return (
    <Page title="Help Center" icon="help_center">
      <Grid>
        {page || homePage ? (
          <NotionBrowser
            homeTitle={homePage?.name}
            homeID={homePage?.page_id}
            pageTitle={page?.name}
            pageID={page?.page_id}
            onPageChange={handlePageChange}
            className="p-0"
            feedbackCategory="help_article"
          />
        ) : (
          <Card>
            <State isEmpty icon="help_center" title="Help Center" emptyDescription="No content to display yet" />
          </Card>
        )}
      </Grid>
    </Page>
  )
}
