import compact from 'lodash/compact'

export const getCanSelectDataForm = (data: any) => {
  if (!data) return { canSelect: false }

  if (data.status === 'signed_off') {
    return {
      canSelect: false,
      message: 'Signed Off records cannot be edited',
    }
  }

  if (data.status === 'closed') {
    return {
      canSelect: false,
      message: 'Closed records cannot be edited',
    }
  }

  return { canSelect: true }
}

export const getBatchActions = ({ isMyProfile, activeTab, tableProps, permissionBase }: any) => {
  return compact([
    isMyProfile &&
      activeTab === 'supervisor' && {
        type: 'sign_off',
        permission: `${permissionBase}.edit`,
        action: async ({ ids, data: signature }: any) => {
          await tableProps.batchUpdateRecords(ids, { [signature.model]: signature.value })
          tableProps.refetch()
        },
      },
    isMyProfile &&
      activeTab === 'supervisor' && {
        type: 'request_updates',
        permission: `${permissionBase}.edit`,
        action: async ({ ids, data: updates }: any) => {
          await tableProps.batchUpdateRecords(ids, {
            status: 'employee_updates_required',
            requested_updates: updates.value,
          })

          tableProps.refetch()
        },
      },
    isMyProfile &&
      activeTab === 'author' && {
        type: 'data_form_send_for_review',
        permission: `${permissionBase}.edit`,
        action: async ({ ids, data: signature }: any) => {
          await tableProps.batchUpdateRecords(ids, { [signature.model]: signature.value, status: 'pending_review' })
          tableProps.refetch()
        },
      },
    {
      type: 'delete',
      permission: `${permissionBase}.delete`,
      action: async ({ ids }: any) => {
        await tableProps.deleteRecords(ids.join(','))
        tableProps.refetch()
      },
    },
  ])
}
