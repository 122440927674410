import React from 'react'

import { COLORS } from '../../theme'

import Checkbox from '../../components/Forms/Checkbox'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import GridTable from '../../components/GridTable'
import Icon from '../../components/Icon'
import Status from '../../components/Status'
import snakeCase from 'lodash/snakeCase'

import {
  GENERAL_FEATURES,
  ERP_FEATURES,
  CONTACTS_FEATURES,
  CHAT_FEATURES,
  CARE_COORDINATION_FEATURES,
  MARKETING_AND_ADMISSIONS_FEATURES,
  RCM_FEATURES,
  INSURANCE_FEATURES,
  NOTES_FEATURES,
  MEDICAL_FEATURES,
  CLINICAL_FEATURES,
  CLIENT_PORTAL_FEATURES,
  SECURITY_FEATURES,
  SIDEBAR_FEATURES,
  SYSTEM_FEATURES,
  BEHAVE_HEALTH_FEATURES,
} from './config'

const HeaderGroup = ({ title }: any) => (
  <div css={styles.header}>
    <span css={styles.headerTitle}>{title}</span>
  </div>
)

const FeatureGroup = ({ features, model = 'feature_flags' }: any) => {
  return features.map((feature: any) => (
    <GridTable.Row css={styles.featureRow}>
      <GridTable.Cell css={styles.featureCell}>
        <Flex gap="1rem" centerY>
          {feature.icon && <Icon icon={feature.icon} />}
          {feature.glyph && <Glyph glyph={feature.glyph} color={feature.color} />}

          {feature.title}
        </Flex>
      </GridTable.Cell>

      <GridTable.Cell>
        {feature.is_dev ? (
          <Status color="green" label="Coming soon" css={{ marginTop: 2 }} />
        ) : (
          <Checkbox
            variant="skinny"
            falseStyle="faded"
            falseIcon="subtract"
            trueIcon="check"
            testKey={`${snakeCase(feature.title)}_checkbox`}
            model={`${model}.${feature.model}`}
          />
        )}
      </GridTable.Cell>
    </GridTable.Row>
  ))
}

export const FeatureFlagsForm = ({ className, model }: any) => {
  return (
    <GridTable templateColumns="350px auto" className={className}>
      <GridTable.Header>
        <GridTable.Cell>Name</GridTable.Cell>
        <GridTable.Cell>Feature Flag</GridTable.Cell>
      </GridTable.Header>

      <HeaderGroup title="General" icon="behave_health" />
      <FeatureGroup features={GENERAL_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="ERP" icon="behave_health" />
      <FeatureGroup features={ERP_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Unified Communications" icon="contacts" />
      <FeatureGroup features={CONTACTS_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Chat" icon="company_chat" />
      <FeatureGroup features={CHAT_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Marketing & Admissions" icon="intake_forms" />
      <FeatureGroup features={MARKETING_AND_ADMISSIONS_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Care Coordination" icon="intake_forms" />
      <FeatureGroup features={CARE_COORDINATION_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="RCM" icon="financials" />
      <FeatureGroup features={RCM_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Insurance" icon="insurance" />
      <FeatureGroup features={INSURANCE_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Notes" icon="housing_notes" />
      <FeatureGroup features={NOTES_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Medical" icon="medical_assessments" />
      <FeatureGroup features={MEDICAL_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Clinical" icon="clinical_assessments" />
      <FeatureGroup features={CLINICAL_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Client Portal" glyph="portal" />
      <FeatureGroup features={CLIENT_PORTAL_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Security" icon="permissions" />
      <FeatureGroup features={SECURITY_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Sidebar" icon="permissions" />
      <FeatureGroup features={SIDEBAR_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="System" icon="permissions" />
      <FeatureGroup features={SYSTEM_FEATURES} model={model} />

      <Divider invisible />

      <HeaderGroup title="Behave Services" icon="permissions" />
      <FeatureGroup features={BEHAVE_HEALTH_FEATURES} model={model} />
    </GridTable>
  )
}

const styles = {
  header: {
    display: 'block',
    width: '100%',
    padding: '0.5rem',
    borderBottom: `1px solid ${COLORS.divider}`,
  },
  headers: {
    margin: '1.5rem 0 0.5rem 0',
    textTransform: 'uppercase',
    opacity: 0.6,
  },
  headerTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    margin: '0 !important',
  },
  featureRow: {
    minHeight: 40,
    maxHeight: 50,
  },
  featureCell: {
    paddingLeft: '1.5rem',
  },
}
