import { Route, useLocation } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '../../components/AnimatedRoutes'

import { BreathalyzerTestOverlay } from '../TestResults/BreathalyzerTestOverlay'
import { BreathalyzerTestWorksheetOverlay } from '../TestResults/BreathalyzerTestWorksheetOverlay'
import { CommunicableDiseaseTestOverlay } from '../TestResults/CommunicableDiseaseTestOverlay'
import { LabTestOverlay } from '../TestResults/LabTestOverlay'
import { UATestOverlay } from '../TestResults/UATestOverlay'
import { UATestWorksheetOverlay } from '../TestResults/UATestWorksheetOverlay'
import { VitalSignsOverlay } from '../TestResults/VitalSignsOverlay'

const BLACKLIST_SEGMENTS = [
  '/invite-to-portal',
  '/admit-client',
  '/discharge-client',
  '/readmit-client',
  '/accept-client',
  '/decline-client',
  '/high-risk-alert',
  '/magic-login',
  '/update-password',
]

export const TestResultsOverlayRoutes = () => {
  const location = useLocation()

  if (location?.pathname && BLACKLIST_SEGMENTS.some((segment) => location.pathname.endsWith(segment))) {
    return null
  }

  return (
    <AnimatedRoutes>
      <Route path={`ua-tests/new-batch`} element={<UATestWorksheetOverlay useV6Router />} />
      <Route path={`ua-tests/:id`} element={<UATestOverlay useV6Router />} />
      <Route path={`breathalyzer-tests/new-batch`} element={<BreathalyzerTestWorksheetOverlay useV6Router />} />
      <Route path={`breathalyzer-tests/:id`} element={<BreathalyzerTestOverlay useV6Router />} />
      <Route path={`vital-signs/:id`} element={<VitalSignsOverlay useV6Router />} />
      <Route path={`communicable-disease-tests/:id`} element={<CommunicableDiseaseTestOverlay useV6Router />} />
      <Route path={`labs/:id`} element={<LabTestOverlay useV6Router />} />
    </AnimatedRoutes>
  )
}
