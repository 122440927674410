import React from 'react'
import { Link } from 'react-router-dom-v5-compat'

import withPermissions from '@behavehealth/hocs/withPermissions'

import { Grid, CardLink, CardTitle, CardSubtitle, Icon } from '@behavehealth/components'

const DashboardCard = withPermissions((props: any) => {
  const { icon, to, title } = props

  return (
    <CardLink as={Link} to={to} showChevron graphic={<Icon icon={icon} />}>
      <CardTitle title={title} />
      <CardSubtitle subtitle="View templates" />
    </CardLink>
  )
})

const DashboardCustomNoteTemplates = () => {
  return (
    <Grid columns="repeat(auto-fit, minmax(280px, 1fr))" alignItems="normal" gap={8}>
      <DashboardCard
        title="Client Templates"
        icon="clients"
        to="/settings/custom-note-templates/clients"
        permission="settings.client_custom_note_templates.view"
        featureFlagV2="client_custom_note_templates"
      />
      <DashboardCard
        title="Staff Templates"
        icon="employees"
        to="/settings/custom-note-templates/staff"
        permission="settings.staff_custom_note_templates.view"
        featureFlagV2="staff_custom_note_templates"
      />
      <DashboardCard
        title="Location Templates"
        icon="properties"
        to="/settings/custom-note-templates/properties"
        permission="settings.property_custom_note_templates.view"
        featureFlagV2="property_custom_note_templates"
      />
      <DashboardCard
        title="Organization Templates"
        icon="organizations"
        to="/settings/custom-note-templates/organizations"
        permission="settings.organization_custom_note_templates.view"
        featureFlagV2="organization_custom_note_templates"
      />
      <DashboardCard
        title="Program Templates"
        icon="programs"
        to="/settings/custom-note-templates/program"
        permission="settings.program_custom_note_templates.view"
        featureFlagV2="program_custom_note_templates"
      />
    </Grid>
  )
}

export default DashboardCustomNoteTemplates
