const STEPS = {
  organization_lead_forms: {
    label: 'Lead Contact Forms',
    isRequiredOptional: true,
  },
  certifications: {
    label: 'Certifications',
    isRequiredOptional: true,
    isBehaveOnly: true,
  },
  inspections: {
    label: 'Inspections',
    isRequiredOptional: true,
    isBehaveOnly: true,
  },
  grievance_forms: {
    label: 'Grievance Forms',
    isRequiredOptional: true,
    isBehaveOnly: true,
  },
  incident_reporting: {
    label: 'Incident Reporting',
    isRequiredOptional: true,
    isBehaveOnly: true,
  },
  studies: {
    label: 'Studies',
    isRequiredOptional: true,
    isBehaveOnly: true,
  },
  surveys: {
    label: 'Surveys',
    isRequiredOptional: true,
    isBehaveOnly: true,
  },
  todos: {
    label: 'Todos',
    isRequiredOptional: true,
  },
  events: {
    label: 'Events',
    isRequiredOptional: true,
  },
  contacts: {
    label: 'Contacts',
    isRequiredOptional: true,
  },
  communications: {
    label: 'Communications',
    isRequiredOptional: true,
  },
  financials: {
    label: 'Financials',
    isRequiredOptional: true,
  },
  custom_forms: {
    label: 'Custom Forms',
    isRequiredOptional: true,
  },
}

export const INTERNAL_WORKFLOW_STEPS = {
  organization_lead_forms: STEPS.organization_lead_forms,
  todos: STEPS.todos,
  events: STEPS.events,
  contacts: STEPS.contacts,
  communications: STEPS.communications,
  financials: STEPS.financials,
  custom_forms: STEPS.custom_forms,
}

export const AUTHORITY_WORKFLOW_STEPS = {
  organization_lead_forms: STEPS.organization_lead_forms,
  certifications: STEPS.certifications,
  inspections: STEPS.inspections,
  grievance_forms: STEPS.grievance_forms,
  incident_reporting: STEPS.incident_reporting,
  studies: STEPS.studies,
  surveys: STEPS.surveys,
  todos: STEPS.todos,
  events: STEPS.events,
  contacts: STEPS.contacts,
  communications: STEPS.communications,
  financials: STEPS.financials,
  custom_forms: STEPS.custom_forms,
}

export const AUTHORITY_WORKFLOWS = [
  {
    id: 'pre_approve',
    name: 'Pre-Approval',
    glyph: 'intake_application',
    glyphColor: 'blue',
    steps: AUTHORITY_WORKFLOW_STEPS,
  },
  {
    id: 'inspect',
    name: 'Review',
    glyph: 'search',
    glyphColor: 'purple',
    steps: AUTHORITY_WORKFLOW_STEPS,
  },
  {
    id: 'approve',
    name: 'Approval',
    glyph: 'tick_circle',
    glyphColor: 'green',
    steps: AUTHORITY_WORKFLOW_STEPS,
  },
  {
    id: 're_approve',
    name: 'Re-Approval',
    glyph: 'reset',
    glyphColor: 'green',
    steps: AUTHORITY_WORKFLOW_STEPS,
  },
  {
    id: 'deny',
    name: 'Deny',
    glyph: 'circle_error',
    glyphColor: 'red',
    steps: AUTHORITY_WORKFLOW_STEPS,
  },
  {
    id: 'ban',
    name: 'Ban',
    glyph: 'decline',
    glyphColor: 'red',
    steps: AUTHORITY_WORKFLOW_STEPS,
  },
]

export const INTERNAL_WORKFLOWS = [
  {
    id: '__workflow_step__',
    name: 'Workflow Step',
    glyph: 'general_info',
    steps: INTERNAL_WORKFLOW_STEPS,
  },
]

export const WORKFLOWS_BY_RELATIONSHIP = {
  authority: AUTHORITY_WORKFLOWS,
  internal: INTERNAL_WORKFLOWS,
}
