import React from 'react'
import pluralize from 'pluralize'
import kebabCase from 'lodash/kebabCase'

import { amount, usDate, niceAmount, daysToWords } from '../../utils/functions'
import { DEFAULT_FILTERS } from '../Filters/constants'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { PAYMENT_METHODS } from '../../utils/constants'
import { useSettings } from '../../hooks/useSettings'

import ChargeStatus, { STATUSES } from '../../components/Statuses/ChargeStatus'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Status from '../../components/Status'

export const AuthorityTransactionsDataTable = (props: any) => {
  const { to, isSelectable, ...rest } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return (
            <MainCell
              id={data.id}
              to={isSelectable ? to?.({ id: data.id, category: kebabCase(pluralize(data.category)) }) : undefined}
              value={value}
            />
          )
        },
      },
      {
        title: 'Amount',
        model: 'total_amount',
        hoverExpand: false,
        formatValue: ({ data, value }: any) => {
          let valueColor = 'red'
          let sign = '+'

          if (data.category === 'payment') {
            valueColor = 'green'
          } else if (data.category === 'refund') {
            valueColor = 'gray'
            sign = '-'
          } else if (data.category === 'write_off') {
            valueColor = 'green'
          } else if (data.category === 'credit') {
            valueColor = 'orange'
          } else sign = '-'

          const hasCustomCollection = data.is_custom_collection

          return (
            <Flex gap="0.5rem" nowrap justifyContent="flex-end">
              <Status color={valueColor} label={`${sign}$${amount(value)}`} />

              {hasCustomCollection && (
                <Status
                  color="green"
                  label={`${sign}$${amount(data.collection_amount)}`}
                  infoTooltip={`Collecting ${niceAmount(data.collection_amount)} when Charged`}
                />
              )}
            </Flex>
          )
        },
      },
      {
        title: 'Transaction Type',
        model: 'category',
        type: 'title',
        width: 160,
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <ChargeStatus status={value} />,
      },
      {
        title: 'Transaction Date',
        model: 'transactioned_at',
        width: 200,
        formatValue: ({ value }: any) => {
          return (
            <div>
              <span>{usDate(value, timezone)} </span>
              <span className="opacity-60 text-[0.9em]">({daysToWords(value, timezone)})</span>
            </div>
          )
        },
      },
      {
        title: 'Payed With',
        model: 'payed_with',
        formatValue: ({ value }: any) => {
          if (!PAYMENT_METHODS?.[value]) return null

          return (
            <>
              <Glyph glyph={PAYMENT_METHODS[value]?.glyph} /> {PAYMENT_METHODS[value]?.label}
            </>
          )
        },
      },
      {
        title: 'Payment Method',
        model: 'payment_method',
        formatValue: ({ value }: any) => value?.name || null,
      },
      {
        title: 'Notes',
        model: 'notes',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to, isSelectable, timezone],
  )

  const filtersConfig = React.useMemo(() => {
    if (!props.hiddenColumnIds) return FILTERS_CONFIG

    const res: any = {}

    for (const key in FILTERS_CONFIG) {
      if (props.hiddenColumnIds?.includes?.(key)) continue
      res[key] = FILTERS_CONFIG[key]
    }

    return res
  }, [props.hiddenColumnIds])

  return (
    <DataTable
      asCard
      title="Transactions"
      testKey="transactions_data_table"
      icon="financials"
      columns={columns}
      filtersConfig={filtersConfig}
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  name: { label: 'Name', type: 'string' },
  total_amount: { label: 'Amount', type: 'number' },
  category: {
    label: 'Transaction Type',
    type: 'multi_select',
    options: [
      { value: 'charge', label: 'Charge' },
      { value: 'payment', label: 'Payment' },
      { value: 'credit', label: 'Credit' },
      { value: 'refund', label: 'Refund' },
      { value: 'write_off', label: 'Write-Off' },
    ],
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: Object.keys(STATUSES).map((key) => ({
      value: key,
      label: STATUSES[key].label,
    })),
  },
  transactioned_at: { label: 'Transaction Date', type: 'date', glyph: 'date' },
  payed_with: {
    label: 'Payed With',
    type: 'multi_select',
    options: Object.keys(PAYMENT_METHODS).map((key) => ({
      value: key,
      label: PAYMENT_METHODS[key].label,
      glyph: PAYMENT_METHODS[key].glyph,
    })),
  },
  // payment_method: {},
  notes: { label: 'Notes', type: 'string' },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
