import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Markup from '../../components/Table/Cells/Markup'
import Status from '../../components/Status'

import { ShiftNoteStatus } from './ShiftNoteStatus'

export const ShiftNotesDataTable = (props: any) => {
  const { to, mainLinkAs } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Note',
        id: 'description',
        model: 'description',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={mainLinkAs} id={data.id} value={<Markup value={value} />} to={to?.(data)} />,
      },
      {
        width: 160,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <ShiftNoteStatus small status={value} />
        },
      },
      {
        width: 160,
        id: 'category',
        model: 'category',
        title: 'Category',
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          return <Status small label={value.replace('_', ' ')} color={value === 'incident_report' ? 'red' : 'blue'} />
        },
      },
      {
        width: 200,
        id: 'dated_at',
        model: 'dated_at',
        title: 'Dated At',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Shift Notes"
      icon="housing_notes"
      testKey="shift_notes_data_table"
      columns={columns}
      filtersConfig={FILTERS.shift_notes}
      {...props}
    />
  )
}
