import React from 'react'
import { useParams } from 'react-router-dom'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { SLUG_RESOURCE_TYPES } from '../../utils/constants'
import { titleCase } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SignaturePad from '../../components/Forms/SignaturePad'
import Textarea from '../../components/Forms/Textarea'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

const RootMedicationIncidentLogOverlay = (props: any) => {
  const {
    cancel,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    navigate,
    location,
  } = useOverlay({
    name: 'medication_incident_logs',
    endpoint: '/medication_incident_logs',
    invalidate: 'medication_incident_logs',
    options: props,
    onAfterClose: (args) => {
      // open the newly created record
      if (!args.isNew || !args?.data?.id || !!props?.onClose) return

      if (args?.location?.pathname?.endsWith('/incident-logs/new')) {
        args.navigate(args?.location?.pathname.replace('/incident-logs/new', `/incident-logs/${args.data.id}`))
      }
    },
  })

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const url = location?.parent?.url || location.pathname.substring(0, location.pathname.lastIndexOf('/incident-logs/'))

    navigate(url)
  }

  const { resource, resource_id }: any = useParams()
  const { user, isPortal, timezone, tenant } = useSettings()

  const isTrialing = tenant?.plan_status === 'trialing'
  const isResident = SLUG_RESOURCE_TYPES?.[resource] === 'resident'

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
    options: { enabled: !!resource_id && isResident },
  })

  if (isOverlayLoading) {
    return <OverlayLoader position="right" showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} />
  }

  return (
    <Overlay showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} onClose={close}>
      <Overlay.Header title="Medication Incident Log" icon="medication_incidents" />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/medication_incident_logs/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && client && { client_id: client.id, client: client }}
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection layout="vertical">
              <OverlaySelector
                model="client"
                label="Client"
                type="clients.admissions_and_current"
                icon="clients"
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />

              <Input
                label="Title"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a short title',
                  },
                }}
              />

              <Textarea useQuickText model="notes" label="Notes" />

              <ObjectSelector
                model="med"
                label="Medication"
                type="client.medications"
                dependent="client_id"
                icon="medications"
                showDescription={false}
                selectTitle={(data) => data.name}
                selectDescription={(data) => titleCase(data.status)}
                disableLink={isPortal}
                // validations={{
                //   presence: {
                //     message: 'Please select a medication',
                //   },
                // }}
              />

              <RadioGroup
                model="category"
                label="Log Category"
                layout="horizontal-dense"
                defaultValue="error_log"
                validations={{
                  presence: {
                    message: 'Please select a category',
                  },
                }}
              >
                <Radio label="Error" value="error_log" />
                <Radio label="Issue" value="issue_log" />
              </RadioGroup>

              {!isNew && data?.author && (
                <OverlaySelector
                  model="author"
                  label="Logged By"
                  type="employees.active"
                  icon="employees"
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => titleCase(data.position)}
                  disableLink={isPortal}
                />
              )}

              <DateTimeInput
                defaultToNow
                model="logged_at"
                label="Logged At"
                validations={{
                  presence: {
                    message: 'Please enter the date of incident',
                  },
                }}
              />

              <FormFutureDateWarning dateLabel="Logged At date" model="logged_at" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this log">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Signatures">
            <Grid gap={16}>
              <SignaturePad
                allowPin
                person={data?.signed_by}
                label="Staff Signature"
                model="employee_signature"
                signedAtModel="signed_at"
                showAccept={false}
              />
            </Grid>
          </Section>

          {/* {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} disableLinks={isPortal} />
              </Section>
            </>
          )} */}
        </Form>
      </Overlay.Content>

      {!isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="10 1 auto"
                permission={isNew ? 'medication_incident_logs.create' : 'medication_incident_logs.edit'}
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="10 1 auto"
                permission="medication_incident_logs.edit"
              />

              <DeleteDialog
                title="Delete Medication Incident Log?"
                message="Are you sure you want to delete this medication incident log? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="medication_incident_logs.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const MedicationIncidentLogOverlay = withOverlayError(RootMedicationIncidentLogOverlay)
