import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { FILTERS } from '../Filters/config'

export const AgreementSigneesDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'signer.name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={to?.(data)} value={value} />
        },
      },
      {
        title: 'Signee Type',
        id: 'signer_type',
        model: 'signer.type',
        type: 'title',
      },
      {
        title: 'Assigned Agreements #',
        id: 'contracts_count',
        model: 'contracts',
        formatValue: ({ value }: any) => {
          return countWord('Agreements', size(value))
        },
      },
    ],
    [to],
  )

  return (
    <DataTable asCard title="Signees" icon="legal_agreement_alt" columns={columns} filtersConfig={FILTERS.agreement_signees} {...rest} />
  )
}
