import React from 'react'
import { useParams } from 'react-router-dom'

import { titleCase } from '../../utils/functions'
import { SLUG_RESOURCE_TYPES } from '../../utils/constants'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import SignaturePad from '../../components/Forms/SignaturePad'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

const RootMedicationDestructionLogOverlay = (props: any) => {
  const {
    cancel,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    location,
    navigate,
  } = useOverlay({
    name: 'medication-destruction-log',
    endpoint: '/medication_destruction_logs',
    invalidate: 'medication-destruction-logs',
    options: props,
    onAfterClose: (args) => {
      // open the newly created record
      if (!args.isNew || !args?.data?.id || !!props?.onClose) return

      if (args?.location?.pathname?.endsWith('/destruction-logs/new')) {
        args.navigate(args?.location?.pathname.replace('/destruction-logs/new', `/destruction-logs/${args.data.id}`))
      }
    },
  })

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const url = location?.parent?.url || location.pathname.substring(0, location.pathname.lastIndexOf('/destruction-logs/'))

    navigate(url)
  }

  const { resource, resource_id }: any = useParams()
  const { isPortal, timezone, tenant } = useSettings()

  const isTrialing = tenant?.plan_status === 'trialing'
  const isResident = SLUG_RESOURCE_TYPES?.[resource] === 'resident'

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
    options: { enabled: !!resource_id && isResident },
  })

  if (isOverlayLoading) {
    return <OverlayLoader position="right" showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} />
  }

  return (
    <Overlay showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} onClose={close}>
      <Overlay.Header title="Medication Destruction Log" icon="medication_inventory" />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/medication_destruction_logs/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && client && { client_id: client.id, client: client }}
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection layout="vertical">
              <OverlaySelector
                model="client"
                label="Client"
                type="clients.admissions_and_current"
                icon="clients"
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />

              <Flex gap={16} stretchChildrenX>
                <ObjectSelector
                  model="med"
                  label="Medication"
                  type="client.medications"
                  dependent="client_id"
                  icon="medications"
                  showDescription={false}
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => titleCase(data.status)}
                  disableLink={isPortal}
                  validations={{
                    presence: {
                      message: 'Please select a medication',
                    },
                  }}
                />

                <Input
                  model="quantity"
                  label="Quantity"
                  validations={{
                    presence: {
                      message: 'Please enter a quantity',
                    },
                  }}
                />
              </Flex>

              <OverlaySelector
                model="employee"
                label="Destroyed By"
                type="employees.active"
                icon="employees"
                selectTitle={(data) => data.name}
                selectDescription={(data) => titleCase(data.position)}
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select an Employee',
                  },
                }}
              />

              <DateTimeInput
                defaultToNow
                model="destroyed_at"
                label="Destroyed At"
                validations={{
                  presence: {
                    message: 'Please enter the date of destruction',
                  },
                }}
              />

              <FormFutureDateWarning dateLabel="Destroyed At date" model="destroyed_at" />

              <Input model="prescription_no" label="Prescription Number" />
              <Input model="pharmacy_name" label="Pharmacy Name" />

              <Textarea useQuickText model="notes" label="Notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this log">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Signatures">
            <Grid gap={16}>
              <SignaturePad
                allowPin
                person={data?.signed_by}
                label="Staff Signature"
                model="employee_signature"
                signedAtModel="signed_at"
                showAccept={false}
              />
            </Grid>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {!isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                testKey="save_destruction_log_button"
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="10 1 auto"
                permission={isNew ? 'medication_destruction_logs.create' : 'medication_destruction_logs.edit'}
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                testKey="edit_destruction_log_button"
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="10 1 auto"
                permission="medication_destruction_logs.edit"
              />

              <DeleteDialog
                title="Delete Medication Destruction Log?"
                message="Are you sure you want to delete this medication destruction log? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  testKey="delete_destruction_log_button"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="medication_destruction_logs.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const MedicationDestructionLogOverlay = withOverlayError(RootMedicationDestructionLogOverlay)
