import React from 'react'
import { NavLink, useParams } from 'react-router-dom-v5-compat'

import { Button, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { OrganizationCommunicationsDataTable } from '@behavehealth/constructs/Communications/OrganizationCommunicationsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Communications: React.FC = () => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['organization', resource_id, 'communications'],
    endpoint: `/organizations/${resource_id}/communications`,
    updateDeleteEndpoint: '/communications',
    enabled: !!resource_id,
    localStorageKey: 'organization_communications_v1',
  })

  return (
    <Page
      title="Communications"
      feature="communications"
      help={<HelpTagIframe id="organization_contacts_and_communications" />}
      actions={
        <>
          <Button as={NavLink} label="Add Communication Log" type="primary" glyph="add" link="new" permission="communications.create" />
        </>
      }
    >
      <Grid gap="1rem" columns="100%">
        <OrganizationCommunicationsDataTable
          to={({ id }) => id}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/organizations-communications',
            },
          ]}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export default Communications
