import React from 'react'
import { motion } from 'framer-motion'
import { Routes, Route, NavLink, Navigate, useLocation, useMatch } from 'react-router-dom-v5-compat'
import clsx from 'clsx'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { isDefined, titleCase } from '@behavehealth/utils/functions'
import { PlainSwitch } from '@behavehealth/components/Forms/Switch'
import { useFormField } from '@behavehealth/components/Forms/hooks/useFormField'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { usePermissions } from '@behavehealth/hooks/usePermissions'
import { withFormContext } from '@behavehealth/components/Forms/context'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import Flex from '@behavehealth/components/Flex'
import Form from '@behavehealth/components/Forms/Form'
import Glyph from '@behavehealth/components/Glyph'
import Icon from '@behavehealth/components/Icon'
import MiniRichTextEditor from '@behavehealth/components/Forms/MiniRichTextEditor'
import Page from '@behavehealth/components/Page'
import PageSection from '@behavehealth/components/PageSection/PageSection'
import Permission from '@behavehealth/components/Permission'
import State from '@behavehealth/components/State'
import Status from '@behavehealth/components/Status'
import Tabs from '@behavehealth/components/Tabs'
import TreeItem from '@behavehealth/components/TreeItem'

import { AUTHORITY_WORKFLOWS } from '@behavehealth/constructs/OrganizationWorkflows/constants'
import { OrganizationWorkflowBuilderOverlay } from '@behavehealth/constructs/OrganizationWorkflows/OrganizationWorkflowBuilderOverlay'
import { useSettings } from '@behavehealth/hooks/useSettings'

const animationProps = {
  type: 'spring',
  duration: 0.35,
  bounce: 0,
}

const RELATIONSHIPS_BY_SLUG: any = {
  internal: 'internal',
  providers: 'provider',
  vendors: 'vendor',
  authorities: 'authority',
}

const OrganizationWorkflows = () => {
  const location = useLocation()
  const match: any = useMatch('/settings/organization-workflows/:relationship/*')

  const relationship = RELATIONSHIPS_BY_SLUG[match?.params?.relationship]

  return (
    <>
      <Page
        breakpoint={3}
        title="Organization Workflows"
        feature="organization_workflows"
        // actions={
        //   relationship === 'authority' && (
        //     <Button
        //       as={NavLink}
        //       label={`Add New ${titleCase(relationship)} Workflow`}
        //       glyph="add"
        //       type="primary"
        //       link={`${location.pathname}/new`}
        //       state={{ data: { relationship } }}
        //     />
        //   )
        // }
      >
        <Tabs className="-mt-4 mb-4">
          <Tabs.List>
            <Tabs.Item as={NavLink} label="Internal" to="internal" />
            <Tabs.Item as={NavLink} label="Providers" to="providers" />
            <Tabs.Item as={NavLink} label="Vendors" to="vendors" />

            <Permission featureFlagV2="authority_organizations">
              <Tabs.Item as={NavLink} label="Authorities" to="authorities" />
            </Permission>
          </Tabs.List>
        </Tabs>

        <Routes>
          <Route index element={<Navigate to="internal" replace />} />

          <Route path="internal/*" element={<ComingSoon relationship="internal" />} />
          <Route path="providers/*" element={<ComingSoon relationship="providers" />} />
          <Route path="vendors/*" element={<ComingSoon relationship="vendors" />} />
          <Route
            path="authorities/*"
            element={<OrganizationWorkflowsList workflowsConfig={AUTHORITY_WORKFLOWS} relationship="authority" />}
          />
        </Routes>
      </Page>

      <AnimatedRoutes>
        <Route path=":slug/:id" element={<OrganizationWorkflowBuilderOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const OrganizationWorkflowsList = ({ workflowsConfig, relationship }: any) => {
  const { data: workflows, isLoading } = useGet({
    name: ['organization_workflows'],
    url: `/organization_workflows`,
    params: { category: relationship },
  })

  if (!workflowsConfig) return null

  const isEmpty = size(workflows) === 0

  if (isEmpty || isLoading) {
    return (
      <Card>
        <State
          isEmpty={isEmpty}
          isLoading={isLoading}
          icon="organization_workflows"
          title="No Organization Workflows"
          minHeight={150}
          emptyDescription="Create a new organization workflow to get started."
        />
      </Card>
    )
  }

  return (
    <div className="grid gap-3">
      {workflows?.map?.((workflow: any) => (
        <OrganizationWorkflow key={workflow.id} workflow={workflow} workflowsConfig={workflowsConfig} />
      ))}
    </div>
  )
}

const OrganizationWorkflow = ({ workflowsConfig, workflow }: any) => {
  const [isEditable, setIsEditable] = React.useState(false)

  const form = React.useRef()

  const { mutateAsync, isLoading: isSaving } = useUpdate({
    name: ['update_workflow'],
    url: `/organization_workflows/${workflow.id}`,
    invalidate: 'organization_workflows',
  })

  const handleSave = async () => {
    const data = form.current?.getFormValue?.()

    await mutateAsync(data)

    setIsEditable(false)
  }

  if (!workflowsConfig || !workflow) return null

  return (
    <Card className="!overflow-visible">
      {workflow?.organization_track && (
        <PageSection.Header
          after={
            workflow.organization_track?.id && (
              <Button
                label="Edit Track →"
                glyph="edit"
                size={100}
                link={`/settings/organization-tracks/${workflow.organization_track.id}`}
                permission="organization_tracks.edit"
              />
            )
          }
          className="px-3 py-2 border-b border-0 border-solid border-divider !m-0 sticky top-[3.5rem] bg-white z-[10] rounded-md"
        >
          <div className="flex items-center flex-nowrap">
            <Icon icon="organization_tracks" size={16} className="mr-1.5" />
            <div className="text-text-muted opacity-80 text-[0.85rem] uppercase font-[700] tracking-[0.5px]">Organization Track:</div>
            <div className="text-1rem font-[700] ml-1">{workflow.organization_track?.name}</div>
            {workflow.organization_track?.is_default && <Status small label="Default" color="blue" className="ml-1.5" />}
          </div>
        </PageSection.Header>
      )}

      <Form getForm={form} isEditable={isEditable} initialModel={workflow} key={`updated-${workflow?.updated_at}`}>
        {workflowsConfig.map((workflow) => (
          <TreeItem
            isOpen={isEditable}
            title={
              <>
                <Glyph glyph={workflow.glyph} size={16} className="mr-1.5" />
                <span>{workflow.name} Workflow</span>
              </>
            }
          >
            {Object.keys(workflow.steps).map((key) => {
              const stepConfig = workflow.steps[key] || {}

              if (stepConfig.isMandatory) return null

              return <WorkflowStep key={key} step={key} stepConfig={stepConfig} model={`options.${workflow.id}`} />
            })}
          </TreeItem>
        ))}
      </Form>

      <div className="px-3 py-2 border-t border-0 border-solid border-divider !m-0 sticky bottom-0 bg-white z-[10] rounded-md">
        <Flex gap="0.75rem">
          {isEditable ? (
            <>
              <Button
                label="Save Workflow"
                glyph="tick_circle"
                type="primary"
                color="green"
                size={200}
                display="inline-flex"
                className="min-w-[100px]"
                onClick={handleSave}
                isLoading={isSaving}
                permission="settings.organization_workflows.edit"
              />

              <Button
                label="Cancel"
                size={200}
                display="inline-flex"
                className="min-w-[100px]"
                onClick={() => setIsEditable(false)}
                isDisabled={isSaving}
              />
            </>
          ) : (
            <Button
              label="Edit Workflow"
              glyph="edit"
              type="primary"
              size={200}
              display="inline-flex"
              className="min-w-[100px]"
              onClick={() => setIsEditable(true)}
              permission="settings.organization_workflows.edit"
            />
          )}
        </Flex>
      </div>
    </Card>
  )
}

const RootWorkflowStep = (props: any) => {
  const { model, form, isEditable, step, stepConfig = {} } = props

  const { label, isBehaveOnly, isRequired: isAlwaysRequired, isRequiredOptional, featureFlag, featureFlagV2 } = stepConfig

  const { isBehave } = useSettings()
  const { allowed: hasFeatureFlag } = usePermissions({ featureFlag: featureFlag || featureFlagV2 })

  const visibilityModel = `${model}.${step}`
  const requiredModel = `${model}_required_steps.${step}`
  const instructionsModel = `${model}_instructions.${step}`

  const [visibility, setVisibility] = React.useState(true)
  const [required, setRequired] = React.useState(!!isAlwaysRequired)
  const [instructions, setInstructions] = React.useState('')

  const { formActions: visibilityActions } = useFormField({
    model: visibilityModel,
    form,
    afterReset: () => {
      let initialVisibility = form?.getInitialInputFieldValue(visibilityModel)

      setVisibility(!!initialVisibility)
    },
  })

  const { formActions: requiredActions } = useFormField({
    model: requiredModel,
    form,
    afterReset: () => {
      let initialRequired = form?.getInitialInputFieldValue(requiredModel)

      setRequired(!!initialRequired)
    },
  })

  const { formActions: instructionsActions } = useFormField({
    model: instructionsModel,
    form,
    afterReset: () => {
      let initialInstructions = form?.getInitialInputFieldValue(instructionsModel)

      setInstructions(initialInstructions)
    },
  })

  // ON MOUNT
  React.useEffect(() => {
    let initialVisibility = form?.getInitialInputFieldValue(visibilityModel)
    let initialRequired = form?.getInitialInputFieldValue(requiredModel)
    let initialInstructions = form?.getInitialInputFieldValue(instructionsModel)

    if (isDefined(initialVisibility)) {
      setVisibility(!!initialVisibility)
    }

    if (isDefined(initialRequired)) {
      setRequired(!!initialRequired)
    }

    if (isDefined(initialInstructions)) {
      setInstructions(initialInstructions)
    }
  }, [])

  // UPDATE FORM
  React.useEffect(() => {
    visibilityActions.setValue(visibility)
  }, [visibility])

  React.useEffect(() => {
    requiredActions.setValue(required)
  }, [required])

  React.useEffect(() => {
    instructionsActions.setValue(instructions)
  }, [instructions])

  const showInstructions = isEditable ? visibility : visibility && instructions

  if (!hasFeatureFlag) return null

  if (isBehaveOnly && !isBehave) return null

  return (
    <div className="max-w-[520px] border-b border-0 border-solid border-divider">
      <div className="flex items-center py-3">
        <div className="flex items-center flex-nowrap mr-auto">
          <h4 className={clsx('text-[1.1rem]', !visibility && 'opacity-50')}>{label}</h4>
          {isBehaveOnly && <Status small label="Dev" color="gray" className="ml-2" />}
        </div>

        {isAlwaysRequired ? (
          <div className="flex items-center flex-nowrap">
            <Glyph glyph="check" size={16} />
            <div className="font-[500] text-[0.95rem] ml-1.5">Always Visible & Required</div>
          </div>
        ) : (
          <>
            {isEditable && !isRequiredOptional && (
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="info" size={16} />
                <div className="font-[400] italic text-[0.95rem] ml-1.5">Required Option Coming Soon</div>
              </div>
            )}

            {isRequiredOptional && (
              <div className={clsx('flex items-center flex-nowrap', !visibility && 'hidden')}>
                {!isEditable && <Glyph glyph={required ? 'check' : 'cross'} size={16} />}

                {isEditable && (
                  <PlainSwitch
                    size={100}
                    isChecked={required && visibility}
                    onCheckedChange={(value) => {
                      setRequired(value && visibility)
                    }}
                    isDisabled={!visibility}
                  />
                )}

                <div className="font-[500] text-[0.95rem] ml-1.5">Required</div>
              </div>
            )}

            <div className="flex items-center flex-nowrap ml-4">
              {!isEditable && <Glyph glyph={visibility ? 'check' : 'cross'} size={16} />}
              {isEditable && <PlainSwitch size={100} isChecked={visibility} onCheckedChange={setVisibility} />}
              <div className="font-[500] text-[0.95rem] ml-1.5">Visible</div>
            </div>
          </>
        )}
      </div>

      {(isEditable || showInstructions) && (
        <motion.div
          initial={false}
          className={clsx(!showInstructions && 'overflow-hidden')}
          animate={isEditable && { height: showInstructions ? 'auto' : 0, opacity: showInstructions ? 1 : 0 }}
          transition={{
            height: animationProps,
            opacity: animationProps,
          }}
        >
          <TreeItem
            title={<span className="text-[0.95rem] text-text-muted">Instructions</span>}
            withHover={false}
            headerClassName="!pt-0 !pb-3"
          >
            <div className={clsx(isEditable ? 'mb-4' : 'mb-2')}>
              <MiniRichTextEditor
                isCompact
                withHover={false}
                actions={EDITOR_ACTIONS}
                value={instructions}
                onUpdate={({ value }: any) => {
                  setInstructions(value)
                }}
              />
            </div>
          </TreeItem>
        </motion.div>
      )}
    </div>
  )
}

const ComingSoon = ({ relationship }: any) => {
  if (!relationship) return null

  return (
    <Card className="pb-4">
      <State
        isEmpty
        icon="organization_workflows"
        title={`${relationship} Organization Workflows`}
        minHeight={150}
        emptyDescription={
          <div className="flex justify-center">
            <Status color="orange" label="Coming Soon" />
          </div>
        }
      />
    </Card>
  )
}

const EDITOR_ACTIONS = ['inline', 'color']

const WorkflowStep = withFormContext(RootWorkflowStep)

export default withPageError(OrganizationWorkflows)
