import React from 'react'
import size from 'lodash/size'

import { PaymentMethod } from '../../BillingElements/PaymentMethodElements'
import { SectionCard } from '../../../components/SectionCard'
import { useSettings } from '../../../hooks/useSettings'

import AddressInput from '../../../components/Forms/AddressInput'
import Checkbox from '../../../components/Forms/Checkbox'
import DateInput from '../../../components/Forms/DateInput'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'
import Label from '../../../components/Label'
import MiniRichTextEditor from '../../../components/Forms/MiniRichTextEditor'
import Option from '../../../components/Forms/Option'
import PageSection from '../../../components/PageSection/PageSection'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Select from '../../../components/Forms/Select'
import Textarea from '../../../components/Forms/Textarea'
import TimezoneInput from '../../../components/Forms/TimezoneInput'

import { STATUSES } from '../QuoteStatus'

export const QuoteDetails = (props: any) => {
  const { isNew, data, isEditable, formData, onValidationUpdate, onUpdate, facility, setFacility } = props

  const form = React.useRef(null)

  const { isEHRApp, tenant } = useSettings()

  const [billedToUpdated, setBilledToUpdated] = React.useState(0)

  return (
    <Form
      isCompact
      getForm={form}
      isEditable={isEditable}
      initialModel={{
        ...formData,
        ...(isNew && isEHRApp && { pass_fee_to_payer: tenant?.pass_behave_internal_billing_fee_to_payer }),
      }}
      onValidationUpdate={onValidationUpdate}
      timezone={formData.timezone || props.timezone}
      onUpdate={onUpdate}
    >
      <FormSection maxWidth="100%">
        <PageSection className="p-5">
          <PageSection.Header>
            <PageSection.Title title="Quote Details" className="!text-[1.65rem]" />
          </PageSection.Header>

          <div className="grid gap-4">
            <div className="grid grid-cols-1 mq1200:grid-cols-2 gap-5">
              <SectionCard title="Quote Details" testKey="quote_details_section" className="!p-3">
                <div className="grid gap-3">
                  <Input
                    label="Quote Name"
                    model="name"
                    validations={{
                      presence: {
                        message: 'Please enter a quote name',
                      },
                    }}
                  />

                  <TimezoneInput
                    allowEmpty
                    label="Timezone"
                    model="timezone"
                    value={formData?.timezone}
                    onUpdate={({ value }) => {
                      onUpdate({ timezone: value })
                    }}
                    validations={{
                      presence: {
                        message: 'Please select a timezone',
                      },
                    }}
                  />

                  <Flex gap="1.25rem">
                    <Select allowEmpty label="Status" model="status" defaultValue={isNew ? 'draft' : undefined}>
                      {Object.entries(STATUSES).map(([key, value]) => (
                        <Option key={key} label={value.label} value={key} disabled={value.isDisabled} />
                      ))}
                    </Select>
                    <DateInput label="Sent Date" model="sent_at" />
                    <DateInput label="Expiry Date" model="expires_at" />
                  </Flex>

                  <Flex gap="1.25rem" alignItems="flex-end">
                    <Checkbox
                      isEditable={false}
                      label="Account Set Up From Quote"
                      model="is_processed"
                      trueIcon="check"
                      falseIcon="empty_checkbox"
                      falseStyle="faded"
                    />

                    <DateInput isEditable={false} label="Account Set Up At" model="processed_at" />
                  </Flex>

                  <MiniRichTextEditor label="Internal Description" model="internal_description" />

                  <Checkbox
                    label="Enable Price Estimate Calculator"
                    model="use_estimator"
                    trueIcon="check"
                    falseIcon="empty_checkbox"
                    falseStyle="faded"
                  />
                </div>
              </SectionCard>

              <SectionCard title="Invoice Details" testKey="invoice_details_section" className="!p-3">
                <div className="grid gap-3">
                  <Textarea label="Description" minRows={2} model="description" />
                  <Textarea label="Instructions" minRows={2} model="instructions" />
                  <Textarea label="Notes" minRows={2} model="notes" />

                  {!isNew && (
                    <div>
                      <Label isCompact label="Payment Method" />
                      {data?.global_payment_method ? <PaymentMethod paymentMethod={data?.global_payment_method} /> : <div>–</div>}
                    </div>
                  )}

                  <RadioGroup label="Payment Processing Fee" model="pass_fee_to_payer" layout="vertical-dense" defaultValue={false}>
                    <Radio label="Behave Health pays the fee" value={false} />
                    <Radio label="Quote Payer pays the fee" value={true} />
                  </RadioGroup>
                </div>
              </SectionCard>
            </div>

            <div className="grid grid-cols-1 mq1200:grid-cols-2 gap-5">
              <SectionCard title="Billed By" testKey="billed_by_section" className="!p-3">
                <div className="grid gap-3">
                  <Input label="Company" model="billed_by_company_name" />
                  <Input label="Name" model="billed_by_name" />
                  <Input label="Email" model="billed_by_email" />
                  <Input label="Phone" model="billed_by_phone_no" />
                  <AddressInput actionLabel="Find Address" model="billed_by_address" />
                </div>
              </SectionCard>

              <SectionCard title="Billed To" testKey="billed_to_section" className="!p-3">
                <div className="grid gap-3 mt-3" key={`updated-${billedToUpdated}-${data?.updated_at}`}>
                  <Input
                    label="Company"
                    model="billed_to_company_name"
                    validations={{
                      presence: {
                        message: 'Please enter a company or person name',
                      },
                    }}
                    description="Use the company name or the person's name"
                  />
                  <Input label="Name" model="billed_to_name" />
                  <Input
                    label="Email"
                    model="billed_to_email"
                    validations={{
                      presence: {
                        message: 'Please enter an email address',
                      },
                    }}
                  />
                  <Input label="Phone" model="billed_to_phone_no" />
                  <AddressInput
                    actionLabel="Find Address"
                    model="billed_to_address"
                    initialValue={
                      size(formData?.billed_to_address)
                        ? formData.billed_to_address
                        : size(facility?.billing_address)
                        ? facility?.billing_address
                        : facility?.mailing_address
                    }
                  />
                </div>
              </SectionCard>
            </div>
          </div>
        </PageSection>
      </FormSection>
    </Form>
  )
}
