import React from 'react'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import kebabCase from 'lodash/kebabCase'

import { COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate, invalidateQueries } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import URLInput from '../../components/Forms/URLInput'

import { APP_URLS } from '../MyAccount/utils'
import { P, Ol, Li } from '../../components/Typography'
import { PlainSwitch } from '../../components/Forms/Switch'
import { OrganizationRelationshipTypeSelect } from '../Organizations/OrganizationRelationshipTypeSelect'

import { OrganizationLeadFormExample } from './OrganizationLeadFormExample'
import { OrganizationLeadFormSettings } from './OrganizationLeadFormSettings'

const RootOrganizationLeadFormOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    save,
  } = useOverlay({
    name: 'organization_lead_forms',
    endpoint: '/organization_lead_forms',
    invalidate: 'organization_lead_forms',
    options: props,
  })

  const { tenant, timezone } = useSettings()

  const isDesktop = useMedia({ minWidth: 900 })

  const [formSettings, setFormSettings] = React.useState(null)
  const [isPreviewVisible, setIsPreviewVisible] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)

  const debouncedSetFormSettings = React.useMemo(() => {
    return debounce((value) => {
      setFormSettings?.(value)
    }, 300)
  }, [])

  const togglePreview = () => {
    setIsPreviewVisible((c) => !c)
  }

  const { mutateAsync: updateDefaultAsync, isLoading: isMakingDefault } = useUpdate({
    url: `/application_forms/${id}/make_default`,
    invalidate: 'application_forms',
  })

  const isDefaultForm = data?.is_default
  const isDefaultSlug = data?.slug === 'full-application'

  React.useEffect(() => {
    if (!!formSettings || isEditable) return

    setFormSettings(data)
  }, [data, isEditable, formSettings])

  React.useEffect(() => {
    if (isPreviewVisible && !isDesktop) {
      setIsPreviewVisible(false)
    }
  }, [isDesktop, isPreviewVisible])

  const setAsDefault = async () => {
    await updateDefaultAsync({})
    invalidateQueries('application_forms')
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={60} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={isPreviewVisible ? 100 : 60}>
      <Overlay.Header icon="organization_lead_forms" title="Lead Contact Form" />

      {isDesktop && (
        <Overlay.SubHeader className="flex items-center justify-end flex-nowrap px-2 !py-1.5">
          <Label isCompact label="Show Preview" onClick={togglePreview} className="!flex-[0_0_auto] cursor-pointer" />
          <PlainSwitch size={200} isChecked={isPreviewVisible} onCheckedChange={setIsPreviewVisible} />
        </Overlay.SubHeader>
      )}

      <div className={clsx('grid grid-rows-[100%] flex-[1_1_auto] overflow-hidden', isPreviewVisible ? 'grid-cols-2' : 'grid-cols-1')}>
        <Overlay.Content className={isPreviewVisible ? 'border-r border-0 border-solid border-divider' : ''}>
          <div className="grid gap-4 px-5 py-4">
            {isEditable && (
              <Alert small contrast glyph="info">
                <P>
                  <strong>How to customize your application:</strong>
                </P>
                <Ol>
                  <Li>Check the boxes for the questions you want in your application.</Li>
                  <Li>If you want the question required select the required check-box.</Li>
                  <Li>
                    Click the preview button to see what your form looks like. Please note that some questions have related follow-up
                    questions included in the preview.
                  </Li>
                </Ol>
              </Alert>
            )}

            {!isEditable && isDefaultForm && !isDefaultSlug && (
              <Alert small contrast glyph="intake_application">
                This is the default application form and can be accessed at{' '}
                <a href={`${APP_URLS.ehr}/${tenant.id}/organization-leads/full-application`} target="_blank">
                  {APP_URLS.ehr}/{tenant.id}/organization-leads/full-application
                </a>
                , as well as the public link below.
              </Alert>
            )}

            <OrganizationLeadFormSettings
              form={form}
              timezone={tenant?.timezone}
              isEditable={isEditable}
              companyName={tenant?.name}
              initialModel={initialModel}
              onValidationUpdate={setIsValid}
              onUpdate={debouncedSetFormSettings}
              before={
                <>
                  <Flex stretchChildrenX gap="1rem">
                    <Input
                      label="Form Name"
                      model="name"
                      defaultValue="Application Form"
                      validations={{
                        presence: {
                          message: 'Please enter a form name',
                        },
                      }}
                    />

                    {isEditable ? (
                      <Input
                        label="Public Link"
                        model="slug"
                        prefix={
                          <span className="flex items-center">
                            <Glyph glyph="website" size={16} className="mr-1.5" color={COLORS.text} />
                            <span className="text-[0.85rem]">
                              {APP_URLS.ehr}/{tenant.id}/organization-leads/
                            </span>
                          </span>
                        }
                        processValueBeforeChange={(value: any) => kebabCase(value)}
                        validations={{
                          presence: {
                            message: 'Please enter a public link',
                          },
                        }}
                      />
                    ) : (
                      <URLInput label="Public Link" value={`${APP_URLS.ehr}/${tenant.id}/organization-leads/${data?.slug}`} />
                    )}
                  </Flex>

                  <OrganizationRelationshipTypeSelect
                    defaultValue="internal"
                    model="organization_relationship"
                    tooltip="This option will help categorize the submissions you receive and allow you to create organization records based on the relationship type."
                  />
                </>
              }
            />
          </div>
        </Overlay.Content>

        {isPreviewVisible && formSettings?.schema && (
          <Overlay.Content>
            <OrganizationLeadFormExample
              schema={formSettings.schema}
              thankYouMessage={formSettings.thank_you_message}
              welcomeMessage={formSettings?.welcome_message}
              roiDisclaimerMessage={formSettings?.roi_disclaimer_message}
              companyName={tenant?.name || ''}
            />
          </Overlay.Content>
        )}
      </div>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={!isValid}
              flex="100 1 auto"
              permission={isNew ? 'settings.organization_lead_forms.create' : 'settings.organization_lead_forms.edit'}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Application Form"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.organization_lead_forms.edit"
            />

            <DeleteDialog
              title="Delete Application Form?"
              message="Are you sure you want to delete this Application Form? This action cannot be undone."
              permission="settings.organization_lead_forms.delete"
              onYes={deleteRecord}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                permission="settings.organization_lead_forms.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const OrganizationLeadFormOverlay = withOverlayError(RootOrganizationLeadFormOverlay)
