import React from 'react'

import { withFormContext } from '../../../components/Forms/context'

import Grid from '../../../components/Grid'
import Flex from '../../../components/Flex'
import Input from '../../../components/Forms/Input'
import { COLORS } from '../../../theme'

import { SectionCard } from '../../../components/SectionCard'
import ValueCodeSelector from '../../../components/Forms/elements/ValueCodeSelector'

const ClaimInstitutionalValueCodes = ({ className }: any) => {
  return (
    <>
      <SectionCard title="Value Codes" className={className}>
        <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2 mq900:grid-cols-4">
          <div css={{ paddingRight: '1rem', paddingBottom: '1rem', borderRight: `1px solid ${COLORS.lightGray}` }}>
            <Grid gap="1rem">
              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #1" model="value_codes.code_1" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_1" />
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #1" model="value_codes.code_2" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_2" />
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #3" model="value_codes.code_3" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_3" />
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #4" model="value_codes.code_4" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_4" />
              </Flex>
            </Grid>
          </div>

          <div css={{ paddingRight: '1rem', paddingBottom: '1rem', borderRight: `1px solid ${COLORS.lightGray}` }}>
            <Grid gap="1rem">
              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #5" model="value_codes.code_5" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_5" />
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #6" model="value_codes.code_6" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_6" />
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #7" model="value_codes.code_7" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_7" />
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #8" model="value_codes.code_8" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_8" />
              </Flex>
            </Grid>
          </div>

          <div css={{ paddingRight: '1rem', paddingBottom: '1rem', borderRight: `1px solid ${COLORS.lightGray}` }}>
            <Grid gap="1rem">
              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #9" model="value_codes.code_9" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_9" />
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #10" model="value_codes.code_10" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_10" />
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #11" model="value_codes.code_11" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_11" />
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <ValueCodeSelector label="Code #12" model="value_codes.code_12" />
                <Input label="Amount" css={{ maxWidth: 150 }} model="value_codes.amount_12" />
              </Flex>
            </Grid>
          </div>
        </div>
      </SectionCard>
    </>
  )
}

export default withFormContext(ClaimInstitutionalValueCodes)
