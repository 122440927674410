import React from 'react'
import { connect } from 'react-redux'
import startCase from 'lodash/startCase'

import { age, address } from '../../../utils/functions'
import { apiCreate } from '../../../modules/api'
import { ICONS } from '../../../theme'
import { withOverlayError } from '../../../hocs/withOverlayError'
import withRouterV6 from '../../../hocs/withRouterV6'

import Alert from '../../Alert'
import Button from '../../Button'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../Overlay'
import Section from '../../Section'
import MultiOverlaySelector from '../../Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { invalidateQueries } from '../../../hooks/useNewAPI'

class AccessListImportOverlay extends OverlayBase {
  skipRecordChecks = true

  save = async () => {
    let model = this.form.current.getFormValue()

    const { clients, ...rest } = model

    const resident_ids = [
      ...clients.applicant_ids,
      ...clients.accepted_ids,
      ...clients.declined_ids,
      ...clients.current_ids,
      ...clients.alumnus_ids,
    ]

    await apiCreate({
      name: 'access_controls',
      url: `/access_controls/import_staff_clients`,
      params: {
        ...rest,
        resident_ids,
      },
    })

    invalidateQueries(null, 'access_controls')

    this.close()
  }

  renderHeader = () => <Overlay.Header icon="clients" title="Add Clients" />

  renderContent = () => {
    return (
      <Overlay.Content>
        <Form
          isEditable
          getForm={this.form}
          onValidationUpdate={this.onValidationUpdate}
          decorate={() => ({
            subject_id: this.props.match.params?.id || this.props.match.params?.resource_id,
            subject_type: 'Employee',
            variant: 'client',
            level: 'default',
          })}
        >
          <Section>
            <FormSection layout="vertical">
              <Alert glyph="info">Select new clients to add to this access list</Alert>
              <Alert type="neutral" glyph="info">
                We automatically filter out all duplicates
              </Alert>
            </FormSection>
          </Section>

          <Section headingType="h3" title="Clients">
            <FormSection layout="vertical">
              <MultiOverlaySelector
                label="Select from Applicants"
                type="clients_by_status.applicants"
                model="clients.applicants"
                icon={ICONS.admissions}
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
              />

              <MultiOverlaySelector
                label="Select from Accepted Clients"
                type="clients_by_status.accepted"
                model="clients.accepted"
                icon={ICONS.admissions}
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
              />

              <MultiOverlaySelector
                label="Select from Declined Clients"
                type="clients_by_status.declined"
                model="clients.declined"
                icon={ICONS.admissions}
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
              />

              <MultiOverlaySelector
                label="Select from Current Clients"
                type="clients_by_status.current"
                model="clients.current"
                icon={ICONS.clients}
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
              />

              <MultiOverlaySelector
                label="Select from Alumni"
                type="clients_by_status.alumni"
                model="clients.alumni"
                icon={ICONS.alumni_relations}
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h3" title="Properties & Programs">
            <FormSection layout="vertical">
              <MultiObjectSelector
                label="Add Clients from Locations"
                type="properties"
                model="properties"
                icon={ICONS.properties}
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => address(data.address)}
              />

              <MultiObjectSelector
                label="Add Clients from Programs"
                type="programs"
                model="programs"
                icon={ICONS.checklist}
                selectTitle={(data: any) => data.name}
                selectDescription={() => null}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { loading } = this.props

    return (
      <Overlay.Footer>
        <Button
          label="Add Clients"
          glyph="check"
          type="primary"
          color="green"
          isLoading={loading}
          onClick={this.save}
          flex="3 1 auto"
          permission="access_controls.actions.import"
        />
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'access_controls')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(AccessListImportOverlay))

export const AccessListImportOverlayV6 = withRouterV6(
  connect(mapStateToProps, mapDispatchToProps)(withOverlayError(AccessListImportOverlay)),
)
