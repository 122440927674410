import React from 'react'
import { NavLink, useParams } from 'react-router-dom-v5-compat'

import { Button, Grid, Page } from '@behavehealth/components'
import { OrganizationContactsDataTable } from '@behavehealth/constructs/Contacts/OrganizationContactsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

export const Contacts: React.FC = () => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['organizations', resource_id, 'contacts'],
    endpoint: `/organizations/${resource_id}/contacts`,
    enabled: !!resource_id,
    localStorageKey: 'authority_organization_contacts_v1',
    headers: {
      'X-Request-Level': 'global',
    },
  })

  return (
    <Page feature="contacts" actions={<Button as={NavLink} label="Add Contact" type="primary" glyph="add" link={'new'} />}>
      <Grid>
        <OrganizationContactsDataTable {...tableProps} mainLinkAs={NavLink} to={(row: any) => `${row.id}`} />
      </Grid>
    </Page>
  )
}
