import React from 'react'

import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import PhoneInput from '../../components/Forms/PhoneInput'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'
import URLInput from '../../components/Forms/URLInput'

import { AddressInputs } from '../../components/Forms/elements'
import { ORGANIZATIONS_TYPE_PROVIDERS } from '../../utils/constants'

type FormCardProps = {
  children: React.ReactNode
  description: string
  headerAside: React.ReactNode
  maxWidth?: boolean | string
  title: string
}

type DataArraySectionProps = {
  children: React.ReactNode
  description: string
  itemTitle: string
  maxWidth?: boolean | string
  model: string
  title: string
}

export const data = [
  {
    title: 'Organization',
    model: 'organization',
    isOpen: true,
    isRequired: true,
    fields: [
      {
        label: 'Organization Name',
        model: 'name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: () => (
          <>
            <Input label="Organization Name" model="organization.name" />

            <div className="!hidden">
              <Select isEditable={false} label="Type" model="category" defaultValue="vendor" value="provider">
                <Option label="Vendor" value="vendor" />
                <Option label="Community Resource" value="resource" />
                <Option label="Provider" value="provider" />
              </Select>
            </div>
          </>
        ),
      },
      {
        label: 'Category',
        model: 'category',
        isChecked: true,
        isRequired: true,
        component: () => (
          <>
            <Select
              label="Category"
              model="subcategory"
              defaultValue="sober_living_home"
              validations={{
                presence: {
                  message: 'Please select an organization category',
                },
              }}
            >
              {Object.keys(ORGANIZATIONS_TYPE_PROVIDERS).map((provider) => (
                <Option label={ORGANIZATIONS_TYPE_PROVIDERS[provider]} value={provider} />
              ))}
            </Select>
          </>
        ),
      },
      {
        label: 'Email',
        model: 'email',
        isChecked: true,
        component: () => <EmailInput label="Email" model="organization.email" />,
      },
      {
        label: 'Phone Number',
        model: 'phone_no',
        isChecked: true,
        component: () => (
          <Flex horizontal gap="1rem">
            <PhoneInput css={{ flex: '1 1 auto' }} model="organization.phone_no" label="Phone Number" type="tel" />
            <Input css={{ width: 145 }} glyph="phone_ext" label="Phone No Extension" model="organization.phone_no_ext" />
          </Flex>
        ),
      },
      {
        label: 'Fax',
        model: 'fax',
        component: () => <Input glyph="fax" label="Fax" model="organization.fax" />,
      },
      {
        label: 'Notes',
        model: 'notes',
        component: () => <Textarea useQuickText model="organization.additional_info" label="Notes" />,
      },
      {
        label: 'Address',
        model: 'address',
        component: () => <AddressInputs label="Address" model="organization.address" />,
      },
    ],
  },
  {
    title: 'Social',
    model: 'social',
    fields: [
      {
        label: 'Website',
        model: 'website',
        component: () => <URLInput label="Website" model="organization.website_url" glyph="website" />,
      },
      {
        label: 'Facebook',
        model: 'facebook',
        component: () => <URLInput glyph="facebook" label="Facebook" model="organization.facebook" />,
      },
      {
        label: 'Instagram',
        model: 'instagram',
        component: () => <URLInput glyph="instagram" label="Instagram" model="organization.instagram" />,
      },
      {
        label: 'Google Business',
        model: 'google_business',
        component: () => <URLInput glyph="googleplus" label="Google Business" model="organization.google_business" />,
      },
      {
        label: 'WhatApp',
        model: 'whatsapp',
        component: () => <URLInput glyph="whatsapp" label="WhatApp" model="organization.whatsapp" />,
      },
      {
        label: 'Zoom',
        model: 'zoom',
        component: () => <URLInput glyph="zoom" label="Zoom" model="organization.zoom" />,
      },
      {
        label: 'Google',
        model: 'google_meet',
        component: () => <URLInput glyph="googleplus" label="Google Meet" model="organization.google_meet" />,
      },
      {
        label: 'Skype',
        model: 'skype',
        component: () => <URLInput glyph="skype" label="Skype" model="organization.skype" />,
      },
    ],
  },
  {
    title: 'Locations',
    model: 'locations',
    isDataArray: true,
    fields: [
      {
        label: 'Name',
        model: 'name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: ({ dataArrayId }: any) => <Input label="Name" model={`${dataArrayId}.name`} />,
      },
      {
        label: 'Address',
        model: 'email',
        component: ({ dataArrayId }: any) => <AddressInputs label="Address" model={`${dataArrayId}.address`} />,
      },
    ],
  },
  {
    title: 'Programs',
    model: 'programs',
    isDataArray: true,
    fields: [
      {
        label: 'Name',
        model: 'name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: ({ dataArrayId }: any) => <Input label="Name" model={`${dataArrayId}.name`} />,
      },
      {
        label: 'Address',
        model: 'email',
        component: ({ dataArrayId }: any) => <AddressInputs label="Address" model={`${dataArrayId}.address`} />,
      },
    ],
  },
  {
    title: 'Contacts',
    model: 'contacts',
    isDataArray: true,
    fields: [
      {
        label: 'First Name',
        model: 'first_name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: ({ dataArrayId }: any) => (
          <Input
            label="First Name"
            model={`${dataArrayId}.first_name`}
            validations={{
              presence: {
                message: 'Please enter your first name',
              },
            }}
          />
        ),
      },
      {
        label: 'Middle Name',
        model: 'middle_name',
        isDisabled: false,
        isRequiredDisabled: true,
        isChecked: true,
        component: ({ dataArrayId }: any) => <Input label="Middle Name" model={`${dataArrayId}.middle_name`} />,
      },
      {
        label: 'Last Name',
        model: 'last_name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: ({ dataArrayId }: any) => (
          <Input
            label="Last Name"
            model={`${dataArrayId}.last_name`}
            validations={{
              presence: {
                message: 'Please enter your last name',
              },
            }}
          />
        ),
      },
      {
        label: 'Email Address',
        model: 'email',
        component: ({ dataArrayId }: any) => <EmailInput label="Email Address" model={`${dataArrayId}.email`} />,
      },
      {
        label: 'Phone Number',
        model: 'phone_no',
        component: ({ dataArrayId }: any) => <PhoneInput label="Phone Number" model={`${dataArrayId}.phone_no`} />,
      },
    ],
  },
]
