import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { apiGet } from '../../../modules/api'
import { usDateTime } from '../../../utils/functions'
import { useAPI, useSettings } from '../../../hooks'
import withSettings from '../../../hocs/withSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Grid from '../../../components/Grid'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'
import Workflow from '../../../components/Workflow/Workflow'

import ApplicationStatus from '../../../components/Statuses/ApplicationStatus'
import ApplicationReviewStatus from '../../../components/Statuses/ApplicationReviewStatus'

import { IntakeApplicationOverlay } from '../../IntakeApplications/IntakeApplicationOverlay'
import { useDataTable } from '../../../components/DataTable/useDataTable'
import { DataTable } from '../../../components/DataTable/DataTable'
import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { useGet } from '../../../hooks/useNewAPI'

const onSaveSuccessful = (clientId: any) => {
  apiGet({
    name: 'intake_applications',
    url: `/residents/${clientId}/intake_applications`,
  })
}

const AddButton = ({ client, match }: any) => {
  const { data: applicationForms, isLoading: isLoadingApplicationForms } = useGet({
    name: 'application_forms',
    url: `/application_forms`,
  })

  const initialData = React.useMemo(() => {
    if (!client) return null

    return {
      personal_details: {
        first_name: client.first_name,
        middle_name: client.middle_name,
        last_name: client.last_name,
        dob: client.dob,
      },
      demographics: {
        sex: client.sex,
        gender_identity: client.gender_identity,
        ethnicity: client.ethnicity,
      },
      contact: {
        primary_contact: {
          phone_no: client.phone_no,
          has_phone_no: !!client.phone_no,
          landline_no: client.landline_no,
          has_landline_no: !!client.landline_no,
          email: client.email,
          has_email: !!client.email,
        },
      },
    }
  }, [client])

  const { defaultForm, otherForms } = React.useMemo(() => {
    let defaultForm = null
    const otherForms: any = []

    if (!applicationForms) return { defaultForm, otherForms }

    for (const applicationForm of applicationForms) {
      if (applicationForm.is_default) {
        defaultForm = applicationForm
      } else {
        otherForms.push(applicationForm)
      }
    }

    return { defaultForm, otherForms }
  }, [applicationForms])

  return (
    <SummonOverlay
      overlay={
        <IntakeApplicationOverlay
          dataID="new"
          initialData={{ application_form: defaultForm, data: initialData }}
          match={match}
          onSaveSuccessful={() => onSaveSuccessful(client.id)}
          onDeleteSuccessful={() => onSaveSuccessful(client.id)}
        />
      }
    >
      <Button label="Add Application" glyph="add" type="primary" display="inline-flex" permission="applications.create" />
    </SummonOverlay>
  )
}

const Applications = ({ client, setStatus }: any) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { isRequired }: any = React.useContext(Workflow.PanelContext)

  const { timezone } = useSettings()

  const clientId = client?.id || resource_id

  const tableProps = useDataTable({
    name: ['clients', clientId, 'intake_applications'],
    endpoint: `/residents/${clientId}/intake_applications`,
    enabled: !!clientId,
  })

  const isEmpty = size(tableProps.data) === 0

  const columns = React.useMemo(() => {
    return [
      {
        width: 220,
        model: 'applied_at',
        title: 'Date & Time',
        formatValue: ({ value, data }: any) => {
          return (
            <SummonOverlay
              overlay={
                <IntakeApplicationOverlay
                  showBackdrop
                  dataID={data.id}
                  match={match}
                  onSaveSuccessful={() => onSaveSuccessful(client.id)}
                  onDeleteSuccessful={() => onSaveSuccessful(client.id)}
                />
              }
            >
              <MainCell value={usDateTime(value, timezone)} />
            </SummonOverlay>
          )
        },
      },
      {
        width: 160,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <ApplicationStatus status={value} />,
      },
      {
        width: 200,
        model: 'review_status',
        title: 'Application Review Status',
        formatValue: ({ value }: any) => (value ? <ApplicationReviewStatus status={value} /> : null),
      },
      {
        model: 'reminded_at',
        title: 'Last Reminded At',
        type: 'date_time',
      },
      {
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [client, timezone])

  const onContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isEmpty || tableProps.isLoading) {
    return (
      <State
        isLoading={tableProps.isLoading}
        isEmpty={isEmpty}
        title="Applications"
        icon="applications"
        emptyDescription="No applications completed yet"
        emptyActions={<AddButton client={client} match={match} />}
      />
    )
  }

  return (
    <Grid gap="1rem">
      <Card testKey="applications_table">
        <DataTable asCard title="Applications" icon="applications" columns={columns} {...tableProps} />

        <CardContent>
          <AddButton client={client} match={match} />
        </CardContent>
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        {!isRequired && <Workflow.SkipButton onClick={onSkip} />}
      </Workflow.Buttons>
    </Grid>
  )
}

export default withSettings(Applications)
