import React from 'react'
import size from 'lodash/size'

import { DataTable } from '../../../components/DataTable/DataTable'
import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { OrganizationCalendarPageActions } from '../../Organizations/OrganizationCalendarPageActions'
import { useDataTable } from '../../../components/DataTable/useDataTable'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import EventStatus from '../../../components/Statuses/EventStatus'
import FormSection from '../../../components/Forms/FormSection'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import AuthorityOrganizationEventBuilderOverlay from '../AuthorityOrganizationEventBuilderOverlay'
import AuthorityOrganizationEventOverlay from '../AuthorityOrganizationEventOverlay'

const CATEGORIES: any = {
  general: 'General',
  client_admission: 'Client Admission',
  recovery_coaching: 'Recovery Coaching',
  housing_activity: 'Housing Activity',
  discharge: 'Discharge',
  psych_appointment: 'Psych Appointment',
  medical_appointment: 'Medical Appointment',
  clinical_appointment: 'Clinical Appointment',
  progress_appointment: 'Progress Appointment',
  individual_therapy: 'Individual Therapy',
  individual_peer_note: 'Individual Peer Note',
  group_peer_notes: 'Group Peer Note',
  individual_daily_note: 'Individual Daily Note',
  group_daily_notes: 'Group Daily Note',
  group_therapy: 'Group Therapy',
  meds_pass: 'Meds Pass',
  urinalysis: 'Urinalysis',
  breathalyzer: 'Breathalyzer',
  vitals: 'Vitals',
  communicable_tests: 'Communicable Tests',
  bed_check: 'Bed Check',
  housing_intake: 'Housing Intake',
  show_as_busy: 'Show as Busy',
}

const EVENT_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Event',
}

export const EventsStep = ({ organization, setStatus }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const [dataId, setDataId] = React.useState(null)
  const [newTemplate, setNewTemplate] = React.useState(null)

  const tableProps = useDataTable({
    name: ['organization', organization.id, 'events'],
    endpoint: `/organizations/${organization.id}/events`,
    updateDeleteEndpoint: `/events`,
    localStorageKey: 'authority_workflow_events_v1',
    headers: {
      'X-Request-Level': 'global',
    },
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        id: 'title',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell isActive={dataId === data.id} id={data.id} value={data.title} onClick={() => setDataId(data.id)} />
        ),
      },
      {
        width: 140,
        model: 'started_at',
        title: 'Date',
        type: 'date',
      },
      {
        width: 140,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <EventStatus status={value} />,
      },
      {
        width: 150,
        model: 'category',
        title: 'Category',
        formatValue: ({ value }: any) => {
          if (!(value in CATEGORIES)) return null

          return CATEGORIES[value]
        },
      },
      {
        width: 150,
        model: 'meeting_type',
        title: 'Event Type',
        formatValue: ({ value }: any) => {
          if (!(value in EVENT_TYPES)) return null

          return EVENT_TYPES[value]
        },
      },
      {
        width: 90,
        id: '__color',
        model: 'color',
        title: 'Color',
        type: 'color',
      },
      {
        width: 200,
        id: 'public_description',
        model: 'public_description',
        title: 'Public Description',
        type: 'rich_text',
      },
      {
        id: 'created_at',
        model: 'created_at',
        title: 'Date Created',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [dataId])

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <DataTable asCard title="Events" icon="calendar" columns={columns} {...tableProps} />

          <CardContent className="flex">
            <OrganizationCalendarPageActions
              showManageLink={false}
              buttonSize={200}
              organizationTrack={organization.organization_track}
              onClick={(template: any) => {
                setNewTemplate(template)
              }}
            />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        isOpen={!!newTemplate}
        onClose={() => {
          setNewTemplate(null)
        }}
        overlay={
          <AuthorityOrganizationEventBuilderOverlay
            dataID="new"
            initialData={{
              ...(newTemplate || {}),
              meeting_place: 'organization',
              organization: organization,
            }}
          />
        }
      />

      <SummonOverlay
        key={`id-${dataId}`}
        isOpen={!!dataId}
        onClose={() => {
          setDataId(null)
        }}
        overlay={<AuthorityOrganizationEventOverlay dataID={dataId} />}
      />
    </>
  )
}
