import React from 'react'
import size from 'lodash/size'

import { usDate, niceAmount, nicePercentage } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Card from '../../components/Card'
import CardTreeItem from '../../components/CardTreeItem'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import DataList from '../../components/DataList'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import InOutNetworkStatus from '../../components/Statuses/InOutNetworkStatus'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Status from '../../components/Status'
import Table from '../../components/Table/Table'

import VOBServicesV2View from '../RCM/components/VOBServicesView'
import VOBServicesView from '../../components/RCM/components/VOBServicesView'

const servicesColumns = [
  {
    width: 140,
    accessor: 'name',
    Header: 'Service',
    Cell: ({ value }: any) => <div className="!font-[600]">{value}</div>,
  },
  {
    width: 100,
    accessor: 'isCovered',
    Header: 'Coverage',
    Cell: ({ value }) => {
      if (!value) return <Status label="Not Covered" color="red" />
      return <Status label="Covered" color="green" />
    },
  },
  {
    width: 100,
    accessor: 'doesRequireAuth',
    Header: 'Authorization',
    Cell: ({ value }: any) => {
      if (!value) return <Status label="Auth NOT Required" color="vividBlue" />
      return <Status label="Auth Required" color="orange" />
    },
  },
]

const constructServices = (servicesCoveredArr: any, servicesNotCoveredArr: any, preCertArr: any) => {
  const res: any = {}

  if (!(servicesCoveredArr && servicesNotCoveredArr && preCertArr)) return res

  for (const section of servicesCoveredArr) {
    for (const service of section.services) {
      res[service.id] = {
        ...service,
        ...res[service.id],
        isCovered: true,
      }
    }
  }

  for (const section of servicesNotCoveredArr) {
    for (const service of section.services) {
      res[service.id] = {
        ...service,
        ...res[service.id],
        isCovered: false,
      }
    }
  }

  for (const section of preCertArr) {
    if (!section.needs_precert) continue

    for (const service of section.services) {
      res[service.id] = {
        ...service,
        ...res[service.id],
        doesRequireAuth: true,
      }
    }
  }

  return res
}

const RootFullVOBSummaryOverlay = (props: any) => {
  const { close, initialModel, isOverlayLoading } = useOverlay({
    name: 'insurance_vobs',
    endpoint: '/insurance_vobs',
    invalidate: 'insurance_vobs',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const {
    client,
    insurance_policy,
    network_status,
    data: { accreditations, cost_sharing, insurance_visits, max_reimbursements, pre_cert, services_covered, services_not_covered, summary },
  } = initialModel

  const services = constructServices(services_covered, services_not_covered, pre_cert)

  return (
    <Overlay maxWidth={48} onClose={close}>
      <Overlay.Header icon="financials" title="VOB Summary" description={<ClientProfileHeader client={client} />} />

      <Overlay.Content>
        <Section title="Patient Eligibility">
          <Grid gap="1.5rem" columns="repeat(auto-fit, minmax(220px, 1fr))">
            <DataList layout="horizontal" labelWidth="180px">
              <DataList.Item label="Payer:" value={insurance_policy?.insurance_name} />
              <DataList.Item label="Network:" value={<InOutNetworkStatus status={network_status} />} />
              <DataList.Item label="Plan:" value={insurance_policy?.plan} />
            </DataList>

            <DataList layout="horizontal">
              <DataList.Item label="Policy Effective:" value={usDate(insurance_policy?.effective_at, timezone)} />
              <DataList.Item label="Policy Resets:" value={usDate(insurance_policy?.resets_at, timezone)} />
              <DataList.Item label="Policy Termination:" value={usDate(insurance_policy?.terminates_at, timezone)} />
            </DataList>
          </Grid>
        </Section>

        {summary?.notes && (
          <Section title="Summary">
            <p>{summary.notes}</p>
          </Section>
        )}

        {size(services) >= 1 && (
          <Section title="Services">
            <Grid>
              <Card>
                <Table data={services} columns={servicesColumns} showSettings={false} />
              </Card>
            </Grid>
          </Section>
        )}

        {size(cost_sharing) >= 1 && (
          <Section title="Cost Sharing">
            <Grid gap="0.5rem">
              {cost_sharing?.map((section) => (
                <CardTreeItem title={section.levels_of_care?.map((o) => o.name)?.join(', ')}>
                  <div css={{ margin: '0.75rem 0 0.25rem' }}>
                    <InOutNetworkStatus status={network_status} />
                  </div>

                  <Grid gap="1.5rem" columns="repeat(auto-fit, minmax(220px, 1fr))">
                    <DataList className="text-[0.95rem]">
                      <DataList.Item label="Individual DED" value={niceAmount(section.individual_ded)} />
                      <DataList.Item label="Individual DED met" value={niceAmount(section.individual_ded_met)} />
                      <DataList.Item label="Individual OOP" value={niceAmount(section.individual_oop)} />
                      <DataList.Item label="Individual OOP met" value={niceAmount(section.individual_oop_met)} />
                    </DataList>

                    <DataList className="text-[0.95rem]">
                      <DataList.Item label="Family DED" value={niceAmount(section.family_ded)} />
                      <DataList.Item label="Family DED met" value={niceAmount(section.family_ded_met)} />
                      <DataList.Item label="Family OOP" value={niceAmount(section.family_oop)} />
                      <DataList.Item label="Family OOP met" value={niceAmount(section.family_oop_met)} />
                    </DataList>
                  </Grid>

                  <Status label="Other Details" color="blue" css={{ margin: '0.75rem 0 0.25rem' }} />

                  <DataList className="text-[0.95rem]" labelWidth="220px">
                    <DataList.Item label="DED included in the OOP?" value={section.ded_included_in_oop ? 'Yes' : 'No'} />
                    <DataList.Item label="Co-Ins Percentage" value={`${nicePercentage(section.percentage_coinsurance)}%`} />
                    <DataList.Item label="Is there a Co-pay?" value={section.is_copay ? 'Yes' : 'No'} />
                    <DataList.Item label="Co-pay Additional Info" value={section.copay_additional_info} />

                    {section.is_copay && (
                      <DataList.Item
                        label="Co-pay Amount:"
                        value={`${niceAmount(section.copay_amount)} ${section.copay_amount_description}`}
                      />
                    )}
                  </DataList>
                </CardTreeItem>
              ))}
            </Grid>
          </Section>
        )}

        {size(insurance_visits) >= 1 && (
          <Section title="Insurance Visits">
            <Grid gap="0.5rem">
              {insurance_visits?.map((section) => (
                <CardTreeItem title={section.services?.map((o) => o.name)?.join(', ')}>
                  <DataList className="text-[0.95rem]">
                    <DataList.Item label="Days / Year:" value={section.days_per_year} />
                    <DataList.Item label="Days Used:" value={section.days_used} />
                    <DataList.Item label="Lifetime Max:" value={section.lifetime_max} />
                    <DataList.Item label="Lifetime Limits Used:" value={section.lifetime_limits_used} />
                    <DataList.Item label="Notes" value={section.notes} />
                  </DataList>
                </CardTreeItem>
              ))}
            </Grid>
          </Section>
        )}

        {size(max_reimbursements) >= 1 && (
          <Section title="Max Reimbursements">
            <Grid gap="0.5rem">
              {max_reimbursements?.map((section) => (
                <CardTreeItem title={section.services?.map((o) => o.name)?.join(', ')}>
                  <DataList className="text-[0.95rem]">
                    <DataList.Item label="Max Amount / Day:" value={section.max_amount_per_day} />
                    <DataList.Item label="Annual Max:" value={section.annual_max} />
                    <DataList.Item label="Annual Used:" value={section.annual_used} />
                    <DataList.Item label="Lifetime Max:" value={section.lifetime_max} />
                    <DataList.Item label="Lifetime Used:" value={section.lifetime_used} />
                    <DataList.Item label="Notes" value={section.notes} />
                  </DataList>
                </CardTreeItem>
              ))}
            </Grid>
          </Section>
        )}

        {accreditations?.has_required_accreditations && (
          <Section title="Accreditations and Exclusions">
            <CardTreeItem title="View Details">
              <DataList labelWidth="500px">
                {accreditations.jcaho && (
                  <DataList.Item label="The Joint Commission (JCAHO)" value={<Status label="Required" color="orange" />} />
                )}

                {accreditations.carf && (
                  <DataList.Item
                    label="Commission on Accreditation of Rehabilitation Facilities (CARF)"
                    value={<Status label="Required" color="orange" />}
                  />
                )}

                {accreditations.coa && (
                  <DataList.Item label="Council on Accreditation (COA)" value={<Status label="Required" color="orange" />} />
                )}

                {accreditations.ncqa && (
                  <DataList.Item
                    label="National Committee for Quality Assurance (NCQA)"
                    value={<Status label="Required" color="orange" />}
                  />
                )}

                {accreditations.hfap && (
                  <DataList.Item
                    label="Healthcare Facilities Accreditation Program (HFAP)"
                    value={<Status label="Required" color="orange" />}
                  />
                )}

                <DataList.Item
                  label="Are free-standing facilities covered?"
                  value={
                    accreditations.are_free_standing_facilities_covered ? (
                      <Status label="Yes" color="green" />
                    ) : (
                      <Status label="No" color="red" />
                    )
                  }
                />
              </DataList>

              <DataList layout="vertical">
                <DataList.Item label="Plan exclusions:" value={accreditations.exclusions} />
              </DataList>
            </CardTreeItem>
          </Section>
        )}

        {initialModel?.services && (
          <>
            <Divider />

            {initialModel?.version === 'v1' && <VOBServicesView value={initialModel.services} />}
            {initialModel?.version === 'v2' && <VOBServicesV2View value={initialModel.services} />}
          </>
        )}
      </Overlay.Content>
    </Overlay>
  )
}

export const FullVOBSummaryOverlay = withOverlayError(RootFullVOBSummaryOverlay)
