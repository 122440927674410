import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { usDateTime } from '../../utils/functions'

import useSettings from '../../hooks/useSettings'

export const FullVOBDataTable = (props: any) => {
  const { to, ...rest } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Date Added',
        model: 'dated_at',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={to?.(data)} value={usDateTime(value, timezone)} />
        },
      },
      {
        title: 'Insurance Payer',
        model: 'insurance_policy.insurance_name',
        id: 'insurance_policy_insurance_name',
      },
      {
        title: 'Plan Name',
        model: 'insurance_policy.plan',
        id: 'insurance_policy_plan',
      },
    ],
    [to],
  )

  return <DataTable asCard title="Full VOB Summaries" icon="financials" columns={columns} filtersConfig={FILTERS.full_vobs} {...rest} />
}
