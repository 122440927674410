import React from 'react'

import { OrganizationCustomNoteTemplatesDataTable } from '@behavehealth/constructs/CustomNoteTemplates/OrganizationCustomNoteTemplatesDataTable'
import { Page, Grid } from '@behavehealth/components'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'organization_custom_note_templates',
  title: 'Organization Custom Note Templates',
}

const OrganizationCustomNoteTemplates = () => {
  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    params: { variant: 'organization' },
    localStorageKey: 'organization_custom_note_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Grid>
        <OrganizationCustomNoteTemplatesDataTable to={(row: any) => row.id} {...tableProps} />
      </Grid>
    </Page>
  )
}

export default withMarketing(OrganizationCustomNoteTemplates, pageConfig)
