import React from 'react'
import { opacify } from 'polished'

import { COLORS } from '../../theme'
import { isURL, isDefined, usDate, usTime } from '../../utils/functions'

import withSettings from '../../hocs/withSettings'

import Button from '../Button'
import Card from '../Card'
import CardHeader from '../CardHeader'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import Field from '../Forms/Field'
import Glyph from '../Glyph'
import Grid from '../Grid'
import SignatureCanvas from './SignatureCanvas'
import Text from '../Typography/Text'

import { withFormContext } from './context'
import { useFormField } from './hooks/useFormField'

const SignatureInput = (props: any) => {
  const { form, model, isEditable, title, showSubtitle, label, timezone, width, height, description, onUpdate, ...others } = props

  const [value, setValue]: any = React.useState(form?.getInitialInputFieldValue(model))
  const [data, setData]: any = React.useState(form?.getInitialInputFieldValue(`${model}_data`))
  const signedAt = React.useRef(form?.getInitialInputFieldValue(`${model}_signed_at`))

  const { formActions } = useFormField({
    model: props.model,
    form: props.form,
    isValid: true,
    defaultEmptyValue: null,
    afterReset: () => {
      setValue(form?.getInitialInputFieldValue(`${model}_data`))
      setData(form?.getInitialInputFieldValue(model))
    },
  })

  const clearSignature = () => {
    setValue(null)
    setData(null)
  }

  const isSigned = isURL(value) || isDefined(data)

  return (
    <Field label={label || title} description={description} isEditable={isEditable} {...others}>
      {!isEditable && (
        <Card>
          {isSigned && (
            <>
              {signedAt.current && (
                <CardHeader
                  paddingY="0.4rem"
                  css={{ borderRadius: 0, background: COLORS.white, boxShadow: `0 0 0 1px ${opacify(-0.05, COLORS.divider)}` }}
                >
                  {showSubtitle && (
                    <CardMeta>
                      <Text glyph="date" description={usDate(signedAt.current, timezone)} />
                      <Text glyph="time" description={usTime(signedAt.current, timezone)} />
                    </CardMeta>
                  )}
                </CardHeader>
              )}

              <div css={styles.signature}>
                <img src={data} alt="signature" />
              </div>
            </>
          )}

          {!isSigned && (
            <CardHeader graphic={<Glyph glyph="signature" size={18} />}>
              <CardSubtitle subtitle="No signature added yet…" />
            </CardHeader>
          )}
        </Card>
      )}

      {isEditable && (
        <Card baseline="2.5rem" paddingY="0.4rem">
          {isSigned && (
            <>
              {signedAt.current && (
                <CardHeader
                  paddingY="0.4rem"
                  css={{ borderRadius: 0, background: COLORS.white, boxShadow: `0 0 0 1px ${opacify(-0.05, COLORS.divider)}` }}
                >
                  {showSubtitle && (
                    <CardMeta>
                      <Text glyph="date" description={usDate(signedAt.current, timezone)} />
                      <Text glyph="time" description={usTime(signedAt.current, timezone)} />
                    </CardMeta>
                  )}
                </CardHeader>
              )}

              <div css={styles.signature}>
                <img src={data} alt="signature" />
              </div>

              <Grid css={styles.footer} autoFlow="column">
                <Button type="minimal" color="red" glyph="cross" label="Clear" onClick={clearSignature} fullWidth />
              </Grid>
            </>
          )}

          {!isSigned && (
            <SignatureCanvas
              model={props.model}
              width={width}
              height={height}
              validations={props.validations}
              onValidationUpdate={formActions.setValidity}
              onUpdate={onUpdate}
            />
          )}
        </Card>
      )}
    </Field>
  )
}

const styles = {
  content: {
    background: '#fdfdfe',
    boxShadow: `0 -1px 0 ${COLORS.divider}, 0 1px 0 ${COLORS.divider}`,

    '&.is-signed': {
      boxShadow: 'none',
      background: 'none',
    },
  },

  tabs: {
    display: 'flex',

    '.tab': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 auto',
      minHeight: '2.75rem',
      transition: 'all 100ms',
      background: 'none !important',
      border: 'none',
      borderRadius: 0,

      '&:hover': {
        transform: 'translateY(-2px)',
      },

      cursor: 'pointer',
      color: COLORS.link,
      textAlign: 'center',
      fontSize: 15,
      textDecoration: 'none',
      fontWeight: 500,

      '&.is-active': {
        boxShadow: `inset 0 -2px 0 ${COLORS.link}`,

        '&:hover': {
          transform: 'translateY(0)',
        },
      },
    },
  },

  photo: {
    padding: '0.5rem !important',

    '.DnDUploader': {
      margin: 0,
    },
  },

  signature: {
    padding: '1rem 1rem 1rem 2rem',

    img: {
      display: 'block',
      width: '100%',
      height: 'auto',
      maxWidth: 400,
      maxHeight: 200,
    },
  },

  footer: {
    '.Button': {
      boxShadow: '1px 0 0 @separator_color',
      borderRadius: 0,
    },
  },
}

SignatureInput.defaultProps = {
  showSubtitle: true,
  isEditable: true,
}

export default withSettings(withFormContext(SignatureInput))
