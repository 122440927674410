import React from 'react'

import NavGroup from './NavGroup'
import NavItem from './NavItem'
import State from './State'

import withScrollContext from '../hocs/withScrollContext'
import { COLORS } from '../theme'

const ScrollMenu = ({ sections }) => {
  const isEmpty = !sections || Object.keys(sections).length === 0
  if (isEmpty) return <State isLoading />

  return Object.keys(sections).map((key) => {
    const section = sections[key]
    if (section.id) {
      return (
        <NavItem
          type="scroll-list"
          asLink={false}
          key={section.id}
          scrollToSection={section.id}
          label={section.name}
          description={section.description}
          isActive={section.active}
          icon={section.icon}
          glyph={section.icon ? null : 'document'}
          color={COLORS.vividBlue}
        />
      )
    } else {
      return (
        <NavGroup key={section.name} label={section.name}>
          {Object.keys(section?.sections).map((element) => (
            <NavItem
              asLink={false}
              type="scroll-list"
              key={section.sections[element].id}
              scrollToSection={`${key}.sections.${section.sections[element].id}`}
              label={section.sections[element].name}
              description={section.sections[element].description}
              isActive={section.sections[element].active}
              glyph={'document'}
              color={COLORS.vividBlue}
            />
          ))}
        </NavGroup>
      )
    }
  })
}

export default withScrollContext(ScrollMenu)
