import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useParams, useNavigate, useMatch } from 'react-router-dom-v5-compat'
import kebabCase from 'lodash/kebabCase'
import snakeCase from 'lodash/snakeCase'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { titleCase } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { TreatmentPlanTemplateOverlay } from '@behavehealth/constructs/TreatmentPlans/TreatmentPlanTemplateOverlay'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import PageHeader from '@behavehealth/components/PageHeader'
import PageSection from '@behavehealth/components/PageSection/PageSection'
import State from '@behavehealth/components/State'
import Tabs from '@behavehealth/components/Tabs'

import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'
import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

const TreatmentPlansTemplatesV2: React.FC = () => {
  const { url } = useRouteURL()
  const navigate = useNavigate()

  const locationMatch: any = useMatch({ path: `${url}/:category` })
  const category = snakeCase(locationMatch?.params?.category)

  const {
    viewCaseManagementTreatmentPlanTemplates,
    viewClinicalTreatmentPlanTemplates,
    viewMedicalTreatmentPlanTemplates,
    viewPeerTreatmentPlanTemplates,
    viewSomeTreatmentPlanTemplates,
  } = useTreatmentPlanPermissions()

  const allowedTabs = React.useMemo(() => {
    const result = []

    if (viewClinicalTreatmentPlanTemplates) result.push('clinical')
    if (viewMedicalTreatmentPlanTemplates) result.push('medical')
    if (viewCaseManagementTreatmentPlanTemplates) result.push('case_management')
    if (viewPeerTreatmentPlanTemplates) result.push('peer')

    return result
  }, [
    viewClinicalTreatmentPlanTemplates,
    viewMedicalTreatmentPlanTemplates,
    viewCaseManagementTreatmentPlanTemplates,
    viewPeerTreatmentPlanTemplates,
  ])

  const firstAllowedTab = allowedTabs[0]

  React.useEffect(() => {
    if (!category || allowedTabs.includes(category)) return

    if (firstAllowedTab) navigate(kebabCase(firstAllowedTab), { replace: true })
  }, [category, allowedTabs, firstAllowedTab])

  return (
    <div>
      <div className="bg-white sticky top-0 z-10">
        <PageHeader title="Treatment Plan Templates" icon="treatment_plans" />

        {viewSomeTreatmentPlanTemplates && (
          <Tabs>
            <Tabs.List className="px-4">
              <Tabs.Item as={NavLink} label="Clinical" to={`clinical`} permission={viewClinicalTreatmentPlanTemplates} />
              <Tabs.Item as={NavLink} label="Medical" to={`medical`} permission={viewMedicalTreatmentPlanTemplates} />
              <Tabs.Item
                as={NavLink}
                label="Case Management"
                to={`case-management`}
                permission={viewCaseManagementTreatmentPlanTemplates}
              />
              <Tabs.Item as={NavLink} label="Peer" to={`peer`} permission={viewPeerTreatmentPlanTemplates} />
            </Tabs.List>
          </Tabs>
        )}
      </div>

      {!viewSomeTreatmentPlanTemplates && (
        <div className="p-4">
          <Card>
            <State
              isEmpty
              icon="treatment_plans"
              title="Treatment Plan Templates"
              emptyDescription="You do not have permissions to access Clinical, Medical nor Peer Treatment Plan Templates. If you're looking for the old Treatment Plan Templates, please click on the menu item under Templates Archived in the Settings menu."
            />
          </Card>
        </div>
      )}

      {viewSomeTreatmentPlanTemplates && firstAllowedTab && (
        <Routes>
          <Route index element={<Navigate to={firstAllowedTab} replace />} />
          <Route path=":category/*" element={<TreatmentPlanTemplateCategoryPage key={category} />} />
        </Routes>
      )}

      <AnimatedRoutes>
        <Route path=":category/:id" element={<TreatmentPlanTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const TreatmentPlanTemplateCategoryPage = () => {
  const params: any = useParams()

  const category = snakeCase(params.category)

  const permissionBase = `${category}_treatment_plan_templates`

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={NavLink} id={data.id} value={data.name} to={data.id} />,
      },
      {
        title: 'Client Tracks',
        model: 'client_tracks',
        type: 'record_client_tracks',
        featureFlag: 'client_tracks',
        editPermission: 'client_tracks.edit',
        disableSort: true,
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [])

  const tableProps = useDataTable({
    name: ['treatment-plan-templates'],
    endpoint: `/treatment_plan_templates`,
    params: { category },
    options: { enabled: !!category },
  })

  return (
    <>
      <div className="p-4">
        <PageSection>
          <PageSection.Header
            after={
              <Button
                as={Link}
                label={`Add ${titleCase(category)} Template`}
                glyph="add"
                type="primary"
                size={200}
                link="new"
                permission={`${permissionBase}.create`}
              />
            }
          >
            <PageSection.Title title={`${titleCase(category)} Templates`} />
          </PageSection.Header>

          <PageSection.Content>
            <DataTable
              asCard
              title="Treatment Plan Templates"
              icon="treatment_plans"
              columns={columns}
              filtersConfig={FILTERS_CONFIG}
              {...tableProps}
            />
          </PageSection.Content>
        </PageSection>
      </div>
    </>
  )
}

const FILTERS_CONFIG = {
  name: { label: 'Name', type: 'string' },
  client_tracks: DEFAULT_FILTERS.client_tracks,
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}

export default withPageError(TreatmentPlansTemplatesV2)
