import React from 'react'
import { animateScroll, scroller } from 'react-scroll'
import { v4 as uuid } from 'uuid'
import { validate } from '../Forms/validators'
import { withFormContext } from '../Forms/context'
import debounce from 'lodash/debounce'
import produce from 'immer'
import size from 'lodash/size'

import Button from '../Button'
import Divider from '../Divider'
import Section from '../Section'
import State from '../State'

import FieldBase from '../Forms/FieldBase'

import AgreementSection from './AgreementSection'
import snakeCase from 'lodash/snakeCase'

class AgreementSections extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = props.validations
    let value = props.value

    if (!value && props.model) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

      value = modelVal || initialModelVal || props.defaultValue || []
    } else if (props.defaultValue) {
      value = props.defaultValue
    }

    if (vs) errors = validate(value, vs)

    value = produce(value, (draft) => {
      for (let i = 0; i < draft?.length; i++) {
        draft[i].id = uuid()
      }
    })

    this.state = {
      type: 'AGREEMENT_SECTIONS',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isBlur: false,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.debouncedScroll = debounce(
      () =>
        animateScroll.scrollToBottom({
          containerId: 'overlay-content',
          isDynamic: true,
          duration: 200,
        }),
      500,
    )

    this.scrollToElement = (id) => {
      const element = document.getElementById(id)

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }

    this.updateType = 'DATA'
  }

  addNewSection = () => {
    const sectionId = uuid()

    const newValue = produce(this.state.value, (draft: any) => {
      draft.push({
        id: sectionId,
        name: `Section ${size(draft) + 1}`,
        should_confirm: false,
        content: '',
      })
    })

    this.changeValue(newValue)

    setTimeout(() => this.scrollToElement(sectionId), 0)
  }

  removeSection = (idx: number) => {
    if (size(this.state.value) === 0) return

    const newValue = produce(this.state.value, (draft: any) => {
      draft.splice(idx, 1)
    })

    this.changeValue(newValue)
  }

  updateSection = (idx: number, model: string, value: any) => {
    const newValue = produce(this.state.value, (draft: any) => {
      draft[idx][model] = value
    })

    this.changeValue(newValue)
  }

  moveSectionUp = (idx: number) => {
    if (idx === 0) return

    const newValue = produce(this.state.value, (draft: any) => {
      const toMove = draft[idx]

      draft.splice(idx, 1)
      draft.splice(idx - 1, 0, toMove)
    })

    this.changeValue(newValue)
  }

  moveSectionDown = (idx: number) => {
    if (idx >= size(this.state.value)) return

    const newValue = produce(this.state.value, (draft: any) => {
      const toMove = draft[idx]

      draft.splice(idx, 1)
      draft.splice(idx + 1, 0, toMove)
    })

    this.changeValue(newValue)
  }

  render = () => {
    const { value } = this.state

    const isEmpty = size(value) === 0

    if (!this.props.isEditable) {
      return value?.map((section: any, idx: number) => (
        <AgreementSection testKey={`agreement_${snakeCase(section.name)}`} key={section?.id} idx={idx} isEditable={false} data={section} />
      ))
    }

    return (
      <>
        {isEmpty ? (
          <State
            isEmpty
            icon="legal_agreement_alt"
            title="Sections"
            emptyDescription="Create the first agreement section"
            emptyActions={<Button type="default" label="Add First Section" glyph="add" onClick={this.addNewSection} />}
          />
        ) : (
          <div>
            {value?.map((section: any, idx: number) => {
              return (
                <AgreementSection
                  id={section?.id}
                  testKey={`agreement_${snakeCase(section.name)}`}
                  key={`${section?.id}-${idx}`}
                  idx={idx}
                  isEditable={this.props.isEditable}
                  data={section}
                  isFirst={idx === 0}
                  isLast={idx === size(value) - 1}
                  remove={this.removeSection}
                  moveUp={this.moveSectionUp}
                  moveDown={this.moveSectionDown}
                  onUpdate={this.updateSection}
                />
              )
            })}
          </div>
        )}

        {!isEmpty && (
          <Section>
            <Button type="default" label="Add New Section" glyph="add" onClick={this.addNewSection} display="inline-flex" />
          </Section>
        )}

        <Divider />
      </>
    )
  }
}

AgreementSections.defaultProps = {
  // maxWidth: 'initial',
  withHover: false,
  validateOn: 'blur-change',
}

export default withFormContext(AgreementSections)
