import { store } from '../setup/store'
import { isDefined, randomID } from '../utils/functions'

import { getAllAction, getAction, createAction, updateAction, destroyAction } from '../setup/storeConfig'

import { Request } from '../declarations/modules/api/Request'

const CACHE_TIME = 30 // in seconds

const getAll = (name, url) => {
  const action = getAllAction(name || randomID())

  return {
    type: action,
    request: { url, method: 'get' },
    meta: {
      cache: CACHE_TIME,
      cacheKey: url,
      reducer: 'data',
      name,
      origin: action,
    },
  }
}

const get = (name, url, reducer, opts, params) => {
  const action = getAction(name || randomID())

  return {
    type: action,
    request: { url, method: 'get', params: params },
    meta: {
      cache: isDefined(opts?.cache) ? opts?.cache : CACHE_TIME,
      cacheKey: url,
      name,
      reducer,
      origin: action,
    },
  }
}

export const create = (name, url, params, notify = true, headers = {}): any => {
  const action = createAction(name || randomID())

  return {
    type: action,
    request: {
      url,
      method: 'post',
      data: params,
      headers: { ...action.headers, ...headers },
    },
    meta: {
      notify: notify,
      reducer: 'data',
      name,
      origin: action,
    },
  }
}

export const update = (name, url, params, reducer, notify = true) => {
  const action = updateAction(name || randomID())

  return {
    type: action,
    request: {
      url,
      method: 'put',
      data: params,
    },
    meta: {
      notify: notify,
      reducer,
      name,
      origin: action,
    },
  }
}

export const destroy = (name, url, params, notify = true) => {
  const action = destroyAction(name || randomID())

  return {
    type: action,
    request: {
      url,
      method: 'delete',
      data: params,
    },
    meta: {
      notify: notify,
      reducer: 'data',
      name,
      origin: action,
    },
  }
}

export const apiGetAll: Request = ({ name, url, reducer = 'data' }) => store.dispatch(getAll(name, url, reducer))
export const apiGet: Request = ({ name, url, reducer = 'data', opts, params = null }) =>
  store.dispatch(get(name, url, reducer, opts, params))
export const apiCreate: Request = ({ name, url, params, notify = true, headers }) =>
  store.dispatch(create(name, url, params, notify, headers))
export const apiUpdate: Request = ({ name, url, params, reducer = 'data', notify = true }) =>
  store.dispatch(update(name, url, params, reducer, notify))
export const apiDelete: Request = ({ name, url, params, notify = true }) => store.dispatch(destroy(name, url, params, notify))
