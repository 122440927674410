import React from 'react'
import size from 'lodash/size'

import { useDataTable } from '../../../components/DataTable/useDataTable'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import FormSection from '../../../components/Forms/FormSection'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { AuthorityFormSubmissionOverlay } from '../../AuthorityFormSubmissions/AuthorityFormSubmissionOverlay'
import { FormSubmissionPageActions } from '../../FormSubmissions/FormSubmissionPageActions'
import { FormSubmissionsDataTable } from '../../FormSubmissions/FormSubmissionsDataTable'

export const FormSubmissionsStep = ({ organization, setStatus }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const [dataId, setDataId] = React.useState(null)
  const [newFormId, setNewFormId] = React.useState(null)

  const tableProps = useDataTable({
    name: ['organization', organization.id, 'form_submissions'],
    endpoint: `/organizations/${organization.id}/form_submissions`,
    updateDeleteEndpoint: `/form_submissions`,
    localStorageKey: 'form_submissions_v2',
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <FormSubmissionsDataTable
            asCard
            to={null}
            activeId={dataId}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'form_submissions.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
            onClick={(item: any) => {
              setDataId(item.id)
            }}
            {...tableProps}
          />

          <CardContent className="flex">
            <FormSubmissionPageActions
              showManageLink={false}
              buttonSize={200}
              reference={organization}
              allowedReference="organizations"
              onClick={(item: any) => {
                setNewFormId(item.id)
              }}
            />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        key={`id-${newFormId}`}
        isOpen={!!newFormId}
        onClose={() => {
          setNewFormId(null)
        }}
        overlay={
          <AuthorityFormSubmissionOverlay
            formId={newFormId}
            dataID="new"
            initialData={{
              reference: organization,
              reference_id: organization.id,
              reference_type: 'organization',
            }}
          />
        }
      />

      <SummonOverlay
        key={`id-${dataId}`}
        isOpen={!!dataId}
        onClose={() => {
          setDataId(null)
        }}
        overlay={<AuthorityFormSubmissionOverlay dataID={dataId} />}
      />
    </>
  )
}
