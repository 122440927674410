import React from 'react'

import { address, titleCase } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Chotomate from '../../components/Chotomate'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Status from '../../components/Status'
import Timeline from '../../components/Timeline/Timeline'
import Select from '../../components/Forms/Select'
import Option from '../../components/Forms/Option'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

const RootShiftNoteOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'shift-notes',
    endpoint: '/housing_notes',
    invalidate: 'shift-notes',
    options: props,
    openAfterCreate: true,
  })

  const { tenant, timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const isIncidentReport = data?.category === 'incident_report'
  const isTrialing = tenant?.plan_status === 'trialing'

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header
        icon="housing_notes"
        title="Shift Note"
        subtitle={!isEditable && data?.category && <Status label={titleCase(data.category)} color={isIncidentReport ? 'red' : 'blue'} />}
      />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/housing_notes/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Chotomate ready name="shift_note_overlay" />

        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection maxWidth="100%">
              <Select label="Status" model="status" layout="vertical-dense" defaultValue="draft">
                <Option label="Draft" value="draft" />
                <Option label="Pending Review" value="pending_review" />
                <Option label="Updates Required" value="updates_required" />
                <Option label="Signed Off" value="signed_off" />
                <Option label="Completed" value="completed" />
                <Option label="Closed" value="closed" />
              </Select>

              <RadioGroup
                model="category"
                label="Note Category"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select a category',
                  },
                }}
              >
                <Radio label="General Note" value="general_note" />
                <Radio label="Incident Report" value="incident_report" />
              </RadioGroup>

              {!isEditable && <ObjectSelector model="author" label="Written by" type="employees" isEditable={false} icon="employees" />}

              {!isEditable && <DateTimeInput model="created_at" label="Created At" />}

              {!isEditable && data?.updated_at !== data?.created_at && <DateTimeInput model="updated_at" label="Last Updated" />}

              {/* <SmartTextarea
                useQuickText
                useDictation
                model="description"
                label="Note"
                validations={{
                  presence: {
                    message: 'Please add a note description',
                  },
                }}
              /> */}

              <DateTimeInput
                defaultToNow
                label="Date & Time"
                model="dated_at"
                validations={{
                  presence: {
                    message: 'Please add a date & time',
                  },
                }}
              />

              <FormFutureDateWarning dateLabel="Date & Time" model="dated_at" />

              <MiniRichTextEditor
                useQuickText
                useDictation
                label="Note"
                model="description"
                validations={{
                  presence: {
                    message: 'Please add a note description',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="People">
            <FormSection maxWidth="100%" layout="vertical">
              <MultiOverlaySelector
                initialModelRequired={isNew}
                model="employees"
                label="Staff"
                type="employees.active"
                icon={ICONS.employees}
              />

              <MultiOverlaySelector
                initialModelRequired={isNew}
                model="residents"
                label="Clients"
                type="clients.current"
                icon={ICONS.clients}
                validations={{
                  presence: {
                    message: 'Please select at least one client',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Section headingType="h2" title="Notifications">
            <FormSection>
              <Alert glyph="notification">Send a notification alert via SMS / Email to the employees selected above</Alert>

              <CheckboxGroup layout="vertical-dense">
                <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
              </CheckboxGroup>
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Places">
            <FormSection maxWidth="100%" layout="vertical">
              <ObjectSelector
                initialModelRequired={isNew}
                model="house"
                label="Location"
                type="properties"
                icon={ICONS.locations}
                selectTitle={(data) => data.name}
                selectDescription={(data) => address(data.address)}
              />

              <ObjectSelector
                initialModelRequired={isNew}
                model="organization"
                label="Organization"
                type="organizations"
                icon={ICONS.organizations}
                selectTitle={(data) => data.name}
                selectDescription={(data) => address(data.address)}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this shift note">
            <FormSection maxWidth="100%" layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Shift Note"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="shift_notes.edit"
            />

            <DeleteDialog
              title="Delete Shift Note?"
              message="Are you sure you want to delete this shift note? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="shift_notes.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ShiftNoteOverlay = withOverlayError(RootShiftNoteOverlay)
