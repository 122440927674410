import React from 'react'

import ContextShow from '../ContextShow'
import Radio from '../Radio'
import RadioGroup from '../RadioGroup'
import Textarea from '../Textarea'

const YesNoRadioGroupExtra = ({
  isRequired,
  label,
  model,
  noLabel,
  textareaDescription,
  textareaLabel,
  textareaModel,
  textareaRequiredMessage,
  yesLabel,
  testKey,
  withHover,
}) => (
  <>
    <RadioGroup
      testKey={testKey}
      label={label}
      model={model}
      withHover={withHover}
      validations={
        isRequired && {
          presence: {
            message: 'Please select a response',
          },
        }
      }
      layout="horizontal-dense"
    >
      <Radio label={yesLabel} value={true} />
      <Radio label={noLabel} value={false} />
    </RadioGroup>

    <ContextShow when={model} is={true}>
      <Textarea
        testKey={`input_more_detail_${testKey}`}
        label={textareaLabel}
        description={textareaDescription}
        model={textareaModel || `${model}_extra`}
        validations={
          isRequired && {
            presence: {
              message: textareaRequiredMessage,
            },
          }
        }
      />
    </ContextShow>
  </>
)

YesNoRadioGroupExtra.defaultProps = {
  yesLabel: 'Yes',
  noLabel: 'No',
  textareaLabel: 'Please explain:',
  textareaRequiredMessage: 'Please explain',
}

export default YesNoRadioGroupExtra
