import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Form, Grid, Page, Radio, RadioGroup, HelpTagIframe } from '@behavehealth/components'
import { StaffAllowedIPsDataTable } from '@behavehealth/constructs/StaffIPRestrictions/StaffAllowedIPsDataTable'

const pageConfig = {
  feature: 'staff_ip_restrictions',
  title: 'IP Restrictions',
  help: <HelpTagIframe id="settings_staff_ip_restrictions" />,
}

const AllowedIPs: React.FC = () => {
  const { id }: any = useParams()

  const form = React.useRef()

  const { data: employee, isLoading: isLoadingEmployee }: any = useGet({
    name: ['employee', id],
    url: `/employees/${id}`,
  })

  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employee', id],
    url: `/employees/${id}`,
    invalidate: ['employees'],
  })

  const tableProps = useDataTable({
    name: ['employee', id, 'allowed_ips'],
    endpoint: `/employees/${id}/allowed_ips`,
    localStorageKey: 'employee_allowed_ips_v1',
    keepPreviousData: false,
  })

  if (!employee) return <Page isLoading {...pageConfig} />

  const usingIPWhitelist = employee.prefs?.use_ip_whitelist

  return (
    <Page
      actions={usingIPWhitelist && <Button as={Link} label="Add IP Restriction" type="primary" glyph="add" link="new" />}
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Form getForm={form} initialModel={{ prefs: employee.prefs }}>
          <RadioGroup
            label="Whitelist by IP"
            layout="vertical-dense"
            model="prefs.use_ip_whitelist"
            isDisabled={isLoadingEmployee}
            onChange={async ({ value }: any) =>
              await updateEmployee({
                prefs: {
                  ...employee.prefs,
                  use_ip_whitelist: value,
                },
              })
            }
          >
            <Radio label="No IP restrictions" value={false} />
            <Radio label="Yes, restrict by IP" value={true} />
          </RadioGroup>
        </Form>

        {usingIPWhitelist && <StaffAllowedIPsDataTable {...tableProps} mainLinkAs={NavLink} to={(row) => row.id} />}
      </Grid>
    </Page>
  )
}

export default withMarketing(AllowedIPs, pageConfig)
