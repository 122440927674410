import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { CUSTOM_NOTE_PORTAL_SETTINGS } from '../../utils/constants'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useCreate } from '../../hooks/useNewAPI'
import { useDataTable } from '../../components/DataTable/useDataTable'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

export const ClientCustomNotesImportOverlay = ({ onClose, referenceId, referenceType }) => {
  const [selectedRows, setSelectedRows]: any = React.useState([])

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    localStorageKey: 'custom_note_templates_v1',
    params: { variant: 'client' },
  })

  const { mutateAsync, isLoading: isCreating } = useCreate({
    name: ['custom_notes_import'],
    url: `/custom_notes/import`,
    invalidate: 'custom_notes',
  })

  const importRecords = async () => {
    if (!referenceId || !referenceType) return

    const custom_note_templates: any = {}

    for (const row of selectedRows) {
      custom_note_templates[row.id] = true
    }

    try {
      await mutateAsync({
        custom_note_templates,
        reference_id: referenceId,
        reference_type: referenceType,
      })

      onClose?.()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={100} onClose={onClose}>
      <Overlay.Header icon="custom_notes" title="Import Multiple Custom Notes" />

      <Overlay.Content>
        <Section margin="0 0 1.5rem 0">
          <Card className="!mt-4">
            <CustomNoteTemplatesDataTable canBatchSelect title="Custom Notes" {...tableProps} onRowSelectionUpdate={setSelectedRows} />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Custom Notes', selectedRowsCount)}` : 'Select Custom Notes to Import'}
          isDisabled={!hasSelectedRows}
          isLoading={isCreating}
          type="primary"
          color="green"
          onClick={importRecords}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const CustomNoteTemplatesDataTable = (props: any) => {
  const { to, title = 'Custom Note Templates', icon = 'custom_notes', mainLinkAs } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={mainLinkAs} id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 200,
        id: 'require_supervisor_signature',
        model: 'settings.require_supervisor_signature',
        title: 'Supervisor Signature',
        type: 'boolean',
      },
      {
        width: 200,
        id: 'require_reference_signature',
        model: 'settings.require_reference_signature',
        title: 'Client Signature',
        type: 'boolean',
      },
      {
        width: 200,
        model: 'portal_settings',
        title: 'Portal Settings',
        formatValue: ({ value }: any) => CUSTOM_NOTE_PORTAL_SETTINGS[value] || null,
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return <DataTable asCard testKey="custom_notes_data_table" title={title} icon={icon} columns={columns} {...props} />
}
