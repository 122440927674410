import { AddressCell } from './cells/AddressCell'
import { AmountCell } from './cells/AmountCell'
import { BooleanCell } from './cells/BooleanCell'
import { ColorCell } from './cells/ColorCell'
import { DateCell } from './cells/DateCell'
import { DateTimeCell } from './cells/DateTimeCell'
import { EmailCell } from './cells/EmailCell'
import { ExternalUrlCell } from './cells/ExternalUrlCell'
import { GlobalTagsCell } from './cells/GlobalTagsCell'
import { GlyphCell } from './cells/GlyphCell'
import { HoverCell } from './cells/HoverCell'
import { HubspotCommunityProfileCell } from './cells/HubspotCommunityProfileCell'
import { HubspotCompanyCell } from './cells/HubspotCompanyCell'
import { HubspotContactCell } from './cells/HubspotContactCell'
import { HubspotDealCell } from './cells/HubspotDealCell'
import { HubspotEHRAccountCell } from './cells/HubspotEHRAccountCell'
import { NumberCell } from './cells/NumberCell'
import { PercentageCell } from './cells/PercentageCell'
import { PhoneCell } from './cells/PhoneCell'
import { ProfileCell } from './cells/ProfileCell'
import { ProfilesCell } from './cells/ProfilesCell'
import { RecordClientTracksCell } from './cells/RecordClientTracksCell'
import { RecordOrganizationTracksCell } from './cells/RecordOrganizationTracksCell'
import { RichTextCell } from './cells/RichTextCell'
import { SelectCell } from './cells/SelectCell'
import { SmartFlagsCell } from './cells/SmartFlagsCell'
import { TagsCell } from './cells/TagsCell'
import { TimeCell } from './cells/TimeCell'
import { TitleCell } from './cells/TitleCell'

export const CELLS: any = {
  address: {
    width: 100,
    component: AddressCell,
  },
  amount: {
    width: 100,
    component: AmountCell,
  },
  boolean: {
    width: 100,
    component: BooleanCell,
  },
  color: {
    width: 60,
    component: ColorCell,
  },
  date_time: {
    width: 180,
    component: DateTimeCell,
  },
  date: {
    width: 120,
    component: DateCell,
  },
  email: {
    width: 200,
    component: EmailCell,
  },
  external_url: {
    width: 200,
    component: ExternalUrlCell,
  },
  global_tags: {
    width: 300,
    component: GlobalTagsCell,
  },
  glyph: {
    width: 60,
    component: GlyphCell,
  },
  number: {
    width: 120,
    component: NumberCell,
  },
  percentage: {
    width: 120,
    component: PercentageCell,
  },
  phone: {
    width: 150,
    component: PhoneCell,
  },
  profile: {
    width: 200,
    component: ProfileCell,
    disableSelect: true,
  },
  profiles: {
    width: 240,
    component: ProfilesCell,
    disableSelect: true,
  },
  popover: {
    width: 200,
    component: HoverCell,
  },
  rich_text: {
    width: 200,
    component: RichTextCell,
  },
  select: {
    width: 200,
    component: SelectCell,
  },
  smart_flags: {
    width: 300,
    component: SmartFlagsCell,
  },
  tags: {
    width: 240,
    component: TagsCell,
    disableSelect: true,
  },
  record_client_tracks: {
    width: 240,
    component: RecordClientTracksCell,
    disableSelect: true,
  },
  record_organization_tracks: {
    width: 240,
    component: RecordOrganizationTracksCell,
    disableSelect: true,
  },
  time: {
    width: 200,
    component: TimeCell,
  },
  title: {
    width: 200,
    component: TitleCell,
  },
  hubspot_company: {
    width: 200,
    component: HubspotCompanyCell,
  },
  hubspot_community_profile: {
    width: 200,
    component: HubspotCommunityProfileCell,
  },
  hubspot_deal: {
    width: 200,
    component: HubspotDealCell,
  },
  hubspot_contact: {
    width: 200,
    component: HubspotContactCell,
  },
  hubspot_ehr_account: {
    width: 200,
    component: HubspotEHRAccountCell,
  },
}
