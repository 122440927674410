import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DEFAULT_FILTERS } from '../Filters/constants'
import { niceAmount } from '../../utils/functions'
import { PRICING_STRATEGIES, PRICING_UNIT_TYPES } from '../../components/Billing/constants'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

const TYPES: any = {
  plan: 'Plan',
  addon: 'Add-On',
  service: 'Service',
  platform_plan: 'Platform Plan',
}

export const AdminProductTemplatesDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            as={NavLink}
            id={data.id}
            value={data.name}
            icon={data?.data?.icon}
            to={props.to ? props.to(data) : undefined}
            onClick={props.onClick ? () => props.onClick(data) : undefined}
          />
        ),
      },
      {
        title: 'Status',
        model: 'status',
        type: 'title',
        width: 120,
      },
      {
        title: 'Type',
        model: 'subcategory',
        formatValue: ({ value }: any) => TYPES?.[value] || null,
        width: 120,
      },
      {
        title: 'SKU',
        id: 'sku',
        model: 'data.sku',
      },
      {
        title: 'Tags',
        model: 'global_tags',
        type: 'global_tags',
        editPermission: true,
      },
      {
        title: 'Smart Flags',
        model: 'smart_flags',
        type: 'smart_flags',
        disableSort: true,
        editPermission: true,
        width: 300,
      },
      {
        title: 'Filter 1',
        id: 'filter_1',
        model: 'data.dynamic_filters.filter_1',
      },
      {
        title: 'Filter 2',
        id: 'filter_2',
        model: 'data.dynamic_filters.filter_2',
      },
      {
        title: 'Filter 3',
        id: 'filter_3',
        model: 'data.dynamic_filters.filter_3',
      },
      {
        title: 'Description',
        id: 'description',
        model: 'data.description',
        type: 'rich_text',
        width: 300,
      },
      {
        title: 'Public Name',
        id: 'public_name',
        model: 'data.public_name',
      },
      {
        title: 'Price',
        id: 'price',
        model: 'data.price',
        width: 120,
        formatValue: ({ value }: any) => (value ? (value < 1 ? `$${value}` : niceAmount(value)) : null),
      },
      {
        title: 'Pricing Strategy',
        id: 'pricing_type',
        model: 'data.pricing_type',
        formatValue: ({ value }: any) => PRICING_STRATEGIES?.[value] || null,
      },
      {
        title: 'Unit Type',
        id: 'price_unit_type',
        model: 'data.price_unit_type',
        formatValue: ({ value }: any) => PRICING_UNIT_TYPES?.[value] || null,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return <DataTable asCard title="Product Templates" testKey="product_templates_data_table" icon="certificate" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  subcategory: {
    label: 'Type',
    type: 'multi_select',
    options: [
      { label: 'Plan', value: 'plan' },
      { label: 'Add-On', value: 'addon' },
      { label: 'Service', value: 'service' },
      { label: 'Platform Plan', value: 'platform_plan' },
    ],
  },
  sku: {
    label: 'SKU',
    type: 'string',
  },
  global_tags: DEFAULT_FILTERS.global_tags,
  smart_flags: {
    label: 'Smart Flags',
    type: 'smart_flags',
    glyph: 'flag',
  },
  filter_1: {
    label: 'Filter 1',
    type: 'string',
  },
  filter_2: {
    label: 'Filter 2',
    type: 'string',
  },
  filter_3: {
    label: 'Filter 3',
    type: 'string',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  public_name: {
    label: 'Public Name',
    type: 'string',
  },
  price: {
    label: 'Price',
    type: 'number',
  },
  pricing_type: {
    label: 'Pricing Strategy',
    type: 'multi_select',
    options: Object.entries(PRICING_STRATEGIES).map(([value, label]) => ({ value, label })),
  },
  price_unit_type: {
    label: 'Unit Type',
    type: 'multi_select',
    options: Object.entries(PRICING_UNIT_TYPES).map(([value, label]) => ({ value, label })),
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
