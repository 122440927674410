import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const STATUSES = {
  active: {
    label: 'Active',
    color: 'green',
  },
  inactive: {
    label: 'Inactive',
    color: 'gray',
  },
}

export const AuthorityDirectoryStatus = ({ ...rest }) => {
  return <SmartStatus statuses={STATUSES} {...rest} />
}
