import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import StaffPermissionsList from '../../components/Permissions/StaffPermissionsList'

const RootPermissionTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'permission_templates',
    endpoint: '/permission_templates',
    invalidate: 'permission_templates',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={55} />
  }

  return (
    <Overlay showBackdrop={isEditable} position="right" maxWidth={55} onClose={close}>
      <Overlay.Header icon="permissions" title="Permissions Template" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection maxWidth="100%">
              <Input
                autoFocus
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a permission template name',
                  },
                }}
              />

              <Card>
                <StaffPermissionsList model="data" />
              </Card>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save Template"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={true}
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              testKey="edit_permissions_template_button"
              label="Edit List"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.permissions.edit"
            />

            <DeleteDialog
              title="Delete Permissions Template?"
              message="Are you sure you want to delete this template? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                testKey="delete_permissions_template_button"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="settings.permissions.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const PermissionTemplateOverlay = withOverlayError(RootPermissionTemplateOverlay)
