import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Card from '@behavehealth/components/Card'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import CardContent from '@behavehealth/components/CardContent'
// import GlobalDnDUploader from '@behavehealth/constructs/Organizations/GlobalDnDUploader'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import DnDUploader from '@behavehealth/components/DnDUploader'

import FileStatus from '@behavehealth/components/Statuses/FileStatus'

import { useGet } from '@behavehealth/hooks/useNewAPI'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const RootFiles = () => {
  const params: any = useParams()
  const match: any = useRouteMatch()
  const { resource_id: id } = params

  const customHeaders = {
    'X-Request-Level': 'global',
  }

  const { data: organization, isLoading }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
    headers: customHeaders
  })

  const tableProps: any = useDataTable({
    name: ['organization', id, 'documents'],
    endpoint: `/organizations/${id}/documents`,
    headers: customHeaders
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            value={value}
            to={{
              pathname: `${match.url}/${data.id}`,
              parent: match,
            }}
          />
        ),
      },
      {
        width: 70,
        model: 'upload_content_type',
        title: 'Type',
        formatValue: ({ value }: any) => <FileStatus status={value} />,
      },
      // {
      //   width: 160,
      //   model: 'file_tags',
      //   title: 'Tags',
      //   formatValue: ({ value }: any) => {
      //     if (!value || value.length === 0) return '–'
      //     return value.map((tag: any) => tag.name).join(', ')
      //   },
      // },
      {
        width: 160,
        model: 'notes',
        title: 'Notes',
      },
      {
        width: 160,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 160,
        model: 'uploaded_by.name',
        title: 'Added By',
      },
      {
        width: 160,
        model: 'uploaded_by_partner.name',
        title: 'Added By Organization',
      },
    ],
    [],
  )

  if (!organization || isLoading) return <PageLoader />

  return (
    <Page title="Files" icon="files">
      <div className="grid grid-cols-[100%]">
        <Card>
          <DataTable {...tableProps} icon="files" title="Files" columns={columns} />

          <CardContent>
            <DnDUploader
              category="general"
              resource={organization}
              headers={customHeaders}
              queryKey={['organization', id, 'documents']}
              isEditable={true}
              isDisabled={false}
            />
          </CardContent>
        </Card>
      </div>
    </Page>
  )
}

export const Files = withPageError(RootFiles)
