import React from 'react'

import Flex from './Flex'
import { OrganizationStatusGroupStatus } from '../constructs/Organizations/OrganizationStatusGroupStatus'

import { Text } from './Typography'

const OrganizationProfileHeader = ({ organization }) => {
  if (!organization) return null

  return (
    <Flex gap={6} alignItems="center">
      <Text avatar={organization.avatar} label={organization.name} />
      <OrganizationStatusGroupStatus small status={organization.status_group} />
    </Flex>
  )
}

export default OrganizationProfileHeader
