import React from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { titleCase } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Divider from '../../components/Divider'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'
import State from '../../components/State'
import SummonOverlay from '../../components/SummonOverlay'

import { FormsImportOverlay } from '../FormBuilder/FormsImportOverlay'
import { ClientTrackTabs } from '../ClientTracks/ClientTrackTabs'
import { OrganizationTrackTabs } from '../Organizations/OrganizationTrackTabs'

const groupData = (data: any) => {
  const groups: any = {}
  const other: any = []

  if (size(data) === 0) return { groups, other }

  for (const item of data) {
    if (item.category) {
      if (!groups[item.category]) groups[item.category] = []

      groups[item.category].push(item)
    } else {
      other.push(item)
    }
  }

  return { groups, other }
}

const CLIENT_REFERENCES = ['admissions', 'past_clients', 'current_clients']

export const FormSubmissionPageActions = (props: any) => {
  const { data, reference, allowedReference, onClick, showManageLink = true, buttonSize = 300 } = props

  const location = useLocation()
  const match = useRouteMatch()

  const isClientReference = CLIENT_REFERENCES.includes(allowedReference) && !!reference?.client_track
  const isOrganizationReference = allowedReference === 'organizations' && !!reference?.organization_track

  const [clientTrackId, setClientTrackId] = React.useState(reference?.client_track?.id)
  const [organizationTrackId, setOrganizationTrackId] = React.useState(reference?.organization_track?.id)

  const [importOverlayOpen, setImportOverlayOpen] = React.useState(false)

  const params = {
    ...(allowedReference && { allowed_references: allowedReference }),
    ...(isClientReference && { client_track_id: clientTrackId }),
    ...(isOrganizationReference && { organization_track_id: organizationTrackId }),
  }

  const { data: forms, isLoading }: any = useGet({
    name: ['forms', params],
    url: `/forms`,
    params,
  })

  const { groups, other } = groupData(forms)

  const isEmpty = size(forms) === 0

  React.useEffect(() => {
    if (!isClientReference) return

    setClientTrackId(reference?.client_track?.id)
  }, [isClientReference])

  React.useEffect(() => {
    if (!isOrganizationReference) return

    setOrganizationTrackId(reference?.organization_track?.id)
  }, [isOrganizationReference])

  return (
    <>
      {showManageLink && (
        <Button
          label="Manage Forms"
          glyph="settings"
          type="minimal"
          link="/settings/forms-builder"
          featureFlagV2="forms_builder"
          permission="forms_builder.view"
        />
      )}

      <Dropdown label="Add New Form" glyph="add" buttonType="primary" permission="form_submissions.create" buttonSize={buttonSize}>
        <DropdownItem
          glyph="documents"
          label="Import Multiple Forms…"
          onClick={() => setImportOverlayOpen(true)}
          css={STYLES.dropdownItem}
          color="blue"
        />

        <Divider />

        {!forms || isEmpty || isLoading ? (
          <State
            isEmpty={isEmpty}
            isLoading={isLoading}
            minHeight={100}
            icon="web_form"
            emptyDescription={
              allowedReference
                ? `There are no forms available to create submissions for ${titleCase(allowedReference)}`
                : 'There are no forms available to create a submission'
            }
          />
        ) : (
          <>
            {isClientReference && (
              <ClientTrackTabs clientTrack={reference?.client_track} activeId={clientTrackId} setActiveId={setClientTrackId} />
            )}

            {isOrganizationReference && (
              <OrganizationTrackTabs
                organizationTrack={reference?.organization_track}
                activeId={organizationTrackId}
                setActiveId={setOrganizationTrackId}
              />
            )}

            {Object.keys(groups).map((groupName: any) => {
              const items = groups[groupName]

              return (
                <div key={groupName} className="border-b border-0 border-solid border-divider">
                  <div className="px-4 pt-3 pb-1 text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-80">
                    {groupName}
                  </div>
                  {items.map((item: any) => (
                    <DropdownItem
                      key={item.id}
                      icon="web_form"
                      label={item.name}
                      link={
                        onClick
                          ? undefined
                          : {
                              pathname: `${location.pathname}/new-${item.id}`,
                              parent: match,
                              data,
                            }
                      }
                      onClick={onClick ? () => onClick(item) : undefined}
                      css={STYLES.dropdownItem}
                    />
                  ))}
                </div>
              )
            })}

            {size(other) > 0 && (
              <>
                <div className="">
                  {size(groups) > 0 && (
                    <div className="px-4 pt-3 pb-1 text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-80">
                      Other
                    </div>
                  )}

                  {other.map((item: any) => (
                    <DropdownItem
                      key={item.id}
                      icon="web_form"
                      label={item.name}
                      link={
                        onClick
                          ? undefined
                          : {
                              pathname: `${location.pathname}/new-${item.id}`,
                              parent: match,
                              data,
                            }
                      }
                      onClick={onClick ? () => onClick(item) : undefined}
                      css={STYLES.dropdownItem}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </Dropdown>

      <SummonOverlay
        isOpen={importOverlayOpen}
        onClose={() => setImportOverlayOpen(false)}
        overlay={
          <FormsImportOverlay
            referenceId={reference?.id || data?.reference?.id}
            referenceType={reference?.type || data?.reference?.type}
            allowedReference={allowedReference}
          />
        }
      />
    </>
  )
}

const STYLES = {
  dropdownItemWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  dropdownItem: {
    flex: '1 1 auto',
    borderRight: `1px solid ${COLORS.divider}`,
  },
}
