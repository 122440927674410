export const FILING_UNDER_OPTIONS = [
  {
    label: 'Community',
    resources: [
      {
        label: 'Organizations',
        type: 'organizations',
        feature: 'organizations',
      },
      {
        label: 'Organization Contacts',
        type: 'organization_contacts',
        feature: 'contacts',
      },
    ],
  },
  {
    label: 'Company',
    resources: [
      {
        label: 'Staff',
        type: 'staff',
        feature: 'employees',
      },
    ],
  },
] as const
