import React from 'react'
import clsx from 'clsx'

import { formatURL } from '../../utils/functions'

import Button from '../../components/Button'
import State from '../../components/State'

import { getCustomPageIcon } from './functions'

export const CustomPageIframe = ({ data, className }: any) => {
  const [isVisible, setIsVisible] = React.useState(true)

  const pageIcon = getCustomPageIcon(data)

  const showIframe = () => {
    setIsVisible(true)
  }

  React.useEffect(() => {
    if (data?.loading_strategy === 'on_click') {
      setIsVisible(false)
    }
  }, [data?.loading_strategy])

  if (!data) return null

  if (!isVisible) {
    return (
      <State
        isEmpty
        icon={pageIcon}
        title={data?.name || 'Iframe'}
        emptyDescription={null}
        emptyActions={<Button label="Load Content" glyph="in_progress" size={200} onClick={showIframe} />}
      />
    )
  }

  return (
    <iframe
      src={formatURL(data.url)}
      frameBorder="0"
      className={clsx('w-full min-h-[90vh]', className)}
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
    />
  )
}
