import React from 'react'

import { AGREEMENTS } from '../../utils/constants'
import { COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AgreementSections from '../../components/Elements/AgreementSections'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SignAgreementOverlay from '../../components/Overlays/pages/Agreements/SignAgreementOverlay'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'

import { ClientTracksMultiObjectSelector } from '../../components/Forms/elements/ClientTracksMultiObjectSelector'

const PreviewLink = ({ onClick }) => {
  return (
    <div onClick={onClick} css={{ display: 'flex', alignItems: 'center' }}>
      <Glyph glyph="view" size={16} css={{ marginRight: '0.25rem' }} />
      <span
        css={{
          fontWeight: 500,
          color: COLORS.blue,
          cursor: 'pointer',
          '&:hover': { textDecoration: 'underline' },
        }}
      >
        Preview Agreement
      </span>
    </div>
  )
}

const RootClientAgreementTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'agreement_templates',
    endpoint: '/agreement_templates',
    invalidate: 'agreement_templates',
    options: props,
  })

  const { allowEditing = true } = props

  const { timezone } = useSettings()

  const [formData, setFormData] = React.useState(null)

  const onPreviewOpen = () => {
    setFormData(form.current?.getFormValue())
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={82} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable} maxWidth={82}>
      <Overlay.Header
        title={data?.name || 'New Client Agreement Template'}
        icon="legal_agreement_alt"
        description={
          <SummonOverlay onOpen={onPreviewOpen} overlay={<SignAgreementOverlay isPreviewMode showBackdrop agreement={formData} />}>
            <PreviewLink />
          </SummonOverlay>
        }
      />

      <Overlay.Content>
        <Form
          useFullModel
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={{
            template_type: 'custom',
            variant: 'client',
          }}
        >
          <Section title="Agreement Details" headingType="h2">
            <FormSection maxWidth="100%">
              <Flex gap="1rem">
                <Input
                  autoFocus
                  label="Agreement Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a template name',
                    },
                  }}
                  className="!grow-[2]"
                />

                <Input
                  label="Order"
                  type="number"
                  model="order"
                  tooltip="Add a number to set the order of the agreement in the list"
                  size={4}
                />
              </Flex>

              <ClientTracksMultiObjectSelector />

              <FormSection horizontal maxWidth="100%">
                <Select
                  allowEmpty
                  css={{ flexGrow: 1, flexBasis: 300 }}
                  label="Category"
                  model="category"
                  validations={{
                    presence: {
                      message: 'Please select a category',
                    },
                  }}
                >
                  {Object.keys(AGREEMENTS).map((option) => (
                    <Option label={AGREEMENTS[option]} value={option} />
                  ))}
                </Select>

                <ContextShow when="category" is="other">
                  <div css={{ flexGrow: 1, flexBasis: 300 }}>
                    <Input label="Other Category Name" model="category_other" />
                  </div>
                </ContextShow>
              </FormSection>

              <Select label="Review Cycle" model="review_cycle">
                <Option label="Daily" value="daily" />
                <Option label="Weekly" value="weekly" />
                <Option label="Monthly" value="monthly" />
                <Option label="Quarterly" value="quarterly" />
                <Option label="Semi-annually" value="semi_annually" />
                <Option label="Annually" value="annually" />
              </Select>

              <Textarea useQuickText label="Notes" model="notes" className="!grow !basis-[300px] !self-start" />
            </FormSection>
          </Section>

          <Divider />

          <FormSection maxWidth="100%">
            <div>
              <AgreementSections
                model="sections"
                validations={{
                  presence: {
                    message: 'Please add at least one section',
                  },
                }}
              />
            </div>
          </FormSection>

          <Section
            title="Staff"
            headingType="h2"
            aside={
              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                <Checkbox
                  label="Require Staff signature"
                  model="request_cosigner_signature"
                  // onUpdate={(state) => this.setState({ requestCoSignerSignature: state.value })}
                />
              </CheckboxGroup>
            }
          />

          <Divider />

          <Section
            title="Signees"
            headingType="h2"
            aside={
              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                <Checkbox
                  label="Require Signees signature"
                  model="should_sign"
                  // onUpdate={(state) => this.setState({ shouldSign: state.value })}
                />
              </CheckboxGroup>
            }
          />

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {allowEditing && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save Agreement"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 240px"
              />

              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                label="Edit"
                glyph="edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="settings.agreement_templates.edit"
              />

              <DeleteDialog
                title="Delete Agreement?"
                message="Are you sure you want to delete this agreement? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="settings.agreement_templates.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const ClientAgreementTemplateOverlay = withOverlayError(RootClientAgreementTemplateOverlay)
