import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import FileStatus from '../../components/Statuses/FileStatus'

export const FilesDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'title',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell as={NavLink} id={data.id} to={props.to ? props.to(data) : data.id} value={value || data?.upload_name} />
        },
      },
      {
        title: 'Type',
        model: 'content_type',
        formatValue: ({ value }: any) => <FileStatus small status={value} />,
      },
      {
        title: 'File Tags',
        model: 'file_tags',
        formatValue: ({ value }: any) => {
          if (!value || value.length === 0) return <DataTable.EmptyCell />
          return value.map((tag: any) => tag.name).join(', ')
        },
      },
      {
        title: 'Notes',
        model: 'notes',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      // {
      //   title: 'Added By',
      //   model: 'author',
      //   type: 'profile',
      //   disableSort: true,
      // },
    ],
    [],
  )

  return <DataTable title="Files" icon="files" columns={columns} filtersConfig={FILTERS.files} {...props} />
}
