import React from 'react'

import { CLEARING_HOUSES } from '../../utils/constants'
import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { usDateTime } from '../../utils/functions'

import EVOBStatus from '../../components/Statuses/EVOBStatus'
import useSettings from '../../hooks/useSettings'
import EVOBCoverageStatus from '../../components/Statuses/EVOBCoverageStatus'

export const EVOBDataTable = (props: any) => {
  const { to, ...rest } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Date Requested',
        model: 'created_at',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={to?.(data)} value={usDateTime(value, timezone)} />
        },
      },
      {
        title: 'eVOB Status',
        model: 'status',
        formatValue: ({ value }: any) => <EVOBStatus small status={value} />,
      },
      {
        title: 'Clearing House',
        model: 'clearing_house',
        formatValue: ({ value }: any) => CLEARING_HOUSES?.[value] || null,
      },
      {
        id: 'coverage_status',
        title: 'Coverage Status',
        model: 'insurance_eligibility_summary.plan_coverage_summary.status',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <EVOBCoverageStatus status={value} />
        },
      },
      {
        title: 'Date of Service',
        model: 'date_of_service',
      },
      {
        title: 'Insurance Payer',
        model: 'payer_name',
      },
    ],
    [to],
  )

  return <DataTable asCard title="eVOBs" icon="financials" columns={columns} filtersConfig={FILTERS.evobs} {...rest} />
}
