import React from 'react'

import { ClientCustomNoteTemplatesDataTable } from '@behavehealth/constructs/CustomNoteTemplates/ClientCustomNoteTemplatesDataTable'
import { Page, Grid } from '@behavehealth/components'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'client_custom_note_templates',
  title: 'Client Custom Note Templates',
}

const ClientCustomNoteTemplates = () => {
  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    params: { variant: 'client' },
    localStorageKey: 'client_custom_note_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Grid>
        <ClientCustomNoteTemplatesDataTable to={(row: any) => row.id} {...tableProps} />
      </Grid>
    </Page>
  )
}

export default withMarketing(ClientCustomNoteTemplates, pageConfig)
