import React from 'react'

import Alert from '@behavehealth/components/Alert'

export const StaffDataFormsAlert = ({ name = 'records' }: any) => {
  return (
    <Alert small contrast glyph="warning" type="warning">
      <b>Please note:</b> currently, new {name} can only be created from the corresponding client page. You can view and update existing
      records here.
    </Alert>
  )
}
