import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

export const AuthorizationsDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Authorized Service Episodes',
        model: 'service_episodes',
        width: 350,
        disableHide: true,
        disableSort: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            value={!value || value?.length === 0 ? '–' : value?.map?.((o: any) => o?.name)?.join(', ')}
            to={to?.(data)}
          />
        ),
      },
      {
        title: 'Authorization #',
        model: 'identifier',
      },
      {
        title: 'Sessions Authorized',
        model: 'sessions',
      },
      {
        title: 'Sessions Used',
        model: 'used_sessions',
      },
      {
        title: 'Notes',
        model: 'notes',
      },
      {
        title: 'Started On',
        model: 'started_at',
        type: 'date_time',
      },
      {
        title: 'Ending On',
        model: 'ended_at',
        type: 'date_time',
      },
      {
        title: 'Date Added',
        width: 200,
        id: 'created_at',
        model: 'created_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Last Updated',
        width: 220,
        id: 'updated_at',
        model: 'updated_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Authorizations"
      icon="authorizations"
      columns={columns}
      filtersConfig={FILTERS.insurance_authorizations}
      {...rest}
    />
  )
}
