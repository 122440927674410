import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { DataExportsDataTable } from '@behavehealth/constructs/DataExports/DataExportsDataTable'

const pageConfig = {
  feature: 'data_exports',
}

const DataExports = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const actions = (
    <Button label="Add Data Export" glyph="add" type="primary" link={`${match.url}/new`} permission="clients.actions.export" />
  )

  const [shouldPoll, setShouldPoll] = React.useState(false)

  const { data, isLoading, refetch, isRefetching }: any = useGet({
    name: ['client', resource_id, 'data-exports'],
    url: `/residents/${resource_id}/data_exports`,
    options: shouldPoll && { refetchInterval: 5 * 1000, refetchOnMount: true, staleTime: 0 },
  })

  const tableProps = useDataTable({
    name: ['client', resource_id, 'data-exports'],
    endpoint: `/residents/${resource_id}/data_exports`,
    enabled: !!resource_id,
    localStorageKey: 'client_data_exports_v1',
  })

  const isEmpty = size(data) == 0

  React.useEffect(() => {
    if (isEmpty) return null

    for (const item of data) {
      if (item.status === 'processing') {
        setShouldPoll(true)
        break
      }

      setShouldPoll(false)
    }
  }, [data, isEmpty])

  return (
    <Page breakpoint="0" actions={actions} {...pageConfig}>
      <div className="grid grid-cols-[100%]">
        <DataExportsDataTable
          headerAfter={
            <>
              <div>
                <Button label="Reload" size={100} glyph="reset" isLoading={isLoading || isRefetching || shouldPoll} onClick={refetch} />
              </div>
            </>
          }
          to={(row: any) => `${match.url}/${row.id}`}
          {...tableProps}
        />
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(DataExports, pageConfig))
