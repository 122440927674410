import React from 'react'
import isFunction from 'lodash/isFunction'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import Flex from '../Flex'
import Tooltip from '../Tooltip'
import RadioCheckElement from '../Forms/RadioCheckElement'

import { useStore } from './useStore'
import { DataTableActionButton } from './DataTableActionButton'

const DEFAULT_ACTION_TYPES: any = {
  delete: {
    glyph: 'delete',
    color: 'red',
    label: (length: number) => `Delete${length ? ` (${length})` : ''}`,
  },
  employee_sign_off: {
    glyph: 'signature',
    color: 'blue',
    label: (length: number) => `Staff Signature${length ? ` (${length})` : ''}`,
  },
  sign_off: {
    glyph: 'signature',
    color: 'green',
    label: (length: number) => `Sign Off As Supervisor${length ? ` (${length})` : ''}`,
  },
  data_form_send_for_review: {
    glyph: 'signature',
    color: 'blue',
    label: (length: number) => `Sign & Send for Review${length ? ` (${length})` : ''}`,
  },
  close: {
    glyph: 'close',
    color: 'green',
    label: (length: number) => `Close${length ? ` (${length})` : ''}`,
  },
  send_for_review: {
    glyph: 'check',
    color: 'green',
    label: (length: number) => `Send for Review${length ? ` (${length})` : ''}`,
  },
  request_updates: {
    glyph: 'note',
    color: 'blue',
    label: (length: number) => `Request Updates${length ? ` (${length})` : ''}`,
  },
  batch_edit: {
    glyph: 'edit',
    color: 'blue',
    label: (length: number) => `Batch Edit${length ? ` (${length})` : ''}`,
  },
}

export const DataTableActions = (props: any) => {
  const { children } = props

  const canBatchSelect: any = useStore((state: any) => state.canBatchSelect)
  const batchActionsConfig: any = useStore((state: any) => state.batchActionsConfig)
  const dataMap: any = useStore((state: any) => state.dataMap)
  const selectAll: any = useStore((state: any) => state.selectAll)
  const selectedIds: any = useStore((state: any) => state.selectedIds)
  const selectNone: any = useStore((state: any) => state.selectNone)
  const getCanSelect: any = useStore((state: any) => state.getCanSelect)
  const renderBatchActions: any = useStore((state: any) => state.renderBatchActions)

  const selectedRowsCount = size(selectedIds)

  const isAllSelected = React.useMemo(() => {
    if (size(dataMap) === 0 || size(selectedIds) === 0) return false

    let result = true

    for (const id in dataMap) {
      if (!selectedIds.includes(id)) {
        result = false
        break
      }
    }

    return result

    // return
  }, [dataMap, selectedIds])

  const isSomeSelected = selectedRowsCount >= 1

  const selectedRows = React.useMemo(() => {
    const result: any = []

    if (size(selectedIds) === 0) return result

    for (const id of selectedIds) {
      result.push(dataMap[id])
    }

    return result
  }, [dataMap, selectedIds])

  const toggleSelected = () => {
    isAllSelected ? selectNone() : selectAll()
  }

  const hasBatchActions = size(batchActionsConfig) > 0

  if (!canBatchSelect && !hasBatchActions) return null

  return (
    <div css={STYLES.root}>
      <div css={STYLES.checkbox} onClick={toggleSelected}>
        <RadioCheckElement
          type="checkbox"
          size={15}
          className="table-row-checkbox"
          isChecked={isAllSelected}
          isIndeterminate={isSomeSelected && !isAllSelected}
        />
      </div>

      <div css={STYLES.content}>
        <div css={STYLES.label} onClick={toggleSelected}>
          {isAllSelected ? 'Select None' : 'Select All'}
        </div>

        <Tooltip show={!isSomeSelected} content="Select one or more rows to enable batch actions">
          <Flex centerY gap="0.1rem">
            {isFunction(renderBatchActions) && renderBatchActions({ selectedIds, selectedRows, selectNone, isDisabled: !isSomeSelected })}

            {batchActionsConfig?.map((action: any, index: number) => (
              <DataTableActionButton
                testKey={`${action.type}_action_button`}
                key={`action-${action.type}-${index}`}
                label={action.label?.(selectedRowsCount) || DEFAULT_ACTION_TYPES?.[action.type].label?.(selectedRowsCount)}
                count={selectedRowsCount}
                glyph={action?.glyph || DEFAULT_ACTION_TYPES?.[action.type]?.glyph}
                color={action?.color || DEFAULT_ACTION_TYPES?.[action.type]?.color}
                type={action.type}
                buttonType={action.buttonType}
                permission={action.permission}
                config={action.config}
                selectedRows={selectedRows}
                action={async (data: any) => {
                  await action?.action({ ids: selectedIds, data: data, selectedRows: selectedRows })
                  !action?.keepSelected && selectNone()
                }}
                isDisabled={!isSomeSelected}
              />
            ))}
          </Flex>
        </Tooltip>

        {children}
      </div>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'var(--row-min-height)',
    flex: '0 0 auto',
    background: COLORS.white,

    '.DataTableCell': {
      fontWeight: 600,
    },
  },

  label: {
    fontWeight: 600,
    cursor: 'pointer',
    fontSize: '0.95em',
    marginRight: '1rem',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 1 auto',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'var(--checkbox-width)',
    alignSelf: 'stretch',
    paddingLeft: 'var(--padding-x)',
    paddingRight: 'var(--padding-x)',
    cursor: 'pointer',
    flex: '0 0 auto',
  },
}
