import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { css, COLORS, HARD_SHADOW } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import State from '../../components/State'
import Status from '../../components/Status'
import Tooltip from '../../components/Tooltip'

import { useTreatmentPlanPermissions } from '../../constructs/TreatmentPlans/useTreatmentPlanPermissions'

const RootTreatmentPlanSelectorOverlay = (props: any) => {
  const { onClose, onSelect, clientId, allowed = ['clinical', 'medical', 'case_management', 'peer', 'daily'] } = props

  const [treatmentPlanId, setTreatmentPlanId] = React.useState('')

  const [goalId, setGoalId] = React.useState('')
  const [objectiveId, setObjectiveId] = React.useState('')
  const [interventionId, setInterventionId] = React.useState('')

  const [goalIndex, setGoalIndex] = React.useState(0)
  const [objectiveIndex, setObjectiveIndex] = React.useState(0)

  const {
    viewSomeTreatmentPlans,
    viewClinicalTreatmentPlans,
    viewMedicalTreatmentPlans,
    viewCaseManagementTreatmentPlans,
    viewPeerTreatmentPlans,
  } = useTreatmentPlanPermissions()

  const clinicalQuery = useGet({
    name: ['client', clientId, 'treatment_plans', { category: 'clinical' }],
    url: `/residents/${clientId}/treatment_plans`,
    params: { category: 'clinical' },
    options: {
      enabled: !!clientId && viewClinicalTreatmentPlans && !!allowed?.includes?.('clinical'),
    },
  })

  const medicalQuery = useGet({
    name: ['client', clientId, 'treatment_plans', { category: 'medical' }],
    url: `/residents/${clientId}/treatment_plans`,
    params: { category: 'medical' },
    options: {
      enabled: !!clientId && viewMedicalTreatmentPlans && !!allowed?.includes?.('medical'),
    },
  })

  const caseManagementQuery = useGet({
    name: ['client', clientId, 'treatment_plans', { category: 'case_management' }],
    url: `/residents/${clientId}/treatment_plans`,
    params: { category: 'case_management' },
    options: {
      enabled: !!clientId && viewCaseManagementTreatmentPlans && !!allowed?.includes?.('case_management'),
    },
  })

  const peerQuery = useGet({
    name: ['client', clientId, 'treatment_plans', { category: 'peer' }],
    url: `/residents/${clientId}/treatment_plans`,
    params: { category: 'peer' },
    options: {
      enabled: !!clientId && viewPeerTreatmentPlans && !!allowed?.includes?.('peer'),
    },
  })

  const { data: goalsObjectivesInterventions, isLoading } = useGet({
    name: ['client', clientId, 'treatment_plans', treatmentPlanId, 'treatment-goals-objectives-interventions'],
    url: `/treatment_plans/${treatmentPlanId}/treatment_goals_objectives_interventions`,
    options: { enabled: !!treatmentPlanId },
  })

  const { goalsById, objectivesById, interventionsById } = React.useMemo(() => {
    const result: any = {
      goalsById: {},
      objectivesById: {},
      interventionsById: {},
    }

    if (!goalsObjectivesInterventions) return result

    for (const goal of goalsObjectivesInterventions) {
      result.goalsById[goal.id] = goal

      for (const objective of goal.treatment_objectives) {
        result.objectivesById[objective.id] = objective

        for (const intervention of objective?.treatment_interventions) {
          result.interventionsById[intervention.id] = intervention
        }
      }
    }

    return result
  }, [goalsObjectivesInterventions])

  const isButtonDisabled = !interventionId && !objectiveId && !goalId

  const isPlansLoading = clinicalQuery?.isLoading || medicalQuery?.isLoading || caseManagementQuery?.isLoading || peerQuery?.isLoading
  const isPlansEmpty =
    size(clinicalQuery?.data) === 0 &&
    size(medicalQuery?.data) === 0 &&
    size(caseManagementQuery?.data) === 0 &&
    size(peerQuery?.data) === 0

  const handleSave = () => {
    const goal = goalsById?.[goalId]
    const objective = objectivesById?.[objectiveId]
    const intervention = interventionsById?.[interventionId]

    if (!goal) return

    let saveData: any = null

    if (intervention) {
      saveData = {
        type: 'treatment_intervention',
        data: produce(intervention, (draft: any) => {
          draft['treatment_goal'] = goal
          draft['treatment_objective'] = objective
        }),
      }
    } else if (objective) {
      saveData = {
        type: 'treatment_objective',
        data: produce(objective, (draft: any) => {
          draft['treatment_goal'] = goal
        }),
      }
    } else {
      saveData = {
        type: 'treatment_goal',
        data: goal,
      }
    }

    onSelect?.(saveData)
    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={onClose} maxWidth={100}>
      <Overlay.Header title="Select Goal, Objective or Intervention" icon="treatment_plans" />

      <Overlay.Content>
        <Section>
          <Grid gap="1rem">
            <Card className={STYLES.card()}>
              <Column title="Treatment Plans">
                {!viewSomeTreatmentPlans ? (
                  <State
                    icon="treatment_plans"
                    isEmpty
                    title="Treatment Plans"
                    emptyDescription="You do not have permission to view any treatment plans"
                  />
                ) : isPlansLoading || isPlansEmpty ? (
                  <State
                    icon="treatment_plans"
                    isLoading={isPlansLoading}
                    isEmpty={isPlansEmpty}
                    title="Treatment Plans"
                    emptyDescription="No treatment plans found"
                  />
                ) : (
                  <div>
                    {viewClinicalTreatmentPlans && size(clinicalQuery?.data) > 0 && (
                      <>
                        <div className="text-text-muted opacity-80 text-[0.8rem] uppercase font-[600] tracking-[0.5px] px-3 py-1.5 border-b border-0 border-solid border-divider bg-hover">
                          Clinical
                        </div>

                        {clinicalQuery?.data?.map?.((plan: any) => (
                          <RowItem
                            key={plan.id}
                            title={plan.name}
                            onClick={() => setTreatmentPlanId(plan.id)}
                            isActive={plan.id === treatmentPlanId}
                          />
                        ))}
                      </>
                    )}

                    {viewMedicalTreatmentPlans && size(medicalQuery?.data) > 0 && (
                      <>
                        <div className="text-text-muted opacity-80 text-[0.8rem] uppercase font-[600] tracking-[0.5px] px-3 py-1.5 border-b border-0 border-solid border-divider bg-hover">
                          Medical
                        </div>

                        {medicalQuery?.data?.map?.((plan: any) => (
                          <RowItem
                            key={plan.id}
                            title={plan.name}
                            onClick={() => setTreatmentPlanId(plan.id)}
                            isActive={plan.id === treatmentPlanId}
                          />
                        ))}
                      </>
                    )}

                    {viewCaseManagementTreatmentPlans && size(caseManagementQuery?.data) > 0 && (
                      <>
                        <div className="text-text-muted opacity-80 text-[0.8rem] uppercase font-[600] tracking-[0.5px] px-3 py-1.5 border-b border-0 border-solid border-divider bg-hover">
                          Case Management
                        </div>

                        {caseManagementQuery?.data?.map?.((plan: any) => (
                          <RowItem
                            key={plan.id}
                            title={plan.name}
                            onClick={() => setTreatmentPlanId(plan.id)}
                            isActive={plan.id === treatmentPlanId}
                          />
                        ))}
                      </>
                    )}

                    {viewPeerTreatmentPlans && size(peerQuery?.data) > 0 && (
                      <>
                        <div className="text-text-muted opacity-80 text-[0.8rem] uppercase font-[600] tracking-[0.5px] px-3 py-1.5 border-b border-0 border-solid border-divider bg-hover">
                          Peer
                        </div>

                        {peerQuery?.data?.map?.((plan: any) => (
                          <RowItem
                            key={plan.id}
                            title={plan.name}
                            onClick={() => setTreatmentPlanId(plan.id)}
                            isActive={plan.id === treatmentPlanId}
                          />
                        ))}
                      </>
                    )}
                  </div>
                )}
              </Column>

              <GoalsColumn
                activeId={goalId}
                dependentId={treatmentPlanId}
                data={treatmentPlanId && goalsById}
                isLoading={isLoading}
                isLastSelection={!objectiveId}
                onSelect={(id: any, index: number) => {
                  setGoalId(id)
                  setObjectiveId('')
                  setInterventionId('')
                  setGoalIndex(index)
                }}
              />

              <ObjectivesColumn
                activeId={objectiveId}
                dependentId={goalId}
                data={goalId && goalsById[goalId]?.treatment_objectives}
                isLoading={isLoading}
                isLastSelection={!interventionId}
                goalIndex={goalIndex}
                onSelect={(id: any, index: number) => {
                  setObjectiveId(id)
                  setInterventionId('')
                  setObjectiveIndex(index)
                }}
              />

              <InterventionsColumn
                isLastSelection
                activeId={interventionId}
                dependentId={objectiveId}
                goalIndex={goalIndex}
                objectiveIndex={objectiveIndex}
                data={objectiveId && objectivesById?.[objectiveId]?.treatment_interventions}
                isLoading={isLoading}
                onSelect={(id) => {
                  setInterventionId(id)
                }}
              />
            </Card>
          </Grid>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={interventionId ? 'Link Intervention →' : objectiveId ? 'Link Objective →' : goalId ? 'Link Goal →' : 'Select a goal…'}
          color={isButtonDisabled ? 'text' : 'blue'}
          type={isButtonDisabled ? 'link' : 'primary'}
          glyph={isButtonDisabled && 'info'}
          onClick={handleSave}
          isDisabled={isButtonDisabled}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const Column: React.FC<any> = (props) => {
  const { children, title, icon, testKey } = props

  return (
    <div data-test={testKey} className={STYLES.column()}>
      {title && (
        <header className={STYLES.columnHeader()}>
          {icon && <Icon icon={icon} size={20} className={STYLES.columnHeaderIcon()} />}
          {title}
        </header>
      )}

      {children}
    </div>
  )
}

const GoalsColumn: React.FC<any> = (props) => {
  const { activeId, dependentId, data, isLoading, onSelect, isLastSelection } = props

  const isEmpty = size(data) === 0

  if (isLoading || isEmpty) {
    return (
      <Column title="Goals">
        <State
          icon="treatment_plans"
          isLoading={isLoading}
          isEmpty={isEmpty}
          title="Goals"
          emptyDescription={dependentId ? 'No goals created for this treatment plan' : 'Select a treatment plan to view goals'}
        />
      </Column>
    )
  }

  return (
    <Column title="Goals">
      {Object.values(data).map((goal: any, index: number) => (
        <RowItem
          key={goal.id}
          titleBefore={<Status small label={`Goal ${index + 1}`} className={STYLES.rowItemStatus()} />}
          title={goal.name}
          subtitle={goal.description}
          onClick={() => onSelect(goal.id, index)}
          isActive={goal.id === activeId}
          isLastSelection={isLastSelection}
        />
      ))}
    </Column>
  )
}

const ObjectivesColumn: React.FC<any> = (props) => {
  const { activeId, dependentId, data, isLoading, onSelect, isLastSelection, goalIndex } = props

  const isEmpty = size(data) === 0

  if (isLoading || isEmpty) {
    return (
      <Column title="Objectives">
        <State
          icon="treatment_plans"
          isLoading={isLoading}
          isEmpty={isEmpty}
          title="Objectives"
          emptyDescription={dependentId ? 'No objectives created yet for selected goal' : 'Select a goal to view objectives'}
        />
      </Column>
    )
  }

  return (
    <Column title="Objectives">
      {Object.values(data).map((objective: any, index: number) => {
        return (
          <RowItem
            key={objective.id}
            titleBefore={<Status small label={`Objective ${goalIndex + 1}.${index + 1}`} className={STYLES.rowItemStatus()} />}
            title={objective.name}
            subtitle={objective.description}
            onClick={() => onSelect(objective.id, index)}
            isActive={objective.id === activeId}
            isLastSelection={isLastSelection}
          />
        )
      })}
    </Column>
  )
}

const InterventionsColumn: React.FC<any> = (props) => {
  const { activeId, dependentId, data, isLoading, onSelect, isLastSelection, goalIndex, objectiveIndex } = props

  const isEmpty = size(data) === 0

  if (isLoading || isEmpty) {
    return (
      <Column title="Interventions">
        <State
          icon="treatment_plans"
          isLoading={isLoading}
          isEmpty={isEmpty}
          title="Interventions"
          emptyDescription={
            dependentId ? 'No interventions created yet for selected objective' : 'Select an objective to view interventions'
          }
        />
      </Column>
    )
  }

  return (
    <Column title="Interventions">
      {Object.values(data).map((intervention: any, index: number) => {
        return (
          <RowItem
            key={intervention.id}
            titleBefore={
              <Status small label={`Intervention ${goalIndex + 1}.${objectiveIndex + 1}.${index + 1}`} className={STYLES.rowItemStatus()} />
            }
            title={intervention.name}
            subtitle={intervention.description}
            onClick={() => onSelect(intervention.id)}
            isActive={intervention.id === activeId}
            isLastSelection={isLastSelection}
          />
        )
      })}
    </Column>
  )
}

const RowItem: React.FC<any> = (props) => {
  const { aside, title, subtitle, graphic, onClick, isActive, isDisabled, style, isLastSelection, titleBefore } = props

  const rootClasses = clsx(
    STYLES.item().className,
    isActive && 'is-active',
    isLastSelection && 'is-last-selection',
    isDisabled && 'is-disabled',
    'w-full grid grid-cols-[100%] overflow-hidden',
  )

  return (
    <Tooltip
      className="w-full"
      show={!!subtitle}
      content={
        <div className="grid gap-1 max-w-[500px]">
          {titleBefore}
          {title && <div className={`font-[600]`}>{title}</div>}
          {subtitle && <div className={``}>{subtitle}</div>}
        </div>
      }
    >
      <div data-test="row_item" className={rootClasses} onClick={!isDisabled && onClick} style={style}>
        {graphic && <div className={STYLES.itemGraphic()}>{graphic}</div>}

        <div>
          {titleBefore}
          {title && <div className={`${STYLES.itemTitle().className} truncate w-full font-[600]`}>{title}</div>}
          {subtitle && <div className={`${STYLES.itemSubtitle()} truncate w-full`}>{subtitle}</div>}
        </div>

        {aside}
      </div>
    </Tooltip>
  )
}

const RowHeader: React.FC<any> = (props) => {
  const { title, graphic } = props

  return (
    <div className={STYLES.rowHeader()}>
      {graphic && <div className={STYLES.rowHeaderGraphic()}>{graphic}</div>}
      <div className={STYLES.rowHeaderTitle()}>{title}</div>
    </div>
  )
}

const STYLES = {
  card: css({
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(200px, 1fr)',
    overflow: 'auto !important',
    height: 450,
    fontSize: '0.9rem',
  }),

  column: css({
    overflowY: 'auto',
    borderRight: `1px solid ${COLORS.divider}`,

    '&:last-child': {
      borderRight: 'none',
    },
  }),

  columnHeaderIcon: css({
    marginRight: '0.4rem',
  }),

  columnHeader: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem 0.75rem',
    fontWeight: 600,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: COLORS.white,
  }),

  rowHeader: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.2rem 0.75rem',
    paddingTop: '0.3rem',
    background: COLORS.hover,
    borderBottom: `1px solid ${COLORS.divider}`,
  }),

  rowHeaderGraphic: css({
    marginRight: '0.4rem',
  }),

  rowHeaderTitle: css({
    fontWeight: 600,
  }),

  rowEmptyState: css({
    fontWeight: 400,
    fontSize: '0.85rem',
    color: COLORS.textMuted,
    textAlign: 'center',
    padding: '0.75rem',
  }),

  rowItemStatus: css({
    background: 'none !important',
    opacity: 0.8,
    color: 'inherit !important',

    span: { padding: 0 },
  }),

  item: css({
    display: 'grid',
    alignItems: 'center',
    minHeight: '2rem',
    padding: '0.45rem 0.75rem',
    cursor: 'pointer',
    borderBottom: `1px solid ${COLORS.divider}`,

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      color: COLORS.black,
      background: tint(0.8, COLORS.vividBlue),
      boxShadow: `inset 3px 0 0 ${COLORS.blue}`,

      '&.is-last-selection': {
        color: COLORS.white,
        background: COLORS.blue,
        boxShadow: 'none',
      },
    },

    '&.is-disabled': {
      background: COLORS.hover,
      cursor: 'not-allowed',
      color: tint(0.1, COLORS.textMuted),

      img: {
        opacity: 0.5,
        filter: 'grayscale(100%)',
      },
    },
  }),

  itemGraphic: css({
    marginRight: '0.4rem',
  }),

  itemTitle: css({
    // fontWeight: 600,
  }),

  itemSubtitle: css({
    fontSize: '0.85rem',
    fontWeight: 400,
    opacity: 0.7,
  }),

  statusesWrapper: css({
    marginLeft: 'auto',
  }),

  occupancyStatus: css({
    minWidth: 72,
    textAlign: 'center',
    justifyContent: 'center',
  }),
}

export const TreatmentPlanSelectorOverlay = withOverlayError(RootTreatmentPlanSelectorOverlay)
