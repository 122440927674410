import React from 'react'
import { darken } from 'polished'
import { DateTime } from 'luxon'
import { useRouteMatch } from 'react-router-dom'

import { COLORS } from '../../theme'
import { useDataTable } from '../../components/DataTable/useDataTable'

import Card from '../../components/Card'
import Tabs from '../../components/Tabs'
import Tooltip from '../../components/Tooltip'

import { AuthorityChargesDataTable } from './AuthorityChargesDataTable'
import { AuthorityCreditsDataTable } from './AuthorityCreditsDataTable'
import { AuthorityPaymentsDataTable } from './AuthorityPaymentsDataTable'
import { AuthorityRefundsDataTable } from './AuthorityRefundsDataTable'
import { AuthorityTransactionsDataTable } from './AuthorityTransactionsDataTable'
import { AuthorityWriteOffsDataTable } from './AuthorityWriteOffsDataTable'

import { AuthorityTransactionStats } from './AuthorityTransactionStats'

export const AuthorityTransactions = (props: any) => {
  const { hiddenColumns, isSelectable = true, name = 'financial_transactions', showReportLinks, url, useStats = true } = props

  const match = useRouteMatch()

  const estTime = DateTime.local().setZone('America/New_York')
  const leftTime = estTime.set({ hour: 8, minute: 0 }).toLocal().toFormat('ha')
  const rightTime = estTime.set({ hour: 10, minute: 0 }).toLocal().toFormat('ha')
  const offsetName = DateTime.local().toFormat('ZZZZZ')

  return (
    <>
      {useStats && <AuthorityTransactionStats name={name} url={url} />}

      <Card testKey="transaction_table" className="!mt-4">
        <Tabs defaultTab="all_transactions">
          <Tabs.List caps className="!pl-4">
            <Tabs.Item label="All Transactions" name="all_transactions" />

            <Tabs.Divider />

            <Tabs.Item label="Charges" name="charges" />
            <Tabs.Item label="Payments" name="payments" />
            <Tabs.Item label="Credits" name="credits" />
            <Tabs.Item label="Refunds" name="refunds" />
            <Tabs.Item label="Write Off's" name="write_offs" />

            <Tooltip
              color={darken(0.05, COLORS.orange)}
              glyph="question"
              position="top"
              content={
                <span>
                  <i>Future Planned Charges</i> and <i>Auto-Repeating Charges</i> get processed daily between <b>{leftTime}</b> and{' '}
                  <b>{rightTime}</b>, {offsetName}
                </span>
              }
              css={{ marginLeft: 'auto', marginRight: '0.85rem' }}
            />
          </Tabs.List>

          <Tabs.Panels>
            <Tabs.Panel name="all_transactions">
              <AllTransactions
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id, category }: any) => ({
                  pathname: `${match?.url}/${category}/${id}`,
                  parent: match,
                })}
              />
            </Tabs.Panel>

            <Tabs.Panel name="charges">
              <Charges
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/charges/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/charges' }] : undefined}
              />
            </Tabs.Panel>

            <Tabs.Panel name="payments">
              <Payments
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/payments/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/payments' }] : undefined}
              />
            </Tabs.Panel>

            <Tabs.Panel name="credits">
              <Credits
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/credits/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/credits' }] : undefined}
              />
            </Tabs.Panel>

            <Tabs.Panel name="refunds">
              <Refunds
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/refunds/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/refunds' }] : undefined}
              />
            </Tabs.Panel>

            <Tabs.Panel name="write_offs">
              <WriteOffs
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/write-offs/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/write-offs' }] : undefined}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Card>
    </>
  )
}

const AllTransactions = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'charge,payment,credit,refund,write_off' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_all_v1`,
  })

  return <AuthorityTransactionsDataTable {...tableProps} {...rest} />
}

const Charges = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'charge' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_charge_v1`,
  })

  return <AuthorityChargesDataTable {...tableProps} {...rest} />
}

const Payments = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'payment' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_payment_v1`,
  })

  return <AuthorityPaymentsDataTable {...tableProps} {...rest} />
}

const Credits = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'credit' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_credit_v1`,
  })

  return <AuthorityCreditsDataTable {...tableProps} {...rest} />
}

const Refunds = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'refund' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_refund_v1`,
  })

  return <AuthorityRefundsDataTable {...tableProps} {...rest} />
}

const WriteOffs = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'write_off' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_write_off_v1`,
  })

  return <AuthorityWriteOffsDataTable {...tableProps} {...rest} />
}
