import React from 'react'
import { Link } from 'react-router-dom-v5-compat'

import { HelpTagIframe, Page } from '@behavehealth/components'
import { OrganizationAgreementTemplatesDataTable } from '@behavehealth/constructs/AgreementTemplates/OrganizationAgreementTemplatesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'organization_agreement_templates',
  title: 'Organization Agreement Templates',
  help: <HelpTagIframe id="settings_organization_agreement_templates" />,
}

const OrganizationAgreementTemplates = () => {
  const tableProps = useDataTable({
    name: ['agreement_templates'],
    endpoint: `/agreement_templates`,
    params: { variant: 'organization' },
    localStorageKey: 'organization_agreement_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <OrganizationAgreementTemplatesDataTable
        to={(row: any) => row.id}
        duplicateFormLinkProps={(duplicated) => ({
          as: Link,
          link: 'new',
          state: { data: duplicated },
        })}
        {...tableProps}
      />
    </Page>
  )
}

export default withMarketing(OrganizationAgreementTemplates, pageConfig)
