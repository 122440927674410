import React from 'react'
import startCase from 'lodash/startCase'

import { Employee } from '../declarations/Employee'
import { fullname } from '../utils/functions'
import { Text } from './Typography'

import Status from './Status'
import Flex from './Flex'

type Props = {
  employee: Employee
}

const StaffProfileHeader: React.FC<Props> = ({ employee }) => {
  if (!employee) return null

  return (
    <Flex gap={6}>
      <Text avatar={employee.avatar} label={fullname(employee)} />
      <Status label="Staff" color="blue" />
      <Text>{`${startCase(employee?.position?.replace('_', ' '))}`}</Text>
    </Flex>
  )
}

export default StaffProfileHeader
