import React from 'react'
import clsx from 'clsx'
import Embed, { defaultProviders } from 'react-tiny-oembed'
import EmbedContainer from 'react-oembed-container'

import State from './State'
import { useGet } from '../hooks/useNewAPI'
import { formatURL } from '../utils/functions'

const providers = [
  ...defaultProviders,
  {
    provider_name: 'Canva',
    provider_url: 'https://www.canva.com',
    endpoints: [
      {
        schemes: ['https://www.canva.com/design/*/view'],
        url: 'https://www.canva.com/_oembed',
        discovery: true,
      },
    ],
  },
]

export const OEmbed = (props: any) => {
  const { children, className, url } = props

  const isCanva = url?.includes?.('canva.com')

  const queryParams = { url }

  const { data, isLoading }: any = useGet({
    name: ['canva_oembed', queryParams],
    url: `/apps/vendors/canva_oembed`,
    params: queryParams,
    options: { enabled: isCanva },
  })

  const rootClasses = clsx('OEMBED relative', !url && 'is-empty', className)

  return (
    <div className={rootClasses}>
      {!formatURL(url) || isLoading ? (
        <State isEmpty={!formatURL(url)} isLoading={isLoading} glyph="embed" title="OEmbed" emptyDescription="Enter an OEmbed URL" />
      ) : isCanva ? (
        <>
          {data?.html && (
            <div css={STYLES.iframeWrapper}>
              <EmbedContainer markup={data.html}>
                <div dangerouslySetInnerHTML={{ __html: data.html }} />
              </EmbedContainer>
            </div>
          )}
        </>
      ) : (
        <Embed url={formatURL(url)} providers={providers}></Embed>
      )}

      {children}
    </div>
  )
}

const STYLES = {
  iframeWrapper: {
    width: '100%',
    height: '100%',
    margin: '0 auto',

    iframe: {
      position: 'absolute',
      width: '100% !important',
      height: '100% !important',
    },
  },
}
