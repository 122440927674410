import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { FILTERS } from '../Filters/config'

export const ProgramOccupanciesDataTable = (props: any) => {
  const { to, ...rest } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'From',
        model: 'started_at',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={to(data)} value={usDateTime(value, timezone)} />
        },
      },
      {
        title: 'Until',
        model: 'ended_at',
        width: 200,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          if (data.period_type === 'indefinite') return 'Discharged'

          return usDateTime(value, timezone)
        },
      },
      {
        id: 'program',
        title: 'Program',
        model: 'place.program',
        type: 'profile',
        width: 180,
      },
      {
        id: 'phase',
        title: 'List',
        model: 'place.phase.name',
        width: 160,
      },
      {
        id: 'seat',
        title: 'Seat',
        model: 'place.name',
        width: 160,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [to],
  )

  return <DataTable asCard title="Bed Assignments" icon="beds" columns={columns} filtersConfig={FILTERS.program_occupancies} {...rest} />
}
