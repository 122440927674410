import React from 'react'
import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import size from 'lodash/size'

import { apiCreate } from '../../modules/api'
import { isDefined, niceAmount, nicePercentage } from '../../utils/functions'

import Button from '../../components/Button'
import Card from '../../components/Card'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Label from '../../components/Label'
import Markup from '../../components/Markup'
import NumberInput from '../../components/Forms/NumberInput'
import Option from '../../components/Forms/Option'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import PageSection from '../../components/PageSection/PageSection'
import PillTab from '../../components/PillTab'
import PillTabList from '../../components/PillTabList'
import RichTextEditor from '../../components/Forms/RichTextEditor'
import Select from '../../components/Forms/Select'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Tabs from '../../components/Tabs'
import Tooltip from '../../components/Tooltip'

import { DragAndDrop } from '../../components/DragAndDrop'
import { ReorderOverlay } from '../../components/ReorderOverlay'
import { BILLING_TYPE, PRICING_STRATEGIES, PRICING_UNIT_TYPES, YEARLY_PRICING_UNIT_TYPES } from '../../components/Billing/constants'

import { QUOTE_CELLS } from './constants'
import { QuoteDiscountsImportOverlay } from './QuoteDiscountsImportOverlay'
import { QuoteEstimatorSummonOverlay } from './QuoteEstimatorSummonOverlay'
import { QuoteOfferOverlay } from './QuoteOfferOverlay'
import { QuoteProductsImportOverlay } from './QuoteProductsImportOverlay'
import { QuoteSection } from './QuoteSection'

import { DiscountOverlay } from '../InternalBilling/DiscountOverlay'
import { ProductOverlay } from '../InternalBilling/ProductOverlay'
import { QuoteIframeForm } from './QuoteIframeForm'
import { PreviewData } from './PreviewData'

export const QuotePricingForm = (props: any) => {
  const {
    data: quoteData,
    formData,
    isEditable,
    onUpdate,
    onValidationUpdate,
    quoteOffers,
    setDeleteQuoteOffers,
    setQuoteOffers,
    updateQuote,
    refetchQuote,
  } = props

  const form = React.useRef(null)

  const [isAccepting, setIsAccepting] = React.useState(false)
  const [isDuplicatingOffer, setIsDuplicatingOffer] = React.useState(false)

  const [activeOfferId, setActiveOfferId] = React.useState(quoteOffers?.[0]?.id || quoteOffers?.[0]?._id)

  const activeOffer = React.useMemo(() => {
    if (!activeOfferId || size(quoteOffers) === 0) return null

    return quoteOffers.find((offer) => offer.id === activeOfferId || offer._id === activeOfferId)
  }, [quoteOffers, activeOfferId])

  const acceptedOfferId = quoteData?.selected_quote_offer?.id
  const isPaid = quoteData?.status === 'paid' || quoteData?.status === 'completed'

  const acceptOffer = async (id: any) => {
    if (!id) return

    setIsAccepting(true)

    try {
      await updateQuote({
        selected_quote_offer_id: id,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsAccepting(false)
    }
  }

  const addOffer = () => {
    const newId = uuid()

    setQuoteOffers((prev: any) => [
      ...prev,
      {
        _id: newId,
        name: `Offer ${size(prev) + 1}`,
        description: '',
        quote_lines: [],
      },
    ])

    setActiveOfferId(newId)
  }

  const duplicateOffer = async (offerId: any) => {
    if (!offerId) return

    setIsDuplicatingOffer(true)

    try {
      await apiCreate({ url: `/quote_offers/${offerId}/duplicate` })

      const newQuote = await refetchQuote()

      if (newQuote?.data?.data?.quote_offers) {
        setQuoteOffers(newQuote?.data?.data?.quote_offers)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsDuplicatingOffer(false)
    }
  }

  const updateOffer = ({ _id, id }, data) => {
    setQuoteOffers((prev: any) => {
      return produce(prev, (draft: any) => {
        const index = id ? draft.findIndex((o) => o.id === id) : draft.findIndex((o) => o._id === _id)

        if (index === -1) return

        draft[index] = {
          ...draft[index],
          ...data,
        }
      })
    })
  }

  React.useEffect(() => {
    if (!activeOffer) {
      setActiveOfferId(quoteOffers?.[0]?.id || quoteOffers?.[0]?._id)
    }
  }, [quoteOffers, activeOffer])

  return (
    <Form
      isCompact
      getForm={form}
      isEditable={isEditable}
      initialModel={formData}
      onValidationUpdate={onValidationUpdate}
      onUpdate={onUpdate}
    >
      <PageSection className="p-5">
        <PageSection.Header
          after={
            <>
              {isEditable && (
                <Flex gap="0.75rem">
                  <SummonOverlay
                    overlay={<ReorderOverlay items={quoteOffers} onSave={setQuoteOffers} getId={(item) => item._id || item.id} />}
                  >
                    <Button
                      nowrap
                      label="Reorder Offers"
                      glyph="drag_and_drop"
                      type="default"
                      size={200}
                      display="inline-flex"
                      className={`!whitespace-nowrap !flex-[0_0_auto]`}
                    />
                  </SummonOverlay>

                  <Button
                    nowrap
                    label="Add Offer"
                    glyph="add"
                    type="primary"
                    size={200}
                    display="inline-flex"
                    onClick={addOffer}
                    className={`!whitespace-nowrap !flex-[0_0_auto]`}
                  />
                </Flex>
              )}
            </>
          }
        >
          <PageSection.Title title="Quote Offers" className="!text-[1.65rem]" />
        </PageSection.Header>

        <QuoteIframeForm model="quote_offers" isEditable={isEditable} onUpdate={onUpdate} formData={formData} />

        <PageSection.Content className="mt-4">
          {size(quoteOffers) === 0 ? (
            <Card>
              <State isEmpty title="Quote Offers" icon="quotes" emptyDescription="No quote offers added yet" minHeight={200} />
            </Card>
          ) : (
            <Tabs activeTab={activeOfferId} onChange={setActiveOfferId}>
              <PillTabList className="px-0 pb-4 !-m-1">
                {quoteOffers.map((offer) => {
                  return (
                    <React.Fragment key={offer.id || offer._id}>
                      <PillTab
                        className="!bg-white !rounded-[5px] !shadow-soft-2 border-[0.5px] border-solid border-white !m-1 [&.is-active]:ring-2 [&.is-active]:border-blue-400"
                        label={
                          <span className="flex items-center flex-nowrap">
                            {offer?.name}

                            {!!acceptedOfferId && acceptedOfferId === offer.id && (
                              <Status className="ml-1.5" small label="Accepted" color="green" />
                            )}
                          </span>
                        }
                        name={offer.id || offer._id}
                      />
                    </React.Fragment>
                  )
                })}
              </PillTabList>

              <Card>
                <Tabs.Panels>
                  {quoteOffers.map((offer) => (
                    <Tabs.Panel key={offer.id || offer._id} name={offer.id || offer._id}>
                      <div className="px-4 py-4">
                        <div className="flex items-center justify-between mb-1">
                          <h2 className="flex items-center mr-4 text-[1.3rem]">
                            {offer.name}

                            <span className="inline-flex flex-nowrap items-center ml-4 text-[0.9rem] text-text-muted font-[400]">
                              <Icon icon="feature_flags" size={14} className="mr-1" />
                              <span className="font-[600] text-text mr-1">Feature Flag Config: </span>
                              {offer.feature_flag_config ? (
                                <span>{offer.feature_flag_config.name}</span>
                              ) : (
                                <span className="opacity-80">–</span>
                              )}
                            </span>
                          </h2>

                          <Flex gap="0.75rem">
                            {!isEditable && size(offer.quote_lines) > 0 && (
                              <>
                                <QuoteEstimatorSummonOverlay quote={quoteData} quoteOfferId={offer.id} quoteLines={offer.quote_lines} />

                                <Button
                                  label="Duplicate Offer"
                                  glyph="copy"
                                  type="default"
                                  size={200}
                                  display="inline-flex"
                                  className={`!whitespace-nowrap !flex-[0_0_auto]`}
                                  onClick={() => {
                                    duplicateOffer(offer.id)
                                  }}
                                  isLoading={isDuplicatingOffer}
                                />
                              </>
                            )}

                            {isEditable && (
                              <>
                                <SummonOverlay
                                  overlay={
                                    <QuoteOfferOverlay
                                      data={offer}
                                      onSave={(data) => {
                                        updateOffer(offer, data)
                                      }}
                                    />
                                  }
                                >
                                  <Button
                                    label="Edit Quote Offer"
                                    glyph="edit"
                                    type="default"
                                    size={200}
                                    display="inline-flex"
                                    className={`!whitespace-nowrap !flex-[0_0_auto]`}
                                  />
                                </SummonOverlay>

                                <DeleteDialog
                                  title="Delete Quote Offer?"
                                  message="Are you sure you want to delete this Quote Offer? This action cannot be undone."
                                  onYes={() => {
                                    // if server item, mark for deletion and remove from state
                                    if (isDefined(offer.id)) {
                                      setDeleteQuoteOffers((prev: any) => [...prev, { id: offer.id, _destroy: 1 }])

                                      setQuoteOffers((prev: any) => {
                                        return produce(prev, (draft: any) => {
                                          const foundIndex = draft.findIndex((o: any) => o.id === offer.id)

                                          if (foundIndex !== -1) {
                                            draft.splice(foundIndex, 1)
                                          }
                                        })
                                      })
                                    } else {
                                      // if local item, remove from state
                                      setQuoteOffers((prev: any) => {
                                        return produce(prev, (draft: any) => {
                                          const foundIndex = draft.findIndex((o: any) => o._id === offer._id)

                                          if (foundIndex !== -1) {
                                            draft.splice(foundIndex, 1)
                                          }
                                        })
                                      })
                                    }
                                  }}
                                >
                                  <Button label="Delete" glyph="delete" color="red" type="default" size={200} />
                                </DeleteDialog>
                              </>
                            )}

                            {!isEditable && size(offer.quote_lines) > 0 && (
                              <>
                                {!acceptedOfferId && (
                                  <ConfirmDialog
                                    title="Accept Offer?"
                                    message="Are you sure you want to accept this offer on behalf of the customer?"
                                    yesLabel="Accept Offer"
                                    onYes={async () => {
                                      await acceptOffer?.(offer.id)
                                    }}
                                  >
                                    <Button
                                      label={`Accept Offer`}
                                      type="primary"
                                      color="green"
                                      glyph="tick_circle"
                                      size={200}
                                      isLoading={isAccepting}
                                    />
                                  </ConfirmDialog>
                                )}

                                {!!acceptedOfferId && acceptedOfferId !== offer.id && (
                                  <ConfirmDialog
                                    title="Change Accepted Offer?"
                                    message="Are you sure you want to change the accepted offer on behalf of the customer?"
                                    yesLabel="Accept Offer"
                                    onYes={async () => {
                                      await acceptOffer?.(offer.id)
                                    }}
                                  >
                                    <Button
                                      label={`Accept This Offer Instead`}
                                      type="primary"
                                      color="green"
                                      glyph="tick_circle"
                                      size={200}
                                      isLoading={isAccepting}
                                    />
                                  </ConfirmDialog>
                                )}
                              </>
                            )}
                          </Flex>
                        </div>

                        {offer.description && <Markup value={offer.description} />}
                      </div>

                      {size(offer.quote_lines) > 0 && (
                        <div className="px-4 pt-4 mb-2 flex items-center justify-between">
                          <h3 className="mr-4 text-[1.05rem] font-[600]">Quote Lines</h3>
                        </div>
                      )}

                      <QuoteLines
                        quote={quoteData}
                        isPaid={isPaid}
                        offer={offer}
                        isEditable={isEditable}
                        quoteLines={offer.quote_lines}
                        onUpdate={(data) => {
                          updateOffer(offer, data)
                        }}
                      />
                    </Tabs.Panel>
                  ))}
                </Tabs.Panels>
              </Card>
            </Tabs>
          )}
        </PageSection.Content>
      </PageSection>

      <Divider className="!my-0" />

      <QuoteSection
        showVariables
        title="Pricing Summary"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_price_summary_section}
        enabledModel="use_price_summary_section"
      >
        <div className="grid mq900:grid-cols-3 gap-4">
          <QuoteIframeForm model="price_summary_iframe_1" isEditable={isEditable} onUpdate={onUpdate} formData={formData} />
          <QuoteIframeForm model="price_summary_iframe_2" isEditable={isEditable} onUpdate={onUpdate} formData={formData} />
          <QuoteIframeForm model="price_summary_iframe_3" isEditable={isEditable} onUpdate={onUpdate} formData={formData} />
        </div>

        <RichTextEditor label="Price Summary Text" model="price_summary_text" />
        <PreviewData isEditable={isEditable} model="price_summary_text" />
      </QuoteSection>

      <Divider className="!my-0" />

      <QuoteSection
        showVariables
        title="Price Matching"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_price_matching_section}
        enabledModel="use_price_matching_section"
      >
        <QuoteIframeForm model="price_matching" isEditable={isEditable} onUpdate={onUpdate} formData={formData} />
        <RichTextEditor label="Price Matching Text" model="price_matching_text" />
        <PreviewData isEditable={isEditable} model="price_matching_text" />
      </QuoteSection>
    </Form>
  )
}

const QuoteLines = (props: any) => {
  const { isEditable, onUpdate, offer, isPaid, quote } = props

  const [quoteLines, setQuoteLines] = React.useState(props.quoteLines || [])
  const [deleteQuoteLines, setDeleteQuoteLines] = React.useState([])

  const quoteLinesEmpty = size(quoteLines) === 0

  const [editProductId, setEditProductId] = React.useState(null)
  const [editLineIndex, setEditLineIndex] = React.useState(null)

  React.useEffect(() => {
    if (!onUpdate) return

    onUpdate({
      quote_lines: quoteLines,
      delete_quote_lines: deleteQuoteLines,
    })
  }, [quoteLines, deleteQuoteLines])

  return (
    <>
      <SummonOverlay
        key={`overlay-${editProductId}-${editLineIndex}`}
        isOpen={isDefined(editProductId) && isDefined(editLineIndex)}
        onClose={() => {
          setEditProductId(null)
          setEditLineIndex(null)
        }}
        overlay={
          <ProductOverlay
            isEditable
            showBackdrop={false}
            key={`id-${editProductId}-${editLineIndex}`}
            dataID={editProductId}
            onSaveSuccessful={(newProduct) => {
              if (!newProduct || typeof editLineIndex !== 'number') return

              setQuoteLines((prev: any) => {
                return produce(prev, (draft: any) => {
                  draft[editLineIndex].product = newProduct
                })
              })

              setEditProductId(null)
              setEditLineIndex(null)
            }}
          />
        }
      />

      <Grid gap="1rem">
        {quoteLinesEmpty ? (
          <>
            <State
              isEmpty
              className="!pt-0"
              icon="invoices_blue"
              emptyDescription={`No Quote Lines added yet`}
              minHeight={200}
              emptyActions={
                isEditable && (
                  <Button
                    label="Add Quote Line"
                    size={200}
                    type="primary"
                    glyph="add"
                    display="inline-flex"
                    onClick={() => {
                      setQuoteLines((prev: any) => [...prev, { _id: uuid() }])
                    }}
                  />
                )
              }
            />
          </>
        ) : (
          <Grid>
            <div className="grid px-4 py-2 !overflow-x-auto">
              <div className="grid gap-3 items-center grid-cols-[60px_360px_170px_300px_150px_360px_minmax(280px,1fr)_50px] py-2 border-b !last:border-none border-solid border-0 border-divider text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-[0.85]">
                <div>Order</div>
                <div className="flex items-center">
                  Product
                  {isEditable && <Glyph glyph="label_required" size={14} />}
                </div>
                <div>Status</div>
                <div>Amount</div>
                <div>Billed</div>
                <div className="flex items-center">Discount</div>
                <div>Description</div>
                <div></div>
              </div>

              <DragAndDrop
                items={quoteLines}
                onUpdate={setQuoteLines}
                isDisabled={!isEditable}
                getId={(item) => item._id || item.id}
                key={`updated-${quote?.updated_at}`}
                renderOverlay={({ item, index }) => {
                  const icon = item?.product?.icon
                  const name = item?.product?.name

                  return (
                    <Card className="w-fit px-3 py-2 flex items-center flex-nowrap !select-none">
                      <Glyph glyph="drag_and_drop" size={14} />
                      <div className="ml-2 mr-4 font-[500] text-text-muted">{index + 1}.</div>
                      {icon && <Icon icon={icon} size={20} className="mr-2" />}
                      <div className="font-[600]">{name}</div>
                    </Card>
                  )
                }}
                renderItem={({ renderDragElement, item: quoteLine, index }) => {
                  return (
                    <div
                      key={`item-${quoteLine.id || quoteLine._id}`}
                      className="grid gap-3 items-center grid-cols-[60px_360px_170px_300px_150px_360px_minmax(280px,1fr)_50px] py-1.5 border-b !last:border-none border-solid border-0 border-divider"
                    >
                      <div className="grid grid-cols-1 items-start">
                        {renderDragElement({
                          children: <div className="ml-2 font-[500] text-text-muted">{index + 1}.</div>,
                          className: '!justify-start !pl-2 !py-2',
                        })}
                      </div>

                      <div className="flex items-center">
                        <div className="flex-[1_1_280px] mr-2 truncate">
                          <div className={clsx(isEditable ? 'hidden' : 'block truncate')}>{QUOTE_CELLS.product(quoteLine.product)}</div>

                          <div className={clsx(isEditable ? 'block' : 'hidden')}>
                            <OverlaySelector
                              showLabel={false}
                              withHover={false}
                              value={quoteLine.product}
                              type="products_no_links"
                              selectTitle={(product: any) => product.name}
                              selectGraphic={(product: any) => (product?.icon ? <Icon icon={product?.icon} size={20} /> : null)}
                              validations={{
                                presence: {
                                  message: 'Please select a product',
                                },
                              }}
                              onUpdate={({ object }) => {
                                setQuoteLines((prev: any) => {
                                  return produce(prev, (draft: any) => {
                                    draft[index].product = object
                                  })
                                })
                              }}
                            />
                          </div>
                        </div>

                        {isEditable && (
                          <>
                            {quoteLine?.product?.id ? (
                              <Button
                                key={`edit-button-${index}`}
                                label="Edit"
                                size={100}
                                type={editProductId === quoteLine.product?.id ? 'primary' : 'minimal'}
                                glyph="edit"
                                display="inline-flex"
                                className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                                onClick={() => {
                                  setEditProductId(quoteLine.product?.id)
                                  setEditLineIndex(index)
                                }}
                              />
                            ) : (
                              <Button
                                key={`add-button-${index}`}
                                label="Add"
                                testKey="add_product_button"
                                size={100}
                                type={editLineIndex === index ? 'primary' : 'minimal'}
                                glyph="add"
                                display="inline-flex"
                                className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                                onClick={() => {
                                  setEditProductId('new')
                                  setEditLineIndex(index)
                                }}
                              />
                            )}

                            <SummonOverlay
                              className="flex-[0_0_auto]"
                              overlay={
                                <QuoteProductsImportOverlay
                                  onImport={(newProduct) => {
                                    setQuoteLines((prev: any) => {
                                      return produce(prev, (draft: any) => {
                                        draft[index].product = newProduct
                                      })
                                    })
                                  }}
                                />
                              }
                            >
                              <Button
                                label="Import"
                                size={100}
                                type="minimal"
                                glyph="add_file"
                                display="inline-flex"
                                className="normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                              />
                            </SummonOverlay>
                          </>
                        )}
                      </div>

                      <div className="flex items-center flex-nowrap">
                        {quoteLine.product?.is_required ? (
                          <Status small label="Required" color="orange" />
                        ) : (
                          <Status small label="Optional" color="blue" />
                        )}

                        {quoteLine.product?.is_recommended && <Status small label="Recommended" color="green" className="ml-1.5" />}
                      </div>

                      <div>
                        {quoteLine.product?.price && quoteLine.product?.pricing_type !== 'free' ? (
                          <>
                            <span className="font-[600] inline-block mr-1.5">
                              {quoteLine.product.price < 1 ? `$${quoteLine.product.price}` : niceAmount(quoteLine.product.price)}
                            </span>

                            {quoteLine.product?.pricing_type ? (
                              <span className="text-text-muted">
                                {PRICING_STRATEGIES[quoteLine.product.pricing_type]}

                                {quoteLine.product?.pricing_type === 'metered_monthly' &&
                                  quoteLine.product?.price_unit_type &&
                                  ` / ${PRICING_UNIT_TYPES[quoteLine.product.price_unit_type]}`}

                                {quoteLine.product?.pricing_type === 'metered_annually' &&
                                  quoteLine.product?.price_unit_type &&
                                  ` / ${YEARLY_PRICING_UNIT_TYPES[quoteLine.product.price_unit_type]}`}
                              </span>
                            ) : (
                              <span className="text-text-muted">–</span>
                            )}
                          </>
                        ) : (
                          <span className="text-text-muted">–</span>
                        )}
                      </div>

                      <div>
                        {quoteLine.product?.bill_type ? (
                          BILLING_TYPE[quoteLine.product.bill_type]
                        ) : (
                          <span className="text-text-muted">–</span>
                        )}
                      </div>

                      <div className="flex items-center">
                        <div className="flex-[1_1_280px] mr-2">
                          <OverlaySelector
                            showLabel={false}
                            withHover={false}
                            value={quoteLine.discount}
                            type="discounts_no_links"
                            selectTitle={(discount: any) => {
                              let info: any = ''

                              const { discount_type, percent_off, amount_off } = discount

                              if (discount_type === 'percentage') {
                                info = `${nicePercentage(percent_off)}%`
                              } else if (discount_type === 'fixed') {
                                info = niceAmount(amount_off)
                              }

                              return (
                                <>
                                  {discount.name} {info && ` (${info})`}
                                </>
                              )
                            }}
                            onUpdate={({ object }) => {
                              setQuoteLines((prev: any) => {
                                return produce(prev, (draft: any) => {
                                  draft[index].discount = object
                                })
                              })
                            }}
                          />
                        </div>

                        {isEditable && (
                          <>
                            {quoteLine?.discount?.id ? (
                              <SummonOverlay
                                className="flex-[0_0_auto]"
                                overlay={
                                  <DiscountOverlay
                                    dataID={quoteLine.discount?.id}
                                    onSaveSuccessful={(newDiscount) => {
                                      setQuoteLines((prev: any) => {
                                        return produce(prev, (draft: any) => {
                                          draft[index].discount = newDiscount
                                        })
                                      })
                                    }}
                                  />
                                }
                              >
                                <Button
                                  label="Edit"
                                  size={100}
                                  type="minimal"
                                  glyph="edit"
                                  display="inline-flex"
                                  className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                                />
                              </SummonOverlay>
                            ) : (
                              <SummonOverlay
                                className="flex-[0_0_auto]"
                                overlay={
                                  <DiscountOverlay
                                    onSaveSuccessful={(newDiscount) => {
                                      setQuoteLines((prev: any) => {
                                        return produce(prev, (draft: any) => {
                                          draft[index].discount = newDiscount
                                        })
                                      })
                                    }}
                                  />
                                }
                              >
                                <Button
                                  label="Add"
                                  size={100}
                                  type="minimal"
                                  glyph="add"
                                  display="inline-flex"
                                  className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                                />
                              </SummonOverlay>
                            )}

                            <SummonOverlay
                              className="flex-[0_0_auto]"
                              overlay={
                                <QuoteDiscountsImportOverlay
                                  onImport={(newDiscount) => {
                                    setQuoteLines((prev: any) => {
                                      return produce(prev, (draft: any) => {
                                        draft[index].discount = newDiscount
                                      })
                                    })
                                  }}
                                />
                              }
                            >
                              <Button
                                label="Import"
                                size={100}
                                type="minimal"
                                glyph="add_file"
                                display="inline-flex"
                                className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                              />
                            </SummonOverlay>
                          </>
                        )}
                      </div>

                      <div className="flex items-center flex-nowrap">
                        <div className="flex-[1_1_auto]">
                          {quoteLine.product?.line_description ? (
                            <div>{quoteLine.product?.line_description}</div>
                          ) : (
                            <span className="text-text-muted">–</span>
                          )}
                        </div>

                        {(quoteLine.product?.description || quoteLine.product?.internal_notes) && (
                          <Tooltip
                            content={
                              <>
                                <Label isCompact label="Full Description" />
                                {quoteLine.product?.description ? (
                                  <Markup value={quoteLine.product.description} />
                                ) : (
                                  <span className="text-text-muted">–</span>
                                )}

                                <Divider />

                                <Label isCompact label="Internal Notes" />
                                {quoteLine.product?.internal_notes ? (
                                  <Markup value={quoteLine.product.internal_notes} />
                                ) : (
                                  <span className="text-text-muted">–</span>
                                )}
                              </>
                            }
                          />
                        )}
                      </div>

                      <div>
                        {isEditable && (
                          <Button
                            hideLabel
                            glyph="delete"
                            color="red"
                            type="minimal"
                            size={100}
                            className="!w-7 !h-7"
                            onClick={() => {
                              // if server item, mark for deletion and remove from state
                              if (isDefined(quoteLine.id)) {
                                setDeleteQuoteLines((prev: any) => [...prev, { id: quoteLine.id, _destroy: 1 }])

                                setQuoteLines((prev: any) => {
                                  return produce(prev, (draft: any) => {
                                    const foundIndex = draft.findIndex((o: any) => o.id === quoteLine.id)

                                    if (foundIndex !== -1) {
                                      draft.splice(foundIndex, 1)
                                    }
                                  })
                                })

                                return
                              }

                              // if local item, remove from state
                              setQuoteLines((prev: any) => {
                                return produce(prev, (draft: any) => {
                                  const foundIndex = draft.findIndex((o: any) => o._id === quoteLine._id)

                                  if (foundIndex !== -1) {
                                    draft.splice(foundIndex, 1)
                                  }
                                })
                              })
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )
                }}
              />
            </div>

            {isEditable && (
              <div className="px-3 py-1.5 flex">
                <Button
                  label="Add Quote Line"
                  size={200}
                  type="primary"
                  glyph="add"
                  display="inline-flex"
                  onClick={() => {
                    setQuoteLines((prev: any) => [...prev, { _id: uuid() }])
                  }}
                />
              </div>
            )}

            <div className="flex justify-end px-3 pb-4 pt-2 font-[600]">
              <div className="flex items-center justify-end pr-4">Sales Tax:</div>
              <Flex nowrap gap="0.5rem">
                <NumberInput
                  // defaultValue={isEditable ? 10 : undefined}
                  size={4}
                  value={offer?.sales_tax}
                  onUpdate={({ value }) => {
                    onUpdate({
                      sales_tax: value,
                    })
                  }}
                />

                <Select
                  defaultValue="percentage"
                  value={offer?.sales_tax_type || 'percentage'}
                  onUpdate={({ value }) => {
                    onUpdate({
                      sales_tax_type: value || 'percentage',
                    })
                  }}
                >
                  <Option label="%" value="percentage" />
                  <Option label="USD" value="amount" />
                </Select>
              </Flex>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  )
}
