import React from 'react'

import { useUpdate } from '../../hooks/useNewAPI'
import TagsSelector from '../../components/TagsSelector'

export const OrganizationTagSelector = ({ organization }: any) => {
  const organizationId = organization?.id

  const { mutateAsync: updateOrganization, isLoading }: any = useUpdate({
    name: ['organization', organizationId],
    url: `/organizations/${organizationId}`,
    invalidate: ['organizations'],
  })

  if (!organizationId) return null

  return (
    <>
      <TagsSelector
        buttonLabel="Organization Tags"
        tags={organization?.tags}
        onSave={(tagIDs: any) => updateOrganization({ tag_ids: tagIDs })}
        isSaving={isLoading}
        smartCategories="organizations"
        buttonClassName="order-first"
      />
    </>
  )
}
