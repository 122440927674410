export const FORM_STATUSES = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  pending: {
    label: 'Pending Review',
    color: 'yellow',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'orange',
  },
  in_progress: {
    label: 'In Progress',
    color: 'blue',
  },
  approved: {
    label: 'Approved',
    color: 'paleGreen',
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
  },
  closed: {
    label: 'Closed',
    color: 'green',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
}

export const COMMENT_STATUSES = {
  issue: {
    label: 'Issue',
    color: 'orange',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
  draft: {
    label: 'Draft',
    color: 'blue',
  },
}

export const AUTHORITY_FORMS = {
  certification_application_form: 'Certification Application Form',
  certification_application_renewal_form: 'Certification Application Renewal Form',
  inspection_checklist: 'Inspection Checklist',
  document_review_checklist: 'Document Review Checklist',
  accreditation_agreement: 'Accreditation Agreement',
  code_of_ethics: 'Code of Ethics',
  assurances: 'Assurances',
  attestation: 'Attestation',
  property_ownership_attestation: 'Property Ownership Attestation',
  home_ownership_attestation: 'Home Ownership Attestation',
  certification_overview: 'Certification Overview',
  organizational_policies_and_procedures_writing_guide: 'Organizational Policies and Procedures Writing Guide',
  resident_intake_packet_writing_guide: 'Resident Intake Packet Writing Guide',
  quality_and_operations_standards: 'Quality and Operations Standards',
  coi_accord_statement_legend: 'COI & Accord Statement Legend',
  governing_board_application: 'Governing Board Application',
  resident_demographic_study: 'Resident Demographic Study',
  resident_paid_work_agreement_survey: 'Resident Paid Work Agreement Survey',
  recovery_residence_resident_survey: 'Recovery Residence Resident Survey',
  recovery_residence_leaders_staff_survey: 'Recovery Residence Leaders/Staff Survey ',
}

export const ORGANIZATION_QUESTIONS = [
  'Member/Application Type (new, renewal, etc)',
  'Organization Name',
  'Residence Ownership',
  'DBA Name if applicable',
  'Tax ID',
  'Org Type',
  'State of Incorporation or Organization',
  'Year/Date Founded',
  'Service Locations (counties)',
  'Phone Number',
  'Fax Number',
  'Program Email',
  'Organization Website',
  'Organzation Business Address',
  'Does applicant own/operate alcohol & drug or mental health program/facility',
  'Do any of the owners identified in this application have an ownership interest in or any sort of business relationship with a licensed, independent use, confirmatory lab?',
  'How many residences in application',
  'How many residences does org operate',
  'Willing to fully participate in affiliate in activites',
  'Read and agree to comply with NARR 3.0, requirements, etc',
  'Mission Statement',
  'Vision Statement',
  'Do you have a written code of ethics',
  'Do you have general liability insurance',
  'Do you comply with all state and federal requirements',
  'Do you maintain an accounting system that fully documents all residnet financial transactions',
  'Do you collect and report accurate process and outcome data',
  'Do you adhere to applicable confidentiality laws',
  'Do you keep resident records secure  with limited staff access',
  'Do you have a grievance policy and procedure',
  'Do you abide by all local building and fire safety codes',
  'Do any of the owners identified have an ownership interest in behavioral healthcare provider',
  'Do you offer housing scholarships for qualified applicants?',
  'Have you read and understood the Certified Residence requirements?',
  'Have you read, and do you agree to comply with the NARR Standards and Code of Ethics?',
  'Level you are pursuing',
  'Type of Dwelling',
  'Willing to adopt NARR Standards',
  'Describe organization governance and leadership',
  'Have you or any org associated with you been charged or convicted of Medicaid Fraud or Abuse?',
  'Number of Beds in Program',
  'Will organization attend training workshops within 90 days of certf',
  'Do you operate other types of housing?',
  'Describe your business',
  'Do you maintain formal standards',
  'Do you agree to adopt the state standards/code of ethics for all residences',
  'Do you have a defined process for resolving complaints',
  'Do you maintain and follow procedures for logging and retaining records of complaints',
  'Have you reviewed the health, safety, and managemetn requirements?',
  'Current status (taking applicants, full, etc',
  'Type of Service/Modality/Speciality/Type you provide (medical, mental health, 12-step, christian, etc)',
  'Substance abouse services provided',
  'Counseling Services Provided',
  'DUI Servicfes Provided',
  'ORS#',
  'Are you willing and able to support -sponsored research',
  'Are you willing and able to contribute financially to the operation of NARR by payment',
  'Do you intend to conform to affiliate requirements',
  'Do you agree to cooperate wtih .... in efforts to resolve complaints received',
  'Recovery Community',
  'Forms of payment accepted',
  'Why do you want to be a member',
  'Do you/your program have any accreditations, certifications, or licenses?',
  'Other Memberships/Affiliations/Alliances',
  'Program Name',
  'Existing Program or Future Project',
  'Is organizations programing faith based',
  'Does the program offer special and specific services to meet the needs of any of the following groups (DOC, LGBTQIA+, Veterans)',
  'Has this organization received funding from State/Federal Dollars',
  'Has your organization had a revocation of a prior license, certificate, or approval issued within the previous 10 years from any in-State or out-of-State agency or provider. Include previous or current organizations associated with the applicant, along with any associated deficiency reports and compliance records.',
  'Has your organization or a program, partnership, corporation, or provider previously or currently associated with the applicant, surrendered, or defaulted on its license, certificate, or approval, or had it revoked or suspended within the previous 10 years, for any reason?',
  'Has your organization or a program, corporation, or provider previously or currently associated with your organization surrendered or defaulted on its license, certification, or approval within the previous five (5) years for reasons related to disciplinary action?',
  'Has any staff member currently associated with your organization had a professional license or certification revoked, suspended, or surrendered for reasons related to disciplinary action or misconduct, within the previous ten (10) years',
  'Has any staff member currently associated with your organization been convicted of a felony, sexual offense, assault, or crime of moral turpitude, within the previous 10 years?',
  'Has any staff member currently associated with your organization been convicted of child abuse or any child sexual abuse?',
  'Has any staff member currently associated with your organization been convicted of abuse or neglect of a vulnerable adult?',
  'Has any staff member currently associated been convicted of a felony within the previous ten (10) years?',
  'Has any staff member currently associated with your organization ever been convicted of a sexual assault, sexual abuse, or second-degree assault?',
  'Does any staff member currently associated with your organization have pending charges for a sexual assault?',
  'Has your organization or a program, partnership, corporation, or provider previously or currently associated with the applicant, received funding within the previous 5 years from any federal, state, or local governmental entity to support the construction, renovation, maintenance, and operation of a recovery residence?',
  'Does your organization or the principals owe money to the Maryland Department of Health or one of its Administrations or any Local Designated Authority?',
  'Provide a listing of any individual who has a 5 percent or more ownership stake in the recovery residence or legal business entity associated with the recovery residence or, if constituted as a non-profit, a listing of the names, percentage of stake in the recovery residence and contact information of all Board members.',
  'Provide the names of any other recovery residences or legal business entities associated with a recovery residence or similar program in which the applicant or the principals have, or have had, an ownership interest within the previous 10 years.',
]

export const HOME_LOCATION_QUESTIONS = [
  'Name of Residence',
  'Residence Address',
  'County',
  'Describe the Residence',
  'License Number',
  'CARF/JAYCO Accreditation Number',
  'House Manager Name',
  'House Manager Phone',
  'House Manage Email',
  'Is house manager compensated for job duties.  If so how.',
  'Does the house manager live in the residence',
  'House manager hours on duty',
  'Number of Clients (residential and outpatient)',
  'Number of Units',
  'Number of Beds',
  'Number of Bedrooms',
  'Number of Bathrooms',
  'Number of Half Bathrooms',
  'Type of Dwelling',
  'Level of Support Provided',
  'Population Served',
  'Are you renting location',
  'If yes, owner name',
  'If yes, owner phone',
  'If yes, owner email',
  'Does residence accept felons',
  'Does residence permit MAT',
  'Is residence abstinence based',
  'Recovery Path',
  'Cost - General',
  'Full Move in Cost',
  'Pro-rated Amount',
  'Administrative Fee Cost',
  'Deposit Amount Cost',
  'First and Last Amount',
  'Billing Frequency',
  'Program Fee',
  'Private Room Cost',
  'Shared Room Cost',
  'Residence Ownership',
  'Owner Written permission',
  'Residence Capacity',
  'Other Available Space',
  'Multiple Fees for accomodations at Residence',
  'Is food included as part of fee',
  'Is property Closed',
  'Gender',
  'Do you manage residence funds',
  'Date Established',
  'Do you offer the same level of service to all locations',
  'Total number of Staff',
  'Number of FT Administrative Staff',
  'Number of Licensed Staff',
  'Number of Certified Staff',
  'Number of Registered staff',
  'Number of FT Support Staff (non-clinical)',
  'Number of FT Support Staff (non-clinical)',
  'Total Part-Time Staff',
  'Total Number of Paid Staff',
  'Total Number of Volunteer Staff',
  'Is residence approved by state DOC',
  'Pool Available',
  'Current status (taking applicants, full, etc',
  'Faith Based',
  'Is substance use testing performed',
  'Financial Arrangements and Opportunities',
  'Do you accept minors',
  'List any special/additional services',
  'Do you have a clinical (PHP or IOP) license from state ',
  'Resident Age Groups',
  'Location (Urban, Rural, Etc)',
  'Bedroom Sq. Ft.',
  'Onsite uring collection for testing',
  'onsite drug testing',
  'Third party service for onsite sample collection',
  'We send samples to a lab for confirmation',
  'Does third party/and or lab bill to insurance',
  'Does your recovery residence bill this cost to insurance',
  'We collect Salivia or other bodily fluids for lab testing',
  'Are children or significant others permitted to live at the residence?',
  'Is social participation required of residents',
  'Is the location accessible to public transportation or is transportation provided',
  'Square Footage',
  'Number of Refrigerators',
  'Does the program limit the use or dosage of any form of medication',
  'Does program provide safe stoarge of all medications',
]

export const CONTACT_QUESTIONS = [
  'Name of Owner/Operator',
  'Contact Name',
  'Contact Person Title',
  'Contact Person Phone Number',
  'Contact Person Email Address',
  'Contact Address',
  'Contact Social Security Number',
  'Contact Birthdate',
  'Credentials/Licenses/Certifications',
  'Previous Training',
  'Billing Contact Name',
  'Billing Contact Address',
  'Billing Contact Phone',
  'Billing Contact Email',
  'Voting Member Name',
  'Voting Member Email',
  'Is Proxy Voting Allowed',
]

export const ORGANIZATION_SECTIONS = [
  {
    title: 'Application and Organization Details',
    questions: [
      'Member/Application Type (new, renewal, etc)',
      'Organization Name',
      'Residence Ownership',
      'DBA Name if applicable',
      'Tax ID',
      'Org Type',
      'State of Incorporation or Organization',
      'Year/Date Founded',
      'Service Locations (counties)',
      'Phone Number',
      'Fax Number',
      'Program Email',
      'Organization Website',
      'Organzation Business Address',
    ],
  },
  {
    title: 'Operational and Ownership Information',
    questions: [
      'Does applicant own/operate alcohol & drug or mental health program/facility',
      'Do any of the owners identified in this application have an ownership interest in or any sort of business relationship with a licensed, independent use, confirmatory lab?',
      'How many residences in application',
      'How many residences does org operate',
      'Willing to fully participate in affiliate in activites',
      'Read and agree to comply with NARR 3.0, requirements, etc',
      'Mission Statement',
      'Vision Statement',
    ],
  },
  {
    title: 'Compliance and Legal Standards',
    questions: [
      'Do you have a written code of ethics',
      'Do you have general liability insurance',
      'Do you comply with all state and federal requirements',
      'Do you maintain an accounting system that fully documents all residnet financial transactions',
      'Do you collect and report accurate process and outcome data',
      'Do you adhere to applicable confidentiality laws',
      'Do you keep resident records secure  with limited staff access',
      'Do you have a grievance policy and procedure',
      'Do you abide by all local building and fire safety codes',
    ],
  },
  {
    title: 'Ownership and Business Relationships',
    questions: [
      'Do any of the owners identified have an ownership interest in behavioral healthcare provider',
      'Do you offer housing scholarships for qualified applicants?',
      'Have you read and understood the Certified Residence requirements?',
      'Have you read, and do you agree to comply with the NARR Standards and Code of Ethics?',
      'Level you are pursuing',
      'Type of Dwelling',
      'Willing to adopt NARR Standards',
      'Describe organization governance and leadership',
    ],
  },
  {
    title: 'Legal and Financial Disclosures',
    questions: [
      'Have you or any org associated with you been charged or convicted of Medicaid Fraud or Abuse?',
      'Number of Beds in Program',
      'Will organization attend training workshops within 90 days of certf',
      'Do you operate other types of housing?',
      'Describe your business',
      'Do you maintain formal standards',
      'Do you agree to adopt the state standards/code of ethics for all residences',
      'Do you have a defined process for resolving complaints',
      'Do you maintain and follow procedures for logging and retaining records of complaints',
      'Have you reviewed the health, safety, and managemetn requirements?',
      'Current status (taking applicants, full, etc',
    ],
  },
  {
    title: 'Services and Specialties',
    questions: [
      'Type of Service/Modality/Speciality/Type you provide (medical, mental health, 12-step, christian, etc)',
      'Substance abouse services provided',
      'Counseling Services Provided',
      'DUI Servicfes Provided',
      'ORS#',
      'Are you willing and able to support -sponsored research',
      'Are you willing and able to contribute financially to the operation of NARR by payment',
      'Do you intend to conform to affiliate requirements',
      'Do you agree to cooperate wtih .... in efforts to resolve complaints received',
      'Recovery Community',
      'Forms of payment accepted',
      'Why do you want to be a member',
      'Do you/your program have any accreditations, certifications, or licenses?',
    ],
  },
  {
    title: 'Membership and Affiliations',
    questions: [
      'Other Memberships/Affiliations/Alliances',
      'Program Name',
      'Existing Program or Future Project',
      'Is organizations programing faith based',
      'Does the program offer special and specific services to meet the needs of any of the following groups (DOC, LGBTQIA+, Veterans)',
      'Has this organization received funding from State/Federal Dollars',
    ],
  },
  {
    title: 'Past Violations and Legal Issues',
    questions: [
      'Has your organization had a revocation of a prior license, certificate, or approval issued within the previous 10 years from any in-State or out-of-State agency or provider. Include previous or current organizations associated with the applicant, along with any associated deficiency reports and compliance records.',
      'Has your organization or a program, partnership, corporation, or provider previously or currently associated with the applicant, surrendered, or defaulted on its license, certificate, or approval, or had it revoked or suspended within the previous 10 years, for any reason?',
      'Has your organization or a program, corporation, or provider previously or currently associated with your organization surrendered or defaulted on its license, certification, or approval within the previous five (5) years for reasons related to disciplinary action?',
    ],
  },
  {
    title: 'Staff and Background Checks',
    questions: [
      'Has any staff member currently associated with your organization had a professional license or certification revoked, suspended, or surrendered for reasons related to disciplinary action or misconduct, within the previous ten (10) years',
      'Has any staff member currently associated with your organization been convicted of a felony, sexual offense, assault, or crime of moral turpitude, within the previous 10 years?',
      'Has any staff member currently associated with your organization been convicted of child abuse or any child sexual abuse?',
      'Has any staff member currently associated with your organization been convicted of abuse or neglect of a vulnerable adult?',
      'Has any staff member currently associated been convicted of a felony within the previous ten (10) years?',
      'Has any staff member currently associated with your organization ever been convicted of a sexual assault, sexual abuse, or second-degree assault?',
      'Does any staff member currently associated with your organization have pending charges for a sexual assault?',
    ],
  },
  {
    title: 'Financial and Funding Information',
    questions: [
      'Has your organization or a program, partnership, corporation, or provider previously or currently associated with the applicant, received funding within the previous 5 years from any federal, state, or local governmental entity to support the construction, renovation, maintenance, and operation of a recovery residence?',
      'Does your organization or the principals owe money to the Maryland Department of Health or one of its Administrations or any Local Designated Authority?',
    ],
  },
  {
    title: 'Ownership and Board Information',
    questions: [
      'Provide a listing of any individual who has a 5 percent or more ownership stake in the recovery residence or legal business entity associated with the recovery residence or, if constituted as a non-profit, a listing of the names, percentage of stake in the recovery residence and contact information of all Board members.',
      'Provide the names of any other recovery residences or legal business entities associated with a recovery residence or similar program in which the applicant or the principals have, or have had, an ownership interest within the previous 10 years.',
    ],
  },
]

export const HOME_LOCATION_SECTIONS = [
  {
    title: 'Residence Information',
    questions: [
      'Name of Residence',
      'Residence Address',
      'County',
      'Describe the Residence',
      'License Number',
      'CARF/JAYCO Accreditation Number',
      'House Manager Name',
      'House Manager Phone',
      'House Manage Email',
    ],
  },
  {
    title: 'House Manager Details',
    questions: [
      'Is house manager compensated for job duties.  If so how.',
      'Does the house manager live in the residence',
      'House manager hours on duty',
    ],
  },
  {
    title: 'Residence Capacity and Features',
    questions: [
      'Number of Clients (residential and outpatient)',
      'Number of Units',
      'Number of Beds',
      'Number of Bedrooms',
      'Number of Bathrooms',
      'Number of Half Bathrooms',
      'Type of Dwelling',
      'Level of Support Provided',
      'Population Served',
    ],
  },
  {
    title: 'Ownership and Rental Information',
    questions: [
      'Are you renting location',
      'If yes, owner name',
      'If yes, owner phone',
      'If yes, owner email',
      'Residence Ownership',
      'Owner Written permission',
    ],
  },
  {
    title: 'Resident Policies',
    questions: ['Does residence accept felons', 'Does residence permit MAT', 'Is residence abstinence based', 'Recovery Path'],
  },
  {
    title: 'Cost and Fees',
    questions: [
      'Cost - General',
      'Full Move in Cost',
      'Pro-rated Amount',
      'Administrative Fee Cost',
      'Deposit Amount Cost',
      'First and Last Amount',
      'Billing Frequency',
      'Program Fee',
      'Private Room Cost',
      'Shared Room Cost',
      'Multiple Fees for accomodations at Residence',
      'Is food included as part of fee',
    ],
  },
  {
    title: 'Residence Capacity and Space',
    questions: ['Residence Capacity', 'Other Available Space', 'Is property Closed'],
  },
  {
    title: 'Demographics and Gender Policies',
    questions: ['Gender', 'Resident Age Groups', 'Do you accept minors'],
  },
  {
    title: 'Staff and Management',
    questions: [
      'Do you manage residence funds',
      'Date Established',
      'Do you offer the same level of service to all locations',
      'Total number of Staff',
      'Number of FT Administrative Staff',
      'Number of Licensed Staff',
      'Number of Certified Staff',
      'Number of Registered staff',
      'Number of FT Support Staff (non-clinical)',
      'Total Part-Time Staff',
      'Total Number of Paid Staff',
      'Total Number of Volunteer Staff',
    ],
  },
  {
    title: 'Legal and Compliance',
    questions: [
      'Is residence approved by state DOC',
      'Faith Based',
      'Is substance use testing performed',
      'Do you have a clinical (PHP or IOP) license from state',
    ],
  },
  {
    title: 'Property Features and Services',
    questions: [
      'Pool Available',
      'Current status (taking applicants, full, etc',
      'List any special/additional services',
      'Location (Urban, Rural, Etc)',
      'Bedroom Sq. Ft.',
      'Onsite uring collection for testing',
      'onsite drug testing',
      'Third party service for onsite sample collection',
      'We send samples to a lab for confirmation',
      'Does third party/and or lab bill to insurance',
      'Does your recovery residence bill this cost to insurance',
      'We collect Salivia or other bodily fluids for lab testing',
    ],
  },
  {
    title: 'Resident Living Conditions',
    questions: [
      'Are children or significant others permitted to live at the residence?',
      'Is social participation required of residents',
      'Is the location accessible to public transportation or is transportation provided',
      'Square Footage',
      'Number of Refrigerators',
    ],
  },
  {
    title: 'Medication and Safety',
    questions: [
      'Does the program limit the use or dosage of any form of medication',
      'Does program provide safe stoarge of all medications',
    ],
  },
]

export const CONTACT_SECTIONS = [
  {
    title: 'Primary Contact Information',
    questions: [
      'Name of Owner/Operator',
      'Contact Name',
      'Contact Person Title',
      'Contact Person Phone Number',
      'Contact Person Email Address',
      'Contact Address',
      'Contact Social Security Number',
      'Contact Birthdate',
      'Credentials/Licenses/Certifications',
      'Previous Training',
    ],
  },
  {
    title: 'Billing Contact Information',
    questions: ['Billing Contact Name', 'Billing Contact Address', 'Billing Contact Phone', 'Billing Contact Email'],
  },
  {
    title: 'Voting Information',
    questions: ['Voting Member Name', 'Voting Member Email', 'Is Proxy Voting Allowed'],
  },
]

export const COMMENTS = [
  {
    index: 1,
    name: 'John Doe',
    comment: "Please clarify what qualifies as 'new' for Member/Application Type.",
    status: 'issue',
  },
  {
    index: 3,
    name: 'Jane Smith',
    comment: 'The Organization Name seems inconsistent with the tax ID record, please verify.',
    status: 'completed',
  },
  {
    index: 5,
    name: 'Alex Johnson',
    comment: 'For Residence Ownership, should this specify the actual physical ownership or management control?',
    status: 'draft',
  },
  {
    index: 7,
    name: 'Emily Davis',
    comment: 'No DBA name provided, please confirm if this is not applicable.',
    status: 'completed',
  },
  {
    index: 7,
    name: 'Michael Brown',
    comment: 'Tax ID format looks incorrect, should include 9 digits.',
    status: 'draft',
  },
  {
    index: 3,
    name: 'Sarah Miller',
    comment: 'Org Type missing. Please specify whether this is a nonprofit or for-profit organization.',
    status: 'issue',
  },
  // {
  //   index: 9,
  //   name: 'David Wilson',
  //   comment: 'State of Incorporation seems to be missing. Could you provide this information?',
  //   status: 'completed',
  // },
  // {
  //   index: 11,
  //   name: 'Olivia Taylor',
  //   comment: 'Year/Date Founded does not match what is listed on your website.',
  //   status: 'draft',
  // },
  // {
  //   index: 16,
  //   name: 'Chris Anderson',
  //   comment: 'Service Locations listed do not match the counties in which services are actually provided.',
  //   status: 'draft',
  // },
  // {
  //   index: 3,
  //   name: 'Sophia Thomas',
  //   comment: 'Please provide a valid Phone Number that includes a working extension if needed.',
  //   status: 'issue',
  // },
  // {
  //   index: 0,
  //   name: 'James Martinez',
  //   comment: 'Fax Number seems outdated, is this still in use?',
  //   status: 'completed',
  // },
  // {
  //   index: 23,
  //   name: 'Emma White',
  //   comment: 'Program Email missing or incorrectly formatted. Please verify.',
  //   status: 'issue',
  // },
  // {
  //   index: 6,
  //   name: 'Daniel Harris',
  //   comment: 'Organization Website link appears broken or inactive.',
  //   status: 'draft',
  // },
  // {
  //   index: 14,
  //   name: 'Grace Clark',
  //   comment: "Business Address doesn't match the one listed on your official documents.",
  //   status: 'issue',
  // },
  // {
  //   index: 8,
  //   name: 'Matthew Lewis',
  //   comment: 'Please confirm if applicant owns or operates alcohol & drug or mental health programs.',
  //   status: 'completed',
  // },
  // {
  //   index: 17,
  //   name: 'Ava Robinson',
  //   comment: 'More details required for ownership interest or business relationships with labs.',
  //   status: 'draft',
  // },
  // {
  //   index: 22,
  //   name: 'Benjamin Walker',
  //   comment: 'Number of residences in application seems too high compared to the provided documentation.',
  //   status: 'draft',
  // },
  // {
  //   index: 4,
  //   name: 'Lucas Young',
  //   comment: 'How many residences does org operate? This section is left blank.',
  //   status: 'issue',
  // },
  // {
  //   index: 15,
  //   name: 'Charlotte Allen',
  //   comment: 'Please clarify your willingness to participate fully in affiliate activities.',
  //   status: 'completed',
  // },
  // {
  //   index: 12,
  //   name: 'Henry King',
  //   comment: 'Ensure that NARR 3.0 compliance is acknowledged.',
  //   status: 'issue',
  // },
  // {
  //   index: 19,
  //   name: 'Victoria Scott',
  //   comment: 'Mission Statement is vague, consider revising for clarity.',
  //   status: 'completed',
  // },
  // {
  //   index: 10,
  //   name: 'Liam Green',
  //   comment: 'Vision Statement missing. Please provide a clear vision for the organization.',
  //   status: 'draft',
  // },
  // {
  //   index: 1,
  //   name: 'Isabella Baker',
  //   comment: 'Code of ethics document not attached, please submit.',
  //   status: 'completed',
  // },
  // {
  //   index: 24,
  //   name: 'Mason Adams',
  //   comment: 'General liability insurance coverage should be specified here.',
  //   status: 'draft',
  // },
  // {
  //   index: 20,
  //   name: 'Mia Nelson',
  //   comment: 'Please provide documentation that shows compliance with state and federal requirements.',
  //   status: 'draft',
  // },
  // {
  //   index: 4,
  //   name: 'Elijah Carter',
  //   comment: 'No information provided regarding resident financial transaction tracking.',
  //   status: 'issue',
  // },
  // {
  //   index: 7,
  //   name: 'Harper Moore',
  //   comment: 'Please confirm how you collect and report process and outcome data.',
  //   status: 'draft',
  // },
]

export const LEAD_APPLICATION_FORM_SCHEMA = {
  organization: {
    show: true,
    fields: {
      name: {
        show: true,
        required: true,
      },
      email: {
        show: true,
        required: false,
      },
      custom_questions: {
        show: false,
      },
    },
  },
  home_location: {
    show: true,
    fields: {
      name: {
        show: true,
        required: true,
      },
      email: {
        show: true,
        required: false,
      },
      custom_questions: {
        show: false,
      },
    },
  },
  personal_details: {
    show: true,
    fields: {
      first_name: {
        show: true,
        required: true,
      },
      middle_name: {
        show: true,
        required: false,
      },
      last_name: {
        show: true,
        required: true,
      },
      email: {
        show: true,
        required: false,
      },
      phone_no: {
        show: true,
        required: false,
      },
      custom_questions: {
        show: false,
      },
    },
  },
}
