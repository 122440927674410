import React from 'react'
import pluralize from 'pluralize'
import { useMedia } from 'use-media'
import { useParams } from 'react-router-dom-v5-compat'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import snakeCase from 'lodash/snakeCase'

import { PlainSwitch } from '../../components/Forms/Switch'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import Checkbox from '../../components/Forms/Checkbox'
import ContextShow from '../../components/Forms/ContextShow'
import DataArray from '../../components/Forms/DataArray'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import RichTextEditor from '../../components/Forms/RichTextEditor'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import State from '../../components/State'
import Status from '../../components/Status'
import Switch from '../../components/Forms/Switch'
import TableArrayForm from '../../components/Forms/TableArrayForm'
import TreeItem from '../../components/TreeItem'

import { AUTHORITY_FORMS } from './authority_forms'
import { AuthorityFormRenderer } from './AuthorityFormRenderer'

export const AuthorityFormBuilder = (props: any) => {
  const {
    cancel,
    close,
    edit,
    form,
    id,
    initialModel,
    isEditable,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    data,
    saveWithData,
  } = useOverlay({
    name: 'TODO',
    endpoint: '/TODO',
    invalidate: 'TODO',
    options: props,
  })

  const isDesktop = useMedia({ minWidth: 1024 })
  const { tenant } = useSettings()
  const authorityName = props.authorityName || tenant?.name

  const { slug } = useParams()
  const category = snakeCase(slug)

  const [formData, setFormData] = React.useState({})
  const [isPreviewVisible, setIsPreviewVisible] = React.useState(false)

  const handlePreviewChange = () => {
    setIsPreviewVisible((c) => !c)
  }

  const debouncedSetFormData = React.useMemo(() => {
    return debounce((value) => {
      setFormData?.(value)
    }, 300)
  }, [])

  const CONFIG = AUTHORITY_FORMS?.[category]

  if (!CONFIG?.sections) return null

  const { title = 'Authority Form', icon = 'authority_forms', sections, isMultiSection, enableComments } = CONFIG

  return (
    <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={isDesktop && isPreviewVisible ? 100 : 50}>
      <Overlay.Header icon={icon} title={title} />

      {isDesktop && (
        <Overlay.SubHeader className="flex items-center justify-end flex-nowrap px-2 !py-1.5">
          <Label isCompact label="Show Preview" onClick={handlePreviewChange} className="!flex-[0_0_auto] cursor-pointer" />
          <PlainSwitch size={200} isChecked={isDesktop && isPreviewVisible} onCheckedChange={handlePreviewChange} />
        </Overlay.SubHeader>
      )}

      <div
        className={clsx(
          'grid grid-rows-[100%] overflow-hidden flex-[1_1_auto]',
          isDesktop && isPreviewVisible ? 'grid-cols-[1fr_600px]' : 'grid-cols-1',
        )}
      >
        <Overlay.Content className={isDesktop && isPreviewVisible ? 'border-r border-0 border-solid border-divider' : ''}>
          <Form
            isCompact
            getForm={form}
            initialModel={initialModel}
            onValidationUpdate={onValidationUpdate}
            isEditable={isEditable}
            onUpdate={debouncedSetFormData}
          >
            <div className="p-5 pt-3 grid gap-4 [--field-max-width:100%]">
              <Input label="Form Name" defaultValue={title} model="name" />

              {isMultiSection
                ? sections.map((mainSection: any, index) => {
                    const { title: mainTitle, sections: subSections } = mainSection

                    return (
                      <React.Fragment key={mainTitle}>
                        {index > 0 && <Divider />}

                        <h2>{mainTitle}</h2>

                        {subSections.map((subSection: any) => {
                          return (
                            <DataFormSection
                              isOpen
                              key={subSection.model}
                              authorityName={authorityName}
                              title={subSection.title}
                              subtitle={subSection.subtitle}
                              isRequired={subSection.isRequired}
                              isEditable={isEditable}
                              model={`schema.${subSection.model}`}
                              sectionId={`section_${subSection.model}`}
                              isPreviewVisible={isDesktop && isPreviewVisible}
                              section={subSection}
                            />
                          )
                        })}
                      </React.Fragment>
                    )
                  })
                : sections.map((section: any) => {
                    return (
                      <DataFormSection
                        isOpen
                        key={section.model}
                        authorityName={authorityName}
                        title={section.title}
                        subtitle={section.subtitle}
                        isRequired={section.isRequired}
                        isEditable={isEditable}
                        model={`schema.${section.model}`}
                        sectionId={`section_${section.model}`}
                        isPreviewVisible={isDesktop && isPreviewVisible}
                        section={section}
                      />
                    )
                  })}
            </div>
          </Form>
        </Overlay.Content>

        {isDesktop && isPreviewVisible && sections && (
          <Overlay.Content>
            <DataFormPreview
              enableComments={false}
              authorityName={authorityName}
              sections={sections}
              schema={formData?.schema}
              isMultiSection={isMultiSection}
            />
          </Overlay.Content>
        )}
      </div>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              flex="100 1 auto"
              permission={isNew ? 'settings.authority_forms.create' : 'settings.authority_forms.edit'}
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Assessment"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.authority_forms.edit"
            />

            {!isNew && data && (
              <>
                {data?.status === 'active' && (
                  <Button
                    label={
                      <span className="flex items-center">
                        <span className="mr-1.5">Deactivate Form</span>
                        <Tooltip content="Hide this form from the list of new client assessments" />
                      </span>
                    }
                    glyph="cross"
                    type="default"
                    color="text"
                    isLoading={isSaving}
                    isDisabled={isLoading}
                    onClick={async () => {
                      await saveWithData({ status: 'inactive' })
                    }}
                    permission="settings.authority_forms.edit"
                  />
                )}

                {data?.status === 'inactive' && (
                  <Button
                    label={
                      <span className="flex items-center">
                        <span className="mr-1.5">Activate Form</span>
                        <Tooltip content="Show this form in the list of new client assessments" />
                      </span>
                    }
                    glyph="cross"
                    type="default"
                    color="green"
                    isLoading={isSaving}
                    isDisabled={isLoading}
                    onClick={async () => {
                      await saveWithData({ status: 'active' })
                    }}
                    permission="settings.authority_forms.edit"
                  />
                )}
              </>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const DataFormPreview = ({ enableComments, authorityName, sections, schema, isMultiSection }) => {
  const previewForm = React.useRef(null)

  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  if (isLoading) return <State isLoading />

  return (
    <Form isCompact getForm={previewForm} className="[--field-max-width:100%]">
      {isMultiSection ? (
        <>
          {sections.map((mainSection: any, index) => {
            if (mainSection.isDataArray) {
              return (
                <React.Fragment key={mainSection.title}>
                  {index > 0 && <Divider />}

                  <Section title={mainSection.title} headingType="h2">
                    <DataArray model={mainSection.model}>
                      {({ orderedIds, add, remove, isEditable }) => (
                        <div className="grid gap-4">
                          {orderedIds?.map((id: string, idx: number) => (
                            <Card key={id}>
                              <CardHeader
                                after={
                                  isEditable && (
                                    <Button
                                      type="minimal"
                                      color="red"
                                      glyph="delete"
                                      label="Remove"
                                      onClick={() => remove(id)}
                                      size={100}
                                    />
                                  )
                                }
                              >
                                <div className="text-text-muted opacity-80 text-[0.85rem] uppercase font-[700] tracking-[1px]">
                                  {pluralize.singular(mainSection.title || 'Record')} #{idx + 1}
                                </div>
                              </CardHeader>

                              <div className="px-3 pb-4">
                                <AuthorityFormRenderer
                                  enableComments={enableComments}
                                  authorityName={authorityName}
                                  parentSection={mainSection}
                                  sections={mainSection.sections}
                                  schema={schema}
                                />
                              </div>
                            </Card>
                          ))}

                          {isEditable && (
                            <div>
                              <Button
                                label={`Add New ${pluralize.singular(mainSection.title || 'Record')}`}
                                glyph="add"
                                type="default"
                                display="inline-flex"
                                size={200}
                                onClick={add}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </DataArray>
                  </Section>
                </React.Fragment>
              )
            }

            return (
              <React.Fragment key={mainSection.title}>
                {index > 0 && <Divider />}

                <Section title={mainSection.title} headingType="h2">
                  <AuthorityFormRenderer
                    enableComments={enableComments}
                    authorityName={authorityName}
                    parentSection={mainSection}
                    sections={mainSection.sections}
                    schema={schema}
                  />
                </Section>
              </React.Fragment>
            )
          })}
        </>
      ) : (
        <AuthorityFormRenderer enableComments={enableComments} authorityName={authorityName} sections={sections} schema={schema} />
      )}
    </Form>
  )
}

const DataFormSection = (props: any) => {
  const { title, children, isRequired, isEditable, model, isOpen, sectionId, isPreviewVisible, section, authorityName } = props
  const [open, setOpen] = React.useState(isOpen)

  const scrollToPreviewSection = () => {
    const element = document?.getElementById(sectionId)

    if (!element) return

    element.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Card className="p-4 pt-3">
      <div className="flex items-center">
        <div className="flex items-center">
          <h3>{title}</h3>

          {isPreviewVisible && (
            <Button label="Preview" glyph="view" size={100} type="minimal" className="mx-1.5" onClick={scrollToPreviewSection} />
          )}
        </div>

        {isEditable && isRequired && <Status label="Required" color="red" css={{ marginLeft: 'auto' }} />}

        <div css={{ marginLeft: 'auto', display: isRequired || !isEditable ? 'none' : 'block' }}>
          <Switch
            withHover={false}
            size={100}
            label={false}
            value={open}
            model={`${model}.show`}
            onUpdate={(state) => setOpen(state.value)}
          />
        </div>
      </div>

      <div style={{ overflow: open ? 'visible' : 'hidden', height: open ? 'auto' : 0, opacity: open ? 1 : 0 }}>
        <div className="grid gap-1.5 mt-3">
          <TreeItem title={<span className="text-[0.95rem] text-blue-500">Custom Text</span>} withHover={false} headerClassName="!p-0 mb-2">
            <RichTextEditor model={`schema.${section.model}.custom_text_before`} withHover={false} />
          </TreeItem>

          <Checkbox
            label="Custom Questions"
            model={`schema.${section.model}.fields.custom_questions_before`}
            trueIcon="check"
            falseIcon="empty_checkbox"
            falseStyle="faded"
          />

          <ContextShow when={`schema.${section.model}.fields.custom_questions_before`} is={true}>
            <div className="pl-5 mt-1.5 mb-2">
              <TableArrayForm
                isCompact
                name="Custom Questions"
                model={`schema.${section.model}.custom_questions_before`}
                inputs={CUSTOM_QUESTION_INPUTS}
              />
            </div>
          </ContextShow>

          {section.fields?.map?.((field: any) => {
            const classes = clsx('grid gap-3', field.hideRequired ? 'grid-cols-1' : 'grid-cols-[1fr_auto_auto]')

            return (
              <div key={`${section.model}-${field.model}`} className={classes}>
                <Checkbox
                  defaultChecked
                  // value={field.isChecked}
                  key={field.model}
                  label={typeof field.label === 'function' ? field.label({ authorityName }) : field.label}
                  description={field.description}
                  model={`schema.${section.model}.fields.${field.model}.show`}
                  isDisabled={field.isDisabled}
                  trueIcon="check"
                  falseIcon="empty_checkbox"
                  falseStyle="faded"
                />

                {!field.hideRequired && (
                  <ContextShow when={`schema.${section.model}.fields.${field.model}.show`} is={true}>
                    <Checkbox
                      defaultChecked
                      // value={field.isRequired}
                      key={field.label}
                      label="Required"
                      model={`schema.${section.model}.fields.${field.model}.required`}
                      isDisabled={field.isDisabled || field.isRequiredDisabled}
                      trueIcon="check"
                      falseIcon="cross"
                      falseStyle="faded"
                    />

                    <Checkbox
                      // defaultChecked
                      // value={field.isRequired}
                      key={field.label}
                      label="Attachments"
                      model={`schema.${section.model}.fields.${field.model}.attachments`}
                      // isDisabled={field.isDisabled || field.isRequiredDisabled}
                      trueIcon="check"
                      falseIcon="cross"
                      falseStyle="faded"
                    />
                  </ContextShow>
                )}
              </div>
            )
          })}

          <Checkbox
            label="Custom Questions"
            model={`schema.${section.model}.fields.custom_questions_after`}
            trueIcon="check"
            falseIcon="empty_checkbox"
            falseStyle="faded"
          />

          <ContextShow when={`schema.${section.model}.fields.custom_questions_after`} is={true}>
            <div className="pl-5 mt-1.5">
              <TableArrayForm
                isCompact
                name="Custom Questions"
                model={`schema.${section.model}.custom_questions_after`}
                inputs={CUSTOM_QUESTION_INPUTS}
              />
            </div>
          </ContextShow>
        </div>
      </div>
    </Card>
  )
}

const CUSTOM_QUESTION_INPUTS = [
  {
    name: 'Label',
    width: '2fr',
    element: (id: string) => {
      return (
        <>
          <Input withHover={false} model={`${id}.label`} placeholder="Enter your question here…" />
          <Input value={id} model={`${id}._id`} className="!hidden" />
        </>
      )
    },
  },
  {
    name: 'Type',
    width: '1fr',
    element: (id: string) => (
      <Select isCompact withHover={false} defaultValue="textarea" model={`${id}.category`}>
        <Option label="Multi-Line Text" value="textarea" />
        <Option label="Single-Line Text" value="input" />
        <Option label="Yes/No" value="true_false" />
        <Option label="Attachments" value="attachments" />
      </Select>
    ),
  },
  {
    name: 'Required',
    width: '1fr',
    element: (id: string) => (
      <Checkbox isCompact label="Required" model={`${id}.is_required`} trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded" />
    ),
  },
]
