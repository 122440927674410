import React from 'react'

import { AdminHRCustomNoteTemplatesDataTable } from '@behavehealth/constructs/CustomNoteTemplates/AdminHRCustomNoteTemplatesDataTable'
import { Page, Grid } from '@behavehealth/components'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'admin_hr_custom_note_templates',
  title: 'Admin HR Custom Note Templates',
}

const AdminHRCustomNoteTemplates = () => {
  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    params: { variant: 'admin_hr' },
    localStorageKey: 'admin_hr_custom_note_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Grid>
        <AdminHRCustomNoteTemplatesDataTable to={(row: any) => row.id} {...tableProps} />
      </Grid>
    </Page>
  )
}

export default withMarketing(AdminHRCustomNoteTemplates, pageConfig)
