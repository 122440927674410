import React from 'react'
import { Link, NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import { ClientAgreementTemplatesDataTable } from '@behavehealth/constructs/AgreementTemplates/ClientAgreementTemplatesDataTable'
import { HelpTagIframe, Page, Tabs } from '@behavehealth/components'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'client_agreement_templates',
  title: 'Client Agreement Templates',
  help: <HelpTagIframe id="settings_agreement_templates" />,
}

const ClientAgreementTemplates = () => {
  const { isBehave, isManagement } = useSettings()

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Tabs defaultTab="support_main_plan">
        <Tabs.List className="-mt-4 mb-3">
          <Tabs.Item as={NavLink} label="Agreements" icon="legal_agreement_alt" to={`agreements`} />

          {(isBehave || isManagement) && (
            <Tabs.Item as={NavLink} label="Admin Agreements" icon="legal_agreement_alt" to={`admin-agreements`} />
          )}
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="agreements" replace />} />

        <Route path={`agreements/*`} element={<AgreementTemplates />} />
        <Route path={`admin-agreements/*`} element={isBehave || isManagement ? <AdminAgreementTemplates /> : null} />
      </Routes>
    </Page>
  )
}

const AgreementTemplates = () => {
  const tableProps = useDataTable({
    name: ['agreement_templates'],
    endpoint: `/agreement_templates`,
    params: { variant: 'client' },
    localStorageKey: 'client_agreement_templates_v1',
  })

  return (
    <ClientAgreementTemplatesDataTable
      title="Client Agreement Templates"
      to={(row: any) => row.id}
      duplicateFormLinkProps={(duplicated) => ({
        as: Link,
        link: 'new',
        state: { data: duplicated },
      })}
      {...tableProps}
    />
  )
}

const AdminAgreementTemplates = () => {
  const tableProps = useDataTable({
    name: ['agreement_templates'],
    endpoint: `/agreement_templates`,
    params: { variant: 'client', agreement_type: 'admin' },
    localStorageKey: 'client_agreement_templates_v1',
  })

  return (
    <ClientAgreementTemplatesDataTable
      title="Client Admin Agreement Templates"
      to={(row: any) => row.id}
      duplicateFormLinkProps={(duplicated) => ({
        as: Link,
        link: 'new',
        state: { data: duplicated },
      })}
      {...tableProps}
    />
  )
}

export default withMarketing(ClientAgreementTemplates, pageConfig)
