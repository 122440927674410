import React from 'react'
import { useParams } from 'react-router-dom'

import { address } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import ContextShow from '../../components/Forms/ContextShow'
import CustomNoteSections from '../../components/Elements/CustomNoteSections'
import DataFormStatus from '../../components/Statuses/DataFormStatus'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Dialog from '../../components/Dialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignaturePad from '../../components/Forms/SignaturePad'
import StaffProfileHeader from '../../components/StaffProfileHeader'
import TextareaDialog from '../../components/Dialogs/TextareaDialog'
import Timeline from '../../components/Timeline/Timeline'
import TooltipButton from '../../components/TooltipButton'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'

const RootStaffCustomNoteOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    saveWithData,
  } = useOverlay({
    name: 'custom_note',
    endpoint: '/custom_notes',
    invalidate: 'custom_notes',
    options: props,
    openAfterCreate: true,
  })

  const params: any = useParams()

  const { user, tenant, timezone, isPortal, isBehave, isOwner } = useSettings()

  const { data: employee }: any = useGet({
    name: ['employee', params.resource_id],
    url: `/employees/${params.resource_id}`,
  })

  const onDisabledClick = () => {
    form.current.validate()
  }

  const sendForSupervisorReview = async (signature) => {
    await saveWithData({
      [signature.model]: signature.value,
    })
  }

  const sendForSupervisorReviewWithoutSignature = async () => {
    await saveWithData({
      status: 'pending_review',
    })
  }

  const requestUpdates = async (updates) => {
    await saveWithData({
      status: 'updates_required',
      request_updates: updates.value,
    })
  }

  const signOff = async (signature) => {
    await saveWithData({
      [signature.model]: signature.value,
    })
  }

  const signOffWithoutSignature = async () => {
    await saveWithData({
      status: 'signed_off',
    })
  }

  if (isOverlayLoading || !employee) {
    return <OverlayLoader position="right" maxWidth={82} />
  }

  const isSupervisor = user?.id === data?.supervisor?.id
  const isTrialing = tenant?.plan_status === 'trialing'

  return (
    <Overlay
      onClose={close}
      showBackdrop={isNew || isEditable}
      position="right"
      maxWidth={82}
      closeWrapper={(element) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost. This action cannot be undone."
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={close}
          skip={!isEditable}
        >
          {element}
        </Dialog>
      )}
    >
      <Overlay.Header
        icon="custom_notes"
        title={isNew ? 'New Custom Note' : data?.name}
        titleAside={<DataFormStatus status={data?.status} />}
        description={<StaffProfileHeader employee={data?.employee || employee} />}
      />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/custom_notes/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          linked={{
            reference_id: employee?.id,
            reference_type: employee?.type,
            variant: 'staff',
          }}
        >
          <Section title="Custom Note Details" commentsModel="settings.comments" commentsName="custom_notes" commentsURL="/custom_notes">
            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
                className="!grow-[2]"
              />

              <Flex gap={8} alignItems="flex-end">
                <OverlaySelector
                  isPolymorphic
                  className="!flex-auto"
                  label="Supervisor"
                  blankLabel="Select Supervisor…"
                  icon="employees"
                  type="employees.active"
                  model="supervisor"
                  selectTitle={(data) => data?.name}
                  selectDescription={() => null}
                  disableLink={isPortal}
                  validations={{
                    presence: {
                      message: 'Please select a Supervisor',
                    },
                  }}
                />

                <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox label="Require Supervisor Signature" model="settings.require_supervisor_signature" />
                </CheckboxGroup>
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <Select label="Status" model="status" layout="vertical-dense" defaultValue="draft">
                  <Option label="Draft" value="draft" />
                  <Option label="Pending Review" value="pending_review" />
                  <Option label="Updates Required" value="updates_required" />
                  <Option label="Signed Off" value="signed_off" />
                  <Option label="Completed" value="completed" />
                  <Option label="Closed" value="closed" />
                </Select>

                <DateTimeInput
                  defaultToNow
                  model="started_at"
                  label="Start Date and Time"
                  validations={{
                    presence: {
                      message: 'Please enter a date and time',
                    },
                  }}
                />

                <DateTimeInput model="ended_at" label="End Date and Time" />
              </Flex>

              <MiniRichTextEditor useQuickText label="Description" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <CustomNoteSections
            model="sections"
            validations={{
              presence: {
                message: 'Please add at least one section',
              },
            }}
          />

          <Section headingType="h2" title="Places">
            <FormSection maxWidth="100%" layout="vertical">
              <ObjectSelector
                model="house"
                label="Location"
                type="properties"
                icon={ICONS.properties}
                selectTitle={(data) => data?.name}
                selectDescription={(data) => address(data?.address)}
              />

              <ObjectSelector
                model="organization"
                label="Organization"
                type="organizations"
                icon={ICONS.organizations}
                selectTitle={(data) => data?.name}
                selectDescription={(data) => address(data?.address)}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section
            headingType="h2"
            title="Signatures"
            aside={
              <CheckboxGroup layout="horizontal-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                <Checkbox label="Staff Signature" model="settings.require_reference_signature" />
                <Checkbox label="Co-Signer Signature" model="settings.require_staff_signature" />
              </CheckboxGroup>
            }
          >
            <FormSection layout="vertical" maxWidth={500} className="!mt-4">
              <ContextShow when="settings.require_reference_signature" is={true}>
                <SignaturePad
                  className="!max-w-[650px]"
                  label="Staff Signature"
                  person={data?.reference}
                  model="reference_signature"
                  signedAtModel="reference_signed_at"
                  allowPin={false}
                />
              </ContextShow>

              <ContextShow when="settings.require_staff_signature" is={true}>
                <SignaturePad
                  className="!max-w-[650px]"
                  label="Co-Signer Signature"
                  person={data?.author}
                  allowPin={false}
                  model="author_signature"
                  signedAtModel="author_signed_at"
                />
              </ContextShow>

              {data?.supervisor_signature && (
                <SignaturePad
                  className="!max-w-[650px]"
                  label="Supervisor Signature"
                  person={data?.supervisor}
                  allowPin={false}
                  model="supervisor_signature"
                  signedAtModel="supervisor_signed_at"
                />
              )}
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this shift note">
            <FormSection maxWidth="100%" layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!isNew && data && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {(data?.status === 'closed' || data?.status === 'signed_off') && (isBehave || isOwner) && (
        <Overlay.Footer>
          <DeleteDialog
            title="Delete Custom Note?"
            message={`Are you sure you want to delete this custom note? This action cannot be undone.`}
            onYes={deleteRecord}
          >
            <Button
              glyph="delete"
              label="Delete Custom Note…"
              type="default"
              color="red"
              isDisabled={isSaving}
              fullWidth
              permission={isBehave || isOwner}
            />
          </DeleteDialog>
        </Overlay.Footer>
      )}

      {data?.status !== 'signed_off' && !isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label={isNew ? 'Save as Draft' : 'Save Changes'}
                glyph="check"
                type="primary"
                color="green"
                onClick={save}
                isLoading={isSaving}
                isDisabled={isSaving}
                flex="100 1 auto"
                permission="staff_custom_notes.create"
              />

              {isInvalid && (
                <Button
                  label="Highlight Required Fields"
                  glyph="view"
                  type="default"
                  color="orange"
                  onClick={onDisabledClick}
                  isDisabled={isSaving}
                />
              )}

              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              {data?.status === 'draft' && (
                <>
                  <Button
                    glyph="edit"
                    label="Edit Custom Note"
                    type="default"
                    isDisabled={isSaving}
                    onClick={edit}
                    flex="100 1 auto"
                    permission="staff_custom_notes.edit"
                  />

                  <DeleteDialog
                    title="Delete Custom Note?"
                    message={`Are you sure you want to delete this custom note? This action cannot be undone.`}
                    onYes={deleteRecord}
                  >
                    <Button
                      fullWidth
                      glyph="delete"
                      label="Delete Custom Note…"
                      type="default"
                      color="red"
                      isDisabled={isSaving}
                      permission="staff_custom_notes.delete"
                    />
                  </DeleteDialog>

                  {!isSupervisor && (
                    <>
                      {data?.settings?.require_staff_signature ? (
                        <SignatureDialog
                          model="author"
                          title="Sign & Send for Review"
                          yesLabel="Apply Signature & Send for Review"
                          onYes={sendForSupervisorReview}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Sign & Send for Supervisor Review…"
                            glyph="signature"
                            type="default"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </SignatureDialog>
                      ) : (
                        <ConfirmDialog
                          model="supervisor"
                          title="Send for Supervisor Review"
                          yesLabel="Send for Review"
                          onYes={sendForSupervisorReviewWithoutSignature}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Send for Supervisor Review…"
                            glyph="check"
                            type="default"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </ConfirmDialog>
                      )}
                    </>
                  )}

                  {isSupervisor && (
                    <>
                      {data?.settings?.require_supervisor_signature ? (
                        <SignatureDialog
                          model="supervisor"
                          title="Sign Off as Supervisor"
                          yesLabel="Apply Signature & Sign Off"
                          onYes={signOff}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Sign Off as Supervisor…"
                            glyph="signature"
                            type="primary"
                            color="green"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </SignatureDialog>
                      ) : (
                        <ConfirmDialog
                          model="supervisor"
                          title="Sign Off as Supervisor"
                          yesLabel="Sign Off"
                          onYes={signOffWithoutSignature}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Sign Off as Supervisor…"
                            glyph="check"
                            type="primary"
                            color="green"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </ConfirmDialog>
                      )}
                    </>
                  )}
                </>
              )}

              {data?.status === 'pending_review' && (
                <>
                  <Button
                    glyph="edit"
                    label="Edit Custom Note"
                    type="default"
                    isDisabled={isSaving}
                    onClick={edit}
                    flex="100 1 auto"
                    permission="staff_custom_notes.edit"
                  />

                  <DeleteDialog
                    title={`Delete Custom Note?`}
                    message={`Are you sure you want to delete this custom note? This action cannot be undone.`}
                    onYes={deleteRecord}
                  >
                    <Button
                      glyph="delete"
                      label={`Delete Custom Note…`}
                      type="default"
                      color="red"
                      isDisabled={isSaving}
                      fullWidth
                      permission="staff_custom_notes.delete"
                    />
                  </DeleteDialog>

                  {isSupervisor && (
                    <TextareaDialog
                      model="request_updates"
                      title="What updates should be made?"
                      onYes={requestUpdates}
                      yesLabel="Ask For Updates"
                    >
                      <Button label="Request Updates…" type="default" isDisabled={isSaving} permission="staff_custom_notes.edit" />
                    </TextareaDialog>
                  )}

                  {isSupervisor && (
                    <>
                      {data?.settings?.require_supervisor_signature ? (
                        <SignatureDialog
                          model="supervisor"
                          title="Sign Off as Supervisor"
                          yesLabel="Apply Signature & Sign Off"
                          onYes={signOff}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Sign Off as Supervisor…"
                            glyph="signature"
                            type="primary"
                            color="green"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </SignatureDialog>
                      ) : (
                        <ConfirmDialog
                          model="supervisor"
                          title="Sign Off as Supervisor"
                          yesLabel="Sign Off"
                          onYes={signOffWithoutSignature}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Sign Off as Supervisor…"
                            glyph="check"
                            type="primary"
                            color="green"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </ConfirmDialog>
                      )}
                    </>
                  )}
                </>
              )}

              {data?.status === 'updates_required' && (
                <>
                  <Button
                    glyph="edit"
                    label={`Edit Custom Note`}
                    type="default"
                    isDisabled={isSaving}
                    onClick={edit}
                    flex="100 1 auto"
                    permission="staff_custom_notes.edit"
                  />

                  <DeleteDialog
                    title={`Delete Custom Note?`}
                    message={`Are you sure you want to delete this custom note? This action cannot be undone.`}
                    onYes={deleteRecord}
                  >
                    <Button
                      fullWidth
                      glyph="delete"
                      label={`Delete Custom Note…`}
                      type="default"
                      color="red"
                      isDisabled={isSaving}
                      permission="staff_custom_notes.delete"
                    />
                  </DeleteDialog>

                  {!isSupervisor && (
                    <>
                      {data?.settings?.require_staff_signature ? (
                        <SignatureDialog
                          model="author"
                          title="Sign & Send for Review"
                          yesLabel="Apply Signature & Send for Review"
                          onYes={sendForSupervisorReview}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Sign & Send for Supervisor Review…"
                            glyph="signature"
                            type="default"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </SignatureDialog>
                      ) : (
                        <ConfirmDialog
                          model="supervisor"
                          title="Send for Supervisor Review"
                          yesLabel="Send for Review"
                          onYes={sendForSupervisorReviewWithoutSignature}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Send for Supervisor Review…"
                            glyph="check"
                            type="default"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </ConfirmDialog>
                      )}
                    </>
                  )}

                  {isSupervisor && (
                    <>
                      {data?.settings?.require_supervisor_signature ? (
                        <SignatureDialog
                          model="supervisor"
                          title="Sign Off as Supervisor"
                          yesLabel="Apply Signature & Sign Off"
                          onYes={signOff}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Sign Off as Supervisor…"
                            glyph="signature"
                            type="primary"
                            color="green"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </SignatureDialog>
                      ) : (
                        <ConfirmDialog
                          model="supervisor"
                          title="Sign Off as Supervisor"
                          yesLabel="Sign Off"
                          onYes={signOffWithoutSignature}
                          isDisabled={isSaving || isInvalid}
                          onDisabledClick={onDisabledClick}
                        >
                          <TooltipButton
                            fullWidth
                            label="Sign Off as Supervisor…"
                            glyph="check"
                            type="primary"
                            color="green"
                            isDisabled={isSaving || isInvalid}
                            permission="staff_custom_notes.edit"
                            show={isInvalid}
                            message="Click to highlight required fields"
                          />
                        </ConfirmDialog>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const StaffCustomNoteOverlay = withOverlayError(RootStaffCustomNoteOverlay)
