import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { apiDelete } from '../../modules/api'
import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../Filters/constants'
import { invalidateQueries } from '../../hooks/useNewAPI'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import Button from '../../components/Button'

const STATUSES = {
  active: 'Active',
  inactive: 'Inactive',
}

export const StaffAllowedIPsDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'IP',
        model: 'ip',
        width: 160,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} as={NavLink} to={to ? to(data) : undefined} value={value} />,
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => STATUSES[value],
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Actions',
        model: 'actions',
        disableSort: true,
        hoverExpand: false,
        width: 180,
        formatValue: ({ data }: any) => (
          <ConfirmDialog
            title="Remove IP?"
            message={`Remove ${data.ip} from the whitelist? This IP will not be able to access their profile anymore.`}
            yesLabel="Remove"
            noLabel="Cancel"
            yesColor="red"
            glyph="delete"
            onYes={() => {
              apiDelete({ name: 'allowed_ips', url: `/allowed_ips/${data.id}` })
              invalidateQueries(null, 'allowed_ips')
            }}
          >
            <Button label="Remove" type="default" color="red" glyph="delete" size={100} />
          </ConfirmDialog>
        ),
      },
    ],
    [to],
  )

  return <DataTable asCard title="IP Addresses" icon="permissions" columns={columns} filtersConfig={FILTERS_CONFIG} {...rest} />
}

const FILTERS_CONFIG = {
  ip: {
    label: 'IP',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
