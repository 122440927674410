import React from 'react'
import { useParams } from 'react-router-dom'

import { Page, PageLayout, HelpTagIframe } from '@behavehealth/components'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { VOBDataTable } from '@behavehealth/constructs/VOB/VOBDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'full_vobs',
  title: 'VOBs',
}

const VOB = ({ match }: any) => {
  const { resource_id: clientId }: any = useParams()

  const tableProps = useDataTable({
    name: ['client', clientId, 'insurance_vobs'],
    endpoint: `/residents/${clientId}/insurance_vobs`,
    params: { version: 'v1' },
    enabled: !!clientId,
    localStorageKey: 'client_insurance_vobs_archived_v1',
  })

  return (
    <Page icon="insurance" title="VOBs" help={<HelpTagIframe id="client_behave_vobs" />}>
      <PageLayout>
        <VOBDataTable {...tableProps} to={(row: any) => `${match.url}/${row.id}`} />
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(VOB, pageConfig))
