import React from 'react'

import { GrievanceCustomNoteTemplatesDataTable } from '@behavehealth/constructs/CustomNoteTemplates/GrievanceCustomNoteTemplatesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

const GrievanceCustomNoteTemplates = () => {
  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    params: { variant: 'grievance' },
    localStorageKey: 'grievance_custom_note_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} title="Grievance Custom Note Templates" icon="grievance_notes">
      <Grid>
        <GrievanceCustomNoteTemplatesDataTable to={(row: any) => row.id} {...tableProps} />
      </Grid>
    </Page>
  )
}

export default GrievanceCustomNoteTemplates
