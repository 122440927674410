import React from 'react'
import { useParams } from 'react-router-dom'

import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'

const RootAllergyOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'allergies',
    endpoint: '/allergies',
    invalidate: 'allergies',
    options: props,
    openAfterCreate: true,
  })

  const { resource_id }: any = useParams()
  const { timezone } = useSettings()

  const clientId = props.clientId || resource_id

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
    options: { enabled: !!clientId },
  })

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable}>
      <Overlay.Header icon="treatment_data" title="Allergy" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              reference_id: client?.id,
              reference_type: client?.type,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <Flex gap="1rem" stretchChildrenX>
                <Select label="Status" model="status" defaultValue="active">
                  <Option label="Active" value="active" />
                  <Option label="Inactive" value="inactive" />
                  <Option label="Canceled" value="canceled" />
                  <Option label="Resolved" value="resolved" />
                </Select>

                <Select label="Category" model="category" defaultValue="drug">
                  <Option label="Drug" value="drug" />
                  <Option label="Food" value="food" />
                  <Option label="Environmental" value="environmental" />
                </Select>

                <Select label="Source" model="source" isEditable={false} defaultValue="internal">
                  <Option label="Custom" value="internal" />
                  <Option label="eRX" value="erx" />
                </Select>
              </Flex>

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please add a name',
                  },
                }}
              />

              <Input
                label="Risk"
                model="criticality"
                description="The potential seriousness of a future reaction. This represents a clinical judgment about the worst-case scenario for a future reaction. For example, a severe rash may have a low risk (or criticality) of a life-threatening reaction."
              />

              <Flex gap="1rem" stretchChildrenX>
                <Input label="Severity" model="severity" />

                <Select label="Reaction Type" model="reaction_type" defaultValue="allergy">
                  <Option label="Allergy" value="allergy" />
                  <Option label="Intolerance" value="intolerance" />
                </Select>
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <DateInput
                  defaultToNow
                  model="started_on"
                  label="Onset On"
                  validations={{
                    presence: {
                      message: 'Please enter the date and time of contact',
                    },
                  }}
                />

                <DateInput model="ended_on" label="Resolved On" />
              </Flex>

              <Textarea label="Description" model="description" />

              <Textarea label="Reactions" model="reactions" />

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Allergy?"
              message="Are you sure you want to delete this Allergy? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AllergyOverlay = withOverlayError(RootAllergyOverlay)
