import React from 'react'

import { usePermissions } from '../../hooks/usePermissions'

import Select from '../../components/Forms/Select'
import Option from '../../components/Forms/Option'
import useSettings from '../../hooks/useSettings'

export const OrganizationRelationshipTypeSelect = (props) => {
  const { isBehave } = useSettings()
  const hasAuthorityFeatureFlag = usePermissions({ featureFlag: 'authority_organizations' })

  return (
    <Select label="Organization Relationship Type" defaultValue="internal" {...props}>
      <option disabled />
      <Option label="Internal" value="internal" />
      <Option label="Provider" value="provider" />
      <Option label="Vendor" value="vendor" />
      {(isBehave || hasAuthorityFeatureFlag) && <Option label="Authority" value="authority" />}
    </Select>
  )
}
