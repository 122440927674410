import React from 'react'
import { useQueryClient } from 'react-query'
import size from 'lodash/size'

import { minutesBetween } from '../../../../../utils/functions'
import { useCreate, getPrefix } from '../../../../../hooks/useNewAPI'

import SummonOverlay from '../../../../SummonOverlay'

import { DailyNoteIndividualOverlay } from '../../../../../constructs/DailyNotes/DailyNoteIndividualOverlay'
import { DailyNoteOutcomeItem } from '../common/DailyNoteOutcomeItem'
import { EventOutcomesFooter } from '../EventOutcomesFooter'
import { getClientAttendance } from '../common/functions'

type Props = {
  event: any
  outcomes?: any
}

export const IndividualDailyNoteOutcome = ({ event, outcomes = [] }: Props) => {
  const queryClient = useQueryClient()

  return (
    <>
      {outcomes.map((outcome: any) => {
        const data = outcome?.reference

        if (!data) return null

        return (
          <SummonOverlay
            key={outcome?.id}
            permission="daily_notes.view"
            overlay={
              <DailyNoteIndividualOverlay
                showBackdrop
                dataID={data.id}
                invalidateKeys={[['event', event?.id]]}
                position="center"
                initialData={data}
                onSaveSuccessful={() => {
                  queryClient.invalidateQueries([getPrefix(), 'event', event?.id])
                }}
                onDeleteSuccessful={() => {
                  queryClient.invalidateQueries([getPrefix(), 'event', event?.id])
                }}
              />
            }
          >
            <DailyNoteOutcomeItem data={data} />
          </SummonOverlay>
        )
      })}

      <AddMore event={event} />
    </>
  )
}

export const AddMore = ({ event, className }: Props) => {
  const { mutateAsync, isLoading: isSaving }: any = useCreate({
    name: ['event-outcomes'],
    url: `/event_outcomes`,
    invalidate: ['event', event?.id],
    invalidateKeys: ['events'],
  })

  if (!event) return null

  const client = event.resident ? event.resident : size(event?.residents) > 0 && event.residents[0]

  return (
    <SummonOverlay
      overlay={
        <DailyNoteIndividualOverlay
          dataID="new"
          position="center"
          isSaving={isSaving}
          initialData={{
            client,
            started_at: event.started_at,
            duration: event.start_time && event.end_time ? minutesBetween(event.start_time, event.end_time) : null,
            ...getClientAttendance(client?.id, event.event_attendances),
          }}
          save={async (data: any) => {
            await mutateAsync({
              data: data,
              event_id: event?.id,
              category: 'individual_daily_note',
            })
          }}
        />
      }
    >
      <EventOutcomesFooter addLabel="Add Individual Daily Note" permission="daily_notes.create" className={className} />
    </SummonOverlay>
  )
}

export default IndividualDailyNoteOutcome
