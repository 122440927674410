import React from 'react'

import Radio from '../Radio'
import RadioGroup from '../RadioGroup'
import Textarea from '../Textarea'
import ContextShow from '../ContextShow'
import snakeCase from 'lodash/snakeCase'

const YesNoRadioGroupWithTextarea = ({
  isRequired,
  label,
  model,
  textareaDescription,
  textareaLabel,
  textareaModel,
  textareaRequiredMessage,
  showTextareaWhen = true,
  testKey,
  validations,
  description,
  withHover,
}: any) => (
  <>
    <RadioGroup
      testKey={testKey}
      label={label}
      model={model}
      description={description}
      withHover={withHover}
      validations={{
        ...(isRequired && {
          presence: {
            message: 'Please select a response',
          },
        }),
        ...validations,
      }}
      layout="horizontal-dense"
    >
      <Radio label="Yes" value={true} />
      <Radio label="No" value={false} />
    </RadioGroup>

    <ContextShow when={model} is={showTextareaWhen}>
      <Textarea
        testKey={`input_more_detail_${testKey}`}
        label={textareaLabel}
        description={textareaDescription}
        model={textareaModel || `${model}_description`}
        validations={
          isRequired && {
            presence: {
              message: textareaRequiredMessage,
            },
          }
        }
      />
    </ContextShow>
  </>
)

YesNoRadioGroupWithTextarea.defaultProps = {
  textareaLabel: 'Please Describe',
  textareaRequiredMessage: 'Please Describe',
}

export default YesNoRadioGroupWithTextarea
