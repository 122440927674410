import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import StateSelect from '../../components/Forms/elements/StateSelect'
import Textarea from '../../components/Forms/Textarea'

const RootCompanyCredentialOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'credentials',
    endpoint: '/credentials',
    invalidate: 'credentials',
    options: props,
  })

  const { tenant, timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable}>
      <Overlay.Header icon="staff_credentials" title="Credential" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              reference_id: tenant?.id,
              reference_type: tenant?.type,
              category: 'custom',
            }
          }
        >
          <Section>
            <FormSection maxWidth="100%">
              <Input label="Credential" description="e.g. A-GNP" model="credential" />
              <Input label="Certification" description="e.g. Adult-Gerontology Primary Care Nurse Practitioner" model="certification" />
              <Input label="Organization" description="e.g. American Academy of Nurse Practitioners" model="organization" />

              <Flex gap="1rem" stretchChildrenX>
                <Select allowEmpty label="Status" model="status">
                  <Option label="Current" model="current" value="current" />
                  <Option label="Renewed & Current" model="renewed_and_current" value="renewed_and_current" />
                  <Option label="Expired" model="expired" value="expired" />
                </Select>

                <Select allowEmpty label="Level" model="level">
                  <Option label="National" value="national" />
                  <Option label="Regional" value="regional" />
                  <Option label="State" value="state" />
                  <Option label="Association" value="association" />
                </Select>
              </Flex>

              <ContextShow when="level" is={'state'}>
                <Flex gap="1rem" stretchChildrenX>
                  <StateSelect
                    model="state"
                    validations={{
                      presence: {
                        message: 'Please select a state',
                      },
                    }}
                  />
                </Flex>
              </ContextShow>

              <Flex gap="1rem" stretchChildrenX>
                <DateInput label="Issue Date" model="issued_at" />
                <DateInput label="Expiration Date" model="expires_at" />
              </Flex>

              <Flex gap="1rem">
                <Input label="License Number" model="license_number" className="!flex-1" />
                <Input label="Graduation Year" size={6} model="graduation_year" />
              </Flex>

              <CheckboxGroup layout="vertical-dense">
                <Checkbox label="Requires Continued Education" model="continued_education_required" />
              </CheckboxGroup>

              <ContextShow when="continued_education_required" is={true}>
                <Flex gap="1rem" stretchChildrenX>
                  <Input type="number" min="0" label="Current Credits" model="current_credits" defaultValue={0} />
                  <Input type="number" min="0" label="Required Annual Credits" model="required_credits" defaultValue={12} />
                </Flex>
              </ContextShow>

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Email Reminders" description="Notify credential expiry">
            <FormSection maxWidth="100%" layout="vertical">
              <Checkbox label="Send Email Reminders" model="should_send_reminders" />
              <ContextShow when="should_send_reminders" is={true}>
                <div className="!pl-6">
                  <CheckboxGroup
                    label="Send Reminders Options"
                    layout="vertical-dense"
                    validations={{
                      presence: {
                        message: 'Please select at least one option',
                      },
                    }}
                  >
                    <Checkbox label="6 months before expiry" model="send_reminders.six_months" />
                    <Checkbox label="3 months before expiry" model="send_reminders.three_months" />
                    <Checkbox label="1 month before expiry" model="send_reminders.one_month" />
                    <Checkbox label="2 weeks before expiry" model="send_reminders.two_weeks" />
                    <Checkbox label="1 week before expiry" model="send_reminders.one_week" />
                    <Checkbox label="1 day before expiry" model="send_reminders.one_day" />
                  </CheckboxGroup>
                </div>
              </ContextShow>
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload files related to this Credential">
            <FormSection maxWidth="100%" layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save Credential"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="settings.credentials.create"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Credential"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.credentials.edit"
            />

            <DeleteDialog
              title="Delete Credential?"
              message="Are you sure you want to delete this credential? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="settings.credentials.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const CompanyCredentialOverlay = withOverlayError(RootCompanyCredentialOverlay)
