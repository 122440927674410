import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../Filters/constants'
import { MainCell } from '../../components/DataTable/cells/MainCell'

export const ProgramCustomNoteTemplatesDataTable = (props: any) => {
  const { to, onClick, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 240,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            as={NavLink}
            to={to ? to(data) : undefined}
            onClick={onClick ? () => onClick(data) : undefined}
            value={value}
          />
        ),
      },
      {
        title: 'Supervisor Signature',
        id: 'require_supervisor_signature',
        model: 'settings.require_supervisor_signature',
        type: 'boolean',
        width: 160,
      },
      {
        title: 'Staff Signature',
        id: 'require_staff_signature',
        model: 'settings.require_staff_signature',
        type: 'boolean',
        width: 160,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to, onClick],
  )

  return (
    <DataTable
      asCard
      title="Program Custom Note Templates"
      icon="custom_notes"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  require_supervisor_signature: {
    label: 'Supervisor Signature',
    type: 'boolean',
  },
  require_staff_signature: {
    label: 'Staff Signature',
    type: 'boolean',
  },
  author: DEFAULT_FILTERS.author,
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
