import React from 'react'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { address } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordions from '../../components/Accordions'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ColorSelector from '../../components/Forms/ColorSelector'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Label from '../../components/Label'
import Grid from '../../components/Grid'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import Links from '../../components/Forms/Links'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Subtasks from '../../components/Forms/Subtasks'
import TaskDisplayStatus from '../../components/Statuses/TaskDisplayStatus'
import TimeInputSelector from '../../components/Forms/TimeInputSelector/TimeInputSelector'
import URLInput from '../../components/Forms/URLInput'

import { JoinMeetingButton } from '../../components/Buttons/JoinMeetingButton'

const RootAuthorityOrganizationTodoOverlay: React.FC<any> = (props) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialData,
    isDeleting,
    isEditable,
    isEmpty,
    isInvalid,
    isLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'todo',
    endpoint: '/events',
    invalidate: 'todos',
    options: props,
    disableParentRequest: true,
    headers: {
      'X-Request-Level': 'global',
    },
  })

  const { maxWidth = 40, position = 'right', showBackdrop = false } = props
  const { timezone } = useSettings()
  const [formData, setFormData] = React.useState(initialData)

  if (isLoading || isEmpty) {
    return <OverlayLoader position={position} maxWidth={maxWidth} showBackdrop={showBackdrop} />
  }

  const hasOrganizations = size(formData?.organization_ids) > 0

  return (
    <Overlay maxWidth={37} onClose={close}>
      <Overlay.Header
        icon="tasks"
        title="To-Do"
        titleAside={<TaskDisplayStatus status={data.display_status} />}
        help={<HelpTagIframe id="todo" />}
      />

      {data?.personal_meeting_room?.id && (
        <Permission featureFlagV2="telehealth" permission="telehealth.view">
          <Overlay.SubHeader>
            <JoinMeetingButton
              type="link"
              meetingID={data?.personal_meeting_room?.id}
              meetingName={`${data.title} – Todo Meeting Room`}
              label="Join Meeting Room"
              size={200}
            />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{ ...initialData, ...data }}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
          key={`updated-${data?.updated_at}`}
          onUpdate={setFormData}
        >
          <Section>
            <FormSection layout="vertical" maxWidth="100%">
              <Input
                label="Title"
                model="title"
                validations={{
                  presence: {
                    message: 'Please enter a task title',
                  },
                }}
              />

              <Flex gap="1rem">
                <div className="!flex-auto">
                  <Select model="priority" label="Priority" fullWidth defaultValue="normal">
                    <Option label="Low" value="low" />
                    <Option label="Normal" value="normal" />
                    <Option label="High" value="high" />
                  </Select>
                </div>

                <ColorSelector label="Color" model="color" />
              </Flex>

              <DateInput
                label="Due Day"
                model="started_at"
                validations={{
                  presence: {
                    message: 'Please select a task date',
                  },
                }}
              />

              <ContextShow when="is_all_day" is={false}>
                <FormSection horizontal>
                  <TimeInputSelector
                    grow
                    defaultToNow
                    label="Due By"
                    model="start_time"
                    validations={{
                      presence: {
                        message: 'Please select when the task starts',
                      },
                    }}
                  />
                </FormSection>
              </ContextShow>

              <Checkbox label="All Day Task" model="is_all_day" defaultValue={false} />

              <MiniRichTextEditor useDictation useQuickText label="Public Description" model="public_description" />

              <div>
                <Accordions>
                  <Accordions.Item title="Tasks" isOpen={size(data.subtasks)}>
                    <Subtasks endpoint="events" model="subtasks" taskId={data?.id} />
                  </Accordions.Item>

                  <Accordions.Item title="Staff" isOpen={size(data.employees)}>
                    <FormSection>
                      <MultiOverlaySelector
                        model="employees"
                        type="employees.active"
                        icon={ICONS.employees}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => startCase(data.position)}
                      />
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Organizations">
                    <FormSection>
                      <MultiOverlaySelector
                        label="Organizations"
                        model="organizations"
                        type="organizations.all"
                        icon={ICONS.organizations}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => startCase(data.status)}
                      />

                      <MultiOverlaySelector
                        label="Organization Contacts"
                        description="Select Contacts from the Organizations selected above"
                        model="organization_contacts"
                        type="all_organization_contacts"
                        dependentValue={formData?.organization_ids}
                        icon={ICONS.organizations}
                        isDisabled={!hasOrganizations}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => startCase(data.status)}
                      />
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Notifications" isOpen={data?.should_send_reminders}>
                    <FormSection>
                      <Alert glyph="info" type="positive">
                        Notifications are being sent to the above <b>Staff</b>
                      </Alert>

                      <Label label="Time-based Reminders:" className="!pb-0" />

                      <Checkbox
                        label="Send SMS and Email Reminders"
                        model="should_send_reminders"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded-linethrough"
                        disabled={true}
                      />

                      <ContextShow when="should_send_reminders" is={true}>
                        <div className="!pl-6">
                          <CheckboxGroup
                            label="Send Reminders Options"
                            layout="vertical-dense"
                            disabled={true}
                            validations={{
                              presence: {
                                message: 'Please select at least one option',
                              },
                            }}
                          >
                            <Checkbox label="Three days before the to-do" model="send_reminders.three_days" />
                            <Checkbox label="Two days before the to-do" model="send_reminders.two_days" />
                            <Checkbox label="One day before the to-do" model="send_reminders.one_day" />
                            <Checkbox label="Three hours before the to-do" model="send_reminders.three_hours" />
                            <Checkbox label="Two hours before the to-do" model="send_reminders.two_hours" />
                            <Checkbox label="One hour before the to-do" model="send_reminders.one_hour" />
                            <Checkbox label="15 min before to-do" model="send_reminders.15_minutes" />
                          </CheckboxGroup>
                        </div>
                      </ContextShow>
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Place" isOpen={data.meeting_place !== 'none'}>
                    <FormSection>
                      <Select label="Meeting Place" model="meeting_place" defaultValue="none" className="!flex-1">
                        <Option label="None" value="none" />
                        <Option label="Online" value="online" />
                        <Option label="Housing" value="property" />
                        <Option label="Office" value="office" />
                        <Option label="Organization" value="organization" />
                        <Option label="Other" value="other" />
                      </Select>

                      {formData?.meeting_place === 'online' && (
                        <>
                          <Checkbox
                            defaultChecked
                            falseIcon="cross"
                            falseStyle="none"
                            label=" "
                            description="I acknowledge it is my responsibility to secure this external meeting room service that is outside the control of Behave Health Corp. This includes any requirements related to HIPAA. If you have any questions email us at contact@behavehealth.com"
                            model="responsibility_acknowledgement"
                          />

                          <URLInput
                            label="Meeting Link"
                            model="meeting_url"
                            isDisabled={formData?.responsibility_acknowledgement !== true}
                            validations={{
                              presence: {
                                message: 'Please enter a meeting link',
                              },
                            }}
                          />
                        </>
                      )}

                      <ContextShow when="meeting_place" is="property">
                        <ObjectSelector
                          icon={ICONS.properties}
                          label="Housing"
                          type="properties"
                          model="house"
                          selectTitle={(data: any) => data.name}
                          selectDescription={(data: any) => address(data.address)}
                          validations={{
                            presence: {
                              message: 'Please select a property',
                            },
                          }}
                        />

                        <ObjectSelector
                          icon={ICONS.rooms}
                          label="Room (optional)"
                          type="property.rooms"
                          model="room"
                          dependent="house_id"
                          disableUnless="house_id"
                          selectTitle={(data: any) => data.name}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="office">
                        <ObjectSelector
                          icon={ICONS.properties}
                          label="Office"
                          type="offices"
                          model="house"
                          selectTitle={(data: any) => data.name}
                          selectDescription={(data: any) => address(data.address)}
                          validations={{
                            presence: {
                              message: 'Please select a property',
                            },
                          }}
                        />

                        <ObjectSelector
                          icon={ICONS.rooms}
                          label="Room (optional)"
                          type="property.rooms"
                          model="room"
                          dependent="house_id"
                          disableUnless="house_id"
                          selectTitle={(data: any) => data.name}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="organization">
                        <ObjectSelector
                          label="Organization"
                          model="organization"
                          type="organizations"
                          icon={ICONS.organizations}
                          selectTitle={(data: any) => data.name}
                          selectDescription={(data: any) => address(data.address)}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" within={['property', 'organization', 'online', 'other']}>
                        <MiniRichTextEditor useQuickText label="More Details" model="place_details" />
                      </ContextShow>
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Links" isOpen={size(data.urls)}>
                    <Links model="urls" />
                  </Accordions.Item>

                  <Accordions.Item title="Notes" isOpen={!!data.internal_notes}>
                    <FormSection>
                      <MiniRichTextEditor useQuickText label="Internal Notes" model="internal_notes" />
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Attachments" isOpen={size(data.documents)}>
                    <FormSection layout="vertical">
                      <Attachments model="documents" labelAlign="top" labelJustify="top" />
                    </FormSection>
                  </Accordions.Item>
                </Accordions>
              </div>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Grid vertical gap="1rem">
          <Flex gap="0.5rem">
            {isEditable && (
              <>
                <Button
                  label="Save"
                  glyph="check"
                  type="primary"
                  color="green"
                  flex="100 1 auto"
                  onClick={save}
                  isLoading={isSaving}
                  isDisabled={isInvalid}
                  permission="todos.edit"
                />
                <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
              </>
            )}

            {!isEditable && (
              <>
                <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" permission="todos.edit" />

                <DeleteDialog
                  title="Delete To-Do?"
                  message="Are you sure you want to delete this to-do? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} permission="todos.delete" />
                </DeleteDialog>
              </>
            )}
          </Flex>
        </Grid>
      </Overlay.Footer>
    </Overlay>
  )
}

export const AuthorityOrganizationTodoOverlay = withOverlayError(RootAuthorityOrganizationTodoOverlay)
