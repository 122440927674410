import React from 'react'
import size from 'lodash/size'

import { withFormContext } from '../../context'

import SelectorBase from '../SelectorBase'
import SelectorInput from '../SelectorInput'
import SelectorMenu from './SelectorMenu'
import SmartPortal from '../../../SmartPortal'
import Value from '../../../Value'
import snakeCase from 'lodash/snakeCase'

import { LINKS, getDefaultGroupBy } from '../SelectorBase'

class ObjectSelector extends SelectorBase {
  renderEdit = () => {
    const { object, isDisabled } = this.state
    const { selectTitle, selectGraphic, className } = this.props

    return (
      <SelectorInput
        showFocusMessage={!this.state.value}
        label={selectTitle && object ? selectTitle(object) : object?.name}
        icon={this.props.icon}
        object={object}
        selectGraphic={selectGraphic}
        avatar={object?.avatar}
        onOpen={this.open}
        onClear={() => {
          if (this.props.onClear) this.props.onClear(this.state)
          this.changeValue(null)
          this.props.onSelect?.(null)
        }}
        isDisabled={isDisabled || this.props.isDisabled || object?.isRequired}
        className={className}
        getRef={this.getInputRef}
        placeholder={this.props.placeholder}
      />
    )
  }

  renderReadOnly = () => {
    const { object } = this.state
    const { icon, selectTitle, showDescription, type, disableLink } = this.props

    const value = selectTitle && object ? selectTitle(object) : object?.name

    return (
      <Value
        avatarMagnify
        value={value}
        icon={icon}
        avatar={object?.avatar}
        avatarMagnifyScale={2.5}
        testKey={`${this.props.testKey || snakeCase(this.props.label)}_selector_value`}
        description={showDescription && object?.description}
        link={!disableLink && LINKS[type]?.(object)}
      />
    )
  }

  renderSelector = () => {
    const { icon, type, label, searchKeys, selectTitle, selectDescription, blacklisted, emptyActions, groupBy, selectGraphic } = this.props

    const { isLoading, apiData } = this.state

    const groupByConfig = groupBy || getDefaultGroupBy(type)

    let data = apiData
    let blacklistSize = size(blacklisted)
    if (blacklistSize > 0) {
      data = apiData.filter((item) => {
        if (typeof blacklisted[0] === 'object') {
          for (let i = 0; i < blacklistSize; i++) {
            if (blacklisted[i].id === item.id && blacklisted[i].type === item.type) return false
          }
          return true
        } else {
          return !blacklisted.includes(item.id)
        }
      })
    }

    return (
      <SmartPortal useReferenceWidth portal="selector" position="bottom-start">
        <SelectorMenu
          data={data}
          icon={icon}
          type={type}
          onSelect={(o) => {
            this.changeValue(o)
            this.props.onSelect?.(o)
            this.close()
          }}
          searchKeys={searchKeys}
          isLoading={isLoading}
          selectTitle={selectTitle}
          selectDescription={selectDescription}
          selectGraphic={selectGraphic}
          emptyActions={emptyActions}
          label={label}
          groupBy={groupByConfig}
          onSearchKeyDown={this.handleSearchKeyDown}
          close={this.close}
        />
      </SmartPortal>
    )
  }
}

ObjectSelector.defaultProps = {
  prefetch: false,
  searchKeys: ['name', 'description'],
  isEditable: true,
  isDisabled: false,
  initialModelRequired: false,
  showDescription: true,
  isOpen: false,
  isRelation: true,
  includeObject: false,
  isPolymorphic: false,
  validateOn: 'blur-change-update',
}

export default withFormContext(ObjectSelector)
