import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const STATUSES = {
  company: {
    label: 'Company',
    color: 'blue',
  },
  personal: {
    label: 'Personal',
    color: 'green',
  },
}

export const QuickTextCategoryStatus = ({ ...rest }) => {
  return <SmartStatus statuses={STATUSES} {...rest} />
}
