import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'

import { MainCell } from '../../components/DataTable/cells/MainCell'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'

export const HubspotCompaniesDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        formatValue: ({ data, value }) => (
          <MainCell
            as={props.mainLinkAs}
            id={data.id}
            value={value}
            avatar={data.avatar || ''}
            to={props.to ? props.to(data) : undefined}
            onClick={props.onSelect ? () => props.onSelect(data) : props.onClick ? () => props.onClick(data) : undefined}
          />
        ),
      },
      {
        title: 'Hubspot Company ID',
        model: 'id',
        type: 'hubspot_company',
      },
      {
        title: 'Business Status',
        model: 'business_status',
      },
      {
        title: 'Description',
        model: 'description',
        width: 300,
      },
      {
        title: 'Website',
        model: 'website',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={value} />
        },
      },
      {
        title: 'LinkedIn',
        model: 'linkedin_company_page',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'LinkedIn'} />
        },
      },
      {
        title: 'Facebook',
        model: 'facebook_company_page',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'Facebook'} />
        },
      },
      {
        title: 'Twitter / X',
        model: 'twitterhandle',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={`https://twitter.com/${value}`} value={value} />
        },
      },
      {
        title: 'Phone No',
        model: 'phone',
        type: 'phone',
      },
      {
        title: 'Owner Email',
        model: 'owneremail',
        type: 'email',
      },
      {
        title: 'Owner Name',
        model: 'ownername',
      },
      {
        title: 'Address Line 1',
        model: 'address',
        width: 200,
      },
      {
        title: 'Address Line 2',
        model: 'address2',
        width: 150,
      },
      {
        title: 'City',
        model: 'city',
        width: 130,
      },
      {
        title: 'County',
        model: 'county',
        width: 120,
      },
      {
        title: 'State',
        model: 'state',
        width: 70,
      },
      {
        title: 'Zip',
        model: 'zip',
        width: 70,
      },
      {
        title: 'Timezone',
        model: 'timezone',
        width: 160,
      },
      {
        title: 'Date Added',
        model: 'createdate',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: '_updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      asCard
      {...props}
      columns={columns}
      icon="hubspot"
      title="HubSpot Companies"
      testKey="hubspot_companies_data_table"
      filtersConfig={FILTERS_CONFIG}
    />
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  id: {
    label: 'Hubspot Company ID',
    type: 'string',
  },
  // business_status
  description: {
    label: 'Description',
    type: 'string',
  },
  business_status: {
    label: 'Business Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  website: {
    label: 'Website',
    type: 'string',
  },
  linkedin_company_page: {
    label: 'LinkedIn',
    type: 'string',
  },
  facebook_company_page: {
    label: 'Facebook',
    type: 'string',
  },
  twitterhandle: {
    label: 'Twitter / X',
    type: 'string',
  },
  phone: {
    label: 'Phone No',
    type: 'string',
  },
  owneremail: {
    label: 'Owner Email',
    type: 'string',
  },
  ownername: {
    label: 'Owner Name',
    type: 'string',
  },
  address: {
    label: 'Address Line 1',
    type: 'string',
  },
  address2: {
    label: 'Address Line 2',
    type: 'string',
  },
  city: {
    label: 'City',
    type: 'string',
  },
  county: {
    label: 'County',
    type: 'string',
  },
  state: {
    label: 'State',
    type: 'string',
  },
  zip: {
    label: 'Zip',
    type: 'string',
  },
  timezone: {
    label: 'Timezone',
    type: 'string',
  },
  createdate: {
    label: 'Date Added',
    type: 'date_time',
  },
  _updated_at: {
    label: 'Date Updated',
    type: 'date_time',
  },
}
