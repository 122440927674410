import React from 'react'
import produce from 'immer'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import EventOverlay from '../../components/Overlays/pages/Calendar/EventOverlay'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import SummonOverlay from '../../components/SummonOverlay'

const RootTreatmentPlanAppointmentOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    params,
  } = useOverlay({
    name: 'treatment-appointment',
    endpoint: '/treatment_appointments',
    invalidate: 'treatment-appointments',
    invalidateKeys: ['event', 'events'],
    options: props,
  })

  const { canEdit, planId, planCategory, permissionBase } = props

  const { timezone } = useSettings()

  const [transformOrigin, setTransformOrigin] = React.useState('')
  const [isEventOverlayOpen, setIsEventOverlayOpen] = React.useState(false)
  const [selectedEvent, setSelectedEvent] = React.useState(null)

  const handleSave = () => {
    const formData = produce(form.current.getFormValue(), (draft) => {
      if (draft.event) delete draft.event
      if (draft.author) delete draft.author
      if (draft.last_edited_by) delete draft.last_edited_by
    })

    saveWithData({
      ...formData,
      event_id: selectedEvent?.id,
    })
  }

  React.useEffect(() => {
    if (!data?.event) return

    setSelectedEvent(data.event)
  }, [data])

  const formKey = React.useMemo(() => {
    if (!data) return null

    return `${data.updated_at}-${data?.event?.updated_at}`
  }, [data])

  if (!planId || !planCategory) return null

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <>
      <Overlay onClose={close}>
        <Overlay.Header icon="calendar" title="Appointment" />

        <Overlay.Content>
          <Form
            key={formKey}
            getForm={form}
            timezone={timezone}
            initialModel={initialModel}
            isEditable={isEditable}
            onValidationUpdate={onValidationUpdate}
            linked={
              isNew && {
                treatment_plan_id: planId,
                category: planCategory,
              }
            }
          >
            <Section>
              <FormSection layout="vertical">
                <Input
                  vertical
                  label="Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a name',
                    },
                  }}
                />

                <SmartTextarea useQuickText useDictation label="Description" model="description" />

                <DateTimeInput defaultToNow label="Appointment Time" model="dated_at" />

                <div>
                  <Flex gap="0.5rem" justifyContent="space-between" alignItems="center">
                    <Label label="Event" />
                    {isEditable && (
                      <Button
                        label="Add New Event"
                        glyph="add"
                        size={100}
                        onClick={(event: any) => {
                          setIsEventOverlayOpen(true)
                          setTransformOrigin(`${event.clientX}px ${event.clientY}px`)
                        }}
                        type="minimal"
                      />
                    )}
                  </Flex>

                  <OverlaySelector
                    key={selectedEvent?.id}
                    blankLabel="Select event…"
                    icon="calendar"
                    type="events"
                    // model="event"
                    value={selectedEvent}
                    selectTitle={(data: any) => data.title}
                    onSelect={(event: any) => {
                      setSelectedEvent(event)
                    }}
                  />
                </div>
              </FormSection>
            </Section>
          </Form>
        </Overlay.Content>

        {canEdit && (
          <Overlay.Footer>
            {isEditable && (
              <>
                <Button
                  glyph="check"
                  label="Save"
                  type="primary"
                  color="green"
                  isLoading={isSaving}
                  onClick={handleSave}
                  isDisabled={isInvalid}
                  flex="100 1 auto"
                  permission={`${permissionBase}.edit`}
                />
                {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
              </>
            )}

            {!isEditable && (
              <>
                <Button
                  label="Edit"
                  glyph="edit"
                  type="default"
                  isDisabled={isDeleting}
                  onClick={edit}
                  flex="100 1 auto"
                  permission={`${permissionBase}.edit`}
                />

                <DeleteDialog
                  title="Delete Appointment?"
                  message="Are you sure you want to delete this Appointment? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button
                    label="Delete"
                    type="default"
                    glyph="delete"
                    color="red"
                    isLoading={isDeleting}
                    fullWidth
                    permission={`${permissionBase}.edit`}
                  />
                </DeleteDialog>
              </>
            )}
          </Overlay.Footer>
        )}
      </Overlay>

      <SummonOverlay
        isOpen={isEventOverlayOpen}
        onClose={() => {
          setIsEventOverlayOpen(false)
        }}
        overlay={
          <EventOverlay
            showBackdrop
            dataID="new"
            position="center"
            maxWidth={37}
            onSaveSuccessful={(event) => {
              setSelectedEvent(event)
            }}
            transformOrigin={transformOrigin}
          />
        }
      />
    </>
  )
}

export const TreatmentPlanAppointmentOverlay = withOverlayError(RootTreatmentPlanAppointmentOverlay)
