import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { getVersionSlug } from '@behavehealth/utils/functions.js'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { DataFormAddDropdown } from '@behavehealth/constructs/DataFormsBuilder/DataFormAddDropdown'
import { Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { ProgressReviewsDataTable } from '@behavehealth/constructs/ProgressReviews/ProgressReviewsDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { StaffDataFormsAlert } from '../components/StaffDataFormsAlert'

import { getBatchActions, getCanSelectDataForm } from '../utils/dataForms'
import { useIsMyProfile } from '../utils/useIsMyProfile'
import { useStaffDataFormTabs, StaffDataFormTabs } from '../components/StaffDataFormTabs'

const pageConfig = {
  feature: 'progress_reviews',
  help: <HelpTagIframe id="progress_reviews" />,
  marketingID: 'progress_reviews',
}

type Props = {
  canCreate: boolean
}

const ProgressReviews: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id: resourceID }: any = useParams()

  const isMyProfile = useIsMyProfile()
  const { activeTab, setActiveTab, queryParams } = useStaffDataFormTabs()

  const tableProps = useDataTable({
    name: ['employee', resourceID, 'data_forms'],
    endpoint: `/employees/${resourceID}/data_forms`,
    params: { category: 'progress_review', ...queryParams },
    updateDeleteEndpoint: '/data_forms',
    enabled: !!resourceID,
    localStorageKey: 'employee_progress_reviews_v1',
  })

  return (
    <Page {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <StaffDataFormsAlert name="Progress Reviews" />

        <StaffDataFormTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        <ProgressReviewsDataTable
          {...tableProps}
          key={`tab-${activeTab}`}
          getCanSelect={getCanSelectDataForm}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/progress-reviews',
            },
          ]}
          to={(record) => {
            const { id, version } = record

            const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

            return {
              pathname: `${match.url}${version === '3.0' ? '/progress-review' : ''}/${id}${formattedVersion}`,
              parent: match,
            }
          }}
          batchActionsConfig={getBatchActions({ isMyProfile, activeTab, tableProps, permissionBase: 'progress_reviews' })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ProgressReviews, pageConfig))
