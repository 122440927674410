import React from 'react'
import { useSelector } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'

import { getVersionSlug } from '@behavehealth/utils/functions'
import { mapToArray } from '@behavehealth/utils/functions'
import { useAPI } from '@behavehealth/hooks'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Divider, HelpTagIframe, Page, Dropdown, DropdownItem } from '@behavehealth/components'
import { TreatmentPlansArchivedDataTable } from '@behavehealth/constructs/TreatmentPlansArchived/TreatmentPlansArchivedDataTable'

const pageConfig = {
  feature: 'treatment_plans',
  help: <HelpTagIframe id="treatment_plans" />,
  marketingID: 'treatment_plans',
}

type Props = {
  canCreate: boolean
}

const AddDropdown = ({ match, templates }: any) => (
  <Dropdown label="Add Treatment Plan…" glyph="add" buttonType="primary" buttonSize={300}>
    <DropdownItem
      label="Empty Treatment Plan"
      glyph="add"
      color="paleBlue"
      link={{
        pathname: `${match.url}/new-20`,
        parent: match,
      }}
    />

    <Divider />

    {mapToArray(templates).map((template) => (
      <DropdownItem
        key={template.id}
        label={template.name}
        icon="treatment_plans"
        color="paleBlue"
        link={{
          pathname: `${match.url}/new-20`,
          parent: match,
          data: {
            ...template,
            data_form_template_id: template.id,
          },
        }}
      />
    ))}
  </Dropdown>
)

const TreatmentPlans: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const templates = useSelector((state) => state.data.data_form_templates?.data)

  useAPI('data_form_templates', `/data_form_templates?category=treatment_plan`)

  const tableProps = useDataTable({
    name: ['client', resource_id, 'data_forms'],
    endpoint: `/residents/${resource_id}/data_forms`,
    enabled: !!resource_id,
    updateDeleteEndpoint: '/data_forms',
    params: { category: 'treatment_plan' },
    localStorageKey: 'client_treatment_plans_archived_v1',
  })

  const actions = (
    <>
      <Button
        label="Manage Treatment Plan Templates"
        glyph="settings"
        type="default"
        link="/settings/archived-treatment-plan-templates"
        permission="settings.treatment_plan_templates.edit"
      />

      <AddDropdown match={match} templates={templates} />
    </>
  )

  return (
    <Page actions={canCreate && actions} {...pageConfig}>
      <div className="grid grid-cols-[100%]">
        <TreatmentPlansArchivedDataTable
          {...tableProps}
          to={({ version, id }: any) => ({
            pathname: `${match.url}/${id}-${getVersionSlug(version)}`,
            parent: match,
          })}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/treatment-plans',
            },
          ]}
          renderDuplicateButton={(row: any) => (
            <Button
              label="Duplicate Plan"
              glyph="add"
              size={100}
              link={{
                pathname: `${match.url}/new`,
                parent: match,
                data: {
                  supervisor: row.supervisor,
                  data: row.data,
                },
              }}
              permission="treatment_plans.create"
            />
          )}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'treatment_plans.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(TreatmentPlans, pageConfig))
