import React from 'react'

import { isDefined } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'
import InviteStatus from '../Statuses/InviteStatus'

import { ROLES } from '../../utils/constants'

const columns = (to: Function = () => {}, timezone: string, mainLinkAs) => [
  {
    width: 280,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'sent_to_details.full_name',
    Header: 'Full Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink as={mainLinkAs} to={to(row.original.id)} label={value} avatar={''} />,
  },
  {
    width: 150,
    canToggleVisible: false,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <InviteStatus status={value} />,
  },
  {
    width: 160,
    accessor: 'sent_to_details',
    Header: 'Invited Via',
    Cell: ({ value }: any) => {
      if (isDefined(value.phone_no)) return 'Text Message'
      if (isDefined(value.email)) return 'Email'
      return <TableCell.Empty />
    },
  },
  {
    width: 160,
    accessor: 'sent_to_details.email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    width: 160,
    accessor: 'sent_to_details.phone_no',
    Header: 'Cell Number',
    Cell: TableCell.Phone,
  },
  {
    width: 180,
    id: 'sent_by',
    Header: 'Invited By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.sent_by?.avatar} name={row.original.sent_by?.name} />,
  },
  {
    width: 190,
    accessor: 'created_at',
    Header: 'Date Invited',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

const ClientsInvitesTable = (props: any) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    title,
    to,
    timezone,
    user,
    tenant,
    hiddenColumns,
    titleAfter,
    apiKey,
    testKey,
    ...rest
  } = props

  return (
    <Table
      testKey="clients_invites_data_table"
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, timezone, props.mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

ClientsInvitesTable.defaultProps = {
  title: 'Client Invites',
  icon: 'clients',
  emptyDescription: 'No client invites added yet',
  localStorageKey: 'clients_invites',
}

export default withSettings(ClientsInvitesTable)
