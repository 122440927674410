import React from 'react'
import { NavLink, Navigate, Route, Routes, useNavigate, useParams, useMatch } from 'react-router-dom-v5-compat'
import kebabCase from 'lodash/kebabCase'
import snakeCase from 'lodash/snakeCase'

import { titleCase } from '@behavehealth/utils/functions'
import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { getClientLink } from '@behavehealth/utils/functions'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { TreatmentPlanOverlay } from '@behavehealth/constructs/TreatmentPlans/TreatmentPlanOverlay'
import { TreatmentPlanStatus } from '@behavehealth/constructs/TreatmentPlans/components/TreatmentPlanStatus'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { StaffDataFormsAlert } from '../components/StaffDataFormsAlert'

import Card from '@behavehealth/components/Card'
import PageHeader from '@behavehealth/components/PageHeader'
import Portal from '@behavehealth/components/Portal'
import State from '@behavehealth/components/State'
import Tabs from '@behavehealth/components/Tabs'

const TreatmentPlans: React.FC = () => {
  const navigate = useNavigate()

  const locationMatch: any = useMatch({ path: `/:resourceType/:resourceId/treatment-plans/:category/*` })
  const category = snakeCase(locationMatch?.params?.category)

  const {
    viewCaseManagementTreatmentPlans,
    viewClinicalTreatmentPlans,
    viewMedicalTreatmentPlans,
    viewPeerTreatmentPlans,
    viewSomeTreatmentPlans,
  } = useTreatmentPlanPermissions()

  const allowedTabs = React.useMemo(() => {
    const result = []

    if (viewClinicalTreatmentPlans) result.push('clinical')
    if (viewMedicalTreatmentPlans) result.push('medical')
    if (viewCaseManagementTreatmentPlans) result.push('case_management')
    if (viewPeerTreatmentPlans) result.push('peer')

    return result
  }, [viewClinicalTreatmentPlans, viewMedicalTreatmentPlans, viewCaseManagementTreatmentPlans, viewPeerTreatmentPlans])

  const firstAllowedTab = allowedTabs[0]

  React.useEffect(() => {
    if (!category || allowedTabs.includes(category)) return

    if (firstAllowedTab) navigate(`${kebabCase(firstAllowedTab)}`, { replace: true })
  }, [category, allowedTabs, firstAllowedTab])

  return (
    <div>
      <div className="bg-white sticky top-0 z-10">
        <PageHeader title="Treatment Plans" icon="treatment_plans" />

        {viewSomeTreatmentPlans && (
          <Tabs>
            <Tabs.List className="px-4">
              <Tabs.Item as={NavLink} label="Clinical" to="clinical" permission={viewClinicalTreatmentPlans} />
              <Tabs.Item as={NavLink} label="Medical" to="medical" permission={viewMedicalTreatmentPlans} />
              <Tabs.Item as={NavLink} label="Case Management" to="case-management" permission={viewCaseManagementTreatmentPlans} />
              <Tabs.Item as={NavLink} label="Peer" to="peer" permission={viewPeerTreatmentPlans} />
            </Tabs.List>
          </Tabs>
        )}
      </div>

      {!viewSomeTreatmentPlans && (
        <div className="p-4">
          <Card>
            <State
              isEmpty
              icon="treatment_plans"
              title="Treatment Plans"
              emptyDescription="You do not have permissions to access Clinical, Medical nor Peer Treatment Plans. If you're looking for the old Treatment Plans, please click on the menu item under Clinical Archived in the Client's menu."
            />
          </Card>
        </div>
      )}

      {viewSomeTreatmentPlans && firstAllowedTab && (
        <Routes>
          <Route index element={<Navigate to={firstAllowedTab} replace />} />
          <Route path=":plan_category/*" element={<TreatmentPlansCategoryPage key={category} />} />
        </Routes>
      )}
    </div>
  )
}

const TreatmentPlansCategoryPage = (props: any) => {
  const { plan_category: paramsCategory, resource_id: clientId }: any = useParams()

  const category = snakeCase(props.category || paramsCategory)
  const permissionBase = `${category}_treatment_plans`

  return (
    <>
      <ProtectedRouteV6 permission={`${permissionBase}.view`}>
        <TreatmentPlansCategoryPageIndex />
      </ProtectedRouteV6>

      <Routes>
        <Route path=":id/*" element={<TreatmentPlanRoute permissionBase={permissionBase} />} />
      </Routes>
    </>
  )
}

const TreatmentPlanRoute = ({ permissionBase }) => {
  return (
    <ProtectedRouteV6 permission={`${permissionBase}.view`}>
      <Portal type="overlay">
        <TreatmentPlanOverlay useV6Router />
      </Portal>
    </ProtectedRouteV6>
  )
}

const TreatmentPlansCategoryPageIndex = (props: any) => {
  const navigate = useNavigate()
  const { resource_id: resourceID, plan_category: paramsCategory }: any = useParams()

  const category = snakeCase(props.category || paramsCategory)
  const permissionBase = `${category}_treatment_plans`

  const tableProps = useDataTable({
    name: ['employee', resourceID, 'treatment_plans'],
    endpoint: `/employees/${resourceID}/treatment_plans`,
    params: { category },
    options: { enabled: !!resourceID && !!category },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'id',
        width: 260,
        formatValue: ({ data }: any) => <MainCell as={NavLink} to={`${data.id}`} value={data.name} />,
      },
      {
        title: 'Client',
        model: 'client',
        formatValue: ({ value }) => {
          if (!value) return null

          return (
            <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/treatment-plans/${paramsCategory}`} />
          )
        },
      },
      {
        title: 'Status',
        model: 'status',
        width: 200,
        formatValue: ({ value }: any) => <TreatmentPlanStatus status={value} />,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ],
    [],
  )

  return (
    <div className="grid gap-4 p-4">
      <StaffDataFormsAlert name="Treatment Plans" />

      <DataTable
        asCard
        title={`${titleCase(category)} Treatment Plans`}
        testKey="treatment_plans_data_table"
        icon="treatment_plans"
        columns={columns}
        {...tableProps}
      />
    </div>
  )
}

export default withPageError(TreatmentPlans)
