import React from 'react'

import { useUpdate } from '../../../../hooks/useNewAPI'
import Notifications from '../../../../modules/notifications'

import Button from '../../../Button'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'

import { QUICK_ADD } from '../../../Worksheet/config'
import { Worksheet } from '../../../Worksheet/Worksheet'

import { EVENT_ATTENDANCE_PRESENT_STATUSES, EVENT_ATTENDANCE_ABSENT_STATUSES } from '../../../../utils/constants'

export const EventAttendanceWorksheetOverlay = (props: any) => {
  const { onClose, onSuccess, invalidateKeys, initialData = [], event, onSaveSuccessful } = props

  const form = React.useRef()
  const [data, setData]: any = React.useState({})

  const [isValid, setIsValid] = React.useState(false)

  const { mutateAsync, isLoading: isSaving }: any = useUpdate({
    name: ['event', event.id, 'event_attendances'],
    url: `/events/${event.id}`,
    invalidate: 'event',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Event attendance updated`, 'positive')

      if (onSuccess) onSuccess()
      if (onClose) onClose()
    },
  })

  const handleSave = async () => {
    const saveData = []
    const deleteData = []

    const initialIds = initialData.map((data: any) => data.id)

    for (const key in data) {
      const rowData: any = data[key]

      saveData.push({
        id: rowData.id,
        status: rowData.status,
        resident_id: rowData.resident?.id,
        present_status: rowData.status == 'present' ? rowData.present_status : '',
        absent_status: rowData.status == 'absent' ? rowData.absent_status : '',
        additional_status: rowData.additional_status,
      })
    }

    const saveDataIds = saveData.map((data: any) => data.id)

    for (const id of initialIds) {
      if (!saveDataIds.includes(id)) {
        deleteData.push({ id, _destroy: true })
      }
    }

    await mutateAsync({
      event_attendances_attributes: [...saveData, ...deleteData],
    })

    if (onSaveSuccessful) onSaveSuccessful()

    onClose()
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        model: 'resident',
        type: 'object_selector',
        config: {
          quickAdd: QUICK_ADD.all_clients,
          isPolymorphic: true,
          shouldHideSelected: true,
          endpoint: '/residents',
          queryKey: 'residents',
          queryParams: { status: 'current' },
          selectTitle: (data: any) => data.name,
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
        },
      },
      {
        title: 'Presence',
        model: 'status',
        type: 'select',
        config: {
          options: [
            { label: 'Present', value: 'present', glyph: 'check', color: 'green' },
            { label: 'Absent', value: 'absent', glyph: 'decline', color: 'red' },
            { label: 'Unset', value: null, glyph: 'question', color: 'gray' },
          ],
        },
      },
      {
        title: 'Present Status',
        model: 'present_status',
        type: 'select',
        config: {
          getIsApplicable: ({ rowData }) => rowData.status && rowData.status === 'present',
          options: [
            { label: 'N/A', value: null, color: 'gray' },
            ...Object.entries(EVENT_ATTENDANCE_PRESENT_STATUSES).map(([value, label]) => ({ label, value })),
          ],
        },
      },
      {
        title: 'Absent Status',
        model: 'absent_status',
        type: 'select',
        config: {
          getIsApplicable: ({ rowData }) => rowData.status && rowData.status === 'absent',
          options: [
            { label: 'N/A', value: null, color: 'gray' },
            ...Object.entries(EVENT_ATTENDANCE_ABSENT_STATUSES).map(([value, label]) => ({ label, value })),
          ],
        },
      },
      {
        title: 'Absentee Notes',
        model: 'absentee_notes',
        type: 'textarea',
        config: {
          getIsApplicable: ({ rowData }: any) => rowData.status === 'absent',
        },
      },
    ] as const
  }, [])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={90}>
      <Overlay.Header title="Event Attendance" icon="calendar" />

      <Overlay.Content className="!p-4">
        <Grid gap="1rem" className="!pb-3">
          <Worksheet
            asCard
            getForm={form}
            columns={columns}
            allow="create-update-delete"
            title="Event Attendance"
            initialData={initialData}
            onValidationUpdate={setIsValid}
            onDataUpdate={setData}
          />
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save Attendance`}
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!isValid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
