import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { mapToArray } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, State, Grid, Divider, Dropdown, DropdownItem, Page, HelpTagIframe } from '@behavehealth/components'
import { CustomNotesDataTable } from '@behavehealth/constructs/CustomNotes/CustomNotesDataTable'
import { PropertyCustomNoteOverlay } from '@behavehealth/constructs/CustomNotes/PropertyCustomNoteOverlay'

const AddDropdown = ({ templates, isLoading, property }: any) => {
  return (
    <Dropdown label="Add Custom Note…" glyph="add" buttonType="primary" buttonSize={300} permission="property_custom_notes.create">
      <DropdownItem
        as={Link}
        label="Empty Custom Note"
        glyph="add"
        color="paleBlue"
        link="new"
        state={{
          data: {
            name: 'New Custom Note',
            house: property,
            house_id: property?.id,
          },
        }}
      />

      {isLoading ? (
        <State isLoading minHeight={100} />
      ) : (
        <>
          <Divider />

          {mapToArray(templates).map((template) => (
            <DropdownItem
              as={Link}
              key={template.id}
              label={template.name}
              glyph="documents"
              color="paleBlue"
              link="new"
              state={{
                data: {
                  ...template,
                  house: property,
                  house_id: property?.id,
                },
              }}
            />
          ))}
        </>
      )}
    </Dropdown>
  )
}

const pageConfig = {
  feature: 'custom_notes',
  marketingID: 'client_financials',
  help: <HelpTagIframe id="custom_notes" />,
}

type Props = {
  canCreate: boolean
}

const CustomNotes = () => {
  return (
    <>
      <CustomNotesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<PropertyCustomNoteOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const CustomNotesIndex: React.FC<Props> = () => {
  const { resource_id: id }: any = useParams()

  const { data: property } = useGet({
    name: ['location', id],
    url: `/houses/${id}`,
  })

  const tableProps = useDataTable({
    name: ['location', id, 'custom_notes'],
    endpoint: `/houses/${id}/custom_notes`,
    params: { variant: 'property' },
    updateDeleteEndpoint: '/custom_notes',
    localStorageKey: 'property_custom_notes',
  })

  const { data: templates, isLoading: isLoadingTemplates } = useGet({
    name: ['custom_note_templates', { variant: 'property' }],
    url: `/custom_note_templates`,
    params: { variant: 'property' },
  })

  return (
    <Page
      actions={
        <>
          <Button
            size={300}
            glyph="settings"
            type="minimal"
            label="Manage Templates"
            link="/settings/custom-note-templates/properties"
            permission="settings.property_custom_note_templates.view"
          />

          <AddDropdown templates={templates} property={property} isLoading={isLoadingTemplates} />
        </>
      }
      {...pageConfig}
    >
      <Grid>
        <CustomNotesDataTable
          {...tableProps}
          mainLinkAs={NavLink}
          to={(data: any) => data.id}
          duplicatePermission="property_custom_notes.create"
          duplicateLinkPropsV6={(record: any) => {
            const {
              client,
              employees,
              ended_at,
              name,
              notes,
              office,
              organization,
              portal_settings,
              house,
              reference,
              sections,
              settings,
              started_at,
              supervisor,
              tags,
              variant,
            } = record

            return {
              link: 'new',
              state: {
                data: {
                  status: 'draft',
                  client,
                  employees,
                  ended_at,
                  name,
                  notes,
                  office,
                  organization,
                  organization_id: organization?.id,
                  portal_settings,
                  house,
                  house_id: house?.id,
                  reference,
                  sections,
                  settings,
                  started_at,
                  supervisor,
                  tags,
                  variant,
                },
              },
            }
          }}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'property_custom_notes.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CustomNotes, pageConfig))
