import React from 'react'

import { Status } from '../'

export const STATUSES = {
  processing: {
    label: 'Processing',
    color: 'gray',
  },
  charged: {
    label: 'Charged',
    color: 'green',
  },
  future: {
    label: 'Planned',
    color: 'yellow',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'red',
  },
  failed: {
    label: 'Failed',
    color: 'red',
  },
}

const ChargeStatus = ({ inline, status, className }: any) => (
  <Status className={className} label={STATUSES[status]?.label} color={STATUSES[status]?.color} inline={inline} />
)

export default ChargeStatus
