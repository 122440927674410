import setup from 'logrocket/setup'

class LogRocket {
  constructor() {
    this.rocket = null
  }

  init = (id, url, version) => {
    this.rocket = setup({
      sdkServer: url,
      ingestServer: url,
    })

    this.rocket.init(id, {
      release: version,
      dom: {
        rateLimitCount: 4096,
        rateLimitPeriod: 40000,
      },
      network: {
        requestSanitizer: (request: any) => {
          // ignore analytics
          if (request.url.indexOf('https://gw.behavehealth.com/analytics') !== -1) {
            return null
          }

          return request
        },
      },
    })
  }

  getRocket = () => this.rocket

  abortRocket = () => {
    this.rocket = null
  }
}

export default new LogRocket()
