import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Chotomate from '../../components/Chotomate'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SignaturePad from '../../components/Forms/SignaturePad'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import Switch from '../../components/Forms/Switch'
import Timeline from '../../components/Timeline/Timeline'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { InsuranceBillingFormSection } from '../RCM/components/InsuranceBillingFormSection'
import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

const RootBreathalyzerTestOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'breathalyzer-tests',
    endpoint: '/breathalyzer_tests',
    invalidate: 'breathalyzer-tests',
    options: props,
    openAfterCreate: true,
  })

  const { isBehave, isPortal, tenant, timezone } = useSettings()

  const isTrialing = tenant?.plan_status === 'trialing'

  if (isOverlayLoading) {
    return <OverlayLoader position="right" showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} />
  }

  return (
    <Overlay showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} onClose={close}>
      <Overlay.Header icon="test_results" title="Breathalyzer Test" help={<HelpTagIframe id="test_results_breathalyzer_tests" />} />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/breathalyzer_tests/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          key={`updated-${data?.updated_at}`}
        >
          {isBehave && (
            <Section headingType="h2" title="Behave Details">
              <FormSection layout="vertical">
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Billing Completed" model="is_billed" />
                </CheckboxGroup>

                <ContextShow when="is_billed" is={true}>
                  <DateTimeInput defaultToNow model="billed_at" label="Billing Completed At" />
                </ContextShow>
              </FormSection>
            </Section>
          )}

          <Section>
            <FormSection layout="vertical">
              <OverlaySelector
                initialModelRequired={isNew}
                model="resident"
                label="Client"
                type="clients.current"
                icon="clients"
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.behave_id}
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />

              <RadioGroup
                model="status"
                label="Status"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select a status',
                  },
                }}
              >
                <Radio label="Collected" value="collected" />
                <Radio label="Not Collected" value="refused" />
              </RadioGroup>

              <DateTimeInput defaultToNow model="collected_at" label="Collection Date and Time" />
              <FormFutureDateWarning dateLabel="Collection Date and Time" model="collected_at" />

              <ContextShow when="status" is="collected">
                <RadioGroup
                  model="result"
                  label="Result"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select at least one option',
                    },
                  }}
                >
                  <Radio label="Positive" value="positive" />
                  <Radio label="Negative" value="negative" />
                  <Radio label="Not Available" value="not_available" />
                </RadioGroup>
              </ContextShow>

              <ContextShow when="result" is="positive">
                <Input
                  model="value"
                  label="Result Value"
                  type="number"
                  step="0.001"
                  min="0"
                  validations={{
                    presence: {
                      message: 'Please enter a result value',
                    },
                  }}
                />
              </ContextShow>

              {tenant?.is_billed && <InsuranceBillingFormSection isNew={isNew} initialModel={initialModel} />}

              <SmartTextarea useDictation useQuickText model="notes" label="Notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this Breathalyzer Test">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section
            headingType="h2"
            title="Signatures"
            description="Use this section to record signatures"
            aside={<Switch defaultValue={false} model="is_signable" />}
          >
            <ContextShow when="is_signable" is={true}>
              <FormSection layout="vertical" className="!pt-4">
                <SignaturePad
                  showSignedBy={false}
                  label="Client Signature"
                  model="client_signature"
                  signedAtModel="client_signed_at"
                  allowPin={false}
                  showAccept={false}
                />

                <SignaturePad
                  allowPin
                  person={data?.signed_by}
                  label="Staff Signature"
                  model="employee_signature"
                  signedAtModel="author_signed_at"
                  showAccept={false}
                />
              </FormSection>
            </ContextShow>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {!isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission="test_results.breathalyzer_tests.create"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit Breathalyzer Test"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="test_results.breathalyzer_tests.edit"
              />

              <DeleteDialog
                title="Delete Breathalyzer Test?"
                message="Are you sure you want to delete this breathalyzer test? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="test_results.breathalyzer_tests.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const BreathalyzerTestOverlay = withOverlayError(RootBreathalyzerTestOverlay)
