import React from 'react'

import { Page, Grid } from '@behavehealth/components'
import { StaffCustomNoteTemplatesDataTable } from '@behavehealth/constructs/CustomNoteTemplates/StaffCustomNoteTemplatesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'staff_custom_note_templates',
  title: 'Staff Custom Note Templates',
}

const StaffCustomNoteTemplates = () => {
  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    params: { variant: 'staff' },
    localStorageKey: 'staff_custom_note_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Grid>
        <StaffCustomNoteTemplatesDataTable to={(row: any) => row.id} {...tableProps} />
      </Grid>
    </Page>
  )
}

export default withMarketing(StaffCustomNoteTemplates, pageConfig)
