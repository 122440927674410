import React from 'react'

import Button from '../../components/Button'
import ButtonGroup from '../../components/ButtonGroup'
import Permission from '../../components/Permission'
import Tooltip from '../../components/Tooltip'

import { NavGroupLabel } from '../../components/NavGroup'

export const OrganizationTrackTabs = ({ organizationTrack, activeId, setActiveId, manageLinkTarget, className = 'px-4 pt-3 pb-2' }) => {
  if (!organizationTrack) return null

  return (
    <Permission featureFlagV2="organization_tracks">
      <div className={className}>
        <div className="flex flex-nowrap justify-between items-center mb-2">
          <NavGroupLabel label="Templates" />
          <Tooltip
            content={
              <div className="grid gap-2">
                <div>
                  <b>Organization Tracks</b>
                </div>

                <ul>
                  <li>
                    Organization Tracks help manage which templates are available for organizations based on the track they are currently
                    assigned to.
                  </li>
                  <li>Organizations can be on only one track at a time, and templates can be linked to one or more tracks.</li>
                  <li>When adding a new record, the available templates will be filtered based on the organization's current track.</li>
                </ul>

                <div>
                  <Button
                    label="Manage Organization Tracks"
                    display="inline-flex"
                    glyph="settings"
                    size={100}
                    link="/settings/organization-tracks"
                    target={manageLinkTarget}
                    useGlyphForTarget={!!manageLinkTarget}
                  />
                </div>
              </div>
            }
            className="ml-2"
          />
        </div>

        <ButtonGroup stretchSelf>
          <Button
            label={organizationTrack?.name}
            icon="organization_tracks"
            color="text"
            type={activeId ? 'primary' : 'default'}
            onClick={(e) => {
              e.stopPropagation()
              setActiveId(organizationTrack?.id)
            }}
            size={200}
          />

          <Button
            label="All Organization Tracks"
            icon="organization_tracks"
            color="text"
            type={!activeId ? 'primary' : 'default'}
            onClick={(e) => {
              e.stopPropagation()
              setActiveId(undefined)
            }}
            size={200}
          />
        </ButtonGroup>
      </div>
    </Permission>
  )
}
