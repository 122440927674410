import React from 'react'

import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import { niceAmount } from '../../utils/functions'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import OverlayLoader from '../../components/OverlayLoader'

import AmountInput from '../../components/Forms/AmountInput'
import Attachments from '../../components/Forms/Attachments'
import ChargeStatus from '../../components/Statuses/ChargeStatus'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateInput from '../../components/Forms/DateInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Textarea from '../../components/Forms/Textarea'

import ChargePayersSection from '../../components/Overlays/pages/Financials/components/ChargePayersSection'

const RootOrganizationChargeOverlay = (props: any) => {
  const {
    cancel,
    client,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    updateAsync,
    record,
  } = useOverlay({
    name: 'financial-transactions',
    endpoint: '/financial_transactions',
    invalidate: 'financial-transactions',
    options: props,
    parentType: 'organization',
    parentRequest: '/organizations',
  })

  const [formData, setFormData] = React.useState(initialModel)
  const { timezone } = useSettings()

  const service = initialModel?.service

  const employees = initialModel?.service?.employees
  const hasEmployees = size(employees) > 0

  const organizations = initialModel?.service?.organizations
  const hasOrganizations = size(organizations) > 0

  const isCharged = initialModel.status === 'charged'
  const hasPayers = size(initialModel.subcharges) > 0

  const allowToUpdateAmount =
    initialModel.status === 'future' ? !initialModel?.is_auto_charged : initialModel.source === 'app' && !initialModel?.is_auto_charged

  if (isOverlayLoading || size(record) === 0) {
    return <OverlayLoader position="right" />
  }

  const toggleCancel = async () => {
    await updateAsync({ status: initialModel.status === 'charged' ? 'cancelled' : 'charged' })
    close()
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header glyph="tag" title="Charge" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          onUpdate={setFormData}
        >
          <Section>
            <FormSection>
              <ObjectSelector
                isEditable={false}
                model="service"
                label="Service"
                type="organization_services"
                sortBy={['order', 'created_at']}
                icon={ICONS.financials}
                selectTitle={(data: any) => `${data.name} (${niceAmount(data.amount)})`}
              />

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a charge name',
                  },
                }}
              />

              {allowToUpdateAmount ? (
                <>
                  {formData?.is_custom_amount ? (
                    <AmountInput
                      label="Amount"
                      model="amount"
                      defaultValue={service?.amount}
                      validations={{
                        presence: {
                          message: 'Please enter an amount',
                        },
                        numericality: {
                          greaterThan: 0,
                          message: 'Please enter an amount',
                        },
                      }}
                    />
                  ) : (
                    <AmountInput label="Service Amount" value={service?.amount} />
                  )}

                  <Checkbox
                    skipDeregister
                    className="!flex-auto"
                    isEditable={isEditable && !isCharged}
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="none"
                    label="Use the Amount from the Service"
                    model="is_custom_amount"
                    true_value={false}
                    false_value={true}
                  />

                  {isEditable && isCharged && (
                    <Alert type="warning">
                      The Service amount does not work for <ChargeStatus inline status="charged" /> Transactions
                    </Alert>
                  )}
                </>
              ) : (
                <>
                  <AmountInput isEditable={false} label="Amount" model="amount" />

                  <Alert glyph="warning" type="warning">
                    The Amount cannot be changed because this Charge was collected using the Automatic Payment Collection
                  </Alert>
                </>
              )}

              <DateInput
                label="Due Date"
                model="transactioned_at"
                validations={{
                  presence: {
                    message: 'Please enter a due date',
                  },
                }}
              />

              {hasEmployees && (
                <OverlaySelector
                  model="employees"
                  label="Staff Member"
                  type="employees.active"
                  apiData={employees}
                  icon={ICONS.employees}
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => startCase(data.position)}
                />
              )}

              {hasOrganizations && (
                <ObjectSelector
                  model="organizations"
                  label="Organization"
                  type="organizations"
                  apiData={organizations}
                  icon={ICONS.organizations}
                  selectTitle={(data: any) => data.name}
                  selectDescription={() => null}
                />
              )}

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section title="Automatic Collection">
            <FormSection maxWidth="100%">
              <CheckboxGroup isEditable={isCharged ? false : isEditable} trueIcon="check" falseIcon="cross" falseStyle="faded">
                <Checkbox
                  isEditable={isCharged ? false : isEditable}
                  label={isCharged ? 'Used Automatic Payment Collection' : 'Use Automatic Payment Collection'}
                  model="should_auto_charge"
                  description="Attempt to collect from the Payment Method selected below"
                />
              </CheckboxGroup>

              {formData?.should_auto_charge && (
                <ObjectSelector
                  isEditable={isCharged ? false : isEditable}
                  icon="billing"
                  type="client.payment_methods"
                  label={isCharged ? 'Charged Payment Method' : 'Payment Method to Charge'}
                  model="payment_method"
                  dependentValue={client?.id}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => data?.reference?.name}
                  validations={{
                    presence: {
                      message: 'Please select a Payment Method to use',
                    },
                  }}
                />
              )}
            </FormSection>
          </Section>

          <Divider />

          {hasPayers && (
            <>
              <ChargePayersSection subcharges={initialModel.subcharges} />
              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload files related to this charge">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable ? (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
              permission="ledger.create"
            />
            <Button label="Cancel" glyph="cross" type="default" onClick={cancel} isDisabled={isSaving} />
          </>
        ) : (
          <>
            <Button label="Edit" glyph="edit" type="default" onClick={edit} flex="100 1 auto" permission="ledger.edit" />

            {initialModel.status === 'cancelled' && (
              <Button
                label="Re-apply Charge"
                glyph="circle_add"
                type="default"
                color="green"
                onClick={toggleCancel}
                permission="ledger.edit"
                isLoading={isSaving}
              />
            )}

            {initialModel.status === 'charged' && (
              <Button
                label="Cancel Charge"
                glyph="circle_error"
                type="default"
                color="red"
                onClick={toggleCancel}
                permission="ledger.edit"
                isLoading={isSaving}
              />
            )}

            {(initialModel.status === 'future' || initialModel.status === 'cancelled') && (
              <DeleteDialog
                title="Delete Charge?"
                message="Are you sure you want to delete this charge? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  fullWidth
                  isLoading={isDeleting}
                  permission="ledger.delete"
                />
              </DeleteDialog>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AuthorityChargeOverlay = withOverlayError(RootOrganizationChargeOverlay)
