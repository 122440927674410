import React from 'react'
import kebabCase from 'lodash/kebabCase'
import { NavLink, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

import Button from '@behavehealth/components/Button'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Status from '@behavehealth/components/Status'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { AuthorityFormSubmission } from '@behavehealth/constructs/Authority/AuthorityFormSubmission'
import { AUTHORITY_FORMS_NAV } from '@behavehealth/constructs/Authority/authority_forms'

export const CertificationForms = () => {
  return (
    <>
      <CertificationFormsIndex />

      <AnimatedRoutes>
        <Route path={`:slug/:id`} element={<AuthorityFormSubmission useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const FORMS = AUTHORITY_FORMS_NAV.find((form) => form.id === 'certifications')?.forms

const CertificationFormsIndex = () => {
  const columns = React.useMemo(
    () => [
      {
        width: 260,
        id: 'name',
        model: 'name',
        title: 'Name',
        formatValue: ({ value, data }: any) => <MainCell as={NavLink} to={`${data.slug}/${data.id}`} value={value} />,
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <Status label="Draft" />,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'form_submissions.edit',
        disableSort: true,
      },
      {
        title: 'Date Submitted',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <Page
      title="Certification Forms"
      icon="certifications_inspections"
      actions={
        <>
          <Button label="Manage Forms" glyph="settings" type="minimal" link="/settings/authority-forms" />

          <Dropdown glyph="add" label="Add New…" buttonType="primary">
            {FORMS.map((form) => (
              <DropdownItem
                as={NavLink}
                key={form.id}
                label={form.title}
                link={`${form.slug}/new`}
                icon="certifications_inspections"
                isDisabled={!form.sections}
              />
            ))}
          </Dropdown>
        </>
      }
    >
      <Grid>
        <DataTable
          asCard
          title="Certification Forms"
          icon="certifications_inspections"
          columns={columns}
          data={[
            {
              id: '1477e8da',
              slug: 'certification-application-form',
              name: 'Certification Application Form',
            },
            {
              id: '2477e8da',
              slug: 'certification-application-renewal-form',
              name: 'Certification Application Renewal Form',
            },
          ]}
          meta={{ count: 2 }}
          batchActionsConfig={[
            {
              type: 'delete',
              action: async ({ ids }: any) => {},
            },
          ]}
        />
      </Grid>
    </Page>
  )
}
