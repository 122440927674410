import React from 'react'

import Icon from '../../Icon'
import Permission from '../../Permission'
import MultiObjectSelector from '../Selectors/MultiObject/MultiObjectSelector'

const selectTitle = (data: any) => data.name
const selectDescription = (data: any) => data.description
const selectGraphic = () => <Icon icon="client_tracks" size={20} />

export const ClientTracksMultiObjectSelector = (props) => {
  return (
    <Permission featureFlagV2="client_tracks">
      <MultiObjectSelector
        label="Applicable Client Tracks"
        model="client_tracks"
        type="client_tracks"
        icon="client_tracks"
        selectTitle={selectTitle}
        selectDescription={selectDescription}
        selectGraphic={selectGraphic}
        showAvatars={false}
        tooltip={
          <>
            <div>Select one or more Client Tracks to restrict this template to the chosen tracks.</div>
            <div>Leave blank to make the template available for all Client Tracks.</div>
          </>
        }
        {...props}
      />
    </Permission>
  )
}
