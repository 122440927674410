import React from 'react'
import { Link, NavLink, Route, useLocation } from 'react-router-dom-v5-compat'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

import { Button, Page } from '@behavehealth/components'
import { QuickTextDataTable } from '@behavehealth/constructs/QuickText/QuickTextDataTable'
import { QuickTextOverlay } from '@behavehealth/constructs/QuickText/QuickTextOverlay'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import QuickTextTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/QuickTextTemplatesImportOverlay'

export const QuickText = () => {
  return (
    <div>
      <QuickTextIndex />

      <AnimatedRoutes>
        <Route path="import" element={<QuickTextTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<QuickTextOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const QuickTextIndex = () => {
  const tableProps = useDataTable({
    name: ['quick_text'],
    endpoint: `/quick_paragraphs`,
  })

  const { pathname } = useLocation()

  const actions = (
    <>
      <Button
        as={Link}
        label="Import Behave Templates"
        type="default"
        glyph="add"
        link={`${pathname}/import`}
        permission="settings.quick_text.create"
      />

      <Button as={Link} label="Add Quick Text" type="primary" glyph="add" link="new" permission="settings.quick_text.create" />
    </>
  )

  return (
    <Page title="Quick Text" feature="quick_text" actions={actions}>
      <div className="grid grid-cols-1">
        <QuickTextDataTable
          mainLinkAs={NavLink}
          {...tableProps}
          to={(row: any) => row.id}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'settings.quick_text.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </div>
    </Page>
  )
}
