import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import produce from 'immer'

import { isDefined } from '../../utils/functions'
import { validate } from './validators'
import { withFormContext, withCheckboxGroupFormContext } from './context'

import RadioCheckBase from './RadioCheckBase'
import Value from '../Value'
import Glyph from '../Glyph'

import FieldBase from './FieldBase'
import { snakeCase } from 'lodash'

export class Checkbox extends FieldBase {
  constructor(props) {
    super(props)

    let defaultTrueFalseValue = null
    let checked = false
    const valueDefined = isDefined(props.value)
    let value = valueDefined ? props.value : false
    if (!valueDefined && props.form && props.model) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)
      value = isDefined(modelVal)
        ? modelVal
        : isDefined(initialModelVal)
        ? initialModelVal
        : isDefined(props.defaultChecked)
        ? props.defaultChecked
        : false
    }

    if (props.hasOwnProperty('true_value') && props.hasOwnProperty('false_value')) {
      // defaultTrueFalseValue = props.defaultChecked ? props.true_value : props.false_value

      if (value === props.true_value) checked = true
    } else {
      checked = value
    }

    let errors = []
    const vs = props.validations

    if (vs) errors = validate(value, vs)

    this.state = {
      type: 'CHECKBOX',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      label: props.label,
      value: value || false,
      isNested: props.isNested || false,
      isChecked: checked,
      isDisabled: props.isDisabled || false,
      isValidations: props.validations,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isRequired: vs?.hasOwnProperty('presence'),
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      errors: [],
      reset: this.onReset,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value || defaultTrueFalseValue || false,
      isChecked: checked,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }
    this.updateType = 'DATA'
  }

  afterMount = () => {
    if (this.props.registerCheckbox) this.props.registerCheckbox(this.state.id, this.setTo, this.state.isChecked)
  }

  getValue = (checked: boolean) => {
    const { true_value, false_value } = this.props

    if (this.props.hasOwnProperty('true_value') && this.props.hasOwnProperty('false_value')) {
      return checked ? true_value : false_value
    }

    return checked
  }

  onChange = () => {
    const toggledChecked = !this.state.isChecked

    this.queue({ type: 'CHANGE_VALUE', value: this.getValue(toggledChecked) })
    this.setState({ isChecked: toggledChecked })
  }

  processUpdate = (_queueEvent: any) => {
    const vs = { ...this.props.defaultValidations, ...this.props.validations }

    let checked = false
    if (this.props.hasOwnProperty('true_value') && this.props.hasOwnProperty('false_value')) {
      if (this.props.value === this.props.true_value) checked = true
    } else {
      checked = this.props.value
    }

    const newState = produce(this.state, (draft: any) => {
      draft.model = this.props.model
      draft.value = this.props.value
      draft.isChecked = checked
      draft.isRequired = vs?.hasOwnProperty('presence')
      draft.defaultValue = this.props.defaultValue
    })

    this.setState({
      isHighlighted: false,
      model: this.props.model,
      value: newState.value,
      isChecked: newState.isChecked,
      isRequired: newState.isRequired,
      defaultValue: newState.defaultValue,
    })

    return newState
  }

  setTo = (value: boolean) => {
    this.changeValue(value)
    this.setState({ isChecked: value })
  }

  /*
    RENDER
  */
  render() {
    const {
      after,
      avatar,
      avatarInitials,
      children,
      className,
      description,
      falseIcon,
      falseStyle,
      glyph,
      glyphColor,
      glyphSize,
      icon,
      label,
      reverse,
      showCheck,
      tooltip,
      trueIcon,
      variant,
      vertical,
      count,
      testKey,
      graphic,
      isCompact,
    } = this.props

    const { id, isChecked, isDisabled, value } = this.state

    let readonlyElement = null

    const falseClassNames = clsx({
      [`false-${falseStyle}`]: falseStyle,
      [className]: className,
    })

    if (isChecked) {
      if (!label) {
        readonlyElement = <Glyph glyph={glyph || trueIcon} data-test={`${testKey}_checked`} color={glyphColor} size={glyphSize} />
      } else {
        readonlyElement = (
          <Value
            className={className}
            avatar={avatar}
            value={label}
            testKey={testKey || `${snakeCase(label)}_checked_checkbox`}
            description={description}
            glyph={glyph || trueIcon}
            glyphColor={glyphColor}
            vertical={vertical}
          />
        )
      }
    } else {
      if (!label) {
        readonlyElement = (
          <div className={falseClassNames} css={falseStyles}>
            <Glyph size={glyphSize} data-test={`${testKey}_unchecked`} glyph={falseIcon} color={glyphColor} />
          </div>
        )
      } else {
        readonlyElement = (
          <div data-test={testKey} className={falseClassNames} css={falseStyles}>
            <Value
              avatar={avatar}
              value={label}
              testKey={testKey || `${snakeCase(label)}_unchecked_checkbox`}
              glyph={falseIcon}
              vertical={vertical}
            />
          </div>
        )
      }
    }

    return this.props.isEditable ? (
      <RadioCheckBase
        id={id}
        after={after}
        count={count}
        avatar={avatar}
        avatarInitials={avatarInitials}
        reverse={reverse}
        isChecked={isChecked}
        isDisabled={isDisabled}
        type="checkbox"
        onFocus={this.onFocus}
        onChange={this.onChange}
        value={value}
        label={label}
        description={description}
        tooltip={tooltip}
        glyph={glyph}
        icon={icon}
        glyphColor={glyphColor}
        className={className}
        showCheck={showCheck}
        variant={variant}
        children={children}
        testKey={testKey || `${snakeCase(label)}_checkbox`}
        graphic={graphic}
        isCompact={isCompact}
      />
    ) : (
      readonlyElement
    )
  }
}

const falseStyles = {
  '&.false-faded': {
    opacity: 0.5,
  },

  '&.false-hidden': {
    display: 'none',
  },

  '&.false-linethrough': {
    opacity: 0.75,
    textDecoration: 'line-through',
    fontStyle: 'italic',
  },

  '&.false-faded-linethrough': {
    opacity: 0.5,
    textDecoration: 'line-through',
    fontStyle: 'italic',
  },
}

Checkbox.defaultProps = {
  glyphSize: 20,
  isEditable: true,
  falseStyle: 'hidden',
  showCheck: true,
  validateOn: 'change',
}

export default withFormContext(withCheckboxGroupFormContext(Checkbox))
