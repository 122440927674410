import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom-v5-compat'

import { Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { ShiftNotesDataTable } from '@behavehealth/constructs/ShiftNotes/ShiftNotesDataTable'

const pageConfig = {
  feature: 'shift_notes',
  help: <HelpTagIframe id="shift_notes" />,
  marketingID: 'shift_notes',
}

const ShiftNotes: React.FC = () => {
  const { resource_id }: any = useParams()

  const { data: location }: any = useGet({
    name: ['location', resource_id],
    url: `/houses/${resource_id}`,
  })

  const tableProps = useDataTable({
    name: ['location', resource_id, 'shift-notes'],
    endpoint: `/houses/${resource_id}/housing_notes`,
    updateDeleteEndpoint: '/housing_notes',
    enabled: !!resource_id,
    localStorageKey: 'location_shift_notes_v1',
  })

  const actions = (
    <>
      <Dropdown label="Add Shift Notes…" glyph="add" buttonType="primary" permission="shift_notes.create">
        <DropdownItem
          as={Link}
          label="Add Shift Note"
          icon="housing_notes"
          color="blue"
          link={`new`}
          state={{
            data: {
              house: location,
            },
          }}
        />
        <DropdownItem as={Link} label="Add Many Shift Notes…" glyph="stack" color="blue" link={`new-batch`} featureFlagV2="bulk_add" />
      </Dropdown>
    </>
  )

  return (
    <Page actions={actions} {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <ShiftNotesDataTable
          {...tableProps}
          mainLinkAs={NavLink}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/shift-notes',
            },
          ]}
          to={(row) => row.id}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'shift_notes.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ShiftNotes, pageConfig))
