import React from 'react'
import { Route as RouteV6, Routes, NavLink } from 'react-router-dom-v5-compat'
import { Route, Redirect, Switch, useRouteMatch, useHistory, useLocation, useParams } from 'react-router-dom'
import { useMedia } from 'use-media'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'

import { BREAKPOINT } from '@behavehealth/theme'
import { fullname, age, usDate, usDateTime } from '@behavehealth/utils/functions'
import Notifications from '@behavehealth/modules/notifications'

import { clearData, updateData } from '@behavehealth/actions/data'
import { store } from '@behavehealth/setup/store'
import { useGet, useUpdate, useDelete, useCreate } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useStore from '@behavehealth/modules/store'
import withBackListener from '@behavehealth/hocs/withBackListener'

import ClientQuickActions from '@behavehealth/components/QuickActions/Client'
import DeleteDialog from '@behavehealth/components/Dialogs/DeleteDialog'
import RequestVOBOverlay from '@behavehealth/components/Overlays/actions/RequestVOBOverlay'

import { HighRiskAlert } from '@behavehealth/constructs/HighRiskAlert/HighRiskAlert'
import { ClientTrackSelector } from '@behavehealth/constructs/ClientTracks/ClientTrackSelector'

import {
  ApplicationStatus,
  BillingStatus,
  Button,
  ClientPortalStatus,
  Divider,
  DropdownItem,
  HelpTagIframe,
  IntakeStatus,
  Nav,
  NavGroup,
  NavItem,
  PageGrid,
  PageProfile,
  Status,
  SummonOverlay,
  TagsSelector,
} from '@behavehealth/components'

import ClientColorPicker from '@behavehealth/components/ColorPickers/ClientColorPicker'
import ConfirmDialog from '@behavehealth/components/Dialogs/ConfirmDialog'
import ERXDropdownItem from '@behavehealth/components/Buttons/ERXDropdownItem'
import ERXStatus from '@behavehealth/components/Statuses/ERXStatus'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'

import ActivityTimeline from './pages/activity_timeline'
import Agreements from './pages/agreements'
import Allergies from './pages/allergies'
import Applications from './pages/applications'
import Authorizations from './pages/authorizations'
import BillingRosters from './pages/billing_rosters'
import Calendar from './pages/calendar'
import ChargeNotes from './pages/charge_notes'
import Claims from './pages/claims'
import ClientCash from './pages/client_cash'
import ClientPortal from './pages/client_portal'
import ClinicalAssessments from './pages/clinical_assessments'
import Communications from './pages/communications'
import Connections from './pages/connections'
import Contacts from './pages/contacts'
import CustomNotes from './pages/custom_notes'
import Diagnoses from './pages/diagnoses'
import Files from './pages/files'
import Financials from './pages/financials'
import GeneralInfo from './pages/general_info'
import Insurance from './pages/insurance'
import IntakeForms from './pages/intake_forms'
import MedicalAssessments from './pages/medical_assessments'
import Medications from './pages/medications'
import Programs from './pages/programs'
import TestResults from './pages/test_results'
import Todos from './pages/todos/todos'
import TreatmentEpisodes from './pages/treatment_episodes'
import TreatmentPlans from './pages/treatment_plans'
import TreatmentPlansV2 from './pages/treatment_plans_v2/treatment_plans'
import VOB from './pages/vob'
import ClinicalMeasurements from './pages/clinical_measurements'
import Occupancies from './pages/occupancies'
import OutcomeMeasures from './pages/outcome_measures'
import { ClientJourneyPage } from './pages/client_journey'
import { ContactsFormSubmissions } from './pages/contacts_form_submissions'
import FormSubmissions from './pages/form_submissions'
import { GrievanceNotes } from './pages/grievance_notes'
import ServiceEpisodes from './pages/service_episodes'
import SafetyChecks from './pages/safety_checks'
import RoundLines from './pages/round_lines'

import InsuranceClaims from './pages/insurance_claims'
import InsuranceCharges from './pages/insurance_charges'
import RCM from './pages/rcm/rcm'

import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'

import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

const TAG_SMART_CATEGORIES = {
  lead: 'applicant',
  intake: 'accepted',
  declined: 'declined',
}

const Admission = () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const params: any = useParams()

  const isBehave = useStore((state) => state.isBehave)
  const tenant: any = useStore((state) => state.tenant)
  const { isSelfServiceBilling, isManagement, timezone } = useSettings()

  const isDesktop = useMedia({ minWidth: BREAKPOINT[3] })

  const { url } = match

  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  const { data: client, isLoading, isError, error }: any = useGet({
    name: ['client', params.client_id],
    url: `/residents/${params.client_id}`,
  })

  const { data: admissions, isError: isAdmissionsError, error: admissionsError }: any = useGet({
    name: ['admissions', params.client_id],
    url: `/residents/${params.client_id}/admissions`,
  })

  const { mutateAsync: updateClient }: any = useUpdate({
    name: ['client', params.client_id],
    url: `/residents/${params.client_id}`,
    invalidate: ['clients'],
  })

  const moveToAdmissionsMutation: any = useCreate({
    name: ['client', params.client_id, 'move_to_admissions'],
    url: `/residents/${params.client_id}/move_to_admissions`,
    invalidate: 'client',
    invalidateKeys: ['clients', 'admissions', 'intake_applications'],
  })

  const { mutateAsync: deleteClientAsync }: any = useDelete({
    name: ['client', params.client_id],
    url: `/residents/${params.client_id}`,
    invalidate: ['clients'],
  })

  const isTenantBilledByBehave = tenant?.is_billed
  const showERXFeatures = tenant?.feature_flags?.erx === true

  const deleteClient = async () => {
    await deleteClientAsync()
    history.push(`/admissions/applicants`)
  }

  const moveToAdmissions = async () => {
    try {
      await moveToAdmissionsMutation.mutateAsync()
    } catch (errors) {
      console.error(errors)
    }
  }

  React.useEffect(() => {
    if (!isError && !isAdmissionsError) return

    console.error(error)
    Notifications.send(error?.error?.data?.errors?.[0], 'negative')
    Notifications.send(error?.data?.errors?.[0], 'negative')

    // go back to the Clients list if unauthorized
    if (error?.error?.status === 401) history.push('/admissions')
    if (admissionsError?.error?.status === 401) history.push('/admissions')

    // go back to the Admissions list if not found
    if (error?.status === 404) history.push('/admissions')
  }, [isError, isAdmissionsError])

  React.useEffect(() => {
    store.dispatch(updateData(`clients.data.${client?.id}`, client))
    store.dispatch(updateData(`admissions.data`, admissions))

    return () => {
      store.dispatch(clearData('clients'))
      store.dispatch(clearData('admissions'))
    }
  }, [client, admissions])

  let back = React.useMemo(() => {
    const base = '/admissions'
    let slug =
      client?.status === 'lead' ? 'applicants' : client?.status === 'intake' ? 'intake' : client?.status === 'declined' ? 'declined' : ''

    return compact([base, slug]).join('/')
  }, [client])

  if (!client || isLoading) return <PageLoader />

  const actions = (
    <>
      <ClientQuickActions data={client} />

      <Divider />

      {showERXFeatures && (
        <ERXDropdownItem isDisabled={client.erx_status !== 'synced'} rcopiaID={client.erx_id} rcopiaExternalID={client.external_id} />
      )}

      <Divider />

      <DropdownItem
        label="Add High-Risk Alert"
        glyph="warning"
        glyphColor="red"
        color="red"
        link={{
          pathname: `${location.pathname}/high-risk-alert`,
          parent: location.pathname,
        }}
        featureFlagV2="risk_alerts"
      />

      <Divider />

      {client.status !== 'declined' && (
        <DropdownItem
          label={`Decline ${client.first_name}…`}
          glyph="decline"
          color="red"
          link={`${location.pathname}/decline-client`}
          permission="client_journey.actions.decline"
        />
      )}

      <DeleteDialog
        title={`Delete ${client.first_name}…`}
        message="Are you sure you want to delete this Applicant? This action cannot be undone."
        onYes={deleteClient}
        permission="client_journey.actions.delete"
      >
        <DropdownItem label="Delete Applicant…" glyph="delete" color="red" permission="client_journey.actions.delete" />
      </DeleteDialog>
    </>
  )

  return (
    <PageGrid columns="320px 1fr" breakpoint={3}>
      <Nav
        more
        showBack
        back={back}
        breakpoint={3}
        headingSize={400}
        record={client}
        title={client.name}
        avatar={client.avatar}
        description={`${startCase(client.sex) || '–'}, #${client.behave_id}`}
        desktopProps={{
          icon: 'intake_forms',
          title: 'Admission',
          headingSize: 300,
          avatar: undefined,
          description: undefined,
        }}
        help={<HelpTagIframe id="admission_profile" />}
        profile={
          <PageProfile
            more
            record={client}
            actions={actions}
            title={fullname(client)}
            subtitle={
              <>
                {client?.dob && (
                  <div>
                    <b>DOB:</b> {usDate(client.dob, timezone)} ({age(client.dob)} y/o)
                  </div>
                )}
              </>
            }
            description={`${startCase(client.sex) || '–'}, #${client.behave_id}`}
            content={
              <>
                {isTenantBilledByBehave && (
                  <SummonOverlay overlay={<RequestVOBOverlay client={client} />}>
                    <Button label="Request Full VOB" glyph="behave_health" color="green" featureFlagV2="full_vobs" />
                  </SummonOverlay>
                )}

                {client.status === 'declined' && (
                  <ConfirmDialog
                    title="Move to Admissions?"
                    message="Are you sure you want to move this Declined Applicant to Admissions?"
                    yesColor="green"
                    onYes={moveToAdmissions}
                  >
                    <Button type="primary" label="Move to Admissions" glyph="check_in" color="yellow" />
                  </ConfirmDialog>
                )}

                {(client.status === 'lead' || client.status === 'declined') && (
                  <Button
                    type="primary"
                    label={`Accept ${client.first_name}…`}
                    glyph="check_in"
                    color="green"
                    link={`${location.pathname}/accept-client`}
                    permission="client_journey.actions.accept"
                  />
                )}

                {client.status === 'intake' && (
                  <Button
                    type="primary"
                    label={`Admit ${client.first_name}…`}
                    glyph="check_in"
                    color="green"
                    link={{
                      pathname: `${location.pathname}/admit-client`,
                      parent: location.pathname,
                    }}
                    permission="client_journey.actions.admit"
                  />
                )}
              </>
            }
            status={
              <>
                {isDesktop && <HighRiskAlert isRounded title={client?.high_risk_title} notes={client?.high_risk_notes} />}

                {client?.id && (
                  <div className="w-full pb-3">
                    <ClientTrackSelector clientId={client.id} activeTrack={client.client_track} />
                  </div>
                )}

                <ClientColorPicker clientID={client?.id} color={client?.color_code} />
                {client.is_billed_by_behave && <BillingStatus status="billed" small />}
                {/* {client.current_intake_application && <ApplicationStatus status={client.current_intake_application.status} />} */}
                {client.current_admission && <IntakeStatus status={client.current_admission?.status} />}
                {client.active_level_of_care && <Status color="green" label={client?.active_level_of_care?.name} />}
                {client?.client_portal_status && <ClientPortalStatus status={client?.client_portal_status} />}
                {showERXFeatures && client?.erx_status && <ERXStatus status={client?.erx_status} />}
              </>
            }
            underActions={
              <div className="!mt-3 w-full">
                <TagsSelector
                  tags={client?.tags}
                  onSave={(tagIDs: any) => updateClient({ tag_ids: tagIDs })}
                  smartCategories={client.status ? TAG_SMART_CATEGORIES[client.status] : 'applicant'}
                />
              </div>
            }
          />
        }
      >
        <NavGroup>
          <NavItem feature="general_info" label="General Info" to={`${url}/general-info`} />
          <NavItem
            showForCommunity
            showMarketing
            feature="todos"
            label="To-Do"
            to={`${url}/todo`}
            permission="todos.view"
            featureFlagV2="todos"
          />
          <NavItem
            showForCommunity
            showMarketing
            label="Calendar"
            to={`${url}/calendar`}
            feature="calendar"
            permission="events.view"
            featureFlagV2="calendar"
          />
          <NavItem
            showForCommunity
            showMarketing
            feature="files"
            label="Files"
            to={`${url}/files`}
            permission="clients.files.view"
            featureFlagV2="files"
          />
          <NavItem
            feature="activity_timeline"
            label="Activity Timeline"
            to={`${url}/activity-timeline`}
            permission="activity_timeline.view"
          />
          <NavItem
            glyph="portal"
            label="Client Portal"
            to={`${url}/client-portal`}
            featureFlagV2="client_portal"
            permission="clients.actions.invite_to_client_portal"
          />
          <NavItem
            showForCommunity
            feature="form_submissions"
            label="Form Inbox"
            to={`${url}/form-submissions`}
            featureFlagV2="form_submissions"
            permission="form_submissions.view"
          />
        </NavGroup>

        <NavGroup label="Client Journey">
          <NavItem
            showMarketing
            feature="client_journey"
            label="Timeline"
            to={`${url}/client-journey`}
            featureFlagV2="client_journey"
            permission="client_journey.view"
          />
          <NavItem showForCommunity feature="treatment_episodes" label="Treatment Episodes" to={`${url}/treatment-episodes`} />
          <NavItem
            showMarketing
            feature="service_episodes"
            label="Service Episodes"
            to={`${url}/service-episodes`}
            featureFlagV2="service_episodes"
            permission="service_episodes.view"
          />
          <NavItem
            showMarketing
            feature="programs"
            label="Program Assignments"
            to={`${url}/programs`}
            permission="programs.view"
            featureFlagV2="programs"
          />
          <NavItem
            showForCommunity
            feature="occupancies"
            label="Bed Assignments"
            to={`${url}/occupancies`}
            featureFlagV2="bed_management"
            permission="bed_management.view"
          />
        </NavGroup>

        <NavGroup label="Care Coordination">
          <NavItem
            showForCommunity
            showMarketing
            feature="applications"
            label="Applications"
            to={`${url}/applications`}
            permission="applications.view"
          />
          <NavItem
            showMarketing
            feature="agreements"
            label="Agreements"
            to={`${url}/agreements`}
            permission="agreements.view"
            featureFlagV2="client_agreements"
          />
        </NavGroup>

        <NavGroup label="Financials">
          <NavItem
            showMarketing
            feature="financials"
            label="Financials"
            to={`${url}/financials`}
            permission="ledger.view"
            featureFlagV2="financials"
          />
          {/* <NavItem
            isDev
            feature="client_cash"
            label="Client Cash"
            to={`${url}/client-cash`}
            permission={isBehave}
          /> */}
        </NavGroup>

        <NavGroup label="Insurance">
          <NavItem
            showMarketing
            feature="insurance_policies"
            label="Insurance & VOB"
            to={`${url}/insurance`}
            permission="insurance_policies.view"
            featureFlagV2="insurance_policies"
          />

          <NavItem
            showMarketing
            feature="insurance_authorizations"
            label="Authorizations"
            to={`${url}/authorizations`}
            permission="insurance_authorizations.view"
            featureFlagV2="insurance_authorizations"
          />
          <NavItem
            showMarketing
            feature="full_vobs"
            icon="insurance"
            label="VOBs"
            to={`${url}/vobs`}
            permission="full_vobs.view"
            featureFlagV2="full_vobs"
          />
          {/* <NavItem color="green" icon="financials" label="RCM" to={`${url}/rcm`} permission="rcm.view" featureFlagV2="rcm" /> */}
          {/* <NavItem
            isDev
            icon="roster"
            label="Billing Roster"
            to={`${url}/billing-roster`}
            permission={isBehave}
            featureFlagV2="insurance_rosters_claims_charges"
          /> */}
          {/* <NavItem
            isDev
            icon="invoices"
            label="Claims"
            to={`${url}/claims`}
            permission={isBehave}
            featureFlagV2="insurance_rosters_claims_charges"
          /> */}
          {/* <NavItem
            isDev
            icon="insurance"
            label="Charges"
            to={`${url}/charges`}
            permission={isBehave}
            featureFlagV2="insurance_rosters_claims_charges"
          /> */}
        </NavGroup>

        <NavGroup label="Contacts">
          <NavItem
            showMarketing
            feature="contacts"
            label="Contacts"
            to={`${url}/contacts`}
            permission="contacts.view"
            featureFlagV2="contacts"
          />
          {/* <NavItem
            feature="form_submissions"
            label="Contacts Form Inbox"
            to={`${url}/contacts-form-submissions`}
            // permission="TODO"
            // featureFlagV2="TODO"
          /> */}
          <NavItem
            showMarketing
            feature="communications"
            label="Communications"
            to={`${url}/communications`}
            permission="communications.view"
            featureFlagV2="communication_logs"
          />
          <NavItem
            showForCommunity
            showMarketing
            feature="care_team"
            label="Care Team"
            to={`${url}/connections`}
            featureFlagV2="care_team"
            permission="connections.view"
          />
        </NavGroup>

        <NavGroup label="Notes">
          <NavItem
            showMarketing
            feature="safety_checks"
            label="Safety Checks"
            to={`${url}/safety-checks`}
            permission="safety_checks.view"
            featureFlagV2="safety_checks"
          />
          <NavItem showMarketing feature="rounds" label="Rounds" to={`${url}/rounds`} permission="rounds.view" featureFlagV2="rounds" />
          <NavItem
            showMarketing
            feature="custom_notes"
            label="Custom Notes"
            to={`${url}/custom-notes`}
            permission="client_custom_notes.view"
            featureFlagV2="client_custom_notes"
          />
          <NavItem
            showMarketing
            feature="grievance_notes"
            label="Grievance Notes"
            to={`${url}/grievance-notes`}
            permission={isBehave || isManagement}
            featureFlagV2="grievance_notes"
          />
        </NavGroup>

        <NavGroup label="Clinical">
          <NavItem
            showMarketing
            feature="diagnoses"
            label="Diagnoses"
            to={`${url}/diagnoses`}
            permission="diagnoses.view"
            featureFlagV2="diagnoses"
          />
          <NavItem
            showMarketing
            feature="clinical_assessments"
            label="Clinical Assessments"
            to={`${url}/clinical-assessments`}
            permission="clinical_assessments.view"
            featureFlagV2="clinical_assessments"
          />
          <NavItem
            as={NavLink}
            showMarketing
            feature="treatment_plans"
            label="Treatment Plans"
            to={`${url}/treatment-plans`}
            permission="treatment_plans.view"
            featureFlagV2="treatment_plans"
          />
          <NavItem
            showMarketing
            feature="clinical_measurements"
            label="Clinical Measures"
            to={`${url}/clinical-measurements`}
            permission="clinical_measurements.view"
            featureFlagV2="clinical_measures"
          />
          <NavItem
            showMarketing
            feature="outcome_measures"
            label="Outcome Measures"
            to={`${url}/outcome-measures`}
            permission="outcome_measures.view"
            featureFlagV2="outcome_measures"
          />
        </NavGroup>

        <NavGroup label="Medical">
          <NavItem showForCommunity feature="allergies" label="Allergies" to={`${url}/allergies`} />
          <NavItem
            showForCommunity
            showMarketing
            feature="test_results"
            label="Test Results"
            to={`${url}/test-results`}
            permission="test_results"
            featureFlagV2="test_results"
          />
          <NavItem
            showMarketing
            feature="medications"
            label="Medications"
            to={`${url}/medications`}
            permission="medications.view"
            featureFlagV2="medications"
          />
          <NavItem
            showMarketing
            feature="medical_assessments"
            label="Medical Assessments"
            to={`${url}/medical-assessments`}
            featureFlagV2="medical_assessments"
            permission="medical_assessments.view"
          />
        </NavGroup>
      </Nav>

      {!isDesktop && <HighRiskAlert title={client?.high_risk_title} notes={client?.high_risk_notes} />}

      <Switch>
        <ProtectedRoute
          path={`/admissions/:resource_id/activity-timeline`}
          component={ActivityTimeline}
          permission="activity_timeline.view"
        />
        <Route path={`/admissions/:resource_id/general-info`} component={GeneralInfo} />
        <Route path={`/admissions/:resource_id/treatment-episodes`} component={TreatmentEpisodes} />
        <ProtectedRoute
          path={`/admissions/:resource_id/service-episodes`}
          component={ServiceEpisodes}
          featureFlagV2="service_episodes"
          permission="service_episodes.view"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/authorizations`}
          component={Authorizations}
          permission="insurance_authorizations.view"
          featureFlagV2="insurance_authorizations"
        />
        <ProtectedRoute path={`/admissions/:resource_id/todo`} component={Todos} permission="todos.view" featureFlagV2="todos" />
        <ProtectedRoute path={`/admissions/:resource_id/files`} component={Files} permission="clients.files.view" featureFlagV2="files" />
        <ProtectedRoute
          path={`/admissions/:resource_id/safety-checks`}
          component={SafetyChecks}
          permission="safety_checks.view"
          featureFlag="safety_checks"
        />
        <ProtectedRoute path={`/admissions/:resource_id/rounds`} component={RoundLines} permission="rounds.view" featureFlag="rounds" />
        <ProtectedRoute
          path={`/admissions/:resource_id/programs`}
          component={Programs}
          permission="programs.view"
          featureFlagV2="programs"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/agreements`}
          component={Agreements}
          permission="agreements.view"
          featureFlagV2="client_agreements"
        />
        <ProtectedRoute path={`/admissions/:resource_id/calendar`} component={Calendar} permission="events.view" featureFlagV2="calendar" />
        <ProtectedRoute
          path={`/admissions/:resource_id/custom-notes`}
          component={CustomNotes}
          permission="client_custom_notes.view"
          featureFlagV2="client_custom_notes"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/grievance-notes`}
          component={GrievanceNotes}
          permission={isBehave || isManagement}
          featureFlag="grievance_notes"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/client-portal`}
          component={ClientPortal}
          featureFlagV2="client_portal"
          permission="clients.actions.invite_to_client_portal"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/occupancies`}
          component={Occupancies}
          permission="bed_management.view"
          featureFlagV2="bed_management"
        />
        <Route path={`/admissions/:resource_id/contacts-form-submissions`} component={ContactsFormSubmissions} />
        <ProtectedRoute
          path={`/admissions/:resource_id/form-submissions`}
          featureFlagV2="form_submissions"
          permission="form_submissions.view"
        >
          <FormSubmissions reference={client} allowedReference="admissions" />
        </ProtectedRoute>

        {/* Contacts */}
        <ProtectedRoute
          path={`/admissions/:resource_id/connections`}
          component={Connections}
          featureFlagV2="care_team"
          permission="connections.view"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/contacts`}
          component={Contacts}
          permission="contacts.view"
          featureFlagV2="contacts"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/communications`}
          component={Communications}
          permission="communications.view"
          featureFlagV2="communication_logs"
        />

        {/* Client Journey */}
        <ProtectedRoute
          path={`/admissions/:resource_id/client-journey`}
          component={ClientJourneyPage}
          featureFlagV2="client_journey"
          permission="client_journey.view"
        />

        {/* Admissions */}
        <ProtectedRoute path={`/admissions/:resource_id/applications`} component={Applications} permission="applications.view" />
        <ProtectedRoute
          path={`/admissions/:resource_id/insurance`}
          component={Insurance}
          permission="insurance_policies.view"
          featureFlagV2="insurance_policies"
        />
        <ProtectedRoute path={`/admissions/:resource_id/vobs`} component={VOB} permission="full_vobs.view" featureFlagV2="full_vobs" />
        <Route path={`/admissions/:resource_id/intake-forms`} component={IntakeForms} />

        {/* Financials */}
        <ProtectedRoute
          path={`/admissions/:resource_id/financials`}
          component={Financials}
          permission="ledger.view"
          featureFlagV2="financials"
        />
        <Route path={`/admissions/:resource_id/charge-notes`} component={ChargeNotes} />
        <Route path={`/admissions/:resource_id/client-cash`} component={ClientCash} />
        <Route path={`/admissions/:resource_id/billing-roster`} component={BillingRosters} />
        <Route path={`/admissions/:resource_id/claims`} component={Claims} />

        {/* Billing */}
        {/* <Route path={`/clients/:resource_id/rosters`} component={InsuranceRosters} /> */}
        <Route path={`/admissions/:resource_id/claims`} component={InsuranceClaims} />
        <Route path={`/admissions/:resource_id/charges`} component={InsuranceCharges} />
        <ProtectedRoute path={`/admissions/:resource_id/rcm`} component={RCM} permission="rcm.view" featureFlagV2="rcm" />

        {/* Clinical */}
        <ProtectedRoute
          path={`/admissions/:resource_id/clinical-assessments`}
          component={ClinicalAssessments}
          permission="clinical_assessments.view"
          featureFlagV2="clinical_assessments"
        />
        <Route path={`/admissions/:resource_id/archived-treatment-plans`} component={TreatmentPlans} />
        {/* <Route path={`/admissions/:resource_id/treatment-plans`} component={TreatmentPlansV2} /> */}
        <ProtectedRoute
          path={`/admissions/:resource_id/diagnoses`}
          component={Diagnoses}
          permission="diagnoses.view"
          featureFlagV2="diagnoses"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/clinical-measurements`}
          component={ClinicalMeasurements}
          permission="clinical_measurements.view"
          featureFlagV2="clinical_measures"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/outcome-measures`}
          component={OutcomeMeasures}
          permission="outcome_measures.view"
          featureFlagV2="outcome_measures"
        />

        {/* Medical */}
        <Route path={`/admissions/:resource_id/allergies`} component={Allergies} />
        <ProtectedRoute
          path={`/admissions/:resource_id/medications`}
          component={Medications}
          permission="medications.view"
          featureFlagV2="medications"
        />
        <ProtectedRoute
          path={`/admissions/:resource_id/medical-assessments`}
          component={MedicalAssessments}
          featureFlagV2="medical_assessments"
          permission="medical_assessments.view"
        />

        <Redirect exact from={match.url} to={`/admissions/${params?.client_id}/general-info`} />
      </Switch>

      <Routes>
        <RouteV6
          path="/admissions/:resource_id/treatment-plans/*"
          element={
            <ProtectedRouteV6 featureFlagV2="treatment_plans" permission={viewSomeTreatmentPlans}>
              <TreatmentPlansV2 />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/admissions/:resource_id/test-results/*"
          element={
            <ProtectedRouteV6 featureFlagV2="test_results" permission="test_results">
              <TestResults />
            </ProtectedRouteV6>
          }
        />
      </Routes>
    </PageGrid>
  )
}

export default withBackListener(withPageError(Admission))
