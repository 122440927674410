import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Dropdown, DropdownItem, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { RECOVERY_NOTES_SLUGS } from '@behavehealth/utils/constants'
import { RecoveryNotesDataTable } from '@behavehealth/constructs/RecoveryNotes/RecoveryNotesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { StaffDataFormsAlert } from '../components/StaffDataFormsAlert'

const pageConfig = {
  feature: 'recovery_coaching',
  help: <HelpTagIframe id="recovery_coaching" />,
  marketingID: 'recovery_coaching',
}

const RecoveryCoaching: React.FC = ({ canCreate = true }: any) => {
  const match = useRouteMatch()
  const { resource_id: resourceID }: any = useParams()

  const { formSettings } = useSettings()

  const tableProps = useDataTable({
    name: ['employee', resourceID, 'data_forms'],
    endpoint: `/employees/${resourceID}/data_forms`,
    params: { category: 'recovery_coaching' },
    updateDeleteEndpoint: '/data_forms',
    enabled: !!resourceID,
    localStorageKey: 'employee_recovery_coaching_v1',
  })

  const formsDropdown = (
    <Dropdown
      testKey="add_new_recovery_note"
      label="Add New…"
      buttonType="primary"
      glyph="add"
      position="bottom"
      permission="recovery_coaching.create"
    >
      <DropdownItem
        label="Case Management Checklist"
        color="orange"
        icon="recovery_coaching"
        link={{
          pathname: `${match.url}/case-management-checklist/new`,
          parent: match,
        }}
      />

      {formSettings?.recovery_notes_recovery_capital_scale && (
        <DropdownItem
          label="Recovery Capital Scale"
          icon="recovery_coaching"
          link={{
            pathname: `${match.url}/recovery-capital-scale/new`,
            parent: match,
          }}
        />
      )}

      {formSettings?.recovery_notes_recovery_capital_plan && (
        <DropdownItem
          label="Recovery Capital Plan"
          icon="recovery_coaching"
          link={{
            pathname: `${match.url}/recovery-capital-plan/new`,
            parent: match,
          }}
        />
      )}

      {formSettings?.recovery_notes_recovery_capital_scale_and_plan && (
        <DropdownItem
          label="Recovery Capital Scale & Plan"
          icon="recovery_coaching"
          link={{
            pathname: `${match.url}/recovery-capital-scale-and-plan/new`,
            parent: match,
          }}
        />
      )}

      {formSettings?.recovery_notes_mirc && (
        <DropdownItem
          label="Multidimensional Inventory of Recovery Capital"
          icon="recovery_coaching"
          link={{
            pathname: `${match.url}/mirc/new`,
            parent: match,
          }}
        />
      )}
    </Dropdown>
  )

  return (
    <Page {...pageConfig}>
      <Grid gap="1rem">
        <StaffDataFormsAlert name="Recovery Notes" />

        <RecoveryNotesDataTable
          {...tableProps}
          to={({ id, subcategory }) => ({
            pathname: `${match.url}/${RECOVERY_NOTES_SLUGS[subcategory]}/${id}`,
            parent: match,
          })}
          // duplicateFormLink={(record: any) => ({
          //   pathname: `${match.url}/case-management-checklist/new`,
          //   parent: match,
          //   data: {
          //     name: record.name,
          //     data: record.data,
          //   },
          // })}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'recovery_coaching.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(RecoveryCoaching, pageConfig))
