import React from 'react'
import startCase from 'lodash/startCase'

import { withFormContext } from '../../../components/Forms/context'
import { encodeObjectToURL } from '../../../utils/functions'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardHeader from '../../../components/CardHeader'
import CardContent from '../../../components/CardContent'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import Input from '../../../components/Forms/Input'
import Type from '../../../components/Typography/Type'
import QuickView from '../../../components/QuickView'
import PrintQuickView from './PrintQuickView'

import ClaimInternalStatusSelect from './ClaimInternalStatusSelect'
import { URL } from '../../../utils/globals'
import { getToken } from '../../../modules/axios'
import useStore from '../../../modules/store'

import { TYPOGRAPHY_VARIANTS } from '../../../theme'

const ClaimBehaveDetails = ({ isNew, claimID }: any) => {
  const tenant = useStore((state) => state.tenant)
  const [token, setToken] = React.useState({})

  const previewURL = `${URL.api}/insurance_claims/${claimID}/pdf?${encodeObjectToURL({
    variant: 'with_bg', // no_bg
    tenant: tenant.subdomain,
    ...token,
  })}`

  const printURL = `${URL.api}/insurance_claims/${claimID}/pdf?${encodeObjectToURL({
    variant: 'no_bg',
    tenant: tenant.subdomain,
    ...token,
  })}`

  React.useEffect(() => {
    const buildToken = async () => {
      const tokenResult = await getToken()

      if (tokenResult?.tokenType === 'jwt') {
        setToken({ token: tokenResult?.token })
      } else if (tokenResult?.tokenType === 'session') {
        setToken({ session_token: tokenResult?.token })
      }
    }

    buildToken()
  }, [])

  return (
    <Card>
      <CardHeader>
        <Flex centerY justifyContent="space-between">
          <Type as="h3" css={styles.cardTitle} variant={TYPOGRAPHY_VARIANTS.CARD_TITLE}>
            General Details
          </Type>

          {!isNew && (
            <Flex gap="1rem">
              <QuickView title="Preview PDF Claim" contentType="application/pdf" url={previewURL}>
                <Button useGlyphForTarget={false} size={100} glyph="view" label="Preview PDF" type="link" className="!p-0" />
              </QuickView>

              <PrintQuickView title="Print PDF" url={printURL}>
                <Button useGlyphForTarget={false} size={100} glyph="print" label="Print PDF" type="link" className="!p-0" />
              </PrintQuickView>
            </Flex>
          )}
        </Flex>
      </CardHeader>

      <CardContent css={styles.cardContent}>
        <Flex gap="1rem">
          {!isNew && <Input isEditable={false} label="Claim ID" model="control_number" prefix="#" />}

          <ClaimInternalStatusSelect model="status" />

          <ObjectSelector
            isPolymorphic
            model="assignee"
            label="Assignee"
            type="employees"
            icon="employees"
            selectTitle={(data: any) => data.name}
            selectDescription={(data: any) => startCase(data.position)}
          />
        </Flex>
      </CardContent>
    </Card>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateRows: 'auto 1fr',
  },

  card: {
    display: 'grid',
    gridGap: '1rem',
    padding: '0.5rem 1rem 1rem',
    gridAutoRows: 'min-content',

    '@media (min-width: 800px)': {
      gridTemplateColumns: '1fr 1fr',
    },
  },

  cardTitle: {
    fontSize: '1rem',
  },

  cardContent: {
    height: '100%',
  },
}

export default withFormContext(ClaimBehaveDetails)
