import React from 'react'
import { tint, darken } from 'polished'

import { COLORS } from '../../theme'
import withSettings from '../../hocs/withSettings'
import { useSettings } from '../../hooks/useSettings'

import Glyph from '../Glyph'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: any, moveLink: any, removeLink: any, timezone: string, mainLinkAs) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'reference.name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        as={mainLinkAs}
        to={to && to(row?.original?.reference)}
        label={value}
        avatar={row?.original?.reference?.avatar || ''}
      />
    ),
  },
  {
    width: 200,
    accessor: 'started_at',
    Header: 'Assignment From',
    Cell: ({ value }: any) => {
      return <TableCell.UsDateTime value={value} timezone={timezone} />
    },
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'ended_at',
    Header: 'Assignment Until',
    Cell: ({ value }: any) => {
      return <TableCell.UsDateTime value={value} timezone={timezone} />
    },
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 160,
    accessor: 'place.name',
    Header: 'Seat',
  },
  {
    Header: 'Location',
    accessor: 'reference.todays_bed_occupancy.place.house',
    width: 160,
    Cell: ({ value }: any) => {
      if (!value) return '–'

      return <TableCell.Link as={mainLinkAs} label={value.name} avatar={value.avatar || ''} to={`/locations/${value.id}`} />
    },
  },
  {
    Header: 'Room',
    accessor: 'reference.todays_bed_occupancy.place.room.name',
    width: 160,
  },
  {
    Header: 'Floor',
    accessor: 'reference.todays_bed_occupancy.place.floor.name',
    width: 160,
  },
  {
    Header: 'Bed',
    accessor: 'reference.todays_bed_occupancy.place.name',
    width: 160,
  },
  {
    id: 'move_action',
    width: 160,
    accessor: 'id',
    disableFilters: true,
    canToggleVisible: false,
    Header: 'Move',
    Cell: ({ row }: any) => {
      return (
        <TableCell.Link
          as={mainLinkAs}
          label="Move…"
          glyph={<Glyph glyph="swap" size={12} color={COLORS.green} />}
          color="blue"
          to={moveLink?.(row.original.id)}
        />
      )
    },
  },
  {
    id: 'remove_action',
    width: 160,
    accessor: 'id',
    disableFilters: true,
    canToggleVisible: false,
    Header: 'Remove',
    Cell: ({ row }: any) => {
      return (
        <TableCell.Link
          as={mainLinkAs}
          label="Remove…"
          glyph={<Glyph glyph="delete" size={12} />}
          color="red"
          to={removeLink?.(row.original.id)}
        />
      )
    },
  },
]

type Props = {
  batchActionsConfig?: Object
  data: any
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  moveLink?: any
  removeLink?: any
  title: string
  titleAfter?: React.ReactNode
  to?: Function
}

const PhaseTable = (props: Props) => {
  const [moveOverlayOpen, setMoveOverlayOpen]: any = React.useState(false)
  const [removeOverlayOpen, setRemoveOverlayOpen]: any = React.useState(false)
  const [overlayData, setOverlayData]: any = React.useState(null)

  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    moveLink,
    removeLink,
    title,
    titleAfter,
    to,
  } = props

  const { timezone } = useSettings()

  return (
    <>
      <Table
        showSettings={false}
        title={title}
        titleAfter={titleAfter}
        icon={icon}
        data={data}
        columns={columns(to, moveLink, removeLink, timezone, props.mainLinkAs)}
        isLoading={isLoading}
        emptyActions={emptyActions}
        emptyDescription={emptyDescription}
        batchActionsConfig={batchActionsConfig}
        localStorageKey={localStorageKey}
        customCellProps={(cell: any) => {
          if (cell.row?.original?.in_the_past) {
            return {
              style: {
                background: tint(0.7, COLORS.red),
                boxShadow: `inset 0 0 1px ${darken(0.2, COLORS.red)}`,
              },
            }
          }
        }}
      />
    </>
  )
}

PhaseTable.defaultProps = {
  title: 'Program List',
  icon: 'program_lists',
  emptyDescription: 'No assignments added to this list yet',
  localStorageKey: 'phase',
}

export default withSettings(PhaseTable)
