import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Alert from '@behavehealth/components/Alert'
import Card from '@behavehealth/components/Card'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import State from '@behavehealth/components/State'
import CardContent from '@behavehealth/components/CardContent'
import DnDUploader from '@behavehealth/constructs/Organizations/DnDUploader'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'

import FileStatus from '@behavehealth/components/Statuses/FileStatus'

import { useGet } from '@behavehealth/hooks/useNewAPI'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const RootFiles = () => {
  const params: any = useParams()
  const match: any = useRouteMatch()
  const { resource_id: id } = params

  const { data: organization, isLoading }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const isShared = organization?.other_partner_settings?.files === true

  const tableProps: any = useDataTable({
    name: ['organization', id, 'organization_files'],
    endpoint: `/organizations/${id}/organization_files`,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            value={value}
            to={{
              pathname: `${match.url}/${data.id}`,
              parent: match,
            }}
          />
        ),
      },
      {
        width: 70,
        model: 'upload_content_type',
        title: 'Type',
        formatValue: ({ value }: any) => <FileStatus status={value} />,
      },
      // {
      //   width: 160,
      //   model: 'file_tags',
      //   title: 'Tags',
      //   formatValue: ({ value }: any) => {
      //     if (!value || value.length === 0) return '–'
      //     return value.map((tag: any) => tag.name).join(', ')
      //   },
      // },
      {
        width: 160,
        model: 'notes',
        title: 'Notes',
      },
      {
        width: 160,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 160,
        model: 'uploaded_by.name',
        title: 'Added By',
      },
      {
        width: 160,
        model: 'uploaded_by_partner.name',
        title: 'Added By Organization',
      },
    ],
    [],
  )

  if (!organization || isLoading) return <PageLoader />

  const partner = organization.other_partner

  const infoAlert = (
    <Alert small contrast glyph="info" className="mb-4" type="negative">
      <span className="font-[600]">Please note: </span> Do not share Client files here. This is for sharing <strong>Public Files</strong>{' '}
      with <strong>{partner?.name || '–'}</strong>.
    </Alert>
  )

  if (!isShared) {
    return (
      <Page icon="files" title="Shared Files">
        {infoAlert}

        <Card>
          <State isEmpty icon="files" title="Files" emptyDescription={`${partner?.name || '–'} has not shared any files`} />
        </Card>
      </Page>
    )
  }

  return (
    <Page title="Shared Files" icon="files">
      {infoAlert}
      <div className="grid grid-cols-[100%]">
        <Card>
          <DataTable {...tableProps} icon="files" title="Files" columns={columns} />

          <CardContent>
            <DnDUploader resource={organization} queryKey={['organization', id, 'documents']} isEditable={true} isDisabled={false} />
          </CardContent>
        </Card>
      </div>
    </Page>
  )
}

export const Files = withPageError(RootFiles)
