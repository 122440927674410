import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { FilesDataTable } from '@behavehealth/constructs/Files/FilesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, CardContent, DnDUploader, Grid, HelpTagIframe, Page, Permission, Uploader } from '@behavehealth/components'

const pageConfig = {
  feature: 'files',
  help: <HelpTagIframe id="files" />,
  marketingID: 'files',
}

const Files = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps: any = useDataTable({
    name: ['client', resource_id, 'files'],
    endpoint: `/residents/${resource_id}/documents`,
    updateDeleteEndpoint: '/documents',
    enabled: !!resource_id,
    localStorageKey: 'client_files_v1',
  })

  const actions = (
    <Uploader category="general" resource={client} queryKey={tableProps.queryKey}>
      <Button label="Upload Files" glyph="add_file" type="primary" size={300} permission="clients.files.create" />
    </Uploader>
  )

  return (
    <Page actions={canCreate && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Card>
          <FilesDataTable
            {...tableProps}
            title="Client Files"
            to={(row: any) => `${match.url}/${row.id}`}
            headerLinksConfig={[
              {
                type: 'report',
                to: '/reports/clients-files',
              },
            ]}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'clients.files.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />

          {canCreate && (
            <CardContent>
              <Permission permission="clients.files.create">
                <DnDUploader category="general" queryKey={tableProps.queryKey} resource={client} isEditable={true} isDisabled={false} />
              </Permission>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Files, pageConfig))
