import React from 'react'

import Radio from '../Radio'
import RadioGroup from '../RadioGroup'
import _ from 'lodash'

const YesNoRadioGroup = ({
  isEditable,
  label,
  description,
  model,
  yesLabel,
  noLabel,
  validations,
  layout,
  isRequired,
  testKey,
  withHover,
}: any) => (
  <RadioGroup
    isEditable={isEditable}
    testKey={testKey}
    label={label}
    description={description}
    model={model}
    withHover={withHover}
    validations={{
      ...(isRequired && {
        presence: {
          message: 'This field is required',
        },
      }),
      ...validations,
    }}
    layout={layout}
  >
    <Radio label={yesLabel} value={true} />
    <Radio label={noLabel} value={false} />
  </RadioGroup>
)

YesNoRadioGroup.defaultProps = {
  yesLabel: 'Yes',
  noLabel: 'No',
  layout: 'horizontal-dense',
}

export default YesNoRadioGroup
