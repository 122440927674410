import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import ReportStatus from '../../components/Statuses/ReportStatus'

import { FILTERS } from '../Filters/config'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'

export const DataExportsDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value} avatar={data.avatar} to={to?.(data)} />,
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <ReportStatus small status={value} />,
        width: 140,
      },
      {
        title: 'Requested At',
        model: 'created_at',
        type: 'date_time',
        width: 200,
      },
      {
        title: 'Start Date',
        model: 'starting_at',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'End Date',
        model: 'ending_at',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Requested By',
        model: 'author',
        type: 'profile',
        width: 180,
      },
      {
        title: 'Download',
        model: 'attachment',
        disableSort: true,
        formatValue: ({ value }: any) => {
          if (!value) return 'Generating…'
          return <LinkCell isExternal href={value} value="Download" glyph="download" />
        },
        width: 180,
      },
    ]
  }, [to])

  return <DataTable asCard title="Data Exports" icon="reports" columns={columns} filtersConfig={FILTERS.data_exports} {...rest} />
}
