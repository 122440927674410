import React from 'react'
import { Link, useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AuthorityOrganizationCommunicationsDataTable } from '@behavehealth/constructs/AuthorityOrganizations/AuthorityOrganizationCommunicationsDataTable'
import { Dropdown, DropdownItem, Grid, Page } from '@behavehealth/components'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'communications',
  title: 'Communications',
}

const RootCommunications: React.FC<Props> = ({ canCreate = true }) => {
  const { user } = useSettings()
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['organization', resource_id, 'communications'],
    endpoint: `/organizations/${resource_id}/communications`,
    updateDeleteEndpoint: '/communications',
    enabled: !!resource_id,
    localStorageKey: 'authority_organization_communications_v1',
    headers: {
      'X-Request-Level': 'global',
    },
  })

  const actions = canCreate && (
    <Dropdown label="Add Communication Logs…" glyph="add" buttonType="primary" permission="communications.create">
      <DropdownItem as={Link} link="new" label="Add Communication Log" icon="communications" color="orange" />

      {/* <DropdownItem
        label="Add Many Communication Logs…"
        glyph="stack"
        color="orange"
        featureFlagV2="bulk_add"
        link={{
          pathname: `${match.url}/new-batch`,
        }}
      /> */}
    </Dropdown>
  )

  return (
    <Page title="Communications" feature="communications" actions={actions}>
      <Grid gap="1rem" columns="100%">
        <AuthorityOrganizationCommunicationsDataTable
          {...tableProps}
          to={({ id }) => id}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'communications.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export const Communications = withPageError(withMarketing(RootCommunications, pageConfig))
