import React from 'react'
import { tint, lighten } from 'polished'
import { useMedia } from 'use-media'
import { useRouteMatch, useLocation } from 'react-router-dom'
import {
  useMatch as useMatchV6,
  useLocation as useLocationV6,
  useParams as useParamsV6,
  Link as LinkV6,
  NavLink as NavLinkV6,
} from 'react-router-dom-v5-compat'
import clsx from 'clsx'
import compact from 'lodash/compact'
import size from 'lodash/size'
import { parse } from 'query-string'

import useStore from '../../modules/store'

import { COLORS, SHADOW, HARD_SHADOW } from '../../theme'
import { formatURL, isDefined } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'
import { useIsMounted } from '../../hooks/useIsMounted'

import { AddressComboBox } from '../../components/Forms/AddressComboBox'
import { PopoverMenu } from '../../components/PopoverMenu'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import ButtonGroup from '../../components/ButtonGroup'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import GridTable from '../../components/GridTable'
import NavLink from '../../components/NavLink'
import Option from '../../components/Forms/Option'
import Search from '../../components/Search'
import Select from '../../components/Forms/Select'
import State from '../../components/State'

import { CommunityContext } from './context'
import { CommunityMap } from './CommunityMap'
import { LOCATION_CATEGORIES, LOCATION_CATEGORIES_FILTERS } from './constants'
import { saveSettings, getSettings } from './localStorage'

import { SearchHighlight } from '../Search/SearchHighlight'
import { SearchResultContext } from '../Search/context'

const US_CENTER = {
  lat: 40.329796,
  lon: -96.196289,
}

const entityToFeature = (entity: any) => {
  if (!entity) return null

  return {
    type: 'Feature',
    properties: {
      entity_id: entity.entity_id,
      entity_slug: entity.entity_slug,
      name: entity.entity_name,
      ...(entity.latlon && {
        lat: entity.latlon[0],
        lon: entity.latlon[1],
      }),
    },
    geometry: {
      type: 'Point',
      coordinates: [entity?.latlon?.[1], entity?.latlon?.[0]],
    },
    source: 'community',
  }
}

export const CommunityBrowser = ({ useV6Router, onFeatureSelect, hideSuggestAction }: any) => {
  const form = React.useRef()
  const isDesktop = useMedia({ minWidth: 1100 })
  const { current: isMounted } = useIsMounted()

  const location = useV6Router ? useLocationV6() : useLocation()
  const searchParams = parse(location.search)

  const latParam = searchParams?.lat && parseFloat(searchParams?.lat)
  const lonParam = searchParams?.lon && parseFloat(searchParams?.lon)

  const tenant = useStore((state: any) => state.tenant)
  const newUser = useStore((state: any) => state.newUser)
  const isBehave = newUser.is_behave

  const [mapRef, setMapRef] = React.useState()

  const savedSettings = getSettings(tenant?.id)

  const [selectedFeature, setSelectedFeature]: any = React.useState(null)

  const [radius, setRadius] = React.useState(savedSettings?.radius || 25)
  const [search, setSearch]: any = React.useState(savedSettings?.search || '')

  const [address, setAddress]: any = React.useState(savedSettings?.address || null)
  const [property, setProperty]: any = React.useState(savedSettings?.property || tenant?.locations?.[0])
  const [searchType, setSearchType] = React.useState(savedSettings?.searchType || 'address')

  const [locationCategories, setLocationCategories] = React.useState(savedSettings?.locationCategories || LOCATION_CATEGORIES_FILTERS)

  const [viewport, setViewport]: any = React.useState(
    savedSettings?.viewport || {
      latitude: tenant?.locations?.[0]?.lat || US_CENTER.lat,
      longitude: tenant?.locations?.[0]?.lon || US_CENTER.lon,
      zoom: 6,
    },
  )

  const [circleCenter, setCircleCenter]: any = React.useState(savedSettings?.circleCenter || null)

  const [tab, setTab] = React.useState('search')

  // Search
  const [query, setQuery] = React.useState('')

  const [isSearchOpen, setIsSearchOpen] = React.useState(false)
  const [isSearchFocused, setIsSearchFocused] = React.useState(false)
  const [filtersVisible, setFiltersVisible] = React.useState(true)

  const searchQueryParams = {
    q: query,
    entity_type: 'organization',
    items: 25,
  }

  const searchMenuRef = React.useRef()
  const canSearch = size(query) > 1

  const {
    data: searchResults,
    isLoading: isSearchLoading,
    isRefetching: isSearchRefetching,
  } = useGet({
    name: ['community-entities-search', searchQueryParams],
    url: '/community/locations/search',
    params: searchQueryParams,
    options: {
      enabled: canSearch && tab === 'search',
      keepPreviousData: true,
    },
  })

  const handleSearchSelect = (location: any) => {
    if (location) {
      setSelectedFeature(location)
      setIsSearchOpen(false)

      if (location.properties.lat && location.properties.lon) {
        setViewport((current: any) => ({ ...current, zoom: 16 }))
      }
    }
  }

  const isSearchEmpty = size(searchResults?.results) === 0

  React.useEffect(() => {
    if (isSearchFocused) setIsSearchOpen(true)
  }, [isSearchFocused])

  React.useEffect(() => {
    if (!isDefined(address?.lat) || !isDefined(address?.lon)) return

    setCircleCenter({ lat: address.lat, lon: address.lon })
  }, [address])

  const handleSearchArea = (center: any) => {
    if (!isDefined(viewport.latitude) || !isDefined(viewport.longitude)) return

    setSearch('')
    setAddress(null)
    setCircleCenter({ lat: viewport.latitude, lon: viewport.longitude })
  }

  const latlon = React.useMemo(() => {
    const lat = circleCenter?.lat
    const lon = circleCenter?.lon

    if (!isDefined(lat) || !isDefined(lon)) return

    return `${circleCenter.lat},${circleCenter.lon}`
  }, [circleCenter])

  const queryParams = tab === 'search' && !!tenant.state ? { state: tenant.state } : { latlon, radius }

  const { data } = useGet({
    name: ['community-locations', queryParams],
    url: '/community/locations/full_search',
    params: queryParams,
    options: {
      enabled: true,
      keepPreviousData: true,
    },
  })

  const updateCategoriesFilter = (category: string, value: boolean) => {
    setLocationCategories((current: any) => ({
      ...current,
      [category]: value,
    }))
  }

  const handleAddressSelect = (o: any) => {
    if (!isDefined(o?.lat) || !isDefined(o?.lon)) return

    setAddress(o)
    setSearchType('address')
    setViewport((current: any) => ({
      ...current,
      latitude: o.lat,
      longitude: o.lon,
    }))
  }

  React.useEffect(() => {
    if (latParam && lonParam) {
      setViewport((current: any) => ({
        ...current,
        zoom: 16,
        latitude: latParam,
        longitude: lonParam,
      }))
    }

    // if (selectedFeature?.properties?.lat && selectedFeature?.properties?.lon) {
    //   setViewport((current: any) => ({
    //     ...current,
    //     latitude: selectedFeature.properties.lat,
    //     longitude: selectedFeature.properties.lon,
    //   }))
    // }
  }, [latParam, lonParam])

  // Save settings to localStorage
  React.useEffect(() => {
    saveSettings(tenant?.id, {
      address,
      circleCenter,
      locationCategories,
      property,
      radius,
      search,
      searchType,
      viewport,
    })
  }, [address, circleCenter, locationCategories, property, radius, savedSettings, search, searchType, viewport])

  return (
    <CommunityContext.Provider value={{ mapRef, selectedFeature, setSelectedFeature, viewport, setViewport }}>
      <div data-test="community_browser" css={STYLES.root} className={isDesktop ? 'is-desktop' : ''}>
        <main css={STYLES.main} className={clsx(isDesktop && 'is-desktop')}>
          <div
            css={STYLES.aside}
            className={clsx(isDesktop && 'is-desktop', tab === 'search' && !!query && 'is-large', !filtersVisible && 'filters-invisible')}
          >
            <Card
              css={STYLES.mainCard}
              className={clsx(isDesktop && 'is-desktop', tab === 'search' && !!query && 'is-large', !filtersVisible && 'filters-invisible')}
            >
              <header css={STYLES.asideHeader} className={clsx(!filtersVisible && 'filters-invisible', isDesktop && 'is-desktop')}>
                <div className="flex items-center flex-nowrap">
                  <Button
                    size={200}
                    hideLabel={filtersVisible}
                    label={!filtersVisible && 'Search & Filters'}
                    glyph="filter"
                    className="mr-1.5 !whitespace-nowrap !shrink-0"
                    color="blue"
                    type={filtersVisible ? 'primary' : 'default'}
                    onClick={() => setFiltersVisible((c) => !c)}
                  />

                  <ButtonGroup stretchSelf className={!filtersVisible && '!hidden !pointer-events-none'}>
                    <Button
                      size={200}
                      label="Search By Business Names"
                      glyph="search"
                      color="blue"
                      type={tab === 'search' ? 'primary' : 'default'}
                      onClick={() => setTab('search')}
                    />
                    <Button
                      size={200}
                      label="Explore Around"
                      glyph="map"
                      color="blue"
                      type={tab === 'explore' ? 'primary' : 'default'}
                      onClick={() => setTab('explore')}
                    />
                  </ButtonGroup>
                </div>

                {/* <div ref={searchMenuRef} className={tab === 'search' ? 'block mt-3' : 'hidden'}>
                <Search
                  showLoader
                  autoFocus={tab === 'search'}
                  placeholder="Search…"
                  debounce={150}
                  value={query}
                  onChange={setQuery}
                  glyphColor="gray"
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                  isLoading={isSearchLoading || isSearchRefetching}
                />
              </div> */}
              </header>

              <div className={clsx(filtersVisible ? 'block' : 'opacity-0 !pointer-events-none')}>
                <main className={tab === 'search' ? 'block px-4 pb-4 pt-2 overflow-auto min-h-[83px]' : 'hidden'}>
                  <Form getForm={form} onSubmit={(e) => e.preventDefault()}>
                    <Grid gap="0.75rem">
                      <Grid gap="0.5rem" nowrap>
                        <div className="flex-1">
                          <div css={STYLES.label}>Search Businesses by Name or Address</div>
                          <Search
                            showLoader
                            autoFocus={tab === 'search'}
                            placeholder="Search…"
                            debounce={150}
                            value={query}
                            onChange={setQuery}
                            glyphColor="gray"
                            onFocus={() => setIsSearchFocused(true)}
                            onBlur={() => setIsSearchFocused(false)}
                            isLoading={isSearchLoading || isSearchRefetching}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Form>
                </main>

                <main className={tab === 'explore' ? 'block px-4 pb-4 pt-2 overflow-auto' : 'hidden'}>
                  <Form getForm={form} onSubmit={(e) => e.preventDefault()}>
                    <FormSection gap="0.75rem" maxWidth="100%">
                      <Flex gap="0.5rem">
                        <div className="flex-1">
                          <div css={STYLES.label}>Explore Around an Address</div>
                          <AddressComboBox
                            css={STYLES.input}
                            value={search}
                            onSearchUpdate={({ value }) => setSearch(value)}
                            onSelect={handleAddressSelect}
                            model="address"
                          />
                        </div>

                        <div>
                          <div css={STYLES.label}>Radius</div>
                          <Select
                            css={STYLES.selectInput}
                            withHover={false}
                            value={radius}
                            onUpdate={(o: any) => {
                              setRadius(parseInt(o.value))
                            }}
                          >
                            <Option label="5 miles" value={5} />
                            <Option label="10 miles" value={10} />
                            <Option label="25 miles" value={25} />
                            <Option label="50 miles" value={50} />
                            <Option label="100 miles" value={100} />
                            <Option label="150 miles" value={150} />
                            <Option label="200 miles" value={200} />

                            {isBehave && (
                              <>
                                <Option label="500 miles" value={500} />
                                <Option label="1000 miles" value={1000} />
                                <Option label="2000 miles" value={2000} />
                              </>
                            )}
                          </Select>
                        </div>
                      </Flex>
                    </FormSection>
                  </Form>
                </main>

                <main className={tab === 'search' ? 'block  overflow-auto' : 'hidden'}>
                  {(isDesktop || canSearch) && (
                    <SearchResults
                      data={searchResults}
                      isDesktop={isDesktop}
                      isLoading={isSearchLoading}
                      isRefetching={isSearchRefetching}
                      isEmpty={isSearchEmpty}
                      canSearch={canSearch}
                      query={query}
                      isOpen={isSearchOpen}
                      onOpenUpdated={setIsSearchOpen}
                      menuRef={searchMenuRef}
                      onSelect={handleSearchSelect}
                      useV6Router={useV6Router}
                      hideSuggestAction={hideSuggestAction}
                    />
                  )}
                </main>
              </div>
            </Card>

            <Card
              className={clsx(
                'px-3 py-2',
                isDesktop ? 'mt-3 !rounded-[5px]' : '!rounded-none',
                filtersVisible ? 'block' : 'opacity-0 !pointer-events-none',
              )}
            >
              <MapFilters data={data} locationCategories={locationCategories} updateCategoriesFilter={updateCategoriesFilter} />
            </Card>
          </div>

          {isMounted && (
            <div className="mq768:pl-[310px]">
              {tab === 'explore' && (
                <div className="absolute z-10 flex py-3 justify-center top-0 left-0 right-0">
                  <Button
                    rounded
                    type="primary"
                    glyph="map"
                    color="blue"
                    label="Explore around this area"
                    size={300}
                    display="inline-flex"
                    className="!font-[500]"
                    onClick={handleSearchArea}
                  />
                </div>
              )}

              <div>
                <CommunityMap
                  getRef={setMapRef}
                  data={data}
                  radius={radius}
                  circleCenter={tab === 'explore' ? circleCenter : null}
                  locationCategories={locationCategories}
                  viewport={viewport}
                  setViewport={setViewport}
                  css={STYLES.map}
                  handleSearchArea={handleSearchArea}
                  onFeatureSelect={(feature: any) => {
                    setSelectedFeature(feature)

                    if (onFeatureSelect) onFeatureSelect(feature)
                  }}
                  onPropertySelect={(property: any) => {
                    setSearchType('property')
                    setProperty(property)
                  }}
                />
              </div>
            </div>
          )}
        </main>
      </div>
    </CommunityContext.Provider>
  )
}

const MapFilters = (props: any) => {
  const { data, locationCategories, updateCategoriesFilter } = props

  return (
    <div>
      <div css={STYLES.filterTitle}>Show</div>

      <div css={STYLES.filterList}>
        {Object.keys(LOCATION_CATEGORIES).map((category: any) => {
          const item = LOCATION_CATEGORIES[category]

          const itemStyle = {
            '--filter-swatch-border': item.border,
            '--filter-swatch-background': item.background,
          }

          return (
            <div css={STYLES.filterItem} style={itemStyle} className={locationCategories[item.model] ? '' : 'is-disabled'}>
              <Checkbox
                label={
                  <>
                    <span css={STYLES.filterLabel}>{item.label}</span>
                    {data?.[category] && <span css={STYLES.filterCount}> ({size(data[category]?.features)})</span>}
                  </>
                }
                icon={item.icon}
                variant="skinny"
                value={locationCategories[item.model]}
                css={STYLES.filterCheckbox}
                onUpdate={({ value }: any) => {
                  updateCategoriesFilter(item.model, value)
                }}
              />

              {data?.[category] && <div css={STYLES.filterSwatch} />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const SearchResults = (props: any) => {
  const { hideSuggestAction, useV6Router } = props

  const match = useV6Router ? null : useRouteMatch()
  const location = useV6Router ? useLocationV6() : useLocation()
  const { data, isDesktop, isLoading, isEmpty, canSearch, query, isOpen, onOpenUpdated, menuRef, onSelect } = props

  const locationMatch: any = useV6Router ? null : useRouteMatch({ path: `${match?.url}/:slug` })
  const searchParams = parse(location.search)

  const params = useV6Router ? useParamsV6() : locationMatch
  const activeSlug = useV6Router ? params?.id : locationMatch?.slug

  const content = !canSearch ? null : isLoading ? (
    <State key="loading-data-state" isLoading minHeight={160} />
  ) : isEmpty ? (
    <State
      key="empty-data-state"
      isEmpty
      glyph="search"
      emptyDescription={`No search results found for "${query}"`}
      minHeight={160}
      emptyActions={
        !hideSuggestAction && (
          <Button
            as={useV6Router ? LinkV6 : undefined}
            label="Suggest Community Member"
            icon="community"
            type="minimal"
            state={useV6Router ? { data: { name: query } } : undefined}
            link={
              useV6Router
                ? 'suggest'
                : {
                    pathname: `${match?.url}/suggest`,
                    parent: match,
                    data: { name: query },
                  }
            }
          />
        )
      }
    />
  ) : (
    <GridTable
      templateColumns="minmax(180px, 1fr) minmax(180px, 1fr) 120px repeat(2, 120px) 70px"
      className="text-[0.9rem] border-t border-0 border-solid border-divider"
      useBanding={false}
      // useRowHover={false}
    >
      <GridTable.Header>
        <GridTable.Cell>Location Address</GridTable.Cell>
        <GridTable.Cell>Organization</GridTable.Cell>
        <GridTable.Cell>Website</GridTable.Cell>
        <GridTable.Cell>City</GridTable.Cell>
        <GridTable.Cell>County</GridTable.Cell>
        <GridTable.Cell>State</GridTable.Cell>
      </GridTable.Header>

      {data?.results?.map?.((result: any, index: number) => {
        const location = result.document
        const lat = location.latlon?.[0]
        const lon = location.latlon?.[1]

        const isActive = () =>
          activeSlug === location.entity_slug && lat === parseFloat(searchParams?.lat) && lon === parseFloat(searchParams?.lon)

        return (
          <SearchResultContext.Provider key={location.external_id} value={{ document: result.document, highlights: result.highlights }}>
            <GridTable.Row>
              <GridTable.Cell className="relative" css={STYLES.mainCell}>
                {useV6Router ? (
                  <NavLinkV6
                    className={isActive() ? 'is-active' : ''}
                    to={`${location.entity_slug || location.entity_id}?lat=${lat}&lon=${lon}`}
                    css={STYLES.searchNavLink}
                    onClick={() => {
                      const feature = entityToFeature(location)
                      if (!feature) return

                      onSelect?.(feature)
                    }}
                  >
                    <SearchHighlight fieldName="name" className="truncate" />
                  </NavLinkV6>
                ) : (
                  <NavLink
                    isActive={isActive}
                    to={`${match?.url}/${location.entity_slug || location.entity_id}?lat=${lat}&lon=${lon}`}
                    css={STYLES.searchNavLink}
                    onClick={() => {
                      const feature = entityToFeature(location)
                      if (!feature) return

                      onSelect?.(feature)
                    }}
                  >
                    {/* <Avatar initials={entity?.name} size={20} src="" className="mr-1.5" /> */}
                    <SearchHighlight fieldName="name" className="truncate" />
                  </NavLink>
                )}
              </GridTable.Cell>

              <GridTable.Cell className="relative" css={STYLES.mainCell}>
                <div className="flex flex-nowrap items-center truncate">
                  <Avatar initials={location?.entity_name} size={20} src="" className="mr-1.5" />
                  <SearchHighlight fieldName="entity_name" className="truncate" />
                </div>
              </GridTable.Cell>

              <TruncatedGridCell className="truncate">
                {location.entity_domain && (
                  <a
                    href={formatURL(location.entity_domain)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-blue-500"
                  >
                    <SearchHighlight fieldName="entity_domain" className="truncate" />
                    <div className="ml-auto pl-1">
                      <Glyph glyph="external_link" size={12} color={COLORS.blue} />
                    </div>
                  </a>
                )}
              </TruncatedGridCell>

              {/* <TruncatedGridCell className="truncate">
                {entity.address_line_1 && <SearchHighlight fieldName="address_line_1" />}
              </TruncatedGridCell> */}

              <TruncatedGridCell className="truncate">{location.city && <SearchHighlight fieldName="city" />}</TruncatedGridCell>

              <TruncatedGridCell className="truncate">{location.county && <SearchHighlight fieldName="county" />}</TruncatedGridCell>

              <TruncatedGridCell className="truncate">{location.state && <SearchHighlight fieldName="state" />}</TruncatedGridCell>
            </GridTable.Row>
          </SearchResultContext.Provider>
        )
      })}
    </GridTable>
  )

  if (isDesktop) return content

  return (
    <PopoverMenu
      defaultOpen
      align="start"
      side="bottom"
      useTriggerWidth
      minHeight="220px"
      isOpen={isOpen}
      sideOffset={-10}
      onOpenUpdated={onOpenUpdated}
      onOpenAutoFocus={(event) => {
        event.preventDefault()
      }}
      onInteractOutside={(event) => {
        if (menuRef?.current?.contains(event.target)) {
          event.preventDefault()
        }
      }}
    >
      <div>{content}</div>
    </PopoverMenu>
  )
}

const TruncatedGridCell = ({ children }: any) => {
  return (
    <GridTable.Cell className="truncate" css={STYLES.truncatedGridCell}>
      {children || <span className="text-text-muted opacity-70">–</span>}
    </GridTable.Cell>
  )
}

const STYLES = {
  root: {
    '--sidebar-width': '500px',
    '--sidebar-width-large': '800px',

    display: 'grid',
    overflow: 'hidden',
    gridTemplateRows: '100%',
    position: 'relative',
  },

  loader: {
    pointerEvents: 'none',
    marginLeft: '0.75rem',
    opacity: 0,

    '&.is-visible': {
      opacity: 1,
    },
  },

  main: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: 'auto 70vh',
    gridTemplateColumns: '100%',

    '@media (min-width: 600px)': {
      gridTemplateRows: 'auto 1fr',
    },

    '&.is-desktop': {
      gridTemplateRows: '100%',
      gridTemplateColumns: '100%',
      overflow: 'hidden',
    },
  },

  aside: {
    // background: COLORS.white,
    // borderRight: `1px solid ${COLORS.divider}`,
    // boxShadow: `
    //   2px 0 6px ${COLORS.shadow},
    //   4px 0 12px ${COLORS.shadow},
    //   8px 0 24px ${COLORS.shadow}
    // `,
    zIndex: 20,
    transition: 'all 120ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    overflow: 'hidden !important',

    opacity: 1,
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    pointerEvents: 'all',
    position: 'relative',

    '&.is-desktop': {
      position: 'absolute',
      top: '0.75rem',
      left: '0.75rem',
      maxHeight: 'calc(100% - 1.5rem)',
      width: 'var(--sidebar-width)',
      // transition: 'width 120ms cubic-bezier(0.39, 0.575, 0.565, 1)',

      '&.is-large': {
        width: 'var(--sidebar-width-large)',
      },
    },

    '&.filters-invisible': {
      boxShadow: 'none',
      background: 'none',
    },
  },

  mainCard: {
    background: COLORS.white,
    boxShadow: `
      2px 0 6px ${COLORS.shadow},
      4px 0 12px ${COLORS.shadow},
      8px 0 24px ${COLORS.shadow}
    `,
    zIndex: 20,
    transition: 'all 120ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    overflow: 'hidden !important',

    opacity: 1,
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    pointerEvents: 'all',
    position: 'relative',
    borderRadius: 0,

    '&.is-desktop': {
      borderRadius: 5,
    },

    '&.filters-invisible': {
      boxShadow: 'none',
      background: 'none',
    },
  },

  asideHeader: {
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(3),
    padding: '0.5rem 0.75rem',

    '&.filters-invisible': {
      boxShadow: 'none',
      border: 'none',

      '&.is-desktop': {
        padding: 0,
      },
    },
  },

  searchNavLink: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 600,

    '&.is-active::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: tint(0.88, COLORS.blue),
      zIndex: -1,
    },
  },

  map: {
    background: COLORS.white,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 0,
  },

  label: {
    fontWeight: 600,
    fontSize: '0.92rem',
    marginBottom: '0.25rem',
  },

  input: {
    height: '34px !important',
    minHeight: 'auto !important',
    fontSize: '0.9rem',
  },

  selectInput: {
    select: {
      height: '34px !important',
      minHeight: 'auto !important',
      fontSize: '0.9rem',
    },
  },

  // Filters
  filterTitle: {
    fontWeight: 600,
    fontSize: '0.92rem',
    marginBottom: '0.25rem',
  },

  filterList: {
    display: 'grid',
    gridGap: '0.3rem',
  },

  filterItem: {
    position: 'relative',
    userSelect: 'none',

    '&.is-disabled': {
      '--filter-swatch-border': `${COLORS.textMuted} !important`,
      '--filter-swatch-background': `${COLORS.textMuted} !important`,
      '--filter-swatch-opacity': 0.3,

      img: {
        filter: 'grayscale(0.8)',
        opacity: 0.6,
      },
    },
  },

  filterCheckbox: {
    fontSize: '0.94rem',
    label: {
      fontWeight: 400,
    },

    '.check-element': {
      width: 18,
      height: 18,
      marginRight: '0.5rem',
    },
  },

  filterLabel: {
    fontWeight: 500,
  },

  filterCount: {
    color: COLORS.textMuted,
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    fontSize: '0.9rem',
    opacity: 0.8,
  },

  filterSwatch: {
    position: 'absolute',
    right: 2,
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    width: 12,
    height: 12,
    borderRadius: '50%',
    background: 'var(--filter-swatch-background)',
    border: '1px solid var(--filter-swatch-border)',
    opacity: 'var(--filter-swatch-opacity)',
  },

  // Search table
  mainCell: {
    '&:hover': {
      background: `${tint(0.88, COLORS.blue)} !important`,
      zIndex: 10,
      width: 'fit-content',
      overflow: 'visible !important',
      minWidth: '100%',
      paddingRight: '0.5rem',
      cursor: 'auto',
    },
  },

  truncatedGridCell: {
    '&:hover': {
      background: `${COLORS.white} !important`,
      zIndex: 10,
      width: 'fit-content',
      overflow: 'visible !important',
      minWidth: '100%',
      paddingRight: '0.5rem',
      cursor: 'auto',
    },
  },
}
