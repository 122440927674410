import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'
import { useSettings } from '../../../../hooks/useSettings'
import { usePermissions } from '../../../../hooks/usePermissions'

import Tabs from '../../../Tabs'

import { FILTERS } from '../../../../constructs/Filters/config'

export const OrganizationsWithRelationshipsTable = ({ onSelect, dependentValue, ...rest }: any) => {
  const { isBehave } = useSettings()
  const hasAuthorityFeatureFlag = usePermissions({ featureFlag: 'authority_organizations' })

  const [relationship, setRelationship] = React.useState('internal')

  const tableProps = useDataTable({
    name: ['selector-authority-organizations'],
    endpoint: '/organizations',
    params: { relationship },
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_organizations_all_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={onSelect ? () => onSelect(data) : undefined}
          />
        ),
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        tagSmartCategories: 'organizations',
      },
      {
        title: 'Type',
        model: 'category',
        type: 'title',
        width: 140,
      },
      {
        title: 'Category',
        id: 'category_type',
        model: 'subcategory.name',
        type: 'title',
        width: 140,
      },
      {
        title: 'Phone',
        model: 'phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'website',
        type: 'external_url',
        disableSelect: true,
      },
      {
        title: 'Address',
        model: 'address',
        type: 'address',
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <>
      <Tabs activeTab={relationship} onChange={setRelationship}>
        <Tabs.List className="px-4">
          <Tabs.Item label="Internal" name={`internal`} />
          {(isBehave || hasAuthorityFeatureFlag) && <Tabs.Item label="Authorities" name={`authority`} />}
          <Tabs.Item label="Providers" name={`provider`} />
          <Tabs.Item label="Vendors" name={`vendor`} />
        </Tabs.List>
      </Tabs>

      <DataTable
        {...tableProps}
        {...rest}
        icon="organizations"
        title="Organizations"
        columns={columns}
        filtersConfig={FILTERS.organizations}
        searchBarFilterKey="name"
      />
    </>
  )
}
