import React from 'react'
import size from 'lodash/size'

import { DataTable } from '../../../components/DataTable/DataTable'
import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../../components/DataTable/useDataTable'
import { useGet } from '../../../hooks/useNewAPI'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Dropdown from '../../../components/Dropdown'
import DropdownItem from '../../../components/DropdownItem'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { NewOrganizationLeadFormSubmissionOverlay } from '../../OrganizationLeads/NewOrganizationLeadFormSubmissionOverlay'
import { OrganizationLeadFormSubmissionOverlay } from '../../OrganizationLeads/OrganizationLeadFormSubmissionOverlay'

export const LeadContactFormsStep = ({ organization, setStatus }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const [formId, setFormId] = React.useState(null)
  const [newFormId, setNewFormId] = React.useState(null)

  const leadFormsQuery = useGet({
    name: ['organization_lead_forms'],
    url: `/organization_lead_forms`,
  })

  const tableProps = useDataTable({
    name: ['organizations', organization.id, 'organization_lead_form_submissions'],
    endpoint: `/organizations/${organization.id}/organization_lead_form_submissions`,
    updateDeleteEndpoint: `/organization_lead_form_submissions`,
    localStorageKey: 'workflow_lead_form_submissions_v1',
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  const columns = React.useMemo(
    () => [
      {
        title: 'Lead Contact Form',
        model: 'organization_lead_form.name',
        width: 300,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => (
          <MainCell testKey="main_cell" id={data.id} value={value} onClick={() => setFormId(data.id)} />
        ),
      },
      {
        title: 'Status',
        model: 'archived_at',
        formatValue: ({ value }) => {
          if (value) return <Status small color="gray" label="Archived" />

          return <Status small color="green" label="Active" />
        },
      },
      {
        title: 'Phone',
        model: 'data.organization.phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'data.organization.email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'data.organization.website',
        type: 'external_url',
        disableSelect: true,
      },
      {
        title: 'Notes',
        model: 'data.organization.additional_info',
      },
      {
        title: 'Address',
        model: 'data.organization.address',
        type: 'address',
        disableSort: true,
        width: 200,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <DataTable {...tableProps} icon="organization_lead_forms" title="Lead Contact Forms" columns={columns} />

          <CardContent className="flex">
            <Dropdown
              label="Add New Organization Lead"
              glyph="add"
              buttonType="primary"
              permission="organization_lead_forms.create"
              buttonSize={200}
            >
              {leadFormsQuery.isLoading || size(leadFormsQuery.data) === 0 ? (
                <State
                  isLoading={leadFormsQuery.isLoading}
                  isEmpty={size(leadFormsQuery.data)}
                  minHeight={100}
                  title="Organization Lead Contact Forms"
                  icon="organization_lead_forms"
                  emptyDescription="No Organization Lead Contact Forms created yet"
                  emptyActions={
                    <Button
                      label="Manage Lead Contact Forms"
                      glyph="settings"
                      type="minimal"
                      link="/settings/organization-lead-contact-forms"
                      permission="settings.organization_lead_forms.create"
                      size={100}
                      target="_blank"
                    />
                  }
                />
              ) : (
                <>
                  {leadFormsQuery.data?.map?.((form: any) => (
                    <DropdownItem
                      key={form.id}
                      onClick={() => {
                        setNewFormId(form.id)
                      }}
                      icon="organization_lead_forms"
                      permission="organization_lead_forms.create"
                      label={form.name}
                    />
                  ))}
                </>
              )}
            </Dropdown>
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        key={`id-${newFormId}`}
        isOpen={!!newFormId}
        onClose={() => {
          setNewFormId(null)
        }}
        overlay={<NewOrganizationLeadFormSubmissionOverlay organization={organization} formId={newFormId} />}
      />

      <SummonOverlay
        key={`id-${formId}`}
        isOpen={!!formId}
        onClose={() => {
          setFormId(null)
        }}
        overlay={<OrganizationLeadFormSubmissionOverlay organization={organization} dataID={formId} />}
      />
    </>
  )
}
