import React from 'react'
import { Global } from '@emotion/react'
import { useParams } from 'react-router-dom'
import size from 'lodash/size'

import { request, buildBaseUrl } from '@behavehealth/modules/axios'
import { COLORS } from '@behavehealth/theme'

import { Button, PageLoader, Markup, Glyph, NavItem } from '@behavehealth/components'
import Card from '@behavehealth/components/Card'
import Notifications from '@behavehealth/modules/notifications'

import { checkShowGroup, OrganizationLeadForm } from '@behavehealth/constructs/OrganizationLeads/OrganizationLeadForm'
import { data as APPLICATION_FORM_DATA } from '@behavehealth/constructs/OrganizationLeads/application_form_data'
import { NotificationsContainer, PortalsContainer } from '@behavehealth/components'
import { PoweredByBehave } from '@behavehealth/components/PoweredByBehave'

const logoUrl = (tenant_id) => {
  return `${process.env.BH_API_BASE_PROTOCOL}://${process.env.BH_API_BASE_URL}/v1/logo/${tenant_id}`
}

export const OrganizationLeadForms: React.FC = () => {
  const { tenant_id, slug }: any = useParams()

  const [leadForm, setLeadForm]: any = React.useState(null)

  const [data, setData]: any = React.useState(null)
  const [valid, setValid]: any = React.useState(false)
  const [loading, setLoading]: any = React.useState(true)
  const [settings, setSettings]: any = React.useState({})
  const [updating, setUpdating]: any = React.useState(false)
  const [submitted, setSubmitted]: any = React.useState(false)
  const [hasLogo, setHasLogo]: any = React.useState(true)
  const [toggleAccordions, setToggleAccordions]: any = React.useState(false)

  const form = React.useRef()

  React.useEffect(() => {
    const dataFetch = async () => {
      await buildBaseUrl()

      try {
        await request.get(logoUrl(tenant_id))
      } catch (error) {
        setHasLogo(false)
      }

      try {
        const settings = await request.get(`/apps/account/tenant/${tenant_id}`)
        setSettings(settings.data)
      } catch (err) {
        console.error(err)
      }
    }

    dataFetch()
  }, [])

  React.useEffect(() => {
    if (!settings?.subdomain) return

    const dataFetch = async () => {
      await buildBaseUrl(settings.subdomain)

      try {
        const res = await request.get(`/organization_lead_forms/by_slug?slug=${slug}`)

        setLeadForm(res.data.data)
      } catch (err) {
        console.error(err)
      }

      setLoading(false)
    }

    dataFetch()
  }, [settings?.subdomain])

  const submitForm = async () => {
    setUpdating(true)

    // add subdomain
    buildBaseUrl()

    try {
      await request.post(
        `/organization_lead_forms/${leadForm?.id}/submit`,
        { data },
        {
          headers: {
            'X-Tenant': settings.subdomain,
          },
        },
      )

      Notifications.send('Your data has been submitted successfully', 'positive')
      setSubmitted(true)
    } catch (errors) {
      // setErrors(errors)
      console.error(errors)
      Notifications.send('Submit Failed', 'negative')
    }

    setUpdating(false)
  }

  const highlightRequired = () => {
    form.current?.highlightInvalid()
    setToggleAccordions((o) => !o)
  }

  if (loading) return <PageLoader />

  return (
    <>
      <Global styles={globalStyles} />

      {submitted && (
        <div className="p-5 min-w-[100vw] min-h-[100vh] flex items-center justify-center">
          <Card className="w-full max-w-[460px] text-center px-4 py-8">
            <Glyph glyph="tick_circle" size={24} className="mx-auto" />
            <h2 className="mt-2 mb-2">Form Submitted</h2>
            {leadForm?.thank_you_message && <Markup value={leadForm?.thank_you_message} />}
          </Card>
        </div>
      )}

      {!submitted && (
        <div className="mq900:pt-4 mq900:px-6 pt-4 px-5 pb-4 flex justify-center mq900:fixed mq900:inset-0">
          <Card className="mq900:h-[calc(100vh-2rem)] mq900:overflow-hidden mq900:grid mq900:grid-rows-[100%] mq900:grid-cols-[260px_1fr] z-[0] relative w-full max-w-[1024px]">
            <nav className="border-solid overflow-y-auto border-b mq900:border-b-0 mq900:border-r border-0 border-solid border-divider px-4 py-5 mq900:px-5 mq900:py-6 flex flex-col">
              {hasLogo && (
                <img
                  src={logoUrl(tenant_id)}
                  alt="logo"
                  className="block w-full h-auto max-w-[160px] max-h-[80px] mx-auto object-contain"
                />
              )}

              <div className="flex-[1_1_auto] hidden mq900:block">
                {APPLICATION_FORM_DATA.map((group) => {
                  const groupSchema = leadForm?.schema?.[`${group.model}`]
                  const shouldShow = checkShowGroup(groupSchema)

                  if (group.model === 'custom' && size(groupSchema?.questions) === 0) return null

                  if (!shouldShow) return null

                  return (
                    <NavItem
                      key={group.model}
                      label={group.title}
                      glyph="document"
                      color={COLORS.vividBlue}
                      onClick={() => {
                        const el = document.getElementById(group.model)

                        if (!el) return

                        el.scrollIntoView({ behavior: 'smooth', offset: 50 })
                      }}
                    />
                  )
                })}
              </div>

              <div className="hidden mq900:block">
                <PoweredByBehave
                  showMiniLogo={false}
                  href={'https://behavehealth.com?utm_source=organization_lead_form'}
                  className="!p-0"
                />
              </div>
            </nav>

            <div className="mq900:overflow-y-auto">
              <div className="px-6 py-8">
                {leadForm?.welcome_message && <Markup value={leadForm?.welcome_message} className="mb-4" />}

                <OrganizationLeadForm
                  form={form}
                  onUpdate={setData}
                  timezone={settings.timezone}
                  schema={leadForm?.schema}
                  companyName={settings.company}
                  onValidationUpdate={setValid}
                  forceOpenAccordion={toggleAccordions}
                />
              </div>

              <footer className="grid gap-4 mt-4 sticky bottom-0 !z-[10] bg-white px-6 pb-4">
                <Button
                  fullWidth
                  color="green"
                  type="primary"
                  label="Submit Form"
                  isLoading={updating}
                  isDisabled={!valid}
                  onClick={submitForm}
                  className="application-form-button"
                />

                {/* {!valid && (
                  <Button
                    fullWidth
                    color="orange"
                    type="default"
                    glyph="view"
                    label="Highlight Required Fields"
                    isLoading={updating}
                    className="application-form-button"
                    onClick={highlightRequired}
                  />
                )} */}

                <div className="mq900:hidden">
                  <PoweredByBehave
                    showMiniLogo={false}
                    href={'https://behavehealth.com?utm_source=organization_lead_form'}
                    className="!p-0"
                  />
                </div>
              </footer>
            </div>
          </Card>
        </div>
      )}

      <NotificationsContainer />
      <PortalsContainer />
    </>
  )
}

const globalStyles = {
  '#app': {
    minHeight: '100vh',
  },

  'html, body': {
    height: 'auto !important',
    overflow: 'auto !important',
  },

  '@media(min-width: 900px)': {
    'html, body': {
      height: 'hidden !important',
      overflow: 'hidden !important',
    },
  },

  '@media print': {
    'html, body': {
      height: 'auto',
      overflow: 'auto',
    },

    '.application-form-root': {
      height: 'auto',
      overflow: 'visible',
    },

    '.application-form-button': {
      display: 'none',
    },

    '.accordion': {
      border: `1px solid ${COLORS.divider}`,
    },

    '*': {
      boxShadow: 'none !important',
    },
  },
}
