import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'

import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Textarea from '../../components/Forms/Textarea'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import QuickView from '../../components/QuickView'
import Section from '../../components/Section'

import { withOverlayError } from '../../hocs/withOverlayError'
import SingleAttachment from '../../components/Forms/SingleAttachment'

import { useCommunityPermissions } from '../Community/useCommunityPermissions'

const RootOrganizationFileOverlay = (props: any) => {
  const { timezone } = useSettings()

  const { canEditPartnerSharedFiles, canDeletePartnerSharedFiles } = useCommunityPermissions()

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'documents',
    endpoint: '/documents',
    invalidate: 'documents',
    options: props,
    disableParentRequest: true,
  })

  const { organizationId } = props

  const [title, setTitle] = React.useState(null)
  const [blob, setBlob] = React.useState(null)

  const handleSave = () => {
    const formData = form.current?.getFormValue()

    saveWithData({
      ...formData,
      name: formData?.name || title,
    })
  }

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="files" title="Organization File" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              organization_id: organizationId,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <Input model="name" label="Name" />
              <Textarea useQuickText label="Notes" model="notes" />

              {!isNew && (
                <>
                  <ObjectSelector
                    isEditable={false}
                    model="author"
                    label="Uploaded By"
                    type="employees"
                    icon="employees"
                    selectTitle={(data) => data.name}
                    selectDescription={(data) => data.position}
                  />
                  <DateTimeInput isEditable={false} model="created_at" label="Date Uploaded" />
                  <DateTimeInput isEditable={false} model="updated_at" label="Last Edited" />
                  <Input isEditable={false} model="content_type" label="Type" />
                </>
              )}
            </FormSection>
          </Section>

          {/* {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
              </Section>
            </>
          )} */}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <Flex stretchChildrenX gap={8}>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={handleSave}
              isDisabled={isInvalid}
              flex="3 1 auto"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} flex="0 1 auto" />}
          </Flex>
        )}

        {!isEditable && (
          <Grid gap={8}>
            <Flex stretchChildrenX gap={8}>
              <QuickView record={data}>
                <Button glyph="external_link" label="View File" type="primary" flex="2 1 auto" />
              </QuickView>

              <Button onClick={() => window.open(data.download)} glyph="download" label="Download" type="primary" color="green" />
            </Flex>

            <Flex stretchChildrenX gap={8}>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="2 1 auto"
                permission={canEditPartnerSharedFiles}
              />

              {canDeletePartnerSharedFiles && (
                <DeleteDialog
                  title="Delete File?"
                  message="Are you sure you want to delete this file? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
                </DeleteDialog>
              )}
            </Flex>
          </Grid>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const OrganizationFileOverlay = withOverlayError(RootOrganizationFileOverlay)
