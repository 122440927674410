import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { DataTable } from '../../components/DataTable/DataTable'

import { MainCell } from '../../components/DataTable/cells/MainCell'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'

export const HubspotContactsDataTable = (props: any) => {
  const match = useRouteMatch()

  const columns = React.useMemo(
    () => [
      {
        title: 'First Name',
        model: 'firstname',
        width: 300,
        formatValue: ({ data, value }) => (
          <MainCell
            id={data.id}
            value={value}
            avatar={data.avatar || ''}
            to={props.to ? props.to(data) : undefined}
            onClick={props.onSelect ? () => props.onSelect(data) : props.onClick ? () => props.onClick(data) : undefined}
          />
        ),
      },
      {
        title: 'Last Name',
        model: 'lastname',
      },
      {
        title: 'Hubspot Contact ID',
        model: 'id',
        type: 'hubspot_contact',
      },
      {
        title: 'Business Status',
        model: 'business_status',
      },
      {
        title: 'Company',
        model: 'company',
      },
      {
        title: 'Job Function',
        model: 'job_function',
      },
      {
        title: 'Job Title',
        model: 'jobtitle',
      },
      {
        title: 'Position',
        model: 'position',
      },
      {
        title: 'Email',
        model: 'email',
        type: 'email',
      },
      {
        title: 'Work Email',
        model: 'work_email',
        type: 'email',
      },
      {
        title: 'Phone',
        model: 'phone',
        type: 'phone',
      },
      {
        title: 'Mobile Phone',
        model: 'mobilephone',
        type: 'phone',
      },
      {
        title: 'Website',
        model: 'website',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={value} />
        },
      },
      {
        title: 'LinkedIn',
        model: 'linkedin',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'LinkedIn'} />
        },
      },
      {
        title: 'Twitter / X',
        model: 'twitterhandle',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={`https://twitter.com/${value}`} value={value} />
        },
      },
      {
        title: 'Bridge Profile URL',
        model: 'bridge_profile_url',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'Bridge'} />
        },
      },
      {
        title: 'Community Profile',
        model: 'community_public_profile_url',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'Community Profile'} />
        },
      },
      {
        title: 'Community Profile 1',
        model: 'community_profile_public_url_1',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'Community Profile 1'} />
        },
      },
      {
        title: 'Community Profile 2',
        model: 'community_profile_public_url_2',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'Community Profile 2'} />
        },
      },
      {
        title: 'Community Profile 3',
        model: 'community_profile_public_url_3',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'Community Profile 3'} />
        },
      },
      {
        title: 'Community Profile 4',
        model: 'community_profile_public_url_4',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'Community Profile 4'} />
        },
      },
      {
        title: 'Community Profile 5',
        model: 'community_profile_public_url_5',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal href={value} value={'Community Profile 5'} />
        },
      },
      {
        title: 'Address',
        model: 'address',
        width: 200,
      },
      {
        title: 'City',
        model: 'city',
        width: 130,
      },
      {
        title: 'State',
        model: 'state',
        width: 70,
      },
      {
        title: 'Zip',
        model: 'zip',
        width: 70,
      },
      {
        title: 'Timezone',
        model: 'timezone',
        width: 160,
      },
      {
        title: 'Category',
        model: 'category',
      },
      {
        title: 'Facility Type',
        model: 'facility_type',
      },
      {
        title: 'Free',
        model: 'free',
      },
      {
        title: 'Gender',
        model: 'gender',
      },
      {
        title: 'How Did You Hear About Us',
        model: 'how_did_you_hear_about_us',
      },
      {
        title: 'Levels of Care Offered',
        model: 'levels_of_care_offered',
      },
      {
        title: 'Meetings Scheduled Count',
        model: 'meetings_scheduled_count',
      },
      {
        title: 'Message',
        model: 'message',
      },
      {
        title: 'Not Interested',
        model: 'not_interested',
      },
      {
        title: 'NPI 1',
        model: 'npi_1',
      },
      {
        title: 'Number of Employees',
        model: 'numemployees',
      },
      {
        title: 'Owner Email',
        model: 'owneremail',
      },
      {
        title: 'Owner Name',
        model: 'ownername',
      },
      {
        title: 'Provider Type',
        model: 'provider_type',
      },
      {
        title: 'Recent Deal Amount',
        model: 'recent_deal_amount',
      },
      {
        title: 'Recent Deal Close Date',
        model: 'recent_deal_close_date',
        type: 'date_time',
      },
      {
        title: 'Risky',
        model: 'risky',
      },
      {
        title: 'Subdomain',
        model: 'subdomain',
      },
      {
        title: 'Total Revenue',
        model: 'total_revenue',
      },
      {
        title: 'VIP',
        model: 'vip',
      },
      {
        title: 'Start Date',
        model: 'start_date',
        type: 'date_time',
      },
      {
        title: 'Trial End Date',
        model: 'trial_end_date',
        type: 'date_time',
      },
      {
        title: 'Trial Start Date',
        model: 'trial_start_date',
        type: 'date_time',
      },
      {
        title: 'Date Added',
        model: 'createdate',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: '_updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      asCard
      {...props}
      columns={columns}
      title="HubSpot Contacts"
      testKey="hubspot_contacts_data_table"
      icon="hubspot"
      filtersConfig={FILTERS_CONFIG}
    />
  )
}

const FILTERS_CONFIG = {
  firstname: {
    label: 'First Name',
    type: 'string',
  },
  lastname: {
    label: 'Last Name',
    type: 'string',
  },
  business_status: {
    label: 'Business Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  company: {
    label: 'Company',
    type: 'string',
  },
  id: {
    label: 'Hubspot Contact ID',
    type: 'string',
  },
  job_function: {
    label: 'Job Function',
    type: 'string',
  },
  jobtitle: {
    label: 'Job Title',
    type: 'string',
  },
  position: {
    label: 'Position',
    type: 'string',
  },
  email: {
    label: 'Email',
    type: 'string',
  },
  work_email: {
    label: 'Work Email',
    type: 'string',
  },
  phone: {
    label: 'Phone',
    type: 'string',
  },
  mobilephone: {
    label: 'Mobile Phone',
    type: 'string',
  },
  website: {
    label: 'Website',
    type: 'string',
  },
  linkedin: {
    label: 'LinkedIn',
    type: 'string',
  },
  twitterhandle: {
    label: 'Twitter / X',
    type: 'string',
  },
  bridge_profile_url: {
    label: 'Bridge Profile URL',
    type: 'string',
  },
  community_public_profile_url: {
    label: 'Community Profile',
    type: 'string',
  },
  community_profile_public_url_1: {
    label: 'Community Profile 1',
    type: 'string',
  },
  community_profile_public_url_2: {
    label: 'Community Profile 2',
    type: 'string',
  },
  community_profile_public_url_3: {
    label: 'Community Profile 3',
    type: 'string',
  },
  community_profile_public_url_4: {
    label: 'Community Profile 4',
    type: 'string',
  },
  community_profile_public_url_5: {
    label: 'Community Profile 5',
    type: 'string',
  },
  address: {
    label: 'Address',
    type: 'string',
  },
  city: {
    label: 'City',
    type: 'string',
  },
  state: {
    label: 'State',
    type: 'string',
  },
  zip: {
    label: 'Zip',
    type: 'string',
  },
  timezone: {
    label: 'Timezone',
    type: 'string',
  },
  category: {
    label: 'Category',
    type: 'string',
  },
  facility_type: {
    label: 'Facility Type',
    type: 'string',
  },
  free: {
    label: 'Free',
    type: 'string',
  },
  gender: {
    label: 'Gender',
    type: 'string',
  },
  how_did_you_hear_about_us: {
    label: 'How Did You Hear About Us',
    type: 'string',
  },
  levels_of_care_offered: {
    label: 'Levels of Care Offered',
    type: 'string',
  },
  meetings_scheduled_count: {
    label: 'Meetings Scheduled Count',
    type: 'string',
  },
  message: {
    label: 'Message',
    type: 'string',
  },
  not_interested: {
    label: 'Not Interested',
    type: 'string',
  },
  npi_1: {
    label: 'NPI 1',
    type: 'string',
  },
  numemployees: {
    label: 'Number of Employees',
    type: 'string',
  },
  owneremail: {
    label: 'Owner Email',
    type: 'string',
  },
  ownername: {
    label: 'Owner Name',
    type: 'string',
  },
  provider_type: {
    label: 'Provider Type',
    type: 'string',
  },
  recent_deal_amount: {
    label: 'Recent Deal Amount',
    type: 'string',
  },
  recent_deal_close_date: {
    label: 'Recent Deal Close Date',
    type: 'date_time',
  },
  risky: {
    label: 'Risky',
    type: 'string',
  },
  subdomain: {
    label: 'Subdomain',
    type: 'string',
  },
  total_revenue: {
    label: 'Total Revenue',
    type: 'string',
  },
  vip: {
    label: 'VIP',
    type: 'string',
  },
  start_date: {
    label: 'Start Date',
    type: 'date_time',
  },
  trial_end_date: {
    label: 'Trial End Date',
    type: 'date_time',
  },
  trial_start_date: {
    label: 'Trial Start Date',
    type: 'date_time',
  },
  createdate: {
    label: 'Date Added',
    type: 'date_time',
  },
  _updated_at: {
    label: 'Date Updated',
    type: 'date_time',
  },
}
