import React from 'react'
import compact from 'lodash/compact'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { address } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useUpdate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useQueryClient } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordions from '../../components/Accordions'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ColorSelector from '../../components/Forms/ColorSelector'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import EventCategorySelect from '../../components/Elements/EventCategorySelect'
import EventStatus from '../../components/Statuses/EventStatus'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Links from '../../components/Forms/Links'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import TimeInputSelector from '../../components/Forms/TimeInputSelector/TimeInputSelector'
import URLInput from '../../components/Forms/URLInput'

import { JoinMeetingButton } from '../../components/Buttons/JoinMeetingButton'

import EventOutcomes, { OUTCOMES } from '../../components/Overlays/pages/Calendar/EventOutcomes'

const EventOverlay = (props: any) => {
  const {
    isNew,
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialData,
    isDeleting,
    isEditable,
    isEmpty,
    isInvalid,
    isLoading,
    isSaving,
    onValidationUpdate,
    queryKey,
    save,
  } = useOverlay({
    name: 'events',
    endpoint: '/events',
    invalidate: 'events',
    disableParentRequest: true,
    invalidateKeys: compact(['event', props.invalidateKeys].flat()),
    onSaveSuccessful: props.onSaveSuccessful,
    options: props,
    headers: {
      'X-Request-Level': 'global',
    },
  })

  const { maxWidth = 40, position = 'right', showBackdrop = false } = props

  const queryClient = useQueryClient()

  const { timezone, isBehave, tenant } = useSettings()
  const [startedAt, setStartedAt]: any = React.useState()
  const [formData, setFormData] = React.useState(initialData)

  const { mutate: activateEvent, isLoading: isActivating }: any = useUpdate({
    name: queryKey,
    url: `/events/${id}/activate`,
    invalidate: 'events',
    invalidateKeys: ['event', id],
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const { mutate: cancelEvent, isLoading: isCancelling }: any = useUpdate({
    name: queryKey,
    url: `/events/${id}/cancel`,
    invalidate: 'events',
    invalidateKeys: ['event', id],
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const isHealthcare = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'
  const isOutcomesEnabled = isHealthcare && data?.category && OUTCOMES.hasOwnProperty(data?.category) // show outcomes only for categories that support it
  const isCategoryDisabled = isOutcomesEnabled && size(data?.event_outcomes) > 0 // event category should not change if documentation was already created

  if (isLoading || isEmpty) {
    return <OverlayLoader position={position} maxWidth={maxWidth} showBackdrop={showBackdrop} />
  }

  const isCompleted = data?.status === 'completed'
  const hasOrganizations = size(formData?.organization_ids) > 0

  return (
    <Overlay
      onClose={close}
      maxWidth={maxWidth}
      position={position}
      showBackdrop={showBackdrop || isEditable}
      transformOrigin={props.transformOrigin}
    >
      <Overlay.Header
        icon="calendar"
        title="Event"
        titleAside={<EventStatus status={data?.status} />}
        help={<HelpTagIframe id="event" />}
      />

      {data?.personal_meeting_room?.id && (
        <Permission featureFlagV2="telehealth" permission="telehealth.view">
          <Overlay.SubHeader>
            <JoinMeetingButton
              type="link"
              meetingID={data?.personal_meeting_room?.id}
              meetingName={`${data?.title} – Event Meeting Room`}
              label="Join Meeting Room"
              size={200}
            />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...initialData,
            ...data,
          }}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
          linked={
            isNew && {
              recurring_frequency: 'never',
              event_type: 'event',
            }
          }
          key={`updated-${data?.updated_at}`}
          onUpdate={setFormData}
        >
          <Section>
            <FormSection layout="vertical" maxWidth="100%">
              <Input
                label="Title"
                model="title"
                validations={{
                  presence: {
                    message: 'Please enter an event name',
                  },
                }}
              />

              <Flex gap="1rem">
                <div className="!flex-auto">
                  <EventCategorySelect
                    label="Event Category"
                    model="category"
                    defaultValue="general"
                    isDisabled={isCategoryDisabled}
                    tooltip={
                      isCategoryDisabled &&
                      'Category cannot be changed as documentation was already created for this event. To change category, please delete all documentation below.'
                    }
                  />
                </div>

                <ColorSelector label="Color" model="color" />
              </Flex>

              <DateInput
                label="Date"
                model="started_at"
                onUpdate={(target: any) => {
                  setStartedAt(target.value)
                }}
                validations={{
                  presence: {
                    message: 'Please select an event date',
                  },
                }}
              />

              <ContextShow when="is_all_day" is={false}>
                <FormSection horizontal>
                  <TimeInputSelector
                    grow
                    defaultToNow
                    label="Start Time"
                    model="start_time"
                    validations={{
                      presence: {
                        message: 'Please select when the event starts',
                      },
                    }}
                  />
                  <TimeInputSelector
                    grow
                    label="End Time"
                    model="end_time"
                    validations={{
                      presence: {
                        message: 'Please select when the event ends',
                      },
                    }}
                  />
                </FormSection>
              </ContextShow>

              <Checkbox label="All Day Event" model="is_all_day" defaultValue={false} />

              <MiniRichTextEditor useDictation useQuickText label="Public Description" model="public_description" />

              {isBehave && (
                <Select isEditable={isEditable} label="Billing Status" model="billing_status" defaultValue="non_billable">
                  <Option label="Non-billable" value="non_billable" />
                  <Option label="Billed" value="billed" />
                  <Option label="Not Billed" value="not_billed" />
                </Select>
              )}

              <div>
                <Accordions>
                  <Accordions.Item title="Staff" isOpen={size(data?.employees)}>
                    <FormSection>
                      <MultiOverlaySelector
                        model="employees"
                        type="employees.active"
                        icon={ICONS.employees}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => startCase(data?.position)}
                      />
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Organizations">
                    <FormSection>
                      <MultiOverlaySelector
                        label="Organizations"
                        model="organizations"
                        type="organizations.all"
                        icon={ICONS.organizations}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => startCase(data.status)}
                      />

                      <MultiOverlaySelector
                        label="Organization Contacts"
                        description="Select Contacts from the Organizations selected above"
                        model="organization_contacts"
                        type="all_organization_contacts"
                        dependentValue={formData?.organization_ids}
                        icon={ICONS.organizations}
                        isDisabled={!hasOrganizations}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => startCase(data.status)}
                      />
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Notifications" isOpen={data?.should_send_reminders}>
                    <FormSection>
                      <Alert glyph="info" type="positive">
                        Notifications are being sent to the above <b>Staff & Clients</b>
                      </Alert>

                      <Label label="Time-based Reminders:" className="!pb-0" />

                      <Checkbox
                        label="Send SMS and Email Reminders"
                        model="should_send_reminders"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded-linethrough"
                      />

                      <ContextShow when="should_send_reminders" is={true}>
                        <div className="!pl-6">
                          <CheckboxGroup
                            label="Send Reminders Options"
                            layout="vertical-dense"
                            validations={{
                              presence: {
                                message: 'Please select at least one option',
                              },
                            }}
                          >
                            <Checkbox label="Ninety days before the event" model="send_reminders.ninety_days" />
                            <Checkbox label="Sixty days before the event" model="send_reminders.sixty_days" />
                            <Checkbox label="Thirty days before the event" model="send_reminders.thirty_days" />
                            <Checkbox label="Three days before the event" model="send_reminders.three_days" />
                            <Checkbox label="Two days before the event" model="send_reminders.two_days" />
                            <Checkbox label="One day before the event" model="send_reminders.one_day" />
                            <Checkbox label="Three hours before the event" model="send_reminders.three_hours" />
                            <Checkbox label="Two hours before the event" model="send_reminders.two_hours" />
                            <Checkbox label="One hour before the event" model="send_reminders.one_hour" />
                            <Checkbox label="15 min before event" model="send_reminders.15_minutes" />
                          </CheckboxGroup>
                        </div>
                      </ContextShow>
                    </FormSection>
                  </Accordions.Item>

                  {isOutcomesEnabled && data && !isEditable && (
                    <Accordions.Item minimal title="Documentation" isOpen={size(data?.event_outcomes) > 0}>
                      <EventOutcomes event={data} category={data?.category} />
                    </Accordions.Item>
                  )}

                  <Accordions.Item title="Place" isOpen={data?.meeting_place !== 'none'}>
                    <FormSection>
                      <Select label="Meeting Place" model="meeting_place" defaultValue="none" className="!flex-1">
                        <Option label="None" value="none" />
                        <Option label="Online" value="online" />
                        <Option label="Housing" value="property" />
                        <Option label="Office" value="office" />
                        <Option label="Organization" value="organization" />
                        <Option label="Other" value="other" />
                      </Select>

                      {formData?.meeting_place === 'online' && (
                        <>
                          <Checkbox
                            defaultChecked
                            falseIcon="cross"
                            falseStyle="none"
                            label=" "
                            description="I acknowledge it is my responsibility to secure this external meeting room service that is outside the control of Behave Health Corp. This includes any requirements related to HIPAA. If you have any questions email us at contact../...com"
                            model="responsibility_acknowledgement"
                          />

                          <URLInput
                            label="Meeting Link"
                            model="meeting_url"
                            isDisabled={formData?.responsibility_acknowledgement !== true}
                            validations={{
                              presence: {
                                message: 'Please enter a meeting link',
                              },
                            }}
                          />
                        </>
                      )}

                      <ContextShow when="meeting_place" is="property">
                        <ObjectSelector
                          icon={ICONS.properties}
                          label="Housing"
                          type="properties"
                          model="house"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => address(data?.address)}
                          validations={{
                            presence: {
                              message: 'Please select a property',
                            },
                          }}
                        />

                        <ObjectSelector
                          icon={ICONS.rooms}
                          label="Room (optional)"
                          type="property.rooms"
                          model="room"
                          dependent="house_id"
                          disableUnless="house_id"
                          selectTitle={(data: any) => data?.name}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="office">
                        <ObjectSelector
                          icon={ICONS.properties}
                          label="Office"
                          type="offices"
                          model="house"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => address(data?.address)}
                          validations={{
                            presence: {
                              message: 'Please select a property',
                            },
                          }}
                        />

                        <ObjectSelector
                          icon={ICONS.rooms}
                          label="Room (optional)"
                          type="property.rooms"
                          model="room"
                          dependent="house_id"
                          disableUnless="house_id"
                          selectTitle={(data: any) => data?.name}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="organization">
                        <ObjectSelector
                          label="Organization"
                          model="organization"
                          type="organizations"
                          icon={ICONS.organizations}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => address(data?.address)}
                          validations={{
                            presence: {
                              message: 'Please select an organization',
                            },
                          }}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" within={['property', 'organization', 'online', 'other']}>
                        <MiniRichTextEditor useQuickText label="More Details" model="place_details" />
                      </ContextShow>
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Links" isOpen={size(data?.urls)}>
                    <Links model="urls" />
                  </Accordions.Item>

                  <Accordions.Item title="Notes" isOpen={!!data?.internal_notes}>
                    <FormSection>
                      <MiniRichTextEditor useQuickText label="Internal Notes" model="internal_notes" />

                      {isEditable && data?.status === 'active' && (
                        <Alert glyph="info">After-session notes will be visible after event is completed.</Alert>
                      )}

                      {data?.status === 'completed' && (
                        <>
                          <MiniRichTextEditor
                            useQuickText
                            isEditable={isEditable}
                            label="After-Session Notes"
                            model="after_session_notes"
                          />
                          <CheckboxGroup isEditable={isEditable} trueIcon="check" falseStyle="faded" falseIcon="cross">
                            <Checkbox isEditable={isEditable} label="After-Session Notes Completed" model="is_after_session_completed" />
                          </CheckboxGroup>
                        </>
                      )}
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Attachments" isOpen={size(data?.documents)}>
                    <FormSection layout="vertical">
                      <Attachments model="documents" labelAlign="top" labelJustify="top" />
                    </FormSection>
                  </Accordions.Item>
                </Accordions>
              </div>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Grid vertical gap="1rem">
          <Flex gap="0.5rem">
            {isEditable && (
              <>
                <Button
                  label="Save"
                  glyph="check"
                  type="primary"
                  color="green"
                  flex="100 1 auto"
                  onClick={save}
                  isLoading={isSaving}
                  isDisabled={isInvalid}
                  permission="events.edit"
                />
                <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
              </>
            )}

            {!isEditable && (
              <>
                <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" permission="events.edit" />

                {data?.status === 'cancelled' && (
                  <Button
                    label="Activate Event"
                    glyph="check"
                    color="green"
                    isLoading={isActivating}
                    onClick={() => {
                      activateEvent()
                    }}
                    permission="events.actions.activate"
                  />
                )}

                {(data?.status === 'active' || data?.status === 'completed') && (
                  <Button
                    label="Cancel Event"
                    glyph="decline"
                    glyphColor="red"
                    color="text"
                    isLoading={isCancelling}
                    onClick={() => {
                      cancelEvent()
                    }}
                    permission="events.actions.cancel"
                  />
                )}

                <DeleteDialog
                  title="Delete Event?"
                  message="Are you sure you want to delete this event? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} permission="events.delete" />
                </DeleteDialog>
              </>
            )}
          </Flex>
        </Grid>
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  meetingIcon: {
    marginLeft: 'auto',
  },
}

export default withOverlayError(EventOverlay)
