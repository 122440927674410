import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { getVersionSlug } from '@behavehealth/utils/functions'
import { MEDICAL_ASSESSMENTS_SLUGS } from '@behavehealth/utils/constants'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, Chotomate, Dropdown, DropdownItem, HelpTagIframe, Page, Grid } from '@behavehealth/components'

import { DataFormAddDropdown } from '@behavehealth/constructs/DataFormsBuilder/DataFormAddDropdown'
import { MedicalAssessmentsDataTable } from '@behavehealth/constructs/MedicalAssessments/MedicalAssessmentsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'

const pageConfig = {
  feature: 'medical_assessments',
  help: <HelpTagIframe id="medical_assessments" />,
  marketingID: 'medical_assessments',
}

type Props = {
  canCreate: boolean
}

const MedicalAssessments: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const resourceID = match.params?.resource_id

  const { data: client }: any = useGet({
    name: ['client', resourceID],
    url: `/residents/${resourceID}`,
  })

  const tableProps = useDataTable({
    name: ['client', resourceID, 'data_forms'],
    endpoint: `/residents/${resourceID}/data_forms`,
    params: { category: 'medical_assessment' },
    updateDeleteEndpoint: '/data_forms',
    localStorageKey: 'client_medical_assessments_v1',
  })

  const to = React.useMemo(
    () => (record: any) => {
      const { version, subcategory, id } = record

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

      return {
        pathname: `${match.url}/${MEDICAL_ASSESSMENTS_SLUGS[subcategory]}/${id}${formattedVersion}`,
        parent: match,
      }
    },
    [match.url],
  )

  const formsDropdown = (
    <>
      <DataFormAddDropdown category="medical_assessment" clientTrack={client?.client_track} />

      {/* <Dropdown
        label="Add New…"
        buttonType="primary"
        glyph="add"
        permission="medical_assessments.create"
      >
        <DropdownItem label="History & Physical" icon="medical_assessments" link={`${match.url}/history-and-physical/new`} />
        <DropdownItem
          label="History & Physical Follow Up"
          icon="medical_assessments"
          link={`${match.url}/history-and-physical-follow-up/new`}
        />
        <DropdownItem label="Psychiatric Assessment" icon="medical_assessments" link={`${match.url}/psychiatric-assessment/new`} />
        <DropdownItem
          label="Client Health Questionnaire"
          icon="medical_assessments"
          link={`${match.url}/client-health-questionnaire/new`}
        />
        <DropdownItem
          label="Client Health Questionnaire & Initial Screening"
          icon="medical_assessments"
          link={`${match.url}/client-health-questionnaire-initial-screening/new`}
        />
      </Dropdown> */}
    </>
  )

  return (
    <Page actions={canCreate && formsDropdown} {...pageConfig}>
      <Chotomate name="medical_assessments" ready={!tableProps.isLoading} />

      <Grid gap="1rem" columns="100%">
        <Card>
          <MedicalAssessmentsDataTable
            {...tableProps}
            to={to}
            hiddenColumnIds={['client']}
            headerLinksConfig={[
              {
                type: 'report',
                to: '/reports/medical-assessments',
              },
            ]}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'medical_assessments.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(MedicalAssessments, pageConfig))
