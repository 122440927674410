import React from 'react'
import { Link, Route, useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Grid, Page, HelpTagIframe, H1 } from '@behavehealth/components'
import { ProgressNotesDataTable } from '@behavehealth/constructs/ProgressNotes/ProgressNotesDataTable'
import { ProgressNoteOverlay } from '@behavehealth/constructs/ProgressNotes/ProgressNoteOverlay'
import { StaffDataFormsAlert } from '../components/StaffDataFormsAlert'

import { getBatchActions, getCanSelectDataForm } from '../utils/dataForms'
import { useIsMyProfile } from '../utils/useIsMyProfile'
import { useStaffDataFormTabs, StaffDataFormTabs } from '../components/StaffDataFormTabs'

const pageConfig = {
  feature: 'progress_notes',
  help: <HelpTagIframe id="progress_notes" />,
  marketingID: 'progress_notes',
}

type Props = {
  canCreate: boolean
}

const ProgressNotes = () => {
  return (
    <>
      <ProgressNotesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<ProgressNoteOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const ProgressNotesIndex: React.FC<Props> = () => {
  const { resource_id = '' }: any = useParams()

  const isMyProfile = useIsMyProfile()
  const { activeTab, setActiveTab, queryParams } = useStaffDataFormTabs()

  const tableProps = useDataTable({
    name: ['employee', resource_id, 'data_forms'],
    endpoint: `/employees/${resource_id}/data_forms`,
    updateDeleteEndpoint: '/data_forms',
    params: { category: 'progress_note', ...queryParams },
    enabled: !!resource_id,
    localStorageKey: 'employee_progress_notes_v1',
  })

  return (
    <Page {...pageConfig}>
      <Grid gap="1rem">
        <StaffDataFormsAlert name="Progress Notes" />

        <StaffDataFormTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        <ProgressNotesDataTable
          asCard
          key={`tab-${activeTab}`}
          to={(data: any) => data.id}
          getCanSelect={getCanSelectDataForm}
          batchActionsConfig={getBatchActions({ isMyProfile, activeTab, tableProps, permissionBase: 'progress_notes' })}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ProgressNotes, pageConfig))
