import React from 'react'
import { DateTime } from 'luxon'
import { transparentize } from 'polished'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import kebabCase from 'lodash/kebabCase'
import * as Dropdown from '@radix-ui/react-dropdown-menu'

import { COLORS, GLYPHS, SHADOW, INPUT_STYLES } from '../../theme'
import { formatURL, titleCase } from '../../utils/functions'
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import ButtonGroup from '../../components/ButtonGroup'
import Card from '../../components/Card'
import CardContent from '../../components/CardContent'
import CardHeader from '../../components/CardHeader'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import GlyphSelector from '../../components/Forms/GlyphSelector'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import Tabs from '../../components/Tabs'
import Textarea from '../../components/Forms/Textarea'
import Tooltip from '../../components/Tooltip'
import URLInput from '../../components/Forms/URLInput'

import { DropdownMenu, DropdownMenuItem } from '../../components/DropdownMenu'

import { DIRECTORY_SCHEMA } from './schema'

const BUTTON_COLORS = [
  COLORS['blue'],
  COLORS['violet'],
  COLORS['purple'],
  COLORS['vividBlue'],
  COLORS['green'],
  COLORS['paleGreen'],
  COLORS['orange'],
  COLORS['red'],
  COLORS['text'],
  COLORS['gray'],
  COLORS['white'],
]

const COLOR_NAMES = {
  [COLORS['blue']]: 'blue',
  [COLORS['violet']]: 'violet',
  [COLORS['purple']]: 'purple',
  [COLORS['vividBlue']]: 'vividBlue',
  [COLORS['green']]: 'green',
  [COLORS['paleGreen']]: 'paleGreen',
  [COLORS['orange']]: 'orange',
  [COLORS['red']]: 'red',
  [COLORS['text']]: 'darkGray',
  [COLORS['gray']]: 'gray',
  [COLORS['white']]: 'white',
}

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const DEFAULT_WIDGET_OPTIONS = {
  button_text: 'Browse Locations',
  button_type: 'ribbon',
  button_size: 'medium',
  button_glyph: 'behave_health',
  button_bg: BUTTON_COLORS[0],
  button_color: COLORS.white,
  button_ribbon_position: 'left-center',
  button_floating_position: 'bottom-right',
}

const RootAuthorityDirectoryOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'directories',
    endpoint: '/directories',
    invalidate: 'directories',
    options: props,
    openAfterCreate: true,
  })

  const { tenant, timezone } = useSettings()

  const widgetId = data?.nanoid

  const { copy: copyID, didCopy: didCopyID } = useCopyToClipboard({
    text: widgetId,
    dismissAfter: 1000,
  })

  const [tab, setTab] = React.useState('options')
  const [isCustomTrigger, setIsCustomTrigger] = React.useState(false)

  const [widgetOptions, setWidgetOptions] = React.useReducer(dataReducer, DEFAULT_WIDGET_OPTIONS)

  const { button_text, button_type, button_size, button_glyph, button_bg, button_color, button_ribbon_position, button_floating_position } =
    widgetOptions

  const [directoryOpen, setDirectoryOpen] = React.useState(false)
  const [previewWebsite, setPreviewWebsite] = React.useState(tenant?.website_url || 'https://www.behavehealth.com')

  const buttonStyle = React.useMemo(() => {
    const style: any = {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      fontSize: '16px',
      fontWeight: 600,
      borderRadius: 5,
      padding: '12px 24px',
      cursor: 'pointer',
      color: button_color,
      background: button_bg,
      boxShadow: SHADOW(2, transparentize(0.85, COLORS.text)),
    }

    if (button_size === 'small') {
      style['padding'] = '8px 16px'
      style['fontSize'] = '14px'
    } else if (button_size === 'medium') {
      style['padding'] = '12px 24px'
      style['fontSize'] = '16px'
    } else if (button_size === 'large') {
      style['padding'] = '16px 32px'
      style['fontSize'] = '18px'
    }

    if (button_type === 'ribbon') {
      style['position'] = 'absolute'
      style['top'] = '50%'
      style['zIndex'] = 99999

      if (button_ribbon_position === 'left-center') {
        style['left'] = '0px'
        style['borderRadius'] = '0 0 5px 5px'
        style['transformOrigin'] = 'top left'
        style['transform'] = 'rotate(-90deg) translateX(-50%)'
      }

      if (button_ribbon_position === 'right-center') {
        style['right'] = '0px'
        style['borderRadius'] = '5px 5px 0 0'
        style['transformOrigin'] = 'top right'
        style['transform'] = 'rotate(-90deg) translateY(-100%) translateX(50%)'
      }
    }

    if (button_type === 'floating') {
      style['position'] = 'absolute'
      style['bottom'] = '24px'
      style['borderRadius'] = '100px'
      style['zIndex'] = 99999

      if (button_floating_position === 'bottom-left') {
        style['left'] = '24px'
      }

      if (button_floating_position === 'bottom-right') {
        style['right'] = '24px'
      }

      if (button_floating_position === 'bottom-center') {
        style['left'] = '50%'
        style['transform'] = 'translateX(-50%)'
      }

      if (!button_text) {
        if (button_size === 'small') {
          style['width'] = '48px'
          style['height'] = '48px'
        } else if (button_size === 'medium') {
          style['width'] = '56px'
          style['height'] = '56px'
        } else if (button_size === 'large') {
          style['width'] = '64px'
          style['height'] = '64px'
        }
      }
    }

    return style
  }, [button_text, button_size, button_type, button_glyph, button_bg, button_color, button_ribbon_position, button_floating_position])

  const buttonStringStyle = React.useMemo(() => {
    const res = Object.keys(buttonStyle)
      .map((s) => `${kebabCase(s)}: ${buttonStyle[s]}`)
      .join(';')

    return res
  }, [buttonStyle])

  const glyphStyle = React.useMemo(() => {
    const style: any = {
      width: '22px',
      height: '22px',
      display: 'inline-block',
      verticalAlign: 'middle',
      flex: '0 0 auto',
      fill: button_color,
    }

    if (button_size === 'small') {
      style['width'] = '18px'
      style['height'] = '18px'
    } else if (button_size === 'medium') {
      style['width'] = '22px'
      style['height'] = '22px'
    } else if (button_size === 'large') {
      style['width'] = '26px'
      style['height'] = '26px'
    }

    if (button_text) {
      style['marginRight'] = '8px'
    } else {
      style['width'] = '28px'
      style['height'] = '28px'
    }

    return style
  }, [button_text, button_size, button_type, button_glyph, button_bg, button_color, button_ribbon_position, button_floating_position])

  const glyphStringStyle = React.useMemo(() => {
    const res = Object.keys(glyphStyle)
      .map((s) => `${kebabCase(s)}: ${glyphStyle[s]}`)
      .join(';')

    return res
  }, [glyphStyle])

  const buttonSvg = GLYPHS?.[button_glyph] && (
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" width={20} height={20} style={glyphStyle}>
      <path d={GLYPHS[button_glyph].path} />
    </svg>
  )

  const code = React.useMemo(() => {
    const script = `<script id="bh-directory" data-id="${widgetId}" src="https://behavehealth-scripts.s3.us-east-1.amazonaws.com/beds_directory_widget.js?t=${Math.round(
      DateTime.local().toSeconds(),
    )}"></script>`

    const svg = GLYPHS[button_glyph]?.path
      ? `<svg viewBox="0 0 30 30" style="${glyphStringStyle}" xmlns="http://www.w3.org/2000/svg" width="20" height="20" ><path d="${GLYPHS[button_glyph].path}" /></svg>`
      : ''

    let button = ''

    if (isCustomTrigger) {
      button = `<!-- Add id="bh-directory-open-${widgetId}" to any clickable element on your website along with the script below to enable the Directory widget -->`
    } else {
      button =
        `<button id="bh-directory-open-${widgetId}" style="${buttonStringStyle}" type="button">${svg}${button_text}</button>`.replaceAll(
          '\n',
          '',
        )
    }

    return `<!-- Button  -->\n${button}\n\n<!-- Script  -->\n${script}`
  }, [isCustomTrigger, widgetId, button_text, button_glyph, buttonStringStyle, glyphStringStyle])

  const { copy, didCopy: codeCopied } = useCopyToClipboard({
    text: code,
    notification: 'Code copied!',
    dismissAfter: 1000,
  })

  const isCustomBg = !BUTTON_COLORS.includes(button_bg)
  const isCustomColor = !BUTTON_COLORS.includes(button_color)

  const buttonBgRef = React.useRef(null)
  const buttonColorRef = React.useRef(null)

  const save = async () => {
    const formData = form.current?.getFormValue()

    await saveWithData({
      ...formData,
      widget_options: widgetOptions,
    })
  }

  React.useEffect(() => {
    if (data?.widget_options) {
      setWidgetOptions(data.widget_options)
    }
  }, [data])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={37} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable} maxWidth={tab === 'options' ? 37 : 130}>
      <Overlay.Header icon="directories" title="Directory" />

      <Overlay.SubHeader className="!p-0 relative z-[5]">
        <Tabs activeTab={tab} onChange={setTab}>
          <Tabs.List className="!border-none px-5">
            <Tabs.Item label="Directory Options" name="options" />
            <Tooltip show={isNew} content="Save the Directory first to view the Embed Widget options">
              <Tabs.Item label="Embed Widget" name="widget" isDisabled={isNew} />
            </Tooltip>
          </Tabs.List>
        </Tabs>
      </Overlay.SubHeader>

      {widgetId && (
        <Overlay.SubHeader className="!px-5 !py-2 relative z-[3] !bg-white !bg-opacity-70">
          <div className="flex flex-nowrap items-center">
            <div className="font-mono text-text-muted text-[0.9rem] mr-auto leading-none relative top-[1px]">
              <b>ID: </b>
              {widgetId}
            </div>

            <Button
              glyph={didCopyID ? 'tick_circle' : 'copy'}
              label={didCopyID ? 'Copied' : 'Copy'}
              color={didCopyID ? 'green' : 'blue'}
              type="default"
              size={100}
              className="ml-2 flex-[0_0_auto]"
              onClick={copyID}
            />
          </div>
        </Overlay.SubHeader>
      )}

      <Overlay.Content className="grid mq900:grid-cols-1 mq900:grid-rows-1 mq900:overflow-hidden">
        <Form
          getForm={form}
          initialModel={{ ...initialModel, ...(isNew && { status: 'inactive' }) }}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          linked={{ category: 'organization_authority' }}
          className="grid mq900:grid-cols-1 mq900:grid-rows-1 mq900:overflow-hidden"
          key={`updated-${data?.updated_at}`}
        >
          <div className={clsx('overflow-y-auto', tab === 'options' ? 'block' : 'hidden')}>
            <div className="p-5">
              <h2 className="mb-3">Directory Details</h2>

              <FormSection maxWidth="100%">
                <Input
                  label="Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please add a directory name',
                    },
                  }}
                />

                <Input
                  label="Public Link"
                  model="slug"
                  prefix={
                    isEditable && (
                      <span className="flex items-center">
                        <Glyph glyph="website" size={16} className="mr-1.5" color={COLORS.text} />
                        <span className="text-[0.85rem]">https://directory.behavehealth.com/</span>
                      </span>
                    )
                  }
                  processValueBeforeChange={(value: any) => kebabCase(value)}
                  validations={{
                    presence: {
                      message: 'Please enter a public link',
                    },
                  }}
                  className={isEditable ? 'block' : 'hidden'}
                />

                {data?.slug && (
                  <div className={isEditable ? 'hidden' : 'block'}>
                    {data?.status === 'active' ? (
                      <URLInput isEditable={false} label="Public Link" value={`https://directory.behavehealth.com/${data?.slug}`} />
                    ) : (
                      <Alert small contrast glyph="info">
                        This directory is currently inactive and cannot be accessed publicly.
                      </Alert>
                    )}
                  </div>
                )}

                <RadioGroup label="Status" model="status" layout="horizontal" defaultValue="inactive">
                  <Radio label="Active" value="active" />
                  <Radio label="Inactive" value="inactive" />
                </RadioGroup>

                <Textarea label="Description" model="description" />
              </FormSection>
            </div>

            <Divider className="!m-0" />

            <div className="p-5">
              <h2 className="mb-3">Data Points</h2>

              <FormSection>
                {isEditable && (
                  <Alert small contrast glyph="info">
                    Select which data points you want to include in the Directory for each facility, location, and program.
                  </Alert>
                )}

                {Object.keys(DIRECTORY_SCHEMA).map((sectionKey) => {
                  const section = DIRECTORY_SCHEMA[sectionKey]

                  return (
                    <CheckboxGroup key={sectionKey} label={section.name} layout="vertical-dense">
                      {Object.keys(section.schema).map((model) => {
                        const { name, tooltip, isRequired } = section.schema[model]

                        return (
                          <Checkbox
                            key={`${sectionKey}.${model}`}
                            defaultChecked={isRequired ? true : undefined}
                            isDisabled={isRequired}
                            label={name}
                            tooltip={tooltip}
                            model={`schema.${sectionKey}.${model}`}
                            trueIcon="check"
                            falseIcon="empty_checkbox"
                            falseStyle="faded"
                          />
                        )
                      })}
                    </CheckboxGroup>
                  )
                })}
              </FormSection>
            </div>
          </div>

          <div className={clsx('mq900:grid-cols-[500px_1fr] mq900:grid-rows-[100%]', tab === 'widget' ? 'grid' : 'hidden')}>
            <div className="overflow-y-auto grid content-start border-r border-solid border-0 border-divider ">
              <div className="p-5">
                <h2 className="mb-3">Behave Button Options</h2>

                <FormSection maxWidth="100%">
                  <Alert small contrast glyph="info">
                    Customize the look and feel of the Directory button that will be embedded on your website.
                  </Alert>

                  <Flex gap="0.75rem">
                    <GlyphSelector
                      label="Icon"
                      value={button_glyph}
                      onUpdate={({ value }) => {
                        setWidgetOptions({ button_glyph: value })
                      }}
                      className="flex-[0_0_70px]"
                    />

                    <Input
                      label="Button Text"
                      value={button_text}
                      onUpdate={({ value }) => setWidgetOptions({ button_text: value })}
                      className="flex-[1_1_auto]"
                    />
                  </Flex>

                  <Flex stretchChildrenX gap="0.75rem">
                    <Select
                      label="Button Size"
                      className="flex-[1_1_auto]"
                      value={button_size}
                      onUpdate={({ value }) => setWidgetOptions({ button_size: value })}
                    >
                      <Option label="Small" value="small" />
                      <Option label="Medium" value="medium" />
                      <Option label="Large" value="large" />
                    </Select>

                    <Select
                      label="Button Type"
                      className="flex-[1_1_auto]"
                      value={button_type}
                      onUpdate={({ value }) => setWidgetOptions({ button_type: value })}
                    >
                      <Option label="Ribbon" value="ribbon" />
                      <Option label="Floating" value="floating" />
                      <Option label="Inline" value="inline" />
                    </Select>

                    {button_type === 'ribbon' && (
                      <Select
                        label="Ribbon Position"
                        className="flex-[1_1_auto]"
                        value={button_ribbon_position}
                        onUpdate={({ value }) => setWidgetOptions({ button_ribbon_position: value })}
                      >
                        <Option label="Left Center" value="left-center" />
                        <Option label="Right Center" value="right-center" />
                      </Select>
                    )}

                    {button_type === 'floating' && (
                      <Select
                        label="Floating Position"
                        className="flex-[1_1_auto]"
                        value={button_floating_position}
                        onUpdate={({ value }) => setWidgetOptions({ button_floating_position: value })}
                      >
                        <Option label="Bottom Left" value="bottom-left" />
                        <Option label="Bottom Center" value="bottom-center" />
                        <Option label="Bottom Right" value="bottom-right" />
                      </Select>
                    )}
                  </Flex>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <Label label="Background Color" />

                      {isEditable && (
                        <DropdownMenu
                          trigger={
                            <div>
                              <Button
                                size={200}
                                className="!justify-start [&>*]:w-full"
                                label={
                                  <div className="flex items-center flex-nowrap flex-[1_1_auto] !w-full">
                                    <div
                                      className="w-4 h-4 rounded-full cursor-pointer hover:scale-[1.1] mr-1.5"
                                      style={{
                                        background: widgetOptions.button_bg,
                                        boxShadow: '0 0 0 1px rgba(0,0,0,0.1)',
                                      }}
                                    />
                                    <div className="text-text">Select Background Color</div>
                                    <Glyph glyph="triangle_down" size={12} className="!ml-auto" />
                                  </div>
                                }
                              />
                            </div>
                          }
                        >
                          <div className="p-4">
                            <div className="grid grid-cols-4 gap-4 justify-center">
                              {BUTTON_COLORS.map((color) => (
                                <Dropdown.Item asChild>
                                  <div>
                                    <Tooltip key={color} content={titleCase(COLOR_NAMES[color])}>
                                      <div
                                        className="w-7 h-7 rounded-full cursor-pointer hover:scale-[1.1]"
                                        style={{
                                          background: color,
                                          boxShadow:
                                            color === button_bg
                                              ? `0 0 0 1px #F7F8FB, 0 0 0 3px ${COLORS.text}`
                                              : '0 0 0 1px rgba(0,0,0,0.1)',
                                        }}
                                        onClick={() => {
                                          setWidgetOptions({ button_bg: color })
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </Dropdown.Item>
                              ))}
                            </div>
                          </div>
                        </DropdownMenu>
                      )}

                      {isEditable ? (
                        <input
                          ref={buttonBgRef}
                          value={button_bg}
                          onChange={(e) => {
                            setWidgetOptions({ button_bg: e.target.value })
                          }}
                          className="mt-2"
                          css={INPUT_STYLES}
                        />
                      ) : (
                        <div>{button_bg}</div>
                      )}
                    </div>

                    <div>
                      <Label label="Text Color" />
                      {isEditable && (
                        <DropdownMenu
                          trigger={
                            <div>
                              <Button
                                size={200}
                                className="!justify-start [&>*]:w-full"
                                label={
                                  <div className="flex items-center flex-nowrap flex-[1_1_auto] !w-full">
                                    <div
                                      className="w-4 h-4 rounded-full cursor-pointer hover:scale-[1.1] mr-1.5"
                                      style={{
                                        background: widgetOptions.button_color,
                                        boxShadow: '0 0 0 1px rgba(0,0,0,0.1)',
                                      }}
                                    />
                                    <div className="text-text">Select Text Color</div>
                                    <Glyph glyph="triangle_down" size={12} className="!ml-auto" />
                                  </div>
                                }
                              />
                            </div>
                          }
                        >
                          <div className="p-4">
                            <div className="grid grid-cols-4 gap-4 justify-center">
                              {BUTTON_COLORS.map((color) => (
                                <Dropdown.Item asChild>
                                  <div>
                                    <Tooltip key={color} content={titleCase(COLOR_NAMES[color])}>
                                      <div
                                        className="w-7 h-7 rounded-full cursor-pointer hover:scale-[1.1]"
                                        style={{
                                          background: color,
                                          boxShadow:
                                            color === button_color
                                              ? `0 0 0 1px #F7F8FB, 0 0 0 3px ${COLORS.text}`
                                              : '0 0 0 1px rgba(0,0,0,0.1)',
                                        }}
                                        onClick={() => {
                                          setWidgetOptions({ button_color: color })
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </Dropdown.Item>
                              ))}
                            </div>
                          </div>
                        </DropdownMenu>
                      )}

                      {isEditable ? (
                        <input
                          ref={buttonColorRef}
                          value={button_color}
                          onChange={(e) => {
                            setWidgetOptions({ button_color: e.target.value })
                          }}
                          className="mt-2"
                          css={INPUT_STYLES}
                        />
                      ) : (
                        <div>{button_color}</div>
                      )}
                    </div>
                  </div>

                  <div>
                    <Label label="Preview" />
                    <button
                      style={{
                        ...buttonStyle,
                        position: 'static',
                        transform: 'none',
                      }}
                      type="button"
                    >
                      {buttonSvg}
                      {button_text}
                    </button>
                  </div>
                </FormSection>
              </div>

              <Divider className="!m-0" />

              <div className="p-5">
                <Flex gap="0.75rem" justifyContent="space-between" className="!mb-3">
                  <h2>Embed Widget</h2>
                  <ButtonGroup>
                    <Button
                      label="Behave Button"
                      color="text"
                      type={!isCustomTrigger ? 'primary' : 'default'}
                      size={200}
                      onClick={() => {
                        setIsCustomTrigger(false)
                      }}
                    />
                    <Button
                      label="Custom Button"
                      color="text"
                      type={isCustomTrigger ? 'primary' : 'default'}
                      size={200}
                      onClick={() => {
                        setIsCustomTrigger(true)
                      }}
                    />
                  </ButtonGroup>
                </Flex>

                <FormSection maxWidth="100%">
                  {isCustomTrigger ? (
                    <Alert small contrast glyph="info">
                      The "Custom Button" option allows you to open the Directory from any clickable element on your website. Paste the code
                      below and follow the instructions to set this up, or send it to your website administrator.
                    </Alert>
                  ) : (
                    <Alert small contrast glyph="info">
                      Paste the code below on your website to embed the Directory, or send it to your website administrator.
                    </Alert>
                  )}

                  <Card>
                    <CardHeader className="!py-1 !px-0">
                      <pre
                        css={{
                          width: '100%',
                          wordBreak: 'break-all',
                          whiteSpace: 'pre-wrap',
                          color: COLORS.textMuted,
                          background: '#EEF0F7',
                          border: `1px solid ${COLORS.divider}`,
                          borderRadius: 5,
                          padding: '0.4rem 0.5rem',
                          margin: 0,
                          fontSize: '0.9em',
                        }}
                      >
                        {code}
                      </pre>
                    </CardHeader>

                    <CardContent>
                      <Flex gap="1rem">
                        <Button
                          glyph={codeCopied ? 'check' : 'copy'}
                          label={codeCopied ? 'Code Copied to Clipboard!' : 'Copy Code'}
                          onClick={copy}
                          display="inline-flex"
                          type={codeCopied ? 'default' : 'primary'}
                        />

                        <a
                          href={`mailto:?subject=Please%20Embed%20Code%20To%20Contact%20Page&body=Hello,%0A%0ACan%20you%20please%20embed%20the%20code%20below%20to%20our%website?%0A%0A${code}%0A%0AThank you!`}
                          target="_blank"
                          rel="noreferrer"
                          css={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            svg: { marginLeft: '0.25em' },
                            '&:hover': { textDecoration: 'underline' },
                          }}
                        >
                          Email to website administrator <Glyph glyph="external_link" size={14} color={COLORS.blue} />
                        </a>
                      </Flex>
                    </CardContent>
                  </Card>
                </FormSection>
              </div>
            </div>

            <div className="grid gap-3 mq900:grid-rows-[auto_1fr] p-5">
              <div className="flex justify-between items-center">
                <h2>Preview</h2>
                <URLInput value={previewWebsite} onUpdate={({ value }) => setPreviewWebsite(value)} className="!w-full !max-w-[300px]" />
              </div>

              <Card
                className={clsx(
                  'relative min-h-[600px]',
                  button_type === 'inline' && 'mq900:grid mq900:grid-cols-1 mq900:grid-rows-[auto_1fr] mq900:overflow-hidden',
                )}
              >
                {button_type === 'inline' ? (
                  <div className="p-3">
                    <Alert small glyph="info">
                      Inline buttons are added anywhere on your website using the provided code snippet. A preview cannot be displayed here.
                    </Alert>

                    <div className="flex justify-center my-3">
                      <button style={buttonStyle} type="button" onClick={() => setDirectoryOpen(true)}>
                        {buttonSvg}
                        {button_text}
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <button style={buttonStyle} type="button" onClick={() => setDirectoryOpen(true)}>
                      {buttonSvg}
                      {button_text}
                    </button>
                  </>
                )}

                {formatURL(previewWebsite) && (
                  <iframe
                    src={formatURL(previewWebsite)}
                    className={clsx('w-full h-full', button_type !== 'inline' && 'absolute inset-0')}
                  />
                )}

                {directoryOpen && (
                  <>
                    <div className="absolute inset-0 bg-backdrop" onClick={() => setDirectoryOpen(false)} />
                    <iframe
                      src="https://development.d1dllrb5nmezyq.amplifyapp.com/bhid_123"
                      className="absolute top-0 right-0 w-full h-full max-w-[1000px]"
                    ></iframe>
                  </>
                )}
              </Card>
            </div>
          </div>
        </Form>
      </Overlay.Content>

      <Overlay.Footer withGradient={false}>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Directory" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Directory?"
              message="Are you sure you want to delete this Directory? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AuthorityDirectoryOverlay = withOverlayError(RootAuthorityDirectoryOverlay)
