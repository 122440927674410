import React from 'react'
import { Link, NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import { HelpTagIframe, Page, Tabs } from '@behavehealth/components'
import { StaffAgreementTemplatesDataTable } from '@behavehealth/constructs/AgreementTemplates/StaffAgreementTemplatesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'staff_agreement_templates',
  title: 'Staff Agreement Templates',
  help: <HelpTagIframe id="settings_employee_agreement_templates" />,
}

const EmployeeAgreementTemplates = () => {
  const { isBehave, isManagement } = useSettings()

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Tabs defaultTab="support_main_plan">
        <Tabs.List className="-mt-4 mb-3">
          <Tabs.Item as={NavLink} label="Agreements" icon="legal_agreement_alt" to={`agreements`} />

          {(isBehave || isManagement) && (
            <Tabs.Item as={NavLink} label="Admin Agreements" icon="legal_agreement_alt" to={`admin-agreements`} />
          )}
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="agreements" replace />} />

        <Route path={`agreements/*`} element={<AgreementTemplates />} />
        <Route path={`admin-agreements/*`} element={isBehave || isManagement ? <AdminAgreementTemplates /> : null} />
      </Routes>
    </Page>
  )
}

const AgreementTemplates = () => {
  const tableProps = useDataTable({
    name: ['agreement_templates'],
    endpoint: `/agreement_templates`,
    params: { variant: 'employee' },
    localStorageKey: 'employee_agreement_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <StaffAgreementTemplatesDataTable
        to={(row: any) => row.id}
        duplicateFormLinkProps={(duplicated) => ({
          as: Link,
          link: 'new',
          state: { data: duplicated },
        })}
        {...tableProps}
      />
    </Page>
  )
}

const AdminAgreementTemplates = () => {
  const tableProps = useDataTable({
    name: ['agreement_templates'],
    endpoint: `/agreement_templates`,
    params: { variant: 'employee', agreement_type: 'admin' },
    localStorageKey: 'employee_admin_agreement_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <StaffAgreementTemplatesDataTable
        to={(row: any) => row.id}
        duplicateFormLinkProps={(duplicated) => ({
          as: Link,
          link: 'new',
          state: { data: duplicated },
        })}
        {...tableProps}
      />
    </Page>
  )
}

export default withMarketing(EmployeeAgreementTemplates, pageConfig)
