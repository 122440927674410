import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import Timeline from '../../components/Timeline/Timeline'

const RootAuthorityOrganizationCommunicationOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'communication',
    endpoint: '/communications',
    invalidate: 'communications',
    options: props,
    disableParentRequest: true,
    onSaveSuccessful: props.onSaveSuccessful,
    onDeleteSuccessful: props.onDeleteSuccessful,
    headers: {
      'X-Request-Level': 'global',
    },
  })

  const { timezone } = useSettings()

  const { referenceID, referenceType } = props

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable}>
      <Overlay.Header title="Communication Log" icon="communications" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              customer_id: referenceID,
              customer_type: referenceType,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <ObjectSelector
                isPolymorphic
                model="contact"
                label="Contact"
                type="organization.contacts"
                isDisabled={!referenceID}
                dependentValue={referenceID}
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.role}
                icon="contacts"
                validations={{
                  presence: {
                    message: 'Please select a contact person',
                  },
                }}
                headers={{ 'X-Request-Level': 'global' }}
              />

              <DateTimeInput
                defaultToNow
                model="contacted_at"
                label="Contact Date and Time"
                validations={{
                  presence: {
                    message: 'Please enter the date and time of contact',
                  },
                }}
              />

              <RadioGroup
                model="category"
                label="Contact Method"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select the method of contact',
                  },
                }}
              >
                <Radio label="Phone" value="phone" />
                <Radio label="Text" value="text_message" />
                <Radio label="Email" value="email" />
                <Radio label="Meeting" value="meeting" />
                <Radio label="Web Meeting" value="web" />
                <Radio label="Other" value="other" />
              </RadioGroup>

              <SmartTextarea
                useQuickText
                useDictation
                model="reason"
                label="Notes"
                validations={{
                  presence: {
                    message: 'Please write a note for this communication log',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this communication log">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="communications.edit"
            />

            <DeleteDialog
              title="Delete Communication Log?"
              message="Are you sure you want to delete this communication log? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="communications.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AuthorityOrganizationCommunicationOverlay = withOverlayError(RootAuthorityOrganizationCommunicationOverlay)
