import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'

import { AnimatedSwitch, Page, Grid, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Portal from '@behavehealth/components/Portal'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Active from './active'
import Completed from './completed'

import { AuthorityOrganizationTodoBuilderOverlay } from '@behavehealth/constructs/AuthorityOrganizations/AuthorityOrganizationTodoBuilderOverlay'
import { AuthorityOrganizationTodoOverlay } from '@behavehealth/constructs/AuthorityOrganizations/AuthorityOrganizationTodoOverlay'

import { OrganizationTodoPageActions } from '@behavehealth/constructs/Organizations/OrganizationTodoPageActions'

const pageConfig = {
  feature: 'todos',
  help: <HelpTagIframe id="todos" />,
  marketingID: 'todos',
}

const Tasks = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { params, url } = match

  const { data: organization }: any = useGet({
    name: ['organization', params?.resource_id],
    url: `/organizations/${params?.resource_id}`,
  })

  return (
    <>
      <Page
        breakpoint="0"
        actions={
          canCreate && (
            <OrganizationTodoPageActions
              data={{ meeting_place: 'organization', organization, organizations: [organization] }}
              organizationTrack={organization?.organization_track}
            />
          )
        }
        {...pageConfig}
      >
        <Grid gap={16}>
          <Tabs>
            <TabList css={styles.tabList}>
              <Tab label="Active" to={`${url}/active`} />
              <Tab label="Completed" to={`${url}/completed`} />
            </TabList>
          </Tabs>

          <Switch>
            <Route path={`${match.path}/active`} children={<Active canCreate={canCreate} />} />
            <Route path={`${match.path}/completed`} children={<Completed canCreate={canCreate} />} />

            <Redirect exact from={url} to={`${url}/active`} />
          </Switch>
        </Grid>
      </Page>

      <Portal type="overlay">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <ProtectedRoute
            path={`${match.path}/:todo_type/todo-builder`}
            component={AuthorityOrganizationTodoBuilderOverlay}
            featureFlag="todos"
            permission="todos.view"
          />

          <ProtectedRoute
            path={`${match.path}/:todo_type/:id`}
            component={AuthorityOrganizationTodoOverlay}
            featureFlag="todos"
            permission="todos.view"
          />
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

const styles = {
  tabList: {
    marginTop: '-1rem',
  },
}

export default withPageError(withMarketing(Tasks, pageConfig))
