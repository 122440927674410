import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AnimatedSwitch, Calendar, CalendarEvent, HelpTagIframe, Page, Grid } from '@behavehealth/components'
import Portal from '@behavehealth/components/Portal'

import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'

import AuthorityOrganizationEventBuilderOverlay from '@behavehealth/constructs/AuthorityOrganizations/AuthorityOrganizationEventBuilderOverlay'
import AuthorityOrganizationEventOverlay from '@behavehealth/constructs/AuthorityOrganizations/AuthorityOrganizationEventOverlay'
import { OrganizationCalendarPageActions } from '@behavehealth/constructs/Organizations/OrganizationCalendarPageActions'

type CalendarDates = {
  currentDate: string
  endDate: string
  startDate: string
}

const pageConfig = {
  feature: 'calendar',
  help: <HelpTagIframe id="calendar" />,
  marketingID: 'calendar',
}

const CalendarPage: React.FC = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { timezone } = useSettings()
  const [dates, setDates] = React.useState<CalendarDates | null>(null)
  const [filters, setFilters] = React.useState({})

  const queryParams = {
    event_type: 'event',
    from: dates?.startDate,
    until: dates?.endDate,
    filters,
  }

  const customHeaders = {
    'X-Request-Level': 'global',
  }

  const { data: organization }: any = useGet({
    name: ['organization', resource_id],
    url: `/organizations/${resource_id}`,
  })

  const { data, isLoading }: any = useGet({
    name: ['organization', resource_id, 'events', queryParams],
    url: `/organizations/${resource_id}/events`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: !!dates },
    headers: customHeaders,
  })

  const { mutate: deleteEvents } = useDelete({
    name: ['organization', resource_id, 'events'],
    url: '/events',
    invalidate: 'events',
    headers: customHeaders,
  })

  return (
    <>
      <Page
        breakpoint="0"
        actions={
          <OrganizationCalendarPageActions
            data={{ meeting_place: 'organization', organization: organization }}
            organizationTrack={organization?.organization_track}
          />
        }
        {...pageConfig}
      >
        <Grid gap="1rem" columns="100%">
          <Filters config={FILTERS.events} onUpdate={setFilters} localStorageKey="calendar" />

          <Calendar
            events={data}
            isLoading={isLoading}
            timezone={timezone}
            localStorageKey="authority_organization_calendar"
            renderEvent={(event: any) => (
              <CalendarEvent useAttendance={false} event={event} timezone={timezone} link={`${match.url}/${event.id}`} />
            )}
            to={(event: any) => `${match.url}/${event.id}`}
            onChange={setDates}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'events.delete',
                action: async ({ ids }: any) => {
                  await deleteEvents(ids.join(','))
                },
              },
            ]}
          />
        </Grid>
      </Page>

      <Portal type="overlay">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <ProtectedRoute
            path={`${match.path}/event-builder`}
            component={AuthorityOrganizationEventBuilderOverlay}
            permission="events.view"
            featureFlag="calendar"
          />
          <ProtectedRoute
            path={`${match.path}/:id`}
            component={AuthorityOrganizationEventOverlay}
            permission="events.view"
            featureFlag="calendar"
          />
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

export default withPageError(withMarketing(CalendarPage, pageConfig))
