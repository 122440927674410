import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import { COLORS } from '@behavehealth/theme'
import { mapToArray } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  Glyph,
  HelpTagIframe,
  Page,
  PageLayout,
  PageSection,
  State,
} from '@behavehealth/components'

import { AgreementsDataTable } from '@behavehealth/constructs/Agreements/AgreementsDataTable'
import { AgreementSigneesDataTable } from '@behavehealth/constructs/Agreements/AgreementSigneesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'

const AddDropdown = () => {
  const match = useRouteMatch()

  const params = { variant: 'organization' }

  const { data: templates, isLoading }: any = useGet({
    name: ['agreement_templates', params],
    url: `/agreement_templates`,
    params,
  })

  return (
    <Dropdown label="Add Agreement…" glyph="add" buttonType="primary" buttonSize={300} permission="organization_agreements.create">
      <DropdownItem
        label="Empty Agreement"
        glyph="add"
        color="paleBlue"
        link={{
          pathname: `${match.url}/new`,
          parent: match,
          data: {
            name: 'New Agreement',
          },
        }}
      />

      {isLoading ? (
        <State isLoading minHeight={100} />
      ) : (
        <>
          <Divider />

          {sortBy(mapToArray(templates), 'order', 'created_at').map((template) => (
            <DropdownItem
              key={template.id}
              label={template.name}
              icon="legal_agreement_alt"
              color="paleBlue"
              link={{
                pathname: `${match.url}/new`,
                parent: match,
                data: template,
              }}
            />
          ))}
        </>
      )}
    </Dropdown>
  )
}

const pageConfig = {
  feature: 'organization_agreements',
  help: <HelpTagIframe id="agreements" />,
}

type Props = {
  canCreate: boolean
}

const Agreements: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()

  const tableProps: any = useDataTable({
    name: ['organization', match.params?.resource_id, 'agreements'],
    endpoint: `/organizations/${match.params?.resource_id}/agreements`,
    updateDeleteEndpoint: '/agreements',
    params: { variant: 'organization' },
    localStorageKey: 'organization_agreements_v1',
  })

  const signeesTableProps = useDataTable({
    name: ['organization', match.params?.resource_id, 'signees'],
    endpoint: `/organizations/${match.params?.resource_id}/signees`,
    enabled: !!match.params?.resource_id,
    localStorageKey: 'organization_signees_v1',
  })

  const agreementsEmpty = size(tableProps.data) === 0

  return (
    <Page
      plan="basic"
      feature="agreements"
      help={<HelpTagIframe id="agreements" />}
      actions={
        <>
          <Button
            size={300}
            glyph="settings"
            type="default"
            label="Manage Templates"
            link="/settings/agreement-templates/organizations"
            permission="settings.agreement_templates.edit"
          />

          <AddDropdown />
        </>
      }
    >
      <PageLayout>
        <PageSection>
          <PageSection.Header
            graphic={<Glyph glyph="user_group" color={COLORS.blue} />}
            after={
              !agreementsEmpty &&
              canCreate && (
                <Button
                  label="Notify Signees"
                  glyph="signature"
                  type="default"
                  link={{
                    pathname: `${match.url}/send-agreements`,
                    parent: match,
                  }}
                  size={300}
                />
              )
            }
          >
            <PageSection.Title title="Signees" />
          </PageSection.Header>

          <PageSection.Content>
            <AgreementSigneesDataTable {...signeesTableProps} />
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header graphic={<Glyph glyph="legal_history" color={COLORS.orange} />}>
            <PageSection.Title title="Agreements" />
          </PageSection.Header>

          <PageSection.Content>
            <AgreementsDataTable
              {...tableProps}
              to={(record: any) => ({
                pathname: `${match.url}/${record.id}`,
                parent: match,
              })}
              testKey="organization_agreements_table"
              batchActionsConfig={[
                {
                  type: 'delete',
                  permission: 'agreements.delete',
                  action: async ({ ids }: any) => {
                    await tableProps.deleteRecords(ids.join(','))
                  },
                },
              ]}
            />
          </PageSection.Content>
        </PageSection>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(Agreements, pageConfig))
