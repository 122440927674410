import React from 'react'
import { Route, useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { PhysicianOrderOverlay } from '@behavehealth/constructs/PhysicianOrders/PhysicianOrderOverlay'
import { PhysicianOrdersDataTable } from '@behavehealth/constructs/PhysicianOrders/PhysicianOrdersDataTable'
import { StaffDataFormsAlert } from '../components/StaffDataFormsAlert'

import { getBatchActions, getCanSelectDataForm } from '../utils/dataForms'
import { useIsMyProfile } from '../utils/useIsMyProfile'
import { useStaffDataFormTabs, StaffDataFormTabs } from '../components/StaffDataFormTabs'

const pageConfig = {
  feature: 'physician_orders',
  help: <HelpTagIframe id="physician_orders" />,
  marketingID: 'physician_orders',
}

type Props = {
  canCreate: boolean
}

const PhysicianOrders = () => {
  return (
    <>
      <PhysicianOrdersIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<PhysicianOrderOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const PhysicianOrdersIndex: React.FC<Props> = () => {
  const { resource_id }: any = useParams()

  const isMyProfile = useIsMyProfile()
  const { activeTab, setActiveTab, queryParams } = useStaffDataFormTabs()

  const tableProps = useDataTable({
    name: ['employee', resource_id, 'data_forms'],
    endpoint: `/employees/${resource_id}/data_forms`,
    updateDeleteEndpoint: '/data_forms',
    params: { category: 'physician_order', ...queryParams },
    enabled: !!resource_id,
    localStorageKey: 'employee_physician_orders_v1',
  })

  return (
    <Page {...pageConfig}>
      <Grid gap="1rem">
        <StaffDataFormsAlert name="Physician Orders" />

        <StaffDataFormTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        <PhysicianOrdersDataTable
          key={`tab-${activeTab}`}
          to={(data: any) => data?.id}
          getCanSelect={getCanSelectDataForm}
          batchActionsConfig={getBatchActions({ isMyProfile, activeTab, tableProps, permissionBase: 'physician_orders' })}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(PhysicianOrders, pageConfig))
