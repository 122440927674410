import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { Button, Page, PageLayout, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { useSettings } from '@behavehealth/hooks/useSettings'
import { AuthorizationsDataTable } from '@behavehealth/constructs/Authorizations/AuthorizationsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const pageConfig = {
  feature: 'insurance_authorizations',
  title: 'Insurance Authorizations',
  help: <HelpTagIframe id="client_insurance_authorizations" />,
}

const Authorizations = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['client', resource_id, 'insurance_authorizations'],
    endpoint: `/residents/${resource_id}/insurance_authorizations`,
    enabled: !!resource_id,
    localStorageKey: 'client_insurance_authorizations_v1',
  })

  const actions = (
    <Button
      type="primary"
      label="+ Add Authorization"
      link={`${match.url}/new`}
      permission={isBehave || 'insurance_authorizations.create'}
    />
  )

  return (
    <Page feature="authorizations" actions={actions} help={<HelpTagIframe id="client_insurance_authorizations" />}>
      <PageLayout>
        <AuthorizationsDataTable to={(row: any) => `${match.url}/${row.id}`} {...tableProps} />
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(Authorizations, pageConfig))
