import React from 'react'
import startCase from 'lodash/startCase'

import { age } from '../../../utils/functions'
import { useUpdate } from '../../../hooks/useNewAPI'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { Text } from '../../Typography'
import AdmissionNavItems from '../../Navs/AdmissionNavItems'
import ApplicationStatus from '../../Statuses/ApplicationStatus'
import BillingStatus from '../../Statuses/BillingStatus'
import ClientPortalStatus from '../../Statuses/ClientPortalStatus'
import IntakeStatus from '../../Statuses/IntakeStatus'
import QuickViewOverlay from './QuickViewOverlay'
import TagsSelector from '../../TagsSelector'

const AdmissionQuickView = ({ client, onClose }: any) => {
  const { mutateAsync: updateClient }: any = useUpdate({
    name: ['client', client?.id],
    url: `/residents/${client?.id}`,
    invalidate: ['clients'],
    invalidateKeys: ['client'],
  })

  return (
    <QuickViewOverlay
      title={client.name}
      avatar={client.avatar}
      onClose={onClose}
      maxWidth={48}
      data={client}
      subheader={
        <>
          <Text description={`${startCase(client.sex) || '–'}, ${age(client.dob)} y/o, #${client.behave_id}`} />
          {client.is_billed_by_behave && <BillingStatus status="billed" small />}
          {/* {client.current_intake_application && <ApplicationStatus status={client.current_intake_application.status} />} */}
          {client.current_admission && <IntakeStatus status={client.current_admission?.status} />}
          {client.client_portal_status && <ClientPortalStatus status={client.client_portal_status} />}
        </>
      }
      headerAfter={
        <div className="!mt-3 w-full">
          <TagsSelector tags={client?.tags} onSave={(tagIDs: any) => updateClient({ tag_ids: tagIDs })} smartCategories="applicant" />
        </div>
      }
    >
      <AdmissionNavItems id={client.id} />
    </QuickViewOverlay>
  )
}

export default withOverlayError(AdmissionQuickView)
