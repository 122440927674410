import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { getClientLink } from '@behavehealth/utils/functions'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

export const ClinicalMeasuresDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        title: 'Client',
        model: 'client',
        formatValue: ({ value }) => {
          if (!value) return null

          return <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/clinical-measurements`} />
        },
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        width: 200,
        model: 'score',
        title: 'Score',
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ],
    [to],
  )

  return (
    <DataTable
      asCard
      title="Clinical Measures"
      icon="patient_progress_measure"
      columns={columns}
      filtersConfig={FILTERS.clinical_measurements}
      {...rest}
    />
  )
}
