import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Calendar, CalendarEvent, HelpTagIframe, Page, Grid } from '@behavehealth/components'

import CalendarPageActions from '../../../components/CalendarPageActions'

import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { FILTERS } from '@behavehealth/constructs/Filters/config'

type CalendarDates = {
  currentDate: string
  endDate: string
  startDate: string
}

type Props = {
  timezone?: string
  canCreate: boolean
}

const pageConfig = {
  feature: 'calendar',
  help: <HelpTagIframe id="calendar" />,
  marketingID: 'calendar',
}

const CalendarPage: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { timezone } = useSettings()
  const [dates, setDates] = React.useState<CalendarDates | null>(null)
  const [filters, setFilters] = React.useState({})

  const queryParams = {
    event_type: 'event',
    from: dates?.startDate,
    until: dates?.endDate,
    filters,
  }

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'events', queryParams],
    url: `/residents/${resource_id}/events`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: !!dates },
  })

  const { mutate: deleteEvents } = useDelete({
    name: ['client', resource_id, 'events'],
    url: '/events',
    invalidate: 'events',
  })

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  return (
    <Page
      breakpoint="0"
      actions={canCreate && <CalendarPageActions data={{ resident: client }} clientTrack={client?.client_track} />}
      {...pageConfig}
    >
      <Grid gap="1rem" columns="100%">
        <Filters config={FILTERS.events} onUpdate={setFilters} localStorageKey="calendar" />

        <Calendar
          events={data}
          isLoading={isLoading}
          timezone={timezone}
          localStorageKey="clients_calendar"
          renderEvent={(event: any) => <CalendarEvent event={event} timezone={timezone} link={`${match.url}/${event.id}`} />}
          to={(event: any) => `${match.url}/${event.id}`}
          onChange={setDates}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'events.delete',
              action: async ({ ids }: any) => {
                await deleteEvents(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CalendarPage, pageConfig))
