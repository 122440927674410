import React from 'react'
import { useParams } from 'react-router-dom'

import { Button, Page, PageLayout, SummonOverlay, HelpTagIframe } from '@behavehealth/components'
import { NewVOBOverlay } from '@behavehealth/constructs/VOB/NewVOBOverlay'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { VOBDataTable } from '@behavehealth/constructs/VOB/VOBDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Permission from '@behavehealth/components/Permission'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'full_vobs',
  title: 'VOBs',
}

const VOB = ({ match }: any) => {
  const { isBehave, isSelfServiceBilling } = useSettings()
  const { resource_id: clientId }: any = useParams()

  const tableProps = useDataTable({
    name: ['insurance_vobs'],
    endpoint: `/residents/${clientId}/insurance_vobs`,
    params: { version: 'v2' },
    localStorageKey: 'insurance_vobs_v2',
  })

  return (
    <Page
      icon="insurance"
      title="VOBs"
      actions={
        <Permission permission={isBehave ? true : isSelfServiceBilling ? 'full_vobs.create' : false} featureFlagV2="full_vobs">
          <SummonOverlay overlay={<NewVOBOverlay />}>
            <Button type="primary" color="blue" glyph="add" label="Add New VOB…" />
          </SummonOverlay>
        </Permission>
      }
      help={<HelpTagIframe id="client_behave_vobs" />}
    >
      <PageLayout>
        <VOBDataTable {...tableProps} to={(row: any) => `${match.url}/${row.id}`} />
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(VOB, pageConfig))
