import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import pluralize from 'pluralize'
import snakeCase from 'lodash/snakeCase'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import DataArray from '../../components/Forms/DataArray'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Label from '../../components/Label'
import Form from '../../components/Forms/Form'
import Nav from '../../components/Nav'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageGrid from '../../components/PageGrid'
import ScrollMenu from '../../components/ScrollMenu'
import ScrollView from '../../components/ScrollView'
import Section from '../../components/Section'
import { PlainSwitch } from '../../components/Forms/Switch'

import { AUTHORITY_FORMS } from './authority_forms'
import { AuthorityFormAssignmentSelector } from './AuthorityFormAssignmentSelector'
import { AuthorityFormGeneralSection } from './AuthorityFormGeneralSection'
import { AuthorityFormRenderer } from './AuthorityFormRenderer'
import { AuthorityFormStatus } from './AuthorityFormStatus'
import { FORM_STATUSES } from './constants'

const GET_TEMP_SCHEMA = (sections: any) => {
  const result: any = {}

  if (!sections) return result

  for (const section of sections) {
    result[section.model] = {
      show: true,
      fields: section.fields.reduce((acc: any, field: any) => {
        acc[field.model] = {
          show: true,
          required: field.required,
          attachments: true, // field.attachments,
        }

        return acc
      }, {}),
    }
  }

  return result
}

const RootAuthorityFormSubmission = (props: any) => {
  // const { category } = props

  const { slug } = useParams()
  const category = props.category || snakeCase(slug)

  const CONFIG = AUTHORITY_FORMS?.[category]

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'TODO',
    endpoint: '/TODO',
    invalidate: 'TODO',
    options: props,
  })

  const { tenant, timezone } = useSettings()

  const [isCommentsVisible, setIsCommentsVisible] = React.useState(true)

  const handleCommentsChange = () => setIsCommentsVisible((prev) => !prev)

  const authorityName = props.authorityName || tenant?.name

  if (!CONFIG?.sections) return null

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const { title, sections, isMultiSection } = CONFIG

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} maxWidth={100}>
      <Overlay.Header icon={CONFIG.icon} title={CONFIG.title} />

      <Overlay.SubHeader className="flex items-center justify-between flex-nowrap px-2 !py-1.5">
        <Flex alignItems="center" gap="1rem">
          <AuthorityFormStatus status="draft" statuses={FORM_STATUSES} />
          <AuthorityFormAssignmentSelector />
        </Flex>

        <div className="flex flex-nowrap items-center">
          <Label isCompact label="Show Comments" onClick={handleCommentsChange} className="!flex-[0_0_auto] cursor-pointer" />
          <PlainSwitch size={200} isChecked={isCommentsVisible} onCheckedChange={handleCommentsChange} />
        </div>
      </Overlay.SubHeader>

      <ScrollView>
        <PageGrid scroll breakpoint={3} className="overlay-page-grid flex-[1_1_auto]">
          <Nav
            top="0"
            breakpoint={3}
            title={' '}
            headingSize={300}
            desktopProps={{ title: null }}
            className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
          >
            <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
            <ScrollMenu />
          </Nav>

          <Overlay.Content>
            <Form
              isCompact
              getForm={form}
              initialModel={initialModel}
              isEditable={isEditable}
              timezone={timezone}
              onValidationUpdate={onValidationUpdate}
            >
              <AuthorityFormGeneralSection defaultName={title} isNew={isNew} />

              <Divider />

              {isMultiSection ? (
                <>
                  {sections.map((mainSection: any, index) => {
                    if (mainSection.isDataArray) {
                      return (
                        <React.Fragment key={mainSection.title}>
                          {index > 0 && <Divider />}

                          <Section
                            title={mainSection.title}
                            headingType="h2"
                            scrollview={{
                              id: mainSection.model,
                              name: mainSection.title,
                              parent: {
                                id: mainSection.model,
                                name: mainSection.title,
                              },
                            }}
                          >
                            <DataArray model={mainSection.model}>
                              {({ orderedIds, add, remove, isEditable }) => (
                                <div className="grid gap-4">
                                  {orderedIds?.map((id: string, idx: number) => (
                                    <Card key={id}>
                                      <CardHeader
                                        after={
                                          isEditable && (
                                            <Button
                                              type="minimal"
                                              color="red"
                                              glyph="delete"
                                              label="Remove"
                                              onClick={() => remove(id)}
                                              size={100}
                                            />
                                          )
                                        }
                                      >
                                        <div className="text-text-muted opacity-80 text-[0.85rem] uppercase font-[700] tracking-[1px]">
                                          {pluralize.singular(mainSection.title || 'Record')} #{idx + 1}
                                        </div>
                                      </CardHeader>

                                      <div className="px-3 pb-4">
                                        <AuthorityFormRenderer
                                          isCommentsVisible={isCommentsVisible}
                                          setIsCommentsVisible={setIsCommentsVisible}
                                          canUpload
                                          enableComments={isCommentsVisible}
                                          authorityName={authorityName}
                                          parentSection={mainSection}
                                          sections={mainSection.sections}
                                          schema={GET_TEMP_SCHEMA(mainSection.sections)}
                                        />
                                      </div>
                                    </Card>
                                  ))}

                                  {isEditable && (
                                    <div>
                                      <Button
                                        label={`Add New ${pluralize.singular(mainSection.title || 'Record')}`}
                                        glyph="add"
                                        type="primary"
                                        display="inline-flex"
                                        size={200}
                                        onClick={add}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </DataArray>
                          </Section>
                        </React.Fragment>
                      )
                    }

                    return (
                      <React.Fragment key={mainSection.title}>
                        {index > 0 && <Divider />}

                        <Section title={mainSection.title} headingType="h2">
                          <AuthorityFormRenderer
                            isCommentsVisible={isCommentsVisible}
                            setIsCommentsVisible={setIsCommentsVisible}
                            canUpload
                            enableComments={isCommentsVisible}
                            authorityName={authorityName}
                            useScrollView
                            parentSection={mainSection}
                            sections={mainSection.sections}
                            schema={GET_TEMP_SCHEMA(mainSection.sections)}
                          />
                        </Section>
                      </React.Fragment>
                    )
                  })}
                </>
              ) : (
                <AuthorityFormRenderer
                  isCommentsVisible={isCommentsVisible}
                  setIsCommentsVisible={setIsCommentsVisible}
                  canUpload
                  enableComments={isCommentsVisible}
                  authorityName={authorityName}
                  useScrollView
                  sections={sections}
                  schema={GET_TEMP_SCHEMA(sections)}
                />
              )}
            </Form>
          </Overlay.Content>
        </PageGrid>
      </ScrollView>

      <Overlay.Footer withGradient={false}>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              // permission="__PERMISSION__.edit"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit __RECORD__NAME__"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              // permission="__PERMISSION__.edit"
            />

            <DeleteDialog
              title="Delete __RECORD__NAME__?"
              message="Are you sure you want to delete this __RECORD__NAME__? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                // permission="__PERMISSION__.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AuthorityFormSubmission = withOverlayError(RootAuthorityFormSubmission)
