import React from 'react'

import StaffProfileHeader from '../../components/StaffProfileHeader'
import Flex from '../../components/Flex'
import OverlaySubHeader from '../../components/Overlay/OverlaySubHeader'

export const StaffWorkflowSubheader = ({ employee }) => {
  if (!employee) return null

  return (
    <OverlaySubHeader className="grid gap-2 !py-2 shadow-hard-2 relative z-[3]">
      <Flex gap="1rem" alignItems="center">
        <div>
          <StaffProfileHeader employee={employee} />
        </div>
      </Flex>
    </OverlaySubHeader>
  )
}
