import React from 'react'
import { NavLink, useParams } from 'react-router-dom-v5-compat'

import { Button, Page, Grid } from '@behavehealth/components'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'programs',
  title: 'Programs',
}

type Props = {
  canCreate: boolean
}

export const Programs: React.FC<Props> = () => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['organizations', resource_id, 'organization_programs'],
    endpoint: `/organizations/${resource_id}/organization_programs`,
    enabled: !!resource_id,
    localStorageKey: 'organization_programs_v1',
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} as={NavLink} value={data.name} avatar={data.avatar} to={data.id} />,
      },
      {
        width: 160,
        title: 'Email',
        model: 'email',
        type: 'email',
      },
      {
        width: 160,
        title: 'Phone',
        model: 'phone_no',
        type: 'phone',
      },
      {
        width: 160,
        title: '# Locations',
        model: 'organization_locations_count',
        type: 'number',
      },
      {
        width: 220,
        title: 'Gender Served',
        model: 'gender',
        type: 'title',
      },
      {
        width: 220,
        title: 'Used Capacity',
        model: 'capacity_used',
      },
      {
        width: 220,
        title: 'Total Capacity',
        model: 'capacity_max',
      },
      {
        title: 'Address',
        model: 'address',
        type: 'address',
        disableSort: true,
        width: 280,
      },
    ]
  }, [])

  return (
    <Page actions={<Button as={NavLink} label="Add New Program" type="primary" glyph="add" link="new" />} {...pageConfig}>
      <Grid>
        <DataTable asCard title="Programs" icon="programs" columns={columns} {...tableProps} />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Programs, pageConfig))
