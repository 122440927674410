import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'
import URLInput from '../../components/Forms/URLInput'

import { ORGANIZATIONS_TYPE_VENDORS, ORGANIZATIONS_TYPE_RESOURCES, ORGANIZATIONS_TYPE_PROVIDERS } from '../../utils/constants'

const RootNewOrganizationOverlay = (props: any) => {
  const { close, form, isInvalid, isOverlayLoading, isSaving, onValidationUpdate, save, initialModel } = useOverlay({
    name: 'organizations',
    endpoint: '/organizations',
    invalidate: 'organizations',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop>
      <Overlay.Header icon="organizations" title="Create Organization" help={<HelpTagIframe id="add_organization" />} />

      <Overlay.Content>
        <Form getForm={form} timezone={timezone} onValidationUpdate={onValidationUpdate}>
          <Grid gap="0.5rem">
            <Section>
              <FormSection layout="vertical">
                <Input
                  autoFocus
                  label="Name"
                  model="name"
                  defaultValue={initialModel?.name}
                  validations={{
                    presence: {
                      message: 'Please enter an organization name',
                    },
                  }}
                />

                <Select
                  label="Type"
                  model="category"
                  defaultValue="vendor"
                  validations={{
                    presence: {
                      message: 'Please select an organization type',
                    },
                  }}
                >
                  <Option label="Vendor" value="vendor" />
                  <Option label="Community Resource" value="resource" />
                  <Option label="Provider" value="provider" />
                </Select>

                <ContextShow when="category" is="vendor">
                  <Select
                    label="Category"
                    model="subcategory"
                    defaultValue="billing_service"
                    validations={{
                      presence: {
                        message: 'Please select an organization category',
                      },
                    }}
                  >
                    {Object.keys(ORGANIZATIONS_TYPE_VENDORS).map((vendor) => (
                      <Option label={ORGANIZATIONS_TYPE_VENDORS[vendor]} value={vendor} />
                    ))}
                  </Select>
                </ContextShow>

                <ContextShow when="category" within="resource">
                  <Select
                    label="Category"
                    model="subcategory"
                    defaultValue="general_resource"
                    validations={{
                      presence: {
                        message: 'Please select an organization category',
                      },
                    }}
                  >
                    {Object.keys(ORGANIZATIONS_TYPE_RESOURCES).map((resource) => (
                      <Option label={ORGANIZATIONS_TYPE_RESOURCES[resource]} value={resource} />
                    ))}
                  </Select>
                </ContextShow>

                <ContextShow when="category" within="provider">
                  <Select
                    label="Category"
                    model="subcategory"
                    defaultValue="sober_living_home"
                    validations={{
                      presence: {
                        message: 'Please select an organization category',
                      },
                    }}
                  >
                    {Object.keys(ORGANIZATIONS_TYPE_PROVIDERS).map((provider) => (
                      <Option label={ORGANIZATIONS_TYPE_PROVIDERS[provider]} value={provider} />
                    ))}
                  </Select>
                </ContextShow>

                <EmailInput label="Email Address" model="email" />

                <Flex horizontal gap="1rem">
                  <PhoneInput css={{ flex: '1 1 auto' }} model="phone_no" label="Phone Number" type="tel" />
                  <Input css={{ width: 145 }} glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />
                </Flex>

                <Input glyph="fax" label="Fax" model="fax" />

                <Textarea useQuickText model="additional_info" label="Notes" />
              </FormSection>
            </Section>

            <Section title="Address">
              <FormSection>
                <AddressSelectorInput actionLabel="Find Address" model="address" />
              </FormSection>
            </Section>

            <Section title="Links & Meetings">
              <FormSection layout="vertical">
                <URLInput label="Website" model="website_url" glyph="website" />

                <Grid vertical gap="1rem" columns="1fr 1fr 1fr">
                  <URLInput glyph="facebook" label="Facebook" model="facebook" />
                  <URLInput glyph="instagram" label="Instagram" model="instagram" />
                  <URLInput glyph="googleplus" label="Google Business" model="google_business" />
                </Grid>

                <Grid vertical gap="1rem" columns="1fr 1fr">
                  <URLInput glyph="whatsapp" label="WhatApp" model="whatsapp" />
                  <URLInput glyph="zoom" label="Zoom" model="zoom" />
                </Grid>

                <Grid vertical gap="1rem" columns="1fr 1fr">
                  <URLInput glyph="googleplus" label="Google Meet" model="google_meet" />
                  <URLInput glyph="skype" label="Skype" model="skype" />
                </Grid>
              </FormSection>
            </Section>
          </Grid>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Create Organization" type="primary" color="green" isLoading={isSaving} onClick={save} isDisabled={isInvalid} />
      </Overlay.Footer>
    </Overlay>
  )
}

export const NewOrganizationOverlay = withOverlayError(RootNewOrganizationOverlay)
