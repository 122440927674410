import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { countWord, arrayToMapWithKey } from '../../utils/functions'
import { COLORS } from '../../theme'

import Button from '../Button'
import Flex from '../Flex'
import Search from '../Search'
import Label from '../Label'
import Switch from '../Forms/Switch'
import Permission from '../Permission'

import { DataTableFilters } from './DataTableFilters'
import { DataTableSorting } from './DataTableSorting'
import { DataTableColumns } from './DataTableColumns'
import snakeCase from 'lodash/snakeCase'

import { useStore } from './useStore'

import { HEADER_FILTERS } from './headerFilters'

const DEFAULT_LINK_TYPES: any = {
  report: {
    glyph: 'documents',
    color: 'blue',
    label: 'View Full Report →',
  },
}

export const DataTableMainHeader = (props: any) => {
  const {
    after,
    glyph,
    graphic,
    icon,
    isEmpty,
    isFiltersOpen,
    isLoading,
    isRefetching,
    isSortingOpen,
    onFiltersUpdate,
    refetch,
    searchBarFilterKey,
    setIsFiltersOpen,
    setIsSortingOpen,
    title,
    columnFiltersEnabled,
    aside,
  } = props

  const addFilter: any = useStore((state: any) => state.addFilter)
  const filters: any = useStore((state: any) => state.filters)
  const filtersVisible: any = useStore((state: any) => state.filtersVisible)
  const help: any = useStore((state: any) => state.help)
  const meta: any = useStore((state: any) => state.meta)
  const removeFilter: any = useStore((state: any) => state.removeFilter)
  const selectedIds: any = useStore((state: any) => state.selectedIds)
  const setFilter: any = useStore((state: any) => state.setFilter)
  const setFiltersVisible: any = useStore((state: any) => state.setFiltersVisible)

  const filtersMap = React.useMemo(() => {
    return arrayToMapWithKey(filters, 'key')
  }, [filters])

  const selectedCount = size(selectedIds)
  const isSomeSelected = selectedCount >= 1

  return (
    <header css={STYLES.header}>
      {/* {(icon || graphic || glyph) && (
        <div className={STYLES.headerGraphic()}>
          {glyph && <Glyph glyph={glyph} size={18} />}
          {icon && <Icon icon={icon} size={18} />}
          {graphic}
        </div>
      )} */}

      <div css={STYLES.headerContent}>
        <div css={STYLES.headerTitle}>
          {isSomeSelected && (
            <>
              {countWord(title, selectedCount)}
              <span style={{ fontWeight: 400, fontSize: '0.92em', color: COLORS.textMuted }}> Selected</span>
            </>
          )}

          {!isSomeSelected && (
            <span data-test={`${snakeCase(title)}_counter`}>
              {''}
              {isLoading ? `${title}…` : isEmpty ? title : countWord(title, meta?.count)}
            </span>
          )}
        </div>

        {/* {isRefetching && (
          <div css={{ margin: '0 0.75rem' }}>
            <Loader size={15} />
          </div>
        )} */}

        <Flex centerY gap="0.25rem">
          {!isLoading && (
            <>
              <DataTableFilters onFiltersUpdate={onFiltersUpdate} isFiltersOpen={isFiltersOpen} setIsFiltersOpen={setIsFiltersOpen} />

              {columnFiltersEnabled && (
                <Flex nowrap centerY>
                  <Switch
                    isEditable
                    testKey="show_filter_switch"
                    value={filtersVisible}
                    onUpdate={({ value }: any) => setFiltersVisible(value)}
                    labelWidth="auto"
                    label={null}
                    withHover={false}
                    size={100}
                  />
                  <Label label="Show Filters" className="ml-2" />
                </Flex>
              )}

              <DataTableSorting isSortingOpen={isSortingOpen} setIsSortingOpen={setIsSortingOpen} />

              <DataTableColumns testKey="header" />
            </>
          )}

          {searchBarFilterKey && (
            <Search
              graphicWidth="32px"
              graphicSize="18px"
              css={STYLES.search}
              value={filtersMap?.hasOwnProperty(searchBarFilterKey) ? filtersMap[searchBarFilterKey].value : ''}
              onChange={(searchValue: string) => {
                // clear filter
                if (!searchValue) {
                  removeFilter(searchBarFilterKey)
                }
                // add new filter
                else if (!filtersMap?.hasOwnProperty(searchBarFilterKey)) {
                  addFilter({ key: searchBarFilterKey, value: searchValue, condition: 'contains' })
                }
                // set existing filter
                else if (filtersMap?.hasOwnProperty(searchBarFilterKey) && filtersMap[searchBarFilterKey].value !== searchValue) {
                  setFilter(searchBarFilterKey, { value: searchValue })
                  return
                }
              }}
            />
          )}

          {after}
        </Flex>

        <HeaderLinks />

        <div className="!flex-auto" />

        {(refetch || aside) && (
          <Flex gap="0.75rem">
            <Button
              testKey="refresh_data_table_button"
              label="Refresh"
              glyph="reset"
              type="default"
              display="inline-flex"
              size={100}
              onClick={refetch}
              isLoading={isRefetching}
              css={{ margin: '0 0.5rem' }}
            />

            {aside}
          </Flex>
        )}

        {help}
      </div>
    </header>
  )
}

const HeaderLinks = () => {
  const headerLinksConfig: any = useStore((state: any) => state.headerLinksConfig)
  const isEmpty = size(headerLinksConfig) === 0

  if (isEmpty) return null

  return (
    <>
      <div css={STYLES.verticalSeparator} />

      <Flex centerY gap="0.25rem">
        {headerLinksConfig.map((link: any) => {
          const config = DEFAULT_LINK_TYPES[link.type]

          if (size(link) === 0 && size(config) === 0) return null

          return (
            <Button
              testKey="view_report_button"
              label={link?.label || config?.label}
              glyph={link?.glyph || config?.glyph}
              size={100}
              type={link?.type || 'minimal'}
              link={link.to}
            />
          )
        })}
      </Flex>
    </>
  )
}

const STYLES = {
  header: {
    display: 'flex',
    minHeight: 'calc(var(--row-min-height) + 0.75rem)',
    flex: '0 0 auto',
    background: COLORS.white,
    padding: '0.25rem 0',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  headerGraphic: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'center',
    width: 'var(--graphic-width)',
  },

  headerContent: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 1 auto',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    flexWrap: 'wrap',
  },

  headerTitle: {
    fontWeight: 600,
    fontSize: '1rem',
    marginRight: '0.5rem',
  },

  verticalSeparator: {
    width: 1,
    height: 14,
    background: COLORS.textMuted,
    opacity: 0.25,
    borderRadius: '50%',
    margin: '0 0.15rem',
  },

  search: {
    height: 28,
    minHeight: 28,
    width: 200,
    position: 'relative',
    zIndex: 3,
  },
}
