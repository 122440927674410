import {
  BASIC_INFORMATION,
  CONTACT,
  CERTIFICATIONS,
  DEMOGRAPHICS,
  FACILITY_DETAILS,
  FINANCIALS,
  POLICIES,
  TREATMENT,
  MARKETING,
  PROGRAM_OPERATIONS,
  TREATMENT_DETAILS,
  DOCUMENTATION,
  MEDICATION_MANAGEMENT,
  STAFF_DEVELOPMENT,
} from './constants'

export const DIRECTORIES_DATA = {
  ...BASIC_INFORMATION,
  ...CONTACT,
  ...CERTIFICATIONS,
  ...DEMOGRAPHICS,
  ...FACILITY_DETAILS,
  ...FINANCIALS,
  ...POLICIES,
  ...TREATMENT,
  ...MARKETING,
  ...PROGRAM_OPERATIONS,
  ...TREATMENT_DETAILS,
  ...DOCUMENTATION,
  ...MEDICATION_MANAGEMENT,
  ...STAFF_DEVELOPMENT,
} as const
