import React from 'react'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import uniq from 'lodash/uniq'

import { titleCase, addressMultiLine, usDate, usDateTime } from '../../../../utils/functions'
import withSettings from '../../../../hocs/withSettings'
import { CLEARING_HOUSES } from '../../../../utils/constants'

import Card from '../../../Card'
import CardTreeItem from '../../../CardTreeItem'
import DataList from '../../../DataList'
import EVOBStatus from '../../../Statuses/EVOBStatus'
import Grid from '../../../Grid'
import Section from '../../../Section'
import State from '../../../State'
import Status from '../../../Status'
import Tabs from '../../../Tabs'
import Text from '../../../Typography/Text'

import { CoInsuranceTable } from '../../../../constructs/EVOB/tables/CoInsuranceTable'
import { CopayTable } from '../../../../constructs/EVOB/tables/CopayTable'
import { DeductibleTable } from '../../../../constructs/EVOB/tables/DeductibleTable'
import { LimitationsTable } from '../../../../constructs/EVOB/tables/LimitationsTable'
import { OutOfPocketTable } from '../../../../constructs/EVOB/tables/OutOfPocketTable'

const processData = (data: any) => {
  if (size(data) === 0) return {}

  const summary = data.insurance_eligibility_summary
  const credential = data?.credential
  const payer = data.insurance_local_payer
  const benefits = summary?.response?.benefitsInformation
  const plan = data.insurance_policy
  const allServiceTypes = []

  const patientInfo = data.insurance_eligibility_summary?.response?.dependents?.[0]
  const subscriberInfo = data.insurance_eligibility_summary?.response?.subscriber

  const _copay = []
  const _coinsurance = []
  const _deductible = []
  const _out_of_pocket = []
  const _limitations = []
  const _other = []

  if (benefits) {
    for (const benefit of benefits) {
      if (benefit.serviceTypes) {
        allServiceTypes.push(...benefit.serviceTypes)
      }

      if (benefit.name === 'Co-Payment') {
        _copay.push(benefit)
      } else if (benefit.name === 'Co-Insurance') {
        _coinsurance.push(benefit)
      } else if (benefit.name === 'Deductible') {
        _deductible.push(benefit)
      } else if (benefit.name === 'Out of Pocket (Stop Loss)') {
        _out_of_pocket.push(benefit)
      } else if (benefit.name === 'Limitations') {
        _limitations.push(benefit)
      } else {
        _other.push(benefit)
      }
    }
  }

  const groupByService = (arr) => {
    const allServices = []

    for (const item of arr) {
      if (item.serviceTypes) {
        allServices.push(...item.serviceTypes)
      }
    }

    const uniqueServices: any = sortBy(uniq(allServices))
    const services: any = {}

    for (const service of uniqueServices) {
      services[service] = []
    }

    for (const item of arr) {
      if (item.serviceTypes) {
        for (const itemService of item.serviceTypes) {
          services[itemService].push(item)
        }
      }
    }

    return services
  }

  const copay = groupByService(_copay)
  const coinsurance = groupByService(_coinsurance)
  const deductible = groupByService(_deductible)
  const out_of_pocket = groupByService(_out_of_pocket)
  const limitations = groupByService(_limitations)
  const other = groupByService(_other)

  return {
    benefits,
    coinsurance,
    copay,
    credential,
    deductible,
    limitations,
    out_of_pocket,
    patientInfo,
    payer,
    plan,
    subscriberInfo,
    summary,
  }
}

const ChangeHealthcare = ({ data, timezone }: any) => {
  if (size(data) === 0) return <State isLoading />

  const {
    benefits,
    coinsurance,
    copay,
    credential,
    deductible,
    limitations,
    out_of_pocket,
    patientInfo,
    payer,
    plan,
    subscriberInfo,
    summary,
  } = processData(data)

  return (
    <>
      <Section>
        <Grid gap="0.75rem">
          <>
            <DataList labelWidth={180} className="text-[0.95rem]">
              <DataList.Item label="Date Requested:" value={usDateTime(data.created_at, timezone)} />
              <DataList.Item label="Clearing House:" value={CLEARING_HOUSES?.[data.clearing_house]} />
              <DataList.Item label="eVOB Status:" value={<EVOBStatus status={data.status} />} />
              <DataList.Item label="Service Codes:" value={data.service_codes?.join(', ') || '–'} />
              <DataList.Item label="Provider NPI:" value={data?.credential?.license_number} />
              <DataList.Item label="Provider Type:" value={startCase(data?.credential?.level)} />
              {data?.credential?.level === 'organization' && (
                <DataList.Item label="Provider Organization Name:" value={data?.credential?.organization} />
              )}
              {data?.credential?.level === 'individual' && (
                <>
                  <DataList.Item label="Provider First Name:" value={data?.credential?.first_name} />
                  <DataList.Item label="Provider Last Name:" value={data?.credential?.last_name} />
                </>
              )}
              <DataList.Item
                label="Error Messages:"
                value={
                  <Grid gap="0.5rem">
                    {data?.insurance_eligibility_summary?.errors?.map((error: any) => (
                      <div>
                        <div>
                          {error.code} {error.location} {error.description}
                        </div>
                        <div>{error.possibleResolutions}</div>
                      </div>
                    ))}
                  </Grid>
                }
              />
            </DataList>
          </>

          <div css={styles.twoColumnGrid}>
            <CardTreeItem title="Provider" css={styles.cardTreeitem}>
              <DataList>
                <DataList.Item label="ID Type" value={credential?.credential} />
                <DataList.Item label="ID" value={credential?.license_number} />
                {credential?.organization ? (
                  <>
                    <DataList.Item label="Organization Name" value={credential?.organization} />
                  </>
                ) : (
                  <>
                    <DataList.Item label="First Name" value={credential?.first_name} />
                    <DataList.Item label="Last Name" value={credential?.last_name} />
                  </>
                )}
              </DataList>
            </CardTreeItem>

            <CardTreeItem title="Payer" css={styles.cardTreeitem}>
              <DataList>
                <DataList.Item label="Name" value={payer?.name} />
                <DataList.Item label="Status" value={titleCase(payer?.status)} />
                <DataList.Item label="Category" value={payer?.category} />
                <DataList.Item label="Website" value={payer?.website} />
                <DataList.Item label="Notes" value={payer?.notes} />
                <DataList.Item label="Open Time" value={payer?.open_time} />
                <DataList.Item label="Close Time" value={payer?.close_time} />
                <DataList.Item label="Clearing House" value={titleCase(payer?.clearing_house)} />
                <DataList.Item
                  label="Supports"
                  value={
                    <Grid gap="0.25rem">
                      <Text glyph={payer?.supports.claims ? 'check' : 'cross'} children="Claims" />
                      <Text glyph={payer?.supports.remittance ? 'check' : 'cross'} children="Remittance" />
                      <Text glyph={payer?.supports.attachments ? 'check' : 'cross'} children="Attachments" />
                      <Text glyph={payer?.supports.eligibility ? 'check' : 'cross'} children="Eligibility" />
                      <Text glyph={payer?.supports.claim_status ? 'check' : 'cross'} children="Claim Status" />
                    </Grid>
                  }
                />
              </DataList>
            </CardTreeItem>
          </div>

          <div css={styles.twoColumnGrid}>
            <CardTreeItem title="Subscriber" css={styles.cardTreeitem}>
              <DataList>
                <DataList.Item label="Name" value={summary?.subscriber_details?.name} />
                <DataList.Item label="Date of Birth" value={summary?.subscriber_details?.dob} />
                <DataList.Item label="Member ID" value={summary?.subscriber_details?.member_id} />
                <DataList.Item label="Group Number" value={summary?.subscriber_details?.group_number} />
                <DataList.Item label="Plan Number" value={summary?.subscriber_details?.plan_number} />
                <DataList.Item label="Address" value={addressMultiLine(summary?.subscriber_details?.address)} />
              </DataList>
            </CardTreeItem>

            <CardTreeItem title="Dependent" css={styles.cardTreeitem}>
              <DataList>
                <DataList.Item label="Name" value={summary?.dependent_details?.name} />
                <DataList.Item label="Date of Birth" value={summary?.dependent_details?.dob} />
                <DataList.Item label="Group Number" value={summary?.dependent_details?.group_number} />
                <DataList.Item label="Insured Indicator" value={summary?.dependent_details?.insured_indicator} />
                <DataList.Item label="Member ID" value={summary?.dependent_details?.member_id} />
                <DataList.Item label="Plan Number" value={summary?.dependent_details?.plan_number} />
                <DataList.Item label="Relation to Subscriber" value={summary?.dependent_details?.relation_to_subscriber} />
                <DataList.Item label="Address" value={addressMultiLine(summary?.dependent_details?.address)} />
              </DataList>
            </CardTreeItem>
          </div>

          <CardTreeItem isOpen title="Demographics" css={styles.cardTreeitem}>
            <div css={styles.demographics}>
              <DataList>
                <Status label="Patient Information" color="blue" />
                <DataList.Item
                  label="Relationship"
                  value={
                    patientInfo?.relationToSubscriberCode &&
                    patientInfo?.relationToSubscriber &&
                    `${patientInfo?.relationToSubscriberCode} – ${patientInfo?.relationToSubscriber}`
                  }
                />
                <DataList.Item label="First Name" value={patientInfo?.firstName} />
                <DataList.Item label="Middle Name" value={patientInfo?.middleName} />
                <DataList.Item label="Last Name" value={patientInfo?.lastName} />
                <DataList.Item label="SSN" value={patientInfo?.ssn} />
                <DataList.Item label="Date of Birth" value={null} /> {/* TODO */}
                <DataList.Item label="Sex" value={patientInfo?.gender} />
                <DataList.Item label="Street" value={patientInfo?.address?.address1} />
                <DataList.Item
                  label="City State Zip"
                  value={
                    patientInfo?.address && `${patientInfo.address.city} ${patientInfo.address.state}\n${patientInfo.address.postalCode}`
                  }
                />
                <DataList.Item label="Eligibility Begin Date" value={``} /> {/* TODO */}
                <DataList.Item label="Eligibility End Date" value={``} /> {/* TODO */}
              </DataList>

              <DataList>
                <Status label="Subscriber Information" color="green" />
                <DataList.Item label="First Name" value={subscriberInfo?.firstName} />
                <DataList.Item label="Middle Name" value={subscriberInfo?.middleName} />
                <DataList.Item label="Last Name" value={subscriberInfo?.lastName} />
                <DataList.Item label="Member ID" value={subscriberInfo?.memberId} />
                <DataList.Item label="SSN" value={subscriberInfo?.ssn} />
                <DataList.Item label="Date of Birth" value={``} />
                <DataList.Item label="Sex" value={subscriberInfo?.gender} />
                <DataList.Item label="Street" value={subscriberInfo?.address?.address1} />
                <DataList.Item
                  label="City State Zip"
                  value={
                    subscriberInfo?.address &&
                    `${subscriberInfo.address?.city} ${subscriberInfo.address?.state}\n${subscriberInfo.address?.postalCode}`
                  }
                />
                <DataList.Item label="Eligibility Begin Date" value={``} />
                <DataList.Item label="Eligibility End Date" value={``} />
              </DataList>

              <DataList>
                <Status label="Plan Detail Information" color="orange" />
                <DataList.Item label="Plan Name" value={plan?.plan} />
                <DataList.Item label="Plan Number" value={plan?.member_id} />
                <DataList.Item label="Plan Begin Date" value={usDate(plan?.effective_at, timezone)} />
                <DataList.Item label="Plan End Date" value={usDate(plan?.terminates_at, timezone)} />
                <DataList.Item label="Group Name" value={plan?.group_name} />
                <DataList.Item label="Group Number" value={plan?.group_id} />
              </DataList>
            </div>
          </CardTreeItem>

          {!benefits && (
            <Card>
              <State isEmpty icon="financials" title="Eligibility" emptyDescription="No eligibility data to display" />
            </Card>
          )}

          {benefits && (
            <CardTreeItem isOpen title="Eligibility">
              <Tabs defaultTab="copay" css={styles.lists}>
                <Tabs.List className="!mb-4">
                  <Tabs.Item label={`Copay`} name="copay" />
                  <Tabs.Item label={`Coinsurance`} name="coinsurance" />
                  <Tabs.Item label={`Deductible`} name="deductible" />
                  <Tabs.Item label={`Out of Pocket`} name="out_of_pocket" />
                  <Tabs.Item label={`Limitations`} name="limitations" />
                </Tabs.List>

                <Tabs.Panels>
                  <Tabs.Panel name="copay">
                    <CopayTable data={copay} />
                  </Tabs.Panel>

                  <Tabs.Panel name="coinsurance">
                    <CoInsuranceTable data={coinsurance} />
                  </Tabs.Panel>

                  <Tabs.Panel name="deductible">
                    <DeductibleTable data={deductible} />
                  </Tabs.Panel>

                  <Tabs.Panel name="out_of_pocket">
                    <OutOfPocketTable data={out_of_pocket} />
                  </Tabs.Panel>

                  <Tabs.Panel name="limitations">
                    <LimitationsTable data={limitations} />
                  </Tabs.Panel>
                </Tabs.Panels>
              </Tabs>
            </CardTreeItem>
          )}
        </Grid>
      </Section>
    </>
  )
}

const styles = {
  lists: {
    paddingRight: '1rem',

    ul: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },

    li: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },

    'ul::before': {
      display: 'none !important',
    },
  },

  twoColumnGrid: {
    display: 'grid',
    gridGap: '0.75rem',

    '@media (min-width: 800px)': {
      gridTemplateColumns: '1fr 1fr',
    },
  },

  cardTreeitem: {
    background: 'rgba(250,250,252,0.96) !important',

    button: {
      background: 'white',
    },
  },

  demographics: {
    // columns="1fr 1fr 1fr" gap="1rem" className="!pt-4"
    display: 'grid',
    gridGap: '1rem',
    paddingTop: '1rem',

    '@media (min-width: 1000px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
}

export default withSettings(ChangeHealthcare)
