import React from 'react'
import { tint } from 'polished'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { formatURL } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

import Avatar from '../../components/Avatar'
import Card from '../../components/Card'
import Glyph from '../../components/Glyph'
import GridTable from '../../components/GridTable'
import Label from '../../components/Label'
import Search from '../../components/Search'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'

import { CommunityMapFeatureOverlay } from '../Community/CommunityMapFeatureOverlay'
import { SearchHighlight } from '../Search/SearchHighlight'
import { SearchResultContext } from '../Search/context'

const RootNewCommunityOrganizationOverlay = (props: any) => {
  const { close } = useOverlay({
    name: 'organizations',
    endpoint: '/organizations',
    invalidate: 'organizations',
    options: props,
  })

  const match = useRouteMatch()

  const [query, setQuery] = React.useState('')
  const [selected, setSelected] = React.useState<any>(null)

  const searchQueryParams = {
    q: query,
    entity_type: 'organization',
    items: 25,
  }

  const canSearch = size(query) > 1

  const {
    data: searchResults,
    isLoading: isSearchLoading,
    isRefetching: isSearchRefetching,
  } = useGet({
    name: ['community-entities-search', searchQueryParams],
    url: '/community/entities/search',
    params: searchQueryParams,
    options: {
      enabled: canSearch,
      keepPreviousData: true,
    },
  })

  const { data: organizations } = useGet({
    name: ['organizations', 'all'],
    url: '/organizations',
  })

  const importedEntityIds = React.useMemo(() => {
    const result: any = []

    if (!organizations) return result

    for (const organization of organizations) {
      if (organization.community_profile?.external_id) {
        result.push(organization.community_profile.external_id)
      }
    }

    return result
  }, [organizations])

  const isSearchEmpty = size(searchResults?.results) === 0

  return (
    <>
      <Overlay showBackdrop closeOnBackdrop position="center" onClose={close} maxWidth={70}>
        <Overlay.Header icon="community" title="Import Community Organization" />

        <Overlay.Content>
          <Section>
            <Label isCompact label="Search Businesses by Name or Address" />
            <Search
              autoFocus
              showLoader
              placeholder="Search…"
              debounce={150}
              value={query}
              onChange={setQuery}
              glyphColor="gray"
              isLoading={isSearchLoading || isSearchRefetching}
            />

            {canSearch && !isSearchEmpty && (
              <Alert contrast glyph="info" className="mt-3">
                Click on a business name to view details and import
              </Alert>
            )}

            <Card className="mt-3 relative z-0">
              {isSearchLoading ? (
                <State key="loading-data-state" isLoading minHeight={160} />
              ) : !canSearch ? (
                <State
                  key="empty-search-state"
                  isEmpty
                  glyph="search"
                  emptyDescription={
                    <>
                      <div>Search Community Organizations</div>
                      <Button
                        label="Or Explore Map →"
                        glyph="map"
                        link="/community/explore"
                        size={200}
                        display="inline-flex"
                        className="mt-4"
                        type="minimal"
                      />
                    </>
                  }
                  minHeight={160}
                />
              ) : isSearchEmpty ? (
                <State
                  key="empty-data-state"
                  isEmpty
                  glyph="search"
                  emptyDescription={
                    <>
                      <div className="text-text font-[600] text-[1rem]">No search results found for "{query}"</div>
                      <div>Try another search or add the organization manually</div>
                    </>
                  }
                  minHeight={160}
                  // emptyActions={
                  //   <Button
                  //     label="Add Organization Manually"
                  //     glyph="add"
                  //     type="primary"
                  //     link={{
                  //       pathname: match.url.replace('import-new', 'create-new'),
                  //       // parent: match,
                  //       data: { name: query },
                  //     }}
                  //   />
                  // }
                />
              ) : (
                <GridTable
                  templateColumns="minmax(240px, 1fr) 150px 150px repeat(2, 120px) 70px"
                  className="text-[0.9rem]"
                  useBanding={false}
                  // useRowHover={false}
                >
                  <GridTable.Header>
                    <GridTable.Cell>Name</GridTable.Cell>
                    <GridTable.Cell>Website</GridTable.Cell>
                    <GridTable.Cell>Address Line 1</GridTable.Cell>
                    <GridTable.Cell>City</GridTable.Cell>
                    <GridTable.Cell>County</GridTable.Cell>
                    <GridTable.Cell>State</GridTable.Cell>
                  </GridTable.Header>

                  {searchResults?.results?.map?.((result: any, index: number) => {
                    const entity = result.document
                    const isImported = importedEntityIds.includes(entity.external_id)

                    return (
                      <SearchResultContext.Provider
                        key={entity.external_id}
                        value={{ document: result.document, highlights: result.highlights }}
                      >
                        <GridTable.Row>
                          <GridTable.Cell className="relative" css={STYLES.mainCell}>
                            <div
                              css={STYLES.searchNavLink}
                              className={selected?.slug === entity.slug ? 'is-active' : ''}
                              onClick={() => {
                                setSelected(entity)
                              }}
                            >
                              <Avatar initials={entity?.name} size={20} src="" className="mr-1.5" />
                              <SearchHighlight fieldName="name" className="truncate" />
                              {isImported && (
                                <div className="ml-auto pl-1.5">
                                  <Status small glyph="tick_circle" color="green" label="Imported" />
                                </div>
                              )}
                            </div>
                          </GridTable.Cell>

                          <TruncatedGridCell className="truncate">
                            {entity.domain && (
                              <a
                                href={formatURL(entity.domain)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center text-blue-500"
                              >
                                <SearchHighlight fieldName="domain" className="truncate" />
                                <div className="ml-auto pl-1">
                                  <Glyph glyph="external_link" size={12} color={COLORS.blue} />
                                </div>
                              </a>
                            )}
                          </TruncatedGridCell>

                          <TruncatedGridCell className="truncate">
                            {entity.primary_location_address_line_1 && <SearchHighlight fieldName="primary_location_address_line_1" />}
                          </TruncatedGridCell>

                          <TruncatedGridCell className="truncate">
                            {entity.primary_location_city && <SearchHighlight fieldName="primary_location_city" />}
                          </TruncatedGridCell>

                          <TruncatedGridCell className="truncate">
                            {entity.primary_location_county && <SearchHighlight fieldName="primary_location_county" />}
                          </TruncatedGridCell>

                          <TruncatedGridCell className="truncate">
                            {entity.primary_location_state && <SearchHighlight fieldName="primary_location_state" />}
                          </TruncatedGridCell>
                        </GridTable.Row>
                      </SearchResultContext.Provider>
                    )
                  })}
                </GridTable>
              )}
            </Card>

            {/* <Divider /> */}

            <div className="flex flex-col items-center">
              <div className="text-[1.05rem] font-[600] text-text-muted mt-6 mb-3">Can't find the organization you are looking for?</div>

              <Button
                label="Add Internal Organization"
                glyph="add"
                type="primary"
                display="inline-flex"
                size={200}
                link={{
                  pathname: match.url.replace('import-new', 'create-new'),
                  data: { name: query },
                }}
              />
            </div>
          </Section>
        </Overlay.Content>
      </Overlay>

      <SummonOverlay
        isOpen={!!selected}
        onClose={() => {
          setSelected(null)
        }}
        overlay={<CommunityMapFeatureOverlay slug={selected?.slug} />}
      />
    </>
  )
}

const TruncatedGridCell = ({ children }: any) => {
  return (
    <GridTable.Cell className="truncate" css={STYLES.truncatedGridCell}>
      {children || <span className="text-text-muted opacity-70">–</span>}
    </GridTable.Cell>
  )
}

const STYLES = {
  searchNavLink: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 600,
    cursor: 'pointer',

    '&.is-active::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: tint(0.88, COLORS.blue),
      zIndex: -1,
    },
  },

  mainCell: {
    '&:hover': {
      background: `${tint(0.88, COLORS.blue)} !important`,
      zIndex: 10,
      width: 'fit-content',
      overflow: 'visible !important',
      minWidth: '100%',
      paddingRight: '0.5rem',
      cursor: 'auto',
    },
  },

  truncatedGridCell: {
    '&:hover': {
      background: `${COLORS.white} !important`,
      zIndex: 10,
      width: 'fit-content',
      overflow: 'visible !important',
      minWidth: '100%',
      paddingRight: '0.5rem',
      cursor: 'auto',
    },
  },
}

export const NewCommunityOrganizationOverlay = withOverlayError(RootNewCommunityOrganizationOverlay)
