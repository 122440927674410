import React from 'react'
import { useSelector } from 'react-redux'

import { Tenant } from '@behavehealth/declarations'

import {
  Accordion,
  AddressSelectorInput,
  Avatar,
  Chotomate,
  EmailInput,
  Flex,
  Form,
  FormSection,
  Grid,
  CheckboxGroup,
  Checkbox,
  HelpTagIframe,
  Input,
  Label,
  Page,
  Permission,
  Radio,
  RadioGroup,
  Textarea,
  URLInput,
} from '@behavehealth/components'

import StateSelect from '@behavehealth/components/Forms/elements/StateSelect'
import TimezoneInput from '@behavehealth/components/Forms/TimezoneInput'

import { apiUpdate } from '@behavehealth/modules/api'
import { JoinMeetingButton } from '@behavehealth/components/Buttons/JoinMeetingButton'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'

const CompanyProfile: React.FC = () => {
  const record: Tenant = useSelector((state: any) => state.me?.tenant)

  const { isBehave } = useSettings()

  return (
    <Page title="Company Settings" feature="company_settings" plan="lite" help={<HelpTagIframe id="settings_company_settings" />}>
      <Grid gap="1rem">
        <Chotomate name="company_profile" ready={record} />

        <Flex centerX centerY vertical gap={8}>
          <Avatar
            isEditable
            magnify={false}
            type="square"
            width={300}
            height={100}
            src={record.avatar}
            recordId={record.id}
            recordType={record.type}
            css={{ img: { height: '90%' } }}
          />
          <div className="mb-4 font-[600]">Your Company Logo</div>
        </Flex>

        <div>
          {/* Company Details */}
          <Accordion
            isOpen
            activateEditMode
            initialModel={record}
            title="Company Details"
            permission="settings.company_profile.company_details.view"
            editPermission="settings.company_profile.company_details.edit"
            onSubmit={async (data) => {
              await apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })
            }}
          >
            <Form>
              {({ isEditable }) => (
                <FormSection layout="horizontal" labelWidth={150}>
                  <Input
                    label="Company Name"
                    model="name"
                    validations={{
                      presence: {
                        message: 'Please enter a company name',
                      },
                    }}
                  />

                  <URLInput label="Website" model="website_url" />

                  <TimezoneInput isEditable={isEditable && isBehave} label="Timezone" model="timezone" />

                  <Input label="Tax ID" model="tax_id" />

                  <Input label="City" model="city" />

                  <StateSelect label="State" model="state" />

                  {record?.personal_meeting_room?.id && (
                    <div>
                      <Label label="Company Meeting Room" />
                      <JoinMeetingButton
                        type="link"
                        title={record.name}
                        meetingID={record?.personal_meeting_room?.id}
                        meetingName={`${record.name} Meeting Room`}
                        label={`Join ${record.name} Meeting Room`}
                        display="inline-flex"
                        className="!pl-0"
                      />
                    </div>
                  )}
                </FormSection>
              )}
            </Form>
          </Accordion>

          <Permission featureFlagV2="insurance_authorizations">
            <h4 css={styles.headers}>Notifications</h4>
            {/* Notifications */}
            <Accordion
              activateEditMode
              initialModel={record}
              glyph="notification"
              title="Notifications"
              description="Set the Company's preference for different notifications triggered by the app"
              permission="settings.company_profile.notifications.view"
              editPermission="settings.company_profile.notifications.edit"
              featureFlag="care_team"
              onSubmit={(data) => apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })}
            >
              <Form>
                <FormSection layout="horizontal" labelWidth={150}>
                  <EmailInput model="authorizations_email" label="Notified on Authorization Updates" />
                </FormSection>
              </Form>
            </Accordion>
          </Permission>

          <h4 css={styles.headers}>Addresses</h4>
          {/* Billing Address */}
          <Accordion
            initialModel={record}
            title="Billing Address"
            permission="settings.company_profile.billing_address.view"
            onSubmit={(data) => apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })}
          >
            <Form>
              <FormSection layout="horizontal" labelWidth={150}>
                <AddressSelectorInput
                  isEditable
                  actionLabel="Update Billing Address"
                  value={record?.billing_address}
                  afterAddressSelect={(address) =>
                    apiUpdate({
                      name: 'tenant',
                      url: `/me/tenant`,
                      params: { billing_address: address },
                    })
                  }
                  permission="settings.company_profile.billing_address.edit"
                />
              </FormSection>
            </Form>
          </Accordion>

          {/* Mailing Address */}
          <Accordion
            initialModel={record}
            title="Mailing Address"
            permission="settings.company_profile.mailing_address.view"
            onSubmit={(data) => apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })}
          >
            <Form>
              <FormSection layout="horizontal" labelWidth={150}>
                <AddressSelectorInput
                  isEditable
                  actionLabel="Update Mailing Address"
                  value={record?.mailing_address}
                  afterAddressSelect={(address) =>
                    apiUpdate({
                      name: 'tenant',
                      url: `/me/tenant`,
                      params: { mailing_address: address, lat: address?.coords?.lat, lon: address?.coords?.lon },
                    })
                  }
                  fuzzyMatch
                  permission="settings.company_profile.billing_address.edit"
                />
              </FormSection>
            </Form>
          </Accordion>

          <Permission featureFlagV2="clinical_notes">
            <h4 css={styles.headers}>Clinical Settings</h4>
            {/* Clinical Notes Type */}
            <Accordion
              activateEditMode
              initialModel={record}
              title="Clinical Notes"
              description="Set the company's preference for Clinical Notes"
              icon="clinical_notes"
              featureFlag="clinical_notes"
              permission="settings.company_profile.clinical_notes_type.view"
              editPermission="settings.company_profile.clinical_notes_type.edit"
              onSubmit={(data) => apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })}
            >
              <Form>
                <FormSection layout="vertical" labelWidth={150}>
                  <CheckboxGroup
                    label="Supervisor Settings"
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="faded"
                    tooltip="Turn this on to make the Clinical Supervisor a mandatory field for all Clinical Notes"
                  >
                    <Checkbox
                      label="Require Clinical Supervisor on all Clinical Notes"
                      model="system_prefs.require_clinical_notes_supervisor"
                    />
                  </CheckboxGroup>

                  <RadioGroup model="preferred_clinical_note_format" label="Clinical Notes Type" layout="horizontal-dense">
                    <Radio label="DAP (Data, Assessment and Plan)" value="dap" />
                    <Radio label="SOAP (Subjective, Objective, Assessment and Plan)" value="soap" />
                    <Radio label="GIRP (Goals, Intervention, Response and Plan)" value="girp" />
                  </RadioGroup>
                </FormSection>
              </Form>
            </Accordion>
          </Permission>

          <Permission featureFlagV2="bed_management">
            <h4 css={styles.headers}>Bed Management Settings</h4>
            {/* Bed Management Type */}
            <Accordion
              activateEditMode
              initialModel={record}
              title="Bed Management"
              description="Set the company's preference for Bed Management"
              icon="bed_management"
              onSubmit={(data: any) => apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })}
              permission="settings.company_profile.bed_management.view"
              editPermission="settings.company_profile.bed_management.edit"
            >
              <Form>
                <FormSection layout="vertical" labelWidth={150}>
                  <RadioGroup
                    model="system_prefs.allow_overbook"
                    label="Allow Overbooking Beds"
                    layout="horizontal-dense"
                    defaultValue={false}
                  >
                    <Radio label="Yes" value={true} />
                    <Radio label="No" value={false} />
                  </RadioGroup>
                </FormSection>
              </Form>
            </Accordion>
          </Permission>

          <Permission featureFlagV2="behave_ai" permission="settings.company_profile.company_details.view">
            <h4 css={styles.headers}>Behave AI</h4>
            {/* Notifications */}
            <Accordion
              activateEditMode
              initialModel={{ behave_ai_company_prompt: record?.behave_ai_company_prompt || '' }}
              icon="behave_ai"
              title="Behave AI Company Description"
              description="Set the Company's description for Behave AI"
              editPermission="settings.company_profile.company_details.edit"
              onSubmit={(data) => apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })}
            >
              <Form>
                <FormSection maxWidth="100%">
                  <Textarea model="behave_ai_company_prompt" label="Behave AI Company Description" />
                </FormSection>
              </Form>
            </Accordion>
          </Permission>

          <h4 css={styles.headers}>Exports</h4>
          {/* Notifications */}
          <Accordion
            activateEditMode
            initialModel={record}
            glyph="attachment"
            title="Exports"
            description="Set the Company's preference for exporting data"
            permission="settings.company_profile.exports.view"
            editPermission="settings.company_profile.exports.edit"
            onSubmit={(data) => apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })}
          >
            <Form>
              <FormSection layout="horizontal" labelWidth={150}>
                <RadioGroup
                  model="system_prefs.hipaa_compliant_ics_export"
                  label="HIPAA Compliant Calendar Export"
                  layout="horizontal-dense"
                >
                  <Radio label="Enabled" value="true" />
                  <Radio label="Disabled" value="false" />
                </RadioGroup>
              </FormSection>
            </Form>
          </Accordion>
        </div>
      </Grid>
    </Page>
  )
}

const styles = {
  headers: {
    margin: '1.5rem 0 0.5rem 0',
    textTransform: 'uppercase',
    opacity: 0.6,
  },
}

export default withPageError(CompanyProfile)
