import React from 'react'
import { darken } from 'polished'
import { useMedia } from 'use-media'
import compact from 'lodash/compact'
import size from 'lodash/size'

import { COLORS, MEDIA_QUERY } from '../../theme'
import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { NavGroupLabel } from '../../components/NavGroup'
import { TreatmentPlanSelector } from '../../components/Forms/TreatmentPlanSelector'
import { useGet, useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Nav from '../../components/Nav'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageGrid from '../../components/PageGrid'
import Permission from '../../components/Permission'
import ScrollMenu from '../../components/ScrollMenu'
import ScrollView from '../../components/ScrollView'
import Section from '../../components/Section'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import State from '../../components/State'
import SummonOverlay from '../../components/SummonOverlay'
import Switch from '../../components/Forms/Switch'
import Textarea from '../../components/Forms/Textarea'
import Tooltip from '../../components/Tooltip'

import { CATEGORIES, DATA_FORM_SETTINGS } from './constants'
import { DataFormAddendum } from './DataFormAddendum'
import { DataFormAddendumOverlay } from './DataFormAddendumOverlay'
import { DataFormAssignmentSelector } from './DataFormAssignmentSelector'
import { DataFormRenderer } from './DataFormRenderer'
import { DataFormSignaturesSection } from './DataFormSignaturesSection'
import { DataFormStatusSelector } from './DataFormStatusSelector'

import { InsuranceBillingFormSection } from '../RCM/components/InsuranceBillingFormSection'
import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

const buildPermission = (permission_name?: string, type?: string) => {
  return permission_name ? `${permission_name}.${type}` : true
}

const RootDataFormSubmissionOverlay = (props: any) => {
  const {
    cancel,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    saveWithData,
    refetch,
    highlightRequired,
    params,
    navigate,
    location,
  } = useOverlay({
    name: 'data_forms',
    endpoint: '/data_forms',
    invalidate: 'data_forms',
    skipClose: true,
    disableParentRequest: true,
    options: props,
    onAfterClose: (args) => {
      // open the newly created data form
      if (!args.isNew || !args?.data?.id || !args?.params?.slug || !!props?.onClose) return

      const pathname = args?.location?.pathname
      let baseURL = pathname.substring(0, pathname.lastIndexOf(`/${args.params.slug}/new`))

      args.navigate(`${baseURL}/${args.params.slug}/${args.data.id}-30`)
    },
  })

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    if (props.useV6Router && props?.back) return navigate(props.back)

    if (props.useV6Router && location?.state?.from) return navigate(location.state.from)

    let url = ''

    if (params?.slug) {
      url = location.pathname.substring(0, location.pathname.lastIndexOf(`/${params.slug}/`))
    } else {
      url = location?.parent?.url || location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    }

    navigate(url)
  }

  const isDesktop = useMedia({ minWidth: 1024 })

  const { timezone, isPortal, record: user, isBehave, isManagement, tenant } = useSettings()

  const clientId = props?.clientId || params?.resource_id
  const settingsId = props?.settingsId || params?.settings_id

  const [isBillable, setIsBillable] = React.useState(false)
  const [status, setStatus] = React.useState(data?.status || 'draft')
  const [closeOffDialogOpen, setCloseOffDialogOpen] = React.useState(false)
  const [signoffDialogOpen, setSignoffDialogOpen] = React.useState(false)
  const [updatesDialogOpen, setUpdatesDialogOpen] = React.useState(false)

  const initialStaff = React.useMemo(() => {
    if (!isNew || user?.type !== 'employee') return []

    return [user]
  }, [isNew])

  const clientQuery = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
    options: {
      enabled: !props?.client && !data?.client,
      staleTime: 1000 * 60 * 5,
    },
  })

  const { mutateAsync: closeDataForm }: any = useCreate({
    name: ['data_forms', id, 'close'],
    url: `/data_forms/${id}/close`,
    invalidate: 'data_forms',
  })

  const { mutateAsync: signOffDataForm }: any = useCreate({
    name: ['data_forms', id, 'sign_off'],
    url: `/data_forms/${id}/sign_off`,
    invalidate: 'data_forms',
  })

  const { mutateAsync: pendingReviewDataForm }: any = useCreate({
    name: ['data_forms', id, 'pending_review'],
    url: `/data_forms/${id}/pending_review`,
    invalidate: 'data_forms',
  })

  const client = props?.client || data?.client || clientQuery.data
  const isSupervisor = data?.supervisor?.id === user?.id && data?.supervisor?.type === user?.type
  const isTrialing = tenant?.plan_status === 'trialing'

  const dataFormSettingsQuery = useGet({
    name: ['data_form_settings', settingsId],
    url: `/data_form_settings/${settingsId}`,
    options: { enabled: isNew && !!settingsId },
  })

  const dataFormSettings = React.useMemo(() => {
    if (isNew) {
      return dataFormSettingsQuery.data
    }

    return data?.data_form_setting
  }, [isNew, data, dataFormSettingsQuery.data])

  const schema = React.useMemo(() => {
    if (isNew) return dataFormSettings?.schema

    return data?.schema
  }, [isNew, data, dataFormSettings])

  const settings = React.useMemo(() => {
    if (isNew) return dataFormSettings?.settings

    return data?.settings
  }, [isNew, data, dataFormSettings])

  const categoryConfig = React.useMemo(() => {
    if (!dataFormSettings) return null

    return Object.values(CATEGORIES).find((o) => o.id === dataFormSettings?.category) || null
  }, [dataFormSettings])

  const formConfig = React.useMemo(() => {
    if (!dataFormSettings) return null

    const recordKey = compact([dataFormSettings.subcategory, dataFormSettings.variant]).join('_')

    return DATA_FORM_SETTINGS[dataFormSettings.category]?.[recordKey] || null
  }, [dataFormSettings])

  const canCloseOff = React.useMemo(() => {
    if (isNew || isEditable) return false

    return true
  }, [isNew, isEditable])

  const cannotCloseOffReasons = React.useMemo(() => {
    if (isNew || isEditable) return ['Save the assessment first to close it off']

    return []
  }, [isNew, isEditable])

  const canSignOff = React.useMemo(() => {
    if (isEditable) return false

    if (size(data?.data_form_signees) === 0) return false

    if (data?.signature_status === 'signed') return true

    const mustApprove = settings?.signoff_settings?.supervisor_must_approve
    const isApproved = data?.status === 'approved'

    if (!isSupervisor && mustApprove && !isApproved) return false

    let didAllSign = true

    for (const signee of data?.data_form_signees) {
      if (!signee.initial_signature || !signee.initial_signed_at) didAllSign = false
    }

    return didAllSign
  }, [data, settings, isSupervisor, isEditable])

  const cannotSignOffReasons = React.useMemo(() => {
    if (canSignOff) return []

    const reasons: any = []

    if (isEditable) reasons.push('Save the assessment first to sign it off')

    if (settings?.signoff_settings?.supervisors_must_sign) reasons.push('Supervisor must sign')
    if (size(data?.staff) > 0 && settings?.signoff_settings?.staff_must_sign) reasons.push('All staff must sign')
    if (settings?.signoff_settings?.supervisor_must_approve) reasons.push('Supervisor must approve the assessment')

    return reasons
  }, [data, settings, canSignOff, isEditable])

  React.useEffect(() => {
    if (!data?.status) return

    setStatus(data?.status)
  }, [data?.status])

  if (isOverlayLoading || !dataFormSettings || !client) return <OverlayLoader position="right" maxWidth={90} />

  const showSignOff = !!settings.enable_signoff_workflow
  const showCloseOff = !showSignOff
  const permissionName = props.permissionName || categoryConfig?.permissionName

  const isSignedOff = status === 'signed_off'
  const isClosedOff = status === 'closed'
  const isPendingReview = status === 'pending_review'
  const isFinalStatus = isSignedOff || isClosedOff
  const showReviewStatuses = !!settings?.enable_supervisor_review
  const showAddendums = isFinalStatus || size(data?.addendums) > 0

  const submissionActions = !isPortal && (
    <>
      {!!settings?.enable_supervisor_review && !isSupervisor && !isFinalStatus && !isPendingReview && (
        <Tooltip
          show={isEditable || !data?.supervisor}
          color={COLORS.orange}
          content={
            isEditable
              ? 'Save the assessment first to send it for review'
              : !data?.supervisor
              ? 'Select a supervisor to send this assessment to review'
              : ''
          }
        >
          <SignatureDialog
            model="author"
            title="Sign & Send for Review"
            yesLabel="Apply Signature & Send for Review"
            onYes={async () => {
              await pendingReviewDataForm(true)
              await refetch()
            }}
            isDisabled={isEditable || isSaving || !data?.supervisor}
            onDisabledClick={highlightRequired}
          >
            <Button
              fullWidth
              label="Sign & Send for Supervisor Review…"
              glyph="signature"
              type="default"
              isDisabled={isEditable || isSaving || !data?.supervisor}
              show={isInvalid}
              message="Click to highlight required fields"
              size={isDesktop ? 200 : 300}
            />
          </SignatureDialog>
        </Tooltip>
      )}

      {showCloseOff && !isClosedOff && (
        <Tooltip
          show={!canCloseOff}
          color={COLORS.orange}
          content={
            size(cannotCloseOffReasons) > 0 && (
              <div className="grid gap-2">
                <div className="font-[600]">Close-Off Requirements:</div>

                {cannotCloseOffReasons.map((message) => (
                  <div key={message} className="flex flex-nowrap items-center">
                    <Glyph glyph="info" color={darken(0.05, COLORS.orange)} size={16} className="mr-1.5" />
                    {message}
                  </div>
                ))}
              </div>
            )
          }
        >
          <Button
            display="inline-flex"
            label="Close Assessment…"
            glyph="close"
            color="green"
            type="primary"
            size={isDesktop ? 200 : 300}
            isDisabled={isEditable || isSaving || !canCloseOff}
            onClick={() => {
              setCloseOffDialogOpen(true)
            }}
          />
        </Tooltip>
      )}

      {showSignOff && !isSignedOff && (
        <Tooltip
          show={!canSignOff}
          color={COLORS.orange}
          className={isDesktop ? '' : 'order-first'}
          content={
            size(cannotSignOffReasons) > 0 && (
              <div className="grid gap-2">
                <div className="font-[600]">Sign-Off Requirements:</div>

                {cannotSignOffReasons.map((message) => (
                  <div key={message} className="flex flex-nowrap items-center">
                    <Glyph glyph="info" color={darken(0.05, COLORS.orange)} size={16} className="mr-1.5" />
                    {message}
                  </div>
                ))}
              </div>
            )
          }
        >
          <Button
            display="inline-flex"
            label="Sign Off…"
            glyph="signature"
            type="primary"
            color="green"
            isDisabled={isEditable || isSaving || !canSignOff}
            flex="100 1 auto"
            size={isDesktop ? 200 : 300}
            onClick={() => {
              setSignoffDialogOpen(true)
            }}
            className={isDesktop ? '' : 'order-first'}
          />
        </Tooltip>
      )}
    </>
  )

  return (
    <>
      <SummonOverlay
        isOpen={updatesDialogOpen}
        onClose={() => {
          setUpdatesDialogOpen(false)
        }}
        overlay={
          <RequestUpdatesOverlay dataFormId={id} value={data?.requested_updates || ''} refetch={refetch} saveWithData={saveWithData} />
        }
      />

      <ConfirmDialog
        setOpen={closeOffDialogOpen}
        title="Close Assessment?"
        message={
          <div className="grid gap-4 text-text">
            <Alert contrast glyph="warning" type="warning" className="text-text">
              <b>Please note,</b> upon closing this assessment, the following will occur:
            </Alert>

            <Card className="px-4 py-3 grid gap-3 leading-normal">
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="lock" size={18} color={COLORS.text} className="flex-0 mr-3" />
                <div>Only owners and administrators will be able to make any further edits</div>
              </div>
            </Card>
          </div>
        }
        yesColor="green"
        yesLabel="Yes, Close Assessment"
        onYes={async () => {
          await closeDataForm()
          await refetch()
          setCloseOffDialogOpen(false)
        }}
        onClose={() => {
          setCloseOffDialogOpen(false)
        }}
      />

      <ConfirmDialog
        setOpen={signoffDialogOpen}
        title="Sign Off Assessment?"
        message={
          <div className="grid gap-4 text-text">
            <Alert contrast glyph="warning" type="warning" className="text-text">
              <b>Please note,</b> upon signing-off this Assessment, the following will occur:
            </Alert>

            <Card className="px-4 py-3 grid gap-3 leading-normal">
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="lock" size={18} color={COLORS.text} className="flex-0 mr-3" />
                <div>Only owners and administrators will be able to make any further edits</div>
              </div>
            </Card>
          </div>
        }
        yesColor="green"
        yesLabel="Yes, Sign-Off Assessment"
        onYes={async () => {
          await signOffDataForm()
          await refetch()
          setSignoffDialogOpen(false)
        }}
        onClose={() => {
          setSignoffDialogOpen(false)
        }}
      />

      <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={90}>
        <Overlay.Header
          title={dataFormSettings?.name}
          icon={categoryConfig?.icon}
          description={<ClientProfileHeader client={client} />}
          titleAside={
            !isEditable &&
            !isTrialing && (
              <Permission permission="clients.actions.export">
                <div className="h-[16px] w-[1px] bg-divider !mx-2" />

                <ExportPDFButton url={`/data_forms/${id}/pdf`} label="Download PDF" />
              </Permission>
            )
          }
        />

        {(isSignedOff || isClosedOff) && (
          <Overlay.SubHeader className="relative z-10 !px-0 !py-0 border-b border-0 border-solid border-divider shadow-hard-3">
            <Alert small glyph="info" type="warning" className="!rounded-none">
              This assessment is now {isSignedOff ? 'signed-off' : isClosedOff ? 'closed' : ''}. Only Owners and Administrators are allowed
              to make further changes. You can however use the Addendums feature to create any addendums.
            </Alert>
          </Overlay.SubHeader>
        )}

        {!isPortal && (
          <Overlay.SubHeader className="relative z-10 !px-3 !py-1.5 border-b border-0 border-solid border-divider shadow-hard-3">
            <Flex centerY gap="1rem" justifyContent="space-between">
              <div>
                <Flex centerY gap="1rem">
                  <div className="inline-flex flex-nowrap items-center">
                    <Glyph glyph="selector" size={16} color={COLORS.gray} className="mr-1" />

                    <DataFormStatusSelector
                      value={status}
                      showSignOff={showSignOff}
                      isFinalStatus={isFinalStatus}
                      canSignOff={canSignOff}
                      cannotSignOffReasons={cannotSignOffReasons}
                      canCloseOff={canCloseOff}
                      cannotCloseOffReasons={cannotCloseOffReasons}
                      isSupervisor={isSupervisor}
                      showReviewStatuses={showReviewStatuses}
                      onUpdate={async (newStatus: any) => {
                        if (isNew) {
                          setStatus(newStatus)
                          form.current?.setFieldValue('status', newStatus)
                          return
                        }

                        if (newStatus === 'signed_off' && canSignOff) {
                          setSignoffDialogOpen(true)
                          return
                        }

                        if (newStatus === 'closed') {
                          setCloseOffDialogOpen(true)
                          return
                        }

                        if (newStatus === 'updates_required') {
                          setUpdatesDialogOpen(true)
                          return
                        }

                        if (newStatus === 'pending_review') {
                          setStatus(newStatus)
                          await pendingReviewDataForm(true)
                          await refetch()
                          return
                        }

                        setStatus(newStatus)

                        await saveWithData({ status: newStatus }, { keepEditing: true })
                      }}
                    />

                    <Tooltip
                      glyphSize={14}
                      glyphColor={COLORS.paleBlue}
                      className="ml-1"
                      content={
                        <div className="grid gap-3 pb-3">
                          <div>
                            Choose the assessment's status. This will determine the next steps and actions available for this assessment
                          </div>

                          <div>
                            <NavGroupLabel label="Initial Status" className="mb-1" />
                            <div>
                              <b>Draft:</b> The assessment is still in progress and not yet ready for review
                            </div>
                          </div>

                          <Divider className="!my-0" />

                          <div className="grid gap-1">
                            <NavGroupLabel label="Review Statuses" />

                            <div className="mb-1 italic text-text-muted">
                              Only available when the "Supervisor Review" workflow is enabled for this type of assessment
                            </div>

                            <div>
                              <b>Pending Review:</b> The assessment is ready for review by the supervisor
                            </div>

                            <div>
                              <b>Updates Required:</b> The assessment requires updates before it can be approved
                            </div>

                            <div>
                              <b>In Progress:</b> The assessment is currently being worked on
                            </div>

                            <div>
                              <b>Approved:</b> The submission has been approved and can be closed or signed-off
                            </div>

                            <div>
                              <b>Rejected:</b> The submission has been rejected and cannot be closed or signed-off
                            </div>
                          </div>

                          <Divider className="!my-0" />

                          <div className="grid gap-1">
                            <NavGroupLabel label="Final Statuses" className="mb-1" />
                            <div>
                              <div>
                                <b>Signed-Off:</b> The assessment has been signed-off and is now closed{' '}
                              </div>

                              <div className="italic text-text-muted">
                                (available when the "Sign-Off Workflow" <b>is enabled</b> for this assessment)
                              </div>
                            </div>

                            <div>
                              <div>
                                <b>Closed:</b> The assessment has been closed and is no longer editable{' '}
                              </div>

                              <div className="italic text-text-muted">
                                (available when the "Sign-Off Workflow" <b>is not</b> enabled for this assessment)
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>

                  <div className="inline-flex flex-nowrap items-center">
                    <Glyph glyph="user_neutral" size={16} color={COLORS.gray} className="mr-1" />

                    <DataFormAssignmentSelector
                      key={`reference-${data?.reference?.id}`}
                      filedUnderClient={data?.reference?.type === 'resident' && data?.reference}
                      value={data?.assignee || initialModel?.assignee}
                      onUpdate={async (record: any) => {
                        if (isNew) {
                          form.current?.setFieldValue('assignee_id', record.id)
                          form.current?.setFieldValue('assignee_type', record.type)
                          return
                        }

                        await saveWithData({ assignee_id: record.id, assignee_type: record.type }, { keepEditing: true })
                      }}
                    />
                    <Tooltip
                      glyphSize={14}
                      glyphColor={COLORS.paleBlue}
                      className="ml-1"
                      content="Select who is responsible for next steps on this assessment to ensure progress and accountability"
                    />
                  </div>
                </Flex>
              </div>

              {isDesktop && (
                <div>
                  <Flex centerY gap="1rem">
                    {submissionActions}
                  </Flex>
                </div>
              )}
            </Flex>
          </Overlay.SubHeader>
        )}

        <ScrollView>
          <PageGrid scroll breakpoint={3} className="overlay-page-grid" css={STYLES.pageGrid}>
            <Nav
              top="0"
              breakpoint={3}
              title={' '}
              headingSize={300}
              desktopProps={{ title: null }}
              className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
            >
              <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
              <ScrollMenu />
            </Nav>

            <Overlay.Content>
              <Form
                getForm={form}
                initialModel={{
                  ...(isNew && { staff: initialStaff }),
                  ...initialModel,
                }}
                isEditable={isEditable}
                timezone={timezone}
                onValidationUpdate={onValidationUpdate}
                linked={
                  isNew && {
                    version: '3.0',
                    client_id: client.id,
                    category: dataFormSettings.category,
                    subcategory: dataFormSettings.subcategory,
                    data_form_setting_id: settingsId,
                    status,
                  }
                }
              >
                {!isFinalStatus && data?.requested_updates && (
                  <>
                    <Section>
                      <Alert small contrast glyph="warning" type="negative">
                        <div className="flex items-center">
                          <div className="flex-[1_1_auto]">
                            <div className="font-[700]">Updates requested:</div>
                            <div>{data?.requested_updates}</div>
                          </div>

                          <SummonOverlay
                            overlay={
                              <RequestUpdatesOverlay
                                dataFormId={id}
                                value={data?.requested_updates || ''}
                                refetch={refetch}
                                saveWithData={saveWithData}
                              />
                            }
                          >
                            <Button label="Edit" glyph="edit" size={100} />
                          </SummonOverlay>
                        </div>
                      </Alert>
                    </Section>

                    <Divider />
                  </>
                )}

                <Section scrollview={{ id: 'general', name: 'General' }} headingType="h2" title="General">
                  <FormSection>
                    <Input
                      label="Name"
                      model="name"
                      defaultValue={dataFormSettings.name}
                      isEditable={isEditable && !isPortal}
                      validations={{
                        presence: {
                          message: 'Please enter a form name',
                        },
                      }}
                    />

                    {!isNew && (
                      <>
                        <ObjectSelector
                          label="Author"
                          blankLabel="Select Staff…"
                          icon="employees"
                          type="employees"
                          model="author"
                          selectTitle={(data) => data?.name}
                          selectDescription={() => null}
                          isEditable={false}
                          disableLink={isPortal}
                        />
                      </>
                    )}

                    <ObjectSelector
                      isPolymorphic
                      label="Supervisor"
                      testKey="data_form_supervisor_selector"
                      blankLabel="Select Supervisor…"
                      icon="employees"
                      type="clinical_supervisors"
                      model="supervisor"
                      selectTitle={(data) => data?.name}
                      selectDescription={() => null}
                      disableLink={isPortal}
                      isEditable={isEditable && !isPortal}
                      tooltip="A signature box will be available at the bottom of this form for the selected supervisor"
                    />

                    <MultiObjectSelector
                      isPolymorphic
                      label="Staff Members"
                      testKey="data_form_staff_selector"
                      blankLabel="Select Staff Members…"
                      icon="employees"
                      type="employees"
                      model="staff"
                      selectTitle={(data) => data?.name}
                      selectDescription={() => null}
                      tooltip="A signature box will be available at the bottom of this form for each of the selected staff members"
                    />

                    <DateTimeInput
                      defaultToNow
                      model="dated_at"
                      label="Start Date and Time"
                      isEditable={isEditable && !isPortal}
                      validations={{
                        presence: {
                          message: 'Please enter a date and time',
                        },
                      }}
                    />

                    <FormFutureDateWarning dateLabel="Start Date and Time" model="dated_at" />

                    <DateTimeInput model="ended_at" label="End Date and Time" isEditable={isEditable && !isPortal} />

                    {!isPortal && schema?.general?.fields?.linked_goals_objectives_interventions && (
                      <TreatmentPlanSelector clientId={client.id} />
                    )}

                    {tenant?.is_billed && <InsuranceBillingFormSection isNew={isNew} initialModel={initialModel} />}
                  </FormSection>
                </Section>

                <Divider />

                <DataFormRenderer useScrollView sections={formConfig?.sections} schema={schema} client={client} isEditable={isEditable} />

                {!isNew && !isPortal && data && (
                  <>
                    <Divider />

                    <DataFormSignaturesSection permissionName={permissionName} signees={data.data_form_signees} settings={settings} />
                  </>
                )}

                {showAddendums && (
                  <>
                    <Divider />

                    <Section
                      scrollview={{ id: 'addendums', name: 'Addendums' }}
                      headingType="h2"
                      title="Addendums"
                      aside={
                        <SummonOverlay overlay={<DataFormAddendumOverlay reference={data} />}>
                          <Button size={200} label="Add Addendum" glyph="add" type="primary" display="inline-flex" />
                        </SummonOverlay>
                      }
                    >
                      {size(data?.addendums) === 0 ? (
                        <Card>
                          <State isEmpty icon="addendums" emptyDescription="No addendums have been added yet" />
                        </Card>
                      ) : (
                        <div className="grid gap-3">
                          {data?.addendums?.map?.((addendum: any) => {
                            return <DataFormAddendum key={`addendum-${addendum.id}`} data={addendum} />
                          })}
                        </div>
                      )}
                    </Section>
                  </>
                )}
              </Form>
            </Overlay.Content>
          </PageGrid>
        </ScrollView>

        <Overlay.Footer>
          {!isDesktop && (
            <div className="!mb-4 w-full">
              <Flex centerY stretchChildrenX gap="0.75rem">
                {submissionActions}
              </Flex>
            </div>
          )}

          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={save}
                isLoading={isSaving}
                permission={isNew ? buildPermission(permissionName, 'create') : buildPermission(permissionName, 'edit')}
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              {isClosedOff || isSignedOff ? (
                <>
                  {isBehave || isManagement ? (
                    <>
                      <ConfirmDialog
                        title="Edit Assessment?"
                        message={`This Assessment is currently ${
                          isSignedOff ? 'signed-off' : isClosedOff ? 'closed' : ''
                        }. Are you sure you want to edit it?`}
                        yesColor="blue"
                        yesLabel="Edit Assessment"
                        onYes={edit}
                      >
                        <Button
                          label="Edit Assessment"
                          glyph="edit"
                          permission={buildPermission(permissionName, 'edit')}
                          flex="100 1 auto"
                        />
                      </ConfirmDialog>

                      <Permission permission={buildPermission(permissionName, 'delete')}>
                        <DeleteDialog
                          title="Delete Assessment?"
                          message="Are you sure you want to delete this Assessment? This action cannot be undone."
                          onYes={deleteRecord}
                        >
                          <Button
                            label="Delete"
                            testKey="data_form_delete_button"
                            glyph="delete"
                            color="red"
                            isDisabled={isDeleting}
                            permission={buildPermission(permissionName, 'delete')}
                          />
                        </DeleteDialog>
                      </Permission>
                    </>
                  ) : (
                    <Tooltip
                      content={`This Assessment is now ${
                        isSignedOff ? 'signed-off' : isClosedOff ? 'closed' : ''
                      }. Only Owners and Administrators are allowed to make further changes. You can however use the Addendums feature to create any addendums.`}
                    >
                      <Flex stretchChildrenX gap="0.75rem" flex="1 1 auto">
                        <Button
                          label="Edit Assessment"
                          glyph="edit"
                          isDisabled
                          permission={buildPermission(permissionName, 'edit')}
                          flex="100 1 auto"
                        />
                        <Button
                          label="Delete"
                          glyph="delete"
                          color="red"
                          isDisabled
                          permission={buildPermission(permissionName, 'delete')}
                        />
                      </Flex>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  <Button
                    glyph="edit"
                    label="Edit"
                    testKey="data_form_edit_button"
                    type="default"
                    isDisabled={isLoading}
                    onClick={edit}
                    flex="100 1 auto"
                    permission={buildPermission(permissionName, 'edit')}
                  />

                  <DeleteDialog
                    title="Delete Form?"
                    message="Are you sure you want to delete this form? This action cannot be undone."
                    onYes={deleteRecord}
                    permission={buildPermission(permissionName, 'delete')}
                  >
                    <Button
                      fullWidth
                      glyph="delete"
                      testKey="data_form_delete_button"
                      label="Delete"
                      type="default"
                      color="red"
                      isLoading={isDeleting}
                      permission={buildPermission(permissionName, 'delete')}
                    />
                  </DeleteDialog>
                </>
              )}
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    </>
  )
}

const RequestUpdatesOverlay = (props: any) => {
  const { value, dataFormId, onClose, refetch } = props

  const [isLoading, setIsLoading] = React.useState(false)

  const { mutateAsync: requestUpdates }: any = useCreate({
    name: ['data_forms', dataFormId, 'request_updates'],
    url: `/data_forms/${dataFormId}/request_updates`,
    invalidate: 'data_forms',
  })

  const form = React.useRef(null)

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    setIsLoading(true)

    await requestUpdates({
      requested_updates: formData.requested_updates,
    })

    await refetch()

    setIsLoading(false)

    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={onClose}>
      <Overlay.Header title="Request Updates" />

      <Overlay.Content>
        <Section>
          <Form getForm={form} initialModel={{ requested_updates: value || '' }}>
            <Textarea label="Updates Requested" model="requested_updates" />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Request Updates" type="primary" color="red" glyph="tick_circle" onClick={handleSave} isLoading={isLoading} />
      </Overlay.Footer>
    </Overlay>
  )
}

const STYLES = {
  pageGrid: {
    flex: '1 1 auto',
  },

  nav: {
    [MEDIA_QUERY[3]]: {
      maxWidth: 300,
    },
  },
}

export const DataFormSubmissionOverlay = withOverlayError(RootDataFormSubmissionOverlay)
