import React from 'react'
import { useQueryClient } from 'react-query'
import size from 'lodash/size'

import { minutesBetween } from '../../../../../utils/functions'
import { useCreate, getPrefix } from '../../../../../hooks/useNewAPI'

import { DailyNoteGroupBuilderOverlay } from '../../../../../constructs/DailyNotes/DailyNoteGroupBuilderOverlay'
import { DailyNoteGroupOverlay } from '../../../../../constructs/DailyNotes/DailyNoteGroupOverlay'
import { DailyNoteOutcomeItem } from '../common/DailyNoteOutcomeItem'
import { EventOutcomesFooter } from '../EventOutcomesFooter'

import SummonOverlay from '../../../../SummonOverlay'
import { getClientAttendance } from '../common/functions'

type Props = {
  event: any
  outcomes?: any
}

export const GroupDailyNoteOutcome = ({ event, outcomes = [] }: Props) => {
  const queryClient = useQueryClient()

  if (!event) return null

  return (
    <>
      {outcomes.map((outcome: any) => {
        const data = outcome?.reference

        if (!data) return null

        return (
          <SummonOverlay
            key={outcome?.id}
            permission="daily_notes.view"
            overlay={
              <DailyNoteGroupOverlay
                showBackdrop
                dataID={data?.id}
                position="center"
                initialData={data}
                onSaveSuccessful={() => {
                  queryClient.invalidateQueries([getPrefix(), 'event', event?.id])
                }}
                onDeleteSuccessful={() => {
                  queryClient.invalidateQueries([getPrefix(), 'event', event?.id])
                }}
              />
            }
          >
            <DailyNoteOutcomeItem data={data} />
          </SummonOverlay>
        )
      })}

      <AddMore event={event} />
    </>
  )
}

export const AddMore = ({ event, className }: Props) => {
  const { mutateAsync, isLoading: isSaving }: any = useCreate({
    name: ['event-outcomes'],
    url: `/event_outcomes`,
    invalidate: ['event', event?.id],
    invalidateKeys: ['events'],
  })

  if (!event?.outcomes_uuid) return null

  return (
    <SummonOverlay
      overlay={
        <DailyNoteGroupBuilderOverlay
          dataID="new"
          position="center"
          isSaving={isSaving}
          save={async (data: any) => {
            await mutateAsync({
              data: data,
              event_id: event?.id,
              category: 'group_daily_notes',
            })
          }}
          initialData={{
            uuid: event.outcomes_uuid,
            topic: event.title,
            description: event.public_description,
            started_at: event.started_at,
            duration: event.start_time && event.end_time ? minutesBetween(event.start_time, event.end_time) : null,

            ...(size(event?.residents) > 0 && {
              group_notes: event.residents?.map?.((resident: any) => ({
                client: resident,
                client_id: resident.id,
                ...getClientAttendance(resident.id, event.event_attendances),
              })),
            }),

            ...(event.resident && {
              group_notes: [
                {
                  client: event.resident,
                  client_id: event.resident.id,
                  ...getClientAttendance(event.resident.id, event.event_attendances),
                },
              ],
            }),
          }}
        />
      }
    >
      <EventOutcomesFooter addLabel="Add Group Daily Note" permission="daily_notes.create" className={className} />
    </SummonOverlay>
  )
}

export default GroupDailyNoteOutcome
