import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { usDateTime } from '../../utils/functions'
import useSettings from '../../hooks/useSettings'
import VOBStatus from '../../components/Statuses/VOBStatus'
import { FILTERS } from '../Filters/config'

export const VOBDataTable = (props: any) => {
  const { to, ...rest } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Date Created',
        model: 'created_at',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={to?.(data)} value={usDateTime(value, timezone)} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <VOBStatus small status={value} />,
      },
      {
        title: 'Insurance Payer',
        model: 'insurance_policy.insurance_local_payer',
        type: 'profile',
        id: 'insurance_local_payer',
        disableSort: false,
      },
      {
        title: 'Plan Name',
        model: 'insurance_policy.plan',
        id: 'insurance_policy_plan',
        disableSort: false,
      },
      {
        title: 'Last Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to],
  )

  return <DataTable asCard title="VOBs" icon="vob" columns={columns} filtersConfig={FILTERS.vobs} {...rest} />
}
