import React from 'react'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPIwithInitialClear } from '../../../hooks/useAPI'
import { countWord } from '../../../utils/functions'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import Workflow from '../../../components/Workflow/Workflow'

import ClientAgreementOverlay from '../../../components/Overlays/pages/Agreements/ClientAgreementOverlay'
import ImportAgreementsOverlay from '../../../components/Overlays/pages/Agreements/ImportAgreementsOverlay'

import { ClientAgreementsPageActions } from '../../Agreements/ClientAgreementsPageActions'

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    width: 150,
    Cell: ({ value, row }: any) => (
      <SummonOverlay overlay={<ClientAgreementOverlay showBackdrop dataID={row.original.id} initialData={row.original} />}>
        <TableCell.MainLink label={value} />
      </SummonOverlay>
    ),
  },
  {
    accessor: 'contracts',
    Header: 'Signees',
    width: 180,
    Cell: ({ value }: any) => countWord('Signee', size(value)),
  },
  {
    accessor: 'author',
    Header: 'Added by',
    width: 140,
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

const AddAgreementButton = ({ match }) => (
  <SummonOverlay overlay={<ImportAgreementsOverlay match={match} />}>
    <Button label="Add Agreements" type="primary" glyph="add" size={300} display="inline-flex" permission="agreements.create" />
  </SummonOverlay>
)

const Agreements = ({ client, setStatus, match }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id } = match.params

  const data = useSelector((state: any) => state.data.agreements?.data)
  const loading = useSelector((state: any) => state.data.agreements?.loading)
  const isEmpty = size(data) === 0

  useAPIwithInitialClear('agreements', `/residents/${resource_id}/agreements`, false)

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  // if (isEmpty || loading) {
  //   return (
  //     <State
  //       isLoading={isEmpty && loading}
  //       isEmpty={isEmpty}
  //       title="Agreements"
  //       icon="legal_agreement_alt"
  //       emptyDescription="No agreements have been added yet"
  //       emptyActions={<AddAgreementButton match={match} />}
  //     />
  //   )
  // }

  return (
    <FormSection maxWidth="100%">
      <Alert contrast glyph="info">
        Import agreements from templates and customize them if needed.
      </Alert>

      <Card>
        <Table
          icon="legal_agreement_alt"
          title="Agreements"
          showSettings={false}
          data={data}
          columns={columns}
          isLoading={loading}
          emptyDescription="No agreements have been added yet."
          // emptyActions={<AddAgreementButton match={match} />}
        />
        <CardContent>
          {/* <AddAgreementButton match={match} /> */}

          <div className="flex">
            <ClientAgreementsPageActions
              useSummonOverlay
              showManageLink={false}
              buttonSize={200}
              buttonDisplay="inline-flex"
              client={client}
              clientTrack={client?.client_track}
            />
          </div>
        </CardContent>
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default Agreements
