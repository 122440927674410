import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { DataTable } from '../../../components/DataTable/DataTable'
import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { OrganizationTodoPageActions } from '../../Organizations/OrganizationTodoPageActions'
import { usDate, usTime } from '../../../utils/functions'
import { useDataTable } from '../../../components/DataTable/useDataTable'
import { useSettings } from '../../../hooks/useSettings'
import { useUpdate } from '../../../hooks/useNewAPI'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CheckButton from '../../../components/CheckButton'
import DonutChart from '../../../components/DonutChart'
import Flex from '../../../components/Flex'
import FormSection from '../../../components/Forms/FormSection'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { AuthorityOrganizationTodoBuilderOverlay } from '../AuthorityOrganizationTodoBuilderOverlay'
import { AuthorityOrganizationTodoOverlay } from '../AuthorityOrganizationTodoOverlay'

const STATUS_COLORS: any = {
  done: 'green',
  due_today: 'orange',
  to_do: 'blue',
  overdue: 'red',
}

const DISPLAY_STATUSES: any = {
  done: 'Completed',
  due_today: 'Due Today',
  to_do: 'To Do',
  overdue: 'Overdue',
}

const TASK_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Task',
}

const TaskCheckbox = ({ task }: any) => {
  const status = task?.display_status

  const { mutate, isLoading } = useUpdate({
    name: ['todo', task.id],
    url: `/events/${task.id}`,
    invalidate: 'todos',
  })

  const toggleTask = () => {
    mutate({ action_status: task.action_status === 'done' ? 'due' : 'done' })
  }

  return (
    <Flex centerY nowrap className="!min-h-[28px] pl-1">
      <CheckButton isCompleted={task.action_status === 'done'} isLoading={isLoading} onClick={toggleTask} className="mr-3" />
      <Status color={STATUS_COLORS[status]} label={DISPLAY_STATUSES[status]} />
    </Flex>
  )
}

export const TodosStep = ({ organization, setStatus }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const { timezone } = useSettings()

  const [dataId, setDataId] = React.useState(null)
  const [newTemplate, setNewTemplate] = React.useState(null)

  const tableProps = useDataTable({
    name: ['organization', organization.id, 'todos'],
    endpoint: `/organizations/${organization.id}/events`,
    updateDeleteEndpoint: `/events`,
    localStorageKey: 'authority_workflow_todos_v1',
    params: { event_type: 'task' },
    headers: {
      'X-Request-Level': 'global',
    },
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        id: 'title',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell isActive={dataId === data.id} id={data.id} value={data.title} onClick={() => setDataId(data.id)} />
        ),
      },
      {
        width: 140,
        id: '__display_status',
        model: 'display_status',
        title: 'Status',
        hoverExpand: false,
        formatValue: ({ data }: any) => <TaskCheckbox task={data} />,
      },
      {
        width: 170,
        id: 'subtasks_no',
        model: 'subtasks_no',
        title: 'Tasks',
        formatValue: ({ data, value }: any) => {
          if (value === 0) return null

          return (
            <div className="flex items-center justify-start">
              <DonutChart
                value={(data.subtasks_completed_no / data.subtasks_no) * 100}
                size={16}
                strokeWidth={3}
                labelSize="0px"
                background={COLORS.divider}
              />
              <div className="ml-1.5">
                {data.subtasks_completed_no} / {data?.subtasks_no} Completed
              </div>
            </div>
          )
        },
      },
      {
        width: 90,
        id: '__color',
        model: 'color',
        title: 'Color',
        type: 'color',
      },
      {
        width: 160,
        id: 'started_at',
        model: 'started_at',
        title: 'Due Date',
        formatValue: ({ value }: any) => usDate(value, timezone),
      },
      {
        width: 120,
        id: '_due_by',
        model: 'is_all_day',
        title: 'Due By',
        formatValue: ({ value, data }: any) => {
          if (value === true) return 'All-day task'

          return usTime(data?.start_time, timezone)
        },
      },
      {
        width: 110,
        id: 'meeting_type',
        model: 'meeting_type',
        title: 'Type',
        formatValue: ({ value }: any) => TASK_TYPES[value],
      },
      {
        width: 110,
        id: 'meeting_place',
        model: 'meeting_place',
        title: 'Meeting Place',
        type: 'title',
      },
      {
        width: 200,
        id: 'public_description',
        model: 'public_description',
        title: 'Public Description',
        type: 'rich_text',
      },
      {
        id: 'created_at',
        model: 'created_at',
        title: 'Date Created',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [dataId, timezone])

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <DataTable asCard title="To-Do's" icon="tasks" columns={columns} {...tableProps} />

          <CardContent className="flex">
            <OrganizationTodoPageActions
              showManageLink={false}
              buttonSize={200}
              organizationTrack={organization.organization_track}
              onClick={(template: any) => {
                setNewTemplate(template)
              }}
            />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        isOpen={!!newTemplate}
        onClose={() => {
          setNewTemplate(null)
        }}
        overlay={
          <AuthorityOrganizationTodoBuilderOverlay
            dataID="new"
            initialData={{
              ...(newTemplate || {}),
              meeting_place: 'organization',
              organizations: [organization],
              organization,
            }}
          />
        }
      />

      <SummonOverlay
        key={`id-${dataId}`}
        isOpen={!!dataId}
        onClose={() => {
          setDataId(null)
        }}
        overlay={<AuthorityOrganizationTodoOverlay dataID={dataId} />}
      />
    </>
  )
}
