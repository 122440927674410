import React from 'react'
import { useQueryClient } from 'react-query'
import { v4 as uuid } from 'uuid'
import produce from 'immer'

import { apiUpdate } from '../../modules/api'
import { INPUT_STYLES } from '../../theme'
import { withFormContext } from './context'

import Button from '../Button'
import CheckButton from '../CheckButton'
import Field from './Field'
import FieldBase from './FieldBase'
import Flex from '../Flex'
import Input from './Input'

const SubtaskItem = (props: any) => {
  const { item, index, isEditable, handleToggle, handleDelete, handleUpdate } = props
  const [loading, setLoading] = React.useState(false)

  const queryClient = useQueryClient()

  const onToggle = async (event: any) => {
    event.preventDefault()

    setLoading(true)
    await handleToggle(index)
    setLoading(false)

    const { queries }: any = queryClient.getQueryCache()

    for (let i = 0; i < queries.length; i++) {
      const query = queries[i]

      if (query.queryKey.includes('todo') || query.queryKey.includes('todos')) {
        queryClient.invalidateQueries(query.queryKey)
      }
    }
  }

  return (
    <div
      css={{
        margin: `${isEditable ? '0.25rem' : '0.5rem'} 0 !important`,
        display: 'flex',
        flexWrap: 'nowrap',
      }}
    >
      {!isEditable && <CheckButton onClick={onToggle} isLoading={loading} isCompleted={item.status === 'completed'} className="!mr-4" />}

      <Input
        isEditable={isEditable}
        className="!flex-auto"
        value={item.title}
        onUpdate={(input: any) => {
          handleUpdate(index, { title: input?.value })
        }}
      />

      {isEditable && (
        <Button
          glyph="delete"
          color="red"
          css={{ marginLeft: '0.5rem', padding: '0.5em 0.5rem 0.5rem 1rem' }}
          type="minimal"
          onClick={() => handleDelete(index)}
        />
      )}
    </div>
  )
}

class Subtasks extends FieldBase {
  constructor(props) {
    super(props)

    this.state = {
      type: 'SUBTASKS',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: props.value || props.form?.getInitialInputFieldValue(props.model, []),
      isValid: true,
      isInvalid: false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      newTitle: '',
      reset: this.onReset,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: props.value || props.form?.getInitialInputFieldValue(props.model, []),
      isValid: true,
      isInvalid: false,
    }

    this.updateType = 'DATA'
    this.inputRef = React.createRef()
  }

  handleAdd = (event) => {
    event.preventDefault()

    if (this.inputRef?.current) this.inputRef.current.focus()

    if (this.state.newTitle.length > 0) {
      this.changeValue([...this.state.value, { uuid: uuid(), title: this.state.newTitle, status: 'due' }])
      this.setState({ newTitle: '' })
    }
  }

  handleEnterKey = (event) => {
    if (event.keyCode === 13) this.handleAdd(event)
  }

  handleDelete = (idx) => {
    this.changeValue(
      produce(this.state.value, (draft: any) => {
        draft.splice(idx, 1)
      }),
    )
  }

  handleToggle = async (idx) => {
    const subtasks = produce(this.state.value, (draft: any) => {
      if (draft[idx].status === 'due') {
        draft[idx].status = 'completed'
      } else {
        draft[idx].status = 'due'
      }
      return draft
    })

    this.changeValue(subtasks)

    await apiUpdate({
      name: 'tasks',
      url: `/${this.props.endpoint}/${this.props.taskId}`,
      params: {
        subtasks,
      },
    })
  }

  handleUpdate = (idx: any, value: any) => {
    const newValue = produce(this.state.value, (draft: any) => {
      draft[idx] = { ...draft[idx], ...value }
    })

    this.changeValue(newValue)
  }

  /*
    RENDER
  */
  render() {
    const { isEditable, taskId, ...others } = this.props
    const { id, value, isRequired, isValid, errors, newTitle } = this.state
    const isEmpty = value.length === 0

    return (
      <Field {...others} id={id} errors={errors} isValid={isValid} isRequired={isRequired} maxWidth="100%">
        {isEmpty && <div>There are no tasks created yet</div>}

        {value.map((item: any, idx: number) => (
          <SubtaskItem
            key={item.uuid}
            index={idx}
            item={item}
            handleToggle={this.handleToggle}
            handleDelete={this.handleDelete}
            handleUpdate={this.handleUpdate}
            isEditable={isEditable}
          />
        ))}

        {isEditable && (
          <div className="!mt-4">
            <Flex gap={8}>
              <input
                ref={this.inputRef}
                placeholder="Add Tasks here..."
                value={newTitle}
                onChange={(e) => this.setState({ newTitle: e.target.value })}
                onKeyDown={this.handleEnterKey}
                css={styles.input}
              />
              <Button label="Add Task" glyph="add" type="primary" onClick={this.handleAdd} />
            </Flex>
          </div>
        )}
      </Field>
    )
  }
}

const styles = {
  input: {
    ...INPUT_STYLES,
    width: 'auto',
    flex: '1 1 auto',
  },
}

Subtasks.defaultProps = {
  endpoint: 'tasks',
}

export default withFormContext(Subtasks)
