import { GRIEVANCE_FORM } from './configs/grievance_form'
import { CERTIFICATION_FORM_CONTACT, CERTIFICATION_FORM_LOCATION, CERTIFICATION_FORM_ORGANIZATION } from './configs/certification_form'
import { INSPECTION_CHECKLIST_FORM } from './configs/inspection_checklist_form'
import { INCIDENT_REPORTING_FORM } from './configs/incident_reporting_form'
import { ACCREDITATION_AGREEMENT } from './configs/accreditation_agreement'
import { DOCUMENT_REVIEW_CHECKLIST } from './configs/document_review_checklist'

export const AUTHORITY_FORMS = {
  grievance: {
    id: 'grievance',
    title: 'Grievance',
    slug: 'grievance',
    icon: 'grievance_incident_forms',
    sections: GRIEVANCE_FORM,
  },
  incident_reporting: {
    id: 'incident_reporting',
    title: 'Incident Reporting',
    slug: 'incident-reporting',
    icon: 'grievance_incident_forms',
    sections: INCIDENT_REPORTING_FORM,
  },
  incident_reporting_followup: {
    id: 'incident_reporting_followup',
    title: 'Incident Reporting Followup',
    slug: 'incident-reporting-followup',
    icon: 'grievance_incident_forms',
    sections: null,
  },
  certification_application_form_organization: {
    id: 'certification_application_form_organization',
    title: 'Certification Application Form – Organization',
    slug: 'certification-application-form-organization',
    icon: 'authority_forms',
    sections: CERTIFICATION_FORM_ORGANIZATION,
  },
  certification_application_form_contacts: {
    id: 'certification_application_form_contacts',
    title: 'Certification Application Form – Contacts',
    slug: 'certification-application-form-contacts',
    icon: 'authority_forms',
    isMultiSection: true,
    sections: [{ title: 'Contacts', model: 'contacts', sections: CERTIFICATION_FORM_CONTACT, isDataArray: true }],
  },
  certification_application_form_locations: {
    id: 'certification_application_form_locations',
    title: 'Certification Application Form – Locations',
    slug: 'certification-application-form-locations',
    icon: 'authority_forms',
    isMultiSection: true,
    sections: [{ title: 'Locations', model: 'locations', sections: CERTIFICATION_FORM_LOCATION, isDataArray: true }],
  },
  certification_application_renewal_form: {
    id: 'certification_application_renewal_form',
    title: 'Certification Application Renewal Form',
    slug: 'certification-application-renewal-form',
    icon: 'authority_forms',
    sections: null,
  },
  inspection_checklist: {
    id: 'inspection_checklist',
    title: 'Inspection Checklist',
    slug: 'inspection-checklist',
    icon: 'authority_forms',
    sections: INSPECTION_CHECKLIST_FORM,
  },
  document_review_checklist: {
    id: 'document_review_checklist',
    title: 'Document Review Checklist',
    slug: 'document-review-checklist',
    icon: 'authority_forms',
    sections: DOCUMENT_REVIEW_CHECKLIST,
  },
  accreditation_agreement: {
    id: 'accreditation_agreement',
    title: 'Accreditation Agreement',
    slug: 'accreditation-agreement',
    icon: 'authority_forms',
    sections: ACCREDITATION_AGREEMENT,
  },
  code_of_ethics: {
    id: 'code_of_ethics',
    title: 'Code of Ethics',
    slug: 'code-of-ethics',
    icon: 'authority_forms',
    sections: null,
  },
  assurances: {
    id: 'assurances',
    title: 'Assurances',
    slug: 'assurances',
    icon: 'authority_forms',
    sections: null,
  },
  attestation: {
    id: 'attestation',
    title: 'Attestation',
    slug: 'attestation',
    icon: 'authority_forms',
    sections: null,
  },
  property_ownership_attestation: {
    id: 'property_ownership_attestation',
    title: 'Property Ownership Attestation',
    slug: 'property-ownership-attestation',
    icon: 'authority_forms',
    sections: null,
  },
  home_ownership_attestation: {
    id: 'home_ownership_attestation',
    title: 'Home Ownership Attestation',
    slug: 'home-ownership-attestation',
    icon: 'authority_forms',
    sections: null,
  },
  certification_overview: {
    id: 'certification_overview',
    title: 'Certification Overview',
    slug: 'certification-overview',
    icon: 'authority_forms',
    sections: null,
  },
  organizational_policies_and_procedures_writing_guide: {
    id: 'organizational_policies_and_procedures_writing_guide',
    title: 'Organizational Policies and Procedures Writing Guide',
    slug: 'organizational-policies-and-procedures-writing-guide',
    icon: 'authority_forms',
    sections: null,
  },
  resident_intake_packet_writing_guide: {
    id: 'resident_intake_packet_writing_guide',
    title: 'Resident Intake Packet Writing Guide',
    slug: 'resident-intake-packet-writing-guide',
    icon: 'authority_forms',
    sections: null,
  },
  quality_and_operations_standards: {
    id: 'quality_and_operations_standards',
    title: 'Quality and Operations Standards',
    slug: 'quality-and-operations-standards',
    icon: 'authority_forms',
    sections: null,
  },
  coi_accord_statement_legend: {
    id: 'coi_accord_statement_legend',
    title: 'COI & Accord Statement Legend',
    slug: 'coi-accord-statement-legend',
    icon: 'authority_forms',
    sections: null,
  },
  governing_board_application: {
    id: 'governing_board_application',
    title: 'Governing Board Application',
    slug: 'governing-board-application',
    icon: 'authority_forms',
    sections: null,
  },
  resident_demographic_study: {
    id: 'resident_demographic_study',
    title: 'Resident Demographic Study',
    slug: 'resident-demographic-study',
    icon: 'authority_forms',
    sections: null,
  },
  resident_paid_work_agreement_survey: {
    id: 'resident_paid_work_agreement_survey',
    title: 'Resident Paid Work Agreement Survey',
    slug: 'resident-paid-work-agreement-survey',
    icon: 'authority_forms',
    sections: null,
  },
  recovery_residence_resident_survey: {
    id: 'recovery_residence_resident_survey',
    title: 'Recovery Residence Resident Survey',
    slug: 'recovery-residence-resident-survey',
    icon: 'authority_forms',
    sections: null,
  },
  recovery_residence_leaders_staff_survey: {
    id: 'recovery_residence_leaders_staff_survey',
    title: 'Recovery Residence Leaders/Staff Survey ',
    slug: 'recovery-residence-leaders-staff-survey',
    icon: 'authority_forms',
    sections: null,
  },
}

export const AUTHORITY_FORMS_NAV = [
  {
    id: 'certifications',
    title: 'Certifications',
    icon: 'certifications_inspections',
    forms: [
      AUTHORITY_FORMS.certification_application_form_organization,
      AUTHORITY_FORMS.certification_application_form_contacts,
      AUTHORITY_FORMS.certification_application_form_locations,
      AUTHORITY_FORMS.certification_application_renewal_form,
      AUTHORITY_FORMS.accreditation_agreement,
      AUTHORITY_FORMS.code_of_ethics,
      AUTHORITY_FORMS.assurances,
      AUTHORITY_FORMS.attestation,
      AUTHORITY_FORMS.property_ownership_attestation,
      AUTHORITY_FORMS.home_ownership_attestation,
      AUTHORITY_FORMS.certification_overview,
      AUTHORITY_FORMS.organizational_policies_and_procedures_writing_guide,
      AUTHORITY_FORMS.governing_board_application,
      AUTHORITY_FORMS.quality_and_operations_standards,
      AUTHORITY_FORMS.coi_accord_statement_legend,
    ],
  },
  {
    id: 'inspections',
    title: 'Inspections',
    icon: 'certifications_inspections',
    forms: [AUTHORITY_FORMS.inspection_checklist, AUTHORITY_FORMS.document_review_checklist],
  },
  {
    id: 'complaints',
    title: 'Complaints',
    icon: 'grievance_incident_forms',
    forms: [AUTHORITY_FORMS.grievance],
  },
  {
    id: 'issues',
    title: 'Issues',
    icon: 'grievance_incident_forms',
    forms: [AUTHORITY_FORMS.incident_reporting, AUTHORITY_FORMS.incident_reporting_followup],
  },
  {
    id: 'studies',
    title: 'Studies',
    icon: 'studies_surveys',
    forms: [AUTHORITY_FORMS.resident_demographic_study],
  },
  {
    id: 'surveys',
    title: 'Surveys',
    icon: 'studies_surveys',
    forms: [
      AUTHORITY_FORMS.resident_paid_work_agreement_survey,
      AUTHORITY_FORMS.recovery_residence_resident_survey,
      AUTHORITY_FORMS.recovery_residence_leaders_staff_survey,
    ],
  },
]
