import React from 'react'
import size from 'lodash/size'
import { Route, NavLink } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useGet, useCreate } from '@behavehealth/hooks/useNewAPI'

import { SubscriptionOverlay } from '@behavehealth/constructs/Account/SubscriptionOverlay'
import { SubscriptionBuilderOverlay } from '@behavehealth/constructs/Account/SubscriptionBuilderOverlay'
import SubscriptionsTable from '@behavehealth/constructs/Account/SubscriptionsTable'

import Flex from '@behavehealth/components/Flex'
import Card from '@behavehealth/components/Card'
import Divider from '@behavehealth/components/Divider'
import Button from '@behavehealth/components/Button'
import PageSection from '@behavehealth/components/PageSection/PageSection'
import ConfirmDialog from '@behavehealth/components/Dialogs/ConfirmDialog'

import { useSettings } from '@behavehealth/hooks/useSettings'

export const SubscriptionsManager = () => {
  return (
    <>
      <SubscriptionsManagerIndex />

      <AnimatedRoutes>
        <Route path="new" element={<SubscriptionBuilderOverlay useV6Router />} />
        <Route path=":id" element={<SubscriptionOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const SubscriptionsManagerIndex = () => {
  const { tenant } = useSettings()

  const { data: subscriptions, isLoading: isLoadingSubscriptions }: any = useGet({
    name: ['subscriptions'],
    url: `/subscriptions`,
  })

  const { mutateAsync: processActiveSubscriptions, isLoading: isProcessingActiveSubscriptions }: any = useCreate({
    name: ['facilities', tenant?.id, 'process_active_subscriptions'],
    url: `/facilities/${tenant?.id}/process_active_subscriptions`,
    invalidate: 'subscriptions',
  })

  const { active, other } = React.useMemo(() => {
    const active: any = []
    const other: any = []

    if (size(subscriptions) === 0) return { active, other }

    for (const subscription of subscriptions) {
      if (subscription.status === 'active') active.push(subscription)
      else other.push(subscription)
    }

    return { active, other }
  }, [subscriptions])

  return (
    <PageSection>
      <PageSection.Header
        after={
          <Flex gap="1rem">
            <ConfirmDialog
              glyph="behave_health"
              title="Process Active Subscriptions?"
              message="This will create an Invoice from all the current Active Subscriptions, and move the Billing Period forward by 1 month. Are you sure you want to proceed?"
              onYes={processActiveSubscriptions}
            >
              <Button as={NavLink} label="Process Active Subscriptions…" glyph="behave" type="primary" color="green" size={200} />
            </ConfirmDialog>

            <Button as={NavLink} label="Add Subscription" glyph="add" type="primary" size={200} link="new" />
          </Flex>
        }
      >
        <PageSection.Title title="Active Subscriptions" />
      </PageSection.Header>

      <Card>
        <SubscriptionsTable
          mainLinkAs={NavLink}
          data={active}
          isLoading={isLoadingSubscriptions || isProcessingActiveSubscriptions}
          to={(id: any) => `${id}`}
        />
      </Card>

      <Divider className="opacity-0" />

      <PageSection.Header>
        <PageSection.Title title="Non-Active Subscriptions" />
      </PageSection.Header>

      <Card>
        <SubscriptionsTable
          mainLinkAs={NavLink}
          data={other}
          isLoading={isLoadingSubscriptions || isProcessingActiveSubscriptions}
          to={(id: any) => `${id}`}
        />
      </Card>
    </PageSection>
  )
}
