import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { DataFormAddDropdown } from '@behavehealth/constructs/DataFormsBuilder/DataFormAddDropdown'
import { Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { DISCHARGE_FORMS_SLUGS } from '@behavehealth/utils/constants'
import { DischargesDataTable } from '@behavehealth/constructs/DischargeSummary/DischargesDataTable'
import { getVersionSlug } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'discharges',
  title: 'Discharges',
}

const Discharges: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const clientId = match.params?.resource_id

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
  })

  const tableProps = useDataTable({
    name: ['client', clientId, 'data_forms'],
    endpoint: `/residents/${clientId}/data_forms`,
    params: { category: 'discharge' },
    updateDeleteEndpoint: '/data_forms',
    enabled: !!clientId,
    localStorageKey: 'client_discharges_v1',
  })

  const action = (
    <DataFormAddDropdown
      category="discharge"
      permission="discharges_builder.view"
      featureFlagV2="discharges_builder"
      manageLink="/settings/discharges-builder"
      clientTrack={client?.client_track}
    />
  )

  const to = React.useMemo(
    () => (record: any) => {
      const { version, subcategory, id } = record

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

      const slug =
        version === '3.0'
          ? `/${DISCHARGE_FORMS_SLUGS[subcategory]}`
          : record.subcategory === 'non_clinical_discharge_summary'
          ? '/non-clinical'
          : ''

      return {
        pathname: `${match.url}${slug}/${id}${formattedVersion}`,
        parent: match,
      }
    },
    [match.url],
  )

  return (
    <Page feature="discharges" actions={canCreate && action} help={<HelpTagIframe id="discharges" />}>
      <Grid gap="1rem" columns="100%">
        <DischargesDataTable
          {...tableProps}
          to={to}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'discharges.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Discharges, pageConfig))
