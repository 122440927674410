import React from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import compact from 'lodash/compact'
import pluralize from 'pluralize'
import size from 'lodash/size'

import { useParams as useParamsV6, useNavigate, useLocation as useLocationV6 } from 'react-router-dom-v5-compat'

import { useGet, useCreate, useUpdate, useDelete, invalidateQueries } from './useNewAPI'

import { GlobalSummonOverlayContext } from '../components/GlobalSummonOverlay'

type Args = {
  closeOnSave?: boolean
  endpoint?: string
  filters?: any
  invalidate?: string | Array<string>
  invalidateKeys?: Array<any>
  isEditable?: boolean
  name?: string
  onDeleteSuccessful?: any
  onSaveSuccessful?: any
  options?: any
  Overlay?: any
  skipClose?: boolean
  parentType?: string
  parentRequest?: string
  headers?: any
  disableParentRequest?: boolean
  onAfterClose?: any
  openAfterCreate?: boolean
}

type BuildKeysArgs = {
  filters?: any
  id: string
  name: string
  parent: any
}

const buildKeys = ({ parent, name, filters, id }: BuildKeysArgs) => {
  const queryKey = compact([parent.type, parent.id, name, id])
  const invalidateKey = compact([parent.type, parent.id, filters, name && pluralize(name)])
  const createKey = compact([parent.type, parent.id, filters, name])

  return {
    queryKey: queryKey,
    createKey: createKey,
    invalidateKey: invalidateKey,
  }
}

const CLIENT_RESOURCE_TYPES = ['residents', 'resident', 'clients', 'client', 'admissions', 'admission', 'alumni']

const processResourceType = (type: string) => {
  if (CLIENT_RESOURCE_TYPES.includes(type)) return 'client'
  else return pluralize.singular(type)
}

const getResourceType = ({ options, params }: any) => {
  // type passed through props
  if (options?.parent?.type) {
    return processResourceType(options.parent.type)
  }

  // structured type found in URL param
  if (params?.resource || params?.resource_type) {
    return processResourceType(params?.resource || params?.resource_type)
  }

  // unstructured type found in URL
  if (CLIENT_RESOURCE_TYPES.includes(params['0'])) {
    return processResourceType(params['0'])
  }

  return null
}

export const useOverlay = (args: Args) => {
  const {
    closeOnSave,
    endpoint,
    filters,
    invalidate,
    invalidateKeys,
    name,
    onDeleteSuccessful,
    onSaveSuccessful,
    options = {},
    Overlay,
    skipClose,
    parentType = 'client',
    parentRequest = '/residents',
    headers,
    disableParentRequest,
    onAfterClose,
    openAfterCreate,
  } = args

  const { useV6Router } = options

  const form: any = React.useRef()
  const history: any = useHistory()
  const navigate: any = useV6Router ? useNavigate() : history.push

  // const locationV5: any = useLocation()
  // const locationV6: any = useLocationV6()
  const location = useV6Router ? useLocationV6() : useLocation()

  // const paramsV5: any = useParams()
  // const paramsV6 = useParamsV6()
  const params = useV6Router ? useParamsV6() : useParams()

  const { addOverlay } = React.useContext(GlobalSummonOverlayContext)

  // setup initial form data
  const initialData = options.initialData || location.data || location.state?.data
  const id = options.dataID || params.id || initialData?.id
  const shouldAnonymize = options.anonymize || location.anonymize
  const isNew = id === 'new' || !id
  const [isDeleted, setIsDeleted] = React.useState(false)

  // find parent id and type
  const parent = {
    id: options.parent?.id || params.resource_id,
    type: getResourceType({ options, params }),
  }

  // setup react query keys
  const _queryKeys = options.queryKeys || buildKeys({ parent, name, filters, id })
  const { queryKey, createKey, invalidateKey } = _queryKeys

  // GET
  const {
    data,
    isLoading: isGetting,
    isRefetching,
    isFetching,
    refetch,
  } = useGet({
    name: queryKey,
    url: `${endpoint}/${id}`,
    options: { enabled: !isNew && !isDeleted && !!endpoint },
    headers,
  })

  // CREATE
  const { mutateAsync: createAsync, isLoading: isCreating } = useCreate({
    name: createKey,
    url: endpoint,
    invalidate: invalidate || invalidateKey,
    invalidateKeys,
    headers,
  })

  // UPDATE
  const { mutateAsync: updateAsync, isLoading: isUpdating } = useUpdate({
    name: queryKey,
    url: `${endpoint}/${id}`,
    invalidate: invalidate || invalidateKey,
    invalidateKeys,
    headers,
  })

  // DELETE
  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useDelete({
    name: queryKey,
    url: endpoint,
    invalidate: invalidate || invalidateKey,
    invalidateKeys,
    headers,
    onSuccess: () => {
      setIsDeleted(true)
      if (onDeleteSuccessful) onDeleteSuccessful()
      if (options?.onDeleteSuccessful) options?.onDeleteSuccessful?.()
      close()
    },
  })

  // ARCHIVE
  const { mutateAsync: archiveAsync, isLoading: isArchiving } = useUpdate({
    name: queryKey,
    url: `${endpoint}/${id}`,
    invalidate: invalidate || invalidateKey,
    headers,
    invalidateKeys,
  })

  // GET Record
  const { data: apiRecord = {} }: any = useGet({
    name: [parentType, parent.id],
    url: `${parentRequest}/${parent.id}`,
    options: {
      enabled: !!parent.id && !disableParentRequest,
    },
  })

  const record = data?.client || apiRecord

  // set up local state
  const [isEditable, setIsEditable] = React.useState(isNew || !!args.isEditable)
  const [isValid, setIsValid] = React.useState(false)

  // set up initial form model
  const initialModel = Object.assign({}, initialData, data)

  // set up reaction state
  const isLoading = isGetting
  const isEmpty = !isNew && size(initialModel) === 0
  const isSaving = isCreating || isUpdating || options.isSaving
  const isOverlayLoading = isLoading || isEmpty

  const close = () => {
    if (options.type === 'summon' && options.onClose) return options.onClose()

    if (useV6Router && options?.back) return navigate(options.back)

    if (useV6Router && location?.state?.from) return navigate(location.state.from)

    const url = location.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const cancel = () => {
    form.current?.resetForm()
    setIsEditable(false)
  }

  const exitEdit = () => {
    setIsEditable(false)
  }

  const save = async () => {
    let _saveData = {
      ...form.current.getFormValue(),
    }

    // if there is a save function passed in, use that instead
    if (options.save) {
      await options.save(_saveData)

      if (!options.skipClose) close()

      invalidateQueries(name)

      return
    }

    let request = isNew ? await createAsync(_saveData) : await updateAsync(_saveData)

    if (options?.onSaveSuccessful) {
      options?.onSaveSuccessful?.(request.data)
    }

    if (onSaveSuccessful) {
      onSaveSuccessful(request.data)
    }

    if (!skipClose && (isNew || closeOnSave)) close()

    setIsEditable(false)

    if (onAfterClose) {
      onAfterClose({ isNew, data: request.data, id, params, location, navigate })
    }

    if (openAfterCreate && isNew && request?.data?.id && location.pathname?.endsWith('/new')) {
      navigate(location.pathname.replace('/new', `/${request.data.id}`))
    }
  }

  const saveWithData: any = async (saveData: any, saveOptions: any = {}) => {
    const { keepEditing = false } = saveOptions

    let request = isNew ? await createAsync(saveData) : await updateAsync(saveData)

    if (options?.onSaveSuccessful) {
      options?.onSaveSuccessful?.(request.data)
    }

    if (onSaveSuccessful) {
      onSaveSuccessful(request.data)
    }

    if (!skipClose && (isNew || closeOnSave)) close()

    if (!keepEditing) setIsEditable(false)

    if (onAfterClose) {
      onAfterClose({ isNew, data: request.data, id, params, location, navigate })
    }

    if (openAfterCreate && isNew && request?.data?.id && location.pathname?.endsWith('/new')) {
      navigate(location.pathname.replace('/new', `/${request.data.id}`))
    }

    return request.data
  }

  const deleteRecord = async () => {
    // if there is a delete function passed in, use that instead
    if (options.deleteRecord) {
      await options.deleteRecord()

      if (!options.skipClose) close()

      return
    }

    await deleteAsync(id)
  }

  const archive = async (status = 'archived') => {
    try {
      await archiveAsync({ status: status })
    } catch (error) {
      console.debug(error)
    }
  }

  const unArchive = async (status = 'active') => {
    try {
      await archiveAsync({ status: status })
    } catch (error) {
      console.debug(error)
    }
  }

  const onIdle = () => {} // TODO

  const handleMinimize = () => {
    if (options?.type === 'summon') return

    addOverlay(
      <Overlay
        {...options}
        isMinimized
        type="summon"
        dataID={id}
        parent={parent}
        initialData={initialData}
        queryKeys={{ queryKey, createKey, invalidateKey }}
      />,
    )

    close()
  }

  const handleFullyMinimize = () => {
    if (options?.type === 'summon') return

    addOverlay(
      <Overlay
        {...options}
        isMinimized
        isFullyMinimized
        type="summon"
        dataID={id}
        parent={parent}
        initialData={initialData}
        queryKeys={{ queryKey, createKey, invalidateKey }}
      />,
    )

    close()
  }

  return {
    anonymize: shouldAnonymize,
    cancel,
    client: record,
    record,
    close,
    createAsync,
    data,
    archive,
    unArchive,
    deleteAsync,
    deleteRecord,
    edit: () => setIsEditable(true),
    exitEdit,
    form,
    handleFullyMinimize,
    handleMinimize,
    highlightRequired: form.current?.highlightInvalid,
    id,
    initialData,
    initialModel,
    invalidateKeys,
    isDeleting,
    isEditable,
    isEmpty,
    isFetching,
    isArchiving,
    isInvalid: !isValid,
    isLoading,
    isNew,
    isOverlayLoading,
    isRefetching,
    isSaving,
    isUpdating,
    isValid,
    onIdle,
    onValidationUpdate: setIsValid,
    parent,
    queryKey,
    save,
    saveWithData,
    setIsEditable,
    updateAsync,
    refetch,
    params,
    navigate,
    location,
  }
}
