import React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AccessListImportOverlayV6 } from '@behavehealth/components/Overlays/pages/AccessListImportOverlay'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { NavGroup, NavItem, Page, PageGrid, Nav, HelpTagIframe } from '@behavehealth/components'
import AccessLevelStatus from '@behavehealth/components/Statuses/AccessLevelStatus'

import AccessList from './access_list'

const pageConfig = {
  feature: 'staff_clients_access',
  title: 'Staff Clients Access',
}

const AccessLists: React.FC = () => {
  const { data, isLoading }: any = useGet({
    name: ['employees', 'for_access'],
    url: `/employees/for_access`,
  })

  const isEmpty = size(data) === 0

  if (!data) {
    return (
      <Page
        feature="employees"
        title="Staff"
        isEmpty={isEmpty}
        isLoading={isLoading}
        emptyDescription="There are no client chats to display"
      />
    )
  }

  return (
    <PageGrid breakpoint={5} columns="280px 1fr">
      <Nav
        icon="employees"
        breakpoint={5}
        title="Staff"
        headingSize={300}
        menuLabel="All Staff"
        menuGlyph="user_group"
        help={<HelpTagIframe id="settings_staff_access_menu" />}
      >
        <NavGroup>
          {data.map((employee: any) => (
            <NavItem
              showAvatarInitials
              as={NavLink}
              label={employee.name}
              to={employee.id}
              color="#7e83a9"
              description={<AccessLevelStatus isLocked={employee.position === 'owner'} status={employee.access_level} />}
            />
          ))}
        </NavGroup>
      </Nav>

      <Routes>
        <Route path=":id/*" element={<AccessList />} />
        <Route index element={<Navigate to={data[0]?.id} replace />} />
      </Routes>

      <AnimatedRoutes>
        <Route path=":id/import" element={<AccessListImportOverlayV6 useV6Router />} />
      </AnimatedRoutes>
    </PageGrid>
  )
}

export default withPageError(withMarketing(AccessLists, pageConfig))
